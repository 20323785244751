import { endpointsService } from "../../../../services/endPoints.services";
import { setDisplayErrorSnackBar} from "../../../../features/userSlice";
import store from "../../../../store";


async function getPsiRequestsOfCompetitors(oauthTokenHeader: string,website_shared_uid:string|null, setIsLoading: any,setRows:any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getPsiRequestsOfCompetitors(oauthTokenHeader,website_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else {
            setRows(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        setRows([]);
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function putPsiRequestsOfCompetitors(oauthTokenHeader: string,website_shared_uid:string|null,psi_shared_uid:any,data:any,setStatus:any) {
    try {
        // store.dispatch(setIsLoading(true));
        const response = await endpointsService.putPsiRequestOfCompetitors(oauthTokenHeader,website_shared_uid,psi_shared_uid,data);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else {
            setStatus(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        (store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function getSerpCompetitors(oauthTokenHeader: string,setData:any, trackPromise: any) {
    try {
        // store.dispatch(setIsLoading(true));
        const response = await endpointsService.getSerpCompetitors(oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else {
            setData(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function postCompetitors(oauthTokenHeader: string,setData:any,website_shared_uid:any,data:any) {
    try {
        // store.dispatch(setIsLoading(true));
        const response = await endpointsService.postCompetitors(oauthTokenHeader,website_shared_uid,data);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to get page content');
        }
        else {
            setData(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function postSerpCompetitor(oauthTokenHeader: string,data:any,setData:any) {
    try {
        // store.dispatch(setIsLoading(true));
        const response = await endpointsService.postSerpCompetitor(oauthTokenHeader,data);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to create page content');
        }
        else {
            setData(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}



// eslint-disable-next-line
export const PsiCompetitors = {
    getPsiRequestsOfCompetitors,
    putPsiRequestsOfCompetitors,
    getSerpCompetitors,
    postCompetitors,
    postSerpCompetitor
};
