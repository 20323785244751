import { endpointsService } from "../../../services/endPoints.services";
import { setDisplayErrorSnackBar, setIsLoading } from "../../../features/userSlice";
import store from "../../../store";

async function createETLSequence(oauthTokenHeader: string, data:any) { 
    try {
        const response = await endpointsService.createETLSequence(oauthTokenHeader, data);
        if (!response.status || response.status !== 201) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating ETL Sequence', type: 'error' }));
        }
        else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'ETL Sequence Created Successfully', type: 'success' }));
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function getETLSequenceContent(oauthTokenHeader: string, selectIsLoading:any, setETLSequenceContent: any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getETLSequences(oauthTokenHeader);
        if(!response || !response.status || response.status !== 200){
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting ETL Sequence Content', type: 'error' })));
        }else {
            setETLSequenceContent(response?.data);
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function getETLProcessList(oauthTokenHeader: string, selectIsLoading:any ,setETLProcessList: any, trackPromise:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getProcessList(oauthTokenHeader);
        if(!response || !response.status || response.status !== 200){
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting ETL Process List', type: 'error' })));
        } else {
            setETLProcessList(response?.data);
        }
    } catch (e:any) {
        // store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
};

async function editETLSequence(sequence_id:any, oauthTokenHeader: string, data:any) {
    try {
        const response = await endpointsService.updateETLSequence(sequence_id,oauthTokenHeader, data);
        if (!response.status || response.status !== 200 || response.status === 400) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating ETL Sequence', type: 'error' }));
        }
        else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'ETL Sequence Updated Successfully! Please make sure to Deploy & Sync the Sequence Configurations', type: 'success' }));
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function etlSequenceToggle(oauthTokenHeader: string, setETLSequenceContent: any, data:any, sequence_id: any,  ) {
    try {
        const response = await endpointsService.etlSequenceToggle(sequence_id, data, oauthTokenHeader);
        if (!response?.status || response?.status !== 204) {
        store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting ETL Sequence Content', type: 'error' }));
        } else {
            setETLSequenceContent(response);
        }
    } catch (e:any) {
        if(e?.status !== undefined){
            setETLSequenceContent(e?.parsedText?.reason);
            store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
        else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Toggle Successfull', type: 'success' }));
        }
    }
}

async function deployETLSequence(data: any, oauthTokenHeader: string) {
try {
    const response = await endpointsService.deployETLSequence(data, oauthTokenHeader);
    if (!response?.status || response?.status !== 204) {
    store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Deploying ETL Sequence Configuration', type: 'error' }));
    } else {
        store.dispatch(setDisplayErrorSnackBar({ message: 'ETL Sequence Deployed Successfully', type: 'success' }));
    }
} catch (e:any) {
    if(e?.status !== undefined){
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Something went wrong while Deploying ETL Sequence Configuration', type: 'error' }));
    }
    else {
        store.dispatch(setDisplayErrorSnackBar({ message: 'Toggle Successfull', type: 'success' }));
    }
}
};

export const ETLSequencesEndPointLogic = {
    getETLProcessList,
    createETLSequence,
    getETLSequenceContent,
    editETLSequence,
    etlSequenceToggle,
    deployETLSequence
}
