import SequencesTable from "../../../../components/Admin/Processes/ETL-Sequence/SequenceTable/SequencesTable";
import { useState, useEffect } from "react";
import { useHasETLSequencesPermission } from '../../../../logic/Admin/Permissions/useHasETLSequencesPermission';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { useDispatch } from "react-redux";
import { ETLSequencesEndPointLogic } from "../../../../logic/Admin/ETLSequences/ETLSequencesEndPointLogic";
import SimpleBackdrop from "../../../../components/BackDrop/BackDrop";
import { trackPromise } from 'react-promise-tracker';

export default function ETLSequenceScreen(userToken: any) {

  const hasGetPermissions = useHasETLSequencesPermission([
    ModulesPermissionNames.GET_API_SEQUENCES,
    ModulesPermissionNames.GET_API_SEQUENCE_ID,
    ModulesPermissionNames.GET_API_SEQUENCE_TOGGLE,
    ModulesPermissionNames.GET_API_DEPLOY_ETL,
    ModulesPermissionNames.GET_API_PROCESSES,
  ]);

  const hasEditPermissions = useHasETLSequencesPermission([
    ModulesPermissionNames.PUT_API_SEQUENCES,
    ModulesPermissionNames.POST_API_SEQUENCES,
    ModulesPermissionNames.PUT_API_SEQUENCE_ID,
    ModulesPermissionNames.POST_API_SEQUENCE_ID,
    ModulesPermissionNames.PUT_API_SEQUENCE_TOGGLE,
    ModulesPermissionNames.POST_API_SEQUENCE_TOGGLE,
    ModulesPermissionNames.POST_API_DEPLOY_ETL,
    ModulesPermissionNames.PUT_API_DEPLOY_ETL,
    ModulesPermissionNames.POST_API_PROCESSES,
    ModulesPermissionNames.PUT_API_PROCESSES,
  ]);

  const oauthTokenHeader = userToken?.userToken;
  const [isLoading, setIsLoading] = useState(false);
  const [reload,setReload] = useState(false);
  const [etlSequence, setEtlSequence] = useState<any[]>([]);
  const [processList, setProcessList] = useState<any[]>([]);
  const [selectedChainStatus, setSelectedChainStatus] = useState("");
  const [inputValueStatus, setInputValueStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState() as any;
  const [selectedFrequency, setSelectedFrequency] = useState() as any;
  const [selectedLastRunDate, setSelectedLastRunDate] = useState(null);
  const [selectedFrequencyDate, setSelectedFrequencyDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedOptionChain, setSelectedOptionChain] = useState<string>("");
  const dispatch = useDispatch();

  const tableHeadings = {
    "edit": "Edit",
    "id": "Cron ID",
    "name": "Queue Name",
    "frequency": "Frequency",
    "category" : "Category",
    "last_run": "Last Run",
    "status": "Status",
    "count": "Process Count",
    "info": "More Info"
  };

useEffect(() => {
  const fetchETLChainsData = async () => {
    setIsLoading(true);
    try{
      if (hasGetPermissions) {
        await Promise.all([
          ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading,setEtlSequence,trackPromise),
          ETLSequencesEndPointLogic.getETLProcessList(oauthTokenHeader,setIsLoading,setProcessList,trackPromise)
        ]);
      } else {
        throw new Error("User doesn't have permissions to fetch ETL Sequences.");
      }
    }catch (error){
      console.error("Error fetching ETL Chains:", error);
    } finally {
      setIsLoading(false);
      setReload(false);
    }
  };
  if (hasGetPermissions) {
    fetchETLChainsData();
  }
    // eslint-disable-next-line
}, [oauthTokenHeader, hasGetPermissions, trackPromise, reload === true]);

  return (
    <div style={{ background: "#ffffff" }}>
      {isLoading ? (
        <SimpleBackdrop />
      ) : (
        <SequencesTable
          etlSequence={etlSequence}
          processList={processList}
          isLoading={isLoading}
          selectedLastRunDate={selectedLastRunDate}
          setSelectedLastRunDate={setSelectedLastRunDate}
          selectedFrequencyDate={selectedFrequencyDate}
          setSelectedFrequencyDate={setSelectedFrequencyDate}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          hasGetPermissions={hasGetPermissions}
          hasEditPermissions={hasEditPermissions}
          setEtlSequence={setEtlSequence}
          oauthTokenHeader={oauthTokenHeader}
          trackPromise={dispatch}
          tableHeadings={tableHeadings}
          selectedChainStatus={selectedChainStatus}
          selectedChain={selectedChain}
          setSelectedChain={setSelectedChain}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setStatus={setStatus}
          status={status}
          inputValueStatus = {inputValueStatus}
          setInputValueStatus = {setInputValueStatus}
          selectedOptionChain = {selectedOptionChain}
          setSelectedOptionChain={setSelectedOptionChain}
          setSelectedChainStatus={setSelectedChainStatus}
          setReload={setReload}
        />
      )}
    </div>
  );
}
