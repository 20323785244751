import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

interface Column { // Define the column type
  id: "id" | "process__name" | "last_failed";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [ // Define the columns
  { id: "id", label: "#", minWidth: 50 },
  { id: "process__name", label: "Process Name", minWidth: 150 },
  { id: "last_failed", label: "Last Failed", minWidth: 150 },
];

interface Data {
  order: number;
  process__name: string;
  end_time: string | null;
}

interface FailedProcessesProps { // Define the props type
  failedProcesses: Data[];
}

const FailedProcesses = ({failedProcesses}:FailedProcessesProps) => {
  const [showAll, setShowAll] = useState(false); // State to track whether to show all rows
  // Function to handle empty or null end_time
  const getEndTime = (endTime: string | null) => {
    if (!endTime) return "N/A";
    const date = new Date(endTime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  // Check if there's data to display
  if (failedProcesses.length === 0) {
    return (
      <Paper className="FailedProcessWrap" sx={{ width: "100%", overflow: "hidden", boxShadow: 'none' }}>
        <Typography variant="h6" align="center">No Failed Process</Typography>
      </Paper>
    );
  }

  // Determine which rows to display based on the showAll state
  const displayedRows = showAll ? failedProcesses : failedProcesses.slice(0, 3);
  return (
    <Paper className="FailedProcessWrap" sx={{ width: "100%", overflow: "hidden", boxShadow: 'none' }}>
      <TableContainer sx={{ boxShadow: 'none', maxHeight: 210 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row: Data, index: number) => (
              <TableRow key={row.order}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="left">{row.process__name}</TableCell>
                  <TableCell align="left">{getEndTime(row.end_time)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Show the link only if there are more than 3 rows */}
      {failedProcesses.length > 3 && (
        <Box sx={{ textAlign: 'right', mt: 1 }}>
          <Link 
            to={''} 
            style={{ color: '#9c27b0', fontWeight: '400' }} 
            onClick={() => setShowAll(!showAll)} // Toggle showAll state
          >
            {showAll ? "Show less" : "View all failed processes"}
          </Link>
        </Box>
        )}
    </Paper>
  );
};

export default FailedProcesses;
