
import parse from "html-react-parser";
import React from "react";
import ReactDOMServer from 'react-dom/server';
var listElements = [] as any;
const removeEmptyLines = (arr: any) => {
    let newParsedArr;
    if (arr) {
        if (arr?.length > 1) {
            newParsedArr = arr?.filter((str: any) => (str !== ' ' && str !== '\r\n' && str !== '\n' && str !== '\r' && str !== '\n\n'
                && str !== '\n\n\n' && String(str).trim() !== '' && str !== undefined));
        } else {
            if (arr !== ' ' && arr !== '\r\n' && arr !== '\n' && arr !== '\r' && arr !== '\n\n'
                && arr !== '\n\n\n' && String(arr).trim() !== '' && arr !== undefined) {
                newParsedArr = arr;
            }
        }
    }
    return newParsedArr;
}
const getScriptInHtmlPage = (arr: any, type: string) => {
    const newParsedArr = arr?.filter((str: any) => (str?.type === type));
    return newParsedArr;
}
const getWrapperElementFromHtmlTemplate = (resultStructure: any) => {
    // wrapper elments will get htmlWrapper in dictionary
    const elementsFromDictionary = resultStructure?.filter((value: any) => (value[0]?.htmlWrapper === true))
    return elementsFromDictionary;
}
const getElementsInWrapperOnCreatePage = (wrapperElementsFromUrl: any) => {
    let body = [] as any;
    let header = [] as any;
    let headerMenu = [] as any;
    let footer = [] as any;
    let wrapperElements = [] as any;
    let htmlWrapperElements = [] as any;
    let newArr = removeEmptyLines(wrapperElementsFromUrl[0]?.props?.children);

    // eslint-disable-next-line
    newArr?.map((element: any) => {
        // eslint-disable-next-line
        if (element?.type === 'header') {
            header.push(element);
        } else if (element?.type === 'footer') {
            footer.push(element);
        }else if(element?.props?.className.includes("sponsors-template")){
            footer.push(element);
        }else if(element?.props?.className.includes("siteNavbar")){
            headerMenu.push(element)
        } else if (element?.props?.children || element?.props?.className.includes("internal-widget")) {
            body.push(element);
        }
    });
    wrapperElements.push(header,headerMenu, body, footer);
    htmlWrapperElements.push({ "htmlWrapperElements": wrapperElements });
    return htmlWrapperElements;
}
const getElementsInWrapperFromHtmlTemplate = (elementsFromDictionary: any, allElements: any) => {
    let body = [] as any;
    let header = [] as any;
    let headerMenu = [] as any;
    let footer = [] as any;
    let wrapperElements = [] as any;
    let htmlWrapperElements = [] as any;
    // eslint-disable-next-line
    elementsFromDictionary?.map((dictionaryObj: any) => {
        const result = allElements?.filter((element: any) => (dictionaryObj[0]?.id === element?.props?.id));
        if (result?.length > 0) {
            let newArr = removeEmptyLines(result[0]?.props?.children);
            // eslint-disable-next-line
            newArr?.map((value: any) => {
                if (value?.type === 'header') {
                    header.push(value);
                } else if (value?.type === 'footer') {
                    footer.push(value);
                } else if(value?.props?.className.includes("siteNavbar")){
                    headerMenu.push(value);
                }else if(value?.props?.className.includes("sponsors-template")){
                    footer.push(value);
                } else if (value?.props?.children || value?.props?.className.includes("internal-widget")) {
                    body.push(value);
                }
            })
            wrapperElements.push(header,headerMenu, body, footer);
        }
        htmlWrapperElements.push({ "htmlWrapperElements": wrapperElements });
    })
    return htmlWrapperElements;
}
const parseHtmlToJsonOnCreatePage = (pageContentJson: any) => {
    const result = [] as any;
    const pageUrlComponents = [] as any;
    let newTemp = "" as any;
    if (pageContentJson) {
        newTemp = pageContentJson.replace(/\r?\n|\r/g, '');
        newTemp = newTemp.replace(/"/g, "'");
        newTemp = parse(newTemp);
        // head
        pageUrlComponents.push(newTemp.props.children[0]);
        // body - only scripts and style
        pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "script"));
        pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "style"));
        // pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "footer"));
        // pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "nav"));
        result.push(pageUrlComponents);
        // in case there's a wrapper in html template (ppc sites)
        // all elements for getting from it widgets and structrued elements
        // After will have dictionary we can check without id
        const wrapperElementsFromUrl = newTemp?.props?.children[1]?.props.children?.filter((value: any) => (value?.props?.class === 'wrpr'));
        if (wrapperElementsFromUrl?.length > 0) {
            result.push(getElementsInWrapperOnCreatePage(wrapperElementsFromUrl));
        }
    }
    return result;
}

const parseHtmlToJson = (pageContentJson: any, type?: string, resultStructure?: any) => {
    const result = [] as any;
    const pageUrlComponents = [] as any;
    let newTemp = "" as any;
    if (pageContentJson) {
        newTemp = pageContentJson?.replace(/\r?\n|\r/g, '');
        newTemp = newTemp?.replace(/"/g, "'");
        newTemp = parse(newTemp);
        if (type === "url") {
            // head
            pageUrlComponents.push(newTemp?.props?.children[0]);
            // body - only scripts and style
            pageUrlComponents.push(getScriptInHtmlPage(newTemp?.props?.children[1]?.props?.children, "script"));
            pageUrlComponents.push(getScriptInHtmlPage(newTemp?.props?.children[1]?.props?.children, "style"));
            // pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "footer"));
            // pageUrlComponents.push(getScriptInHtmlPage(newTemp.props.children[1].props.children, "nav"));
            result.push(pageUrlComponents);
            // in case there's a wrapper in html template (ppc sites)
            if (resultStructure) {
                // all elements for getting from it widgets and structrued elements
                const elementsFromDictionary = getWrapperElementFromHtmlTemplate(resultStructure);
                if (elementsFromDictionary?.length > 0) {
                    result.push(getElementsInWrapperFromHtmlTemplate(elementsFromDictionary, newTemp?.props?.children[1]?.props?.children));
                }
            } else {
                // all elements for getting from it widgets and structrued elements
                result.push(removeEmptyLines(newTemp?.props?.children[1]?.props?.children));
            }
        }
        else if (type !== "css") {
            newTemp = removeEmptyLines(newTemp);
        }
    }
    if (result?.length > 0) {
        return result;
    } else {
        return newTemp;
    }
}
const checkshortcodes = (parsedJson: any) => {
    if (parsedJson) {
        const shortCodes = [] as any;
        let startKey = 0;
        let endKey = 0;
        while (startKey !== -1) {
            startKey = parsedJson.indexOf("[", endKey);
            endKey = parsedJson.indexOf("]", endKey) + 1;
            shortCodes.push(parsedJson.substring(startKey, endKey));
        }
    }
}
const buildDivForShortCodeWidget = (details: any) => {
    let newElements = [] as any;
    // eslint-disable-next-line 
    Object.keys(details?.checked)?.map((obj: string) => {
        const shortcodes = details?.widgetSelected;
        let newDiv;
        if (shortcodes.length >1 && shortcodes.length <= 5) {
            // Dynamically construct the 'shared_uids' string for up to 5 shortcodes
            const sharedUids = shortcodes.slice(0, 5).map((uid:any, index:any) => `${uid}:${index + 1}`).join(',');
    
            newDiv = `<div class="internal-widget ${obj}">[render_widget shared_uids='${sharedUids}' location='${obj}']</div>`;
        } else {
            // Handle the case where there are more than 5 shortcodes or no shortcodes
            newDiv = `<div class="internal-widget ${obj}">[render_widget shared_uid='${shortcodes[0] || ''}' location='${obj}']</div>`;
        }
    
        newElements.push(newDiv);
    });
    
    return newElements;
}
const buildDivForShortCode = (details: any) => {
    let newElements = [] as any;
    const shortcodes = details?.widgetSelected;
    const newDiv = `<div class="internal-widget">[render_widget shared_uid='${shortcodes[0]}']</div>`;
    newElements.push(newDiv);
    return newElements;
}
const getSectionMainLocation = (currentpageElements: any, currentDroppable: number) => {
    let indexElement = '' as any;
    // eslint-disable-next-line 
    indexElement = currentpageElements?.findIndex((value: any) => (value?.props.className === 'main' &&  value?.props.className === "sponsors-template htmlTemplate" && value?.type === 'SECTION'));
    return indexElement;
}
const onEditWidget = (currentpageElements: any, setCurrentPageElements: any, currentpageElementsOriginal: any, setCurrentPageElementsOriginal: any,
    elementIndex: number, widgetShortCode: string, deviceChecked: any,siteAllAvailableWidgets?:any) => {
    // wrapping shortcode for widget
    const newItem = buildDivForShortCodeWidget({ checked: deviceChecked, widgetSelected: widgetShortCode });
    let parsedValue =parse(newItem[0]) as any;
    let widgetName = "" as string;
    let widgetType = "" as string;
    
    siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
        if(siteAllAvailableWidget.shared_uid.includes(widgetShortCode[0])){
            widgetName=siteAllAvailableWidget?.widget_name;
            widgetType=siteAllAvailableWidget?.type;
        }
        return null;
    })
    siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
        if(siteAllAvailableWidget.shared_uid.includes(widgetShortCode[1])){
            widgetName+=" , "+siteAllAvailableWidget?.widget_name;
            widgetType=siteAllAvailableWidget?.type;
        }
        return null;
    })  
    
    siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
        if(siteAllAvailableWidget.shared_uid.includes(widgetShortCode[2])){
            widgetName+=" , "+siteAllAvailableWidget?.widget_name;
            widgetType=siteAllAvailableWidget?.type;
        }
        return null;
    }) 
    
    siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
        if(siteAllAvailableWidget.shared_uid.includes(widgetShortCode[3])){
            widgetName+=" , "+siteAllAvailableWidget?.widget_name;
            widgetType=siteAllAvailableWidget?.type;
        }
        return null;
    })    

    siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
        if(siteAllAvailableWidget.shared_uid.includes(widgetShortCode[4])){
            widgetName+=" , "+siteAllAvailableWidget?.widget_name;
            widgetType=siteAllAvailableWidget?.type;
        }
        return null;
    })   
      
        

 
    let newItemValue= `<div class="${parsedValue?.props.className}"  widget_type="${widgetType}" widget_name="${widgetName}">${parsedValue?.props.children}</div>`
    let newCurrentElements = Array.from(currentpageElements);
    let newOriginalElements = Array.from(currentpageElementsOriginal);
    if (newItem[0]?.includes('mobile') || newItem[0]?.includes('tablet')) {
        const newDiv = `<div class="internal-widget ${Object.keys(deviceChecked)[0]}" widget_type="${widgetType}" widget_name="${widgetName}">Please note: the contents of this widget are displayed on other devices</div>`;

        // no need to replace mobile and tablet on currentpageElements since we still won't see it on desktop
        newCurrentElements[elementIndex] = parse(newDiv);
        newOriginalElements[elementIndex] = parse(newItemValue);
    } else {
        newCurrentElements[elementIndex] = parse(newItemValue);
        newOriginalElements[elementIndex] = parse(newItemValue);
        // only if we update desktop we need to update in current element
    }
    setCurrentPageElements(newCurrentElements);
    setCurrentPageElementsOriginal(newOriginalElements);
    return { newCurrentElements, newOriginalElements };
}

const onDragEnd = (currentpageElements: any, setCurrentPageElements: any, tasks: any, setTasks: any, data: any,
    currentpageElementsOriginal: any, setCurrentPageElementsOriginal: any, setNewElementAdded: any, WidgetDetails?: any,siteAllAvailableWidgets?:any) => {
    if (!data) {
        return;
    }
    let newItem = '' as any;
    if (data?.source?.droppableId === "PageBuilding" ) {
        newItem = Array.from(currentpageElements);
        const [removed] = newItem?.splice(data?.source?.index, 1);
        newItem?.splice(data?.destination?.index, 0, removed);
        setCurrentPageElements(newItem);
        const newItemOriginal = Array.from(currentpageElementsOriginal);
        const [removedOriginal] = newItemOriginal.splice(data.source.index, 1);
        newItemOriginal.splice(data.destination.index, 0, removedOriginal);
        setCurrentPageElementsOriginal(newItemOriginal);

    } else {
        if (WidgetDetails) {
            // wrapping shortcode for widget
            const widgetType = WidgetDetails?.type;
            if (widgetType === 'Comparison Table') {
                newItem = buildDivForShortCodeWidget(WidgetDetails);
            } else {
                newItem = buildDivForShortCode(WidgetDetails);
            }
        }
    
        }
        const metricId = data.draggableId;
        let key = '';
        if (data.draggableId?.split("-").length > 3) {
            const splittedKeys = data.draggableId?.split("-");
            key = splittedKeys[splittedKeys.length - 2];
        } else {
            key = data.draggableId?.split("-")[1];
        }
        const elementInTasks = tasks[key]?.find((item: any) => (item?.id === metricId));
        // for other items which are not widgets
        if (newItem?.length === 0) {
            newItem = elementInTasks.title as any;
        }
        if (WidgetDetails) {
            let newCurrentElements = Array.from(currentpageElements);
            let newOriginalElements = Array.from(currentpageElementsOriginal);
            // eslint-disable-next-line
            newItem?.map((value: any, index: number) => {
                let parsedValue = parse(value) as any;
                const uidRegex = /shared_uids?='([^']*)'/;
                        let filteredSharedUids = [] as any;
                        let widgetName = "" as string;
                        let widgetType = "" as string;
                        const match = parsedValue && parsedValue?.props?.children.match(uidRegex);
                         if (match) {
                           filteredSharedUids = match[1].split(',').map((uid:any) => uid.split(':')[0]); 
                           }
                        siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
                            if(siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[0])){
                                widgetName=siteAllAvailableWidget?.widget_name;
                                widgetType=siteAllAvailableWidget?.type;
                            }
                            return null;
                        })    
                        siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
                            if(siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[1])){
                                widgetName+=" , "+siteAllAvailableWidget?.widget_name;
                                widgetType=siteAllAvailableWidget?.type;
                            }
                            return null;
                            
                        })
                        siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
                            if(siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[2])){
                                widgetName+=" , "+siteAllAvailableWidget?.widget_name;
                                widgetType=siteAllAvailableWidget?.type;
                            }
                            return null;
                            
                        })
                        siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
                            if(siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[3])){
                                widgetName+=" , "+siteAllAvailableWidget?.widget_name;
                                widgetType=siteAllAvailableWidget?.type;
                            }
                            return null;
                            
                        })
                        siteAllAvailableWidgets.filter((siteAllAvailableWidget:any)=>{
                            if(siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[4])){
                                widgetName+=" , "+siteAllAvailableWidget?.widget_name;
                                widgetType=siteAllAvailableWidget?.type;
                            }
                            return null;
                            
                        })
                     
                if (value.includes('mobile') || value.includes('tablet')) {
                    const device = Object.keys(WidgetDetails.checked)[index];
                    const newDiv = `<div class="internal-widget ${device}" widget_type="${widgetType}" widget_name="${widgetName}">Please note: the contents of this widget are displayed on other devices</div>`;
                    newCurrentElements.splice(data.destination.index, 0, parse(newDiv));
                    newOriginalElements.splice(data.destination.index, 0, parse(value));
                } else {
                    const newValue=`<div class="${parsedValue?.props.className}" widget_type="${widgetType}" widget_name="${widgetName}">${parsedValue?.props.children}</div>`
                    const sectionMainIndex = getSectionMainLocation(newCurrentElements, data.destination.index) as number;
                    newCurrentElements.splice(data.destination.index , 0, parse(newValue));
                    newOriginalElements.splice(data.destination.index , 0, parse(value));
                    setNewElementAdded(sectionMainIndex + 1);
                }
            })
            setCurrentPageElements(newCurrentElements);
            setCurrentPageElementsOriginal(newOriginalElements);
        } else {
            let newCurrentElements = Array.from(currentpageElements);
            let newOriginalElements = Array.from(currentpageElementsOriginal);
            newCurrentElements.splice(data.destination.index, 0, parse(newItem));
            setCurrentPageElements(newCurrentElements);
            newOriginalElements.splice(data.destination.index, 0, parse(newItem));
            setCurrentPageElementsOriginal(newOriginalElements);
        }
        setTasks(tasks);
    };

const handleFileChange = (setImgClicked: any, imgClicked: any, event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
        return;
    } else {
        const temp = URL.createObjectURL(fileObj as any);
        let newImg = imgClicked;
        newImg.target.src = temp;
        setImgClicked(newImg);
    }
}
const onChangeDndItem = (setImgClicked: any, e: any, inputRef: any) => {
    if (e.detail === 2) {
        if ((e.target.className.includes('img') || e.target.nodeName === 'IMG')) {
            setImgClicked(e);
            inputRef.current.click();
        }
    }
}
const getAllWidgetsAvailableByType = (selectedWidgettype: any, siteAllAvailableWidgets: any) => {
    return siteAllAvailableWidgets.filter((widget: any) => (widget?.type === selectedWidgettype));
}
const onClickItem = (selectedWidgettype: any, setAllWidgetsByType: any, siteAllAvailableWidgets: any) => {
    const allWidgetsByType = getAllWidgetsAvailableByType(selectedWidgettype, siteAllAvailableWidgets);
    setAllWidgetsByType(allWidgetsByType);
}

const getStructureElements = (value: any, keyValue: any, fatherKey: string, structureClasses: string, type: string,
    fixed: any, id: string, htmlTemplate: any, htmlWrapper: any,defaultValue:any,tagClassName:any) => {
    if (typeof value === 'object' && value[0]?.tag==="h1") {
        for (const key in value) {
            getStructureElements(value[key]?.tag, value[key]?.label, fatherKey, `/${structureClasses}/${value[key]?.tag}`, type, fixed, id, htmlTemplate, htmlWrapper,defaultValue[key],tagClassName[key]?.class);
        }
    } 
    else if (value[0]?.tag==="p"){
        for (const key in value) {
            // from here I am taking the className
            getStructureElements(value[key]?.tag, value[key]?.label, fatherKey, `/${structureClasses}/${value[key]?.tag}`, type, fixed, id, htmlTemplate, htmlWrapper,defaultValue[key],tagClassName[key]?.class);
        }
    }else {
        listElements.push({
            "element": keyValue, "name": fatherKey, "type": type, "fixed_structure": fixed,
            "defaultValue": value, "structureClasses": structureClasses, "id": id, "htmlTemplate": htmlTemplate, "htmlWrapper": htmlWrapper ,"value":value , "tagClassName":tagClassName
        });
    }

    return listElements; // Return listElements after all recursive calls
}


const getElementsInPageContent = (structureMockupJson: string) => {
    const elmentsToEdit = [] as any;
    if (structureMockupJson) {
        // eslint-disable-next-line
        JSON.parse(structureMockupJson)?.structure?.map((value: any, index: any) => {
            getStructureElements(value?.content, index, value?.content_wrapper?.class, value?.content_wrapper?.class, value?.content_wrapper?.tag, value?.fixed_structure, value?.id, value?.label, value?.htmlWrapper,value?.content,value?.content);
            elmentsToEdit.push(listElements as any);
            listElements = [];
        })
    }

    return elmentsToEdit;
}

const createTaskToDrag = (value: any) => {
    return {
        "title": value.defaultValue,
        "id": `${value.element}-Titles-${Math.random()}`,
        "element": value.element,
        "name": value.htmlTemplate,
        "type": value.tag,
        "defaultValue": value.defaultValue,
        "fixed_structure": value.fixed_structure
    }
}
const createTaskTextToDrag = (value: any) => {
    return {
        "title": value.defaultValue,
        "id": `${value.element}-Texts-${Math.random()}`,
        "element": value.element,
        "name": value.htmlTemplate,
        "type": value.tag,
        "defaultValue": value.defaultValue,
        "fixed_structure": value.fixed_structure
    }
}
const createTaskToDragStructuredElements = (value: any) => {
    return {
        "title": value.defaultValue,
        "id": `${value[0].name}-Structure Elements-${Math.random()}`,                                                                                                                     
        "element": value[0].element,
        "name": value[0].htmlTemplate,
        "type": value[0].type,
        "defaultValue": value.defaultValue,
        "fixed_structure": value[0].fixed_structure
    }
}
const createTask = (value: any) => {
    let item = {} as any;
    item = createTaskToDrag(value);
    return item;
}
const createTaskText = (value: any) => {
    let item = {} as any;
    item = createTaskTextToDrag(value);
    return item;
}
const createTaskForWidgetToDrag = (widget: any, index: any) => {
    return {
        "title": `[render_widget shared_uid='${widget.shared_uid}']`,
        "id": `${index}-Widgets-${Math.random()}`,
        "element": widget.type,
        "name": widget.widget_name,
        "type": widget.type,
        "defaultValue": "Widget",
        "fixed_structure": false
    }
}
const getWidgetsTasks = (siteAllAvailableWidgets: any) => {
    let AllAvaialbeWidgets = [] as any
    // eslint-disable-next-line
    siteAllAvailableWidgets?.map((widget: any, index: any) => {
        if ((AllAvaialbeWidgets?.filter((value: any) => (value.type === widget.type))).length === 0) {
            AllAvaialbeWidgets.push(createTaskForWidgetToDrag(widget, index));
        }
    })
    return AllAvaialbeWidgets;
}
const getHtmlStructure = (detailedElement: any, type: string, tagClassName: any) => {
    // here from the tagClassName I am getting the classname of P and UL
    let htmlStructure = "";
    let closingTags = "";
    // eslint-disable-next-line
    detailedElement?.map((value: any, index: any) => {
        if (htmlStructure?.length === 0) {
            // first tag start
            closingTags = `</${type}>`;
            htmlStructure = `<${type} class='${value}'>`;
        } else {
            let classTag = value?.split(" ")[0];
            let className = value?.split(" ")[1];
            closingTags = `</${classTag}> ` + closingTags;
                // for showing unordered list with short className
              if(classTag === 'ul' && tagClassName === 'ulShort'){
                htmlStructure += ` <${classTag} class='ulShort'><li>default Value</li>`
                closingTags = `</ol>` + closingTags;
            } else if (classTag === 'ul') {
                // for showing unordered list
                htmlStructure += ` <${classTag}><li>default Value</li>`;
                closingTags = `</ul>` + closingTags;
            } else if (classTag === 'ol') {
                // for showing ordered list
                htmlStructure += ` <${classTag}><li>default Value</li>`;
                closingTags = `</ol>` + closingTags;
            } else if (classTag === 'p' && tagClassName === 'pShort') {
                // for p tag with pShort class name
                if (index === detailedElement.length - 1) {
                    // last element - reached element
                    htmlStructure += ` <${classTag} class='pShort'>default Value`;
                } else {
                    htmlStructure += ` <${classTag} class='pShort'>`;
                }
            } else {
                if (index === detailedElement.length - 1) {
                    // last element - reached element
                    htmlStructure += ` <${classTag}> default Value`;
                } else {
                    htmlStructure += ` <${classTag} class='${className}'>`;
                }
            }
        }
    });

    htmlStructure += closingTags;
    return htmlStructure;
}


const createDetailedElement = (element: any) => {
    const elementsDetailed = [] as any;
    // eslint-disable-next-line
    element?.structureClasses?.split("/")?.map((item: any) => {
        if (item?.length > 0) {
            elementsDetailed.push(item);
        }
    })
    return elementsDetailed;
}
// const createDetailedElementsFixedStructure = (elements: any) => {
//     const elementsDetailed = [] as any;
//     // eslint-disable-next-line
//     elements?.map((value: any, index: number) => {
//         elementsDetailed[index] = [] as any;
//         // eslint-disable-next-line
//         value?.structureClasses?.split("/").map((item: any) => {
//             if (item?.length > 0) {
//                 elementsDetailed[index].push(item);
//             }
//         })
//     });
//     return elementsDetailed;
// }
// const getHtmlStructureFixedStructrue = (detailedElements: any, type: string) => {
//     let firstTimeChecked = 1;
//     let htmlStructure = "";
//     let closingTags = [] as any;
//     const element1 = detailedElements[0];
//     let elementsIndex = 1;
//     for (let index = 1; index < detailedElements.length; index++) {
//         // eslint-disable-next-line
//         element1?.map((value: any, index2: any) => {
//             if (htmlStructure?.length === 0) {
//                 // first tag start
//                 closingTags.push(`</${type}>`);
//                 htmlStructure = `<${type} class='${value}'>`;
//             } else {
//                 if (value === detailedElements[index][index2]) {
//                     // elements still same hirerchy
//                     if (firstTimeChecked) {
//                         // still didnt add the elements
//                         let classTag = value?.split(" ")[0];
//                         let className = value?.split(" ")[1];
//                         closingTags.push(`</${classTag}>`);
//                         htmlStructure = htmlStructure + ` <${classTag} class='${className}'>`;
//                     }
//                 } else {
//                     if (index2 <= elementsIndex) {
//                         // reached element not in hirerchy - new hirerchy
//                         //first close last tag
//                         htmlStructure = htmlStructure + closingTags[index2];
//                         //adding next element
//                         let classTag = detailedElements[index][index2]?.split(" ")[0];
//                         let className = detailedElements[index][index2]?.split(" ")[1];
//                         closingTags.push(`</${classTag}>`);
//                         htmlStructure = htmlStructure + ` <${classTag} ${className ? `class=${className}` : ''}> default Value </${classTag}>`;
//                     } else {
//                         // still same hirerchy but sub element different
//                         if (firstTimeChecked) {
//                             let classTag = value?.split(" ")[0];
//                             let className = value?.split(" ")[1];
//                             htmlStructure = htmlStructure + ` <${classTag} ${className ? `class=${className}` : ''}> default Value </${classTag}>`;
//                         }
//                         // from second array
//                         let classTag = detailedElements[index][index2]?.split(" ")[0];
//                         let className = detailedElements[index][index2]?.split(" ")[1];
//                         htmlStructure = htmlStructure + ` <${classTag} ${className ? `class=${className}` : ''}> default Value </${classTag}>`;
//                     }
//                 }
//             }
//         });
//         elementsIndex++;
//         firstTimeChecked = 0;
//     };
//     for (let index = closingTags.length - 1; index >= 0; index--) {
//         htmlStructure = htmlStructure + closingTags[index];
//     }
//     return htmlStructure;
// } written by ortal but I did not found any utilization of this function this is why I commented out this
const getDateTodayFormat = () => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    let date = new Date();
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    const newDate = `${month} ${year}`;
    return newDate;
}
const getDateTodayShortCodeTwoElements = (values: any) => {
    let test = "test "
    const defaultValue = `<section class='main date internal-widget'><h1>${test}</h1><div class='date-today' contenteditable='false'>${getDateTodayFormat()}</div></section>`;
    return defaultValue;
}
// const getDateTodayShortCodeOneElement = (values: any) => {
//     const type = values[0]?.type;
//     const className = values[0]?.name;
//     const defaultValue = `<${type} class='${className}'><div class="date-today">${getDateTodayFormat()}</div></${type}>`
//     return defaultValue;
// }
const updateTasks = (tasks: any, setTasks: any, pageStructureStyle: any, siteAllAvailableWidgets: any) => {
    let newTasks = tasks;
    let newPageElements = [] as any;
    let newTextElements = [] as any;
    let newStructuredElements = [] as any;
    let newImageElements = [] as any;

    // eslint-disable-next-line
    pageStructureStyle?.map((values: any) => {
        if (values[0]?.fixed_structure && values[0]?.class !== 'wrpr' && !values[0]?.name?.includes('htmlTemplate')) {
            if (values[0].name.includes("main date internal-widget")) {
                if (Object.values(values[0]?.defaultValue[1]).length >= 1) {
                    values.defaultValue = getDateTodayShortCodeTwoElements(values);
                } 
                newStructuredElements.push(createTaskToDragStructuredElements(values));

            } 
                // const detailedElements = createDetailedElementsFixedStructure(values); 
                // values.defaultValue = getHtmlStructureFixedStructrue(detailedElements, values[0]?.type);
                // newStructuredElements.push(createTaskToDragStructuredElements(values));
            
        }
        else if(!values[0]?.fixed_structure && values[0]?.name?.includes("Image") && values[0]?.type?.includes("img")){
            if(values[0].name.includes("Image")){
                values.defaultValue=values[0].name;
                values[0].element=values[0].name;
                newImageElements.push(createTaskToDragStructuredElements(values))
            }        
        }
        else if(!values[0]?.fixed_structure && values[0]?.htmlTemplate==="Texts"){
            // eslint-disable-next-line
            values.map((value:any)=>{
                const detailedElement = createDetailedElement(value);
                value.defaultValue = getHtmlStructure(detailedElement, value?.type, value?.tagClassName);
                newTextElements.push(createTaskText(value));
            })
        }
        else {            // eslint-disable-next-line
            values.map((value: any) => {
                if (!(value?.name?.includes("internal-widget")) && !(value?.name?.includes("external-widget")) && !values[0]?.fixed_structure && value?.htmlWrapper!==true && values[0]?.htmlTemplate==="Titles") {
                    const detailedElement = createDetailedElement(value);
                    value.defaultValue = getHtmlStructure(detailedElement, value?.type, value?.tagClassName);
                    newPageElements.push(createTask(value));
                }
            });
        }
        
    })
    if (newPageElements?.length > 0) {
        newTasks[newPageElements[0]?.name] = [] as any;
        newTasks[newPageElements[0]?.name] = newPageElements;
    }
    if (newTextElements?.length > 0) {
        newTasks[newTextElements[0]?.name] = [] as any;
        newTasks[newTextElements[0]?.name] = newTextElements;
    }
    if (newStructuredElements?.length > 0) {
        newTasks[newStructuredElements[0]?.name] = [] as any;
        newTasks[newStructuredElements[0]?.name] = newStructuredElements;
    }
    if (siteAllAvailableWidgets?.length > 0) {
        newTasks['Widgets'] = [] as any;
        newTasks['Widgets'] = getWidgetsTasks(siteAllAvailableWidgets);
    }
    if(newImageElements?.length > 0) {
        newTasks[newImageElements[0]?.name] = [] as any;
        newTasks[newImageElements[0]?.name] = newImageElements;
    }
    setTasks(newTasks);
}
const isStructured = (className: string, pageElements: any) => {
    let result;
    if (className !== undefined) {
        // eslint-disable-next-line
        return pageElements?.filter((element: any) => {
            if (element[0]?.name === className) {
                result = element?.filter((value: any) => (value?.element && value?.fixed_structure))
            }
        })
    }
    return result;
}
const isElementhtmlTemplate = (className: string, pageBuildingStructure: any) => {
    const result = pageBuildingStructure?.filter((value: any) => (value[0]?.name === className && value[0]?.htmlTemplate === true && value[0]?.fixed_structure === true))
    return result;
}
const updateWidgetsInPageElements = (
    pageElementsFromUrl: any, 
    pageElements: any, 
    pageBuildingStructure: any,
    siteAllAvailableWidgets: any
  ) => {
    let newpageElements = pageElementsFromUrl as any;
    const internalWidgetsPageUrl = [] as any;
    const externalWidgetsPageUrl = [] as any;
    let internalWidgetsIndex = 0;
    let externalWidgetsIndex = 0;
  
    if (!pageElements || !pageElements.length) {
      return pageElementsFromUrl;
    }
  
    pageElementsFromUrl?.forEach((value: any) => {
      if (
        (value?.props?.className?.includes("internal-widget") && 
         value?.props?.className?.includes("sponsor-template")) || 
        value?.props?.className?.includes("internal-widget") || 
        isStructured(value?.props?.className, pageBuildingStructure)?.length > 0 || 
        isElementhtmlTemplate(value?.props?.className, pageBuildingStructure)?.length > 0
      ) {
        internalWidgetsPageUrl.push(value);
      } else if (value?.props?.className?.includes("external-widget")) {
        externalWidgetsPageUrl.push(value);
      }
    });
  
    pageElements?.forEach((value: any, index: number) => {
      if (
        (value?.props?.className?.includes("internal-widget") && 
         value?.props?.className?.includes("sponsor-template")) || 
        value?.props?.className?.includes("internal-widget") || 
        isStructured(value?.props?.className, pageBuildingStructure)?.length > 0 || 
        isElementhtmlTemplate(value?.props?.className, pageBuildingStructure)?.length > 0
      ) {
        if (
          value.props?.className === 'internal-widget mobile' ||
          value?.props?.className === 'internal-widget tablet' ||
          value?.props?.className === 'internal-widget desktop'
        ) {
          const uidRegex = /shared_uids?='([^']*)'/;
          let filteredSharedUids = [] as any;
          let widgetName = "" as string;
          let widgetType = "" as string;
          const match = value?.props?.children.match(uidRegex);
          if (match) {
            filteredSharedUids = match[1].split(',').map((uid: any) => uid.split(':')[0]);
          }
  
          siteAllAvailableWidgets.forEach((siteAllAvailableWidget: any) => {
            if (siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[0])) {
              widgetName = siteAllAvailableWidget?.widget_name;
              widgetType = siteAllAvailableWidget?.type;
            }
          });
  
          siteAllAvailableWidgets.forEach((siteAllAvailableWidget: any) => {
            if (siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[1])) {
              widgetName += " , " + siteAllAvailableWidget?.widget_name;
              widgetType = siteAllAvailableWidget?.type;
            }
          });

          siteAllAvailableWidgets.forEach((siteAllAvailableWidget: any) => {
            if (siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[2])) {
              widgetName += " , " + siteAllAvailableWidget?.widget_name;
              widgetType = siteAllAvailableWidget?.type;
            }
          });

          siteAllAvailableWidgets.forEach((siteAllAvailableWidget: any) => {
            if (siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[3])) {
              widgetName += " , " + siteAllAvailableWidget?.widget_name;
              widgetType = siteAllAvailableWidget?.type;
            }
          });

          siteAllAvailableWidgets.forEach((siteAllAvailableWidget: any) => {
            if (siteAllAvailableWidget.shared_uid.includes(filteredSharedUids[4])) {
              widgetName += " , " + siteAllAvailableWidget?.widget_name;
              widgetType = siteAllAvailableWidget?.type;
            }
          });
  
          let childrenNote = "Please note: the contents of this widget are displayed on other devices";
          const newValue = {
            children: value?.props?.className.includes("desktop") ? 
                      internalWidgetsPageUrl[internalWidgetsIndex]?.props?.children : childrenNote,
            className: newpageElements[index]?.props?.className,
            widget_type: widgetType,
            widget_name: widgetName
          };
  
          if (React.isValidElement(newpageElements[index])) {
            newpageElements[index] = React.cloneElement(newpageElements[index], newValue);
          } else {
            console.error("Invalid React element at index", index, newpageElements[index]);
          }
          internalWidgetsIndex++;
        } else {
          if (React.isValidElement(internalWidgetsPageUrl[internalWidgetsIndex])) {
            newpageElements[index] = internalWidgetsPageUrl[internalWidgetsIndex];
          } else {
            console.error("Invalid React element at internalWidgetsPageUrl index", internalWidgetsIndex, internalWidgetsPageUrl[internalWidgetsIndex]);
          }
          internalWidgetsIndex++;
        }
      } else if (value?.props?.className?.includes("external-widget")) {
        if (React.isValidElement(externalWidgetsPageUrl[externalWidgetsIndex])) {
          newpageElements[index] = externalWidgetsPageUrl[externalWidgetsIndex];
        } else {
          console.error("Invalid React element at externalWidgetsPageUrl index", externalWidgetsIndex, externalWidgetsPageUrl[externalWidgetsIndex]);
        }
        externalWidgetsIndex++;
      }
    });
  
    return newpageElements;
  };
  

const parseElementsToJson = (elements: any) => {
    // Function to replace dates with shortcodes
    const replaceDates = (html: string, regex: RegExp, replacement: string, context?: any) => {
        return html.replace(regex, (match) => {
            if (context && context.locale === "US") {
                // Assuming US locale uses mm/dd/yyyy
                // eslint-disable-next-line
                const [month, day, year] = match.split("/");
                if (parseInt(month) > 12) {
                    return match; // Invalid date, skip replacement
                }
                return replacement.replace('d/m/Y', 'd/m/Y');
            } else {
                // Assuming non-US locale uses dd/mm/yyyy
                // eslint-disable-next-line
                const [day, month, year] = match.split("/");
                if (parseInt(month) > 12) {
                    return match; // Invalid date, skip replacement
                }
                return replacement;
            }
        });
    };

    // Process each element
    const parsedElementsArr = elements.map((value: any) => {
        // Render element to HTML string
        let elementsHtml = ReactDOMServer.renderToString(value);
        elementsHtml = elementsHtml.replace(/&#x27;/g, "'");
        elementsHtml = elementsHtml.replace(/\t/g, "");

        // Check and replace dates within <span class="sc_date">
        if (/<span class="sc_date">/.test(elementsHtml)) {
            elementsHtml = elementsHtml.replace(/<span class="sc_date">([\s\S]*?)<\/span>/g, (match, innerHtml) => {
                innerHtml = replaceDates(innerHtml, /\b\d{2}\/\d{2}\/\d{4}\b/gi, "[date-today format='d/m/Y']");
                innerHtml = replaceDates(innerHtml, /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}\b/gi, "[date-today format='M Y']");
                innerHtml = replaceDates(innerHtml, /\b\d{1,2}(st|nd|rd|th) (Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, "[date-today]");
                innerHtml = replaceDates(innerHtml, /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, "[date-today format='F Y']");
                innerHtml = replaceDates(innerHtml, /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{1,2}, \d{4}\b/gi, "[date-today format='F j, Y']");
                innerHtml = replaceDates(innerHtml, /\b\d{4}\b/gi, "[date-today format='Y']");
                return `<span class="sc_date">${innerHtml}</span>`;
            });
        }

        // Check and replace dates within <span class="sc_intl_date">
        if (/<span class="sc_intl_date">/.test(elementsHtml)) {
            elementsHtml = elementsHtml.replace(/<span class="sc_intl_date">([\s\S]*?)<\/span>/g, (match, innerHtml) => {
                innerHtml = replaceDates(innerHtml, /\b\d{2}\/\d{2}\/\d{4}\b/g, "[intl-date-today format='dd/MM/YYYY']");
                innerHtml = replaceDates(innerHtml, /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}\b/gi, "[intl-date-today format='M Y']");
                innerHtml = replaceDates(innerHtml, /\b\d{1,2}(st|nd|rd|th) (Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, "[intl-date-today]");
                innerHtml = replaceDates(innerHtml, /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, "[intl-date-today format='F Y']");
                innerHtml = replaceDates(innerHtml, /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{1,2}, \d{4}\b/gi, "[intl-date-today format='F j, Y']");
                innerHtml = replaceDates(innerHtml, /\b\d{4}\b/gi, "[intl-date-today format='Y']");        
                return `<span class="sc_intl_date">${innerHtml}</span>`;
            });
        }

        return elementsHtml;
    }).join('');

    return parsedElementsArr;
};


const isDateTodayFormat = (elementsToCheck: any) => {
    let flag = -1;

    if (Array.isArray(elementsToCheck) && elementsToCheck.length > 0) {
        elementsToCheck.forEach((element: any, index: number) => {
            if (element?.props?.children && element?.props?.className?.includes("date-today")) {
                flag = index;
            }
        });
    } else if (elementsToCheck?.props?.className?.includes("date-today")) {
        flag = 0;
    }

    return flag;
};

const getDateFormatParsed = () => {
    const value = `<div class="date-today">[date-today format='F Y']</div>`;
    const newValue = parse(value);
    return newValue;
}
const updateDateTodayShortCode = (newElement: any) => {
    let clonedObject = { ...newElement };
    let index = isDateTodayFormat(newElement?.props?.children);
    let newChildrens = [] as any;

    if (newElement?.props?.children?.length > 0 && typeof (newElement?.props?.children) !== 'string') {
        // eslint-disable-next-line
        newElement?.props?.children?.map((child: any) => {
            newChildrens.push(child);
        })
        newChildrens[index] = getDateFormatParsed();
        const newProps = { ...newElement?.props, children: newChildrens }
        clonedObject = { ...newElement, props: newProps };
    } else {
        newChildrens = getDateFormatParsed();
    }
    const newProps = { ...newElement?.props, children: newChildrens }
    clonedObject = { ...newElement, props: newProps };
    return clonedObject;
}

const parseRelevantElementsToJson = (currentpageElements: any, currentpageElementsOriginal: any) => {
    let newCurrentPageElements = [] as any;
    // eslint-disable-next-line
    currentpageElements?.map((element: any, index: number) => {
        if ((!element?.props?.className?.includes("htmlTemplate"))) {
            // TODO: check what hapened after editing existed shortcode of desktop
            if (element?.props?.className?.includes("internal-widget")) {
                if (element?.props?.className?.includes("main date")) {
                    newCurrentPageElements[index] = updateDateTodayShortCode(element);
                } else {
                    newCurrentPageElements[index] = currentpageElementsOriginal[index];
                }
            } else {
                newCurrentPageElements[index] = element;
            }
        }
    })
    const parsedElementsToJson = parseElementsToJson(newCurrentPageElements);
    return parsedElementsToJson;
}

const onCreatePage = (pageUrlContent: any, setHtmlWrapperDiv: any, setHeader: any, setHeaderMenu: any ,setFooter: any, setPageUrlComponents: any,
    setCurrentPageElements: any) => {
    let pageElementsFromUrl;
    const result = pageBuildingHelpers.parseHtmlToJsonOnCreatePage(pageUrlContent?.__html) as any;
    if (Object.keys(result[1][0])[0] === 'htmlWrapperElements') {
        // TODO - remove once fixing dcitionary for create page
        setHtmlWrapperDiv('wrpr');
        const htmlWrapperElements = (Object.values(result[1])[0] as any).htmlWrapperElements;
        setHeader(htmlWrapperElements[0]);
        setHeaderMenu(htmlWrapperElements[1]);
        // internal-widget and elements not null 
        pageElementsFromUrl = htmlWrapperElements[2];
        setFooter(htmlWrapperElements[3]);
    } else {
        // all elements for getting from it widgets and structrued elements
        pageElementsFromUrl = result[1];
    }
    setPageUrlComponents(result[0] as any);
    setCurrentPageElements(pageElementsFromUrl);
}
const parsePreviewContentForCurrentChanges = (previewContent: any, pageBuildingStructure: any) => {
    const result = pageBuildingHelpers.parseHtmlToJson(previewContent?.__html, "url", pageBuildingStructure) as any;
    let pageElementsFromUrl;
    if (Object.keys(result[1][0])[0] === 'htmlWrapperElements') {
        const htmlWrapperElements = (Object.values(result[1])[0] as any).htmlWrapperElements;
        // internal-widget and elements not null 
        pageElementsFromUrl = htmlWrapperElements[1];
    } else {
        // all elements for getting from it widgets and structrued elements
        pageElementsFromUrl = result[1];
    }
    return pageElementsFromUrl;
}
const updateWidgetChangedWithPreviewCurrentChanges = (previewCurrentChanges: any, pageBuildingStructure: any, currentpageElements: any,
    setCurrentPageElements: any) => {
    const pageElementsFromUrl = parsePreviewContentForCurrentChanges(previewCurrentChanges?.previewContent, pageBuildingStructure);
    const index = previewCurrentChanges?.widgetDetails;
    if (pageElementsFromUrl[index]?.props?.className?.includes("internal-widget desktop")) {
        const cloneCurrentPageElements = Array.from(currentpageElements);
        cloneCurrentPageElements[index] = pageElementsFromUrl[index];
        setCurrentPageElements(cloneCurrentPageElements);
    }
}
const parseContentFromPreview = (pageUrlContent: any, resultStructure: any, setHeader: any,setHeaderMenu:any, setFooter: any, setPageUrlComponents: any) => {
    const result = pageBuildingHelpers.parseHtmlToJson(pageUrlContent?.__html, "url", resultStructure) as any;
    let pageElementsFromUrl;
    if (Object.keys(result[1][0])[0] === 'htmlWrapperElements') {
        const htmlWrapperElements = (Object.values(result[1])[0] as any).htmlWrapperElements;
        setHeader(htmlWrapperElements[0]);
        setHeaderMenu(htmlWrapperElements[1])
        // internal-widget and elements not null 
        pageElementsFromUrl = htmlWrapperElements[2];
        setFooter(htmlWrapperElements[3]);
    } else {
        // all elements for getting from it widgets and structrued elements
        pageElementsFromUrl = result[1];
    }
    // head + body (scripts and style)
    setPageUrlComponents(result[0] as any);
    return pageElementsFromUrl;
}
const onUpdateCreatePage = (setCurrentPageElementsOriginal: any, pageDictionary: any, pageContent: any, pageUrlContent: any, setHeader: any,setHeaderMenu:any,
    setFooter: any, setPageUrlComponents: any, setPageStructureStyle: any, pageStructureDesign: any, setPageBuildingStructure: any,
    tasks: any, setTasks: any, siteAllAvailableWidgets: any, setCurrentPageElements: any) => {
    const resultStructure = pageBuildingHelpers.getElementsInPageContent(pageDictionary);
    // check if page isn't empty
    if (pageContent?.post_content || pageUrlContent?.__html) {
        let pageElements = pageBuildingHelpers.parseHtmlToJson(pageContent?.post_content, "") as any;
        let clonedPageElements = [] as any;
        // for saving original elements before updating it from preview
        // if there is a single page element in the page then converting it into an array form otherwise this was causing error
        if(pageElements && !Array.isArray(pageElements) && Object.keys(pageElements).length > 0 )
              
                pageElements =[pageElements];
              
              clonedPageElements = [...pageElements];

        const pageElementsFromUrl = parseContentFromPreview(pageUrlContent, resultStructure, setHeader,setHeaderMenu, setFooter, setPageUrlComponents);
        setPageStructureStyle(pageBuildingHelpers.parseHtmlToJson(pageStructureDesign, "css", "") as any);
        setPageBuildingStructure(resultStructure);
        pageBuildingHelpers.updateTasks(tasks, setTasks, resultStructure, siteAllAvailableWidgets);
        let newPageElements = pageBuildingHelpers.updateWidgetsInPageElements(pageElementsFromUrl, clonedPageElements, resultStructure,siteAllAvailableWidgets);
        setCurrentPageElements(newPageElements);
        setCurrentPageElementsOriginal(clonedPageElements);
    } else {
        setPageStructureStyle(pageBuildingHelpers.parseHtmlToJson(pageStructureDesign, "css", "") as any);
        setPageBuildingStructure(resultStructure);
        pageBuildingHelpers.updateTasks(tasks, setTasks, resultStructure, siteAllAvailableWidgets);
        setCurrentPageElements([]);
        setCurrentPageElementsOriginal([]);
    }
}
// eslint-disable-next-line
export const pageBuildingHelpers = {
    parseHtmlToJson,
    checkshortcodes,
    onDragEnd,
    handleFileChange,
    onChangeDndItem,
    getElementsInPageContent,
    updateTasks,
    onClickItem,
    updateWidgetsInPageElements,
    parseElementsToJson,
    parseRelevantElementsToJson,
    parseHtmlToJsonOnCreatePage,
    onCreatePage,
    onUpdateCreatePage,
    onEditWidget,
    updateWidgetChangedWithPreviewCurrentChanges,
};
