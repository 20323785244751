import hostname from "../../../services/api/config.host";
import { validPassword } from "../../Login/logic/validation";
export const confirmPassword = async (
  uid: string | undefined,
  token: string | undefined,
  newPassword: string,
  reNewPassword: string
): Promise<string> => {
  const newPasswordValidationResult = validPassword(newPassword);
  const reNewPasswordValidationResult = validPassword(reNewPassword);
  if (newPasswordValidationResult) {
    throw new Error("Invalid new password: " + newPasswordValidationResult);
  } else if (reNewPasswordValidationResult) {
    throw new Error("Invalid re-entered password: " + reNewPasswordValidationResult);
  }
  if (!newPassword || !reNewPassword) {
    throw new Error("Both fields are required");
  }
  if (newPassword !== reNewPassword) {
    throw new Error("Passwords do not match");
  }
  if (!uid || !token) {
    throw new Error("Invalid parameters for password confirmation");
  }
  try {
    const response = await fetch(`${hostname}/aladdin_api/v1/users/reset_password_confirm/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: uid,
        token: token,
        new_password: newPassword,
        re_new_password: reNewPassword,
      }),
    });
    if (response.ok) {
      return "Password reset confirmed successfully";
    } else {
      throw new Error("Password reset confirmation failed. Please try again later.");
    }
  } catch (error) {
    throw new Error(`Error during password reset confirmation: ${error}`);
  }
};
