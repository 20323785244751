import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Pagination,
  Stack,
  Switch,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Link from '@mui/material/Link';
import "../../CompetitorsPageSpeed.css"
import { PsiCompetitors } from "../../../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedEndPointLogic";
import { Snackbar, Alert } from "@mui/material";
import SimpleBackdrop from "../../../../../BackDrop/BackDrop";

interface Props {
  tableHeadings: { [key: string]: string };
  psiRequestRows: any[];
  userToken: string;
  selectedSiteUid: any;
  setRefresh: any;
  selectedCompetitorName: any;
  selectedStatus: any;
  selectedUser: any;
  selectedDate: any;
  selectedCompetitorURL: any;
}

const CompetitorsPageScanRequestsTable = ({ tableHeadings, psiRequestRows, userToken, selectedSiteUid, selectedCompetitorName,
  selectedStatus, selectedUser, selectedDate, selectedCompetitorURL ,setRefresh
}: Props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [, setMessage] = useState("") as any;
  const [, setPsiSharedUid] = useState("");
  const [, setStatus] = useState("") as any;

  const [switchStates, setSwitchStates] = useState<boolean[]>(
    psiRequestRows.map((row) => row.active === true)
  )
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  // const [loadingStates] = useState<boolean[]>(Array(psiRequestRows.length).fill(false));
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>(""); // Column to sort by
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>(
    () =>
      psiRequestRows.reduce((acc, row) => {
        acc[row.shared_uid] = false; // Initialize all rows as not loading
        return acc;
      }, {} as { [key: string]: boolean })
  );



  const filteredLogs = (psiRequestRows || []).filter((data: any) => {
    const competitiorName = Array.isArray(selectedCompetitorName) && selectedCompetitorName.length !== 0
      ? selectedCompetitorName.includes(data?.competitor_name)
      : true; // If no Competitor Name is selected, treat it as a match

    const status = Array.isArray(selectedStatus) && selectedStatus.length !== 0
      ? selectedStatus[0].includes(data?.active)
      : true;

    const user = Array.isArray(selectedUser) && selectedUser.length !== 0
      ? selectedUser.includes(data?.created_by)
      : true;

    const url = Array.isArray(selectedCompetitorURL) && selectedCompetitorURL.length !== 0
      ? selectedCompetitorURL?.includes(data?.request_url)
      : true;

    const isDateInRange = Array.isArray(selectedDate) && selectedDate.length === 2 && selectedDate[0] && selectedDate[1]
      ? selectedDate[0] === selectedDate[1] // If both dates are the same, check for equality
        ? new Date(data?.last_run).toDateString() === new Date(selectedDate[0]).toDateString()
        : new Date(data?.last_run) >= new Date(selectedDate[0]) &&
        new Date(data?.last_run) <= new Date(new Date(selectedDate[1]).setHours(23, 59, 59, 999))
      : true;

    return competitiorName && status && user && isDateInRange && url;
  });


  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };



  const sortedPsiRequests = [...filteredLogs].sort((a, b) => {
    if (orderBy === "scan_status") {
      const aStatus = a.active ? 1 : 0; // Convert boolean to number
      const bStatus = b.active ? 1 : 0;
      return order === "asc" ? aStatus - bStatus : bStatus - aStatus;
    }
    // Generic sorting for other columns
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  useEffect(() => {
    const initialSwitchStates = {} as any;
    filteredLogs.forEach(row => {
      initialSwitchStates[row.shared_uid] = row.active; // Assuming `active` indicates the switch state
    });
    setSwitchStates(initialSwitchStates);
    // eslint-disable-next-line
  }, []);


  const headCells = Object.entries(tableHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSwitchChange = async (index: number, shared_uid: string, isActive: boolean) => {
    // Set the loading state for this row
    setLoadingStates((prevState) => ({
      ...prevState,
      [shared_uid]: true, // Mark the current row as loading
    }));

    try {
      setSwitchStates((prevState: any) => ({
        ...prevState,
        [shared_uid]: !prevState[shared_uid], // Toggle based on the previous state
      }));
      setPsiSharedUid(shared_uid);
      setStatus(isActive);

      // Prepare the data for API call
      const data = { active: isActive };

      // Perform the API call
      if (userToken) {
        await PsiCompetitors.putPsiRequestsOfCompetitors(
          userToken,
          selectedSiteUid,
          shared_uid,
          data,
          setMessage
        );
        setSnackbarMessage("Toggle updated successfully!");
        setSnackbarSeverity("success");
      } else {
        throw new Error("User doesn't have permissions to fetch Psi Competitors data");
      }
    } catch (error) {
      console.error("Error updating Psi Competitors:", error);
      setSnackbarMessage("Error updating toggle!");
      setSnackbarSeverity("error");
    } finally {
      // Reset the loading state for this row
      setLoadingStates((prevState) => ({
        ...prevState,
        [shared_uid]: false, // Mark the current row as not loading
      }));
      setRefresh(true);
      setOpenSnackbar(true);
    }
  };

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  const paginatedpsiRequest = sortedPsiRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isValidUrl = (url: any) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.host + parsedUrl.pathname;
    } catch (error) {
      // If the URL is invalid, log the error or handle it appropriately
      return ""; // Return an empty string or fallback value
    }
  };

  return (
    <>
      <TableContainer className="PageScantablewrap" component={Paper} sx={{ maxHeight: 800 }}>
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(([key, value], index) => (
                <TableCell key={index} align="left">
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : "asc"}
                    onClick={() => handleRequestSort(key)}
                  >
                    {value && typeof value === "string" ? value : ""}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPsiRequests.length === 0 ? <TableRow><TableCell>No Data to Show</TableCell></TableRow> : paginatedpsiRequest
              .map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}>
                    {headCells.map(([key], cellIndex) => (
                      <TableCell key={cellIndex} align="left">
                        {key === 'competitor_name' ? (
                          <>{row[key] ? row[key] : ""}</>
                        ) : key === 'request_url' ? (
                          <Link
                            href={row[key] && isValidUrl(row[key]) ? row[key] : ""}
                            target="_blank"
                            color="secondary"
                          >
                            {row[key] && isValidUrl(row[key])
                              ? new URL(row[key]).host + new URL(row[key]).pathname
                              : ""}
                          </Link>

                        ) : key === 'scan_status' ? (
                          <>
                            {loadingStates[row['shared_uid']] ? (
                              <SimpleBackdrop/> 
                            ) : (
                              <Switch
                                {...label}
                                checked={switchStates[row['shared_uid']]} // Access state using shared_uid
                                onChange={() => handleSwitchChange(rowIndex, row['shared_uid'], !switchStates[row['shared_uid']])}
                                sx={{
                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#2E7D32',
                                  },
                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#2E7D32',
                                  },
                                }}
                              />
                            )}
                            {switchStates[row['shared_uid']] ? 'Active' : 'Inactive'}

                          </>
                        )
                          : key === 'tests_amount' ? (
                            <Link href="#" color="secondary">
                              {row[key] ? row[key] : ""}
                            </Link>
                          ) : key === 'last_run' ? (
                            <>{row[key] ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: undefined,
                              hour12: true
                            }).format(new Date(row[key])) : ""}</>
                          ) : key === 'created' ? (
                            <>{row[key] ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: undefined,
                              hour12: true
                            }).format(new Date(row[key])) : ""}</>

                          ) : key === 'created_by' ? (
                            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                              <Avatar alt={row[key] ? row[key] : ""} src="#" sx={{ width: 24, height: 24 }} />
                              {row[key] ? row[key] : ""}
                            </Stack>
                          ) : (
                            row[key] ? row[key] : ""
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={sortedPsiRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                display: "none",
              },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={Math.ceil(sortedPsiRequests.length / rowsPerPage)}
            page={page + 1}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
            onChange={(event, page) => handleChangePage(event, page - 1)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortedPsiRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: 'hidden-displayed-rows', // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
            }}
          />
        </Stack>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}  // 3 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CompetitorsPageScanRequestsTable;
