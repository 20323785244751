import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import TemplatesFilter from "./TemplatesFilter"
import { Link as RouterLink} from 'react-router-dom';

interface TemplateFilterProps {
  combinedOptions: any;
  oauthTokenHeader: any;
  selectedTemplate: any;
  setSelectedTemplate: any;
  selectedSharedUid: any;
  setSelectedSharedUid: any;
  designTemplatesData: any;
  setDesignTemplatesData: any;
  selectedFeatures:any;
  selectedComponents:any;
  setSelectedFeatures:any;
  setSelectedComponents:any;
}

function TemplatesFilterCard({combinedOptions, oauthTokenHeader, selectedTemplate, setSelectedTemplate, selectedSharedUid, setSelectedSharedUid, designTemplatesData, setDesignTemplatesData, selectedFeatures, selectedComponents, setSelectedFeatures, setSelectedComponents }:TemplateFilterProps) {

  const templates = designTemplatesData?.map((template:any)=> template?.name);

  const clearSelectedTemplate = () => {
    setSelectedTemplate(null); // Clear the selected template
  }

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={2}
        sx={{padding:"16px 16px 0px 16px"}}
      >
        <Autocomplete
          sx={{ width: "300px" }}
          options={templates ? templates : []}
          value={selectedTemplate}
          onChange={(event, newValue:any) => {
            setSelectedTemplate(newValue); // Update state with selected template 
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Template Name"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <TemplatesFilter designTemplatesData={designTemplatesData} clearSelectedTemplate={clearSelectedTemplate} setSelectedFeatures={setSelectedFeatures} setSelectedComponents={setSelectedComponents} selectedFeatures={selectedFeatures} selectedComponents={selectedComponents}/>
          <RouterLink to="create_new_template" state={{
                selectedSharedUid: selectedSharedUid,
                designTemplatesData: designTemplatesData,
                combinedOptions: combinedOptions,
              }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              <Stack flexDirection={"row"} gap={1}>
                Create Template <AddIcon fontSize="small" />
              </Stack>
            </Button>
         </RouterLink>
        </Stack>
      </Stack>
    </>
  );
}
export default TemplatesFilterCard;
