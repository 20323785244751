import { ReactElement, ComponentPropsWithoutRef } from "react";
import "./Page.css";

export interface PageProps extends ComponentPropsWithoutRef<'main'> {

}

export default function Page({
    children,
    ...rest
}: PageProps): ReactElement {
    return (
        <main {...rest}>
            {children}
        </main>
    )
}