import { createSlice } from '@reduxjs/toolkit';
export const assetManagementSlice = createSlice({
  name: 'assetManagement',
  initialState: {
    assetManagementData: null,
  },
  reducers: {
    setAssetManagementData: (state, action) => {
      state.assetManagementData = action.payload;
    }
  },
});
export const {
  setAssetManagementData,
} = assetManagementSlice.actions;
// We export this that we could get the user state (loggedIn/loggedOut)
// export const selectUser = (state: { user: { user: any; }; }) => state.user.user;
export const selectAssetManagementData = (state: any) => state.assetManagement.assetManagementData;
export default assetManagementSlice.reducer;