import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import CreateSequence from "../Modal/CreateSequence";
import SequenceFilterItems from "./SequenceFilterItems";
import "./SequenceFilters.css";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";

interface ChainsFiltersProps {
  updatedArray:any
  etlSequence: any;
  processList: any;
  selectedFrequency: any;
  setSelectedFrequency: any;
  selectedStatus: any;
  setSelectedStatus: any;
  setPage: any;
  oauthTokenHeader: any;
  selectedRowData: any;
  setSelectedRowData: any;
  selected: any;
  setSelected: any;
  hasGetPermissions: any;
  hasEditPermissions: any;
  setEtlSequence: any;
  rowId: any;
  setRowId: any;
  status: any;
  setStatus: any;
  inputValueStatus: string;
  setInputValueStatus: any;
  selectedChainStatus: string;
  selectedOptionChain: string;
  setSelectedChainStatus: React.Dispatch<React.SetStateAction<string>>;
  selectedChain: string;
  isEditBtnEnabled: any;
  setIsEditBtnEnabled: any;
  setSelectedOptionChain: React.Dispatch<React.SetStateAction<string>>;
  setSelectedChain: React.Dispatch<React.SetStateAction<string>>;
  setReload: any;
  filteredDataSequence: any;
  selectedStatuses: any;
  setSelectedStatuses: any;
  selectedLastRun: any;
  setSelectedLastRun: any;
  selectedLastRunDate: any;
  setSelectedLastRunDate: any;
  selectedFrequencyDate:any;
  setSelectedFrequencyDate:any;
}

const SequenceFilters = ({
  updatedArray,
  etlSequence,
  processList,
  selectedFrequency,
  setSelectedFrequency,
  selectedStatus,
  setSelectedStatus,
  setPage,
  oauthTokenHeader,
  hasGetPermissions,
  hasEditPermissions,
  setEtlSequence,
  status,
  setStatus,
  selectedChainStatus,
  inputValueStatus,
  setInputValueStatus,
  setSelectedOptionChain,
  selectedOptionChain,
  setSelectedChainStatus,
  selectedChain,
  setSelectedChain,
  setReload,
  filteredDataSequence,
  selectedStatuses,
  setSelectedStatuses,
  selectedLastRun,
  setSelectedLastRun,
  selectedLastRunDate,
  setSelectedLastRunDate,
  selectedFrequencyDate,
  setSelectedFrequencyDate,
}: ChainsFiltersProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, ] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, ] = useState("");

  const tableFilterProps = {
    etlSequence,
    updatedArray,
    inputValueStatus,
    selectedFrequency,
    setSelectedFrequency,
    selectedStatus,
    setSelectedStatus,
    setPage,
    status,
    setStatus,
    selectedChain,
    setSelectedChain,
    setInputValueStatus,
    selectedChainStatus,
    setSelectedChainStatus,
    selectedOptionChain,
    setSelectedOptionChain,
    filteredDataSequence,
    selectedStatuses,
    setSelectedStatuses,
    selectedLastRun,
    setSelectedLastRun,
    selectedFrequencyDate,
    setSelectedFrequencyDate,
    selectedLastRunDate,
    setSelectedLastRunDate,
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  };
  const sequenceData = etlSequence?.data?.map(
    (sequence: any) => sequence?.name
  );
  return (
    <>
      {renderSnackbar()}
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <Paper className="chainsFiltersWrap">
          <div style={{ padding: "16px" }}>
            {/* Heading */}
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={8}>
                <Typography variant="h3" sx={{ fontSize: "24px" }}>
                  ETL Sequences
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Manage sequence settings or add new sequences.
                </Typography>
              </Grid>
            </Grid>
            {/* Filter part */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  marginTop: "5px",
                }}
              >
                {/*Queue Name */}
                <Autocomplete
                  options={sequenceData ? sequenceData : []}
                  value={selectedChain}
                  onChange={(event, newValue: any) => {
                    setSelectedChain(newValue); // Update state with selected sequence
                    setPage(0); // Reset page number to 0
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sequence Name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "300px" }}
                />
                <SequenceFilterItems {...tableFilterProps} />
              </div>
              {/* Action Buttons */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<AddIcon />}
                  onClick={handleOpen}
                >
                  Create New Sequence
                </Button>
                <CreateSequence
                  etlSequence={etlSequence}
                  processList={processList}
                  oauthTokenHeader={oauthTokenHeader}
                  hasGetPermissions={hasGetPermissions}
                  hasEditPermissions={hasEditPermissions}
                  setEtlSequence={setEtlSequence}
                  openModal={openModal}
                  onCloseModal={handleClose}
                  setReload={setReload}
                />
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default SequenceFilters;
