import { endpointsService } from '../../../../services/endPoints.services';
import { setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import store from '../../../../store';


async function getDesignTemplates(oauthTokenHeader: string, website_shared_uid: string,setData:any,setError:any) {
  try {
    const response = await endpointsService.getAllTemplates(oauthTokenHeader, website_shared_uid);
    if (!response.status || response.status !== 200) {
       /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Quarterly goals', type: 'error' }))); */
    } else {
      setData(response?.data?.data);
    }
    } catch (e:any) {
    setError(e);
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
};

async function createDesignTemplates(oauthTokenHeader:any, data:any) {
  try {
    const response = await endpointsService.createTemplate(oauthTokenHeader, data);
    if (!response.status || response.status !== 201) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating Template', type: 'error' }));
    }
    else {
      // store.dispatch(setDisplayErrorSnackBar({ message: 'Template Have Been Created Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
};

async function getWidgetStructures(oauthTokenHeader: string, setWidgetStructures:any) {
  try {
    store.dispatch(setIsLoading(true));
    const response = await endpointsService.getWidgetStructure(oauthTokenHeader);
    if (!response || !response.status || response.status !== 200) {
      throw new Error('Failed to get page content');
    }
    setWidgetStructures(response?.data?.data); // Set the fetched data
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function getLabels(oauthTokenHeader: string, setLabelsContent: any,setErrorAccToPageStatus:any) {
  try {
      const response = await endpointsService.getLabels(oauthTokenHeader);
      if (!response.status || response.status !== 200) {
          setErrorAccToPageStatus([response])
          /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
      } else {
          setLabelsContent(response?.data?.data);
      }
  } catch (e:any) {
      if(e?.status !== undefined){
          store.dispatch(setDisplayErrorSnackBar({ message: 'Unauthorized access for label', type: 'error' }));
      }
      else {
          store.dispatch(setDisplayErrorSnackBar({ message: "Toggle Successfull", type: 'error' }));
      }
  }
}

async function getSingleDesignTemplate(oauthTokenHeader: string, templateUid: string, setDesignTemplatesData: any, setError: any) {
  try {
    const response = await endpointsService.getSingleTemplate(oauthTokenHeader, templateUid);
    if (!response.status || response.status !== 200) {
      // store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Template', type: 'error' }));
    } else {
      setDesignTemplatesData(response?.data?.data);
    }
  } catch(e:any) {
    setError(e);
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function editDesignTemplates(oauthTokenHeader:any, data:any, templateUid:any) {
  try {
    const response = await endpointsService.editTemplate(oauthTokenHeader, data, templateUid);
    if (!response.status || response.status !== 200) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Editing Template', type: 'error' }));
    }
    else {
      // store.dispatch(setDisplayErrorSnackBar({ message: 'Template Have Been Edited Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

export const DesignTemplatesEndPointLogic =  {
  getDesignTemplates,
  createDesignTemplates,
  getWidgetStructures,
  getSingleDesignTemplate,
  editDesignTemplates,
  getLabels
}
