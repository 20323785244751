import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import {
  Alert,
  Collapse,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import "./WebsiteActionTable.css";
import { Box } from "@mui/system";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Link as RouterLink } from "react-router-dom";
import WebsiteAdditionalInfo from "./WebsiteAdditionalInfo/WebsiteAdditionalInfo";

interface Props {
  tableHeadings: { [key: string]: string };
  rows: any[];
}

function WebsiteActionTable({ tableHeadings, rows }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const headCells = Object.entries(tableHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openRow, setOpenRow] = React.useState<number | null>(null);

  const handleRowClick = (index: number) => {
    setOpenRow((prev) => (prev === index ? null : index));
  };

  return (
    <TableContainer
      className="quaterlygoalswrap"
      component={Paper}
      sx={{ marginTop: "16px" }}
    >
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel>
                  {value && typeof value === "string" ? value : ""}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <React.Fragment key={row.cron_id}>
                <TableRow
                  sx={{
                    "& > *": {
                      borderBottom:
                        "1px solid rgba(224, 224, 224, 1) !important",
                    },
                  }}
                >
                  {headCells.map(([key], idx) => (
                    <TableCell key={idx} align="left">
                      {key === "more_info" ? (
                        <TableCell align="left" sx={{ border: "none" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            color="secondary"
                            onClick={() => handleRowClick(index)}
                          >
                            {openRow === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      ) : key === "site_name" ? (
                        <>
                          <Link 
                              href="#" 
                              color="secondary" 
                              sx={{
                                '&:hover': {
                                  color: 'secondary.main', // Keeps the same color as the default, or you can use a custom color
                                  textDecoration: 'underline', // Removes underline if it appears
                                },
                              }}
                            >
                              {row[key] ? row[key] : ""}
                            </Link>
                        </>
                      ): key === "status" ? (
                        <>
                        <Alert
                            variant="outlined"
                            severity="success"
                            icon={row.status === "active" ? <CheckCircleOutlinedIcon sx={{color:"#1E4620"}} fontSize="small"/> : <CancelOutlinedIcon sx={{color:"#5F2120"}} fontSize="small"/>}
                            sx={{
                              display:"flex",
                              alignItems:"center",
                              marginBlock:"8px",
                              padding:"0px 4px", 
                              background: row.status === "active" ? "#EDF7ED" : "#FDEDED", 
                              width:"86px",
                              border: row.status === "active" ? "1px solid #2E7D3280" : "1px solid #D32F2F80",
                              color: row.status === "active" ? "#1E4620" : "#5F2120",
                            }}
                          >
                          {row[key] ? row[key] : ""}
                        </Alert>
                        </>
                      ) : key === "edited_by" ? (
                        <>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar
                              alt="jonathan"
                              src={row.imageUrl || ""}
                              sx={{ width: 24, height: 24 }}
                            >
                              {row[key] ? row[key][0] : "?"}{" "}
                            </Avatar>
                            <Typography fontSize={14}>{row[key] ? row[key] : ""}</Typography>
                          </Stack>
                        </>
                      ) : key === "territory" ? (
                        <>
                          {row[key] ? (
                            <>
                              <Typography fontSize={14}>{row[key]}</Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : key === "quick_actions" ? (
                        <>
                          <Box display="flex" gap={1} paddingLeft={1}>
                            <Tooltip title="Edit" placement="top">
                              <Box>
                                <IconButton size="small"
                                component={RouterLink}
                                to="edit_website"
                                color="secondary"
                                sx={{ whiteSpace: "nowrap" }}
                                >
                                  <CreateOutlinedIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </IconButton>
                              </Box>
                            </Tooltip>
                            <Tooltip title="Setting" placement="top">
                              <IconButton size="small"
                              component={RouterLink}
                              to="website_setting"
                              color="secondary"
                              sx={{ whiteSpace: "nowrap" }}>
                                <TuneOutlinedIcon
                                  fontSize="small"
                                  color="secondary"
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </>
                      ) : key === "pages_in_group" ? (
                        <>
                          <Link href="#" color="secondary">
                            {row[key] ? row[key] : "N/A"}
                          </Link>
                        </>
                      ) : row[key] ? (
                        row[key]
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                    colSpan={Object.keys(tableHeadings).length + 1}
                  >
                    <Collapse
                      in={openRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ padding: "20px 0px" }}>
                        {/* Additional content here */}
                        <WebsiteAdditionalInfo />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel":
              {
                display: "none",
              },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={rows.length}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".css-levciy-MuiTablePagination-displayedRows": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
    </TableContainer>
  );
}

export default WebsiteActionTable;
