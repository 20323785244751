import CompetitorsPageSpeed from '../../../../components/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeed'

const CompetitorsPageSpeedScreen = ({ userToken ,response }: { userToken: any , response : any }) => {
  return (
    <>
    <CompetitorsPageSpeed userToken={userToken} response={response}/>
    </>
  )
}

export default CompetitorsPageSpeedScreen;