import { Autocomplete, Button, Dialog, DialogActions, DialogTitle, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useState } from 'react';

export interface AssignPageGroupProps{
    assignPageGroupDialogOpen:any
    setAssignPageGroupDialogOpen:any
    pageDetails:any
    pageGroups:any
    pageGroupForAssign:any
    assignPageGroup:any
    setPageDetails:any
    setRefresh:any
    setIsCheckboxCheckedArray:any
    isCheckboxCheckedArray:any
}

function AssignPageGroup({isCheckboxCheckedArray,setIsCheckboxCheckedArray,setRefresh,setPageDetails,assignPageGroup,pageGroupForAssign,pageGroups,pageDetails,assignPageGroupDialogOpen,setAssignPageGroupDialogOpen}:AssignPageGroupProps) {
  const [selectPageGroup, setSelectPageGroup] = useState("")
  
  /* Used in cancel */
  const handleClose = () => {
    setAssignPageGroupDialogOpen(false)
  }

const assign = async () => {

 const data = {
    "data": {
        "page_group_id": `${selectPageGroup}`
    }
}

  await assignPageGroup(data)
  await setAssignPageGroupDialogOpen(false)
  await setPageDetails([])
  await setRefresh(true)
}

const handleRemove = (shared_uid:any ,id:any, index:number) => {
  const iii = pageDetails.filter((item:any) => item.id === id)
  setPageDetails(pageDetails.filter((item:any) => item.shared_uid !== shared_uid));
  const updatedCheckboxState = [...isCheckboxCheckedArray];
    updatedCheckboxState[iii[0]?.index] = !updatedCheckboxState[iii[0]?.index];
    setIsCheckboxCheckedArray(updatedCheckboxState);
};

  return (
    <div>
      {/* Dialog/popup after clicking on Assign page group button */}
    <Dialog
      open={assignPageGroupDialogOpen}
      fullWidth
      maxWidth="md"
    >
      <div style={{ padding: "16px 24px", display: "flex", flexDirection: "column" }}>
        <DialogTitle sx={{padding:"0px 0px 16px 0px"}}>Assign Page Group</DialogTitle>
        <DialogTitle sx={{padding:"0px 0px 16px 0px", fontSize:"18px"}}>Selected Pages</DialogTitle>

      {/* table calling here */}
      <TableContainer component={Paper} sx={{ maxHeight: '180px'}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Current group</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageDetails?.map((row:any, index:any) => (
            <TableRow key={index}>
              <TableCell sx={{padding:"0 16px"}}>{row.post_name}</TableCell>
              <TableCell sx={{padding:"0 16px"}}>{row.page_group_name}</TableCell>
              <TableCell sx={{padding:"0 16px"}}>
                <IconButton onClick={() => handleRemove(row?.shared_uid ,row?.id, index)} aria-label="delete">
                  <CancelOutlinedIcon color='secondary' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     {/* DropDown for adding new page group */}
     <Autocomplete
     disabled={pageDetails?.length === 0}
      options={pageGroupForAssign}
      getOptionLabel={(option:any) => option.group_name} // This determines what to display in the dropdown
      onChange={(event, value) => setSelectPageGroup(value ? value.shared_uid : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          label="New Page Group"
          placeholder="Please select a page group from the list."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}
          color="secondary"
        />
      )}
      size="small"
      sx={{ paddingBlock: "24px 0" }}
    />
        <Typography sx={{paddingBlock:"8px 6px", fontSize:"12px", lineHeight:"20px", color:"#0000008F"}}>Can’t find the right group for these pages?</Typography>

        {/* if user can’t find the right group for pages then click on button below */}

        <Button
          sx={{width:"250px", display:"flex", alignItems:'center' }}
          disabled
          variant="outlined"
          color="secondary"
        >
          CREATE NEW PAGE GROUP
          <LabelOutlinedIcon sx={{marginLeft:"8px", marginBottom:"3px" }} />
        </Button>
      </div>
      <DialogActions sx={{padding:"0px 24px 20px 24px"}}>
        {/* if user wants to cancel then click on button below */}
        <Button onClick={handleClose}  color="secondary">
          Cancel
        </Button>
        {/* for assigning */}
        <Button
         disabled={pageDetails?.length === 0}
        onClick={assign}
          variant="contained"
          color="secondary"
        >
          ASSIGN
          <LabelOutlinedIcon sx={{marginLeft:"5px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  )
}

export default AssignPageGroup