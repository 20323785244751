import { Paper, Typography } from "@mui/material";
import React from "react";
import ProductTypeFilterCard from "./ProductTypeFilter/ProductTypeFilterCard";
import ProductTypeTable from "./ProductTypeTable/ProductTypeTable";

const tableHeadings = {
  product_type: "Product Type",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    product_type: "Sport",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    product_type: "Sport",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    product_type: "Sport",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const ProductType = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Product Types List</Typography>
        <ProductTypeFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <ProductTypeTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default ProductType;
