import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import GeoModal from "../GeoModal/GeoModal";
import GeoFilter from "./GeoFilter";

const GeoFilterCard = () => {

 const [openModal, setOpenModal] = useState(false);

  const demoLising = [
    { label: "List Item One"},
    { label: "List Item Two"},
    { label: "List Item Three"},
    { label: "List Item Four"},
    { label: "List Item Five"},
  ];

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={4}
        sx={{ paddingBlock: "16px" }}
      >
        <Autocomplete
          sx={{ width: "350px" }}
          options={demoLising.map((listname)=>listname.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Geo Name"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "10px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <GeoFilter/>
          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenModal}
          >
            Create New Geo <AddIcon sx={{ml:1}} />
          </Button>
        </Stack>
      </Stack>
      <GeoModal open={openModal} handleClose={handleCloseModal} />
    </>
  );
}

export default GeoFilterCard