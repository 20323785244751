import {
    Alert,
    Button,
    Dialog,
    DialogTitle,
    Paper,
    TextField,
    Snackbar,
    Typography,
    CircularProgress,
  } from "@mui/material";
  import {Stack } from "@mui/system";
  import AddIcon from '@mui/icons-material/Add';
  import { useEffect, useState } from "react";
  import { DesignTemplatesEndPointLogic } from '../../../../../logic/AssetManagement/Design/Templates/DesignTemplatesEndPointLogic';
import { setIsLoading } from '../../../../../features/userSlice';
  interface DuplicatePageGroupModalProps {
    open: boolean;
    onClose: any;
    selectedSharedUid: any;
    selectedTemplateShareuid: any;
    hasGetPermissions: any;
    hasEditPermissions: any;
    designTemplatesData: any;
    setDesignTemplatesData: any;
    oauthTokenHeader: any;
  }

  function DuplicateTemplateModal({open, onClose,selectedSharedUid,selectedTemplateShareuid,oauthTokenHeader,hasGetPermissions, designTemplatesData, setDesignTemplatesData,hasEditPermissions }: DuplicatePageGroupModalProps) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false); // State for loading
    const [singlePageContent, setSinglePageContent] = useState([]) as any;
    const [, setError] = useState(""); // State for error message
    const [, setSelectedLabels] = useState<any>([]); // State for selected labels
    const [templateName, setTemplateName] = useState(''); // State for template name
    const [, setAdditionalSettings] = useState(''); // State for additional settings
    const [, setSelectedWidgetStructure] = useState(''); // State for selected widget structure
    const [, setHtml] = useState(''); // State for HTML
    const [, setCss] = useState(''); // State for CSS

    useEffect(() => {
    const fetchSingleTemplate = async() => {
      setLoading(true);
      try {
        if(selectedTemplateShareuid !== null && selectedTemplateShareuid !== "") {
          await DesignTemplatesEndPointLogic.getSingleDesignTemplate(oauthTokenHeader, selectedTemplateShareuid, setSinglePageContent, setError);
        } else {
          throw new Error('Unauthorized access for template');
        }
      } catch(error) {
        console.error(error, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchSingleTemplate();
  // eslint-disable-next-line
  }, [selectedTemplateShareuid]);

  useEffect(() => {
    if (open && singlePageContent) {
      setTemplateName(`${singlePageContent?.name}_copy`); // Set the template name to the selected template name
    }
  }, [open,singlePageContent]);

  const duplicateTemplate = async() => {
    const data = {
      website_shared_uid: selectedSharedUid,
      name: templateName,
      component: singlePageContent?.component?.shared_uid,
      html: singlePageContent?.html,
      css: singlePageContent?.css,
      additional_info: singlePageContent?.additional_info,
      labels: singlePageContent?.labels
    }
    
    try {
      // if(hasEditPermissions) {
        setIsLoading(true);
        await DesignTemplatesEndPointLogic.createDesignTemplates(oauthTokenHeader, data);
        setSnackbarOpen(true);
        setSnackbarMessage('Template duplicated successfully');
        setSelectedWidgetStructure('');
        setSelectedLabels([]);
        setTemplateName('');
        setHtml('');
        setCss('');
        setAdditionalSettings('');
        setSinglePageContent({});
        onClose();
      // } else {
      //   throw new Error('Unauthorized access for template');
      // }
      setIsLoading(true);
      await DesignTemplatesEndPointLogic.getDesignTemplates(oauthTokenHeader, selectedSharedUid, setDesignTemplatesData, setError);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  const validate = async() => {
    if(!templateName) {
      setSnackbarOpen(true);
      setSnackbarMessage('Please fill all the fields');
      setIsValid(false);
      return false;
    }

    // Check if templateName is at least 8 characters long and contains only letters and numbers
    const templateNameRegex = /^[a-zA-Z0-9-_&*$#!:|,./\\ ]{8,}$/;
    if (!templateNameRegex.test(templateName)) {
      setSnackbarOpen(true);
      setSnackbarMessage('Template name must be at least 8 characters long and contain only letters, numbers, and underscores');
      setIsValid(false);
      return false;
    }

    // Check if the template name is unique
    const isTemplateNameUnique = Array.isArray(designTemplatesData) && designTemplatesData.every((item:any) => item.name !== templateName) &&
    designTemplatesData.filter((item: any) => item?.name === templateName && item?.name !== singlePageContent?.name).length === 0;

    if (!isTemplateNameUnique) {
        setSnackbarOpen(true);
        setSnackbarMessage('Template name must be unique');
        setIsValid(false);
        return false;
    }

    setIsValid(true); // Validation passed
    return true; // Validation passed
  }

  const handleDuplicateTemplate = async() => {
    const isFormValid = await validate();
    if (!isFormValid) return; // Only proceed if the form is valid
    try {
      setLoading(true);
      await duplicateTemplate(); // Call the createNewTemplate function
    } catch(error) {
      console.error(error, "error");
    } finally {
      setLoading(false);
    }
  }

  const handleCloseDuplicateModal = () => {
    onClose();
  };

  const renderSnackbar = () => {
    return (
        <Snackbar
            sx={{ zIndex: "9999999" }}
            open={snackbarOpen}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarOpen(false)}
        >
            <Alert
                elevation={6}
                variant="filled"
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarMessage.includes('Error') || isValid ? "success" : "error"}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
};

    return (
      <>
        {renderSnackbar()}
        <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      >
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
          <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Duplicate Template
          </DialogTitle>
          <TextField
            id="outlined-multiline-flexible"
            label="New Template Name" // Set your label here
            value={loading ? "" : templateName} // Show _copy here only for display
            onChange={(e) => setTemplateName(e.target.value)} // Set the value to the templateName state
            multiline
            required
            color="secondary"
            size="small"
            maxRows={4}
            sx={{
              width: "852px",
            }}
          />
          <Typography sx={{fontSize:"12px", marginTop:"8px", color:"#00000099"}}>All fields are mandatory.</Typography>
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
            <Button color="secondary" onClick={handleCloseDuplicateModal}>CANCEL</Button>
            <Button variant="contained" color="secondary" sx={{ textTransform: 'uppercase' }} disabled={!templateName || templateName === ""} onClick={handleDuplicateTemplate}><Stack flexDirection={"row"} gap={1}>{loading ? <CircularProgress size={24} color="inherit" /> : "Create New"}<AddIcon fontSize="small"/></Stack></Button>
          </Stack>
        </Paper>
      </Dialog>
      </>
    );
  }
  export default DuplicateTemplateModal;
