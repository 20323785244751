import CompetitorsPageScanRequestsTable from './CompetitorsPageScanRequestsTable/CompetitorsPageScanRequestsTable'
import CompetitorsPageScanRequestsFilterCard from './CompetitorsPageScanRequestsFilter/CompetitorsPageScanRequestsFilterCard'
import { useState } from 'react';

const tableHeadings = {
  "competitor_name": "Competitor Name",
  "request_url": "Competitor’s URL",
  "scan_status":"Scan Status",
  "tests_amount": "Scan Count",
  "last_run":"Last Scan",
  "created":"Requested On",
  "created_by":"Requested By",
}

interface CompetitorsPageSpeedTabs {
  psiRequestRows:any;
  userToken:string;
  selectedSiteUid:any;
  setRefresh:any;
}

const CompetitorsPageScanRequests = ({psiRequestRows,userToken,selectedSiteUid,setRefresh}:CompetitorsPageSpeedTabs) => {

  const compitetorData = psiRequestRows.map((psiRequestRow: any) => ({
    competitor_name: psiRequestRow?.competitor_name,
    request_url: psiRequestRow?.request_url
  }));

  // Use a Set to ensure unique combinations of competitor_name and request_url
  const uniqueCompitetorData = Array.from(
    new Set(compitetorData.map((item:any) => JSON.stringify(item)))
  ).map((item:any) => JSON.parse(item));
  const [selectedCompetitorName,setSelectedCompetitorName] = useState(null);
  const [selectedCompetitorURL,setSelectedCompetitorURL] = useState(null) as any;
  const [selectedStatus,setSelectedStatus] = useState(null);
  const [selectedUser,setSelectedUsers] = useState(null);
  const [selectedDate,setSelectedDate] = useState(null);

  return (
    <>
      <CompetitorsPageScanRequestsFilterCard userToken={userToken} psiRequestRows={psiRequestRows} uniqueCompitetorData={uniqueCompitetorData}
        selectedCompetitorName={selectedCompetitorName} setSelectedCompetitorName={setSelectedCompetitorName} setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus} setSelectedUsers={setSelectedUsers} selectedUser={selectedUser} setSelectedDate={setSelectedDate} selectedDate={selectedDate}
        setSelectedCompetitorURL={setSelectedCompetitorURL} selectedCompetitorURL={selectedCompetitorURL} selectedSiteUid={selectedSiteUid}
        setRefresh={setRefresh}/>
      <CompetitorsPageScanRequestsTable tableHeadings={tableHeadings} psiRequestRows={psiRequestRows} userToken={userToken} selectedSiteUid={selectedSiteUid}
        setRefresh={setRefresh} selectedCompetitorName={selectedCompetitorName}
        selectedStatus={selectedStatus} selectedUser={selectedUser} selectedDate={selectedDate}
        selectedCompetitorURL={selectedCompetitorURL} />
    </>
  )
}

export default CompetitorsPageScanRequests;