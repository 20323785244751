import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./SequenceModal.css";

interface Data {
  order: number;
  process_name: string;
  process_id: number;
  remove_chain: string;
}

interface Props {
  uniqueSubProcesses: any[];
  setUniqueSubProcesses: (processes: any[]) => void; // Add this to your props
  onDelete: (processId: number) => void;
}

function createData(
  order: number,
  process_name: string,
  process_id: number,
  remove_chain: string
): Data {
  return { order, process_name, process_id, remove_chain };
}

export default function EditProcessesChainTable({ uniqueSubProcesses,setUniqueSubProcesses, onDelete }: Props) {
  const [rows, setRows] = useState<Data[]>([]);

  // Update rows when uniqueSubProcesses changes
  useEffect(() => {
    const updatedRows = uniqueSubProcesses?.map((process: any, index: number) =>
      createData(process.order, process.process__name, process.process__id, "Delete")
    );
    setRows(updatedRows);
  }, [uniqueSubProcesses]);

  const handleDragEnd = (e: DropResult) => {
    if (!e.destination) return;
    // Create a copy of the current rows
    let tempRows = Array.from(rows);
    // Rearrange the rows based on drag result
    const [movedRow] = tempRows.splice(e.source.index, 1);
    tempRows.splice(e.destination.index, 0, movedRow);
  
    // Update the order in the rows array
    const updatedRows = tempRows.map((row, index) => ({
      ...row,
      order: index + 1,
    }));
    setRows(updatedRows);
  
    // Update uniqueSubProcesses based on the new order
    const reorderedProcesses = updatedRows.map((row) => ({
      process__id: row.process_id,
      process__name: row.process_name,
    }));
    setUniqueSubProcesses(reorderedProcesses); // Assuming `uniqueSubProcesses` is set from a parent state or passed down
  };
  

  // Move row up
  const handleMoveUp = (index: number) => {
    if (index === 0) return; // Cannot move up the first row
    const newRows = [...rows];
    [newRows[index], newRows[index - 1]] = [newRows[index - 1], newRows[index]];
    setRows(newRows.map((row, i) => ({ ...row, order: i + 1 })));
    const reorderedProcesses = newRows.map((row) => ({
      process__id: row.process_id,
      process__name: row.process_name,
    }));
    setUniqueSubProcesses(reorderedProcesses); // Update uniqueSubProcesses state
  };

  // Move row down
  const handleMoveDown = (index: number) => {
    if (index === rows.length - 1) return; // Cannot move down the last row
    const newRows = [...rows];
    [newRows[index], newRows[index + 1]] = [newRows[index + 1], newRows[index]];
    setRows(newRows.map((row, i) => ({ ...row, order: i + 1 })));
    const reorderedProcesses = newRows.map((row) => ({
      process__id: row.process_id,
      process__name: row.process_name,
    }));
    setUniqueSubProcesses(reorderedProcesses); // Update uniqueSubProcesses state
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer sx={{ maxHeight: 240 }}>
            <Table className="dragChainsTable" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Order</TableCell>
                  <TableCell align="left">Process Name</TableCell>
                  <TableCell align="left">Process ID</TableCell>
                  <TableCell align="center">Remove from Chain</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {rows
                     ?.slice() // Create a shallow copy of rows to avoid mutating the state directly
                     ?.sort((a, b) => a.order - b.order) // Sort rows by the order property
                     ?.map((row, index) => (
                      <Draggable
                        key={row.process_id}
                        draggableId={row.process_name}
                        index={index}
                      >
                        {(provider, snapshot) => {
                          const style = {
                            ...provider.draggableProps.style,
                            width: "805px",
                            display: snapshot.isDragging ? "table" : "",
                            backgroundColor: snapshot.isDragging
                              ? "white"
                              : "white",
                            boxShadow: snapshot.isDragging
                              ? "0px 0px 3px #ccc"
                              : "",
                            borderRadius: snapshot.isDragging ? "4px" : "",
                            left: snapshot.isDragging ? "40px" : "",
                          };
                          return (
                            <TableRow
                              key={row.process_id}
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                              style={style}
                            >
                              <TableCell>
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                  }}
                                >
                                  {index + 1}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                {...provider.dragHandleProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <DragIndicatorIcon
                                    sx={{
                                      color: "rgb(0 0 0 / 54%)",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {row.process_name}
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {row.process_id}
                                  <span className="processIdActionBtn">
                                    <Button
                                      color="secondary"
                                      size="small"
                                      variant="outlined"
                                      onClick={() => handleMoveUp(index)}
                                      disabled={index === 0}
                                    >
                                      <KeyboardArrowUpOutlinedIcon fontSize="small" />
                                    </Button>
                                    <Button
                                      color="secondary"
                                      size="small"
                                      variant="outlined"
                                      onClick={() => handleMoveDown(index)}
                                      disabled={index === rows.length - 1}
                                    >
                                      <KeyboardArrowDownOutlinedIcon fontSize="small" />
                                    </Button>
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => onDelete(row.process_id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </Paper>
      <Typography variant="body1" sx={{ fontSize: 13, mt: 1 }}>
        {rows?.length} Processes selected
      </Typography>
    </>
  );
}
