import { createSlice } from '@reduxjs/toolkit';
import { UserDetailsResponse } from '../data/user/UserDetailsResponse';
import { AppState } from './AppState';
import { AlertColor } from '@mui/material';

export interface ErrorSnackBarState {
  message: string,
  type: AlertColor | undefined,
}

export interface UserSlice {
  userToken: string | null,
  userData: UserDetailsResponse | null,
  isLoading: boolean,
  displayErrorSnackBar: ErrorSnackBarState | null,
  viewsToDisplay: { home: boolean },
}

const initialState: UserSlice = {
  userToken: null,
  userData: null,
  isLoading: false,
  displayErrorSnackBar: null,
  viewsToDisplay: { home: true },
} as const;

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setSignedOut: (state) => {
      state.userToken = null;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDisplayErrorSnackBar: (state, action) => {
      state.displayErrorSnackBar = action.payload;
    },
    setViewsToDisplay: (state, action) => {
      state.viewsToDisplay = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

export const {
  setSignedOut, setUserData, setIsLoading, setViewsToDisplay, setDisplayErrorSnackBar, setUserToken,
} = userSlice.actions;

// We export this that we could get the user state (loggedIn/loggedOut)
// export const selectUser = (state: { user: { user: any; }; }) => state.user.user;

export const selectSignedIn = (state: AppState): boolean => !!state.user.userToken;
export const selectIsLoading = (state: AppState) => state.user.isLoading;

export const selectUserData = (state: AppState): UserDetailsResponse | null => state.user.userData ?? null;
export const selectUserMenu = (state: AppState) => state.user.userData?.data?.products ?? null;
export const selectUserDetails = (state: AppState) => state.user.userData?.data?.user_details ?? null;
export const selectUserWebsitePermissions = (state: AppState) => state.user.userData?.data?.website_permissions ?? null;

export const selectViewsToDisplay = (state: AppState) => state.user.viewsToDisplay;
export const selectDisplayErrorSnackBar = (state: AppState) => state.user.displayErrorSnackBar;
export const selectUserToken = (state: AppState): string | null => state.user.userToken;


export default userSlice.reducer;