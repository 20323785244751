import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Paper, Typography, InputAdornment, Autocomplete, Alert, Snackbar} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Stack } from "@mui/system";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ManualExpenseDocs from "../CustomTableManualExpenseNotes/ManualExpenseDocs";
import { advertisingAccountsEndPointLogic } from "../../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ManualExpenseEndPointLogic } from "../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic";
import { trackPromise } from "react-promise-tracker";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../../../../features/userSlice';

interface AddManualExpenseDialogProps {
  open: boolean;
  handleClose: () => void;
  setManualexpenserows:any;
  note: string;
  oauthTokenHeader:string;
  siteNames:any;
  fileBase64:any,
  setFileBase64:any,
  setFileName:any,
  fileName:any,
  setRefresh:any;
  isCardVisible:any;
  setIsCardVisible:any
}

const AddManualExpenseDialog: React.FC<AddManualExpenseDialogProps> = ({ open, handleClose, setManualexpenserows, note,oauthTokenHeader,siteNames,fileBase64,setFileBase64,setFileName,fileName,setRefresh,isCardVisible,setIsCardVisible }) => {

  const [trafficSource,setTrafficSource]=useState([]);
  const [sourceUID,setSourceUID]=useState("");
  const [assetSharedUID,setAssetSharedUID]=useState("");
  const [date,setDate]=useState("");
  const [impressions,setImpressions]=useState(0);
  const [currencies,setCurrencies]=useState([]);
  const [currencyCode,setCurrencyCode]=useState("");
  const [expense,setExpense]=useState("") as any;// need to check this
  const [clicks,setClicks]=useState(0);
  const [amount,setAmount]=useState(0);
  const [comment,setComment]=useState("");
  const [loading, setLoading] = useState(false);  // Add state for error handling
  const [alertOpen, setAlertOpen] = useState(false); 
  const [success, setSuccess] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch(); 
  let data :any = {
        "website_shared_uid": assetSharedUID,
        "currency": currencyCode,
        "traffic_source": sourceUID,
        "date": date,
        "impressions": impressions,
        "clicks": clicks,
        "expenses": amount,
        "comment": comment
      } ;

const validateFields = () => {
  // Check if all required fields are filled
  return assetSharedUID && sourceUID && date && currencyCode;
};

useEffect(()=> {
  if (open) {
    advertisingAccountsEndPointLogic.getTrafficSource(oauthTokenHeader, setTrafficSource);
    ManualExpenseEndPointLogic.getCurrencies(oauthTokenHeader,setCurrencies);
  }
},[open,oauthTokenHeader])

useEffect(() => {
  // This will run after `expense` is set

  if (fileBase64) {
    // Create an array of Promises for each file upload
    const uploadPromises = fileBase64.map((file: any) => {
      const singleFileData = {
        object_type: "manual_adjustment_expenses",
        file_name: file.name,
        file: file.base64
      };

      // Return the promise from the API call
      return ManualExpenseEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, expense, trackPromise);
    });

    // Use Promise.all to wait for all uploads to complete
    Promise.all(uploadPromises).then(() => {
      // Clear fileBase64 array after all files are uploaded
      setFileBase64([]);
    }).catch((error) => {
      throw new Error ("File upload failed", error);
    });
  }
  // eslint-disable-next-line
}, [expense, oauthTokenHeader]);


const handleSave = async () => {
  if (validateFields()) {
    // Proceed to save
    setLoading(true);
    await ManualExpenseEndPointLogic.createExpense(oauthTokenHeader, data, setExpense, trackPromise)
      .then(() => {
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage('Expense Created Successfully');
        setAssetSharedUID("");
        setSourceUID("");
        setDate("");
        setCurrencyCode("");
        setRefresh(true);
        ManualExpenseEndPointLogic.getManualExpenseContent(oauthTokenHeader, setIsLoading, setManualexpenserows, dispatch); // Refresh the table after save
        setLoading(false);
        setSuccess("Expense Created Successfully");
      })
      .catch((error) => {
        setSnackbarOpen(true);
        setSnackbarMessage('Error creating expense');
        console.error("Error during save:", error);
      });
  } else {
    // Show validation error if fields are not filled
    // setError(true);
    setAlertOpen(true); // Show Snackbar alert
  }
};

const handleAlertClose = () => {
  setAlertOpen(false);  // Close Snackbar
};

useEffect(() => {
  if (alertOpen) {
    const timer = setTimeout(() => {
      setAlertOpen(false);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timer when component unmounts or alertOpen changes
  }
}, [alertOpen]);

const renderSnackbar = () => {
  return (
    <Snackbar
      sx={{ zIndex: '9999999' }}
      open={snackbarOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarMessage.includes('Error') ? 'error' : 'success'}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
};


  return (
    <>
    {renderSnackbar()}
    {loading ? <SimpleBackdrop/> :
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}>
    <Paper sx={{ padding: "24px 24px 20px 24px", height: "836px", overflowY: "auto" }}>
      {alertOpen && (
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          All mandatory fields are required!
        </Alert>
      )}
      {Object.keys(success).length >= 1 && (
        <Snackbar onClose={handleAlertClose} autoHideDuration={3000}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Expense Created Successfully
          </Alert>
        </Snackbar>
      )}
      <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>Add New Expense</DialogTitle>
      <Typography sx={{ marginBottom: "16px" }}>Details</Typography>
      <DialogContent sx={{ padding: "8px 0px" }}>
        <Stack flexDirection="row" gap="16px">
          <Autocomplete
            options={siteNames}
            getOptionLabel={(option: any) => option?.site?.site_name}
            onChange={(e: any, option: any) => setAssetSharedUID(option?.key)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Related To"
                placeholder='Choose Asset from the list.'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "273px" }}
          />
          <Autocomplete
            options={trafficSource}
            getOptionLabel={(option: any) => option.traffic_source_name}
            onChange={(e, option: any) => setSourceUID(option?.shared_uid)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source"
                placeholder='Choose Source from the list.'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "273px" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ paddingTop: "0", overflow: "visible" }}>
              <DatePicker
                className="brandDataDatePicker"
                format="DD-MM-YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "273px" }}
                label="Pick a date"
                onChange={(date) => {
                  if (date) {
                    const formattedDate = dayjs(date).format("YYYY-MM-DD");
                    setDate(formattedDate);
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogContent sx={{ padding: "0" }}>
        <TextField
          size="small"
          color="secondary"
          sx={{ mt: "16px", mb: "16px", padding: "0", width: "49%", marginRight: "16px" }}
          id="outlined-helperText"
          label="Impressions"
          onKeyDown={(e) => {
            // Allow only digits, backspace, and delete
            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "Tab") {
              e.preventDefault();
            }
          }}
          defaultValue=""
          onChange={(e: any) => setImpressions(e.target.value)}
        />
        <TextField
          size="small"
          color="secondary"
          sx={{ mt: "16px", mb: "16px", padding: "0", width: "49%" }}
          id="outlined-helperText"
          label="Clicks"
          onKeyDown={(e) => {
            // Allow only digits, backspace, and delete
            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "Tab") {
              e.preventDefault();
            }
          }}
          defaultValue=""
          onChange={(e: any) => setClicks(e.target.value)}
        />
      </DialogContent>
      <DialogContent sx={{ padding: "0" }}>
        <Stack flexDirection="row">
          <TextField
            size="small"
            color="secondary"
            sx={{ margin: "8px 0", padding: "0", width: "49%", marginRight: "16px" }}
            id="outlined-helperText"
            label="Amount"
            onKeyDown={(e) => {
              const inputValue = (e.target as HTMLInputElement).value;

              // Allow only digits, backspace, delete, and prevent multiple '-'
              if (
                !/[0-9]/.test(e.key) && 
                e.key !== "Backspace" && 
                e.key !== "Delete" && 
                !(e.key === "-" && inputValue === "") &&
                !(e.key === "-" && inputValue.length === 0) && // Allow '-' only if input is empty
                !(e.key === "-" && inputValue[0] === '-') && e.key !== "Tab"
                
              ) {
                e.preventDefault();
              }

              // Prevent '-' in any position other than the first
              if (e.key === "-" && inputValue.includes("-")) {
                e.preventDefault();
              }
            }}
            onChange={(e: any) => setAmount(e.target.value)}
            defaultValue=""
          />
          <Autocomplete
            options={currencies}
            getOptionLabel={(option: any) =>
              option ? `${option.currency_symbol} ${option.currency_name}` : ''
            }
            onChange={(event, option: any) => setCurrencyCode(option?.shared_uid)}
            renderOption={(props, option: any) => (
              <li {...props}>
                {/* Add the currency symbol next to the currency name */}
                <span style={{ marginRight: 8 }}>{option?.currency_symbol}</span>
                {option?.currency_name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Currency"
                placeholder="Choose Currency from the list."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "49%", marginTop: "8px" }}
          />
        </Stack>
      </DialogContent>
      <Typography sx={{ marginBottom: "16px", marginTop: "8px", fontSize: "12px", lineHeight: "20px", color: "#00000099" }}>
        Fields marked with * are mandatory.
      </Typography>
      <Typography mt="16px" mb="8px">Expense Description</Typography>
      <TextField
        id="outlined-multiline-flexible"
        label="Statement" // Set your label here
        placeholder="Add your description here" // Set your placeholder here
        multiline
        color="secondary"
        size="medium"
        maxRows={4}
        sx={{
          width: '852px',
        }}
        onChange={(e) => setComment(e.target.value)}
      />
      <Typography mt="16px" mb="8px">Attachments</Typography>
      <ManualExpenseDocs 
        setFileBase64={setFileBase64} 
        setFileName={setFileName} 
        fileName={fileName}  
        setIsCardVisible={setIsCardVisible}
        isCardVisible={isCardVisible} 
        fileBase64={fileBase64}
      />
    </Paper>
    <Paper sx={{ padding: "16px 24px 20px 24px" }}>
      <DialogActions sx={{ padding: "16px 0 0 0" }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary" variant="contained">
          ADD expense <CheckOutlinedIcon fontSize="small" sx={{ marginLeft: "8px" }} />
        </Button>
      </DialogActions>
    </Paper>
  </Dialog>
   }
    </>
  );
};

export default AddManualExpenseDialog;
