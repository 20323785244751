
import { useSelector } from 'react-redux';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { pageBuildingHelpers } from './logic/PageBuildingHelpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import { selectIsLoading, selectUserData, setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import { useHasPageManagementPermissions } from '../../../../logic/AssetManagement/PageManagement/Permissions/useHasPageBuildingPermissions';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { WebsitePermissions } from '../../../../data/user/WebsitePermissions';
import { WebsiteWithUuid } from '../../../../data/website/Website';
import store from '../../../../store';
import { pageBuildingEndPointLogic } from '../../../../logic/AssetManagement/PageManagement/PageBuilding/pageBuildingEndPointLogic';
import SimpleBackdrop from '../../../../components/BackDrop/BackDrop';
import PageHeadlineDetails from '../../../../components/AssetManagement/PageManagement/PageHeadlineDetails/PageHeadlineDetails';
import PageBuilding from '../../../../components/AssetManagement/PageManagement/PageBuilding/PageBuilding';
import { Alert, Snackbar } from '@mui/material';
import ErrorHandle from '../../../../components/ErrorHandle/ErrorHandle'
import ErrorPopupAccToPageStatus from '../../../../components/ErrorPopupAccToPageStatus/ErrorPopupAccToPageStatus'
import SharedStateContext from '../../../../components/SharedStateProvider';

export default function PageBuildingScreen(userToken: any) {
    // All Capabilities for this screen
    const hasPermissions = useHasPageManagementPermissions([
        ModulesPermissionNames.GET_API_PAGE,
        ModulesPermissionNames.GET_API_PAGES,
        ModulesPermissionNames.GET_API_WIDGETS,
        ModulesPermissionNames.GET_API_PAGE_GROUPS,
        ModulesPermissionNames.GET_API_THEME,
        ModulesPermissionNames.PUT_API_PAGE,
        ModulesPermissionNames.PUT_API_PAGES,
        ModulesPermissionNames.GET_API_PAGE_GROUPS,
        ModulesPermissionNames.PUT_API_PAGE_GROUPS,
        ModulesPermissionNames.PUT_API_THEME,
        ModulesPermissionNames.POST_API_PAGE,
        ModulesPermissionNames.POST_API_PAGES,
        ModulesPermissionNames.POST_API_PAGE_GROUPS,
    ]);
    const hasEditPermissions = useHasPageManagementPermissions([
        ModulesPermissionNames.PUT_API_PAGE,
        ModulesPermissionNames.PUT_API_PAGES,
        ModulesPermissionNames.GET_API_PAGE_GROUPS,
        ModulesPermissionNames.PUT_API_PAGE_GROUPS,
        ModulesPermissionNames.GET_API_THEME,
        ModulesPermissionNames.PUT_API_THEME,
        ModulesPermissionNames.POST_API_PAGE,
        ModulesPermissionNames.POST_API_PAGES,
        ModulesPermissionNames.POST_API_PAGE_GROUPS,
    ]);
    const navigate = useNavigate();
    const oauthTokenHeader = userToken?.userToken;
    const location = useLocation();
    const [pageUid,] = useState<string>(location?.state?.pageUid);
    const pageTitle = location?.state?.pageTitle ? location?.state?.pageTitle : '';
    const [preview,setPreview] =useState(location?.state?.previewLink ? location?.state?.previewLink : '') ;
    const [,setPostStatus] = useState<string>(location?.state?.postStatus ? location?.state?.postStatus : '');
    const { pageName } = useParams();
    const { siteName } = useParams();
    const isLoading = useSelector(selectIsLoading);
    const userWebsitePermissions = useSelector(selectUserData)?.data?.website_permissions as WebsitePermissions;
    const [pageStructureStyle, setPageStructureStyle] = useState();
    const [currentpageElements, setCurrentPageElements] = useState<any>();
    const [currentpageElementsOriginal, setCurrentPageElementsOriginal] = useState();
    const [pageBuildingStructure, setPageBuildingStructure] = useState();
    const inputRef = useRef(null) as any;
    const [imgClicked, setImgClicked] = useState() as any;
    const [tasks, setTasks] = useState({}) as any;
    const [isPublishOpen, setisPublishOpen] = useState<any>(false);
    const [ids, setIds] = useState<number[]>([]);
    const [pageContent, setPageContent] = useState<any>();
    const [pageStructureDesign, setPageStructureDesign] = useState<any>();
    const [pageDictionary, setPageDictionary] = useState<any>();
    const [siteAllAvailableWidgets, setSiteAllAvailableWidgets] = useState();
    const [pageUrlContent, setPageUrlContent] = useState<any>();
    const [pageUrlComponents, setPageUrlComponents] = useState<any>();
    const [header, setHeader] = useState<any>();
    const [headerMenu, setHeaderMenu] = useState<any>();
    const [footer, setFooter] = useState<any>();
    const [htmlWrapperDiv,] = useState<string>('');
    const [pageIsUpdatedCount, setPageIsUpdatedCount] = useState<number>();
    const [previewCurrentChanges, setPreviewCurrentChanges] = useState<any>([]);
    const [previewLink , setPreviewLink] =useState<any>("")
    const [newElementAdded, setNewElementAdded] = useState<number>();
    const [,setPageDiscardData] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isElementSaved, setIsElementSaved] = useState(false);
    const [isYesBtnClicked, setIsYesBtnClicked] = useState(false);
    const [isPageStrError, setIsPageStrError] = useState(false)
    const [errorAccToPageStatus, setErrorAccToPageStatus] = useState<number>();
    const [retry, setRetry] = useState(false)
    const [open, setOpen] = useState(true)
    const [isLoadingClosed, setIsLoadingClosed] = useState(false); // new state to check if loader is closed
    const {  setPageRefreshStatus } = useContext(SharedStateContext); // using the context api shared data
    const [saveTrigger, setSaveTrigger] = useState(false);


const pageContentPageId = pageContent?.page_id;

let pageContentJson : any;
const selectedSite = useMemo(() => {
    return (userWebsitePermissions.find((website) => Object.values(website)[0].site_name === siteName) as WebsiteWithUuid ?? null);
    // eslint-disable-next-line
}, [siteName]);
const siteUid = Object.keys(selectedSite)[0];

    const handleCloseSnackbar = () => {
        setShowSuccessPopup(false);
    };
    

    useEffect(() => {
        try {
            store.dispatch(setIsLoading(true));
            if (pageContent && pageUrlContent!==undefined) {
                pageBuildingHelpers.onUpdateCreatePage(setCurrentPageElementsOriginal, pageDictionary, pageContent, pageUrlContent,
                    setHeader,setHeaderMenu, setFooter, setPageUrlComponents, setPageStructureStyle, pageStructureDesign, setPageBuildingStructure, tasks,
                    setTasks, siteAllAvailableWidgets, setCurrentPageElements)
            }
        } catch (e) {
            /* store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' })); */
            store.dispatch(setIsLoading(false));
            setIsPageStrError(true)
            /* navigate(`/`); */
        }
        // eslint-disable-next-line
    }, [pageContent,pageUrlContent])

    useEffect(() => {
        if (!isLoading && isLoadingClosed) {
          setShowSuccessPopup(true);
        }
      }, [isLoading, isLoadingClosed]); // show success popup when loader is closed

    useEffect(() => {
        if (currentpageElements !== undefined) {
            store.dispatch(setIsLoading(false));
        }
         else {
            store.dispatch(setIsLoading(true));
        }
    }, [currentpageElements])
    useEffect(() => {
        if (pageContent?.post_status === 'draft' && currentpageElements?.length === 0) {
            setIsElementSaved(false)
        }
        if (pageContent?.post_status === 'publish' && currentpageElements?.length === 0) {
            setIsElementSaved(false)
            setisPublishOpen(false)
        }
        if (pageContent?.post_status === 'pending' && currentpageElements?.length === 0) {
            setIsElementSaved(false)
        }
        
    }, [currentpageElements, pageContent])
    
    useEffect(() => {
        try {
            store.dispatch(setIsLoading(true));
            if ((pageUid || pageIsUpdatedCount) && hasPermissions) {
                store.dispatch(setIsLoading(true));
                    pageBuildingEndPointLogic.getPageUrlContent(preview, setPageUrlContent,
                        setIsLoading, trackPromise);
                pageBuildingEndPointLogic.getSiteAllAvailableWidgets(Object.keys(selectedSite)[0], oauthTokenHeader,
                    setSiteAllAvailableWidgets,setErrorAccToPageStatus,setRetry,setOpen, trackPromise, setIsLoading) as any;
                    
            } else {
                pageBuildingEndPointLogic.navigateTo404(navigate);
            }
            /* setOpen(false) */
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
            store.dispatch(setIsLoading(false));
            setOpen(true)
            /* navigate(`/`); */
        }
        // eslint-disable-next-line
    }, [pageUid, pageIsUpdatedCount, retry === true,saveTrigger]); //, isYesBtnClicked === true 
    useEffect(() => {
        try {
            if (Object.values(previewCurrentChanges)?.length === 2) {
                store.dispatch(setIsLoading(true));
                pageBuildingHelpers.updateWidgetChangedWithPreviewCurrentChanges(previewCurrentChanges, pageBuildingStructure,
                    currentpageElements, setCurrentPageElements);
                store.dispatch(setIsLoading(false));
            }
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'Can not parse new widget changes from preview' }));
            store.dispatch(setIsLoading(false));
        }
        // eslint-disable-next-line
    }, [previewCurrentChanges])
    useEffect(() => {
        try {
            if (siteAllAvailableWidgets) {
                store.dispatch(setIsLoading(true));
                pageBuildingEndPointLogic.getPageContent((Object.keys(selectedSite))[0], pageUid, oauthTokenHeader, setPageContent, setPageStructureDesign,
                    setPageDictionary, setIsLoading, setErrorAccToPageStatus,setRetry,setOpen,  trackPromise) as any;
            }
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
            store.dispatch(setIsLoading(false));
            
            /* navigate(`/`); */
        }
        // eslint-disable-next-line
    }, [siteAllAvailableWidgets, retry === true]) //, isYesBtnClicked === true
    useEffect(() => {
        if (newElementAdded !== undefined && newElementAdded !== null) {
            previewCurrentChange(newElementAdded);
        }
        // eslint-disable-next-line
    }, [newElementAdded])
    
    const previewCurrentChange = (elementIndex: number, updatedElementsPageElements?: any) => {
        try {
            store.dispatch(setIsLoading(true));
            
            if (updatedElementsPageElements?.newCurrentElements?.length > 0 && updatedElementsPageElements?.newOriginalElements?.length > 0) {
                // in case sending from edit widget
                pageContentJson = pageBuildingHelpers.parseRelevantElementsToJson(updatedElementsPageElements?.newCurrentElements,
                    updatedElementsPageElements?.newOriginalElements);
            } else {
                // in case we're dragging new widget
                pageContentJson = pageBuildingHelpers.parseRelevantElementsToJson(currentpageElements, currentpageElementsOriginal);
            }

            const data = {
                data: {
                    "post_content": pageContentJson
                }
            }
            pageBuildingEndPointLogic.getPagePreviewForCurrentChanges(elementIndex, Object.keys(selectedSite)[0], pageUid, data, setIsLoading,
                trackPromise, setPreviewCurrentChanges, oauthTokenHeader , setPreviewLink);
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
            store.dispatch(setIsLoading(false));
        }
    }
    const onDragEnd = (data: any, details?: any) => {
        try {
            pageBuildingHelpers.onDragEnd(currentpageElements, setCurrentPageElements, tasks, setTasks, data
                , currentpageElementsOriginal, setCurrentPageElementsOriginal, setNewElementAdded, details ,siteAllAvailableWidgets);

        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
        }
    }
    const handleFileChange = (event: any) => {
        pageBuildingHelpers.handleFileChange(setImgClicked, imgClicked, event);
    }
    const onChangeDndItem = (e: any) => {
        pageBuildingHelpers.onChangeDndItem(setImgClicked, e, inputRef);
    }
    const onClickItem = (selectedWidgettype: any, setAllWidgetsByType: any) => {
        pageBuildingHelpers.onClickItem(selectedWidgettype, setAllWidgetsByType, siteAllAvailableWidgets);
    }

    const publishPage = async () => {
        try {
            store.dispatch(setIsLoading(true));
            const pageContentJson = pageBuildingHelpers.parseRelevantElementsToJson(currentpageElements, currentpageElementsOriginal);
            const currentPageTitle = pageTitle ? pageTitle : `${pageTitle}`; // Ensure the correct title for duplicated pages
            const updateObject = {
                data: {
                    "post_title": currentPageTitle,
                    "post_content": pageContentJson,
                    "post_status":"publish"
                }
            }
            await pageBuildingEndPointLogic.updatePage(updateObject, Object.keys(selectedSite)[0], pageUid, setIsLoading, oauthTokenHeader, trackPromise,
                setPageIsUpdatedCount, pageIsUpdatedCount ,setPreview);
            store.dispatch(setIsLoading(true));
            
            if(pageContent?.unpublished_change===true){
                const data =""
                await pageBuildingEndPointLogic.discardPage(Object.keys(selectedSite)[0], pageUid, setIsLoading, oauthTokenHeader, setPageDiscardData, data,setPageIsUpdatedCount, pageIsUpdatedCount, setPreview);
                await pageBuildingEndPointLogic.getPageContent((Object.keys(selectedSite))[0], pageUid, oauthTokenHeader, setPageContent, setPageStructureDesign,
                    setPageDictionary, setIsLoading,setErrorAccToPageStatus,setRetry,setOpen, trackPromise);
            }
    
            // Close publish dialog and update states
            setisPublishOpen(false);
            setIsElementSaved(false);
            setPostStatus("publish");
    
            // Update the IDs list
            const updatedNumbers = ids.filter(n => n !== pageContent?.page_id);
            setIds(updatedNumbers);
            
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
            store.dispatch(setIsLoading(false));
           /*  navigate(`/`); */
        } finally{
            setSuccessMessage("Page Published Successfully.");
            setPageRefreshStatus(true);
            setIsLoadingClosed(true); // set isLoadingClosed to true when loader is closed
        }
    }  
    const addId = (newId: number) => {
        setIds([...ids, newId]);

      };

        useEffect(() => {
        const storedIds = localStorage.getItem('ids');
        if (storedIds) {
            
          setIds(JSON.parse(storedIds));

        }
      }, []);

      useEffect(() => {
        localStorage.setItem('ids', JSON.stringify(ids));
      }, [ids]);
   
    const savePage = async () => {
        try {
            store.dispatch(setIsLoading(true));
            const pageContentJson = pageBuildingHelpers.parseRelevantElementsToJson(currentpageElements, currentpageElementsOriginal);
            const updateObject = {
                data: {
                    "post_title": pageTitle,
                    "post_content": pageContentJson,
                    "post_status":"pending",
                }
            }
            await pageBuildingEndPointLogic.savePage(updateObject, Object.keys(selectedSite)[0], pageUid, setIsLoading, oauthTokenHeader, trackPromise,
                setPageIsUpdatedCount, pageIsUpdatedCount,setPreview);
            store.dispatch(setIsLoading(true));
            setIsElementSaved(false)
            /* setisPublishOpen(true) */
            localStorage.setItem('isPageSaved', JSON.stringify(isElementSaved))
            localStorage.setItem('isPageSavedId', JSON.stringify(pageContent?.page_id))
            
            addId(pageContent?.page_id)
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
            store.dispatch(setIsLoading(false));
            /* navigate(`/`); */
        } finally{
            if(pageContent?.shared_uid !== undefined){
                setSuccessMessage("Page saved successfully.");
                setPageRefreshStatus(true);
                setIsLoadingClosed(true); // set isLoadingClosed to true when loader is closed
        }}
    }
    
const discardPage = async () => {
        try {
            store.dispatch(setIsLoading(true));
            const data = "";
            setPreview(location?.state?.previewLink);
            await pageBuildingEndPointLogic.discardPage(Object.keys(selectedSite)[0], pageUid, setIsLoading, oauthTokenHeader, setPageDiscardData, data,setPageIsUpdatedCount, pageIsUpdatedCount, setPreview);
            await pageBuildingEndPointLogic.getPageContent((Object.keys(selectedSite))[0], pageUid, oauthTokenHeader, setPageContent, setPageStructureDesign,
                    setPageDictionary, setIsLoading, setErrorAccToPageStatus,setRetry,setOpen, trackPromise);
                    store.dispatch(setIsLoading(true));
         } catch (error) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: error, type: 'error' }));
            /* navigate(`/`); */
        } finally {        
                setPageRefreshStatus(true);
                setIsLoadingClosed(true); // set isLoadingClosed to true when loader is closed
                setSuccessMessage("Page discarded successfully.");
            // store.dispatch(setIsLoading(true));
        }
    }
    
    const onEditWidget = (elementIndex: number, widgetShortCode: string, deviceChecked: any) => {
        try {
            pageBuildingHelpers.onEditWidget(currentpageElements, setCurrentPageElements, currentpageElementsOriginal,
                setCurrentPageElementsOriginal, elementIndex, widgetShortCode, deviceChecked,siteAllAvailableWidgets);
                
            // only for desktop device we need to show visual widget
            // if (deviceChecked?.desktop) {
            //     previewCurrentChange(elementIndex, updatedElementsPageElements);
            // }
        } catch (e) {
            store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
        }
    }
    const pageBuildingProps = {
        currentpageElementsOriginal,
        setCurrentPageElementsOriginal,
        currentpageElements,
        handleFileChange,
        inputRef,
        onChangeDndItem,
        onClickItem,
        onDragEnd,
        pageUrlComponents,
        tasks,
        pageBuildingStructure,
        header,
        footer,
        headerMenu,
        pageStructureStyle,
        htmlWrapperDiv,
        setCurrentPageElements,
        siteAllAvailableWidgets,
        onEditWidget,
        oauthTokenHeader,
        siteUid,
        setisPublishOpen,
        setIsElementSaved,
        
    };
    const PageHeadlineDetailsProps = {
        oauthTokenHeader,
        siteUid,
        pageUid,
        pageName,
        siteName,
        publishPage,
        savePage,
        selectedSite,
        previewLink , 
        pageContent,
        previewCurrentChange,
        discardPage,
        currentpageElements,
        isPublishOpen,
        isElementSaved,
        pageContentPageId,
        ids,
        hasEditPermissions,
        setIsYesBtnClicked,
        isYesBtnClicked,
        hasPermissions,
        setPageContent,
        setPageStructureDesign,
        setPageDictionary,
        setPageUrlContent,
        setSiteAllAvailableWidgets,
        siteAllAvailableWidgets,
        setPageRefreshStatus,
        currentpageElementsOriginal,
        setSaveTrigger,
        setPreview,
        setSuccessMessage

    }
    const PageElementErrorProps = {
        pageUid,
        setPageStructureDesign,
        setPageDictionary,
        setIsLoading,
        setPageContent,
        oauthTokenHeader,
        selectedSite,
        trackPromise
       
    }
    const ErrorPopupStatusProps = {
        pageUid,
        setPageStructureDesign,
        setPageDictionary,
        setIsLoading,
        setPageContent,
        oauthTokenHeader,
        selectedSite,
        trackPromise,
        siteUid,
        errorAccToPageStatus,
        setErrorAccToPageStatus, 
        setRetry,
        open,
        setOpen
    }

    return <>
        {isLoading && <SimpleBackdrop />}
        {isPageStrError === true && <ErrorHandle {...PageElementErrorProps}/>}
        {errorAccToPageStatus && <ErrorPopupAccToPageStatus {...ErrorPopupStatusProps}/>}
        <PageHeadlineDetails {...PageHeadlineDetailsProps}  />
        {currentpageElements ? <PageBuilding pageDictionary={pageDictionary} {...pageBuildingProps} /> : ' '}
        <Snackbar sx={{zIndex:"9999999"}} open={showSuccessPopup} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
                    {successMessage}
                </Alert>
        </Snackbar>
    </>
}
