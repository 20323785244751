import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DomaineDoesNotExist from "./DomaineDoesNotExist";
import { useCallback, useEffect, useState } from "react";
import { PsiCompetitors } from "../../../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedEndPointLogic";

interface CompetitorsPageScanModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  userToken: string;
  selectedSiteUid: any;
  setRefresh: any;
}

const CompetitorsPageScanRequestsModal = ({
  open,
  handleClose,
  data,
  userToken,
  selectedSiteUid,
  setRefresh,
}: CompetitorsPageScanModalProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [isDomainAvailable, setIsDomainAvailable] = useState(false);
  const [, setSharedUid] = useState<string | null>(null);
  const [, setWebsiteSharedUid] = useState<string | null>(null);
  const [postStatus, setPostStatus] = useState([]) as any;
  const [validDomain, setValidDomain] = useState([]) as any;
  const [showDomainNotExist, setShowDomainNotExist] = useState(false) as any;
  const [baseDomain, setBaseDomain] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to extract the base domain from the input URL
  // const getBaseDomain = (url: string) => {
  //   try {
  //     const hostname = new URL(url).hostname;
  //     return hostname;
  //   } catch (error) {
  //     console.error("Invalid URL:", error);
  //     return null;
  //   }
  // };

  // Function to check if searchValue matches any domain in the data array
  const checkDomainAvailability = async () => {
    setValidDomain(false);
    setPostStatus([]);
    const baseDomain = searchValue.trim();
    const isValid = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/.test(baseDomain);
    if (!isValid) {
      setIsDomainAvailable(false);
      setValidDomain(true);
      return;
    }

    if (!baseDomain) {
      setIsDomainAvailable(false);
      setValidDomain(true);
      return;
    }

    const foundDomain = data.find(
      (item: any) => item.domain.toLowerCase() === baseDomain.toLowerCase()
    );

    setBaseDomain(baseDomain);

    if (foundDomain) {
      setLoading(true);
      let postData = {
        url_name: searchValue,
        serp_competitor_shared_uid: foundDomain.shared_uid,
      };

      setIsDomainAvailable(true);
      setSharedUid(foundDomain.shared_uid);

      const websiteSharedUid = selectedSiteUid;

      if (websiteSharedUid) {
        setWebsiteSharedUid(websiteSharedUid);
        await PsiCompetitors.postCompetitors(
          userToken,
          setPostStatus,
          websiteSharedUid,
          postData
        );
      }
      setLoading(false);
    } else {
      setIsDomainAvailable(false);
      setShowDomainNotExist(true);
    }
  };

  const handleClosePopup = useCallback(() => {
    handleClose();
    setShowDomainNotExist(false);
    setSearchValue("");
    setPostStatus([]);
    setIsDomainAvailable(true);
    setValidDomain([]);
    setRefresh(true);
  }, [handleClose, setRefresh]);
  

  const handleCancelPopup = () => {
    handleClose();
    setShowDomainNotExist(false);
    setSearchValue("");
    setPostStatus([]);
    setIsDomainAvailable(true);
    setValidDomain([]);
  };

  // Automatically close modal after 1 second on success
  useEffect(() => {
    if (Object.keys(postStatus).length >= 1) {
      const timer = setTimeout(() => {
        handleClosePopup();
      }, 1000);

      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [postStatus, handleClosePopup]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Add Competitor New Page Scan Request
        </DialogTitle>
        <TextField
          id="outlined-multiline-flexible"
          label="URL *"
          placeholder="Paste the URL of the page you would like to scan here"
          multiline
          color="secondary"
          size="small"
          fullWidth
          maxRows={4}
          onChange={(e) => {
            let value = e.target.value.trim();

            if (value.startsWith("http://") || value.startsWith("https://")) {
              // If it has http or https but no www
              if (!value.includes("://www.")) {
                const protocol = value.split("://")[0];
                const restOfUrl = value.split("://")[1];
                value = `${protocol}://www.${restOfUrl}`;
              }
            } else if (value.startsWith("www.")) {
              // If it starts with www but no protocol
              value = `https://${value}`;
            } else {
              // If it doesn't start with http or www
              value = `https://www.${value}`;
            }

            setSearchValue(value);
          }}
          disabled={isDomainAvailable === false && showDomainNotExist === true}
        />

        <Typography
          sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
        >
          Mandatory field
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "#00000099" }}>
          New Page Scan Requests are created with a default active state.
        </Typography>

        {Object.keys(postStatus).length >= 1 && (
          <Alert
            sx={{ alignItems: "center", mt: 1 }}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
          >
            Page Scan Requested successfully!
          </Alert>
        )}
        {validDomain === true && (
          <Alert severity="error" sx={{ alignItems: "center", mt: 1 }}>
            URL is not valid
          </Alert>
        )}
        {showDomainNotExist === true && (
          <DomaineDoesNotExist
            showDomainNotExist={showDomainNotExist}
            handleClosePopup={handleClosePopup}
            data={data}
            baseDomain={baseDomain}
            userToken={userToken}
            searchValue={searchValue}
          />
        )}
        {Object.keys(postStatus).length === 0 &&
          showDomainNotExist === false && (
            <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
              <Button
                color="secondary"
                onClick={handleCancelPopup}
                sx={{ mr: 1 }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!searchValue}
                onClick={checkDomainAvailability}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "REQUEST SCAN"
                )}
              </Button>
            </Stack>
          )}
      </Paper>
    </Dialog>
  );
};

export default CompetitorsPageScanRequestsModal;
