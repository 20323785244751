
import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import {AdditionalContentManualExpense} from "./AdditionalContentManualExpense";
import "./CustomTableManualExpence.css";


export interface ManulaExpenseData {
  tableHeadings: { [key: string]: string };
  manualexpenserows: any;
  trafficSources:any;
  manualExpenseTableDataUpdate:any;
  siteNames:any;
  result:any;
  selectedAsset:any;
  setSelectedAsset:any;
  selectedSource:any;
  setSelectedSource:any;
  fileBase64:any,
  setFileBase64:any,
  setFileName:any,
  fileName:any,
  selectedRows:any;
  setSelectedRows:any;
  oauthTokenHeader:string;
  isCardVisible:any;
  setIsCardVisible:any;
  selectedExpense:any;
  selectedDate:any;
  setSelectedDate:any
}


export default function CustomTableManualExpense({ tableHeadings, manualexpenserows, trafficSources,manualExpenseTableDataUpdate,siteNames,result,selectedAsset,selectedSource,
  fileBase64,setFileBase64,setFileName,fileName,selectedRows,setSelectedRows,oauthTokenHeader,isCardVisible,setIsCardVisible,selectedExpense,
  selectedDate,setSelectedDate
}: ManulaExpenseData) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const headCells = Object.entries(tableHeadings);
  const [singleExpneseShareUID , setSingleExpneseShareUID] = React.useState(null) ;
  // row is caling here
// Extract values from manualexpenserows?.data and filter out any falsy values
const manualExpenseTableData = Object.values(manualexpenserows?.data || []);
 manualExpenseTableDataUpdate = manualExpenseTableData.filter(Boolean).flat();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelectedRows([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row: any) => {
    setOpenRow(openRow === row.shared_uid ? null : row.shared_uid);
    setSingleExpneseShareUID(row.shared_uid); // Use the row's unique identifier
  };

  const handleCheckboxChange = (row: any) => {
    setSelectedRows((prevSelected: any) => {
      // Check if the row is already selected based on shared_uid
      const isRowSelected = prevSelected.some((selectedRow: any) => selectedRow.shared_uid === row.shared_uid);
      if (isRowSelected) {
        // If already selected, remove it
        return prevSelected.filter((selectedRow: any) => selectedRow.shared_uid !== row.shared_uid);
      } else {
        // Otherwise, add the whole row
        return [...prevSelected, row];
      }
    });
  };

  //filter function
  const filteredLogs = result.filter((log: any) => {
    const statusAssets = selectedAsset && selectedAsset.length !== 0
      ? selectedAsset.includes(log?.website_shared_uid)
      : true; // If no Asset is selected, treat it as a match
  
    const statusSource = selectedSource && selectedSource.length !== 0
      ? selectedSource.includes(log?.traffic_source__shared_uid)
      : true;
  
    const expenseName = selectedExpense && selectedExpense.length !== 0
      ? selectedExpense.includes(log?.expense_name)
      : true;
  
    const isDateInRange = selectedDate.length === 2 && selectedDate[0] && selectedDate[1]
      ? selectedDate[0] === selectedDate[1] // If both dates are the same, check for equality
        ? new Date(log?.date).toDateString() === new Date(selectedDate[0]).toDateString()
        : new Date(log?.date) >= new Date(selectedDate[0]) && 
          new Date(log?.date) <= new Date(new Date(selectedDate[1]).setHours(23, 59, 59, 999))
      : true;
  
    return statusAssets && statusSource && expenseName && isDateInRange;
  });
  
  React.useEffect(()=>{
    setPage(0);
  },[selectedAsset, selectedSource, selectedExpense])

  // State to manage the sorting key and direction
  const [sort, setSort] = React.useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({ keytoSort: undefined, direction: undefined });

  // Headers for the table columns with id, KEY for sorting, and LABEL for display
  const tableheaders = [
    { id: 1, KEY: 'expense_name', LABEL: 'Expense Name', sortable: true },
    { id: 2, KEY: 'related_asset', LABEL: 'Related to', sortable: true },
    { id: 3, KEY: 'source', LABEL: 'Source', sortable: true },
    { id: 4, KEY: 'date', LABEL: 'Date', sortable: true },
    { id: 5, KEY: 'clicks', LABEL: 'Clicks', sortable: true },
    { id: 6, KEY: 'impressions', LABEL: 'Impressions', sortable: true },
    { id: 7, KEY: 'expenses', LABEL: 'Expenses', sortable: true },
    { id: 8, KEY: 'created', LABEL: 'Created on', sortable: true },
    { id: 9, KEY: 'created_by', LABEL: 'Created_by', sortable: true },
    { id: 10, KEY: 'more_info', LABEL: 'More info', sortable: false },
  ];

  // Function to handle click on table headers
  function handleHeaderClick(header: any) {
    if (!header.sortable) return; // Ignore click if column is not sortable

    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  }
  // Function to get sorted data based on current sort state
  function getSortedData(arrayToSort: any[]) {
    // Apply the default sorting by 'modified' date if no sorting key is set
    if (!sort.keytoSort || !sort.direction) {
      return arrayToSort.slice().sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    }
  
    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
      // Check if sort.keytoSort is not undefined and has the property
      const keytoSort = sort.keytoSort;
  
      if (keytoSort === undefined) {
        return 0; // Return 0 if the sorting key is undefined
      }
  
      let aValue, bValue;
  
      if (keytoSort === 'related_asset') {
        aValue = siteNames.find((source: any) => source.key === a.website_shared_uid)?.site?.site_name || "";
        bValue = siteNames.find((source: any) => source.key === b.website_shared_uid)?.site?.site_name || "";
      } else if (keytoSort === 'source') {
        aValue = trafficSources.find((source: any) => source.shared_uid === a.traffic_source__shared_uid)?.traffic_source_name || "";
        bValue = trafficSources.find((source: any) => source.shared_uid === b.traffic_source__shared_uid)?.traffic_source_name || "";
      } else {
        aValue = a[keytoSort];
        bValue = b[keytoSort];
      }
  
      // Handle undefined values for sorting
      if (aValue === undefined) return 1;
      if (bValue === undefined) return -1;
      if (aValue === "") return 1;
      if (bValue === "") return -1;
  
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (aValue instanceof Date && bValue instanceof Date) {
        return sort.direction === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
      } else {
        return sort.direction === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }
    });
  }
  
  const sortedRows = getSortedData(filteredLogs || []);
  const paginatedLogs = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 
  return (
    <TableContainer className="chainsTableWrap" component={Paper}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
          {tableheaders.map((header) => (
            <TableCell key={header.id} align="left">
              <TableSortLabel
                active={sort.keytoSort === header.KEY}
                direction={sort.direction}
                onClick={() => handleHeaderClick(header)}
              >
                {header.LABEL}
              </TableSortLabel>
            </TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedLogs.length === 0 ? (
            <TableRow>
              <TableCell colSpan={tableheaders.length} align="center">
                No data available
              </TableCell>
            </TableRow>
          ): paginatedLogs
            .map((row: any, index: number) => (
              <>
                <React.Fragment key={row.shared_uid}>
                  <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}>
                    {headCells.map(([key], idx) => (
                      <TableCell key={idx} align="left">
                        {key === 'post_title' ? (
                          <>
                            {/* Add your checkbox component here */}
                            <Checkbox
                              color="secondary"
                              checked={selectedRows.some((selectedRow: any) => selectedRow.shared_uid === row.shared_uid)}
                              onChange={() => handleCheckboxChange(row)}
                            />
                            <Tooltip title={row?.expense_name || ""} arrow>
                              <span>{row?.expense_name?.split(' ').slice(0, 3).join(' ')}</span>
                            </Tooltip>
                          </>
                        ) : key === 'more_info' ? (
                          <TableCell key={`${row.cron_id}-${key}`} align="left" sx={{ border: "none" }}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              color="secondary"
                              onClick={() => handleRowClick(row)}
                            >
                              {openRow === row.shared_uid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        ) : key === 'impressions' ? (
                          <div>
                            {row[key] !== null ? row[key] : ""}
                          </div>
                        ) : key === 'related_asset' ? (
                          <div>
                            {
                              (siteNames.find((source: any) => source.key === row?.website_shared_uid)?.site?.site_name || "")
                            }
                          </div>
                        )
                          : key === 'clicks' ? (
                            <div>
                              {row[key] !== null ? row[key] : ""}
                            </div>
                          ) : key === 'created' ? (
                            <div>
                              {row[key] ? (() => {
                                const date = new Date(row[key]);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                              })() : ''}
                            </div>
                          ) : key === 'date' ? (
                            <div>
                              {row[key] ? (() => {
                                const date = new Date(row[key]);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                              })() : ''}
                            </div>
                          ) : key === 'created_by' ? (
                            <div>
                              {row[key] ? row[key] : ""}
                            </div>
                          ) : key === 'source' ? (
                            <div>
                              {row?.traffic_source__shared_uid
                                ? (trafficSources.find((source: any) => source.shared_uid === row.traffic_source__shared_uid)?.traffic_source_name || "")
                                : ""}
                            </div>
                          ) : (
                            row[key] ? row[key] : ""
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={Object.keys(tableHeadings).length + 1}>
                      <Collapse in={openRow === row.shared_uid} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px 0px' }}>
                          {/* Additional content here */}
                          <AdditionalContentManualExpense fileBase64={fileBase64} setFileBase64={setFileBase64} setFileName={setFileName} fileName={fileName}
                            singleExpneseShareUID={singleExpneseShareUID} oauthTokenHeader={oauthTokenHeader} siteNames={siteNames} setIsCardVisible={setIsCardVisible}
                            isCardVisible={isCardVisible} />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              </>
            ))}
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filteredLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={Math.ceil(filteredLogs.length / rowsPerPage)}
          onChange={(event, value) => handleChangePage(event, value - 1)}
          showFirstButton
          page={page+1}
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
    </TableContainer>
  );
}
