import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { PsiCompetitors } from "../../../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedEndPointLogic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import { useNavigate } from "react-router-dom";


interface DomaineDoesNotExistProps{
  showDomainNotExist:any;
  handleClosePopup:any;
  data:any;
  baseDomain:any;
  userToken:string;
  searchValue:string;
}
const DomaineDoesNotExist = ({showDomainNotExist,handleClosePopup,data,baseDomain,userToken,searchValue}:DomaineDoesNotExistProps) => {

  const competitorRelevance = [
    { label: "Brand" },
    { label: "Affiliate" },
    { label: "Other" }
  ];

  const uniqueCompanyNames = data && Array.from(new Set(data.map((value: any) => value?.company_name)));
  const [selectedCompetitor, setSelectedCompetitor] = useState(null) as any;
  const [radioValue, setRadioValue] = useState<string>('existingCompetitor'); 
  const [newCompetitorName, setNewCompetitorName] = useState<string>(''); // For new competitor name
  const [selectedRelevance, setSelectedRelevance] = useState<string | null>(null); // For new competitor relevance
  const [response,setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleRequestScanExisting = async () => {
    setLoading(true); // Set loading to true when API call starts
    if (selectedCompetitor) {
      const data = {
        "domain": searchValue,
        "domain_type": "Brand",
        "company_name": selectedCompetitor
        }
      //  API call
      await PsiCompetitors.postSerpCompetitor(userToken,data,setResponse);
      autoClosePopupAfterSuccess();
    }
    setLoading(false);
  };
  // const handleBack = () => {
  //   navigate('/market_positioning/scout/competitors_page_speed');
  // };
  const handleClosePopover = () => {
    setSelectedCompetitor(null);
    setNewCompetitorName("")
    setSelectedRelevance(null);
    handleClosePopup();
    setResponse([]);
  }

  const handleRequestScanNew = async () => {
    setLoading(true);
    if (newCompetitorName && selectedRelevance) {
      const data = {
        "domain": baseDomain,
        "domain_type": selectedRelevance,
        "company_name": newCompetitorName
        }
      // Example API call
      await PsiCompetitors.postSerpCompetitor(userToken,data,setResponse);
      autoClosePopupAfterSuccess();
    }
    setLoading(false);
  };

  const autoClosePopupAfterSuccess = () => {
    setTimeout(() => {
      handleClosePopover();
    }, 1000); // Close popup after 2 seconds
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          marginTop: "8px",
          color: "#000000DE",
          lineHeight: "24px",
          fontWeight: "500",
        }}
      >
        Looks like we've discovered a new competitor! <br></br>
        Please fill his name and define his relevance or add this URL to an
        existing one.
      </Typography>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={radioValue}
        onChange={(e) => setRadioValue(e.target.value)}
      >
        <Grid container spacing={1} alignItems="center" sx={{ my: 1, px:2 }}>
          <Grid item xs={4}>
            <FormControlLabel
              value="existingCompetitor"
              control={<Radio size="medium" color="secondary" />}
              label="Add to Existing Competitor"
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              fullWidth
              options={uniqueCompanyNames}
              onChange={(event, newValue) => setSelectedCompetitor(newValue)} // Set selected competitor
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Competitor List"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  color="secondary"
                />
              )}
              size="small"
              disabled={radioValue !== 'existingCompetitor'} // Disable if not selected
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              value="newCompetitor"
              control={<Radio size="medium" color="secondary" />}
              label="Create New Competitor"
            />
          </Grid>
          <Grid item xs={4}>
          <TextField color="secondary" size="small" fullWidth  placeholder="example competitor name" label="Competitor Name *"
           variant="outlined"
           onChange={(e) => setNewCompetitorName(e.target.value)} // Track new competitor name
           disabled={radioValue !== 'newCompetitor'}  />
          </Grid>
          <Grid item xs={4}>
          <Autocomplete
              fullWidth
              options={competitorRelevance.map((relevancename) => relevancename.label)}
              onChange={(event, newValue) => setSelectedRelevance(newValue)} // Track new competitor relevance
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Competitor relevance *"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  color="secondary"
                />
              )}
              size="small"
              disabled={radioValue !== 'newCompetitor'} // Disable if not selected
            />
          </Grid>
        </Grid>
      </RadioGroup>

      <Typography
        sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
      >
        Mandatory field
      </Typography>
      <Typography sx={{ fontSize: "12px", color: "#00000099" }}>
        New Page Scan Requests are created with a default active state.
      </Typography>
      {Object.keys(response).length===0 && showDomainNotExist === true && <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
          <Button color="secondary" onClick={handleClosePopover} sx={{ mr: 1 }}>
            CANCEL
          </Button>
          {/* <Button color="secondary" variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button> */}
          <Button variant="contained"
           color="secondary" 
           disabled={
            loading ||
            (radioValue === 'existingCompetitor' && !selectedCompetitor) ||
            (radioValue === 'newCompetitor' && (!newCompetitorName || !selectedRelevance))
          }// Enable if condition is met
          onClick={radioValue === 'existingCompetitor' ? handleRequestScanExisting : handleRequestScanNew} >
             {loading ? <CircularProgress size={24} color="inherit" /> : "REQUEST SCAN"}
          </Button>
        </Stack> }
        {Object.keys(response).length>=1 && <Alert
          sx={{ alignItems: "center", mt:1 }}
          iconMapping={{
            success: <CheckCircleOutlineIcon fontSize="inherit" />,
          }} >
          Page Scan Requested successfully!
        </Alert>}
    </>
  );
};

export default DomaineDoesNotExist;
