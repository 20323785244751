import { setDisplayErrorSnackBar, setUserData, setUserToken } from "../../features/userSlice";
import { getUserDetails } from "../../services/login.service";
import store from "../../store";
import { UserDetailsResponse } from "../../data/user/UserDetailsResponse";

export const softLogin = (refreshToken: string, setResponse:any): Promise<string | null> | null => {
    try {
        return getUserDetails(refreshToken)?.then((response: UserDetailsResponse) => {
            if (response?.status === 200) {
                store.dispatch(setUserData({ ...response }));
                store.dispatch(setUserToken(refreshToken));
                setResponse(response?.data);
                return refreshToken;
            } else {
                store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while getting user details', type: 'error' }));
                return null;
            }
        });
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while getting user details', type: 'error' }));
        return null;
    }
};
