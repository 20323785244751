import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./LogTable.css";
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LogTableAdditionalContent from "./LogTableAdditionalContent";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';



interface Props {
  tableHeadings: { [key: string]: string };
  logs: any;
  selectedStatus:any;
  selectedMethod:any;
  error:any;
  setRefresh:any;
  filteredLogsByLastRun:any;
  setSelectedIds:any;
  selectedIds:any;
  selectedName:any;
  setSelectedName:any;
  page:any;
  setPage:any;
}

export default function LogTable({ tableHeadings ,logs,selectedStatus,selectedMethod,error,setRefresh,filteredLogsByLastRun,setSelectedIds
  ,selectedName,page,setPage
}: Props) {
  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const headCells = Object.entries(tableHeadings);
  const [open, setOpen] = React.useState(true);
  const [runId, setRunId] = React.useState<number[]>([]);
  
  const navigate = useNavigate();

  const filteredLogs = logs.filter((log: any) => {
    const statusMatches = selectedStatus && selectedStatus.length !== 0
      ? selectedStatus.includes(log?.status)
      : true; // If no status is selected, treat it as a match
  
    const methodMatches = selectedMethod && selectedMethod.length !== 0
      ? selectedMethod.includes(log?.run_method)
      : true; // If no method is selected, treat it as a match 
  
    const lastRunMatches = filteredLogsByLastRun && filteredLogsByLastRun.length !== 0
      ? filteredLogsByLastRun.some((filteredLog: any) => filteredLog.run_id === log.run_id)
      : true; // Check if the log is part of the latest run

      const processNameMatches = selectedName
      ? selectedName === log.process_name
      : true; 
  
    return statusMatches && methodMatches && lastRunMatches && processNameMatches;
  });
  

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (index: number) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleRetry =() => {
    setRefresh(true);
  }

  const handleClose = () => {
    setOpen(false);
    navigate(`/`)
  }

  React.useEffect(()=>{
    setPage(0);
  },[selectedStatus,selectedMethod,setPage])

  const handleCheckboxChange = (process_id: number, run_id:any, isChecked: boolean) => {
    if (isChecked) {
      // Add the run_id to the selected array
      setSelectedIds((prevIds:any) => [...prevIds, process_id]);
      setRunId((prevIds:any) => [...prevIds, run_id]);
    } else {
      // Remove the run_id from the selected array
      setSelectedIds((prevIds:any) => prevIds.filter((id:any) => id !== process_id));
      setRunId((prevIds:any) => prevIds.filter((id:any) => id !== run_id));
    }
  };

  return (
    <>
    
    {error && Object.values(error).length >=1 ? <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {error}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Invalid Page Structure or Page Group Missing
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
          <Button onClick={handleClose} autoFocus color='secondary'>
            Back
          </Button>
          <Button onClick={handleRetry} autoFocus color='secondary'>
            Retry
          </Button>
        </DialogActions>
      </Dialog> : <TableContainer className="LogTableWrap" component={Paper} sx={{ maxHeight: 800 }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel>
                  {value && typeof value === "string" ? value : ""}
                </TableSortLabel>
              </TableCell>
            ))}
            {/* <TableCell align="center">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredLogs?.length!==0 && filteredLogs ? filteredLogs
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row:any, index:number) => (
              <React.Fragment key={row.cron_id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                {headCells.map(([key], idx) => (
                    <TableCell key={idx} align="left"
                      sx={{
                        backgroundColor: runId.includes(row?.run_id) ? "#F7EEF9" : "transparent",
                      }}>
                      {key === 'run_id' ? (
                        <>
                          <Checkbox size="small" color="secondary" 
                          checked={runId.includes(row?.run_id)}
                          onChange={(e) => handleCheckboxChange(row.process_id, row?.run_id, e.target.checked)}/>
                          {row[key]}
                        </>
                      ) : key === 'info' ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            color="secondary"
                            onClick={() => handleRowClick(index)}
                            disabled
                          >
                            {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                      ) : key ==='status' ? (
                          <Alert
                          icon={row?.status === 'SUCCESS' ? <CheckCircleOutlineIcon  sx={{color:"#1E4620", width:"16px",height:"16px"}}/> : row?.status === 'RUNNING' ?
                             <AutorenewOutlinedIcon  sx={{color:"#7B1FA2", width:"16px",height:"16px"}}/> : row?.status === 'WARNING' ? <HighlightOffIcon  sx={{color:"#ED6C02", width:"16px",height:"16px"}}/>
                              : row?.status === "PENDING" ? <PendingOutlinedIcon  sx={{color:"#014361", width:"16px",height:"16px"}}/> : <CancelOutlinedIcon  sx={{color:"#5F2120", width:"16px",height:"16px"}}/>}
                              sx={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                width: "84px",
                                padding: "3px",
                                border:"1px solid",
                                color: row?.status === 'SUCCESS' ? "#1E4620" 
                                : row?.status === 'RUNNING' ? "#7B1FA2"
                                : row?.status === 'WARNING' ? "#ED6C02"
                                : row?.status === "PENDING" ? "#014361" 
                                : row?.status === "CANCELLED" ? "#5F2120"
                                : row?.status === "FAILURE" ? "#5F2120"
                                : "defaultBackgroundColor" , 
                                borderColor: row?.status === 'SUCCESS' ? "#2E7D3280" 
                                : row?.status === 'RUNNING' ? "#9C27B01F"
                                : row?.status === 'WARNING' ? "#ED6C0280"
                                : row?.status === "PENDING" ? "#2196F380" 
                                : row?.status === "CANCELLED" ? "#D32F2F80"
                                : row?.status === "FAILURE" ? "#D32F2F80"
                                : "defaultBackgroundColor" ,
                                backgroundColor: row?.status === 'SUCCESS' ? "#EDF7ED" 
                                  : row?.status === 'RUNNING' ? "#F3E5F5"
                                  : row?.status === 'WARNING' ? "#FFF4E5"
                                  : row?.status === "PENDING" ? "#E5F6FD" 
                                  : row?.status === "CANCELLED" ? "#FDEDED"
                                  : row?.status === "FAILURE" ? "#FDEDED"
                                  : "defaultBackgroundColor"  // A default background color for any other status
                              }}
                            variant="outlined"
                          >
                            {row?.status === 'SUCCESS' ? "Success" : row?.status === 'RUNNING' ? "Running" : row?.status === 'WARNING' ? "Warning" : row?.status === 'PENDING' ? "Pending" : row?.status === 'CANCELLED' ? "Cancelled" : "Failure"}
                          </Alert>
                      ) : key ==='response' ? (
                        // underline="always" rel='noreferrer' color="secondary"
                        <RouterLink to={"etl-process-log-Response"} 
                          state={{
                            runId : row?.run_id
                          }}
                          className="custom-link">
                          View Log
                        </RouterLink>
                      ) : key ==='run_by' ? (
                        <div style={{display:"flex",alignItems:"center",gap:5}}>
                          <Avatar sx={{ width: 24, height: 24 }}>{row[key] && row[key].charAt(0)}</Avatar>
                          {row[key] ? row[key] : "N/A" }
                        </div>
                      ) : key ==='start' ? (
                        <>
                        {new Date(row?.start_time).toLocaleString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                        </>
                      ): key ==='end' ? (
                        <>
                        {new Date(row?.end_time).toLocaleString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                        </>
                      ) : key ==='created' ? (
                        <>
                        {row?.record_created}
                        </>
                      ) : key ==='updated' ? (
                        <>
                        {row?.record_updated}
                        </>
                      ): key ==='fetched' ? (
                        <>
                        {row?.record_total}
                        </>
                      ): key ==='method' ? (
                        <>
                        {row?.run_method ? row?.run_method : "N/A"}
                        </>
                      ) :key ==='process_name' ? (
                        <>
                        {row?.process_name? row?.process_name : "N/A"}
                        </>
                      ): (
                        row[key] ? row[key] : "N/A"
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={Object.keys(tableHeadings).length + 1}>
                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                      <Box sx={{ padding: '20px 0px' }}>
                        {/* Additional content here */}
                        <LogTableAdditionalContent rowData={row}/>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )) : <TableRow><TableCell sx={{margin:"10px"}}>No data to display</TableCell></TableRow> }
            
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filteredLogs?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={Math.ceil(filteredLogs?.length/rowsPerPage)}
          onChange={(event, value) => handleChangePage(event, value - 1)}
          page={page+1}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
    </TableContainer>}
    
    </>
  );
}
