import {
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  Paper,
  TextField,
  Snackbar,
  Alert,
  Menu,
  MenuItem
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ComputerIcon from "@mui/icons-material/Computer";
import TabletIcon from "@mui/icons-material/Tablet";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LinkIcon from "@mui/icons-material/Link";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect, useRef } from "react";





export interface ComponentOptionsProps {
  handleClickEditElement: any;
  handelClickDeleteElement: any;
  handelCLickSaveElement: any;
  pageDictionary: any;
  index: number;
  element: any;
  setIsElementSaved: any;
  setIsPublishOpen: any;
  indexvalue:any;
  isDragged:boolean;
  setIsDragged:any
}

export default function ComponentOptions({
  handleClickEditElement,
  handelClickDeleteElement,
  handelCLickSaveElement,
  pageDictionary,
  index,
  element,
  setIsElementSaved,
  setIsPublishOpen,
  indexvalue,
  isDragged,
  setIsDragged
  
}: ComponentOptionsProps) {
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const paperRef = useRef<HTMLDivElement>(null);
  const [, setSelectedText] = useState("");
  const [appliedStyle, setAppliedStyle] = useState("");
  const [, setTheme] = useState(null);
  const [lang, setLang] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const savedSelectionRef = useRef<Range | null>(null);
  
  useEffect(() => {
    const data = JSON.parse(pageDictionary);
    const themeValue = data?.settings?.theme;
    const langValue = data?.settings?.lang;
    setTheme(themeValue);
    setLang(langValue);
  }, [pageDictionary]);


  const shortCodeArray = lang === "en" ? [
    {"YYYY":"<span class='sc_date'>[date-today format='Y']</span>"},
    {"Month YYYY":"<span class='sc_date'>[date-today format='F Y']</span>"},
    {"Month DD, YYYY":"<span class='sc_date'>[date-today format='F j, Y']</span>"},
    {"MMM YYYY":"<span class='sc_date'>[date-today format='M Y']</span>"},
    {"MM/DD/YYYY":"<span class='sc_date'>[date-today format='m/d/Y']</span>"},
    {"DD/MM/YYYY":"<span class='sc_date'>[date-today format='d/m/Y']</span>"}
  ] : [
    {"YYYY":"<span class='sc_intl_date'>[intl-date-today format='Y']</span>"},
    {"Month YYYY":"<span class='sc_intl_date'>[intl-date-today format='F Y']</span>"},
    {"Month DD, YYYY":"<span class='sc_intl_date'>[intl-date-today format='F j, Y']</span>"},
    {"MMM YYYY":"<span class='sc_intl_date'>[intl-date-today format='M Y']</span>"},
    {"MM/DD/YYYY":"<span class='sc_intl_date'>[intl-date-today format='MM/dd/YYYY']</span>"},
    {"DD/MM/YYYY":"<span class='sc_intl_date'>[intl-date-today format='dd/MM/YYYY']</span>"}
  ]

  const regexArray = [
    /\b\d{2}\/\d{2}\/\d{4}\b/gi, // DD/MM/YYYY
    /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}\b/gi, // MMM YYYY
    /\b\d{1,2}(st|nd|rd|th) (Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, // 1st Jan 2024
    /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi, // MMM YYYY
    /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{1,2}, \d{4}\b/gi, // Jan 1, 2024
    /\b\d{4}\b/gi, // Year
    /\b\d{2}\/\d{2}\/\d{4}\b/g,
    /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}\b/gi,
    /\b\d{1,2}(st|nd|rd|th) (Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi,
     /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{4}\b/gi,
     /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) \d{1,2}, \d{4}\b/gi,
     /\b\d{4}\b/gi,
    /\[(date|intl-date).*?\]/g
  ];
  

  

  const elementClickedUpdate = (state: boolean) => {
    setIsEditClicked(state);
  };

  useEffect(() => {
    if (isEditClicked && !isDragged) {
      setOpenPopup(true);
    } else if (indexvalue === index && isDragged) {
      setOpenPopup(true);
    }
    // eslint-disable-next-line
  }, [isEditClicked,index,indexvalue,isDragged]);

  const handleClosePopup = () => {
    setOpenPopup(false);
    setIsDragged(false);
  };

  const handleSaveChanges = (textChanged: string) => {
    const cleanedText = removeEmptyTags(textChanged);  
    if (cleanedText.trim().length === 0) {
      // If the cleaned text is empty, delete the element
      handelClickDeleteElement(index);
    } else {
      // Save changes if the cleaned text is not empty
      handelCLickSaveElement(cleanedText, index, elementClickedUpdate);
    }
  
    handleClosePopup();
    setIsElementSaved(true);
    setIsPublishOpen(true);
  };
  

  const removeEmptyTags = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    // Select empty tags to remove
    const emptyTags = tempDiv.querySelectorAll("strong:empty, em:empty, u:empty, a:empty , span:empty");
    emptyTags.forEach((tag) => {
      if (tag.parentNode) {
        tag.parentNode.removeChild(tag);
      }
    });
    // Return cleaned HTML
    return tempDiv.innerHTML;
  };

  const handleCancelChanges = () => {
    elementClickedUpdate(false);
    handleClosePopup();
  };

  const checkedIsEditable = () => {
    if (element) {
      if (
        !element?.props?.className?.includes("external-widget") &&
        !element?.props?.className?.includes("img") &&
        !element?.props?.className?.includes("htmlTemplate") &&
        !element?.props?.className?.includes("sponsors-template")
      ) {
        return true;
      }
    }
    return false;
  };

  const getSelectedText = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      setSelectedText(selection.toString());
      const parentTag = selection.anchorNode?.parentNode as HTMLElement;
      if (parentTag && parentTag.tagName.toLowerCase() === "strong") {
        setAppliedStyle("strong");
      } else if (parentTag && parentTag.tagName.toLowerCase() === "em") {
        setAppliedStyle("em");
      } else if (parentTag && parentTag.tagName.toLowerCase() === "u") {
        setAppliedStyle("u");
      } else {
        setAppliedStyle("");
      }
    }
  };

  const wrapSelectionWith = (tag: string) => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = selection.toString();

      // Create a new tag and wrap the selected text
      const span = document.createElement(tag);
      span.appendChild(document.createTextNode(selectedText));
      range.deleteContents();
      range.insertNode(span);

      // Collapse the range to place the cursor outside the wrapped text
      range.setStartAfter(span);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);

      // Update the applied style
      setAppliedStyle(tag);
    }
  };

  const toggleStyle = (tag: string) => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString();
      if (selectedText.trim().length === 0) {
        // No text is selected, do nothing
        return;
      }

      const parentTag = selection.anchorNode?.parentNode as HTMLElement;
      if (parentTag && parentTag.tagName.toLowerCase() === tag) {
        // Remove the tag if it's already applied
        const grandParent = parentTag.parentNode;
        if (grandParent) {
          while (parentTag.firstChild) {
            grandParent.insertBefore(parentTag.firstChild, parentTag);
          }
        }
        setAppliedStyle("");
      } else {
        wrapSelectionWith(tag);
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
  
    // Get the current selection
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      // Get the first range of the selection
      const range = selection.getRangeAt(0);
  
      // Delete the contents of the range
      range.deleteContents();
  
      // Create a text node with the pasted text
      const textNode = document.createTextNode(text);
  
      // Insert the text node at the range
      range.insertNode(textNode);
  
      // Move the cursor to the end of the inserted text node
      range.setStartAfter(textNode);
      range.collapse(true);
  
      // Clear all ranges and add the updated range
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };
  

  const toggleBold = () => {
    toggleStyle("strong");
  };

  const toggleItalic = () => {
    toggleStyle("em");
  };

  const toggleUnderline = () => {
    toggleStyle("u");
  };

  const toggleAnchorTag = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const parentTag = selection.anchorNode?.parentNode as HTMLElement;

      if (parentTag && parentTag.tagName.toLowerCase() === "a") {
        // Remove the anchor tag if it's already applied
        const grandParent = parentTag.parentNode;
        while (parentTag.firstChild) {
          grandParent && grandParent.insertBefore(parentTag.firstChild, parentTag);
        }
        // grandParent && grandParent.removeChild(parentTag);
        setAppliedStyle("");
      }
    }
  };

  const insertLink = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0 && selection.toString().trim() !== "") {
      savedSelectionRef.current = selection.getRangeAt(0);
      setOpenLinkDialog(true);
    } else {
      setSnackbarMessage("Please select some text first.");
      setSnackbarOpen(true);
    }
  };

  const handleLinkDialogClose = () => {
    setOpenLinkDialog(false);
    setLinkUrl("");
  };

  const handleLinkInsert = () => {
    if (savedSelectionRef.current) {
      const range = savedSelectionRef.current;
      const selectedContent = range.extractContents();
      const anchor = document.createElement("a");
      anchor.href = linkUrl;
      anchor.target = "_blank";
      anchor.appendChild(selectedContent);
      range.insertNode(anchor);

      // Collapse the range to place the cursor outside the wrapped text
      range.setStartAfter(anchor);
      range.collapse(true);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
    handleLinkDialogClose();
  };

  const clearTags = () => {
    if (paperRef.current) {
      const html = paperRef.current.innerHTML;
      const div = document.createElement("div");
      div.innerHTML = html;

      const elements = div.querySelectorAll("strong, em, u, a");
      elements.forEach((el) => {
        const parent = el.parentNode;
        if (parent && parent.contains(el)) {
          while (el.firstChild) {
            parent.insertBefore(el.firstChild, el);
          }
          parent.removeChild(el);
        }
      });

      paperRef.current.innerHTML = div.innerHTML;
      setAppliedStyle("");
    }
  };

  const handleDataArrayIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShortCodeSelect = (shortCodeKey: string, shortCodeValue: string) => {
    if (paperRef.current) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Create a span element with the shortCodeKey as its content
            const span = document.createElement("span");
            span.textContent = shortCodeKey;

            // Insert the span element into the range
            range.deleteContents();
            range.insertNode(span);

            // Move the cursor to the end of the inserted span
            range.setStartAfter(span);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);

            // Replace the span's content with the corresponding shortcode value
            span.outerHTML = shortCodeValue;
        }
    }
    handleMenuClose();
};

const handleKeyDown = (e: any) => {
  if (e.key === 'Backspace' || e.key === 'Delete') {
    const contentEditableElement = paperRef.current;

    if (contentEditableElement) {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;

      const range = selection.getRangeAt(0);
      const startNode = range.startContainer;
      const endNode = range.endContainer;

      if (!startNode || !endNode) return;

      // Define your regexArray


      // Helper function to find and replace the text
      const replaceTextInNode = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          let text = node.nodeValue || '';

          // Iterate through regexArray and replace matching text
          regexArray.forEach((regex) => {
            if (regex.test(text)) {
              text = text.replace(regex, '');
              node.nodeValue = text;
            }
          });
        }
      };

      // Process nodes within the selected range
      const processRangeNodes = (range: Range) => {
        let node = range.startContainer as any;
        const endNode = range.endContainer;

        while (node && node !== endNode) {
          replaceTextInNode(node);
          node = node.nextSibling;
        }
        replaceTextInNode(endNode);
      };

      // Replace shortcodes within the selected range
      processRangeNodes(range);

      // Ensure the selection is updated
      try {
        selection.removeAllRanges();
        selection.addRange(range);
      } catch (error) {
        console.error('Error during selection update:', error);
      }
    }
  }
};




  return (
    <Box>
      <div
        className="elementOptions"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "4px",
          marginBottom: "4px",
          padding: "12px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          

          {
            element &&
            element.props &&
            element.props.className?.includes("internal-widget") &&
            element.props.className !== "main date internal-widget" && (
              <Typography  className="card-title">
                <>
                <strong>{element?.props?.widget_type}</strong> : <em>[{element?.props?.widget_name}]</em>
                </>
              </Typography>
            )
          }
          {
            element &&
            element.props &&
            !element.props.className?.includes("internal-widget desktop") &&
            !element.props.className?.includes("internal-widget mobile") &&
            !element.props.className?.includes("internal-widget tablet") && (
              <Typography className="card-title">
                <>
                <strong>Element : </strong>
        <em>
          {element?.props?.children?.type !== undefined
            ? element?.props?.children?.type
            : element?.props?.children[1]?.type}
        </em>
                </>
              </Typography>
            )
          }
          
          <div>
            {element?.props?.className?.includes("desktop") && (
              <Tooltip title="Desktop">
                <IconButton aria-label="tooltip-1" disabled>
                  <ComputerIcon sx={{ marginRight: 1, fontSize: "small" }} />
                </IconButton>
              </Tooltip>
            )}
            {element?.props?.className?.includes("tablet") && (
              <Tooltip title="Tablet">
                <IconButton aria-label="tooltip-1" disabled>
                  <TabletIcon sx={{ marginRight: 1, fontSize: "small" }} />
                </IconButton>
              </Tooltip>
            )}
            {element?.props?.className?.includes("mobile") && (
              <Tooltip title="Mobile">
                <IconButton aria-label="tooltip-2" disabled>
                  <PhoneIphoneIcon sx={{ marginRight: 1, fontSize: "small" }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          {!isEditClicked && checkedIsEditable() && (
            <IconButton
              id="editButton"
              aria-label="Edit"
              onClick={() => handleClickEditElement(index, elementClickedUpdate)}
              style={{ marginRight: "8px" }}
            >
              <EditIcon sx={{ color: "#9C27B0" }} />
            </IconButton>
          )}
          {!isEditClicked && checkedIsEditable() && (
            <IconButton id="deleteButton" aria-label="Delete" onClick={() => handelClickDeleteElement(index)}>
              <DeleteIcon sx={{ color: "#9C27B0" }} />
            </IconButton>
          )}
        </div>
      </div>
      <Dialog open={openPopup} maxWidth="lg">
        <DialogTitle height="64px">Edit Text</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", minWidth: "1150px", width: "100%", wordBreak: "break-word" }}>
          <div style={{ display: "flex", gap: "5px", marginBottom: "10px" , justifyContent:"left" , alignItems:"center" }}>
            <div style={{borderRight:"1px black solid",display:"flex",gap:"8px"}}>
              <Tooltip title="Bold">
                <FormatBoldIcon onClick={toggleBold} sx={{ cursor: "pointer", color: appliedStyle === "strong" ? "#9C27B0" : "inherit" }} />
              </Tooltip>
              <Tooltip title="Italic">
                <FormatItalicIcon onClick={toggleItalic} sx={{ cursor: "pointer", color: appliedStyle === "em" ? "#9C27B0" : "inherit" }} />
              </Tooltip>
              <Tooltip title="Underline" style={{marginRight:"8px"}}>
                <FormatUnderlinedIcon onClick={toggleUnderline} sx={{ cursor: "pointer", color: appliedStyle === "u" ? "#9C27B0" : "inherit" }} />
              </Tooltip>
              </div>
              <Tooltip title="Shortcode">
                <IconButton onClick={handleDataArrayIconClick} sx={{ cursor: "pointer",color:"#000000DE" }}>
                  <InsertInvitationIcon fontSize="medium"/>
                  <ExpandMoreIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
              <div style={{borderLeft:"1px black solid",display:"flex",gap:"8px"}}>
              <Tooltip title="Insert Link" style={{marginLeft:"8px"}}>
                <LinkIcon onClick={insertLink} sx={{ cursor: "pointer" }} />
              </Tooltip>
              <Tooltip title="Remove Anchor Tag">
                <LinkOffIcon onClick={toggleAnchorTag} sx={{ cursor: "pointer" }} />
              </Tooltip>
              <Tooltip title="Clear Tags">
                <FormatClearIcon onClick={clearTags} sx={{ cursor: "pointer" }} />
              </Tooltip>
              </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {shortCodeArray.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={() => handleShortCodeSelect(Object.keys(item)[0], Object.values(item)[0])}
                    >
                      {Object.keys(item)[0]}
                    </MenuItem>
                  ))}
                </Menu>
            </div>
            <Paper
              ref={paperRef}
              id={`Paper-element-${index}`}
              onMouseUp={getSelectedText}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
              contentEditable={true}
              style={{
                padding: "16px",
                border: "2px #0000003B solid",
                marginBottom: "24px",
                minHeight: "176px"
              }}
            >
              {element}
            </Paper>
            
            <Box sx={{display:"flex",justifyContent:"right"}}>
              <Button
                // variant="outlined"
                color="secondary"
                onClick={handleCancelChanges}
                style={{ marginRight: "16px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<SaveIcon />}
                onClick={() => handleSaveChanges(paperRef.current && paperRef.current.innerHTML.length > 0 ? paperRef.current.innerHTML : "")}
              >
                Save Changes
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={openLinkDialog} onClose={handleLinkDialogClose}>
        <DialogTitle>Insert Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="URL"
            type="url"
            fullWidth
            value={linkUrl}
            placeholder="https://example.com/"
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
            <Button onClick={handleLinkDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleLinkInsert} color="primary" variant="contained" style={{ marginLeft: "8px" }}>
              Insert
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%', zIndex:"9999999" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
