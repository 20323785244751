import "./Monitoring.css";
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { MonitoringEndPointLogic } from "../../../../logic/AssetManagement/Health/Monitoring/MonitoringEndPointLogic";
import { useEffect, useState } from "react";
import "./Monitoring.css";

interface Props {
  selectedSharedUid: any;
  selectedVersion: any;
  setisDataAvilable: any;
  isDataAvilable: any;
  startDate: any;
  endDate: any;
  token: any;
  setLoading: any;
  search: any;
  setSearch: any;
  loading: any;
  dataShow: any;
  tableData: any;
  isPreviousDataAvilable: any;
  setIsPreviousDataAvilable: any;
}

const DowntimeCalculator = ({ selectedSharedUid, selectedVersion, startDate, endDate, token, setLoading,
  search, setSearch, setisDataAvilable, isDataAvilable, dataShow, tableData, isPreviousDataAvilable, setIsPreviousDataAvilable }: Props) => {

  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [, setError] = useState([]);
  const [filteredData, setFilteredData] = useState([]) as any;
  const [previousStartDate, setPreviousStartDate] = useState("");
  const [previousEndDate, setPreviousEndDate] = useState("");
  const [averageAvailabilityPercentage, setAverageAvailabilityPercentage] = useState(0) as any;
  const [formattedAvailability, setFormattedAvailability] = useState(0);

  useEffect(() => {
    if (search === true && selectedSharedUid.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getMonitoring(
              token,
              selectedSharedUid,
              startDate,
              endDate,
              setData,
              setError,
              setisDataAvilable
            )
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
        } finally {
          // setLoading(false);
          setSearch(false);
        }
      };

      fetchMonitoringData();
    }
    // eslint-disable-next-line
  }, [token, selectedSharedUid, search]);

  useEffect(() => {
    if (search === true && selectedVersion.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getSystemMonitoring(
              token,
              startDate,
              endDate,
              setData,
              setError,
              setisDataAvilable
            )
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
        } finally {
          // setLoading(false);
          setSearch(false);
          // setisDataAvilable(false);
        }
      };

      fetchMonitoringData();
    }
    // eslint-disable-next-line
  }, [token, selectedVersion, search]);

  useEffect(() => {
    if (selectedVersion.length !== 0) {
      const newFilteredData = data.filter((row: any) =>

        selectedVersion === (row?.service_type)
      );
      setFilteredData(newFilteredData);
    } else {
      setFilteredData([]); // Reset to original data if no version is selected
    }
  }, [selectedVersion, data, search]);

  const dataToDisplay = selectedVersion.length !== 0 ? filteredData : data || [];

  const convertToMinutes = (duration: any) => {
    let days = 0, hours = 0, minutes = 0, seconds = 0;

    if (duration.includes("days")) {
      const parts = duration.split(", ");
      const dayPart = parts[0].split(" ")[0]; // Extract number of days
      days = parseInt(dayPart);
      duration = parts[1]; // Update to just HH:MM:SS
    }

    const timeParts = duration.split(":");
    hours = parseInt(timeParts[0]);
    minutes = parseInt(timeParts[1]);
    seconds = parseInt(timeParts[2]);

    // Total duration in minutes (including seconds as fractional minutes)
    return days * 24 * 60 + hours * 60 + minutes + seconds / 60;
  };

  // Calculate the recent total downtime in minutes (including seconds)
  const totalDowntimeMinutes = dataToDisplay.reduce((total: any, incident: any) => {
    return total + convertToMinutes(incident.downtime_duration);
  }, 0);


  // calculate the previous downtime in minutes
  const previoustotalDowntimeMinutes = previousData.reduce((total: any, incident: any) => {
    return total + convertToMinutes(incident.downtime_duration);
  }, 0);

  // Calculate the average downtime minutes
  const averageprevioustotalDowntime = ((previoustotalDowntimeMinutes / previousData.length) * 100) / 100;
  const averageDowntime = dataToDisplay.length > 0
    ? totalDowntimeMinutes / dataToDisplay.length
    : 0;

  // Total Downtime (Minutes) previous quater and recent quarter comparison formulla
  const totaldowntimecompare = previousData.length === 0
    ? 0
    : data.length === 0 || previoustotalDowntimeMinutes > totalDowntimeMinutes
      ? -((previoustotalDowntimeMinutes - totalDowntimeMinutes) / previoustotalDowntimeMinutes) * 100
      : ((previoustotalDowntimeMinutes - totalDowntimeMinutes) / previoustotalDowntimeMinutes) * 100;


  // Average Downtime (Minutes) previous quater and recent quarter comparison formulla
  const averagedowntimecompare = averageprevioustotalDowntime === 0
    ? 0
    : data.length === 0 || averageprevioustotalDowntime > averageDowntime
      ? -((averageprevioustotalDowntime - averageDowntime) / averageprevioustotalDowntime) * 100
      : ((averageprevioustotalDowntime - averageDowntime) / averageprevioustotalDowntime) * 100;


      const calculateTimeBetweenDates = (startDate: string, endDate: string) => {
        // Create Date objects from the input strings
        const start = new Date(startDate);
        const end = new Date(endDate);
      
        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end.getTime() - start.getTime();
      
        // Convert milliseconds to days and minutes
       // 1 day = 24 hours * 60 minutes * 60 seconds * 1000 ms
        const differenceInMinutes = differenceInMilliseconds / (1000 * 60); // 1 minute = 60 seconds * 1000 ms
      
        return differenceInMinutes// Total minutes
        
      };



  useEffect(() => {
    // Calculate minutes between startDate and endDate for the recent period
    const minutesBetween = calculateTimeBetweenDates(startDate, endDate);
    const secondsBetween = minutesBetween * 60;

    const downtimeSeconds = totalDowntimeMinutes * 60;
    const availabilityPercentage = Math.floor(((secondsBetween - downtimeSeconds) / (secondsBetween))*10000);

    const previousavailabilityPercentage = Math.floor(((secondsBetween - (previoustotalDowntimeMinutes*60)) / (secondsBetween))*10000);

    // Format availability percentages
    setFormattedAvailability(availabilityPercentage/100);

    setAverageAvailabilityPercentage((availabilityPercentage-previousavailabilityPercentage)/100);
    // eslint-disable-next-line
  }, [data, previousData]);

  const calculateDaysBetween = (start: Date, end: Date) => {
    const diffInMs = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  };

  // Utility function to subtract a number of days from a date
  const subtractDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - (days + 1));

    // Format the date to 'YYYY-MM-DD'
    const formattedDate = newDate.toISOString().slice(0, 10); // Slices the string to return only 'YYYY-MM-DD'

    return formattedDate;
  };

  // Effect to calculate the previous period (new start and end date)
  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the duration between startDate and endDate
      const daysBetween = calculateDaysBetween(start, end);

      // Calculate the new start and end dates for the previous period
      setPreviousStartDate(subtractDays(start, daysBetween));
      setPreviousEndDate(subtractDays(end, daysBetween));
    }
    //eslint-disable-next-line
  }, [startDate, endDate, dataShow]);

  // useEffect for previous quarter 
  useEffect(() => {
    if (selectedSharedUid.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getPreviousMonitoring(
              token,
              selectedSharedUid,
              previousStartDate,
              previousEndDate,
              setPreviousData,
              setError,
              setIsPreviousDataAvilable
            );
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
        } finally {
          setLoading(false);
          setSearch(false);
          // setisDataAvilable(false);
        }
      };

      fetchMonitoringData();
    }
    // eslint-disable-next-line
  }, [token, search, isDataAvilable, setIsPreviousDataAvilable, selectedSharedUid, setLoading, setSearch, tableData.length]);

  useEffect(() => {
    if (selectedVersion.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getSystemMonitoring(
              token,
              previousStartDate,
              previousEndDate,
              setPreviousData,
              setError,
              setisDataAvilable
            )
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
        } finally {
          setLoading(false);
          setSearch(false);
        }
      };

      fetchMonitoringData();
    }
    // eslint-disable-next-line
  }, [token, search, isDataAvilable, selectedSharedUid, setLoading, setSearch, tableData]);

  const percentageChange = previousData.length === 0
    ? 0
    : data.length === 0
      ? ((data.length - previousData.length) / previousData.length) * 100
      : ((data.length - previousData.length) / previousData.length) * 100;


  // Select the appropriate icon based on the percentage change
  let percentChangeicon;


  if (percentageChange === 0 || isNaN(percentageChange) || !isFinite(percentageChange)) {
    // Show flat icon for 0, NaN, or Infinity
    percentChangeicon = <TrendingFlatIcon sx={{ height: "25px", mr: '5px', color: "#000000" }} />;
  } else if (percentageChange > 0) {
    percentChangeicon = <TrendingUpIcon sx={{ height: "25px", mr: '5px', color: "#D32F2F" }} />;
  } else if (percentageChange < 0) {
    percentChangeicon = <TrendingDownIcon sx={{ height: "25px", mr: '5px', color: "#2E7D32" }} />;
  }


  // Select the appropriate icon based on the total downtime change
  let totaldowntimeAndaverageCompareicon;

  if (
    totaldowntimecompare === 0 || averagedowntimecompare === 0 ||
    isNaN(totaldowntimecompare) || isNaN(averagedowntimecompare) ||
    !isFinite(totaldowntimecompare) || !isFinite(averagedowntimecompare)
  ) {
    // Show flat icon for 0, NaN, or Infinity
    totaldowntimeAndaverageCompareicon = <TrendingFlatIcon sx={{ height: "25px", mr: '5px', color: "#000000" }} />;
  } else if (totaldowntimecompare > 0 || averagedowntimecompare > 0) {
    // Show down icon if either value is positive#D32F2F
    totaldowntimeAndaverageCompareicon = <TrendingUpIcon sx={{ height: "25px", mr: '5px', color: "#D32F2F" }} />;
  } else if (totaldowntimecompare < 0 || averagedowntimecompare < 0) {
    // Show up icon if either value is negative
    totaldowntimeAndaverageCompareicon = <TrendingDownIcon sx={{ height: "25px", mr: '5px', color: "#2E7D32" }} />;
  }


  // Select the Availability Percentage icon based on the total downtime change

  let availabilityPercentageicon;

  if (
    averageAvailabilityPercentage === 0 ||
    isNaN(averageAvailabilityPercentage) ||
    !isFinite(averageAvailabilityPercentage)
  ) {
    // Show flat icon for 0, NaN, or Infinity
    availabilityPercentageicon = <TrendingFlatIcon sx={{ height: "25px", mr: '5px', color: "#000000" }} />;
  } else if (averageAvailabilityPercentage > 0) {
    // Show up icon if the value is positive
    availabilityPercentageicon = <TrendingUpIcon sx={{ height: "25px", mr: '5px', color: "#2E7D32" }} />;
  } else if (averageAvailabilityPercentage < 0) {
    // Show down icon if the value is negative
    availabilityPercentageicon = <TrendingDownIcon sx={{ height: "25px", mr: '5px', color: "#D32F2F" }} />;
  }

  return (
    <>
      {(<Grid container spacing={2} alignItems="center" sx={{ my: "0px" }}>
        <Grid item xs={3}>
          <Paper className="monitoringFiltersWrap" sx={{ padding: "16px" }}>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ color: '#9C27B0' }}>Downtime Incidents</Typography>
            </Box>
            <Box sx={{ my: '8px' }}>
              <Typography align="center" variant="h2" sx={{}}>{isFinite(dataToDisplay.length) ? dataToDisplay.length : "0"}</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.9rem', minHeight: "84px" }}>The number of times the website was unavailable during the selected period.</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "left", alignItems: "center", mt: "10px" }}>
              <Typography
                sx={{
                  fontSize: '.9rem',
                  color: !isFinite(percentageChange) || percentageChange === 0
                    ? "#000000"
                    : percentageChange < 0
                      ? "#2E7D32"
                      : "#D32F2F",
                  display: 'flex',
                  justifyContent: "left",
                  alignItems: "center",
                  mr: '5px'
                }}
              ><>
                  {percentageChange !== 0 ? percentChangeicon : ""}
                  {percentageChange !== 0 ? `${percentageChange.toFixed(2)}%` : "No Incidents found in the previous Quarter"}
                </></Typography>
              {percentageChange !== 0 && <Typography sx={{ fontSize: '.9rem', ms: '5px' }}> compared to the previous quarter. </Typography>}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="monitoringFiltersWrap" sx={{ padding: "16px" }}>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ color: '#9C27B0' }}>Total Downtime (Minutes)</Typography>
            </Box>
            <Box sx={{ my: '8px' }}>
              <Typography align="center" variant="h2">
                {isFinite(totalDowntimeMinutes)
                  ? `${Math.floor(totalDowntimeMinutes)} min, ${(totalDowntimeMinutes % 1 * 60).toFixed(0)} sec`
                  : "0 min, 0 sec"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.9rem', minHeight: "84px" }}>The total amount of minutes the website was unavailable during the selected period.</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "left", alignItems: "center", mt: "10px" }}>
              <Typography
                sx={{
                  fontSize: '.9rem',
                  color: !isFinite(totaldowntimecompare) || totaldowntimecompare === 0
                    ? "#000000" // Default black color for invalid or zero values
                    : totaldowntimecompare < 0
                      ? "#2E7D32" // Green for negative values
                      : "#D32F2F", // Red for positive values
                  display: 'flex',
                  justifyContent: "left",
                  alignItems: "center",
                  mr: '5px'
                }}
              >
                {previousData.length === 0 ? " " : totaldowntimeAndaverageCompareicon}
                {isFinite(totaldowntimecompare) && previousData.length !== 0
                  ? `${totaldowntimecompare.toFixed(2)}%`
                  : "No Downtime in the previous Quarter"}
              </Typography>

              {previousData.length === 0 ? " " : <Typography sx={{ fontSize: '.9rem', ms: '5px' }}> compared to the previous quarter. </Typography>}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="monitoringFiltersWrap" sx={{ padding: "16px" }}>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ color: '#9C27B0' }}>Average Downtime (Minutes)</Typography>
            </Box>
            <Box sx={{ my: '8px' }}>
              <Typography align="center" variant="h2">
                {isFinite(averageDowntime)
                  ? `${Math.floor(averageDowntime)} min, ${(averageDowntime % 1 * 60).toFixed(0)} sec`
                  : "0 min, 0 sec"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.9rem', minHeight: "84px" }}>The average duration of each downtime incident. Calculated as: Total Downtime / Downtime Incidents.</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "left", alignItems: "center", mt: "10px" }}>
              <Typography
                sx={{
                  fontSize: '.9rem',
                  display: 'flex',
                  justifyContent: "left",
                  alignItems: "center",
                  mr: '5px',
                  color: !isFinite(averagedowntimecompare) || averagedowntimecompare === 0
                    ? "#000000" // Default black for invalid or zero values
                    : averagedowntimecompare < 0
                      ? "#2E7D32" // Green for negative values
                      : "#D32F2F", // Red for positive values
                }}
              >
                <>
                  {previousData.length === 0 ? " " : totaldowntimeAndaverageCompareicon}
                  {isFinite(averagedowntimecompare) && previousData.length !== 0
                    ? `${averagedowntimecompare.toFixed(2)}%`
                    : "No Downtime in the previous Quarter"}
                </>
              </Typography>

              {previousData.length === 0 ? " " : <Typography sx={{ fontSize: '.9rem', ms: '5px' }}> compared to the previous quarter. </Typography>}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className="monitoringFiltersWrap" sx={{ padding: "16px" }}>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ color: '#9C27B0' }}>Availability Percentage</Typography>
            </Box>
            <Box sx={{ my: '8px' }}>
              <Typography align="center" variant="h2">
                {
                  isFinite(formattedAvailability)
                    ? `${(formattedAvailability < 0 ? 0 : formattedAvailability).toFixed(2).padStart(5, '0')}%`
                    : "00.00%"
                }
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.9rem', minHeight: "84px" }}>The percentage of time the website was available during the selected period.</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "left", alignItems: "center", mt: "10px" }}>
              <Typography sx={{
                fontSize: '.9rem', color: averageAvailabilityPercentage < 0 ? "#D32F2F" :
                  averageAvailabilityPercentage > 0 ? "#2E7D32" :
                    "#000000", display: 'flex', justifyContent: "left", alignItems: "center", mr: '5px'
              }}>
                {availabilityPercentageicon}
                {isFinite(averageAvailabilityPercentage) &&
                  `${averageAvailabilityPercentage.toFixed(2)}%`}</Typography>
              {<Typography sx={{ fontSize: '.9rem', ms: '5px' }}> compared to the previous quarter. </Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default DowntimeCalculator;
