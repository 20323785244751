import React from "react";
import { TextField, Button, Snackbar } from "@mui/material";
import confirmPasswordStyles from "./ConfirmPasswordStyles.module.css";
import { Link } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";

interface ConfirmPasswordUIProps {
  newPassword: string;
  reNewPassword: string;
  passwordError: string | null;
  showPassword: boolean;
  onNewPasswordChange: (value: string) => void;
  onReNewPasswordChange: (value: string) => void;
  onToggleShowPassword: () => void;
  onResetPasswordConfirm: () => void;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarSeverity: "success" | "error";
  onSnackbarClose: () => void;
}

const ConfirmPasswordSection: React.FC<ConfirmPasswordUIProps> = ({
  newPassword,
  reNewPassword,
  passwordError,
  showPassword,
  onNewPasswordChange,
  onReNewPasswordChange,
  onToggleShowPassword,
  onResetPasswordConfirm,
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  onSnackbarClose,
}) => {
  return (
    <section className={confirmPasswordStyles.confirmPasswordSection}>
      <div className={confirmPasswordStyles.resetPasswordLogoSection}>
        <img
          className={confirmPasswordStyles.optivalLogo1Icon}
          alt=""
          src="/optivallogo-1@2x.png"
        />
      </div>
      <div className={confirmPasswordStyles.resetPasswordTitleSection}>
        <h4 className={confirmPasswordStyles.typography1}>
          <div className={confirmPasswordStyles.h4}>
            Enter Your New Password
          </div>
        </h4>
      </div>
      <form>
        <div className={confirmPasswordStyles.confirmPasswordTextField}>
          <TextField
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => onNewPasswordChange(e.target.value)}
            error={passwordError ? true : false}
            helperText={passwordError || ""}
             InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onToggleShowPassword}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{width:"500px"}}
          />
        </div>
        <div className={confirmPasswordStyles.confirmPasswordTextField}>
          <TextField
            label="Re-enter New Password"
            type="password"
            value={reNewPassword}
            onChange={(e) => onReNewPasswordChange(e.target.value)}
            error={passwordError ? true : false}
            helperText={passwordError || ""}
            sx={{width:"500px"}}
          />
        </div>
        <Button
          className={confirmPasswordStyles.confirmPasswordButton}
          variant="contained"
          color="secondary"
          onClick={onResetPasswordConfirm}
        >
          RESET PASSWORD
        </Button>
        <Link to="/login" className={confirmPasswordStyles.backButton}>
          <Button
            className={confirmPasswordStyles.backButton}
            variant="outlined"
            color="secondary"
          >
            BACK TO LOGIN
          </Button>
        </Link>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={onSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default ConfirmPasswordSection;
