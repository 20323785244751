import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {Autocomplete, Button, FormControl,Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import "./RunNewProcessLog.css";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
// import { ETLLogsEndPointLogic } from "../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";



  interface Props {
    token:string;
    setSelectedSource:any;
    selectedSource:any;
    setBrandID:any;
    brandID:any;
    websiteSharedUid:any;
    setFromDate:any;
    setToDate:any;
    setbackend:any;
    setGaming:any;
    setBigRep:any;
    barnds:any;
    setBrands:any;
  }
  
export default function RunNewProcessLogBrandDataAPI({token,setFromDate,setToDate,setBrandID,brandID,websiteSharedUid,setbackend,
  setGaming,setBigRep,barnds,setBrands}:Props) {

  const [autocompleteCount,setAutocompleteCount]=useState([1])as any;

  const handleAddAutocomplete = () => {
    if (autocompleteCount.length < 8) {  // Limit to 8 fields
      setAutocompleteCount([...autocompleteCount, autocompleteCount.length + 1]);
      setBrands((prev: any) => [...prev, { name: "" }]); // Add a new object with an empty 'name'
    } 
  };
  
  const handleProcessChange = (value: string, index: number) => {
    const selectedBrand = barnds.find((brand: any) => brand.name === value);
    
    if (selectedBrand) {
      const updatedBrandIDs = [...brandID]; // Copy the existing brandID array
      updatedBrandIDs[index] = selectedBrand.id; // Update the brandID at the current index
      
      setBrandID(updatedBrandIDs); // Update the brandID state with the new array
    }
  };
  
  return (
    <>
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "16px" }}
              >
                Brand Data API
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  mb: 1,
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Process Details:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: "5px !important" }}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    className="brandDataDatePicker"
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "secondary",
                        required: true,
                      },
                    }}
                    sx={{ width: "100%" }}
                    label="Start Date "
                    onChange={(newValue) => {
                      // Format the date to YYYY-MM-DD and set it
                      if (newValue) {
                        const formattedDate = newValue.format('YYYY-MM-DD'); // Format the date
                        setFromDate(formattedDate); // Set the formatted date
                      } else {
                        setFromDate(""); // Clear the date if no value is selected
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: "5px !important" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                  className="brandDataDatePicker"
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "secondary",
                        required: true,
                      },
                    }}
                    sx={{ width: "100%" }}
                    label="End Date"
                    onChange={(newValue) => {
                      // Format the date to YYYY-MM-DD and set it
                      if (newValue) {
                        const formattedDate = newValue.format('YYYY-MM-DD'); // Format the date
                        setToDate(formattedDate); // Set the formatted date
                      } else {
                        setToDate(""); // Clear the date if no value is selected
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Process Actions:
              </Typography>
              <FormControl>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={<Checkbox size="small" color="secondary" onChange={(e)=>e.target.checked ? setbackend(true) : setbackend(false)}/>}
                    label="Pull Backend info"
                  />
                  <FormControlLabel
                    control={<Checkbox size="small" color="secondary" onChange={(e)=>e.target.checked ? setBigRep(true) : setBigRep(false)} />}
                    label="Push to Business Reporting"
                  />
                  <FormControlLabel
                    control={<Checkbox size="small" color="secondary" onChange={(e)=>e.target.checked ? setGaming(true) : setGaming(false)} />}
                    label="Update “new_gaming_partners_data” Table"
                  />
                </FormGroup>
              </FormControl>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "12px",
                }}
              >
                Select at least one action.
              </Typography>
            </Grid>
          </Grid>
          {/* Add another brand */}
          <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  mb: 1,
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Brand
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
                  {autocompleteCount && autocompleteCount.map((item:any, index:number) => {
                    // Filter options to exclude selected processes
                    const filteredOptions = barnds && Array.isArray(barnds)
                    ? barnds.map((brands: any) => brands.name)
                    : [];
                    return (
                      <Grid item xs={9} key={index}>
                        <Autocomplete
                          id={`Brands-${index}`}
                          disablePortal={false}
                          options={filteredOptions} // Use filtered options
                          fullWidth
                          size="medium"
                          // value={barnds?.name || ""}
                          onChange={(event, newValue) => handleProcessChange(newValue as string, index)} // Update selected value
                          renderInput={(params) => (
                            <TextField
                              color="secondary"
                              {...params}
                              required
                            />
                          )}
                          sx={{marginBlock:2}}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          <Grid container spacing={3}>
            <Grid item xs={9} mt={2}>
            <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >
                Want to run more processes?
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >
                Run up to 8 Brands simultaneously.
              </Typography>
              {autocompleteCount.length===8 ? <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >
                Maximum number of simultaneous processes reached.
              </Typography>: ""}
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >
                Want to run more Brand Data API’s? please wait until the current
                batch is done and start the process again.
              </Typography>
            </Grid>
            <Grid item xs={3} mt={2}>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                fullWidth
                endIcon={<AddIcon />}
                onClick={handleAddAutocomplete}
              >
                Add another brand
              </Button>
            </Grid>
          </Grid>
    </>
  )
}
