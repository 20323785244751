import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./EngagementIncentives.css";
import ClickData from "./ClickData/ClickData";
import PerformanceDayBrands from "./PerformanceDayBrands/PerformanceDayBrands";
import BudgetControl from "./BudgetControl/BudgetControl";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  selectedSiteUid:string | null;
  userToken:any;
}

interface EngagementIncentivesTabsProps {
  selectedSiteUid: string | null;
  userToken: any;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, selectedSiteUid, userToken, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EngagementIncentivesTabs = ({
  selectedSiteUid,
  userToken,
}:EngagementIncentivesTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                color: "#00000099", // Default color for unselected tabs
                "&.Mui-selected": {
                  color: "#9C27B0", // Change color when the tab is selected
                },
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "flex-start",
              },
              "& .MuiTab-root:first-of-type": {
                paddingLeft: 0,
              },
            }}
          >
            <Tab label="Budget control" {...a11yProps(0)} />
            <Tab label="Performance By Day And Brands" {...a11yProps(1)} />
            <Tab label="Click data" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} selectedSiteUid={selectedSiteUid} userToken={userToken}>
          <BudgetControl selectedSiteUid={selectedSiteUid} userToken={userToken} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} selectedSiteUid={selectedSiteUid} userToken={userToken}>
          <PerformanceDayBrands selectedSiteUid={selectedSiteUid} userToken={userToken}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} selectedSiteUid={selectedSiteUid} userToken={userToken}>
          <ClickData selectedSiteUid={selectedSiteUid} userToken={userToken} />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default EngagementIncentivesTabs;
