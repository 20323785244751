import { setDisplayErrorSnackBar } from "../../../../features/userSlice";
import { endpointsService } from "../../../../services/endPoints.services";
import store from "../../../../store";

async function createPage(siteUid: string, data: any, setIsLoading: any, trackPromise: any, setCreatePageResponse: any, oauthTokenHeader: string, setShowErrorDialog: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.createPage(data, siteUid, oauthTokenHeader);

        if (!response.status || response.status !== 200) {
            store.dispatch(setIsLoading(true));
            setShowErrorDialog(true); // Set error dialog to show
        } else {
            setCreatePageResponse(response?.data?.data);
        }
        
    } catch (e:any) {
        if(e.status !== 500){
            store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
        setShowErrorDialog(true); // Set error dialog to show
    } finally {
        store.dispatch(setIsLoading(false));
    }
}


async function duplicatePage(siteUid: string, data: any, setIsLoading: any, trackPromise: any, oauthTokenHeader: string, setDuplicatePageResponse: any , pageUid:string, setShowDuplicateErrorDialog: any) {
    try {
        store.dispatch(setIsLoading(true));
    
        const response = await endpointsService.duplicatePage(
          data,
          siteUid,
          oauthTokenHeader,
          pageUid
        );
    
        if (!response.status || response.status !== 200) {
          if (response.status === 500) {
            setShowDuplicateErrorDialog(true); // Show error dialog for duplicate page title
            return; // Exit function to prevent further state updates
          }
          store.dispatch(setIsLoading(true));
          setShowDuplicateErrorDialog(true); // Show error dialog for duplicate page title
        } else {
          setDuplicatePageResponse(response?.data?.data);
        }
      } catch (e : any) {
        store.dispatch(
          setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })
        );
      }
}


async function getPageContentAfterCreate(siteUid: string, pageUid: string, oauthTokenHeader: string, setPageContentAfterCreate: any, setIsLoading: any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        
        const response = await endpointsService.getPageContent(siteUid, pageUid, oauthTokenHeader);

        if (!response.status || response.status !== 200 || response?.response?.status === 500) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content after create', type: 'error' }));
        } else {
            setPageContentAfterCreate(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    } finally {
        store.dispatch(setIsLoading(false));
    }
}



// eslint-disable-next-line
export const createPageEndPointLogic = {
    createPage,
    getPageContentAfterCreate,
    duplicatePage
};
