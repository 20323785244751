import {Grid, Paper, Typography } from "@mui/material";
import "../../EngagementIncentives.css";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";

interface BudgetControlCardProps {
  exequteQueryContent: any;
}

const BudgetControlCard = ({ exequteQueryContent }: BudgetControlCardProps) => {

  const hasData = exequteQueryContent?.query_output?.length > 0;
  const isLoading = !hasData && !exequteQueryContent;

  return (
    <>
      {isLoading ? (
        <SimpleBackdrop />
      ) : (
        <Grid container spacing={2} mb={1}>
          <Grid item xs={3}>
            <Paper className="budgetControlCard" sx={{ p: 2 }}>
              <Typography variant="body1">Actual QFTD</Typography>
              <Typography textAlign="center" variant="h2" sx={{ py: 1 }}>
                {hasData ? exequteQueryContent.query_output[0]?.qftd_actual : "0"}
              </Typography>
              <Typography variant="h6">
                QFTD generated in the selected month on the selected website, using the traffic source "Incentivized Traffic"
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className="budgetControlCard" sx={{ p: 2 }}>
              <Typography variant="body1">QFTD Budget</Typography>
              <Typography textAlign="center" variant="h2" sx={{ py: 1 }}>
                {hasData ? exequteQueryContent.query_output[0]?.qftd_budget : "0"}
              </Typography>
              <Typography variant="h6">
                QFTD budget defined for the selected month for the selected site, using "Incentivized Traffic"
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className="budgetControlCard Active"
              sx={{
                p: 2,
                background: (() => {
                  if (hasData) {
                    const utilizationValue = exequteQueryContent.query_output[0]?.Utilization;
                    const utilizationPercentage = utilizationValue != null ? (utilizationValue * 100).toFixed(2) : 0 as any;
                
                    if (utilizationPercentage >= 0 && utilizationPercentage <= 70) {
                      return "#EDF7ED"; // Green
                    } else if (utilizationPercentage >= 71 && utilizationPercentage <= 95) {
                      return "#ED6C0280"; // Yellow
                    } else if (utilizationPercentage >= 96) {
                      return "#D32F2F80"; // Red
                    }
                  }
                  return "#EDF7ED"; // Default color if no data
                })(),
                
              }}
            >
              <Typography variant="body1">Utilization</Typography>
              <Typography textAlign="center" variant="h2" sx={{ py: 1 }}>
              {hasData && exequteQueryContent.query_output[0]?.Utilization != null ? (
                  `${(exequteQueryContent.query_output[0].Utilization * 100).toFixed(2)}%`
                ) : (
                  "0%"
              )}
              </Typography>
              <Typography variant="h6">Actual QFT Divided by Budget QFTD</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className="budgetControlCard" sx={{ p: 2 }}>
              <Typography variant="body1">Cost</Typography>
              <Typography textAlign="center" variant="h2" sx={{ py: 1 }}>
                {hasData
                  ? `${(exequteQueryContent.query_output[0]?.cost || 0).toLocaleString()}$`
                  : "0$"}
              </Typography>
              <Typography variant="h6">
                Actual QFTD multiplied by the cost of each QFTD
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BudgetControlCard;
