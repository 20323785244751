import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./CreateProcess.css";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import store from "../../../../../store";
import { setIsLoading } from "../../../../../features/userSlice";
import { ETLProcessListEndPointLogic } from "../../../../../logic/Admin/ETL Process List/ETLProcessListEndPointLogic";
import { trackPromise } from "react-promise-tracker";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#fff",
  padding: "20px 24px",
  borderRadius: "4px",
  boxShadow: "0px 0px 16px #50505085",
  zIndex: "999",
  maxHeight: "80vh",
  overflowY: "auto",
  overflowX: "hidden",
};

const modalHeading = {
  fontSize: "20px",
  fontWeight: "500",
  marginBottom: "16px",
};

type CreateProcessProps = {
  openModal: boolean;
  onCloseModal: any;
  hasEditPermissions: any;
  oauthTokenHeader: any;
  setReload: any;
};

const CreateProcess = (props: CreateProcessProps) => {
  const { openModal, onCloseModal, oauthTokenHeader, setReload , hasEditPermissions } = props;
  const [processName, setProcessName] = useState("");
  const [processCommand, setProcessCommand] = useState("");
  const [ETLProcess, setETLProcess] = useState([]) as any;
  const [, setIsLoad] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const [processNameError, setProcessNameError] = useState(false);
  const [processCommandError, setProcessCommandError] = useState(false);
  const [error, setError] = useState<string | null>(null); // State for error messages

  // Handle input changes
  const handleProcessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProcessName(event.target.value);
    setProcessNameError(false); // Reset error state when input changes
  };

  const handleProcessCommandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProcessCommand(event.target.value);
    setProcessCommandError(false); // Reset error state when input changes
  };

  const createETLProcess = async (data: any) => {
    store.dispatch(setIsLoading(true));
    setIsLoad(true);
    try {
      if (hasEditPermissions) {
      await ETLProcessListEndPointLogic.createETLProcess(
        oauthTokenHeader,
        setETLProcess,
        trackPromise,
        data
      );
      if(ETLProcess==="success"){
        setReload(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("ETL Process created successfully!");
        setIsLoad(true);
      setReload(true);
      setSnackbarOpen(true);
      }
      }
       else {
        setError("User Don`t have permission");
      }
    }catch (error) {
      // setSnackbarSeverity("error");
      setError(ETLProcess?.reason);
    }
  };

  useEffect(()=>{
    if(ETLProcess!=="success"){
      setSnackbarSeverity("error");
      setSnackbarMessage("");
      setError(ETLProcess?.parsedText?.reason);
    } else{
      setReload(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("ETL Process created successfully!");
      onCloseModal();
      setReload(true);
      setProcessName("");
      setProcessCommand("");
      setError("");
    }
    // eslint-disable-next-line
  },[ETLProcess])

  const handleSubmit = async () => {
    let valid = true;
    
    if (!processName) {
      setProcessNameError(true);
      valid = false;
    }

    if (!processCommand) {
      setProcessCommandError(true);
      valid = false;
    }

    if (!valid) {
      // setSnackbarSeverity("error");
      // setSnackbarMessage("Both fields are mandatory.");
      setSnackbarOpen(true);
      return;
    }

    const data = {
      name: processName,
      command: processCommand,
    };

    await createETLProcess(data);
    // onCloseModal();
    // setReload(true);
    // setProcessName("");
    // setProcessCommand("");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleCancel = () => {
    setProcessName("");
    setProcessCommand("");
    setProcessNameError(false);
    setProcessCommandError(false);
    setError(null);
    onCloseModal();
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Typography
            sx={modalHeading}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add New Process
          </Typography>

          {/* Modal Body */}
          {error && error.length>=1 && (
            <Alert severity="error" sx={{ marginBottom: "16px" }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="processName"
                required
                label="Process Name"
                value={processName}
                helperText={processNameError ? "Process Name is mandatory." : ""}
                error={processNameError}
                size="small"
                onChange={handleProcessNameChange}
                fullWidth
                color="secondary"
              />
              <Typography
                component="p"
                sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
              >
                New ETL Processes are created with a default Enabled state.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="processCommand"
                required
                label="Process Command"
                value={processCommand}
                helperText={processCommandError ? "Process Command is mandatory." : ""}
                error={processCommandError}
                size="small"
                onChange={handleProcessCommandChange}
                fullWidth
                color="secondary"
              />
            </Grid>
          </Grid>

          {/* Modal footer */}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Grid
              item
              xs={12}
              justifyContent={"flex-end"}
              sx={{ display: "flex" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<DoneIcon />}
                sx={{ marginLeft: 1 }}
                onClick={handleSubmit}
              >
                ADD Process
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Snackbar for alerts */}
      {snackbarMessage && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default CreateProcess;
