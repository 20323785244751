import React from 'react'
import EditTemplate from '../../../../components/AssetManagement/Design/Templates/EditTemplate/EditTemplate'

const EditTemplateScreen = () => {
  return (
    <div>
      <EditTemplate />
    </div>
  )
}

export default EditTemplateScreen
