/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useLocation } from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReorderIcon from '@mui/icons-material/Reorder';
import { useState, useEffect } from "react";
import {Collapse,List,ListItem,ListItemButton,ListItemIcon,ListItemText,TextField,Tooltip,PopperPlacementType,Popper,ClickAwayListener,Fade,Paper,Switch,} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { selectUserData } from "../../features/userSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InboxIcon from '@mui/icons-material/MoveToInbox'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DataThresholdingIcon from '@mui/icons-material/DataThresholding'
import SettingsIcon from '@mui/icons-material/Settings'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import DrawerData from "./drawerdata.json";

import "./Drawer.css";

const drawerWidth = 290;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 20px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5)} + 20px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
interface Props {
  response: any;
}
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
interface SubMenuOpenStates {
  [key: string]: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


interface SubSubMenuItem {
  [key: string]: any
}

interface SubMenuItem {
  [key: string]: SubSubMenuItem[]
}

interface MenuItem {
  [key: string]: SubMenuItem[]
}

const capitalizeAndRemoveUnderscores = (str: string) => {
  return str
    .split('_')
    .map((word, index) => {
      if (index === 0 && word.toLowerCase() === 'etl') {
        return 'ETL';
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

const iconMap: { [key: string]: React.ReactElement } = {
  "assets": <WebAssetIcon />,
  "Partners": <HandshakeOutlinedIcon />,
  "Organic Traffic": <SavingsOutlinedIcon />,
  "Market Positioning": <CompareOutlinedIcon />,
  "Finance": <AttachMoneyIcon />,
  "Data Collection": <DataThresholdingIcon />,
  "Personal Settings": <SettingsIcon />,
  "paid_traffic": <AdsClickIcon />,
  "admin": <AdminPanelSettingsIcon />,
}

// Defined the link map
const linkMap: { [key: string]: string } = {
  page_management: "/assets/page_management",
  website_actions: "assets/websites/website_actions",
  page_groups: "assets/websites/page_groups",
  website_page_groups: "/comingSoon",
  themes: "assets/design/theme",
  templates: "assets/design/templates",
  monitoring: "assets/health/monitoring",
  page_speed_performance: "assets/health/page_speed_performance",
  data_accuracy: "/comingSoon",
  business_leader_dashboard: "/comingSoon",
  action_items: "/comingSoon",
  performance_forecaster: "/comingSoon",
  activity_notes: "/assets/activity_notes",
  eom_process: "/comingSoon",
  manual_adjustments: "/comingSoon",
  invoices: "/comingSoon",
  receipts: "/comingSoon",
  content_payment: "/comingSoon",
  link_payments: "/comingSoon",
  manual_expenses: "/finance/providers_expenses/manual_expenses",
  competitors: "/comingSoon",
  gambling_licenses: "/comingSoon",
  competitors_page_speed: "market_positioning/scout/competitors_page_speed",
  competitors_serp_positioning: "/comingSoon",
  offers_attractiveness: "/comingSoon",
  partner_opportunities: "/comingSoon",
  link_research: "/comingSoon",
  backlinks_monitoring: "/comingSoon",
  paid_traffic_reports: "/comingSoon",
  paid_traffic_insights: "/comingSoon",
  account_actions: "/comingSoon",
  nrpu_setting: "/comingSoon",
  set_the_right_price: "/comingSoon",
  make_sure_opt_can_happen: "/comingSoon",
  increase_inventory: "/comingSoon",
  partners_hierarchy: "/comingSoon",
  promotion_content: "/comingSoon",
  deals: "/comingSoon",
  offers: "/comingSoon",
  crm: "/comingSoon",
  performance_control: "/comingSoon",
  improve_deals: "/comingSoon",
  table_optimization: "/comingSoon",
  fraud: "/comingSoon",
  budget_control: "/comingSoon",
  organic_traffic_reports: "/comingSoon",
  organic_traffic_insights: "/comingSoon",
  asset_permissions: "/comingSoon",
  organization_permissions: "/comingSoon",
  user_management: "/comingSoon",
  advertising_accounts: "/paidTraffic/advertising-accounts",
  engagement_incentives:"paidTraffic/engagement_incentives",
  etl_sequences: "/admin/processes/etl-sequences",
  etl_list: "/admin/processes/etl-process-list",
  etl_logs: "/admin/processes/etl-process-log",
  integration_keys: "/comingSoon",
  alerts_config: "/comingSoon",
  tasks_config: "/comingSoon",
  view_territories: "/admin/view_territories",
  view_geos: "/admin/view_territories",
  view_traffic_sources: "/admin/view_territories",
  view_industries: "/admin/view_territories",
  view_device_types: "/admin/view_territories",
  view_products_types: "/admin/view_territoriesn",
  view_currency_symbols: "/admin/view_territories",
}

export default function MiniDrawer(response:Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState<string | null>(null)
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null)
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [, setAnchorEl] = useState(null);
  const [subMenuOpenStates, setSubMenuOpenStates] = useState<SubMenuOpenStates>({});
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const sidebarMenuItems : MenuItem = response?.response?.response?.products || {};
  const DrawerMenuItems: MenuItem = DrawerData || {};
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMenu(null);
  };
  const handleItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    name: any
  ) => {
    setSelectedItem(name);
    setSelectedItemInLocalStorage(name);
  };
  const handleSubMenuClick = (name: string) => {
    if (!open) {
      setOpen(true);
    }
    setSubMenuOpenStates((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleToggleMenu = (event: React.MouseEvent<HTMLLIElement>,menuKey: string) => {
    setOpen(true);
    setOpenMenu(openMenu === menuKey ? null : menuKey)
    setSelectedItem(menuKey);
    // setSelectedItemInLocalStorage(menuKey);
  }

  const handleToggleSubMenu = (subMenuKey: string) => {
    if(!openMenu){
      setOpen(true);
      setOpenMenu(openSubMenu === subMenuKey ? null : subMenuKey)
    }
    setOpenSubMenu(openSubMenu === subMenuKey ? null : subMenuKey)
    setSubMenuOpenStates((prevState) => ({
      ...prevState,
      [subMenuKey]: !prevState[subMenuKey],
    }));
  }

  const setSelectedItemInLocalStorage = (selectedItem: string) => {
    localStorage.setItem("selectedItem", selectedItem);
  };
  
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedItem(null);
    } else {
      const savedSelectedItem = localStorage.getItem("selectedItem");
      if (savedSelectedItem) {
        setSelectedItem(savedSelectedItem);
      }
    }
  }, [location]);
  
   // account popover

   const [anchorElaccount, setAnchorElaccount] = useState<HTMLButtonElement | null>(null);
   const [openaccount, setOpenaccount] = useState(false);
   const [placementaccount, setPlacementaccount] = useState<PopperPlacementType>();
   const handleClickaccount =
   (newPlacement: PopperPlacementType) =>
   (event: React.MouseEvent<HTMLButtonElement>) => {
     setAnchorElaccount(event.currentTarget);
     setOpenaccount((prev) => placement !== newPlacement || !prev);
     setPlacementaccount(newPlacement);
     setOpenpop(false);
   };
   const handleClickAwayaccount = () => {
     setOpenaccount(false);
   };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleMenuClose();
    navigate("/logout");
  };
  const loggedInUserDetails = Object.values(
    userData?.data?.user_details as Record<string, any>
  );
  const [anchorElpop, setAnchorElpop] = useState<HTMLButtonElement | null>(null);
  const [openpop, setOpenpop] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElpop(event.currentTarget);
    setOpenpop((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setOpenaccount(false)
  }

  const handleClickAway = () => {
    setOpenpop(false);
  };

  // notification switch

  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // mark all read 
  
  const [notificationsRead, setNotificationsRead] = useState(false);

  const handleMarkAllRead = () => {
    setNotificationsRead(true);
  };

  // color change when i click a notification

  const [activeNotification, setActiveNotification] = useState(null);

  const handleNotificationClick = (index: any) => {
    // Toggle the active state of the clicked notification
    setActiveNotification(activeNotification === index ? null : index);
  };

  // subsub menu

  const [subSubMenuOpenStates, setSubSubMenuOpenStates] = React.useState<{ [key: string]: boolean }>({});
  const handleSubSubMenuClick = (name: string) => {
    setSubSubMenuOpenStates((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  // Extracting the admin data
  const adminMenu = sidebarMenuItems["admin"]
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Popper
          sx={{ zIndex: 999999999 }}
          open={openpop}
          anchorEl={anchorElpop}
          placement={placement}
          transition
          >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <div className="notification-bg">
                  <div className="notification-header">
                  <Typography sx={{fontWeight:"400", fontSize:"24px", lineHeight:"32px" }}>Notifications</Typography>
                  <div className="notification-switch">
                    <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>Show unread only</Typography>
                    <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="secondary"
                    />
                    <OpenInNewIcon color="secondary" sx={{marginLeft:"16px"}}/>
                  </div>
                  </div>
                  <Divider />
                  <div className="notification-body">
                    <div className="notification-body-header">
                      <Typography sx={{fontWeight:"500", fontSize:"20px", lineHeight:"32px", letterSpacing:"0.15px" }}>Today</Typography>
                      <Typography onClick={handleMarkAllRead} color="secondary" sx={{fontWeight:"500", fontSize:"16px", lineHeight:"22px", letterSpacing:"0.46px", cursor:"pointer" }}>Mark all as read</Typography>
                    </div>
                    <div className={`notification-body-details ${activeNotification === 0 ? 'active' : ''}`} onClick={() => handleNotificationClick(0)}>
                        <Avatar alt="Travis Howard" src="#" />
                        <div className="NotificationShort" >
                        <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>Aladdin System Permissions updated</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"14px", lineHeight:"20px", letterSpacing:"0.17px",color:"#00000099"}}>Super User “Moshe” deleted</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"10px", lineHeight:"14px",color:"#00000099"}}>2 Hours ago</Typography>
                        </div>
                        {!notificationsRead && (
                          <FiberManualRecordIcon color="secondary" sx={{ width: '8px' }} />
                        )}
                    </div>
                    <div className="notification-body-details">
                        <Avatar alt="Travis Howard" src="#" />
                        <div className="NotificationShort" >
                        <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>Aladdin System Permissions updated</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"14px", lineHeight:"20px", letterSpacing:"0.17px",color:"#00000099"}}>Super User “Moshe” deleted</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"10px", lineHeight:"14px",color:"#00000099"}}>2 Hours ago</Typography>
                        </div>
                        {!notificationsRead && (
                          <FiberManualRecordIcon color="secondary" sx={{ width: '8px' }} />
                        )}
                    </div>
                    <div className="notification-body-header">
                      <Typography sx={{fontWeight:"500", fontSize:"20px", lineHeight:"32px", letterSpacing:"0.15px" }}>Yesterday</Typography>
                    </div>
                    <div className="notification-body-details">
                        <Avatar alt="Travis Howard" src="#" />
                        <div className="NotificationShort" >
                        <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>Aladdin System Permissions updated</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"14px", lineHeight:"20px", letterSpacing:"0.17px",color:"#00000099"}}>Super User “Moshe” deleted</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"10px", lineHeight:"14px",color:"#00000099"}}>2 Hours ago</Typography>
                        </div>
                        {!notificationsRead && (
                          <FiberManualRecordIcon color="secondary" sx={{ width: '8px' }} />
                        )}
                    </div>
                    <div className="notification-body-details">
                        <Avatar alt="Travis Howard" src="#" />
                        <div className="NotificationShort" >
                        <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>Aladdin System Permissions updated</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"14px", lineHeight:"20px", letterSpacing:"0.17px",color:"#00000099"}}>Super User “Moshe” deleted</Typography>
                        <Typography sx={{fontWeight:"400", fontSize:"10px", lineHeight:"14px",color:"#00000099"}}>2 Hours ago</Typography>
                        </div>
                        {!notificationsRead && (
                          <FiberManualRecordIcon color="secondary" sx={{ width: '8px' }} />
                        )}
                    </div>
                  </div>
                </div>
              </Paper>
            </Fade>
            </ClickAwayListener>
          )}
        </Popper>
        <Popper
          sx={{ zIndex: 999999999 }}
          open={openaccount}
          anchorEl={anchorElaccount}
          placement={placementaccount}
          transition
          >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAwayaccount}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <div className="account-details-bg">
                <Typography sx={{fontWeight:"500", fontSize:"20px", lineHeight:"32px", letterSpacing:"0.15px", padding:"8px 16px" }}>Account</Typography>
                <div className="account-details">
                <Avatar sx={{ height: "40px", width: "40px" }}>{loggedInUserDetails[1].charAt(0)}</Avatar>
                  <div>
                  <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px"}}>{loggedInUserDetails[1]}</Typography>
                  <Typography sx={{fontWeight:"400", fontSize:"14px", lineHeight:"20px", letterSpacing:"0.15px", color:"#00000099" }}>{loggedInUserDetails[3]}</Typography>
                  </div>
                </div>
                <Divider/>
                <div className="account-details-logout">
                  <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px", paddingBlock:"8px"}}>Profile</Typography>
                  <Typography sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px", paddingBlock:"8px"}}>Settings</Typography>
                  <Typography onClick={handleLogout} sx={{fontWeight:"400", fontSize:"16px", lineHeight:"24px", letterSpacing:"0.15px", paddingBlock:"8px", cursor:"pointer"}}>Log out</Typography>
                </div>
                </div>
              </Paper>
            </Fade>
            </ClickAwayListener>
          )}
        </Popper>
        <CssBaseline />
        <AppBar
          key={"appBar"}
          position="fixed"
          open={open}
          sx={{ background: "#FFFFFF"}}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "4px",
              margin: "0px, 16px, 0px, 12px",
            }}
          >
            <div className="icon-heading">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  ...(open && { display: "none" }),
                  color: "#000000DE",
                }}
              >
                <ReorderIcon />
              </IconButton>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "32px",
                    fontStyle: "normal",
                    letterSpacing: "0.15px",
                    fontWeight: 500,
                    color: "#000000DE",
                  }}
                >
                  Aladdin
                </Typography>
              </Link>
            </div>
            <div className="textField-notification-account">
              <TextField
                placeholder="Brand, Action or keyword..."
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                color="secondary"
                sx={{ width: "250px" }}
              />
              <IconButton 
                 sx={{ color: openpop ? "#9C27B0" : "#0000008F", height: "32px", width: "32px"}}
                 onClick={handleClick('bottom-end')}>
              <NotificationsIcon sx={{ height: "24px", width: "24px" }}/>
              </IconButton>
              <IconButton 
                  sx={{ color: openpop ? "#9C27B0" : "#0000008F", height: "32px", width: "32px"}}
                  onClick={handleClickaccount('bottom-end')}>
                <Avatar sx={{ height: "32px", width: "32px" }}>{loggedInUserDetails[1].charAt(0)}</Avatar>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer className="DrawerWrap" variant="permanent" open={open} onClose={handleDrawerClose}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <div
            className="lists"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div className="list1">
              <List sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px" }}>
                {Object.keys(DrawerMenuItems)
                  .filter((key) => key !== "admin") // Filter out the 'admin' key
                  .map((key:any, index:any) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding 
                        sx={{ 
                          display: 'block',
                          "&:hover": {
                            background: "#9C27B014",
                            color: "#9C27B0"
                          },
                          color: "#000000de",
                         }} 
                        onClick={(event) => handleToggleMenu(event, key)}>
                        <Tooltip
                          title={capitalizeAndRemoveUnderscores(key)}
                          placement="right-start"
                          disableHoverListener={open}
                        >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            ":hover": {backgroundColor: "#9C27B014"},
                            borderRadius: "8px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              "&:hover": { color: "#9C27B0" },
                            }}
                          >
                            {iconMap[key] || <InboxIcon />}
                          </ListItemIcon>
                          <ListItemText primary={capitalizeAndRemoveUnderscores(key)} sx={{ opacity: open ? 1 : 0 }} />
                          <IconButton edge="end" sx={{ display: open ? "flex" : "none", marginLeft: "auto" }}>
                            <ExpandMoreIcon
                              sx={{
                                transform:
                                  openMenu === key
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                              }}
                            />
                          </IconButton>
                        </ListItemButton>
                        </Tooltip>
                      </ListItem>
                      {openMenu === key && (
                        <List  sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px"}}>
                          {sidebarMenuItems[key]?.map((subMenuItem, subIndex) => (
                            <React.Fragment key={subIndex}>
                              {Object.keys(subMenuItem).map((subKey, subSubIndex) => (
                                  <div className="subMenuWrap">{(subKey!=="page_management" && subKey!=="advertising_accounts") ?
                                    <React.Fragment key={subSubIndex}>
                                    <ListItem disablePadding 
                                    sx={{
                                      display: "flex",
                                      background: selectedItem === subKey ? "#9C27B014" : "transparent",
                                      borderRadius: selectedItem === subKey ? "4px" : "none",
                                     }} 
                                    onClick={() => handleToggleSubMenu(subKey)}>
                                      <ListItemButton
                                        sx={{
                                          minHeight: 48,
                                          paddingLeft:"60px",
                                          justifyContent: open ? 'initial' : 'center',
                                          ":hover": {backgroundColor: "#9C27B014"},
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <ListItemText
                                            primary={capitalizeAndRemoveUnderscores(subKey)}
                                            primaryTypographyProps={{ fontSize: '14px' }}
                                            sx={{ opacity: open ? 1 : 0 }}
                                          />
                                        <IconButton edge="end" sx={{ display: open ? "flex" : "none", marginLeft: "auto" }}>
                                          <ExpandMoreIcon
                                            sx={{
                                              transform:
                                                openSubMenu === subKey
                                                  ? 'rotate(180deg)'
                                                  : 'rotate(0deg)',
                                              transition: 'transform 0.3s ease',
                                            }}
                                          />
                                        </IconButton>
                                      </ListItemButton>
                                    </ListItem>
                                    {openSubMenu === subKey && (
                                      <Collapse 
                                      in={openSubMenu === subKey}
                                      timeout="auto"
                                      unmountOnExit
                                      >
                                      <List sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px" }}>
                                        {subMenuItem[subKey].map((subSubMenuItem, subSubSubIndex) => (
                                          <React.Fragment key={subSubSubIndex}>
                                            {Object.keys(subSubMenuItem).map((subSubKey, subSubSubSubIndex) => (
                                              <Link to={linkMap[subSubKey] || "#"} style={{ textDecoration: "none", color:"unset" }}>
                                                <ListItem key={subSubSubSubIndex} disablePadding sx={{ display: 'block' }}>
                                                <ListItemButton
                                                    sx={{
                                                      minHeight: 48,
                                                      paddingLeft:"0px",
                                                      justifyContent: open ? 'initial' : 'center',
                                                      ":hover": {backgroundColor: "#9C27B014"},
                                                      borderRadius: "8px",
                                                    }}
                                                  >
                                                    <ListItemIcon
                                                      sx={{
                                                        minWidth: 0,
                                                        marginRight: "32px",
                                                        marginLeft: "8px",
                                                        justifyContent: "center",
                                                        "&:hover": { color: "#9C27B0" },
                                                        opacity: open ? 1 : 0,
                                                        color: "#9C27B0",
                                                      }}
                                                    >
                                                      <ArrowRightIcon />
                                                    </ListItemIcon>
                                                  <ListItemText primary={capitalizeAndRemoveUnderscores(subSubKey)} primaryTypographyProps={{ fontSize: '14px' }}/>
                                                  </ListItemButton>
                                                </ListItem>
                                              </Link>
                                            ))}
                                          </React.Fragment>
                                        ))}
                                      </List>
                                      </Collapse>
                                    )}
                                  </React.Fragment>
                                    :   <List sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px" }}>
                                    {subMenuItem[subKey].map((subSubMenuItem, subSubSubIndex) => (
                                      <React.Fragment key={subSubSubIndex}>
                                        {Object.keys(subSubMenuItem).map((subSubKey, subSubSubSubIndex) => (
                                          <Link to={linkMap[subSubKey] || "#"} style={{ textDecoration: "none", color:"unset" }}>
                                            <ListItem key={subSubSubSubIndex} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                  minHeight: 48,
                                                  paddingLeft:"0px",
                                                  justifyContent: open ? 'initial' : 'center',
                                                  ":hover": {backgroundColor: "#9C27B014"},
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                <ListItemIcon
                                                  sx={{
                                                    minWidth: 0,
                                                    marginRight: "32px",
                                                    marginLeft: "8px",
                                                    justifyContent: "center",
                                                    "&:hover": { color: "#9C27B0" },
                                                    opacity: open ? 1 : 0,
                                                    color: "#9C27B0",
                                                  }}
                                                >
                                                  <ArrowRightIcon />
                                                </ListItemIcon>
                                              <ListItemText primary={capitalizeAndRemoveUnderscores(subSubKey)} primaryTypographyProps={{ fontSize: '14px' }}/>
                                              </ListItemButton>
                                            </ListItem>
                                          </Link>
                                        ))}
                                      </React.Fragment>
                                    ))}
                                  </List>}</div>
                              ))}
                            </React.Fragment>
                          ))}
                        </List>
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </div>
            <div className="list2">
              <List sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px"}}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                onClick={(event) => handleToggleMenu(event, "admin")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ":hover": {backgroundColor: "#9C27B014"},
                    borderRadius: "8px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      "&:hover": { color: "#9C27B0" },
                    }}
                  >
                    {iconMap["admin"]}
                  </ListItemIcon>
                  <ListItemText
                    primary={capitalizeAndRemoveUnderscores("admin")}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  <IconButton edge="end" sx={{ display: open ? "flex" : "none", marginLeft: "auto" }}>
                    <ExpandMoreIcon
                      sx={{
                        transform: openMenu === "admin" ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </IconButton>
                </ListItemButton>
              </ListItem>
                {openMenu === "admin" && (
                  <List className="subMenuWrap" sx={{ margin: open ? "0 8px 0 8px" : "0px 8px 0 8px"}}>
                    {adminMenu?.map((subMenuItem, subIndex) => (
                      <React.Fragment key={subIndex}>
                        {Object.keys(subMenuItem)?.map((subKey, subSubIndex) => (
                          <React.Fragment key={subSubIndex}>
                            <ListItem
                              disablePadding
                              sx={{ display: 'block' }}
                              onClick={() => handleToggleSubMenu(subKey)}
                            >
                              <ListItemButton
                                sx={{
                                  minHeight: 48,
                                  paddingLeft:"60px",
                                  justifyContent: open ? 'initial' : 'center',
                                  ":hover": {backgroundColor: "#9C27B014"},
                                  borderRadius: "8px",
                                }}
                              >
                                <ListItemText
                                  primary={capitalizeAndRemoveUnderscores(subKey)}
                                  primaryTypographyProps={{ fontSize: '14px' }}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                                <IconButton edge="end" sx={{ display: open ? "flex" : "none", marginLeft: "auto" }}>
                                  <ExpandMoreIcon
                                    sx={{
                                      transform: openSubMenu === subKey
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                      transition: 'transform 0.3s ease',
                                    }}
                                  />
                                </IconButton>
                              </ListItemButton>
                            </ListItem>
                            {openSubMenu === subKey &&
                              subMenuItem[subKey]?.map((subSubMenuItem, subSubMenuIndex) => (
                                <List key={subSubMenuIndex}>
                                  {Object.keys(subSubMenuItem)?.map((subSubKey, subSubIndex) => (
                                    <ListItem
                                      key={subSubIndex}
                                      disablePadding
                                      sx={{ display: 'block' }}
                                    >
                                      <Link
                                        to={linkMap[subSubKey] || "#"}
                                        style={{ textDecoration: "none", color:"unset" }}
                                      >
                                        <ListItemButton
                                          sx={{
                                            minHeight: 48,
                                            paddingLeft:"0px",
                                            justifyContent: open ? 'initial' : 'center',
                                            ":hover": {backgroundColor: "#9C27B014"},
                                            borderRadius: "8px",
                                          }}
                                        >
                                          <ListItemIcon
                                            sx={{
                                              minWidth: 0,
                                              marginRight: "32px",
                                              marginLeft: "8px",
                                              justifyContent: "center",
                                              "&:hover": { color: "#9C27B0" },
                                              opacity: open ? 1 : 0,
                                              color: "#9C27B0",
                                            }}
                                          >
                                            <ArrowRightIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={capitalizeAndRemoveUnderscores(subSubKey)}
                                            primaryTypographyProps={{ fontSize: '14px' }}
                                            sx={{ opacity: open ? 1 : 0 }}
                                          />
                                        </ListItemButton>
                                      </Link>
                                    </ListItem>
                                  ))}
                                </List>
                              ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </List>
              <Typography 
                sx={{
                  minHeight: 48,
                  fontSize: '0.9rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#00000099',
                  opacity: open ? 1 : 0
                }}
              >
                Powered by Optival LTD
              </Typography>
            </div>
          </div>
        </Drawer>
      </Box>
    </React.Fragment>
  );
}
