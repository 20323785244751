import { Button, Grid, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import BudgetControlModal from "../BudgetControlModal/BudgetControlModal";
import dayjs from "dayjs";

interface BudgetControlFilterProps{
  period:any;
  setPeriod:any;
  startDate:any;
  setStartDate:any;
  endDate:any;
  setEndDate:any;
  setShow:any;
  setDisplay:any;
  exequteQueryContentQueryCreate:any;
  userToken:string;
  setRefresh:any;
}

const BudgetControlFilter = ({period,setPeriod,startDate,setStartDate,endDate,setEndDate,setShow,setDisplay,exequteQueryContentQueryCreate,userToken,setRefresh}:BudgetControlFilterProps) => {

  const [openModal, setOpenModal] = useState(false);
  const [monthRange, setMonthRange] = useState<{ label: string }[]>([]);

  const exequteQueryContentQueryCreateData = exequteQueryContentQueryCreate?.query_output || [];

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  // Generate last 23 months including the current month
  const generateMonthRange = () => {
    const months = [];
    const currentMonth = dayjs(); // Get the current date using dayjs
  
    // Generate last 23 months + 3 future months (total 27 months)
    for (let i = 23; i >= -3; i--) {
      const month = currentMonth.subtract(i, 'month').format('MM/YYYY');
      months.push({ label: month });
    }
  
    return months.reverse(); // Now the list starts with the current month and includes future months
  };

  useEffect(() => {
    const months = generateMonthRange();
    setMonthRange(months.reverse());
  
    // Find and set the default period to the actual current month
    if (!period) {
      const currentMonth = dayjs().format('MM/YYYY'); // Get the current month using dayjs
      const currentMonthIndex = months.findIndex(month => month.label === currentMonth);
  
      if (currentMonthIndex !== -1) {
        setPeriod(currentMonth); // Set current month as the period
        calculateStartAndEndDate(currentMonth); // Calculate start and end date
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array to run only once on mount
  

  const calculateStartAndEndDate = (selectedPeriod: string) => {
    const [month, year] = selectedPeriod.split("/").map(Number);
    const start = dayjs(`${year}-${month}-01`).format("YYYY-MM-DD"); // First day of the month
    const end = dayjs(start).endOf("month").format("YYYY-MM-DD"); // Last day of the month

    setStartDate(start);
    setEndDate(end);
  };

    const handleShow = () => {
      setShow(true);
      setDisplay(true);
    };
    

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{marginTop:'0px'}}
      >
        <Grid item xs={2}>
          <Autocomplete
            disablePortal
            options={monthRange.slice().reverse()}
            value={period ? { label: period } : null}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Period" color="secondary" />
            )}
            onChange={(e, value) => {
              setPeriod(value?.label || '');
              if (value) {
                calculateStartAndEndDate(value.label); // Calculate dates when period changes
              }
            }}
            onInputChange={(event, inputValue, reason) => {
              if (reason === "clear") {
                setPeriod(null);
                setStartDate(null)
                setEndDate(null) 
                setDisplay(false);
              }
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={7}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleShow} // Trigger handleShow function
            size="medium"
            disabled={!period}
          >
            Show
          </Button>
        </Grid>
        <Grid item xs={3} textAlign={"right"}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<AddIcon />}
            onClick={handleOpenModal}
            disabled={!startDate}
          >
            Add new budget
          </Button>
          <BudgetControlModal open={openModal} handleClose={handleCloseModal} exequteQueryContentQueryCreateData={exequteQueryContentQueryCreateData}
          userToken={userToken} startDate={startDate} setRefresh={setRefresh} />
        </Grid>
      </Grid>
    </>
  );
};

export default BudgetControlFilter;
