import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/BreadCrumbs/Breadcrumbs";

export default function BreadCrumbsScreen() {

    const location = useLocation();

    const breadCrumbsProps = {
        location,
    }

    return (
        <Breadcrumbs {...breadCrumbsProps} />
    )
}