import { Button, Dialog, DialogTitle, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CongratulationMessage from "./CongratulationMessage";
import WebsiteDetails from "./WebsiteDetails";
import WebsiteImageOption from "./WebsiteImageOption";
import WebsiteConnectivity from "./WebsiteConnectivity";

interface CreateWebsiteModalProps {
  open: boolean;
  handleClose: () => void;
}

function CreateNewWebsiteModal({
  open,
  handleClose,
}: CreateWebsiteModalProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Create a New Website
        </DialogTitle>
        <WebsiteDetails />
        <WebsiteImageOption />
        <WebsiteConnectivity />
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
          <Button color="secondary" onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="contained" color="secondary" disabled>
            <Stack flexDirection={"row"} gap={1}>
              create <AutoFixHighOutlinedIcon />
            </Stack>
          </Button>
        </Stack>
      </Paper>
      {/* This component will be call after the creation of a new site */}
      <CongratulationMessage />
    </Dialog>
  );
}

export default CreateNewWebsiteModal;
