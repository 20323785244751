import { Autocomplete, Alert, Button, Chip, Grid, Paper, SnackbarContent, TextField, Typography, Snackbar, Dialog, DialogActions, DialogContent, CircularProgress } from "@mui/material"
import { Box,Stack } from "@mui/system"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import { useHasDesignTemplatePermission } from '../../../../../logic/AssetManagement/Permissions/useHasDesignTemplatePermission';
import { ModulesPermissionNames } from '../../../../../data/menu/ModulesPermissions';
import { DesignTemplatesEndPointLogic } from '../../../../../logic/AssetManagement/Design/Templates/DesignTemplatesEndPointLogic';
import { setIsLoading } from '../../../../../features/userSlice';
import SharedStateContext from '../../../../SharedStateProvider';

interface CreateTemplateProps {
  oauthTokenHeader: any;
  response:any;
}

interface WidgetStructure {
  shared_uid: string;
  name: string;
  widget_meta: string;
}

function CreateTemplate({oauthTokenHeader, response}: CreateTemplateProps) {
  const navigate = useNavigate();  // Initialize the useNavigate hook
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [labelsContent, setLabelsContent] = useState<any[]>([]);
  const [/* errorAccToPageStatus, */ setErrorAccToPageStatus] = useState([]);
  const location = useLocation(); // Initialize the useLocation hook
  const { designTemplatesData,selectedSharedUid, combinedOptions } = location.state || {};
  const {handleCreateClose} = useContext(SharedStateContext);

  // const hasGetPermissions = useHasDesignTemplatePermission([
  //   ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_DETAILS,
  //   ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_LIST
  // ]);

  const hasEditPermissions = useHasDesignTemplatePermission([
    ModulesPermissionNames.PUT_API_DESIGN_TEMPLATES_DETAILS,
    ModulesPermissionNames.POST_API_DESIGN_TEMPLATES_DETAILS,
    ModulesPermissionNames.DELETE_API_DESIGN_TEMPLATES_DETAILS,
    ModulesPermissionNames.PUT_API_DESIGN_TEMPLATES_LIST,
    ModulesPermissionNames.POST_API_DESIGN_TEMPLATES_LIST,
    ModulesPermissionNames.DELETE_API_DESIGN_TEMPLATES_LIST
  ]);

  const [, setError] = useState("");
  const [, setDesignTemplatesData] = useState("");
  const [selectedLabels, setSelectedLabels] = useState<any>([]); // State for selected labels
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const [templateName, setTemplateName] = useState(''); // State for template name
  const [widgetStructure, setWidgetStructure] = useState<WidgetStructure[]>([]); //  State for widget structure
  const [additionalSettings, setAdditionalSettings] = useState(''); // State for additional settings
  const [showErrorMessage, setShowErrorMessage] = useState(false); // State for error message visibility
  const [selectedWidgetStructure, setSelectedWidgetStructure] = useState(''); // State for selected widget structure
  const [html, setHtml] = useState(''); // State for HTML
  const [css, setCss] = useState(''); // State for CSS

  useEffect(() => {
    const fetchWebsiteStructure = async() => {
      try {
        if(oauthTokenHeader) {
          await DesignTemplatesEndPointLogic.getWidgetStructures(oauthTokenHeader, setWidgetStructure);
        } else {
          throw new Error("Error fetching website structure data");
        }
      } catch(error) {
        console.error(error, "error");
      }
    };
    fetchWebsiteStructure();
  },[oauthTokenHeader]);

  useEffect(() => {
    const fetchLabels = async() => {
      try {
        if(oauthTokenHeader) {
          await DesignTemplatesEndPointLogic.getLabels(oauthTokenHeader, setLabelsContent, setErrorAccToPageStatus);
        } else {
          throw new Error("Error fetching labels data");
        }
      } catch (error) {
        console.error(error, "error");
      }
    };
    fetchLabels();
  },[oauthTokenHeader, setErrorAccToPageStatus]);

  const labels = labelsContent.map((item:any) => item?.label);

  const createNewTemplate = async() => {
    const data = {
      website_shared_uid : selectedSharedUid,
      component: selectedWidgetStructure,
      name: templateName,
      html: html,
      css: css,
      labels: selectedLabels,
      additional_info: additionalSettings
    }
    try {
     if (hasEditPermissions) {
      await DesignTemplatesEndPointLogic.createDesignTemplates(oauthTokenHeader, data);
     } else {
       throw new Error("User doesn't have permissions to create new template");
     }
     setDialogOpen(true);
     setIsLoading(true);
     await DesignTemplatesEndPointLogic.getDesignTemplates(oauthTokenHeader, selectedSharedUid, setDesignTemplatesData, setError);
    } catch(error) {
      console.error(error, "error");
    } finally {
      setIsLoading(false);
    }
  }

  const MAX_ADDITIONAL_INFO_LENGTH = 255; // Set this to the maximum length your database allows
  const validate = async() => {
    if(!templateName || !selectedWidgetStructure || !html || !css) {
      setSnackbarOpen(true);
      setSnackbarMessage('Please fill all the fields');
      setIsValid(false);
      return false;
    }

    // Check if templateName is at least 8 characters long and contains only letters and numbers
    const templateNameRegex = /^[a-zA-Z0-9-_&*$#!:|,./\\ ]{8,}$/;
    if (!templateNameRegex.test(templateName)) {
      setSnackbarOpen(true);
      setSnackbarMessage('Template name must be at least 8 characters long and must contain only letters, numbers underscores and hyphens');
      setIsValid(false);
      return false;
    }

    // Check if the template name is unique
    const isTemplateNameUnique = designTemplatesData.every((item:any) => item.name !== templateName);

    if (!isTemplateNameUnique) {
      setSnackbarOpen(true);
      setSnackbarMessage('Template name must be unique');
      setIsValid(false);
      return false;
    }

    // Check if additionalSettings is a valid JSON
    try {
      if (additionalSettings) {
        const parsedAdditionalInfo = JSON.parse(additionalSettings);
      // Optionally, you can check if it's an object
      if (typeof parsedAdditionalInfo !== 'object') {
        throw new Error('Not an object');
      }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMessage('Additional settings must be a valid JSON object');
      setShowErrorMessage(true);
      setIsValid(false);
      return false;
    }

    // Check if additionalSettings exceeds the maximum length
  if (additionalSettings.length > MAX_ADDITIONAL_INFO_LENGTH) {
    setSnackbarOpen(true);
    setSnackbarMessage(`Additional settings must not exceed ${MAX_ADDITIONAL_INFO_LENGTH} characters`);
    setShowErrorMessage(true);
    setIsValid(false);
    return false;
  }

    setIsValid(true); // Validation passed
    return true; // Validation passed
  }

  const handleCreateTemplate = async() => {
    const isFormValid = await validate();
    if (!isFormValid) return; // Only proceed if the form is valid
    try {
      setLoading(true);
      await createNewTemplate(); // Call the createNewTemplate function
      setIsLoading(true);
      setDialogOpen(true); // Open the dialog
    } catch(error) {
      console.error(error, "error");
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  }

  const handleCancelClick = () => {
    handleCreateClose(); // Close the dialog
    navigate('/assets/design/templates');  // Redirect to the specified route
  };

  const handleClose = () => {
    setDialogOpen(false);
    handleCreateClose(); // Close the dialog
    setSelectedWidgetStructure('');
    setSelectedLabels([]);
    setTemplateName('');
    setHtml('');
    setCss('');
    setAdditionalSettings('');
    navigate('/assets/design/templates');  // Redirect to the specified route
  };

  const widgetStructureOptions = widgetStructure.map((widget) => ({
    label: widget?.name,       // Display name in the dropdown
    value: widget?.shared_uid, // Value to be used (shared_uid)
  }));

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('Error')  || isValid ? "success" : "error"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
    };

  return (
    <> 
       {renderSnackbar()}
        <Paper sx={{ padding: "8px 16px", height: "100%", display: "flex", flexDirection: "column" }}>
        <Typography fontSize={24}>Create New Template</Typography>
          <Box sx={{ flexGrow: 1, overflowY: "auto", height: "706px", paddingRight: "16px" }}>
          <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Stack>
                  <Typography marginTop={1}>General</Typography>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Template Name" // Set your label here
                    placeholder="How you like the Theme to be called" // Set your placeholder here
                    multiline
                    required
                    color="secondary"
                    size="small"
                    maxRows={4}
                    value={templateName}
                    onChange={(e) => {setTemplateName(e.target.value)}}
                    sx={{
                      width: "100%",
                      marginTop:"16px"
                    }}
                  />
                  <Typography fontSize={12} marginTop={0.5}>minimum of 8 characters, only letters and numbers. </Typography>
                  <Autocomplete
                      sx={{ width: "100%", marginTop:"16px" }}
                      options={combinedOptions}
                      value={combinedOptions.find((option: any) => option.value === selectedSharedUid) || null}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="What Element does this template refers to"
                          label="Realated Website"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          color="secondary"
                          required
                        />
                    )}
                    size="small"
                  />
                  <Autocomplete
                      sx={{ width: "100%", marginTop:"16px" }}
                      options={widgetStructureOptions ? widgetStructureOptions : []}
                      onChange={(event, newValue) => {
                        setSelectedWidgetStructure(newValue ? newValue.value : ''); // Set the shared_uid of the selected widget
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="What Element does this template refers to"
                          label="Component Type"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          color="secondary"
                          required
                        />
                    )}
                    size="small"
                  />
                  <Typography marginBlock={2}>Labels</Typography>
                 <Autocomplete
                    multiple
                    freeSolo
                    id="fixed-tags-demo"
                    value={selectedLabels}
                    onChange={(event, newValue:any) => {
                      setSelectedLabels(newValue);
                    }}
                    options={labels}
                    getOptionLabel={(option) => option}
                    renderTags={(tagValue, getTagProps) => (
                      <Box
                        sx={{
                          maxHeight: 180, // Limit height for about 8 chips
                          overflowY: 'auto', // Enable scrolling when content overflows
                          display: 'flex',
                          flexWrap: 'wrap', // Ensure chips wrap to the next line if needed
                          gap: '12px', // Add space between each chip
                        }}
                      >
                        {tagValue.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={key}
                              label={option}
                              {...tagProps}
                              color="secondary"
                              sx={{
                                backgroundColor: "#7B1FA2",
                                border: "1px solid #7B1FA2",
                                color: "#FFFFFF",
                                '& .MuiChip-deleteIcon': {
                                  color: '#D7BBE3', // Set the color of the delete icon (cross)
                                },
                              }}
                            />
                          );
                        })}
                      </Box>
                    )}
                    sx={{ width: "100%", height: "234px", '& .MuiAutocomplete-endAdornment': {
                      top: '9%', // Position the clear button at the top 9%
                      position: 'absolute', // Ensure it's positioned relative to the container
                    },'& .MuiAutocomplete-popupIndicator': {
                      display: 'none', // This removes the popup indicator (dropdown arrow)
                    }, }} // Autocomplete takes full width
                    size="small" // Set Autocomplete size to small
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Label"
                        placeholder="Add new label"
                        color="secondary"
                        sx={{ 
                          '& .MuiInputBase-root': { 
                            minHeight: '234px', // Adjust the height of the textarea here
                            alignItems: 'flex-start', // Align the input and placeholder to the top
                          },
                          '& .MuiFormLabel-root': {
                            top: '-5px', // Adjust label position closer to the top
                          },
                          '& .MuiInputBase-input': {
                            paddingTop: '8px', // Add padding to position text at the top
                          },
                        }}
                      />
                    )}
                  />
                  <Typography marginBlock={2}>Additional Settings</Typography>
                  <TextField
                        id="outlined-textarea"
                        label="Content"
                        value={additionalSettings}
                        onChange={(e) => setAdditionalSettings(e.target.value)} // Use the new handler
                        placeholder="{}"
                        multiline
                        rows={7}
                        color="secondary"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: additionalSettings && showErrorMessage ? '#D32F2F' : 'default', // Set border color based on showErrorMessage
                            },
                            '&:hover fieldset': {
                              borderColor: additionalSettings && showErrorMessage ? '#D32F2F' : 'default', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: additionalSettings && showErrorMessage ? '#D32F2F' : 'default', // Border color when focused
                            },
                          },
                        }}
                      />
                    {additionalSettings && showErrorMessage === true ? ( // Conditionally render the warning message
                      <Typography fontSize={12} marginTop={2} color={"#D32F2F"}>
                        {snackbarMessage}
                      </Typography>
                    ):( null )}
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                   <Stack flexDirection={"row"} gap={4}>
                  <Grid item xs={6}>
                    <Stack>
                      <Typography marginTop={1} marginBottom={2}>Code</Typography>
                      <TextField
                        id="outlined-textarea"
                        label="HTML"
                        value={html}
                        onChange={(e) => {setHtml(e.target.value)}}
                        placeholder="{}"
                        multiline
                        rows={22}
                        color="secondary"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                <Stack>
                    <TextField
                      id="outlined-textarea"
                      label="CSS"
                      value={css}
                      onChange={(e) => {setCss(e.target.value)}}
                      placeholder="{}"
                      multiline
                      rows={22}
                      color="secondary"
                      sx={{marginTop:"48px"}}
                    />
                  </Stack>
                  </Grid>
                   </Stack>
                   <SnackbarContent
                      sx={{
                        background: "#FDEDED",
                        color: "#5F2120",
                        marginTop: "32px",
                        boxShadow: "none",
                        border: "1px solid #D32F2F80",
                        padding: "0 4px",
                      }}
                      message={
                        <Box display="flex">
                          <ErrorOutlineIcon sx={{ marginRight: "8px", color: "#5F2120" }} />
                          <Box>
                            <Typography variant="body1" fontWeight="500" sx={{ marginBottom: "4px" }}>
                             Update with Caution:
                            </Typography>
                            <Typography variant="body2"
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 2, // Limit the text to 2 lines
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis', // Adds ellipsis if text is too long
                            }}>
                            Please proceed with extreme care when making updates, as these changes may adversely affect the overall design functionality. Ensure thorough testing and validation to avoid any unintended disruptions or errors in the end user experience.
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                </Grid>
              </Grid>
          </Box>
          </Box>
          <Box>
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={4} gap={1} >
            <Button color="secondary" variant="outlined"><Stack flexDirection={"row"} gap={1} onClick={handleCancelClick}><ArrowBackIosNewIcon fontSize="small"/>Cancel</Stack></Button>
            <Button variant="contained" color="secondary" disabled={!templateName || !selectedWidgetStructure || !html || !css} onClick={handleCreateTemplate} sx={{ textTransform: 'uppercase' }}><Stack flexDirection={"row"} gap={1}>{loading ? <CircularProgress size={24} color="inherit" /> : "Create template"}<CheckIcon fontSize="small"/></Stack></Button>
          </Stack>
          </Box>
        </Paper>
        {/* Dialog component */}
      <Dialog open={dialogOpen} onClose={handleClose} PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}>
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogContent>
          <Typography variant="h6">Template Created Successfully!</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleClose} sx={{ textTransform: 'uppercase' }}>Close</Button>
        </DialogActions>
      </Paper>
      </Dialog>
    </>
  )};

export default CreateTemplate
