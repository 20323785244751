import { useSelector } from "react-redux";
import Card from "../../../../components/AssetManagement/PageManagement/Card/Card";
import { selectIsLoading, selectUserData } from "../../../../features/userSlice";
import SimpleBackdrop from "../../../../components/BackDrop/BackDrop";

export interface CardScreenProps {
    selectedSiteName: string | undefined;
    setSelectedSiteName: (name: string) => void;
}

export default function CardScreen({
    selectedSiteName,
    setSelectedSiteName
}: CardScreenProps) {

    const userWebsitePermissions = useSelector(selectUserData)?.data?.website_permissions;
    const isLoading = useSelector(selectIsLoading);

    return (
        <>
            {isLoading && <SimpleBackdrop />}
            {!!userWebsitePermissions &&
                <Card
                    userWebsitePermissions={userWebsitePermissions}
                    setSelectedSiteName={setSelectedSiteName}
                    selectedSiteName={selectedSiteName}
                />
            }
        </>
    )
}