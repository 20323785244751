import CreateTemplate from '../../../../components/AssetManagement/Design/Templates/CreateNewTemplate/CreateTemplate';

function CreateTemplateScreen(userToken:any, response:any) {
  const oauthTokenHeader = userToken?.userToken;
  const Websiteresponse = userToken?.response;
  return (
    <div>
        <CreateTemplate oauthTokenHeader={oauthTokenHeader} response={Websiteresponse}  />
    </div>
  )
}

export default CreateTemplateScreen
