import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SequenceFilters from '../SequenceFilters/SequenceFilters';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Alert,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Typography
} from "@mui/material";
import "./SequencesTable.css";
import SequenceTableAdditionalContent from './SequenceTableAdditionalContent';
import EditSequence from '../Modal/EditSequence';
import parser from 'cron-parser';
import { format } from 'date-fns';

interface ChainsTableProps {
  etlSequence: any;
  processList: any;
  isLoading: any;
  selectedFrequency: any;
  setSelectedFrequency: any;
  selectedLastRunDate: any;
  setSelectedLastRunDate: any;
  selectedFrequencyDate: any;
  setSelectedFrequencyDate: any;
  selectedStatus: any;
  setSelectedStatus: any;
  hasGetPermissions: any;
  hasEditPermissions: any;
  setEtlSequence: any;
  oauthTokenHeader: string;
  status: any;
  setStatus: any;
  setSelectedChain: any;
  trackPromise: any;
  tableHeadings: any;
  selectedChainStatus: string;
  selectedChain: any;
  setSelectedRow:any;
  selectedRow:any;
  inputValueStatus: string;
  setInputValueStatus: any;
  selectedOptionChain: string;
  setSelectedOptionChain: any;
  setSelectedChainStatus: any;
  setReload: any;
}

export default function SequencesTable({
  etlSequence,
  processList,
  isLoading,
  selectedFrequency,
  setSelectedFrequency,
  selectedLastRunDate,
  setSelectedLastRunDate,
  selectedFrequencyDate,
  setSelectedFrequencyDate,
  selectedStatus,
  setSelectedStatus,
  hasGetPermissions,
  hasEditPermissions,
  setEtlSequence,
  oauthTokenHeader,
  trackPromise,
  tableHeadings,
  setSelectedRow,
  status,
  setStatus,
  selectedChainStatus,
  selectedChain,
  inputValueStatus,
  setInputValueStatus,
  setSelectedChain,
  selectedOptionChain,
  setSelectedOptionChain,
  setSelectedChainStatus,
  setReload
}: ChainsTableProps) {
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({ keytoSort: undefined, direction: undefined });
  const [selectedRowData, setSelectedRowData] = useState<any>(null); // State to store selected row data
  const [selected, setSelected] = useState<any[]>([]);
  const [isEditBtnEnabled, setIsEditBtnEnabled] = useState<boolean>(true);
  const [rowId, setRowId] = useState('');
  const [, setParentChainStatus] = useState<boolean>();
  const [sortedData,] = useState([]) as any;
  const [selectedStatuses, setSelectedStatuses] = useState(null);
  const [selectedLastRun, setSelectedLastRun] = useState<[string, string] | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSequenceData, setSelectedSequenceData] = useState(null) as any;
  const formattedDates = useMemo(() => {
    if (!etlSequence?.data) return [];
    return etlSequence?.data.map((row:any) => {
        const interval = parser.parseExpression(row?.expression);
        const nextDate = interval.next().toDate();
        return format(nextDate, 'dd/MM/yy, h:mm a');
    });
  }, [etlSequence?.data]);

  const combinedArray = useMemo(() => {
    if (!etlSequence?.data) return [];
    return etlSequence.data.map((item: any, index: any) => ({
 ...item,
      cron_job: formattedDates[index] || null
    }));
  }, [etlSequence?.data, formattedDates]);

  function convertCronJobToISO(cronJob: any) {
    const [datePart, timePart] = cronJob.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [time, period] = timePart.split(' ');

    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours < 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    const date = new Date(Date.UTC(2000 + year, month - 1, day, hours, minutes));
    return date.toISOString();
  }

  const updatedArray = useMemo(() => {
    return combinedArray.map((item: any) => ({
      ...item,
      cron_job: convertCronJobToISO(item.cron_job)
    }));
  }, [combinedArray]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setExpandedRow(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleopenEditModal = (row:any) => {
    setOpenModal(true);
    setSelectedSequenceData(row);
  };

  const handleChainStatusChange = (status: boolean) => {
    setParentChainStatus(status);
  };

  const formatDate = (dateString: string) => { // Function to format date
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of year
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    return `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
  };

  const handleExpandRow = useCallback(
    (index: number) => {
      const rowIndex = index % rowsPerPage;
      const expandedRowIndex = page * rowsPerPage + rowIndex;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const row = sortedData[expandedRowIndex]; // Use sortedData instead of filteredData
      setExpandedRow((prevExpandedRow) =>
        prevExpandedRow === expandedRowIndex? null : expandedRowIndex
      );
    },
    [page, rowsPerPage, sortedData]
  );



  const filteredDataSequence = useMemo(() => {
    return updatedArray?.filter((sequence: any) => {
      const isActive = selectedStatuses?.[0] === 'true';
      const statusChainName = selectedChain && selectedChain.length !== 0
      ? selectedChain?.includes(sequence?.name)
      : true; // If no Asset is selected, treat it as a match
  
      const isDateInRange = Array.isArray(selectedLastRunDate) && selectedLastRunDate?.length === 2 && selectedLastRunDate?.[0] && selectedLastRunDate?.[1]
      ? selectedLastRunDate?.[0] === selectedLastRunDate?.[1] // If both dates are the same, check for equality
        ? new Date(sequence?.last_run).toDateString() === new Date(selectedLastRunDate?.[0]).toDateString()
        : new Date(sequence?.last_run) >= new Date(selectedLastRunDate?.[0]) &&
        new Date(sequence?.last_run) <= new Date(new Date(selectedLastRunDate?.[1]).setHours(23, 59, 59, 999))
      : true;
  
      const isDateRangeInFreqeuncy = Array.isArray(selectedFrequencyDate) && selectedFrequencyDate?.length === 2 && selectedFrequencyDate?.[0] && selectedFrequencyDate?.[1]
    ? selectedFrequencyDate?.[0] === selectedFrequencyDate?.[1] // If both dates are the same, check for equality
      ? new Date(sequence?.cron_job).toDateString() === new Date(selectedFrequencyDate?.[0]).toDateString()
      : new Date(sequence?.cron_job) >= new Date(selectedFrequencyDate?.[0]) &&
      new Date(sequence?.cron_job) <= new Date(new Date(selectedFrequencyDate?.[1]).setHours(23, 59, 59, 999))
    : true; // If no date range is selected, treat it as a match
  
      return (
        (isDateInRange) && (isDateRangeInFreqeuncy) && (statusChainName) && (selectedStatuses === null || (isActive && sequence?.active === true) || (!isActive && sequence?.active === false))
      )
    });
  }, [updatedArray, selectedStatuses, selectedChain, selectedLastRunDate, selectedFrequencyDate]);

useEffect(()=>{
  setPage(0);
},[selectedChain,selectedFrequencyDate,selectedLastRunDate, status, setPage]);


  const tableheaders = [
    { id: 0, KEY: 'edit', label: 'Edit', sortable: false },
    { id: 1, KEY: 'id', label: 'Cron ID', sortable: true },
    { id: 2, KEY: 'name', label: 'Queue Name', sortable: true },
    { id: 3, KEY: 'cron_job', label: 'Frequency', sortable: true },
    { id: 4, KEY: 'last_run', label: 'Last Run', sortable: true },
    { id: 5, KEY: 'status', label: 'Status', sortable: true },
    { id: 6, KEY: 'count', label: 'Process Count', sortable: true },
    { id: 7, KEY: 'info', label: 'More Info', sortable: false },
  ];

// function to handle click on table headers
const handleHeaderClick = (header : any) => {
  if (!header.sortable) return; // Ignore click if column is not sortable

  setSort({
    keytoSort: header.KEY,
    // Toggle sort direction if the same column is clicked, otherwise default to ascending
    direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
  });
}

// eslint-disable-next-line react-hooks/exhaustive-deps
const getSortedData = (arrayToSort: any[]) => {
   // If no sorting key is set, return the original array
   if (!sort.keytoSort || !sort.direction) {
    return arrayToSort;
  }

  // Clone the array to avoid mutating the original array
  return arrayToSort.slice().sort((a, b) => {
    // Check if sort.keytoSort is not undefined and has the property
    const keytoSort = sort.keytoSort;

    if (keytoSort === undefined) {
      return 0; // Return 0 if the sorting key is undefined
    }

    const aValue = a[keytoSort];
    const bValue = b[keytoSort];

    // Handle undefined values for sorting
    if (aValue === undefined) {
      return 1; // Move undefined values to the end
    }
    if (bValue === undefined) {
      return -1; // Move undefined values to the end
    }

    // Handle "N/A" values for sorting
    if (aValue === "") {
      return 1; // Move "N/A" values to the end
    }
    if (bValue === "") {
      return -1; // Move "N/A" values to the end
    }

    // Perform sorting based on the direction and type of values
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // String comparison (localeCompare for correct string sorting)
      return sort.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Numeric comparison
      return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (aValue instanceof Date && bValue instanceof Date) {
      // Date comparison
      return sort.direction === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    } else {
      // Fallback to string comparison if values are of different types
      return sort.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });
};

  // eslint-disable-next-line
  const sortedRows = getSortedData(filteredDataSequence);

  const paginatedSequences = sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    if (newRowsPerPage !== rowsPerPage) {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    }
  };

  useEffect(() => {
  const newPageCount = Math.ceil(filteredDataSequence.length / rowsPerPage);
  setPageCount(newPageCount);
// eslint-disable-next-line
},[rowsPerPage]);



  return (
    <>
      <SequenceFilters
        updatedArray={updatedArray}
	      etlSequence = {etlSequence}
        processList = {processList}
        selectedStatus = {selectedStatus}
        setSelectedStatus = {setSelectedStatus}
        selectedFrequency = {selectedFrequency}
        setSelectedFrequency = {setSelectedFrequency}
        setPage = {setPage}
        selectedRowData = {selectedRowData}
        setSelectedRowData = {setSelectedRowData}
        oauthTokenHeader = {oauthTokenHeader}
        setSelectedChain={setSelectedChain}
        selectedChain={selectedChain}
        setStatus={setStatus}
        selected={selected}
        setSelected={setSelected}
        rowId={rowId}
        setRowId={setRowId}
        status={status}
        hasGetPermissions={hasGetPermissions}
        isEditBtnEnabled={isEditBtnEnabled}
        setIsEditBtnEnabled={setIsEditBtnEnabled}
        hasEditPermissions={hasEditPermissions}
        setEtlSequence={setEtlSequence}
        inputValueStatus = {inputValueStatus}
        setInputValueStatus = {setInputValueStatus}
	      selectedChainStatus = {selectedChainStatus}
        selectedOptionChain = {selectedOptionChain}
        setSelectedOptionChain={setSelectedOptionChain}
        setSelectedChainStatus={setSelectedChainStatus}
        setReload={setReload}
        filteredDataSequence={filteredDataSequence}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        selectedLastRun={selectedLastRun}
        setSelectedLastRun={setSelectedLastRun}
        selectedLastRunDate = {selectedLastRunDate}
        setSelectedLastRunDate = {setSelectedLastRunDate}
        selectedFrequencyDate={selectedFrequencyDate}
        setSelectedFrequencyDate={setSelectedFrequencyDate}
      />
      <TableContainer className="chainsTableWrap" component={Paper} sx={{ maxHeight: 800 }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableRow>
          {tableheaders.map((header) => (
              <TableCell key={header.id}>
                <TableSortLabel
                  active={sort.keytoSort === header.KEY}
                  direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                  onClick={() => handleHeaderClick(header)}
                  disabled={!header.sortable}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedSequences?.length !== 0 && paginatedSequences
            ?.map((row: any, index: number) => {
              const uniqueProcesses = new Set(
                row.processes.map((process: any) => process.process__id)
              );
              const uniqueProcessCount = uniqueProcesses.size;
              return (
                <React.Fragment key={index}>
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        color="secondary"
                        onClick={() => handleopenEditModal(row)}
                      >
                        <img className="icon4" alt="" src="/icon4.svg" />
                      </IconButton>
                      <EditSequence
                        etlSequence={etlSequence}
                        processList={processList}
                        selectedRowData={selectedRowData}
                        setSelectedRowData={setSelectedRowData}
                        selectedSequenceData={selectedSequenceData}
                        setSelectedSequenceData={setSelectedSequenceData}
                        setSelected={setSelected}
                        rowId={rowId}
                        open={openModal}
                        oauthTokenHeader={oauthTokenHeader}
                        hasGetPermissions={hasGetPermissions}
                        hasEditPermissions={hasEditPermissions}
                        setEtlSequence={setEtlSequence}
                        onClose={handleCloseModal}
                        setReload={setReload}
                      />
                    </TableCell>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {formatDate(row?.cron_job)}
                    </TableCell>
                    <TableCell align="left">{formatDate(row.last_run)}</TableCell>
                    <TableCell align="left">
                      {row?.active === true ? (
                        <Alert
                          iconMapping={{
                            success: (
                              <CheckCircleOutlineOutlinedIcon
                                fontSize="inherit"
                                style={{ color: "#1E4620" }}
                              />
                            ),
                          }}
                          sx={{
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            width: "100px",
                            padding: "6px",
                            backgroundColor: "#EDF7ED",
                            ".MuiAlert-icon": {
                              marginRight: "5px",
                            },
                          }}
                          variant="outlined"
                          severity="success"
                        >
                          Active
                        </Alert>
                      ) : (
                        <Alert
                          iconMapping={{
                            error: (
                              <CancelOutlinedIcon
                                fontSize="inherit"
                                style={{ color: "#5F2120" }}
                              />
                            ),
                          }}
                          sx={{
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            width: "100px",
                            padding: "6px",
                            backgroundColor: "#FDEDED",
                            ".MuiAlert-icon": {
                              marginRight: "5px",
                            },
                          }}
                          variant="outlined"
                          severity="error"
                        >
                          Inactive
                        </Alert>
                      )}
                    </TableCell>
                    <TableCell align="left">{uniqueProcessCount}</TableCell>
                    <TableCell align="left">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        color="secondary"
                      >
                        {expandedRow === index ? (
                          <KeyboardArrowUpIcon
                            color="secondary"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleExpandRow(index)}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            color="secondary"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleExpandRow(index)}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {expandedRow === index + page * rowsPerPage && (
                    <TableRow>
                      <TableCell colSpan={tableheaders.length + 1}>
                          <Box sx={{ padding: "20px 0px" }}>
                            <SequenceTableAdditionalContent
                              etlSequence={etlSequence}
                              hasGetPermissions={hasGetPermissions}
                              hasEditPermissions={hasEditPermissions}
                              setEtlSequence={setEtlSequence}
                              oauthTokenHeader={oauthTokenHeader}
                              expandedRow={expandedRow}
                              trackPromise={trackPromise}
                              onChainStatusChange={handleChainStatusChange}
                              selectedChain={selectedChain}
                              data={sortedData[expandedRow]}
                              rowId={rowId}
                            />
                          </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )})}
              {sortedRows?.length === 0 && (
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell colSpan={tableheaders.length + 1} align="center" sx={{marginTop:"20px", marginBottom: "20px" }}>
                      <Typography variant="h6">There is no data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
        </TableBody>
      </Table>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TablePagination
                  component="div"
                  count={sortedRows?.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[]}
                  sx={{
                    ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                      display: "none",
                    },
                    ".MuiTablePagination-actions": {
                      display: "none",
                    },
                    ".MuiTablePagination-toolbar": {
                      paddingLeft: "16px",
                    },
                  }}
                />
                <Pagination
                  count={pageCount}
                  showFirstButton
                  showLastButton
                  className="pagination-button"
                  color="secondary"
                  size="small"
                  sx={{
                    ".css-wjh20t-MuiPagination-ul": {
                      justifyContent: "center",
                    },
                  }}
                  onChange={(event, value) =>
                    handleChangePage(null, value - 1)
                  }
                />
                <TablePagination
                  component="div"
                  count={sortedRows?.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  classes={{
                    displayedRows: 'hidden-displayed-rows', // Apply the custom class
                  }}
                  sx={{
                    ".MuiTablePagination-actions": {
                      display: "none",
                    },
                  }}
                />
              </Stack>
    </TableContainer>
  </>
  );
}
