import {
  Autocomplete,
  TextField,
  Button,
  Box,
  InputAdornment,
  Dialog,
  DialogContent,
  MenuItem,
  DialogActions,
  Select,
  Typography,
  Tooltip,
} from "@mui/material";
import "./SitePages.css";
import CustomTable from "../../../CustomTable/CustomTable";
import tableHeadCells from "./tableHeadCells.json";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect, useMemo } from "react";
import { WebsiteWithUuid } from "../../../../data/website/Website";
import {
  PageGroup,
} from "../../../../data/sitePages/SitePage";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SitePagesFilter from "./SitePagesFilter";
import RefreshIcon from '@mui/icons-material/Refresh';
/* import AssignPageGroup from "../AssignPageGroup/AssignPageGroup"; */

export interface SitePagesProps {
  sitePages: any;
  selectedSite: WebsiteWithUuid;
  handleCreatePage: any;
  pageGroups: PageGroup[] | undefined;
  handelCreatePagePopUp: any;
  openCreateNewPage: boolean;
  setOpenCreateNewPage: any;
  hasEditPermissions: boolean;
  oauthTokenHeader:string;
  setDuplicatePageResponse:any;
  duplicatePageResponse:any;
  setRefresh:any;
  deletePageData:any;
  setDeletePageData:any;
  // Function to set the selected option for group
  setSelectedOptionGroup: React.Dispatch<React.SetStateAction<string>>;
  // Function to set the selected option for status
  setSelectedOptionStatus: React.Dispatch<React.SetStateAction<string>>;
  // Function to set the selected option for edit
  setSelectedOptionEdit: React.Dispatch<React.SetStateAction<string>>;
  selectedOptionGroup : string;
  selectedOptionEdit : string;
  selectedOptionStatus : string;
  pageGroupApiCall: any;
  pageGroupForAssign:any;
  setSelectedPageForAssign:any;
  setPageDetails:any;
  pageDetails:any;
  assignPageGroup:any;
  setIsMetaUpdated:any;
  isMetaUpdated:any;
}

export default function SitePages({
  sitePages,
  selectedSite,
  handleCreatePage,
  pageGroups,
  handelCreatePagePopUp,
  openCreateNewPage,
  setOpenCreateNewPage,
  hasEditPermissions,
  oauthTokenHeader,
  duplicatePageResponse,
  setDuplicatePageResponse,
  setRefresh,
  deletePageData,
  setDeletePageData,
  setSelectedOptionGroup,
  setSelectedOptionEdit,
  setSelectedOptionStatus,
  selectedOptionGroup,
  selectedOptionEdit,
  selectedOptionStatus,
  pageGroupApiCall,
  pageGroupForAssign,
  setSelectedPageForAssign,
  setPageDetails,
  pageDetails,
  assignPageGroup,
  setIsMetaUpdated,
  isMetaUpdated,
}: SitePagesProps) {
  const [selectedPage, setSelectedPage] = useState<string | undefined>();
  const [pageTitle, setPageTitle] = useState<string>();
  const [pageName, setPageName] = useState<string>();
  const [pageGroup, setPageGroup] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setErrorValidate] = useState<string>();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  // tableProps states
  const [groupSelected, setGroupSelected] = useState(false);
  const [editedSelected, setEditedSelected] = useState(false);
  const [statusSelected, setStatusSelected] = useState(false);
  const [inputValueGroup, setInputValueGroup] = useState("");
  const [inputValueEdited, setInputValueEdited] = useState("");
  const [inputValueStatus, setInputValueStatus] = useState("");
  const [pageOwnerSearchTerm, setPageOwnerSearchTerm] = useState("");
  const [selectedPageOwners, setSelectedPageOwners] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<any[]>([]);
  const [assignPageGroupDialogOpen, setAssignPageGroupDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState(''); // input value for the search
  const [isOpen, setIsOpen] = useState(false); // dropdown open state
  const [setIsAssignPageGroupOpen] = useState(false)
  const [isAssignPageGroupEnable, setIsAssignPageGroupEnable] = useState(true);
  const [selectedOption,setSelectedOption] = useState(null);
  const originalSitePages = useMemo(() => {
    return sitePages || [];
  }, [sitePages]);
  const [isCheckboxCheckedArray, setIsCheckboxCheckedArray] = useState(
    Array(originalSitePages ? originalSitePages.length : 0).fill(false)
  );
 
const siteUid  = selectedSite && Object.keys(selectedSite)[0]

  const tableProps = {
    sitePages,
    headCells: tableHeadCells,
    selectedPage,
    selectedSite,
    hasEditPermissions,
    oauthTokenHeader,
    siteUid,
    duplicatePageResponse,
    setDuplicatePageResponse,
    deletePageData,
    setDeletePageData,
    selectedOptionGroup,
    selectedOptionStatus,
    setSelectedOptionGroup,
    selectedOptionEdit,
    setSelectedOptionEdit,
    setSelectedOptionStatus,
    groupSelected,
    setGroupSelected,
    editedSelected,
    setEditedSelected,
    statusSelected,
    setStatusSelected,
    inputValueGroup,
    setInputValueGroup,
    inputValueEdited,
    setInputValueEdited,
    inputValueStatus,
    setInputValueStatus,
    pageOwnerSearchTerm,
    setPageOwnerSearchTerm,
    selectedPageOwners,
    setSelectedPageOwners,
    searchText, 
    setSearchText,
    setIsAssignPageGroupOpen,
    setIsAssignPageGroupEnable,
    setPageDetails,
    setIsCheckboxCheckedArray,
    isCheckboxCheckedArray,
    setRefresh,
    assignPageGroup,
    pageGroupForAssign,
    pageGroups,
    pageDetails,
    assignPageGroupDialogOpen,
    setAssignPageGroupDialogOpen,
    setIsMetaUpdated,
    isMetaUpdated,
    setSelectedOption,
    setSelectedDate,
    selectedDate
  };

  useEffect(() => {
    const deletedPages = sessionStorage.getItem('deletedPages');
    if (deletedPages) {
      const deletedPagesArray = JSON.parse(deletedPages);
      if (deletedPagesArray.includes(pageTitle)) {
        setShowErrorDialog(true);
      }
    }
  }, [pageTitle]);

  const handleClose = () => {
    setOpenCreateNewPage(false);
    setPageName("");
    setPageTitle("");
    setPageGroup("");
    setErrorValidate("");
  };

  const handleRetry = () => {
    setShowErrorDialog(false);
    setOpenCreateNewPage(true);
  };

  const validate = () => {
    if (!pageTitle || !pageGroup) {
      setErrorValidate("Missing Mandatory Fields");
    } else if (sitePages?.records?.some((post : any) => post?.post_title === pageTitle)) {
      setErrorValidate("Duplicate Page Title");
      setShowErrorDialog(true);
    } else {
      handleCreatePage(pageTitle, pageName, pageGroup, setShowErrorDialog);
      handleClose();
    }
  };

  const openAssignPageGroupDialog = () => { 
    pageGroupApiCall()
    setAssignPageGroupDialogOpen(true)
  }
  
  const handelSelectPage = (event: any, option: any) => 
  {
    if (option) {
      setSelectedPage(option?.shared_uid);
    } else {
      setSelectedPage("")
    }
  };

  const filteredSitePages = sitePages?.filter((page:any) => page?.shared_uid !== null && page?.post_status !== "bin");

  return (
    <>
      <div>
        <div className="card-bulk-actions-1-mediu1">
          <div className="cardheader1">
            <div className="content9">
              <h5 className="header2">Pages</h5>
              <div className="subheader3">
                Edit a specific page or modify multiple pages settings
              </div>
            </div>
            <div className="iconbutton18">
              <img className="icon71" alt="" src="/icon2.svg" />
            </div>
          </div>
          <div className="custom-table-toolbar1">
            <div className="queries2">
              <Autocomplete
                disablePortal
                disabled={!filteredSitePages} // disable the input if there are no site pages
                id="combo-box-demo"
                getOptionLabel={(option: any) => option.post_title ? option.post_title : option.preview_link} // display the page title or URL
                options={filteredSitePages || []} // site pages array
                sx={{ width: '100%', maxWidth:"400px" }}
                color="secondary"
                open={isOpen && searchText.length > 0} // open the dropdown when the input value changes
                onInputChange={(event:any, value:any) => { setSearchText(value); setIsOpen(true);}} // open the dropdown when the input value changes
                onClose={() => {setIsOpen(false);}}
                onChange={(e:any, option:any) => { // handle the selection of an option
                handelSelectPage(e, option);
                setSelectedOption(option)
                setIsOpen(false); // close the dropdown when an option is selected
                }}
                filterOptions={(options:any, { inputValue }) => // filter the options based on the input value
                  options.filter(
                    (option:any) =>
                      option.post_title.trim()?.toLowerCase().includes(inputValue.trim()?.toLowerCase()) ||
                      option.preview_link.trim()?.toLowerCase().includes(inputValue.trim()?.toLowerCase())
                  )
                }
                renderOption={(propsOptions, option) => (
                  <Box
                    component="li"
                    {...propsOptions}
                    key={option?.post_id as any}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{fontWeight: 500}}>
                      {(option.post_title)} :
                    </Typography>
                    <Typography>
                      {(option.preview_link)}
                    </Typography>
                  </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Page Title or URL" // label for the input
                    disabled={!sitePages}
                    color="secondary"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ marginLeft: "12px" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                openOnFocus={false} // prevent dropdown from opening on focus
                size="small"
                value={selectedOption}
              />
               {/* site pages filter component called here  */}
               <SitePagesFilter {...tableProps} />
            </div>

            <div className="actions2">
            <Tooltip title="Refresh">
              <RefreshIcon onClick={(()=>setRefresh(true))} color="secondary" style={{cursor:"pointer"}}/>
                </Tooltip>
              <Button disabled={isAssignPageGroupEnable} onClick={openAssignPageGroupDialog}  variant="outlined" color="secondary"  sx={{
              fontSize: '0.875rem', // Default font size
              '@media (max-width: 1200px)': {
              fontSize: '0.5rem', // Font size for viewport width >= 1200px
              }
                }}>
                ASSIGN PAGE GROUP
              </Button>
              {hasEditPermissions ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ backgroundColor: "#9C27B0", color: "white" }}
                  onClick={handelCreatePagePopUp}
                  sx={{
                    fontSize: '0.875rem', // Default font size
                    '@media (max-width: 1200px)': {
                    fontSize:'0.5rem', // Font size for viewport width >= 1200px
                    }
                      }}
                >
                  Create New Page 
                  <AddIcon sx={{
                    fontSize: '0.875rem', // Default font size
                    '@media (max-width: 1200px)': {
                    fontSize:'1rem', // Font size for viewport width >= 1200px
                    }
                      }}/>
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          {!sitePages ? "" : <CustomTable {...tableProps} />}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <Dialog
          open={openCreateNewPage}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
        >

          <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
            <DialogTitle sx={{padding:"0px 0px 16px 0px"}}>Create New Page</DialogTitle>
            {/* {errorValidate ? <Alert severity="warning" sx={{marginBottom:"10px"}} >{errorValidate}</Alert> : "" } */}
            <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  required
                  fullWidth
                  id="pageTitle"
                  label="Page Title"
                  value={pageTitle}
                  onChange={(e) => setPageTitle(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <InputLabel id="pageGroups-label">Page Group *</InputLabel>
                <Select
                  required
                  fullWidth
                  labelId="pageGroups-label"
                  id="pageGroups"
                  value={pageGroup}
                  onChange={(e) => setPageGroup(e.target.value)}
                >
                  {pageGroups?.map((option: PageGroup) => (
                    <MenuItem key={option.shared_uid} value={option.shared_uid}>
                      {option.group_name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: "20px" }}>All fields are mandatory</div>
            <Box
              component="div"
              sx={{
                p: 1,
                borderRadius: 1,
                backgroundColor: '#F7EEF9',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon style={{ marginRight: '8px' }} color="secondary" />
                <Typography variant="h6" color="#9C27B0">PLEASE NOTE:</Typography>
              </div>
              <Typography color="#9C27B0" sx={{ paddingLeft: 5 }}>
                All pages are created with a default "Draft" status. You will be able to change that once the page is created.
              </Typography>
            </Box>
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => validate()}
              variant="contained"
              color="secondary"
              endIcon={<AutoFixHighIcon />}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Assign page group Dialog */}
     {/*  <AssignPageGroup isCheckboxCheckedArray={isCheckboxCheckedArray} setIsCheckboxCheckedArray={setIsCheckboxCheckedArray} setRefresh={setRefresh} setPageDetails={setPageDetails} assignPageGroup={assignPageGroup} pageGroupForAssign={pageGroupForAssign} pageGroups={pageGroups} pageDetails={pageDetails} assignPageGroupDialogOpen={assignPageGroupDialogOpen} setAssignPageGroupDialogOpen={setAssignPageGroupDialogOpen} /> */}
      {/* Error Dialog for Duplicate Page Title */}
      <Dialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Duplicate Page Title</DialogTitle>
        <DialogContent>
          <Typography>
            A page with this page title already exists or there was an error while creating the page. Do you want to retry?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowErrorDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRetry} color="secondary" variant="contained">
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
