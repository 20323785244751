import {Avatar, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Box,} from '@mui/system';
import './CustomTableActivityNotes.css';

interface Props {
  oauthTokenHeader:any
  singleActivityNotesContent:any
  singleNoteShareUID:any
  loading: any
  compareEmailForEdit:boolean
  UpdateActivityNote:Function
}

function AdditionalContentNote({oauthTokenHeader,singleActivityNotesContent,UpdateActivityNote,loading,singleNoteShareUID,compareEmailForEdit}:Props) {
  const key = Object.keys(singleActivityNotesContent)?.[0];
  const initialText = `${singleActivityNotesContent[key]?.[0]?.note}`;
  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };
  const infoArray = [
    {
      label: 'By',
      content: '',
      gridSize: 5,
      avatar: `${singleActivityNotesContent[key]?.[0]?.created_by}` // URL to the avatar image
  },
    {
      label: 'Posted',
      content: `${formatDate(singleActivityNotesContent[key]?.[0]?.created)}`,
      gridSize: 5,
    },
    {
      label: 'Last Edited',
      content: `${formatDate(singleActivityNotesContent[key]?.[0]?.modified)}`,
      gridSize: 5,
    },
  ];

  return (

    <div>
      {!loading && (
        <>
        <TextField
        color="secondary"
        id="outlined-multiline-static"
        label="Full Note"
        multiline
        rows={7}
        value={initialText || ""} // Use empty string if initialText is undefined
        sx={{ width: '100%', height: '200px', marginBottom: '8px' }}
        InputProps={{
          readOnly: true,
          sx: {
            height: '100%',},}}/>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" justifyContent="space-between">
      <Grid item xs={12} display={'flex'} flexDirection={'row'} gap={3}>
        {infoArray.map((info, index) => (
         <Box key={index}  display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography noWrap sx={{ fontWeight: '500', fontSize: '14px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                {info.label}
                {info.avatar && (
                  <Avatar src={info.avatar} alt={info.label} sx={{ width: 24, height: 24, marginRight: '8px' }} >
                   {singleActivityNotesContent && singleActivityNotesContent[key]?.[0]?.modified_by?.charAt(0)}
                  </Avatar>
                )}
              </Typography>
              {typeof info.content === 'string' ? (
                <Typography noWrap sx={{ fontSize: '14px', marginLeft: '8px' }}>
                  {info.content}
                </Typography>
              ) : (
                info.content
              )}
        </Box>
        ))}
        </Grid>
         <Grid item xs={12} textAlign={'right'}>
        </Grid>
      </Grid>
      </>
      )}
      {loading && <CircularProgress size={24} color="secondary" />}
    </div>
  );
}

export default AdditionalContentNote;
