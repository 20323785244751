import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

const device = [{ label: "Mobile" }, { label: "Tablet" }, { label: "Desktop" }];
const year = [
  { label: "2021" },
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },
];
const month = [
  { label: "January" },
  { label: "February" },
  { label: "March" },
  { label: "April" },
];
const period = [
  { label: "Q1" },
  { label: "Q2" },
  { label: "Q3" },
  { label: "Q4" },
];
const paegName = [
  { label: "https://www.toppariuris.com" },
  { label: "https://www.toppariuris.com" },
  { label: "https://www.toppariuris.com" },
];

const PerformanceDashboardFilter = () => {
  return (
    <>
      <Grid container spacing={3} mb={3} mt={0} className="ratedTableWrap">
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Autocomplete
                disablePortal
                options={device}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Device" color="secondary" />
                )}
                size="small"
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                disablePortal
                options={year}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Year" color="secondary" />
                )}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ display: "flex" }}>
                <FormControlLabel
                  value="month"
                  control={<Radio size="small" color="secondary" />}
                  label="Month"
                />
                <Autocomplete
                  disablePortal
                  options={month}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Month" color="secondary" />
                  )}
                  size="small"
                />
              </Grid>
              <Grid item xs={6} sx={{ display: "flex" }}>
                <FormControlLabel
                  value="period"
                  control={<Radio size="small" color="secondary" />}
                  label="Period"
                />
                <Autocomplete
                  disablePortal
                  options={period}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Period" color="secondary" />
                  )}
                  size="small"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "right" }}>
          <Button
            sx={{
              textTransform: "uppercase",
              fontSize: "13px",
              fontWeight: 500,
              color: "#9C27B0",
            }}
            // onClick={}
          >
            Advanced options
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: "1rem" }}
            //onClick={}
          >
            display
          </Button>
        </Grid>
        <Grid item xs={5} sx={{ display: "flex" }}>
          <FormControlLabel
            control={<Checkbox size="small"  color="secondary" defaultChecked />}
            label="Page Specific Data"
            sx={{whiteSpace:'nowrap'}}
          />
          <Autocomplete
            fullWidth
            options={paegName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Page Name or URL"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ marginLeft: "12px" }} />
                    </InputAdornment>
                  ),
                }}
                color="secondary"
              />
            )}
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceDashboardFilter;
