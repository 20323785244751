import { Paper, Typography } from "@mui/material";
import React from "react";
import TrafficClickIdentifierFilterCard from "./TrafficClickIdentifierFilter/TrafficClickIdentifierFilterCard";
import TrafficClickIdentifierTable from "./TrafficClickIdentifierTable/TrafficClickIdentifierTable";

const tableHeadings = {
  identifier_name: "Identifier Name",
  traffic_source: "Traffic Source",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    identifier_name: "gclid",
    traffic_source: "g ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    identifier_name: "gclid",
    traffic_source: "g ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    identifier_name: "gclid",
    traffic_source: "g ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const TrafficClickIdentifier = () => {
  return (
    <>
      <Paper sx={{boxShadow:'none', padding: "8px 16px 0px",borderEndEndRadius:'0px',borderBottomLeftRadius:'0px'  }}>
        <Typography fontSize={24}>Traffic Click Identifier List</Typography>
        <TrafficClickIdentifierFilterCard />
      </Paper>
      <Paper sx={{boxShadow:'none', borderTopLeftRadius:'0px',borderTopRightRadius:'0px',  }}>
      <TrafficClickIdentifierTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default TrafficClickIdentifier;
