import React, { useState, useEffect, } from "react";
import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Stack,
  TablePagination,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Alert from "@mui/material/Alert";
import { AdditionalContentAccounts } from './AdditionalContentAccounts';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditIcon from "@mui/icons-material/Edit";
import "./CustomTableNewFeatures.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditAccountDialog from '../CardFilters/EditAccount';

interface Props {
  isLoading:any,
  accountUpdateStatus:any,
  selectedRows:any,
  postWebsiteToAcc:any,
  setSelectedRows:any,
  siteNames:any,
  getAdAccountDetailsForEdit:any,
  singleAdAccountData:any,
  setSingleAdAccountData:any,
  hasGetPermissions:any,
  selectedRow:any,
  setSelectedRow:any,
  advertisingAccounts: any[];
  oauthTokenHeader: string;
  trackPromise: any;
  tableHeadings: any;
  fetchAdAccountData:any;
  selectedOptionSource:string[];
  selectedOptionAsset:string[];
  selectedOptionTerritory:string[];
  selectedAsset:any;
  setSelectedAsset:any;
  selectedAdvertisementSource:any;
  setSelectedAdvertisementSource:any;
  selectedTerritory:any;
  setSelectedTerritory:any;
  selectedAccount:string;
  setIsRowTrue:any;
  setRowId:any;
  setIsEditBtnEnabled:any;
  setIsCheckboxCheckedArray:any
  isCheckboxCheckedArray:any
  trafficSources:any
  getTrafficSource: Function
  setTrafficSourceData:any
  getAdAccountDetails:Function
  editDialogOpen:any 
  setEditDialogOpen:any
  fetchSingleAdAccountData:Function,
  setReloadAfterToggleEdit:any,
  rowIndexNumber:any
  setrowIndexNumber:any
  setAccountUpdateStatus:any
  modifiedArray:any
}


export default function CustomTableNewFeatures({
  isLoading,
  modifiedArray,
  accountUpdateStatus,
  postWebsiteToAcc,
  siteNames,
  getAdAccountDetailsForEdit,
  singleAdAccountData,
  setSingleAdAccountData,
  hasGetPermissions,
  setReloadAfterToggleEdit,
  selectedRow,
  setSelectedRow,
  selectedRows,
  setSelectedRows,
  advertisingAccounts,
  oauthTokenHeader,
  trackPromise,
  tableHeadings,
  selectedAsset,
  setSelectedAsset,
  selectedAdvertisementSource,
  setSelectedAdvertisementSource,
  selectedTerritory,
  setSelectedTerritory,
  fetchAdAccountData,
  selectedOptionSource,
  selectedOptionAsset,
  selectedOptionTerritory,
  selectedAccount,
  setIsRowTrue,
  // setRowId,
  // setIsEditBtnEnabled,
  setIsCheckboxCheckedArray,
  isCheckboxCheckedArray,
  trafficSources,
  getTrafficSource,
  setTrafficSourceData,
  getAdAccountDetails,
  editDialogOpen,
  setEditDialogOpen,
  fetchSingleAdAccountData,
  rowIndexNumber,
  setrowIndexNumber,
  setAccountUpdateStatus
}: Props) {
  const [rows, setRows] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [singleAccountShareUID, setSingleAccountShareUID] = useState<any>(null);
  const [selected, setSelected] = useState<any[]>([]);
  const [, setIsEditBtnEnabled] = useState<boolean>(true);
  const [, setRowId] = useState('');
  const [, setSelectedRowData] = useState<any>(null); // State to store selected row data
  const [expandedRow,] = useState<number | null>(null);
  const [, setParentAccountStatus] = useState<boolean>();
  const headCells = Object.entries(tableHeadings);
  const [accountUpdateError, setAccountUpdateError] = useState(false);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({
    keytoSort: 'modified',
    direction: 'desc'
  });

  // Function to integrate filter component with the table
  const filteredData = modifiedArray?.filter((account:any) => {
    const accountName = selectedAccount && selectedAccount?.length !== 0 ? selectedAccount?.includes(account?.account_name): true;
    const assetName = selectedAsset && selectedAsset?.length !== 0 ? selectedAsset?.includes(account?.websites__site_name): true;
    const sourceName = selectedAdvertisementSource && selectedAdvertisementSource?.length !== 0 ? selectedAdvertisementSource?.includes(account?.traffic_source): true;
    const territoryname = selectedTerritory && selectedTerritory?.length !== 0 ? selectedTerritory?.includes(account?.territory): true;
    return (
     (accountName) && (assetName) && (sourceName) && (territoryname)
    )
  });

  const uniqueFilteredData = Array.from(
    new Map(filteredData.map((item:any) => [item.account_id, item])).values()
  );

  // headers for the table columns with sortable option for each column
  const tableheaders = [
    {id:0, KEY: 'account_name', sortable: true, label: 'Account Name'},
    {id:1, KEY: 'account_id', sortable: true, label: 'Account ID'},
    {id:2, KEY: 'active', sortable: true, label: 'Status'},
    {id:3, KEY: 'traffic_source', sortable: true, label: 'Traffic Source'},
    {id:4, KEY: 'industry', sortable: true, label: 'Industry'},
    {id:5, KEY: 'territory', sortable: true, label: 'Territory'},
    {id:6, KEY: 'modified', sortable: true, label: 'Last Modified'},
    {id:7, KEY: 'modified_by', sortable: true, label: 'Modified By'},
    {id:8, KEY: 'more_info', sortable: false, label: 'More Info'},
  ];
  
  // function to handle click on table headers
  const handleHeaderClick = (header : any) => {
    if (!header.sortable) return; // Ignore click if column is not sortable
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSortedData = (arrayToSort: any[]) => {
    // If no sorting key is set, return the original array
    if (!sort.keytoSort || !sort.direction) {
      return arrayToSort;
    }

    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
    const keytoSort = sort.keytoSort;
    if (keytoSort === undefined) {
      return 0; // Return 0 if the sorting key is undefined
    }
  
    let aValue = a[keytoSort];
    let bValue = b[keytoSort];
    
    // Convert account_id to numbers for comparison
    if (keytoSort === 'account_id') {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }
  
    // If sorting by website_shared_uid, get the site name
    if (keytoSort === 'website_shared_uid') {
      aValue = siteNames.find((source: any) => source.key === a.website_shared_uid)?.site?.site_name || "";
      bValue = siteNames.find((source: any) => source.key === b.website_shared_uid)?.site?.site_name || "";
    }
  
    if (aValue === undefined) {
      return 1; // Move undefined values to the end
    }
    if (bValue === undefined) {
      return -1; // Move undefined values to the end
    }

    // Handle "N/A" values for sorting
    if (aValue === "") {
      return 1; // Move "N/A" values to the end
    }
    if (bValue === "") {
      return -1; // Move "N/A" values to the end
    }

    // Perform sorting based on the direction and type of values
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // String comparison (localeCompare for correct string sorting)
      return sort.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Numeric comparison
      return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (aValue instanceof Date && bValue instanceof Date) {
      // Date comparison
      return sort.direction === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    } else {
      // Fallback to string comparison if values are of different types
      return sort.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });
  };

  const sortedRows = getSortedData(uniqueFilteredData || []);
  const paginatedAccounts = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: number) => {
    setIsEditBtnEnabled(true)
    setPage(newPage);
    setSelectedRows([]);
  };

 const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
    
  const handleAccountStatusChange = (status: boolean) => {
    setParentAccountStatus(status);
  };
  
  const handleRowClick = (row:any, rowIndex:any) => {
    setSingleAccountShareUID(row?.shared_uid);
    const selectedRow = selected?.indexOf(rowIndex);
    setSelected(selectedRow === -1 ? [rowIndex] : []);
    setSelectedRowData(row);
    setSelectedRow(row);
    setRowId(row.id);
    setOpenModal(true);
  };

  const handleMoreInfoClick = (row:any) => {
    setOpenRow(openRow === row?.shared_uid ? null : row?.shared_uid);
    setSingleAccountShareUID(row?.shared_uid);
  }
 
 useEffect(() => {
   setPage(0);
  }, [selectedAccount,selectedAsset,selectedAdvertisementSource,selectedTerritory]);

  useEffect(() => {
    const newPageCount = Math.ceil(uniqueFilteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  }, [rowsPerPage, uniqueFilteredData])

  useEffect(() => {
    setRows(uniqueFilteredData);
    const newPageCount = Math.ceil(uniqueFilteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[uniqueFilteredData,rowsPerPage]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container item style={{ width: "100%" }} >
          <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
            <Table aria-label="simple table" size="small" stickyHeader>
              <TableHead sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableRow>
                {tableheaders.map((header,index) => (
                  <TableCell key={header.id}>
                    <TableSortLabel
                      active={sort.keytoSort === header.KEY}
                      direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                      onClick={() => handleHeaderClick(header)}
                      disabled={!header.sortable}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAccounts.length > 0 && paginatedAccounts
                  ?.map((row:any, rowIndex:any) => (
                    <React.Fragment key={rowIndex}>
                      <TableRow
                        // className="draggable-dialog-title"
                        hover={true}
                        style={{
                          backgroundColor: isCheckboxCheckedArray[rowIndex]
                            ? "#F7EEF9"
                            : "inherit",
                             boxShadow: expandedRow === rowIndex ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none"
                        }}
                      >
                        <TableCell sx={{ padding: "0" }}>
                          <Button>
                            <EditIcon  onClick={() => handleRowClick(row, rowIndex)} color={"secondary" } />
                          </Button>
                          {row?.account_name}
                        </TableCell>
                        <TableCell align="left">{Number(row?.account_id)}</TableCell>
                        <TableCell align="left">
                        <Alert
                          icon={ row?.active ? <CheckCircleOutlineOutlinedIcon  sx={{ color: "#1E4620", fontSize:"16px", marginLeft:"2px" }} fontSize="small"/> : <CancelOutlinedIcon  sx={{ color: "#5F2120", fontSize:"16px", marginLeft:"2px"}} fontSize="small"/>}
                          variant="outlined"
                          sx={{
                            color: row?.active ? "#1E4620" : "#5F2120",
                            background: row?.active ? "#EDF7ED" : "#FDEDED",
                            fontSize: "0.75rem",
                            padding: "0",
                            height: "26px",
                            width: "80px",
                            display: "flex",
                            alignItems: "center",
                            border: row?.active ? "1px solid #2E7D3280" : "1px solid #D32F2F80"
                          }}
                        >
                            {row?.active ? "Enabled" : "Disabled"}
                          </Alert>
                        </TableCell>
                        <TableCell align="left" >{row?.traffic_source}</TableCell>
                        <TableCell align="left" >{row?.industry}</TableCell>
                        <TableCell align="left" >{row?.territory}</TableCell>
                        <TableCell align="left" >{formatDate(row?.modified)}</TableCell>
                        <TableCell align="left" >
                          <div style={{display: "flex",alignItems: "center",boxShadow: "10px"}}>
                            <AccountCircleIcon sx={{ color: "#BDBDBD", marginRight: "4px" }} /> {row?.modified_by}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          color="secondary"
                          onClick={() => handleMoreInfoClick(row)}
                        >
                          {openRow === row.shared_uid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={headCells.length + 1}>
                          <Collapse in={openRow === row?.shared_uid} timeout="auto" unmountOnExit>
                            <AdditionalContentAccounts singleAccountShareUID={singleAccountShareUID} oauthTokenHeader={oauthTokenHeader} rows={rows}
                               getAdAccountDetails={getAdAccountDetails}
                               setTrafficSourceData={setTrafficSourceData}
                               advertisingAccounts={advertisingAccounts}
                               trackPromise={trackPromise}
                               onAccountStatusChange={handleAccountStatusChange}
                               fetchAdAccountData={fetchAdAccountData}
                               expandedRow={expandedRow}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                {!paginatedAccounts?.length && (
                  <TableRow>
                    <TableCell
                      className="text-center"
                      colSpan={headCells.length + 1}
                    >
                      <div className="p-2">There were no pages.</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TablePagination
                component="div"
                count={sortedRows?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[]}
                sx={{
                  ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                    display: "none",
                  },
                  ".MuiTablePagination-actions": {
                    display: "none",
                  },
                  ".MuiTablePagination-toolbar": {
                    paddingLeft: "16px",
                  },
                }}
              />
              <Pagination
                count={pageCount}
                page={page+1}
                /* defaultPage={selectedAccount !== null ? 1 : 0} */
                showFirstButton
                showLastButton
                className="pagination-button"
                color="secondary"
                size="small"
                sx={{
                  ".css-wjh20t-MuiPagination-ul": {
                    justifyContent: "center",
                  },
                }}
                onChange={(event, value) =>
                  handleChangePage(null, value - 1)
                }
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={sortedRows?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                  displayedRows: 'hidden-displayed-rows', // Apply the custom class
                }}
                sx={{
                  ".MuiTablePagination-actions": {
                    display: "none",
                  },
                  ".css-levciy-MuiTablePagination-displayedRows": {
                    display: "none",
                  },
                }}
              />
            </Stack>
          </TableContainer>
          <EditAccountDialog
            singleAccountShareUID={singleAccountShareUID} 
            accountUpdateStatus={accountUpdateStatus}
            setAccountUpdateStatus={setAccountUpdateStatus}
            postWebsiteToAcc={postWebsiteToAcc}
            siteNames={siteNames}
            setEditDialogOpen={setEditDialogOpen}
            setIsCheckboxCheckedArray={setIsCheckboxCheckedArray}
            setrowIndexNumber={setrowIndexNumber}
            setRowId={setRowId}
            getAdAccountDetailsForEdit={getAdAccountDetailsForEdit}
            setTrafficSourceData={setTrafficSourceData}
            fetchAdAccountData={fetchAdAccountData}
            accountUpdateError={accountUpdateError}
            setAccountUpdateError={setAccountUpdateError}
            setIsEditBtnEnabled={setIsEditBtnEnabled}
            setReloadAfterToggleEdit={setReloadAfterToggleEdit}
            setSingleAdAccountData={setSingleAdAccountData}
            singleAdAccountData={singleAdAccountData}
            hasGetPermissions={hasGetPermissions}
            rowId={singleAccountShareUID}
            oauthTokenHeader={oauthTokenHeader}
            open={openModal}
            onClose={handleCloseModal}
           />
        </Grid>
      </Grid>
    </Grid>
  );
}
