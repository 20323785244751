import React, { useEffect, useState, useMemo} from 'react'
import {Alert,Accordion,AccordionDetails,AccordionSummary,Autocomplete,Box,Button,Grid,Modal,Switch,TextField,Typography,Snackbar, FormControlLabel, CircularProgress} from '@mui/material';
import "./SequenceModal.css";
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch } from 'react-redux'
import { ETLSequencesEndPointLogic } from '../../../../../logic/Admin/ETLSequences/ETLSequencesEndPointLogic'
import EditProcessesChainTable from './EditProcessesChainTable'
import EditFrequencyTabs from './EditFrequencyTabs';
import cronstrue from 'cronstrue';
import { setIsLoading } from '../../../../../features/userSlice';

interface Props{
  etlSequence: any;
  processList: any;
  selectedRowData: any
  setSelectedRowData: any
  setSelected: any
  rowId: any
  oauthTokenHeader: any
  hasGetPermissions: any
  hasEditPermissions: any
  setEtlSequence: any
  open:any;
  onClose:any;
  setReload:any;
  selectedSequenceData:any;
  setSelectedSequenceData:any;
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#fff',
  padding: '20px 24px',
  borderRadius: '4px',
  boxShadow: '0px 0px 16px #50505085',
  zIndex: '999',
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
}

const modalHeading = {
  fontSize: '20px',
  fontWeight: '500',
  marginBottom: '16px',
}
const modalLabel = {
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '10px',
}

const EditSequence = ({ etlSequence, selectedSequenceData,setSelectedSequenceData, processList, oauthTokenHeader,rowId,selectedRowData,setSelectedRowData, setSelected, hasGetPermissions,hasEditPermissions,setEtlSequence, open, onClose,setReload }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [queueName, setQueueName] = useState('');
  const [processNameList, setProcessNameList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hourlyChainExpression, setHourlyChainExpression] = useState("");
  const [repeatEveryDayChainExpression, setRepeatEveryDayChainExpression] = useState("");
  const [repeatPerDayChainExpression, setRepeatPerDayChainExpression] = useState("");
  const [monthlyChainExpression, setMonthlyChainExpression] = useState("");
  const [weeklyChainExpression, setWeeklyChainExpression] = useState("");
  const [uniqueSubProcesses, setUniqueSubProcesses] = useState<any>([]);
  const [selectedProcess, setSelectedProcess] = useState<any>(null); // Add state for selected process
  const [humanReadableCron, setHumanReadableCron] = useState<string | null>(null);
  const [, setETLChainContent] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const frequencyText = useMemo(() => {
    if (humanReadableCron) {
      return (
        <Typography sx={modalLabel}>
          Your Selected Frequency: {humanReadableCron}
        </Typography>
      );
    }
    return null;
 }, [humanReadableCron]);

  const handleSelectedHourlyTimeChange = (time:any) => {
    setHourlyChainExpression(time);
  };

  const handleRepeatEveryDayTimeChange = (time:any) => {
    setRepeatEveryDayChainExpression(time);
  }

  const handleRepeatPerDayTimeChange = (time:any) => {
    setRepeatPerDayChainExpression(time);
  }

  const handleMonthlyTimeChange = (time:any) => {
    setMonthlyChainExpression(time);
  }

  const handleWeeklyTimeChange = (time:any) => {
    setWeeklyChainExpression(time);
  }

  useEffect(() => {
    const sub_processes = selectedSequenceData?.processes;
    const uniqueSubProcesses = sub_processes?.filter((process:any, index:any, self:any) => {
      return self.findIndex((p:any) => p.process__id === process.process__id) === index;
    });
    setUniqueSubProcesses(uniqueSubProcesses);
  }, [selectedSequenceData]);

  useEffect(() => {
    if(open) {
      setSwitchChecked(true);
    }
  },[open]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseEditModal = () => {
    setSelectedSequenceData("");
    onClose();
    setSelected([]);
  };

  const handleAddToSequence = () => {
    if (selectedProcess) {
      const processAlreadyAdded = uniqueSubProcesses.some(
      (process:any) => process.process__id === selectedProcess.process__id
      );
      if (processAlreadyAdded) {
      setSnackbarMessage('Process already added to the sequence! Please enter any other Process');
      setIsValid(false); // Set snackbar to show error
      setSnackbarOpen(true);
      } else {
      setUniqueSubProcesses([...uniqueSubProcesses, {
        ...selectedProcess,
        order: uniqueSubProcesses?.length + 1
      }]);
      setSelectedProcess(null);
      }
    }
  };

  const [switchChecked, setSwitchChecked] = useState(false);
  useEffect(() => {
    setSwitchChecked(selectedSequenceData?.active === true);
  },[selectedSequenceData]);

  const handleDeleteProcess = (processId: number) => {
    const updatedProcesses = uniqueSubProcesses.filter((process:any) => process.process__id !== processId);
    setUniqueSubProcesses(updatedProcesses);
  };

  const handleSwitchChange = async (rowID:any) => {
    setSwitchChecked(!switchChecked);
    setLoading(true);
    let data = '';
    try{
      await ETLSequencesEndPointLogic.etlSequenceToggle(oauthTokenHeader,setETLChainContent,data,rowID);
      // setSnackbarOpen(true);
      // setSnackbarMessage('Sequence status updated successfully. Please make sure to Deploy & Sync the Sequence Configurations.');
      if (hasGetPermissions) {
        setLoading(true);
        await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading, setEtlSequence, dispatch);
      } else {
        throw new Error("User doesn't have permissions to fetch ETL Sequence data");
      }
    }catch (error){
      setSnackbarOpen(true);
      setSnackbarMessage('Error while toggling sequence status');
      console.error("Error while toggling sequence status:", error);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence status updated successfully. Please make sure to Deploy & Sync the Sequence Configurations.');
    }
  };

  useEffect(() => {
    if (open) {
      setSwitchChecked(selectedSequenceData?.active === true);
      setQueueName(selectedSequenceData?.name || '');
    }
  }, [open, selectedSequenceData]);

  useEffect(() => {
    if (processList?.data) {
      const uniqueProcesses = new Set();
      const activeProcesses = processList?.data
        ?.filter((process:any) => process.active)
        ?.filter((process:any) => {
          if (!uniqueProcesses.has(process.id)) {
            uniqueProcesses.add(process.id);
            return true;
          }
          return false;
        })
        ?.map((process:any) => ({
          label: process.name,
          process__id: process.id,
          order: process.order,
          process__name: process.name,
          // process__command: process.process__command,
        })); // Ensure all necessary fields are included
      setProcessNameList(activeProcesses);
    }
  }, [processList]);

  const UpdateSequence = async() => {
    let expression;
    if (hourlyChainExpression) {
      expression = hourlyChainExpression;
    } else if (repeatEveryDayChainExpression) {
      expression = repeatEveryDayChainExpression;
    } else if (repeatPerDayChainExpression) {
      expression = repeatPerDayChainExpression;
    } else if (monthlyChainExpression) {
      expression = monthlyChainExpression;
    } else if (weeklyChainExpression) {
      expression = weeklyChainExpression;
    } else {
      expression = selectedSequenceData?.expression;
    }
    const data = {
      "name": queueName, // Sequence Name
      "processes": uniqueSubProcesses.map((process: any) => process.process__id),  // Sequence's Processes
      "expression": expression, // Sequence's Frequency
      "is_active": switchChecked, // Sequence's Status
    };
    try {
      if(hasEditPermissions) {
        setLoading(true);
        await ETLSequencesEndPointLogic.editETLSequence(selectedSequenceData?.id, oauthTokenHeader,data);
        setReload(true);
        setSnackbarOpen(true);
        setSnackbarMessage('ETL Sequence Updated Successfully! Please make sure to Deploy & Sync the Sequence Configurations');
        setQueueName("");
        setHourlyChainExpression("");
        setRepeatEveryDayChainExpression("");
        setRepeatPerDayChainExpression("");
        setMonthlyChainExpression("");
        setWeeklyChainExpression("");
        setSelected([]);
        setSelectedSequenceData("");
        onClose();
      } else {
        throw new Error("User doesn't have permissions to edit ETL sequences");
      }
      if (hasGetPermissions) {
        setLoading(true);
        await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading, setEtlSequence, dispatch);
      } else {
        throw new Error('You do not have permission to view the sequence');
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error updating sequence');
      console.error(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validate = async (editingSequenceId: any) => {
    const trimmedQueueName = queueName.trim();
    if (!trimmedQueueName) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name is required.');
      setIsValid(false);
      return false;
    }
  
    // Check for invalid characters (anything other than letters, numbers, or spaces)
    const validNameRegex = /^[a-zA-Z0-9 ]+$/;
    if (!validNameRegex.test(trimmedQueueName)) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name can only contain letters, numbers, and spaces.');
      setIsValid(false);
      return false;
    }
  
    // Check if the name is unique (excluding the currently editing sequence)
    const isUniqueName = etlSequence?.data?.some((sequence: any) => {
      if (sequence.id === editingSequenceId) {
        return true; // Skip the current sequence
      }
      return sequence.name === trimmedQueueName; // Check if the name is already in use
    });
  
    if (!isUniqueName) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name must be unique.');
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    return true;
  };

  const handleUpdateSequence = async() => {
    const editingSequenceId = selectedSequenceData?.id;
    const isFormValid = await validate(editingSequenceId);
    if(!isFormValid) return;
    try{
      setLoading(true);
      await UpdateSequence();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

   useEffect(() => {  
    if (selectedSequenceData?.expression) {
      try {
        const nextRunTime = cronstrue.toString(selectedSequenceData?.expression);
        setHumanReadableCron(nextRunTime);
      } catch (error) {
        console.error('Error parsing cron expression:', error);
      }
    }
  }, [selectedSequenceData?.expression]);

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: '9999999' }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={isValid ? "success" : "error"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  };

  return (
    <>
    {renderSnackbar()}
    <div>
    <Modal
        open={open}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Set the background color to transparent
          }
        }}
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Typography sx={modalHeading} id="modal-modal-title" variant="h6" component="h2">Edit Sequence</Typography>
          {/* Modal Body */}
          <Grid container spacing={3} sx={{ mb: 2 }} alignItems={"center"}>
            <Grid item xs={9}>
              <Typography variant="h6" sx={modalLabel} component="h2">
                Sequence Details
              </Typography>
              <TextField
                id='sequence-name'
                label="Sequence Name*"
                value={queueName}
                onChange={(e) => setQueueName(e.target.value)}
                helperText="Field is mandatory."
                variant="outlined"
                size="small"
                fullWidth
                color='secondary'
              />
            </Grid>
            <Grid item xs={3} justifyContent={'space-between'} sx={{ display: 'flex' }}>
              <FormControlLabel
                  control={<Switch color="success" checked={switchChecked} onChange={() => handleSwitchChange(selectedSequenceData?.id)} />}
                  label="Sequence Status"
              />
            </Grid>
          </Grid>
          {/* Modal Accordion */}
          <Typography sx={modalLabel} variant="h6" component="h2">Settings</Typography>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
             <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Frequency</Typography>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                  Setup Sequence reoccurrence dates and times
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditFrequencyTabs
                   onSelectedHourlyTimeChange={handleSelectedHourlyTimeChange}
                   onSelectedRepeatEveryDayTimeChange={handleRepeatEveryDayTimeChange}
                   onSelectedRepeatPerDayTimeChange={handleRepeatPerDayTimeChange}
                   onSelectedMonthlyTimeChange={handleMonthlyTimeChange}
                   onSelectedWeeklyTimeChange={handleWeeklyTimeChange}
                   frequencyText={frequencyText}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Sequence Processing Order
                </Typography>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                  Setup the sequence’s structure and order
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
               <Grid container spacing={1} sx={{ mb: 2 }} alignItems={'center'}>
                <Grid item xs={9}>
                 <Autocomplete
                  disablePortal
                  id="processName"
                  options={processNameList}
                  value={selectedProcess}
                  getOptionLabel={(option:any) => option.label}
                  onChange={(event, newValue) => setSelectedProcess(newValue)}
                  fullWidth
                  color="secondary"
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Process Name" color="secondary" />
                  )}
                 />
               </Grid>
               <Grid item xs={3} justifyContent={'space-between'} sx={{ display: 'flex' }}>
                <Button fullWidth onClick={handleAddToSequence} color="secondary" variant="contained" size="medium" sx={{ padding: '8px 10px', display: 'block' }}>
                  Add to Sequence
                </Button>
               </Grid>
              </Grid>
              <EditProcessesChainTable uniqueSubProcesses={uniqueSubProcesses} onDelete={handleDeleteProcess} setUniqueSubProcesses={setUniqueSubProcesses} />
             </AccordionDetails>
            </Accordion>
            {/* Modal footer */}
            <Grid container alignItems={'center'} justifyContent={'flex-end'} mt={3}>
             <Grid item xs={12} justifyContent={'flex-end'} sx={{ display: 'flex' }}>
              <Button variant="outlined" color="secondary" onClick={handleCloseEditModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleUpdateSequence} color="secondary" endIcon={<DoneIcon />} sx={{ marginLeft: 1 }}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Edit Sequence"}
              </Button>
          </Grid>
         </Grid>
        </Box>
      </Modal>
    </div>
    </>
  );
};

export default EditSequence;
