import { Stack } from '@mui/system'
import QuaterlyGoalFilter from './QuaterlyGoalFilter'
import { Button } from '@mui/material'
import { useState } from 'react'
import QuarterlyGoalsDrawer from '../QuarterlyGoalsDrawer/QuarterlyGoalsDrawer';
import CreateSetNewGoal from '../QuaterlyGoalModal/CreateSetNewGoal';

interface QuaterlyGoalFilterCardProps {
  oauthTokenHeader: any;
  hasEditPermissions: any;
  hasGetPermissions: any;
  tableData: any;
  selectedSharedUid: any;
  setLoading: any
  loading: any;
  quarterlyGoalsData: any;
  setQuarterlyGoalsData: any;
  selectedDevice:any;
  setSelectedDevice:any;
  goalsRequestedBy:any; 
  setGoalsRequestedBy:any;
  selectedgoalsDate:any;
  setSlectedgoalsDate:any
}

function QuaterlyGoalFilterCard({oauthTokenHeader, hasEditPermissions, hasGetPermissions, tableData, selectedSharedUid,quarterlyGoalsData, setQuarterlyGoalsData,selectedDevice, setSelectedDevice, goalsRequestedBy, setGoalsRequestedBy, selectedgoalsDate, setSlectedgoalsDate }: QuaterlyGoalFilterCardProps) {
  const [openModal, setOpenModal] = useState(false);
  const [opendrawer, setopendrawer] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handledraweropen = () => {
    setopendrawer(true)
  }
  const handledrawerclose = () => {
    setopendrawer(false)
  }

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <QuaterlyGoalFilter selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} tableData={tableData} goalsRequestedBy={goalsRequestedBy} setGoalsRequestedBy={setGoalsRequestedBy} selectedgoalsDate={selectedgoalsDate} setSlectedgoalsDate={setSlectedgoalsDate}/>
        <Stack flexDirection={'row'} gap={2} alignItems={'flex-end'}>
          <Button variant='outlined' color='secondary' onClick={handledraweropen}>Goals index</Button>
          <Button variant='contained' color='secondary' onClick={handleOpen}>Set new Goals</Button>
        </Stack>
      </Stack>
      <QuarterlyGoalsDrawer open={opendrawer} handleClose={handledrawerclose} />
      <CreateSetNewGoal tableData={tableData} oauthTokenHeader={oauthTokenHeader} hasEditPermissions={hasEditPermissions} hasGetPermissions={hasGetPermissions} selectedSharedUid={selectedSharedUid} openModal={openModal} onCloseModal={handleClose} setQuarterlyGoalsData={setQuarterlyGoalsData} quarterlyGoalsData={quarterlyGoalsData} />
    </>
  )
}

export default QuaterlyGoalFilterCard
