/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  AlertTitle,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PercentIcon from "@mui/icons-material/Percent";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { advertisingAccountsEndPointLogic } from "../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import SimpleBackdrop from "../../../BackDrop/BackDrop";
import { Stack } from "@mui/system";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import Grid from "@mui/material/Grid";
import "../../PaidTraffic.css";
import store from "../../../../store";
import { setIsLoading } from "../../../../features/userSlice";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

interface EditAccountDialogProps {
  open: any;
  singleAccountShareUID: any
  oauthTokenHeader: string;
  rowId: string;
  onClose: any;
  hasGetPermissions: any;
  singleAdAccountData: any;
  setSingleAdAccountData: any;
  setReloadAfterToggleEdit: any;
  setIsEditBtnEnabled: any;
  accountUpdateError: any;
  setAccountUpdateError: any;
  fetchAdAccountData: any;
  setTrafficSourceData: any;
  getAdAccountDetailsForEdit: Function;
  setRowId: any;
  setrowIndexNumber: any;
  setIsCheckboxCheckedArray: any;
  setEditDialogOpen: any;
  siteNames: any;
  postWebsiteToAcc: Function;
  setAccountUpdateStatus: any;
  accountUpdateStatus: any;
}


interface convActions {
  id: string;
  action: string;
}

const EditAccountDialog: FC<EditAccountDialogProps> = ({
  singleAccountShareUID,
  setAccountUpdateStatus,
  accountUpdateStatus,
  postWebsiteToAcc,
  siteNames,
  setEditDialogOpen,
  setIsCheckboxCheckedArray,
  setrowIndexNumber,
  setRowId,
  getAdAccountDetailsForEdit,
  setTrafficSourceData,
  fetchAdAccountData,
  setAccountUpdateError,
  accountUpdateError,
  setIsEditBtnEnabled,
  setReloadAfterToggleEdit,
  setSingleAdAccountData,
  open,
  onClose,
  oauthTokenHeader,
  rowId,
  hasGetPermissions,
  singleAdAccountData,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType /* , setAlertType */] = useState("info");
  const [autocompleteList, setAutocompleteList] = useState([]) as any; // Initialize with an empty array
  const [showAlert, setShowAlert] = useState(false);
  const [taxationAmount, setTaxationAmount] = useState(0.0);
  const [singleAdvertisingAccountContent, setSingleAdvertisingAccountContent] = useState<any[]>([]);
  const [geoSelected, setGeoSelected] = useState("");
  const [industrySelected, setIndustrySelected] = useState("");
  const [adAccountGeoDetails, setAdAccountGeoDetails] = useState([]);
  const [adAccountIndustryDetails, setAdAccountIndustryDetails] = useState([]);
  const [, setAdAccountTerritoryDetails] = useState([]);
  const [, setAdvertisingAccountToggleContent,] = useState([]);
  const [isAccountUpdated, setIsAccountUpdated] = useState(false);
  const [isInputMissing, setIsInputMissing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [linkedAssets, setLinkedAssets] = useState<any[]>([]);
  const [, setErrorAccToPageStatus] = useState(false);
  const [, setAssetType] = useState("");
  const [, setSelectedWebsite] = useState("");
  const [, setSelectedStartDate] = useState<Dayjs | null>(null);
  const [, setSelectedEndDate] = useState<Dayjs | null>(null);
  const [newDataFields, setNewDataFields] = useState([]) as any;
  const dispatch = useDispatch();
  // const [errorAlert , setErrorAlert] =useState<string | null>(null);

  const handleAddNewField = () => {
    setNewDataFields((prevFields:any) => [...prevFields, { assetType: "", website: "", startDate: null, endDate: null }]);
  };

  useEffect(() => {
    if (singleAdvertisingAccountContent && singleAdvertisingAccountContent[0]?.linked_assets) {
      setLinkedAssets(singleAdvertisingAccountContent[0].linked_assets);
    }
  }, [singleAdvertisingAccountContent]);

  const handleDisagree = (): void => {
    onClose();
  };

  const [switchChecked, setSwitchChecked] = useState(false);
  useEffect(() => {
    setSwitchChecked(singleAdvertisingAccountContent?.[0]?.active === true);
  }, [setSingleAdvertisingAccountContent]);

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isActive = event.target.checked;
    try {
      setStatusLoading(true);
      setSwitchChecked(isActive);
      await toggle();
      await fetchAdAccountData();
      setIsEditBtnEnabled(true);
    } catch (error) {
      console.error("Error toggling account status.");
    } finally {
      setStatusLoading(false);
    }
  };

  useEffect(() => {
    async function getGeo() {
      try {
        if (singleAccountShareUID !== null) {
          await advertisingAccountsEndPointLogic.getGeo(setAdAccountGeoDetails, oauthTokenHeader);
        } else {
          throw new Error("Error fetching geo details");
        }
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      }
    }
    async function getIndustries() {
      try {
        if (singleAccountShareUID !== null) {
          await advertisingAccountsEndPointLogic.getIndustry(setAdAccountIndustryDetails, oauthTokenHeader);
        } else {
          throw new Error("Error fetching industry details");
        }
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      }
    }
    async function getTerritories() {
      try {
        if (singleAccountShareUID !== null) {
          await advertisingAccountsEndPointLogic.getTerritory(setAdAccountTerritoryDetails, oauthTokenHeader);
        } else {
          throw new Error("Error fetching territory details");
        }
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      }
    }
    getGeo();
    getIndustries();
    getTerritories();
  }, [oauthTokenHeader, singleAccountShareUID]);

  const toggle = async () => {
    const toggleData = "";
    try {
      await advertisingAccountsEndPointLogic.advertisingAccountsToggle(oauthTokenHeader,setAdvertisingAccountToggleContent, toggleData, singleAccountShareUID);
      setSnackbarOpen(true);
      setSnackbarMessage(!switchChecked ? "Sync Data Enabled" : "Sync Data Disabled");
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  };

  const handleAddButtonClick = () => {
    setTaxationAmount((prevAmount) =>
      parseFloat((prevAmount + 0.1).toFixed(2))
    );
  };
  const handleMinusButtonClick = () => {
    setTaxationAmount((prevAmount) =>
      parseFloat((prevAmount - 0.1).toFixed(2))
    );
  };

  useEffect(() => {
    const fetchSingleAdvertisingAccountData = async() => {
      setLoading(true); // Set loading to true when fetching data
      try {
        if(open) {
          await advertisingAccountsEndPointLogic.getSingleAdvertisingAccount(oauthTokenHeader, setSingleAdvertisingAccountContent, setErrorAccToPageStatus, singleAccountShareUID,dispatch);
        }else {
          throw new Error("Error fetching advertising accounts");
        }
      } catch(error){
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when data is fetched
      }
    };
    fetchSingleAdvertisingAccountData();
  },[oauthTokenHeader,open,singleAccountShareUID,dispatch]);


  useEffect(() => {
    if(singleAdvertisingAccountContent) {
      setGeoSelected(singleAdvertisingAccountContent?.[0]?.geo);
      setIndustrySelected(singleAdvertisingAccountContent?.[0]?.industry);
      setTaxationAmount(singleAdvertisingAccountContent?.[0]?.account_taxation);
    }
  },[singleAdvertisingAccountContent])

  const updateAdAccount = async () => {
    store.dispatch(setIsLoading(true));
    const data = {
      data: {
        geo: `${geoSelected ? geoSelected : singleAdvertisingAccountContent?.[0]?.geo}`,
        industry: `${industrySelected ? industrySelected : singleAdvertisingAccountContent?.[0]?.industry}`,
        traffic_source: `${singleAdvertisingAccountContent?.[0]?.traffic_source}`,
        account_taxation: /* Number(taxationAmount.toFixed(2)) */ 1,
      },
    };
    try {
      await advertisingAccountsEndPointLogic.updateAdAccount(singleAccountShareUID,data,setAccountUpdateStatus,setAccountUpdateError,oauthTokenHeader,dispatch);
      setSnackbarOpen(true);
      setSnackbarMessage("Account Updated successfully");
    } catch (error) {
      console.error("Error updating ad account:", error);
    } finally {
      setRowId("");
      setSingleAdvertisingAccountContent([]);
      setIndustrySelected("");
      setGeoSelected("");
      setTaxationAmount(0.0);
      setAccountUpdateStatus("");
      setSnackbarOpen(true);
      setSnackbarMessage("Account Updated successfully");
      onClose();
      store.dispatch(setIsLoading(false));
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false); // Close the alert
  };


  useEffect(() => {
    // Check if singleAdvertisingAccountContent and its properties exist
    if (singleAdvertisingAccountContent && singleAdvertisingAccountContent[0]?.linked_assets) {
      const newList = singleAdvertisingAccountContent?.[0]?.linked_assets?.map((asset: any, index: number) => ({
        id: index + 1,
        assetType: "website",
        assetName: asset.websites__site_name,
        startDate: new Date(asset.start_date),
        endDate: new Date(asset.end_date),
        disabled: true, // Mark existing assets as disabled
      }));
      setAutocompleteList(newList); // Update the state with the new list
    } else {
      setAutocompleteList([]); // Reset to an empty array if data is not available
    }
  }, [singleAdvertisingAccountContent]); // Dependency array to run effect when singleAdvertisingAccountContent changes

  const handleAssetChange = (index: any, field: string, value: any) => {
    const newList = [...autocompleteList];
    newList[index][field] = value;
    setAutocompleteList(newList);
  };

  const handleAgree = async () => {
    const data = {
      data: {
        sites: newDataFields.map((field:any) => ({
          site_name: field.website, // Use the website from the newDataFields
          start_date: field.startDate ? field.startDate.format("YYYY-MM-DD") : null, // Format the start date to YYYY-MM-DD
          end_date: field.endDate ? field.endDate.format("YYYY-MM-DD") : null, // Format the end date to YYYY-MM-DD
        })),
      },
    };
   try {
    setLoading(true);
    await advertisingAccountsEndPointLogic.postWebToAcc(oauthTokenHeader,data,singleAdvertisingAccountContent?.[0]?.shared_uid);
    setAssetType("");
    setSelectedWebsite("");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    if (oauthTokenHeader) {
      await updateAdAccount();
      if(oauthTokenHeader) {
        setLoading(true);
        await fetchAdAccountData();
      } else {
        throw new Error("Error fetching advertising accounts");
      }
    } else {
      throw new Error("Input missing");
    }
   } catch (error) {
      console.error("Error updating ad account:", error);
   }finally{
    setLoading(false);
   }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAccountUpdateError(false);
    }, 3000); // 3000 ms = 3 seconds
    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [accountUpdateError, setAccountUpdateError]);

  useEffect(() => {
    if (isInputMissing) {
      setIsInputMissing(true);
      const timer = setTimeout(() => {
        setIsInputMissing(false);
      }, 3000); // 3000 ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [isInputMissing]);

  const dialogContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Reset scroll position on component mount or dialog open
    if (dialogContentRef?.current) {
      dialogContentRef.current.scrollTop = 0;
    }
    if (isInputMissing && dialogContentRef?.current) {
      dialogContentRef?.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isInputMissing]);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (value === "") {
      setTaxationAmount(0.0);
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setTaxationAmount(parsedValue);
      }
    }
  };

  useEffect(() => {
    if (accountUpdateStatus && accountUpdateStatus?.length > 0) {
      setIsAccountUpdated(true);
      setSingleAdvertisingAccountContent([]);
      onClose();
    }
  }, [toggle,onClose,setIsAccountUpdated,setIsEditBtnEnabled,setReloadAfterToggleEdit,setSingleAdvertisingAccountContent,accountUpdateStatus,setEditDialogOpen]);

  const geoDropDown = adAccountGeoDetails && adAccountGeoDetails?.map((a: any) => a.name);
  const filteredSiteNames = siteNames?.filter(
    (siteName: any) =>
      !autocompleteList?.some((auto: any) => auto?.assetName === siteName)
  );
  // const territoryDropDown = adAccountTerritoryDetails && adAccountTerritoryDetails?.map((a: any) => a.territory_name);
  const industryDropDown = adAccountIndustryDetails && adAccountIndustryDetails?.map((a: any) => a.industry_name);

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: '9999999' }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity="success"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  };

  return (
    <>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <>
        {renderSnackbar()}
        <Dialog
          ref={dialogContentRef}
          sx={{ overflowY: "auto" }}
          maxWidth="md"
          open={open}
          onClose={handleDisagree}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Edit Account"}
          </DialogTitle>
          <DialogContent sx={{ overflowY: "auto" }}>
            <DialogContentText>
              {isInputMissing && (
                <Alert
                  sx={{
                    height: "50%",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 4px",
                  }}
                  severity="warning"
                >
                  Input missing, Please try again!
                </Alert>
              )}
              {accountUpdateError && (
                <Alert
                  sx={{
                    height: "50%",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 4px",
                  }}
                  severity="error"
                >
                  Account Updation failed.
                </Alert>
              )}
              {showAlert && (
                <Alert
                  iconMapping={{
                    success: (
                      <CheckCircleOutlineIcon
                        sx={{
                          color: alertType === "error" ? "#5F2120" : "#1E4620",
                        }}
                      />
                    ),
                  }}
                  onClose={handleAlertClose}
                  sx={{
                    backgroundColor:
                      alertType === "error" ? "#FDEDED" : "#EDF7ED",
                    color: alertType === "error" ? "#5F2120" : "#1E4620",
                    border:
                      alertType === "error"
                        ? "1px solid #D32F2F80"
                        : "1px solid #2E7D3280",
                    height: "50%",
                    fontWeight: "500",
                  }}
                >
                  <AlertTitle>
                    {alertType === "success" ? "Account retrieved successfully!" : "Unable to retrieve account, please check the Account name and Source and try again."}
                  </AlertTitle>
                </Alert>
              )}
              <div style={{ display: "flex",paddingBottom: "16px",paddingTop: "16px",gap: "16px" }}>
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Source"
                  value={singleAdvertisingAccountContent?.[0]?.traffic_source ? (singleAdvertisingAccountContent?.[0]?.traffic_source
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    )
                  }
                  size="small"
                  sx={{ width: "50%", background: "#0000000F" }}
                />
                <TextField
                  disabled
                  id="outlined-disabled"
                  // label="ID at source"
                  value={singleAdvertisingAccountContent?.[0]?.account_id}
                  size="small"
                  sx={{ width: "50%", background: "#0000000F" }}
                />
              </div>
              <div style={{ display: "flex", paddingBottom: "16px", gap: "16px" }}>
                <TextField
                  disabled
                  id="outlined-disabled"
                  // label="Account Name"
                  value={singleAdvertisingAccountContent?.[0]?.account_name}
                  size="small"
                  sx={{ width: "49%", background: "#0000000F" }}
                />
                <TextField
                  id="outlined-disabled"
                  size="small"
                  sx={{ width: "318px" }}
                  color="secondary"
                  label="Taxation"
                  value={taxationAmount}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <AddIcon
                  onClick={handleAddButtonClick}
                  cursor="pointer"
                  color="secondary"
                  sx={{ marginLeft: "5px", marginTop: "6px" }}
                />
                <RemoveIcon
                  onClick={handleMinusButtonClick}
                  cursor="pointer"
                  color="secondary"
                  sx={{ marginLeft: "5px", marginTop: "6px" }}
                />
              </div>
              <div style={{ display: "flex", paddingBottom: "16px" }}>
                <div>
                  <Autocomplete
                    options={geoDropDown}
                    value={geoSelected ? geoSelected : null} // Set to null if no geo is selected
                    onChange={(event, newValue: any) => {
                      setGeoSelected(newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Geo *"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon sx={{ marginLeft: "12px" }} />
                            </InputAdornment>
                          ),
                        }}
                        color="secondary"
                      />
                    )}
                    size="small"
                    sx={{ width: "430px", paddingRight: "16px" }}
                  />
                </div>
                <div>
                  <Autocomplete
                    options={industryDropDown}
                    value={industrySelected ? industrySelected : null} // Set to null if no industry is selected
                    onChange={(event, newValue: any) => {
                      setIndustrySelected(newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Industry *"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        color="secondary"
                      />
                    )}
                    size="small"
                    sx={{ width: "418px" }}
                  />
                </div>
              </div>
              <FormHelperText sx={{ marginBlock: "16px" }}>
                All fields are mandatory.
              </FormHelperText>
              <FormHelperText sx={{ marginBlock: "16px", fontSize: "16px", color: "#000000" }}>
                Linked Asset{" "}
              </FormHelperText>
              <Grid container spacing={2}>
                {linkedAssets.map((asset, index) => (
                  <Grid item xs={12} key={asset.websites__shared_uid}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <Autocomplete
                            disabled
                            options={["website"]}
                            defaultValue={"website"}
                            onChange={(event, newValue) =>
                              handleAssetChange(
                                index,
                                "assetType",
                                newValue || ""
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Asset type"
                                placeholder="Select type from the list."
                                color="secondary"
                              />
                            )}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Autocomplete
                            disabled
                            options={filteredSiteNames?.map((site:any)=> site?.site?.site_name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Website"
                              size="small"
                              color="secondary"
                            />
                          )}
                          value={asset.websites__site_name}
                          onChange={(event, newValue) => {
                            // Handle the change event for the website name
                            // Update the linkedAssets state if necessary
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangePicker
                            disabled
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                color: 'secondary',
                              },
                            }}
                            value={[dayjs(asset.start_date), dayjs(asset.end_date)]}
                            onChange={(newValue) => {
                              const updatedAssets = [...linkedAssets];
                              updatedAssets[index].start_date = newValue[0]?.format("YYYY-MM-DD");
                              updatedAssets[index].end_date = newValue[1]?.format("YYYY-MM-DD");
                              setLinkedAssets(updatedAssets);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* Add New Data Start */}
              {newDataFields.map((field:any, index:any) => (
                <Grid container spacing={2} sx={{ marginTop: "16px" }} key={index}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      options={["website"]}
                      value={field.assetType}
                      onChange={(event, newValue) => {
                        const updatedFields = [...newDataFields];
                        updatedFields[index].assetType = newValue || "";
                        setNewDataFields(updatedFields);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Asset type"
                          placeholder="Select type from the list."
                          color="secondary"
                        />
                      )}
                      size="small"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={filteredSiteNames?.map((site: any) => site?.site?.site_name)}
                      value={field.website}
                      onChange={(event, newValue) => {
                        const updatedFields = [...newDataFields];
                        updatedFields[index].website = newValue || "";
                        setNewDataFields(updatedFields);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Website"
                          size="small"
                          color="secondary"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        slots={{ field: SingleInputDateRangeField }}
                        onChange={(newValue: any) => {
                          const updatedFields = [...newDataFields];
                          updatedFields[index].startDate = newValue[0];
                          updatedFields[index].endDate = newValue[1];
                          setNewDataFields(updatedFields);
                        }}
                        name="allowedRange"
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            color: 'secondary',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              ))}
              <Stack padding={1} marginY={2} direction="column" alignItems={'start'} spacing={1}>
                <Typography fontSize={12} sx={{ marginBlock: "8px 6px" }}>More than one linked asset?</Typography>
                <Button variant="outlined" onClick={handleAddNewField} color="secondary">Add New</Button>
              </Stack>
              {/* Add New Data End */}
              <FormControlLabel control={ 
                statusLoading ? (<CircularProgress size={24} color="secondary" />) 
                : (<Switch color="success" checked={singleAdvertisingAccountContent?.[0]?.active === true} 
                  sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#2E7D32',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#2E7D32',
                  }
                }} onChange={handleSwitchChange} />)}
                sx={{ marginTop: "20px" }}
                label="Account Status"
              />
              <Snackbar
                sx={{ top: "100px !important" }}
                open={snackbarOpen}
                autoHideDuration={1000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  onClose={() => setSnackbarOpen(false)}
                  severity={switchChecked ? "success" : "warning"}
                  sx={{ width: "100%", zIndex: "9999999", top: "100px" }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                sx={{ top: "100px !important" }}
                open={isAccountUpdated}
                autoHideDuration={6000}
                onClose={() => setIsAccountUpdated(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  onClose={() => setIsAccountUpdated(false)}
                  severity="success"
                  sx={{ width: "100%", zIndex: "9999999", top: "100px" }}
                >
                  {"Account Updated succesfully"}
                </Alert>
              </Snackbar>
              <Stack
                padding={1}
                marginY={2}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Typography style={{ fontSize: "1rem" }}>
                  Conversion Actions
                </Typography>
                <Button
                  onClick={() => getAdAccountDetailsForEdit()}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    border: "none", // Removes the border
                    "&:hover": {
                      border: "none", // Ensures no border on hover as well
                    },
                  }}
                >
                  <ReplayOutlinedIcon color="secondary" />
                </Button>
              </Stack>
              {singleAdvertisingAccountContent[0]?.conv_actions?.map(
                (item: convActions) => (
                  <div
                    style={{
                      display: "flex",
                      paddingBottom: "16px",
                      gap: "16px",
                    }}
                  >
                    <TextField
                      disabled
                      id="outlined-disabled"
                      label="Action name"
                      defaultValue={item?.action}
                      size="small"
                      sx={{ width: "500px", background: "#0000000F" }}
                    />
                    <TextField
                      disabled
                      id="outlined-disabled"
                      label="Action ID at source"
                      defaultValue={item?.id}
                      size="small"
                      sx={{ width: "325px", background: "#0000000F" }}
                    />
                  </div>
                )
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "0px 24px 20px 24px" }}>
            <Button autoFocus onClick={handleDisagree}
              sx={{
                color: "#9C27B0",
                "&:hover": { background: "#ffffff", color: "#9C27B0" },
              }}
            >
              CANCEL
            </Button>
            <Button onClick={handleAgree} autoFocus
              sx={{
                background: "#9C27B0",
                color: "#ffffff",
                "&:hover": {
                  background: "#9C27B0",
                  color: "#ffffff",
                },
              }}
            >
              save changes
              <SaveOutlinedIcon sx={{ marginLeft: "8px" }} />
            </Button>
          </DialogActions>
        </Dialog>
        </>
      )}
    </>
  );
};

export default EditAccountDialog;
