import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import "./global.css";
import store from "./store"; // This calls the configure store function (its the first time we import store.ts)
import { initClientSide } from "./AppInitLogic";
import ReactDOM from "react-dom/client";
import Root from "./Root";
import HomeScreen from "./pages/home/Screens/HomeScreen";
import { isLoggedInLoader } from "./IsLoggedInLoader";
import PageManagementScreen from "./pages/AssetManagement/PageManagement/PageManagementScreen";
import App from "./App";
import LogoutScreen from "./pages/Logout/LogoutScreen";
import ResetPasswordScreen from "./pages/ResetPassword/screen/ResetPasswordScreen";
import ConfirmPasswordScreen from "./pages/ResetPassword/ConfirmPasswordScreen";
import LoginScreen from "./pages/Login/screens/LoginScreen";
import PageBuildingScreen from "./pages/AssetManagement/PageManagement/PageBuilding/PageBuildingScreen";
import Page404 from "./components/Page404/Page404";
import AdvertisingAccountsScreen from "./pages/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccountsScreen";
import ComingSoonScreen from "./pages/ComingSoon/ComingSoonScreen";
import ActivityNotesScreen from "./pages/AssetManagement/ActivityNotes/ActivityNotesScreen";
import ETLSequenceScreen from "./pages/Admin/Processes/ETLSequences/ETLSequenceScreen";
import ETLProcessLogScreen from "./pages/Admin/Processes/ETLProcessLog/ETLProcessLogScreen";
import ETLLogLogResponseScreen from "./pages/Admin/Processes/ETLProcessLog/ETLLogLogResponseScreen";
import ETLProcessListScreen from "./pages/Admin/Processes/ETLProcessList/ETLProcessListScreen";
import ManualExpenseScreen from "./pages/ManualExpenseManagement/ManualExpense/ManualExpenseScreen";
import * as Sentry from "@sentry/react";
import PageSpeedPerformanceScreen from "./pages/AssetManagement/Health/PageSpeedPerformance/PageSpeedPerformanceScreen";
import MonitoringScreen from "./pages/AssetManagement/Health/Monitoring/MonitoringScreen";
import WebsitePageGroupScreen from "./pages/AssetManagement/WebsitePageGroup/WebsitePageGroupScreen";
import CompetitorsPageSpeedScreen from "./pages/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedScreen";
import TemplatesScreen from "./pages/AssetManagement/Design/Templates/TemplatesScreen";
import CreateTemplateScreen from "./pages/AssetManagement/Design/Templates/CreateTemplateScreen";
import EditTemplateScreen from "./pages/AssetManagement/Design/Templates/EditTemplateScreen";
import EngagementIncentives from "./components/Paid Traffic/EngagementIncentives/EngagementIncentives";
import ThemeScreen from "./pages/AssetManagement/Design/Theme/ThemeScreen";
import CreateNewThemeScreen from "./pages/AssetManagement/Design/Theme/CreateNewThemeScreen";
import EditThemeScreen from './pages/AssetManagement/Design/Theme/EditThemeScreen';
import MetaEntitiesScreen from "./pages/Admin/MetaEntities/MetaEntitiesScreen";
import WebsiteActionsScreen from "./pages/AssetManagement/WebsiteActions/WebsiteActionsScreen";
import WebsiteSettingScreen from "./pages/AssetManagement/WebsiteActions/WebsiteSettingScreen";
import WebsiteEditScreen from "./pages/AssetManagement/WebsiteActions/WebsiteEditScreen";

let react_env= process.env.REACT_APP_ENV
console.log('process.env', process.env)
if ( react_env === 'staging' ||  react_env === 'production') {
  Sentry.init({
    dsn: "https://0be5db9287a78d10dda16de552c7027a@o4505628677570560.ingest.us.sentry.io/4506937359990785",
    environment: react_env, // Set the environment
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions 
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/aladin-ui-stage\.etloptival\.com/ , /^https:\/\/aladin-ui\.etloptival\.com/ ],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // Sample rate at 10%. Adjust as needed.
    replaysOnErrorSampleRate: 1.0, // Sample rate at 100% when errors occur.
  });
} 

const muiTheme = createTheme();
renderClientSide();
async function renderClientSide() {
  try {
    let response = null;
    const userToken = await initClientSide((res:any) => {response = res;}).catch((err) => {
      return null;
    });
    const router = createBrowserRouter([
      {
        path: "/", element: <Root />, children: [
          {
            path: "", element: <App response={response} />, loader: () => isLoggedInLoader(userToken), children: [
              {
                path: "", element: <HomeScreen />
              },
              { path: "/comingSoon", element: <ComingSoonScreen />},
              { path: "assets/page_management/:siteName?", element: <PageManagementScreen userToken={userToken} /> },
              { path: "assets/page_management/:siteName/:pageName/:action", element: <PageBuildingScreen userToken={userToken} /> },
              { path: "paidTraffic/:siteName?", element: <AdvertisingAccountsScreen userToken={userToken} response={response} /> },
              { path: "finance/:siteName?/manual_expenses", element: <ManualExpenseScreen userToken={userToken} response={response} /> },
              { path: "admin/:siteName?/etl-sequences", element: <ETLSequenceScreen userToken={userToken} /> },
              { path: "assets/:siteName?/activity_notes" , element: <ActivityNotesScreen userToken={userToken} response={response} />},
              { path: "admin/:siteName?/etl-process-log", element: <ETLProcessLogScreen userToken={userToken} response={response}/>},
              { path: "admin/:siteName?/etl-process-list", element: <ETLProcessListScreen userToken={userToken} />},
              { path: "admin/:siteName?/etl-process-log/etl-process-log-Response", element: <ETLLogLogResponseScreen userToken={userToken} />},
              { path: "assets/websites/page_groups", element: <WebsitePageGroupScreen /> },
              { path: "market_positioning/scout/competitors_page_speed", element: <CompetitorsPageSpeedScreen userToken={userToken} response={response}/>},
              { path: "assets/health/page_speed_performance/:siteName?", element: <PageSpeedPerformanceScreen userToken={userToken} response={response}/>},
              { path: "assets/health/monitoring", element: <MonitoringScreen userToken={userToken} response={response}/>},
              { path: "assets/design/templates", element: <TemplatesScreen userToken={userToken} response={response} />},
              { path: "assets/design/templates/create_new_template", element: <CreateTemplateScreen userToken={userToken} response={response} />},
              { path: "assets/design/templates/edit_new_template", element: <EditTemplateScreen />},
              { path: "paidTraffic/engagement_incentives", element: <EngagementIncentives userToken={userToken} response={response}/> },
              { path: "assets/design/templates/create_new_template", element: <CreateTemplateScreen/>},
              { path: "assets/design/theme", element: <ThemeScreen userToken={userToken} response={response} />},
              { path: "assets/design/theme/create_new_theme", element: <CreateNewThemeScreen userToken={userToken} response={response} />},
              { path: "assets/design/theme/edit_new_theme", element: <EditThemeScreen />},
              { path: "assets/design/theme/create_new_theme", element: <CreateNewThemeScreen />},
              { path: "admin/view_territories/", element: <MetaEntitiesScreen />},
              { path: "assets/websites/website_actions", element: <WebsiteActionsScreen />},
              { path: "assets/websites/website_actions/edit_website", element: <WebsiteEditScreen />},
              { path: "assets/websites/website_actions/website_setting", element: <WebsiteSettingScreen />}
            ]
          },
          { path: "/login", element: <LoginScreen /> },
          { path: '/logout', element: <LogoutScreen /> },
          { path: "/reset-password", element: <ResetPasswordScreen /> },
          { path: `/password/reset/confirm/:uid/:token`, element: <ConfirmPasswordScreen /> },
          { path: `/404`, element: <Page404 /> },
        ]
      },
      // { path: "/404", element: // TODO:: page 404 element }
    ]);
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    );
  } catch (e) {
    // TODO - render page 404
    Sentry.captureException(e);
    console.error(e);
  }
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
