import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';

export interface EmptyPlaceholderProps {
  pageDictionary: any;
}

export default function EmptyPlaceholder({ pageDictionary }: EmptyPlaceholderProps) {
  const [, setTheme] = useState(null);

  useEffect(() => {
    const data = JSON.parse(pageDictionary);  // Parse the page dictionary string into a JavaScript object
    const themeValue = data?.settings?.theme;  // Extract the theme value from the parsed data
    setTheme(themeValue); // Set the extracted theme value into the state variable 'theme'
  }, [pageDictionary]);

  return (
    <>
    <div className="card-title" style={{height:"200px", background:"inherit", display:"flex", alignItems:"center", justifyContent:"center", textTransform:"capitalize"}}>
      Please add some content here <AddIcon /> <AddIcon />
    </div>
    </>
  );
}