import React, { useState } from "react";
import ResetPasswordSection from "../../../components/ResetPasswordSection/ResetPasswordSection";
import { resetPassword } from "../logic/resetPasswordHelper";

const ResetPasswordScreen: React.FC = (props: any) => {
  const [email, setEmail] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleResetPassword = async () => {
    try {
      await resetPassword(email);
      setShowSuccessPopup(true);
      setEmail("");
    } catch (error: any) {
      setErrorPopupMessage(error.message);
      setShowErrorPopup(true);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };
  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  return (
    <ResetPasswordSection
      email={email}
      onEmailChange={setEmail}
      onResetPasswordClick={handleResetPassword}
      showSuccessPopup={showSuccessPopup}
      showErrorPopup={showErrorPopup}
      onCloseSuccessPopup={handleCloseSuccessPopup}
      onCloseErrorPopup={handleCloseErrorPopup}
      errorPopupMessage={errorPopupMessage}
    />
  );
};

export default ResetPasswordScreen;
