import { Grid, Typography } from "@mui/material";
import React from "react";
import PerformanceDashboardBottomRatedPages from "./PerformanceDashboardBottomRatedPages";
import PerformanceDashboardTopRatedPages from "./PerformanceDashboardTopRatedPages";
import '../PerformanceDashboard.css'

const tableHeadings = {
  "page_name": "Page Name",
  "overall_score": "Overall Score",
  "real_users_score": "Real Users Score",
  "real_time_score": "Real-Time Score",
  "ads_mobile_core": "Ads Mobile Score",
}
const rows = [
  {
    page_name: "example page name 1",
    overall_score: "98%",
    real_users_score: "99%",
    real_time_score: "96%",
    ads_mobile_core: "96%",
  },
  {
    page_name: "example page name 2",
    overall_score: "98%",
    real_users_score: "99%",
    real_time_score: "96%",
    ads_mobile_core: "96%",
  },
  {
    page_name: "example page name 3",
    overall_score: "98%",
    real_users_score: "99%",
    real_time_score: "96%",
    ads_mobile_core: "96%",
  },
  {
    page_name: "example page name 4",
    overall_score: "98%",
    real_users_score: "99%",
    real_time_score: "96%",
    ads_mobile_core: "96%",
  },
  {
    page_name: "example page name 5",
    overall_score: "98%",
    real_users_score: "99%",
    real_time_score: "96%",
    ads_mobile_core: "96%",
  },
];


const PerformanceDashboardRatedTable = () => {
  return (
    <>
      <Grid container spacing={3} mb={2} className="ratedTableWrap">
        <Grid item xs={6}>
          <Typography sx={{fontSize:'18px', color:'#000000'}}>Top Rated Pages</Typography>
          <PerformanceDashboardTopRatedPages tableHeadings={tableHeadings} rows={rows} />
        </Grid>
        <Grid item xs={6}>
        <Typography sx={{fontSize:'18px', color:'#000000'}}>Bottom Rated Pages</Typography>
          <PerformanceDashboardBottomRatedPages tableHeadings={tableHeadings} rows={rows} />
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceDashboardRatedTable;
