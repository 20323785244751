import { fetchWrapper } from './api/fetch-wrapper';
import { UserDetailsResponse } from '../data/user/UserDetailsResponse';
import hostname from './api/config.host';

const apiUrl: string = `${hostname}/aladdin_api/v1`;

// TODO: add type
export const loginService = {
    signIn: async (params: object) => fetchWrapper.post(`${apiUrl}/token/login`, params),
    signOut: async (id: string) => fetchWrapper.get(`${apiUrl}/signout/${id}`),
};

export async function getUserDetails(oauthTokenHeader: string): Promise<UserDetailsResponse> {
    return fetchWrapper.get(`${apiUrl}/users/me/`, oauthTokenHeader);
}
