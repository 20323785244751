import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import assetManagementReducer from './features/assetManagementSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    assetManagement: assetManagementReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;