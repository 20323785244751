import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import Link from "@mui/material/Link";
import "../../MetaEntities.css";
import GeoModal from "../GeoModal/GeoModal";

interface Props {
  tableHeadings: { [key: string]: string };
  rows: any[];
}

const GeoTable = ({ tableHeadings, rows }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const headCells = Object.entries(tableHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const [openEditModal, setopenModal] = useState(false);
  const handleopenEditModal = () => {
    setopenModal(true);
  };
  const handlecloseEditModal = () => {
    setopenModal(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer
        className="PageScantablewrap"
        component={Paper}
        sx={{ maxHeight: 800 }}
      >
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(([key, value], index) => (
                <TableCell key={index} align="left">
                  <TableSortLabel>
                    {value && typeof value === "string" ? value : ""}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <TableRow
                    sx={{
                      "& > *": {
                        borderBottom:
                          "1px solid rgba(224, 224, 224, 1) !important",
                      },
                    }}
                  >
                    {headCells.map(([key], cellIndex) => (
                      <TableCell key={cellIndex} align="left">
                        {key === "geo_name" ? (
                          <>
                            <Link href="#" color="secondary" sx={{mr:1}}>
                              <img
                                alt="Edit"
                                src="/icon4.svg"
                                onClick={handleopenEditModal}
                              />
                            </Link>
                            {row[key] ? row[key] : "N/A"}
                          </>
                        ) : key === "associated_territory" ? (
                          <> {row[key] ? row[key] : "N/A"}</>
                        ) : key === "time_zone" ? (
                          <> {row[key] ? row[key] : "N/A"}</>
                        ) : key === "created_on" ? (
                          <> {row[key] ? row[key] : "N/A"}</>
                        ) : key === "created_by" ? (
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Avatar
                              alt={row[key] ? row[key] : "N/A"}
                              src="#"
                              sx={{ width: 24, height: 24 }}
                            />
                            {row[key] ? row[key] : "N/A"}
                          </Stack>
                        ) : key === "last_edited" ? (
                          <>{row[key] ? row[key] : "N/A"}</>
                        ) : key === "edited_by" ? (
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Avatar
                              alt={row[key] ? row[key] : "N/A"}
                              src="#"
                              sx={{ width: 24, height: 24 }}
                            />
                            {row[key] ? row[key] : "N/A"}
                          </Stack>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel":
                {
                  display: "none",
                },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={rows.length}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: "hidden-displayed-rows", // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
            }}
          />
        </Stack>
        <GeoModal
          open={openEditModal}
          handleClose={handlecloseEditModal}
        />
      </TableContainer>
    </>
  );
};

export default GeoTable;
