import Cookies from "js-cookie";
import { softLogin } from "./logic/login/login";

export async function initClientSide(setResponse:any): Promise<string | null> {
    const refreshToken = Cookies.get('RT');
    if (refreshToken) {
        return softLogin(refreshToken, setResponse);
    } else {
        return null;
    }
};
