import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import CompetitorsPageScanRequestsFilter from './CompetitorsPageScanRequestsFilter'
import CompetitorsPageScanRequestsModal from "../CompetitorsPageScanRequestsModal/CompetitorsPageScanRequestsModal";
import { PsiCompetitors } from "../../../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedEndPointLogic";
import { trackPromise } from "react-promise-tracker";

interface CompetitorsPageScanRequestsFilterProps {
  userToken: string;
  psiRequestRows: any;
  uniqueCompitetorData: any;
  setSelectedCompetitorName: any;
  selectedCompetitorName: any;
  selectedStatus: any;
  setSelectedStatus: any;
  selectedUser: any;
  setSelectedUsers: any;
  selectedDate: any;
  setSelectedDate: any;
  setSelectedCompetitorURL: any;
  selectedCompetitorURL: any;
  selectedSiteUid: any;
  setRefresh: any;
}

const CompetitorsPageScanRequestsFilterCard = ({ userToken, uniqueCompitetorData, setSelectedCompetitorName, selectedCompetitorName, psiRequestRows,
  selectedStatus, setSelectedStatus, setSelectedUsers, selectedUser, selectedDate, setSelectedDate, setSelectedCompetitorURL, selectedCompetitorURL,
  selectedSiteUid,setRefresh
}: CompetitorsPageScanRequestsFilterProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);

  const handleOpenModal = () => {
    setOpenModal(true);
    PsiCompetitors.getSerpCompetitors(userToken, setData, trackPromise);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={3}
        sx={{ paddingBlock: "16px" }}
      >
        <Autocomplete
          sx={{ width: "400px" }}
          options={uniqueCompitetorData}
          value={selectedCompetitorURL ? uniqueCompitetorData.find((option: any) => option.request_url === selectedCompetitorURL[0]) : null}
          getOptionLabel={(option) => (option ? option.request_url : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by URL"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          isOptionEqualToValue={(option: any, value: any) =>
            option.request_url === value.request_url
          }
          filterOptions={(options, state) =>
            options.filter((option) =>
              option.request_url.toLowerCase().includes(state.inputValue.toLowerCase())
            )
          } // Custom filtering logic based on request_url only
          size="small"
          onChange={(event, newValue) => {
            if (newValue) {
              // Set the selected URL when a value is chosen
              const url = newValue?.request_url || ""; 
              setSelectedCompetitorURL([url]); 
            } else {
              // Set data to null when cleared
              setSelectedCompetitorURL(null);
            } // Store selected value in stateurl); // Store selected value in state
          }}
        />

        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <CompetitorsPageScanRequestsFilter psiRequestRows={psiRequestRows} selectedCompetitorName={selectedCompetitorName}
            setSelectedCompetitorName={setSelectedCompetitorName} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}
            setSelectedUsers={setSelectedUsers} selectedUser={selectedUser} selectedDate={selectedDate} setSelectedDate={setSelectedDate}
            setSelectedCompetitorURL={setSelectedCompetitorURL}  />
          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenModal}
          >
            Create new Request <AddIcon />

          </Button>
        </Stack>
      </Stack>
      <CompetitorsPageScanRequestsModal open={openModal} handleClose={handleCloseModal} data={data} userToken={userToken} selectedSiteUid={selectedSiteUid}
      setRefresh={setRefresh} />
    </>
  )

}

export default CompetitorsPageScanRequestsFilterCard