import Box from "@mui/material/Box";
import FilterComponent from "../../../../../ReusableFIlterComponent/FilterComponent";
import { useState } from "react";

interface Props {
  selectedDevice: any;
  setSelectedDevice:any;
  tableData:any;
  goalsRequestedBy:any; 
  setGoalsRequestedBy:any;
  selectedgoalsDate:any;
  setSlectedgoalsDate:any
}

export default function QuaterlyGoalFilter({selectedDevice,setSelectedDevice,tableData,goalsRequestedBy,setGoalsRequestedBy,selectedgoalsDate,setSlectedgoalsDate}:Props) {
  const [searchDevice, setSearchDevice] = useState('');
  const [searchGoalsRequestedBy, setSearchGoalsRequestedBy] = useState('');

  const filterConfig = [
    {
      key: 'date',
      label: 'Select Date Range',
      filterName: 'created',
      data: tableData,
      selectedOption: selectedgoalsDate ,
      onSelectionChange: (updatedSelection: any) => setSlectedgoalsDate(updatedSelection),
      count: 0,
      colorSelected: selectedgoalsDate !== null && selectedgoalsDate.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      isDateRangeFilter: true
    },
    {
      key: 'device',
      label: 'Select a device',
      searchTerm: searchDevice,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchDevice(e.target.value),
      selectedOption: selectedDevice,
      filterName: 'device',
      data: tableData,
      onSelectionChange: (updatedSelection: any) => setSelectedDevice(updatedSelection),
      count: selectedDevice && selectedDevice?.length,
      colorSelected: selectedDevice !== null && selectedDevice?.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: false,
    },
    {
      key: 'request by',
      label: 'Select a user',
      searchTerm: searchGoalsRequestedBy,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchGoalsRequestedBy(e.target.value),
      selectedOption: goalsRequestedBy,
      filterName: 'created_by',
      data: tableData,
      onSelectionChange: (updatedSelection: any) => setGoalsRequestedBy(updatedSelection),
      count: goalsRequestedBy && goalsRequestedBy.length,
      colorSelected: goalsRequestedBy !== null && goalsRequestedBy?.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    }
  ];

  const clearFilters = () => {
    setSelectedDevice(null);
    setGoalsRequestedBy(null);
    setSlectedgoalsDate([]);
  }

  return (
    <Box sx={{paddingTop:"10px"}}>
    <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}
