import { Outlet } from "react-router-dom";
import styles from "./components/Home/Home.module.css";
import Drawer from "./components/Drawer/Drawer";
import BreadCrumbsScreen from "./pages/BreadCrumbs/BreadCrumbsScreen";
import ErrorMessageScreen from "./pages/ErrorMessage/ErrorMessageScreen";
import { selectDisplayErrorSnackBar } from "./features/userSlice";
import { useSelector } from "react-redux";
import { SharedStateProvider } from "./components/SharedStateProvider";
import { LicenseInfo } from '@mui/x-license';



interface Props {
  response: any;
}



const App = (response:Props) => {
  const messageForSnackBar = useSelector(selectDisplayErrorSnackBar);
  // Set the license key for MUI components
  // Ensure the license key is stored in the environment variable 'REACT_APP_MUI_LICENSE_KEY'
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);
  
  return (
    <div className="app-container">
      <div className={styles.pageBuilding}>
        <div className={styles.pageLayout}>
          <Drawer response={response} />
          <div style={{ marginTop: "-4px", width: "100%", paddingLeft:"6px", overflow:"hidden" }}>
            <div style={{display:"block" , width:"100%"}}>
            <ErrorMessageScreen message={messageForSnackBar?.message} type={messageForSnackBar?.type} />
            </div>
            <div className="bread-crumbs" style={{ marginBottom: "10px" }}>
              <BreadCrumbsScreen />
            </div>
            <SharedStateProvider>
            <Outlet />
            </SharedStateProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
