import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography, Alert, IconButton, Collapse, Paper } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';

export interface WidgetListPopUpProps {
    allWidgetsByType: any;
    onDragEndHandel: any;
    statusClicked: any;
    handelCLickEditWidget: any;
    deviceEdited: string | null;
    setOpen: any;
    setisPublishOpen: any;
    setIsElementSaved: any;
    selectedWidgettype: any;
    shareUidAvailable: any;
}

export default function WidgetListPopUp({
    allWidgetsByType,
    onDragEndHandel,
    statusClicked,
    deviceEdited,
    handelCLickEditWidget,
    setOpen,
    setisPublishOpen,
    setIsElementSaved,
    selectedWidgettype,
    shareUidAvailable, // getting the SharedUid of the widget when editing
}: WidgetListPopUpProps) {
    const [widgetSelected, setWidgetSelected] = useState<(string | null)[]>([null]);
    const [checked, setChecked] = useState([]) as any;
    const [errorSelect, setErrorSelect] = useState<string>();
    const [errorCheckbox, setErrorCheckbox] = useState<string>();
    const [openSelectAlert, setOpenSelectAlert] = useState<boolean>(true);
    const [openCheckboxAlert, setOpenCheckboxAlert] = useState<boolean>(true);
    const [autocompleteCount, setAutocompleteCount] = useState<number>(shareUidAvailable && shareUidAvailable.length === 5 ? 5 : shareUidAvailable.length === 4 ? 4 :
        shareUidAvailable.length === 3 ? 3 : shareUidAvailable.length === 2 ? 2 : 1);
    let lblWidgetDropDown = `Select a ${selectedWidgettype}`;

    const onChecked = (value: boolean, device: string) => {
        const newCheck = { ...checked };
        if (value) {
            newCheck[device] = true;
        } else {
            delete newCheck[device];
        }
        setChecked(newCheck);
    }

    useEffect(() => {
        if (errorSelect) {
            setOpenSelectAlert(true);
            const timer = setTimeout(() => setOpenSelectAlert(false), 3000); // 3 seconds timer
            return () => clearTimeout(timer);
        }
    }, [errorSelect]);

    useEffect(() => {
        if (errorCheckbox) {
            setOpenCheckboxAlert(true);
            const timer = setTimeout(() => setOpenCheckboxAlert(false), 3000); // 3 seconds timer
            return () => clearTimeout(timer);
        }
    }, [errorCheckbox]);

    useEffect(() => {
        if (deviceEdited) {
            const checkedValues = deviceEdited === "desktop"
                ? { desktop: true }
                : deviceEdited === "mobile"
                    ? { mobile: true }
                    : { tablet: true };

            setChecked(checkedValues);
            setWidgetSelected(shareUidAvailable)
        }
    }, [deviceEdited, allWidgetsByType, shareUidAvailable]);

    const onSelectWidget = () => {
        if (validation()) {
            cleanUpWidgetSelected();
            if (statusClicked === 'dragged') {
                onDragEndHandel({ widgetSelected: widgetSelected, checked, type: selectedWidgettype }, "widgetList");
                setisPublishOpen(true);
                setIsElementSaved(true);
            } else {
                handelCLickEditWidget(statusClicked, widgetSelected, checked);
                setOpen(false);
                setisPublishOpen(true);
                setIsElementSaved(true);
            }
            setAutocompleteCount(1);
        }
    }

    const validation = () => {
        let validationFlag = true;
        cleanUpWidgetSelected();
        if (!widgetSelected[0]) {
            setErrorSelect("Choose Widget");
            setOpenSelectAlert(true);
            validationFlag = false;
        } else {
            setErrorSelect("");
            setOpenSelectAlert(false);
        }
        if (deviceEdited) {
            onChecked(true, deviceEdited);
        } else if ((!checked?.desktop && !checked?.mobile && !checked?.tablet) && !deviceEdited && selectedWidgettype === "Comparison Table") {
            setErrorCheckbox("Choose Device");
            setOpenCheckboxAlert(true);
            validationFlag = false;
        } else {
            setErrorCheckbox("");
            setOpenCheckboxAlert(false);
        }
        return validationFlag;
    }

    const cleanUpWidgetSelected = () => {
        setWidgetSelected(widgetSelected.filter(widget => widget !== null));
    }

    const handelClose = (value: any) => {
        setOpen(false);
    };

    const handleAddAnother = () => {
        // Validate all current autocomplete values
        const allSelected = Array.from({ length: autocompleteCount }).every((_, index) => 
            widgetSelected[index] !== null && widgetSelected[index] !== undefined
        );
    
        if (allSelected && autocompleteCount < 5) {
            setAutocompleteCount(autocompleteCount + 1);
        } else {
            setErrorSelect("Please select a value before adding another.");
            setOpenSelectAlert(true);
        }
    };
    

    // const WidgetName = allWidgetsByType.filter((widget: any) => shareUidAvailable.includes(widget.shared_uid));

    const filteredWidgets = widgetSelected
        .map(uid => allWidgetsByType.find((widget: any) => widget.shared_uid === uid))
        .filter(widget => widget !== undefined);

    const handleRemoveAutocomplete = (index: number) => {
        const updatedWidgetSelected = [...widgetSelected];
        updatedWidgetSelected.splice(index, 1); // Remove the widget at the given index
        setWidgetSelected(updatedWidgetSelected.filter(widget => widget !== null));

        // Decrease the autocomplete count
        setAutocompleteCount(autocompleteCount - 1);
    };
    

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FormControl fullWidth>
                    {selectedWidgettype === "Comparison Table" ? <Typography sx={{ fontSize: "16px", color: "#000000DE", fontWeight: "500" }}>Devices to show on : {deviceEdited}</Typography> : ""}

                    <FormGroup row style={{ justifyContent: "space-around" }}>
                        {!deviceEdited && selectedWidgettype === "Comparison Table" ? (
                            <>
                                <FormControlLabel control={<Checkbox onChange={(e) => onChecked(e.target.checked, "desktop")} color="secondary" />} label="Desktop (1280px)" />
                                <FormControlLabel control={<Checkbox onChange={(e) => onChecked(e.target.checked, "tablet")} color="secondary" />} label="Tablet (768px)" />
                                <FormControlLabel control={<Checkbox onChange={(e) => onChecked(e.target.checked, "mobile")} color="secondary" />} label="Mobile (360px)" />
                            </>
                        ) : ''}

                    </FormGroup>
                    <Typography sx={{ fontSize: "12px", color: "#00000099", marginBottom: "20px" }}>Select at least one to activate this widget.</Typography>

                    {Array.from({ length: autocompleteCount }).map((_, index) => (
                        <div key={index} style={{ display: "flex" }}>
                            <Autocomplete
                                fullWidth
                                key={index}
                                id={`widgetShortCode-${index}`}
                                disableClearable
                                options={allWidgetsByType}
                                getOptionLabel={(option: any) => option.widget_name}
                                onChange={(event, newValue: any) => {
                                    const newWidgetSelected = [...widgetSelected];
                                    newWidgetSelected[index] = newValue ? newValue.shared_uid : null;
                                    setWidgetSelected(newWidgetSelected.filter(widget => widget !== null));
                                }}
                                value={filteredWidgets[index] ? { widget_name: filteredWidgets[index].widget_name } : undefined}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label={deviceEdited === null ? lblWidgetDropDown : filteredWidgets[index]?.widget_name}
                                        variant="outlined"
                                        color="secondary"
                                    />
                                )}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                                        {children}
                                    </Paper>
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#9C27B0',
                                        },
                                    },
                                }}
                                style={{ marginBottom: '10px' }}
                            />
                            {/* Remove Button for dynamic autocomplete */}
                            {autocompleteCount > 1 && index > 0 && (
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => handleRemoveAutocomplete(index)}
                                    color="secondary"
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>
                    ))}

                    {selectedWidgettype === "Comparison Table" && autocompleteCount < 5 && (
                        <div style={{ display: "flex", alignItems: "flex-start", gap: "6px", marginTop: "8px", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: "12px", color: "#00000099" }}>Would you like to show more than one table?</Typography>
                            <Button color="secondary" variant="outlined" endIcon={<AddIcon />} onClick={handleAddAnother}>ADD ANOTHER</Button>
                        </div>
                    )}

                    <Collapse in={openSelectAlert}>
                        {errorSelect && (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpenSelectAlert(false)}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                style={{ marginTop: "15px" }}
                            >
                                {errorSelect}
                            </Alert>
                        )}
                    </Collapse>
                    <Collapse in={openCheckboxAlert}>
                        {errorCheckbox && (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpenCheckboxAlert(false)}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                style={{ marginTop: "8px", marginBottom: "15px" }}
                            >
                                {errorCheckbox}
                            </Alert>
                        )}
                    </Collapse>
                </FormControl>
            </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "6px" }}>
                <Button color="secondary" onClick={handelClose}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={() => onSelectWidget()} endIcon={<AddIcon />}>{!deviceEdited ? 'ADD' : 'UPDATE'} WIDGET</Button>
            </div>
        </>

    );
}

