import {
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  FormControlLabel,
  TextField,
  Typography,
  Snackbar,
  Switch,
  CircularProgress
} from "@mui/material";
import React,{useState, useEffect} from "react";
import "./SequenceModal.css";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FrequencyTabs from "./FrequencyTabs";
import ProcessesChainTable from "./ProcessesChainTable";
import { useDispatch } from "react-redux";
import { ETLSequencesEndPointLogic } from '../../../../../logic/Admin/ETLSequences/ETLSequencesEndPointLogic';
import { setIsLoading } from '../../../../../features/userSlice';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#fff",
  padding: "20px 24px",
  borderRadius: "4px",
  boxShadow: "0px 0px 16px #50505085",
  zIndex: '999',
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX:'hidden'
};

const modalHeading = {
  fontSize: "20px",
  fontWeight: "500",
  marginBottom: "16px",
};
const modalLabel = {
  fontSize: "16px",
  fontWeight: "400",
  marginBottom: "10px",
};


interface Props  {
  etlSequence: any;
  processList: any;
  hasGetPermissions: any,
  hasEditPermissions: any,
  setEtlSequence: any,
  openModal: boolean;
  onCloseModal: any;
  oauthTokenHeader: any;
  setReload: any;
};

export default function CreateSequence ({
  etlSequence,
  processList,
  hasGetPermissions,
  hasEditPermissions,
  setEtlSequence,
  openModal,
  onCloseModal,
  oauthTokenHeader,
  setReload
}: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [processNameList, setProcessNameList] = useState<any[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<any | null>(null);
  const [tableProcesses, setTableProcesses] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [chainName, setChainName] = useState("");
  const [hourlyChainExpression, setChainExpression] = useState("");
  const [repeatEveryDayChainExpression, setRepeatEveryDayChainExpression] = useState("");
  const [repeatPerDayChainExpression, setRepeatPerDayChainExpression] = useState("");
  const [monthlyChainExpression, setMonthlyChainExpression] = useState("");
  const [weeklyChainExpression, setWeeklyChainExpression] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSelectedHourlyTimeChange = (time:any) => {
    setChainExpression(time);
  };

  const handleRepeatEveryDayTimeChange = (time:any) => {
    setRepeatEveryDayChainExpression(time);
  }

  const handleRepeatPerDayTimeChange = (time:any) => {
    setRepeatPerDayChainExpression(time);
  }

  const handleMonthlyTimeChange = (time:any) => {
    setMonthlyChainExpression(time);
  }

  const handleWeeklyTimeChange = (time:any) => {
    setWeeklyChainExpression(time);
  }

  useEffect(() => {
    if (processList && processList.data) {
    const activeProcesses = processList.data.filter((process:any) => process.active); 
    const nameIdMap = new Map();
    activeProcesses.forEach((process: any) => {
      const { name, id } = process;
        nameIdMap.set(name, {
          label: name,
          process__name: name,
          process__id: id,
          order: 1,
        });
      });
    const nameIdArray = Array.from(nameIdMap.values());
    setProcessNameList(nameIdArray);
    }
  }, [processList]);

  useEffect(() => {
    if (openModal) {
      setSwitchChecked(true);
      setChainName("");
      setChainExpression("");
      setRepeatEveryDayChainExpression("");
      setMonthlyChainExpression("");
      setTableProcesses([]);
      setSnackbarOpen(false);
      setSnackbarMessage('');
    }
  }, [openModal]);

  const handleAddToChain = () => {
    if (selectedProcess) {
      setTableProcesses([...tableProcesses, selectedProcess]);
      setProcessNameList(processNameList.filter(item => item.process__id !== selectedProcess.process__id));
      setSelectedProcess(null);
    }
  };

  const handleDeleteProcess = (processId: any) => {
    const deletedProcess = tableProcesses.find(process => process.process__id === processId);
    setProcessNameList([...processNameList, deletedProcess]);
    const updatedProcesses = tableProcesses.filter(process => process.process__id !== processId);
    setTableProcesses(updatedProcesses);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };

  const createSequence = async () => {
    let expression;
    if (hourlyChainExpression) {
      expression = hourlyChainExpression;
    } else if (repeatEveryDayChainExpression) {
      expression = repeatEveryDayChainExpression;
    } else if (repeatPerDayChainExpression) {
      expression = repeatPerDayChainExpression;
    } else if (monthlyChainExpression) {
      expression = monthlyChainExpression;
    } else if (weeklyChainExpression) {
      expression = weeklyChainExpression;
    } else {
      expression = '';
    }
    const data = {
      "name": chainName, // Sequence Name
      "expression": expression, // Sequence Cron Expression
      "processes": tableProcesses.map((process:any) => process.process__id), // Sequence Processes
      "is_active": switchChecked, // Sequence Status
   };
    try{
      if (hasEditPermissions) {
      await ETLSequencesEndPointLogic.createETLSequence(oauthTokenHeader,data);
      setReload(true);
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence Created Successfully! Please make sure to Deploy & Sync the Sequence Configurations');
      setChainName("");
      setChainExpression("");
      setRepeatEveryDayChainExpression("");
      setMonthlyChainExpression("");
      setTableProcesses([]);
      onCloseModal();
      } else {
        throw new Error("User doesn't have permissions to create ETL sequences");
      }
      if (hasGetPermissions) {
        await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading,setEtlSequence,dispatch)
      } else {
        throw new Error("Permission Denied");
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error while creating Sequence');
      console.error(error);
    } finally {
      setLoading(false);
      onCloseModal();
    }
  };

  // const processNames = etlSequence?.data?.flatMap((sequence:any) => 
  //   sequence?.processes?.map((process:any) => process.process__name)
  // ) || [];

  const validate = async () => {
    if (!chainName.trim()) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name is required.');
      setIsValid(false);
      return false;
    }

    const specialCharPattern = /[^a-zA-Z0-9 ]/;
    if (specialCharPattern.test(chainName)) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name must not contain special characters.');
      setIsValid(false);
      return false;
    }

    if (!hourlyChainExpression.trim() && !repeatEveryDayChainExpression.trim() && !repeatPerDayChainExpression.trim() && !monthlyChainExpression.trim() && !weeklyChainExpression.trim()) {
      setSnackbarOpen(true);
      setSnackbarMessage('At least one Sequence expression is required.');
      setIsValid(false);
      return false;
    }

    if (tableProcesses.length === 0) {
      setSnackbarOpen(true);
      setSnackbarMessage('At least one process must be added to the Sequence.');
      setIsValid(false);
      return false;
    }

    // Check if Sequence name is unique
    const isUniqueName = etlSequence?.data.every((sequence:any) => sequence.name !== chainName.trim());
    if (!isUniqueName) {
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence name must be unique.');
      setIsValid(false);
      return false;
    }

    // const isUniqueProcess = tableProcesses.every((process:any) => !processNames.includes(process.process__name));
    // if (!isUniqueProcess) {
    //   setSnackbarOpen(true);
    //   setSnackbarMessage('Duplicate already exists.');
    //   setIsValid(false);
    //   return false;
    // }

    setIsValid(true);
    return true;
  };

  const [switchChecked, setSwitchChecked] = useState(true);

  const handleCloseCreateDialog = () => {
    onCloseModal();
  }

  const handleCreateSequence = async() => {
    const isFormValid = await validate();
    if (!isFormValid) return;
    try{
      setReload(true);
      setLoading(true);
      await createSequence();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderSnackbar = () => {
  return (
    <Snackbar
      sx={{ zIndex: "9999999" }}
      open={snackbarOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={isValid ? "success" : "error"}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  };

  return (
    <>
    {renderSnackbar()}
    <div>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Typography
            sx={modalHeading}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Sequence
          </Typography>
          <Typography variant="h6" sx={modalLabel} component="h2">
              Sequence Details
          </Typography>
          {/* Modal Body */}
          <Grid container spacing={3} sx={{ mb: 2, }} alignItems={"center"}>
          <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-start" }}>
              <TextField
                id="queueName"
                label="Queue Name"
                value={chainName}
                helperText="Field is mandatory."
                size="small"
                fullWidth
                color="secondary"
                onChange={(e) => setChainName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", mb:4 }}>
             <FormControlLabel control={<Switch color="success" checked={true}   />} label="Sequence Status" />
            </Grid>
          </Grid>
          {/* Modal Accordion */}
          <Typography
            sx={modalLabel}
            variant="h6"
            component="h2"
          >
            Settings
          </Typography>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Frequency
              </Typography>
              <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12 }}>
                Setup Sequence reoccurrence dates and times
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FrequencyTabs
                onSelectedHourlyTimeChange={handleSelectedHourlyTimeChange}
                onSelectedRepeatEveryDayTimeChange={handleRepeatEveryDayTimeChange}
                onSelectedRepeatPerDayTimeChange={handleRepeatPerDayTimeChange}
                onSelectedMonthlyTimeChange={handleMonthlyTimeChange}
                onSelectedWeeklyTimeChange={handleWeeklyTimeChange}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Sequence Processing Order
              </Typography>
              <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12 }}>
                Setup the Sequence structure and order
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ mb: 2 }} alignItems={"center"}>
                <Grid item xs={9}>
                  <Autocomplete
                    disablePortal
                    id="processName"
                    options={processNameList}
                    fullWidth
                    color="secondary"
                    size="small"
                    value={selectedProcess}
                    onChange={(event, newValue) => {
                      setSelectedProcess(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Process Name" color="secondary" />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  justifyContent={"space-between"}
                  sx={{ display: "flex" }}
                >
                  <Button fullWidth onClick={handleAddToChain} color="secondary" variant="contained" size="medium" sx={{padding:'8px 10px', display:"block"}}>
                    Add to Sequence
                  </Button>
                </Grid>
              </Grid>
              <ProcessesChainTable tableProcesses={tableProcesses} setTableProcesses={setTableProcesses} handleDeleteProcess={handleDeleteProcess} />
            </AccordionDetails>
          </Accordion>
          {/* Modal footer */}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={3}
          >
            <Grid
              item
              xs={12}
              justifyContent={"flex-end"}
              sx={{ display: "flex" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseCreateDialog}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<DoneIcon />}
                onClick={handleCreateSequence}
                sx={{ marginLeft: 1 }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Create New Sequence"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
    </>
  );
};
