import {
  Button,
  Dialog,
  DialogTitle,
  Paper,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from '@mui/icons-material/Done';

interface ProductTypeProps {
  open: boolean;
  handleClose: () => void;
}

function ProductTypeModal({
  open,
  handleClose,
}: ProductTypeProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
        Create New Product Type
        </DialogTitle>
        <Typography sx={{mb:1}}>Details</Typography>
        <Grid container mt={0} columnSpacing={3}>
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-flexible"
              label="Product Type *" // Set your label here
              placeholder="Enter unique Product Type Name" // Set your placeholder here
              color="secondary"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography
          sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
        >
         This is a mandatory field.
        </Typography>

        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
          <Button color="secondary" onClick={handleClose} sx={{mr:1}}>
            CANCEL
          </Button>
          <Button variant="contained" color="secondary" disabled>
          Create <DoneIcon sx={{ml:1}} />
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}

export default ProductTypeModal;
