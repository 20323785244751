import { Autocomplete, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Paper, Radio, TextField, Typography} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DomainIntregationCongrats from "./ConnectDomainElements/DomainIntregationCongrats";
import RecomendedDNSmanager from "./ConnectDomainElements/RecomendedDNSmanager";
import CustomDNSmanager from "./ConnectDomainElements/CustomDNSmanager";

interface CreateWebsiteModalProps {
  open: boolean;
  handleClose: () => void;
}

function ConnectDomainModal({
  open,
  handleClose,
}: CreateWebsiteModalProps) {

 const websitelisting = [
        { label: "websiteexample1.com" },
        { label: "websiteexample2.com" },
        { label: "websiteexample3.com" },
        { label: "websiteexample4.com" },
        { label: "websiteexample5.com" },
 ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <Box>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
        Connect Your Website to a Domain
        </DialogTitle>
        <DialogContent sx={{ padding: "0px 0px 16px 0px", color:"#000000DE" }}>
          <DialogContentText id="alert-dialog-description" sx={{color:"#000000DE" }}>
          By connecting your domain, you'll ensure that visitors can reach your website through a personalized web address. 
          This process involves a few simple steps to either use your current DNS manager or delegate it to our DNS manager for seamless integration. Please fill your website URL and choose the preferred Integration Option:
          </DialogContentText>
        </DialogContent>
        <Box>
            <Box>
                <Autocomplete
                    sx={{marginTop:"16px"}}
                    fullWidth
                    options={websitelisting.map((websitename) => websitename.label)}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        required
                        label="Website URL"
                        color="secondary"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        />
                    )}
                    size="small"
                    />
                <Typography sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099", fontWeight: "400" }}>mandatory field.</Typography>
            </Box>
            <Box mt={1}>
                <Typography fontWeight={500}>Integration Options</Typography>
                <Grid container columnSpacing={8} mt={2}>
                    <Grid item xs={6} md={6}>
                      <Stack flexDirection={"column"} gap={1} alignItems={"center"}>
                      <FormControlLabel value="#" control={<Radio color="secondary"/>} label="I’ll use your DNS Manager (recommended)" />
                      </Stack>
                    <Card sx={{padding:"8px"}}>
                      <RecomendedDNSmanager/>
                    </Card>
                    </Grid>
                    <Grid item xs={6} md={6}>
                    <Stack direction="row" gap={1} alignItems={"center"}>
                    <FormControlLabel value="#" control={<Radio color="secondary"/>} label="I’ll use my own DNS Manager (custom)" />
                    </Stack>
                    <Card sx={{padding:"8px"}}>
                      <CustomDNSmanager/>
                    </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
          <Button color="secondary" onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="contained" color="secondary">
            <Stack flexDirection={"row"} gap={1}>
            Request Domain Integration
            </Stack>
          </Button>
        </Stack>
        </Box>
        <DomainIntregationCongrats/>
      </Paper>
    </Dialog>
  );
}

export default ConnectDomainModal;
