import { Grid, Typography } from '@mui/material'
import AdditionalContentNote from './AdditionalContentNote'
import AdditionalContentLabels from './AdditionalContentLabels'
import { useEffect, useState } from 'react'
import { ActivityNotesEndPointLogic } from '../../../../logic/AssetManagement/ActivityNotes/ActivityNotesLogic/ActivityNotesEndPointLogic'
import ActivityNotesAdditionalFileUpload from './ActivityNotesAdditionalFileUpload'

interface Props {
  oauthTokenHeader:any
  open:any
  ActivityNotesContent:any
  setActivityNotesContent:any
  setIsCardVisible:any
  isCardVisible:any
  fileBase64:any,
  setFileBase64:any,
  selectedRows:any,
  setSelectedRows:any
  data:any
  rowIndexNumber:any
  singleNoteShareUID:any
  userData:any
  UpdateActivityNote:Function,
  setFileName:any,
  fileName:any
  postFileUpload:any
  fileBase64s:any 
  setFileBase64s:any
  getFileContent:any
  isThisAdd:any
  setFiles:any
  files:any
  isDragActive:any 
  setIsDragActive:any
}

interface ActivityNoteContent {
  [key: string]: { created: string; note: string; labels:any; activity_date: string }[];
}

export const AdditionalContentActivityNotes = ({oauthTokenHeader,open,ActivityNotesContent,setActivityNotesContent,setIsCardVisible,isCardVisible,fileBase64,setFileBase64,selectedRows,setSelectedRows,singleNoteShareUID,isDragActive, setIsDragActive,files, setFiles,isThisAdd,getFileContent,postFileUpload,UpdateActivityNote,userData, data, rowIndexNumber,fileBase64s, setFileBase64s,setFileName,fileName }: Props) => {
 const [singleActivityNotesContent, setSingleActivityNotesContent] = useState<ActivityNoteContent>({});
 const [loading, setLoading] = useState(true); // Add a loading state

 useEffect(() => {
  const fetchSingleActivityNotesData = async() => {
    setLoading(true); // Set loading to true when fetching data
    try{
      await ActivityNotesEndPointLogic.getSingleActivityNotesContent(oauthTokenHeader,setSingleActivityNotesContent, singleNoteShareUID);
    } catch(error){
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when data is fetched
    }
  };
  fetchSingleActivityNotesData();
 },[oauthTokenHeader,singleNoteShareUID]);

 const [compareEmailForEdit, ] = useState(false);

  return (
    <>
    <Grid container>
        <Grid xs={5} px={2}>
            <Typography variant='body1' mb={2}>Note</Typography>
            <AdditionalContentNote oauthTokenHeader={oauthTokenHeader} singleNoteShareUID={singleNoteShareUID} singleActivityNotesContent={singleActivityNotesContent} loading={loading} UpdateActivityNote={UpdateActivityNote} compareEmailForEdit={compareEmailForEdit} />
        </Grid>
        <Grid xs={4} px={2}>
           <Typography mb={2} variant='body1'>Attachments</Typography>
           <ActivityNotesAdditionalFileUpload setIsCardVisible={setIsCardVisible} isCardVisible={isCardVisible} oauthTokenHeader={oauthTokenHeader} files={files} setFiles={setFiles} singleNoteShareUID={singleNoteShareUID} />
        </Grid>
        <Grid xs={3} px={2}>
          <Typography mb={2} variant='body1'>Labels</Typography>
          <AdditionalContentLabels oauthTokenHeader={oauthTokenHeader} singleNoteShareUID={singleNoteShareUID} singleActivityNotesContent={singleActivityNotesContent} loading={loading} />
        </Grid>
    </Grid>
    </>
  )
}
