import { endpointsService } from "../../../services/endPoints.services";
import { setDisplayErrorSnackBar } from "../../../features/userSlice";
import store from "../../../store";

async function getExequteQuery(oauthTokenHeader: string, setExequteQueryContent: any,website_shared_uid:string,start_date:any,
    end_date:any,QUERY_ID:any
) {
    try {
        const response = await endpointsService.getExequteQuery(oauthTokenHeader,website_shared_uid,start_date,end_date,QUERY_ID);
        if (!response.status || response.status !== 200) {
            throw new Error();
        }
        else{
            setExequteQueryContent(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function postExequteQuery(oauthTokenHeader: string, data:any,setExequteQueryMessage:any) {
    try {
        const response = await endpointsService.postExequteQuery(oauthTokenHeader,data);
        if (!response.status || response.status !== 201) {
            throw new Error();
        }
        else{
            setExequteQueryMessage(response?.data?.reason);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function putExequteQuery(oauthTokenHeader: string, data:any,itb_shared_uid:any,setExequteQueryMessage:any) {
    try {
        const response = await endpointsService.putExequteQuery(oauthTokenHeader,data,itb_shared_uid);
        if (!response.status || response.status !== 200) {
            throw new Error();
        }
        else {
            setExequteQueryMessage(response?.data?.reason)
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}


export const engagementIncentivesEndPointLogic = {
    getExequteQuery,
    postExequteQuery,
    putExequteQuery
}