import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Avatar, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { ManualExpenseEndPointLogic } from '../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic';
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../../../features/userSlice';

interface Comment {
  content: string;
  postedDate: string;
  lastEditedDate: string;
}
interface Props {
  oauthTokenHeader:string;
  singleExpneseShareUID:any;
}

function ManualExpenseComment({oauthTokenHeader,singleExpneseShareUID}:Props) {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [postedComments, setPostedComments] = useState<Comment[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editComment, setEditComment] = useState("");
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [,setSuccess] = useState("");
  const userData = useSelector(selectUserData) as any;
  const [uploadedComments, setUploadedComments] = useState([]) as any;
  const [selectedComment, setSelectedComment] = useState<any>(null);

  let commentData :any = {
    "data" :{
     "object_type": "manual_adjustment_expenses",
     "comment": comment
  }} ;

  const handleCommentUpload = () => {
    ManualExpenseEndPointLogic.postComment(oauthTokenHeader, commentData, singleExpneseShareUID, trackPromise)
      .then(() => {
        fetchUploadedComments(); // Fetch the updated file list after upload
        // setFileBase64([]);
      })
      .catch((error: any) => {
        console.error("Error uploading file: ", error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const fetchUploadedComments = () => {
    if(singleExpneseShareUID && uploadedComments !== 0){
      setLoading(true);
      ManualExpenseEndPointLogic.getComments(oauthTokenHeader, singleExpneseShareUID, trackPromise,setUploadedComments)
      .catch((error: any) => {
        console.error("Error fetching uploaded files: ", error);
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    fetchUploadedComments();
    // eslint-disable-next-line
  }, []);

  const handlePostComment = () => {
    const newComment: Comment = {
      content: comment,
      postedDate: new Date().toLocaleString([], {hour12: true, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit'}),
      lastEditedDate: new Date().toLocaleString([], {hour12: true, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit'}),
    };
    setPostedComments([...postedComments, newComment]);
    setComment("");
    setLoading(true);
    handleCommentUpload();
  };

  const handleEditClick = (postedComment:any,index: number) => {
    setEditComment(postedComment?.comment);
    setEditIndex(index);
    setIsDialogOpen(true);
  };

  const handleSaveClick = (selectedComment:any) => {
    let commentUpdatedData :any = {
      "data": {
          "comment_id": selectedComment,
          "comment": editComment
          }
      }

    if (editIndex !== null) {
      ManualExpenseEndPointLogic.putComments(oauthTokenHeader, commentUpdatedData, singleExpneseShareUID, trackPromise,setSuccess)
      .then(() => {
        fetchUploadedComments();
      })
      .catch((error: any) => {
        console.error("Error uploading file: ", error);
      });
      setIsDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const options = {
    month: 'short', // 'Aug.'
    day: 'numeric', // 6
    year: 'numeric', // 2023
    hour: 'numeric', // 10
    minute: 'numeric', // 49
    hour12: true, // 12-hour format (am/pm)
  } as any;

  return (
    <div>
      <Stack flexDirection={"column"} alignItems={"end"} gap={"8px"} sx={{ marginBottom: "24px" }}>
        <TextField
          color="secondary"
          size="small"
          id="outlined-multiline-static"
          label="Statement"
          multiline
          rows={2}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ width: "100%" }}
        />
        <Button 
        color="secondary" 
        variant="contained" 
        sx={{ textDecoration: "underline" }} 
        onClick={handlePostComment}
        disabled={!comment.trim()}>
          Post comment
        </Button>
      </Stack>

      {loading ? <CircularProgress color="secondary" size="30px"/> : (
        <>
      <Stack direction="column" spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto' }}>
        {uploadedComments && uploadedComments.length > 0 ? (
        uploadedComments?.sort((a: any, b: any) => new Date(b.modified).getTime() - new Date(a.modified).getTime()) // Sort latest to oldest
        ?.map((postedComment:any, index:number) => (
          <div className="posted-comment" key={index}>
        {/* Comment TextField */}
            <TextField
              variant="filled"
              size="medium"
              id="outlined-multiline-static"
              multiline
              rows={2}
              value={postedComment.comment}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  '&:before': { borderBottom: 'none' },
                  '&:hover:before': { borderBottom: 'none' },
                  '&:after': { borderBottom: 'none' }
                }
              }}
              sx={{ width: "100%" }}
            />
            <Stack flexDirection="row" justifyContent="flex-start" sx={{ marginBlock: "8px" }}>
              {/* By Label and Avatar */}
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <Typography noWrap sx={{ fontWeight: '500', fontSize: '14px', marginRight: '8px' }}>
                  By
                </Typography>
                <Avatar sx={{ width: 24, height: 24 }}>{postedComment?.modified_by?.charAt(0).toUpperCase()}</Avatar>
              </Grid>

              {/* Posted Section */}
              <Grid item xs={4}>
                <Stack direction="row" alignItems="center">
                  <Typography noWrap sx={{ fontWeight: '500', fontSize: '14px', marginRight: '8px' }}>
                    Posted
                  </Typography>
                  <Typography noWrap sx={{ fontSize: '14px' }}>
                    {new Date(postedComment?.created).toLocaleDateString('en-US', options)|| 'N/A'}
                  </Typography>
                </Stack>
              </Grid>

              {/* Last Edited Section */}
              <Grid item xs={4}>
                <Stack direction="row" alignItems="center">
                  <Typography noWrap sx={{ fontWeight: '500', fontSize: '14px', marginRight: '8px' }}>
                    Last Edited
                  </Typography>
                  <Typography noWrap sx={{ fontSize: '14px' }}>
                    {new Date(postedComment?.modified).toLocaleDateString('en-US', options) || 'N/A'}
                  </Typography>
                </Stack>
              </Grid>

              {/* Edit Button */}
              {userData?.data?.user_details?.email === postedComment?.modified_by && (
                <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{ textDecoration: "underline", padding: 0 }}
                    color="secondary"
                    onClick={() => {
                      handleEditClick(postedComment, index);
                      setSelectedComment(postedComment?.shared_uid);
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
            </Stack>
            </div>
          ))
        ) : (
          <Typography sx={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
            No Comments
          </Typography>
        )}
      </Stack>
        </>
      )}

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} PaperProps={{ sx: { width: "900px", maxWidth: "900px"}}}>
        <Paper> 
          <DialogTitle sx={{padding:'16px 24px 10px 24px'}}>Edit Comment</DialogTitle>
          <DialogContent sx={{paddingTop:"6px !important"}}>
            <TextField
              color="secondary"
              size="small"
              id="outlined-multiline-static"
              label="Edit Comment"
              multiline
              rows={4}
              value={editComment}
              onChange={(e) => setEditComment(e.target.value)}
              sx={{ width: "100%" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
            <Button onClick={() => handleSaveClick(selectedComment)} color="secondary" variant="contained">Save</Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  )
}

export default ManualExpenseComment;
