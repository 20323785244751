import EditTheme from '../../../../components/AssetManagement/Design/Theme/EditTheme/EditTheme'

const EditThemeScreen = () => {
  return (
    <>
      <EditTheme />
    </>
  )
}

export default EditThemeScreen
