import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { loginHelpers } from '../logic/loginHelpers';
import { validEmail, validPassword } from '../logic/validation';
import LoginSection from '../../../components/LoginSection/LoginSection';
import styles from "./Login.module.css";
import { Snackbar , IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from "@mui/material/Alert";
import { useLocation } from 'react-router-dom';


export default function LoginScreen(props: any) {
    const [userSignInValueLocalStorage, setUserSignInValueLocalStorage] = useState('false');
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoginSuccessfull, setIsLoginSuccessfull] = useState(null);
    const location = useLocation();
   /*  const [isLoginLoading, setIsLoginLoading] = useState(false) */

   useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSnackbarMessage(location.state.successMessage);
      setSnackbarOpen(true);
    }
  }, [location.state]);
    

    const isSuccessLogin = (body:any) => {
        loginHelpers.isSuccessLogin(body, {
            dispatch: props.dispatch,
            userSignInValueLocalStorage: userSignInValueLocalStorage,
            setUserSignInValueLocalStorage: setUserSignInValueLocalStorage,
            handleSnackbarOpen: handleSnackbarOpen,
            setSnackbarMessage: setSnackbarMessage,
            setIsLoginSuccessfull: setIsLoginSuccessfull,
            /* setIsLoginLoading: setIsLoginLoading */
        });
    };
    const loginNow = (email: string, password: string) => {
        if (loginHelpers.isValidateFields(email, password)) {
            isSuccessLogin({ email, password });
            
        }
    }

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    useEffect(() => {
        setUserSignInValueLocalStorage(localStorage.getItem('signin') as any);
    }, []);
    const loginProps = {
        userSignInValueLocalStorage,
        isSuccessLogin,
        props,
        dispatch,
        loginNow,
        isValidEmail: validEmail,
        validPassword,
        isLoginSuccessfull
    };
    return <>
        <div className={styles.x1080empty}>
            <img
                className={styles.backgroundImage1}
                alt=""
                src="/background-image-1@2x.png"
            />
            <section className={styles.whiteSection}>
                {<LoginSection {...loginProps} />
                }
                
            </section>
            <Snackbar
             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
               
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                >
                     <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={location.state && location.state.successMessage ? "success" : "error"}
                
              >
                {snackbarMessage}
              </MuiAlert>
                </Snackbar>
            
        </div>
    </>
}
