import { Table, TableBody, TableCell, TableContainer, TableHead, Pagination,TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect} from "react";
import { MonitoringEndPointLogic } from "../../../../logic/AssetManagement/Health/Monitoring/MonitoringEndPointLogic";
import SimpleBackdrop from "../../../BackDrop/BackDrop";

interface Props {
  tableHeadings: { [key: string]: string };
  selectedSharedUid:any;
  selectedVersion:any;
  setisDataAvilable:any;
  isDataAvilable:any;
  startDate:any;
  endDate:any;
  token:any;
  setLoading:any;
  search:any;
  setSearch:any;
  loading:any;
  setTabledata:any;
  tableData:any;
}

const DowntimeIncidentsTable = ({ tableHeadings,selectedSharedUid, selectedVersion,startDate,endDate,token,setLoading,
  search,setSearch,loading,setisDataAvilable,isDataAvilable,setTabledata,tableData}: Props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [, setError] = React.useState("");
  const [orderBy, setOrderBy] = React.useState<string | null>(null);
  const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">("asc");
  const [filteredData, setFilteredData] = React.useState([]) as any;
  
  const headCells = Object.entries(tableHeadings);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const nonSortableColumns = ["down_timestamp", "up_timestamp"];

  const handleRequestSort = (property: string) => {
    // Prevent sorting for non-sortable columns
    if (nonSortableColumns.includes(property)) return;

    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = React.useMemo(() => {
    const data = [...(selectedVersion.length !== 0 ? filteredData : tableData || [])];
    if (orderBy) {
      return data.sort((a: any, b: any) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];
        if (valueA == null || valueB == null) return 0; // Handle null/undefined values
        return (orderDirection === "asc" ? 1 : -1) * (valueA > valueB ? 1 : -1);
      });
    }
    return data;
  }, [orderBy, orderDirection, filteredData, tableData, selectedVersion]);

 

  useEffect(() => {
    if (search === true && selectedSharedUid.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getMonitoring(
              token,
              selectedSharedUid,
              startDate,
              endDate,
              setTabledata,
              setError,
              setisDataAvilable
            );
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
          
        } finally {
          setSearch(false);
          // setisDataAvilable(false);
          if (tableData.length === 0) {
            setTimeout(() => setLoading(false), 100); 
          }
          // setLoading(false);
        }
      };
  
      fetchMonitoringData();
    }
    //eslint-disable-next-line
  }, [token, selectedSharedUid, search, startDate, endDate, setLoading, isDataAvilable,setSearch]);
  
  useEffect(() => {
    if (search === true && selectedVersion.length !== 0) {
      const fetchMonitoringData = async () => {
        setLoading(true);
        try {
          if (token) {
            await MonitoringEndPointLogic.getSystemMonitoring(
              token,
              startDate,
              endDate,
              setTabledata,
              setError,
              setisDataAvilable
            );
          } else {
            throw new Error("User doesn't have permissions to fetch manual expense data");
          }
        } catch (error) {
          throw new Error("Error fetching monitoring data");
        } finally {
          // setLoading(false);
          setSearch(false);
          // setisDataAvilable(false);
        }
      };
  
      fetchMonitoringData();
    }
    // eslint-disable-next-line
  }, [token, selectedVersion, search, startDate, endDate, setLoading, isDataAvilable,setSearch]);
  

  useEffect(() => {
    if (selectedVersion.length !== 0) {
      const newFilteredData = tableData.filter((row: any) =>
        
        selectedVersion === (row?.service_type)
      );
      setFilteredData(newFilteredData);
    } else {
      setFilteredData([]); // Reset to original data if no version is selected
    }
  }, [selectedVersion, tableData,search]);

  const convertTime = (timestamp:any) => {
    const date = new Date(timestamp); // Parse the timestamp
    const hours = date.getUTCHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Pad minutes
    const amPm = date.getUTCHours() < 12 ? "a.m." : "p.m."; // Determine a.m. or p.m.
    return `${hours}:${minutes} ${amPm}`; // Return formatted time
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString); // Convert the string to a Date object
    const day = String(date.getUTCDate()).padStart(2, "0"); // Ensure 2-digit day
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Ensure 2-digit month, getUTCMonth is 0-indexed
    const year = String(date.getUTCFullYear()).slice(-2); // Get last 2 digits of the year
    return `${day}/${month}/${year}`; // Format the date as dd/mm/yy
  };

  const dataToDisplay = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  return (
    <>
    {loading ? (
      <SimpleBackdrop />
    ) : dataToDisplay  ? (
      <TableContainer
        className="monitoringTableWrap"
        sx={{ maxHeight: 800, background: '#ffffff', mt: '1rem' }}
      >
        <Typography sx={{ fontSize: '24px', lineHeight: '32px', padding: '8px 16px' }}>
          Downtime Incidents
        </Typography>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
                  {headCells.map(([key, value], index) => (
                    <TableCell key={index} align="left">
                      {key === "down_timestamp" || key === "up_timestamp" ? (
                        value && typeof value === 'string' ? value : ''
                      ) : (
                        <TableSortLabel
                          active={orderBy === key}
                          direction={orderBy === key ? orderDirection : "asc"}
                          onClick={() => handleRequestSort(key)}
                        >
                          {value && typeof value === 'string' ? value : ''}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToDisplay.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>No Data</TableCell>
              </TableRow>
            ) : (
              dataToDisplay
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <React.Fragment key={index}>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      {headCells.map(([key], idx) => (
                        <TableCell key={idx} align="left">
                          {key === "up_timestamp" ? (
                            <>
                              {convertTime(row[key])}
                            </>
                          ) : key === "down_timestamp" ? (
                            <>
                              {convertTime(row[key])}
                            </>
                          ) : key === "event_date" ? (
                            <>{formatDate(row[key])}</>
                          ) : row[key] ? (
                            row[key]
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))
            )}
          </TableBody>
        </Table>
        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={dataToDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                display: "none",
              },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={Math.ceil(dataToDisplay.length / rowsPerPage)}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
            onChange={(event, value) => setPage(value - 1)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataToDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: 'hidden-displayed-rows', // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
            }}
          />
        </Stack>
      </TableContainer>
    ) : null}
  </>  
  );
};

export default DowntimeIncidentsTable;
