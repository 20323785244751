import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation/* , useNavigate */ } from 'react-router-dom';

export interface PageElementErrorProps {
   /*  pageUid: string;
    setPageStructureDesign: any;
    setPageDictionary: any;
    setIsLoading:any;
    setPageContent : any;
    oauthTokenHeader: string */
    selectedSite: any
    /* trackPromise: any
    siteUid: any */
    errorAccToPageStatus:any
   /*  setErrorAccToPageStatus: any 
    setRetry: any */
    setOpen:any
    open:any
}
export default function AlertDialog(
    {
       selectedSite,
      /*  siteUid,
       pageUid,
        oauthTokenHeader,
        setPageContent,
        setPageDictionary, 
        setIsLoading, */
        errorAccToPageStatus,
        /* setErrorAccToPageStatus, 
        trackPromise,
        setPageStructureDesign,
        setRetry, */
        setOpen,
        open
        
      }: PageElementErrorProps
    
) {

const location = useLocation()
 
 const [/* isEditPage */, setIsEditPage]= React.useState(false)
 
/* const navigate = useNavigate()
const obj: any = Object.values(selectedSite)[0]; */

const handleClose = () => {
 
   /* navigate(`/assets/${obj.site_name}`) */
    setOpen(false);
  };

  /* const getPageContent = async () => {
    try {
      await pageBuildingEndPointLogic.getPageContent(siteUid,pageUid, oauthTokenHeader, setPageContent,setPageStructureDesign, setPageDictionary, setIsLoading,setErrorAccToPageStatus, trackPromise);
      
    } catch (error) {
        console.error("Error fetching page content:", error);
        
    }
  }; */

/*   const retEditPage = () => {
    setOpen(false)
    setRetry(true)
  }

  const ret = () => {
    setOpen(false)
    setRetry(true)
    
  } */

  React.useEffect(() => {

    const extractEdit = () => {
        const parts = location?.pathname.split('/');
        const editPart = parts[parts.length - 1]; // Get the last part of the URL
        if (editPart === 'edit') {
            setIsEditPage(true)
        }
      };
      extractEdit()
}, [location])

const jsonString = errorAccToPageStatus[0]?.text;
const jsonObject = jsonString && JSON.parse(jsonString);
const reason = jsonObject?.reason;
 
  return (
    
    <>
      
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "900px", maxWidth: "350px"}}}
      >
        <DialogTitle id="alert-dialog-title">
        {errorAccToPageStatus[0]?.response?.status} error!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {reason} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
         <Button onClick={handleClose} autoFocus color='secondary'>
            close
          </Button>
          
         {/*  {
            isEditPage === true ?
            <Button onClick={retEditPage} autoFocus color='secondary'>
            retry
          </Button> :
          <Button onClick={ret} autoFocus color='secondary'>
          retry
        </Button>
          } */}
          
        </DialogActions>
      </Dialog>
    </>
  );
}