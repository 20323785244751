import React, { useState, useRef, useEffect } from 'react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';

interface FilterProps {
  key: string;
  label: string;
  searchTerm?: any; // Optional now
  showSearch?: boolean;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedOption: any;
  filterName: string;
  data: any[];
  onSelectionChange: (updatedSelection: any) => void;
  count: number;
  colorSelected: string;
  multiSelect: boolean;
  valueMapping?: any;
  isDateRangeFilter?: boolean;
  onDateRangeChange?: (dateRange: [Dayjs | null, Dayjs | null]) => void;
  siteNames?: any;
  trafficSources?: any;
  filterRoot?: any;
}

interface FilterComponentProps {
  filterConfig: FilterProps[];
  clearFilters: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filterConfig, clearFilters }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-start');
  const [selectedContentIndex, setSelectedContentIndex] = useState<number>(0);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (newPlacement: PopperPlacementType, index: number) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(anchorEl !== event.currentTarget || !open);
    setPlacement(newPlacement);
    setSelectedContentIndex(index);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popperRef.current && !popperRef.current.contains(event.target as Node) && anchorEl && !anchorEl.contains(event.target as Node)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
    const { checked } = event.target;
    const currentConfig = filterConfig[selectedContentIndex];
  
    // Ensure selectedOption is an array
    const selectedOptions = Array.isArray(currentConfig.selectedOption)
      ? currentConfig.selectedOption
      : [];
  
    let updatedSelection;
    if (currentConfig.multiSelect) {
      updatedSelection = checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item !== option);
    } else {
      updatedSelection = checked ? [option] : [];
    }
  
    currentConfig.onSelectionChange(updatedSelection);
  };

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Next Month',
      getValue: () => {
        const today = dayjs();
        const startOfNextMonth = today.endOf('month').add(1, 'day');
        return [startOfNextMonth, startOfNextMonth.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];


  const filteredOptions = Array.from(
    new Set( filterConfig[selectedContentIndex]?.key === "asset" 
        ? filterConfig[selectedContentIndex]?.siteNames
            ?.filter((site: any) =>
              site.site.site_name
                .toLowerCase()
                .includes(filterConfig[selectedContentIndex].searchTerm?.toLowerCase() || "")
            )
            .map((site: any) => site.key) // Map to keys for consistency with selection handling
        : filterConfig[selectedContentIndex]?.key === "source" 
        ? filterConfig[selectedContentIndex]?.trafficSources
            ?.filter((source: any) =>
              source.traffic_source_name
                .toLowerCase()
                .includes(filterConfig[selectedContentIndex].searchTerm?.toLowerCase() || "")
            )
            .map((source: any) => source.shared_uid)
        :  filterConfig[selectedContentIndex].data
            ?.flatMap((item) => {
              const filterRoot = filterConfig[selectedContentIndex].filterRoot; // Can be a string or an array
              const filterName = filterConfig[selectedContentIndex].filterName;
  
              let nestedData = item;
  
              // Traverse the object if filterRoot is an array of keys
              if (Array.isArray(filterRoot)) {
                for (const key of filterRoot) {
                  nestedData = nestedData?.[key];
                  if (!nestedData) break; // Stop if any part of the chain is undefined
                }
              } else if (typeof filterRoot === "string") {
                nestedData = nestedData?.[filterRoot]; // Access directly if it's a string
              }
  
              if (Array.isArray(nestedData)) {
                // Extract values from the nested array if filterName exists
                return nestedData.flatMap((nestedItem: any) => nestedItem[filterName] || []);
              }
  
              const value = item[filterName];
  
              if (Array.isArray(value)) {
                return value; // If value is an array, return its items
              }
              return String(value); // Otherwise, return the value as a string
            })
            ?.filter((option) => {
              if (Array.isArray(option)) {
                // Handle arrays, ensure any item in the array matches the search term
                return option.some((subOption) =>
                  String(subOption)
                    .toLowerCase()
                    .includes(filterConfig[selectedContentIndex].searchTerm?.toLowerCase() || '')
                );
              }
              if (typeof option === 'string' && option !== 'null') {
                // Handle strings, case-insensitive search
                return option
                  .toLowerCase()
                  .includes(filterConfig[selectedContentIndex].searchTerm?.toLowerCase() || '');
              }
              return false; // Exclude non-string values
            })
    )
  );


  const displayOption = (option: any) => {
    const valueMapping = filterConfig[selectedContentIndex].valueMapping;

    // Check if the current filter key is "asset"
    if (filterConfig[selectedContentIndex]?.key === "asset") {
      const siteNames = filterConfig[selectedContentIndex]?.siteNames;

      // Loop through the siteNames to check if the current option's key matches any site name
      const matchedSite = siteNames?.find((site: any) => site.key === option);

      // If a matching site is found, return the site_name
      if (matchedSite) {
        return matchedSite.site.site_name; // Return the site_name if matched
      } else {
        // If no match is found, return null or any fallback value
        return null;
      }
    }

    if (filterConfig[selectedContentIndex]?.key === "source") {
      const trafficSources = filterConfig[selectedContentIndex]?.trafficSources;
      const matchedSource = trafficSources?.find((source: any) => source.shared_uid === option);
      if (matchedSource) {
        return matchedSource.traffic_source_name; // Return the site_name if matched
      } else {
        // If no match is found, return null or any fallback value
        return null;
      }
    }
    // Use mapped label if valueMapping exists, otherwise show raw option
    return valueMapping ? valueMapping[option] ?? option : option;
  };



  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Popper sx={{ zIndex: 1200 }} open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={popperRef}>
              <Box padding={2} minWidth={320} sx={{padding: "4px 24px 24px 24px", height:"auto", overflowY: "auto",scrollBehavior: "smooth",transition: "all 0.3s ease", scrollbarWidth: "thin"}} maxHeight={550}> 
                {filterConfig[selectedContentIndex].showSearch && (
                  <TextField
                    color='secondary'
                    label="Search..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={filterConfig[selectedContentIndex].searchTerm || ''}
                    onChange={filterConfig[selectedContentIndex].onSearchChange}
                  />
                )}
                <Typography sx={{ fontWeight: '500', color: '#000000DE', paddingBlock: '16px' }}>
                  {filterConfig[selectedContentIndex].label}
                </Typography>
                {filterConfig[selectedContentIndex].isDateRangeFilter ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                      value={
                        filterConfig[selectedContentIndex].selectedOption
                          ? [
                            dayjs(filterConfig[selectedContentIndex].selectedOption[0]),
                            dayjs(filterConfig[selectedContentIndex].selectedOption[1]),
                          ]
                          : [null, null] // default value for date range
                      }
                      onChange={(newValue) => {
                        // Type assertion to ensure that TypeScript understands that newValue is a tuple
                        const [startDate, endDate] = newValue as [Dayjs | null, Dayjs | null];

                        let formattedDateRange: [Dayjs | null, Dayjs | null] = [null, null];

                        // Check if only one date is selected
                        if (startDate && !endDate) {
                          // If only one date is selected, treat it as both start and end date
                          formattedDateRange = [startDate, startDate];
                        } else if (startDate && endDate) {
                          // If both dates are selected, use the date range as is
                          formattedDateRange = [startDate, endDate];
                        }

                        // Format the dates if necessary (optional)
                        const formatted = formattedDateRange.map(date =>
                          date ? dayjs(date).format('YYYY-MM-DD') : null
                        );

                        // Pass the formatted date range to the parent component
                        filterConfig[selectedContentIndex].onDateRangeChange?.(newValue); // Pass raw Dayjs objects
                        filterConfig[selectedContentIndex].onSelectionChange(formatted); // Update state with formatted dates
                      }}
                      slotProps={{
                        shortcuts: {
                          items: shortcutsItems,
                        },
                        actionBar: { actions: [] },
                      }}
                      calendars={2}
                    />
                  </LocalizationProvider>
                ) : filterConfig[selectedContentIndex].multiSelect ? (
                  <Box>
                    {filteredOptions.map((option:any) => (
                      <FormControlLabel
                        sx={{padding:"0px 0px 0px 12px"}}
                        key={option}
                        control={
                          <Checkbox
                            color="secondary"
                            checked={filterConfig[selectedContentIndex].selectedOption !== null && (filterConfig[selectedContentIndex].selectedOption as any).includes(option)}
                            onChange={(event) => handleOptionChange(event, option)}
                          />
                        }
                        label={displayOption(option)}
                        style={{ display: 'block', marginRight: 0 }}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box minWidth={320} sx={{padding:"16px"}} >
                    <RadioGroup
                      value={filterConfig[selectedContentIndex].selectedOption as string}
                      onChange={(event) => handleOptionChange(event, event.target.value)}
                    >
                      {filteredOptions.map((option:any) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio color="secondary" />}
                          label={displayOption(option)}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>

      {filterConfig.map((config, index) => (
        <Button
          sx={{
            // eslint-disable-next-line
            backgroundColor: open && selectedContentIndex === index ||
              ((filterConfig[selectedContentIndex]?.selectedOption?.length && selectedContentIndex === index))
              ? '#f7eef9'
              : 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(156, 39, 176, 0.04)',
            },
          }}

          key={config.key}
          onClick={handleClick('bottom-start', index)}
        >
          <span style={{ color: config.count > 0 ? config.colorSelected : "#000000DE" }}>
            {config.key}
          </span>
          {config.count > 0 ? (
            <span style={{ color: config.colorSelected }}>+{config.count}</span>
          ) : (
            <KeyboardArrowDownIcon style={{ color: "#000000DE" }} />
          )}
        </Button>
      ))}
      <Button
        sx={{ color: '#000000DE', '&:hover': { backgroundColor: 'rgba(156, 39, 176, 0.04)' } }}
        onClick={clearFilters}
      >
        Clear
      </Button>
    </Box>
  );
};

export default FilterComponent;
