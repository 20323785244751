import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./RunNewProcessLog.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  table : any;
  setSelectedTable: any;
}

export default function RunNewProcessLogTag({table,setSelectedTable}:Props) {
  const tableNames = table?.tables?.map((name: string) => ({ title: name })) || [];
  return (
    <Autocomplete
    className='multipleTabItem'
    multiple
    id="checkboxes-tags"
    fullWidth
    options={tableNames}
    disableCloseOnSelect
    getOptionLabel={(option: any) => option.title}
    // Use isOptionEqualToValue instead of getOptionSelected
    isOptionEqualToValue={(option, value) => option.title === value.title}
    onChange={(event, newValue) => {
      // Extract the titles of the selected options
      const selectedTitles = newValue.map((option: any) => option.title);
      setSelectedTable(selectedTitles);  // Update the state with selected titles
    }}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8, marginLeft: 0 }}
          checked={selected}  // Bind selection to checkbox
          color='secondary'
        />
        {option.title}
      </li>
    )}
    renderInput={(params) => (
      <TextField color='secondary' {...params} placeholder="Choose Tables" />
    )}
  />
  
  );
}

