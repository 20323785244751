/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Button,CircularProgress,Stack, TextField, Typography } from "@mui/material"; 
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import { advertisingAccountsEndPointLogic } from "../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { useDispatch } from 'react-redux';

interface Props {
  singleAccountShareUID:any
  advertisingAccounts: any;
  trackPromise: any;
  onAccountStatusChange: any;
  fetchAdAccountData:any
  setTrafficSourceData:any
  getAdAccountDetails:Function
  oauthTokenHeader:any
  rows:any
  expandedRow:any
}

export const AdditionalContentAccounts = ({singleAccountShareUID,oauthTokenHeader,trackPromise,onAccountStatusChange,fetchAdAccountData,rows,setTrafficSourceData,getAdAccountDetails,expandedRow}:Props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [advertisingAccountContent,setAdvertisingAccountContent] = useState({})
  const [errorAccToPageStatus, setErrorAccToPageStatus] = useState(false);
  const [errorAlert , setErrorAlert] =useState<string | null>(null);
  const [singleAdvertisingAccountContent, setSingleAdvertisingAccountContent] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchSingleAdvertisingAccountData = async() => {
      setLoading(true); // Set loading to true when fetching data
      try {
        await advertisingAccountsEndPointLogic.getSingleAdvertisingAccount(oauthTokenHeader, setSingleAdvertisingAccountContent, setErrorAccToPageStatus, singleAccountShareUID,dispatch);
      } catch(error){
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when data is fetched
      }
    };
    fetchSingleAdvertisingAccountData();
  },[oauthTokenHeader,singleAccountShareUID,dispatch]);

  const linked_assets = singleAdvertisingAccountContent?.[0]?.linked_assets;

  useEffect(() => {
    const linked_asset_count = linked_assets?.length;
    if (linked_asset_count > 1) {
      setErrorAlert("Multiple linked assets found.");
    } else {
      setErrorAlert(null);
    }
  },[linked_assets]);

  const handleStatusChange = async(account:any) =>{
    setLoading(true)
    setLoading(account?.shared_uid);
    const newActiveState = !account?.active;
    const data = { active: newActiveState };
    try {
      await advertisingAccountsEndPointLogic.advertisingAccountsToggle(oauthTokenHeader,setAdvertisingAccountContent, data, account?.shared_uid, );
      setSnackbarOpen(true);
      setSnackbarMessage('Account Status updated successfully');
      if(oauthTokenHeader) {
        setLoading(true);
        await fetchAdAccountData();
      } else {
        throw new Error("User doesn't have permissions to fetch advertising accounts data");
      }
    } catch(error){
      console.error("Error updating page speed:", error);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage('Status updated successfully');
    }
};

  // Utility function to format the cost
  const formatCurrency = (value: number | undefined) => {
    if (value === undefined) return "$0.00"; // Handle undefined case
    return `$${Math.round(value).toLocaleString()}`; // Round to nearest integer and format with commas
  };

  return (
    <>
        <>
          <Box sx={{ paddingBlock: "10px" }}>
            {errorAlert && (
              <Alert severity="success" sx={{ marginBottom: '16px', alignItems:"center"}}>
                {errorAlert}
              </Alert>
            )}
            <Typography>Month to Date Data</Typography>
            <Stack direction="row" marginBlock={"8px"}>
              {
                loading ? <CircularProgress color="secondary"  /> : (
                  <>
                     <TextField
                        sx={{ width: "20%", paddingRight: "16px"}}
                        id="standard-required"
                        label="Spend:"
                        value={singleAdvertisingAccountContent?.[0]?.mtd_data?.cost ? formatCurrency(singleAdvertisingAccountContent?.[0]?.mtd_data?.cost) : "$0.00"}
                        variant="standard"
                        color="secondary"
                        disabled
                      />
                      <TextField
                        sx={{ width: "20%", paddingRight: "16px"}}
                        id="standard-required"
                        label="Impressions:"
                        value={singleAdvertisingAccountContent?.[0]?.mtd_data?.impressions || 0}
                        variant="standard"
                        color="secondary"
                        disabled
                      />
                      <TextField
                        sx={{ width: "20%", paddingRight: "16px"}}
                        id="standard-required"
                        label="Clicks:"
                        value={singleAdvertisingAccountContent?.[0]?.mtd_data?.clicks || 0}
                        variant="standard"
                        color="secondary"
                        disabled
                      />
                  </>
                )
              }
            </Stack>
            <Stack direction="row" justifyContent="space-between" gap={4}>
              <Box width="40%">
                <Typography style={{ fontSize: "16px" }}>Link Asset</Typography>
                <Stack direction="column" gap={"16px"} marginTop={"16px"}>
                  {linked_assets && linked_assets.map((asset:any, index:any) => (
                    <Stack key={index} direction="row" gap={"16px"}>
                      <TextField
                        sx={{ width: "100%" }}
                        required
                        id={`outlined-required-action-${index}`} // Unique ID for each field
                        label="Action type"
                        defaultValue={"website"}
                        size="small"
                        disabled
                      />
                      <TextField
                        sx={{ width: "100%" }}
                        required
                        id={`outlined-required-asset-name-${index}`} // Unique ID for each field
                        label="Asset name"
                        defaultValue={asset.websites__site_name} // Display the site name for the current asset
                        size="small"
                        disabled
                      />
                    </Stack>
                  ))}
                </Stack>
              </Box>
              <Box width="40%">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography style={{ fontSize: "1rem" }}>Conversion Actions</Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      border: 'none', // Removes the border
                      '&:hover': {
                        border: 'none', // Ensures no border on hover as well
                      }
                    }}
                 >
                  <ReplayOutlinedIcon color='secondary' />
                </Button>
                </Stack>
                <Stack gap={"16px"} marginTop={"16px"} direction="column">
                  <Stack direction="row" gap={"16px"}>
                  {singleAdvertisingAccountContent?.[0]?.conv_actions.length > 0 ? (
                    singleAdvertisingAccountContent?.[0]?.conv_actions?.map((action: any, index: number) => (
                    <Stack key={index} direction="column" gap={"16px"}>
                      {/* Action Name */}
                      <TextField
                        sx={{ width: "100%" }}
                        required
                        id="outlined-required"
                        label="Action name"
                        value={action?.action ? action?.action : "N/A"}
                        size="small"
                        disabled
                      />
                      {/* Action ID at Source */}
                      <TextField
                        sx={{ width: "100%" }}
                        required
                        id="outlined-required"
                        label="Action ID at source"
                        value={action?.id}
                        size="small"
                        disabled
                      />
                    </Stack>
                      ))
                    ) : (
                      <Typography fontWeight={500}>There are no Conversion Actions</Typography>
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Box width="20%">
                <Stack gap={"16px"} direction="row" alignItems="center" justifyContent="space-between">
                <Typography>Account Status</Typography>
                {loading === singleAdvertisingAccountContent?.[0]?.shared_uid ? ( // Check if the current row is loading
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  <>
                    <Switch
                      checked={singleAdvertisingAccountContent?.[0]?.active === true}
                      color="success"
                      onChange={() => handleStatusChange(singleAdvertisingAccountContent?.[0])} // Pass row to handler
                    />
                  </>
                )}
              </Stack>
            </Box>
            </Stack>
          </Box>
        </>
    </>
  )
}

