import {
  Autocomplete,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import ProcessListFiltersItems from "./ProcessListFiltersItems";
import CreateProcess from "../Modal/CreateProcess";
// import EditProcess from "../Modal/EditProcess";
import "./ProcessListFilters.css";

interface Props{
  ETLProcessContent: any[];
  setSelectedProcess:any;
  selectedProcess:any;
  setStatus:any;
  status:any;
  hasEditPermissions:any;
  oauthTokenHeader:any;
  setReload:any;
  selectedRow:any;
  setSelectedRow:any;
  page:number;
  setPage:any;
  searchStatus:any;
  setSearchStatus:any;
}

const ProcessListCard = ({ETLProcessContent,setSelectedProcess,setStatus,status,hasEditPermissions,oauthTokenHeader,setReload,setPage,selectedProcess,
  searchStatus,setSearchStatus
}:Props) => {

  const [openModal, setOpenModal] = useState(false);
 

  const process = ETLProcessContent.map((process:any)=> process?.name)

  // const handleOpen = () => {
  //   setOpenModal(true);
  // };

  const handleClose = () => {
    setOpenModal(false);
  };


  return (
    <>
      <Paper className="chainsFiltersWrap">
        <div style={{ padding: "16px" }}>
          {/* Heading */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
              ETL Process List
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
              Create new or edit existing ETL Processes. 
              </Typography>
            </Grid>
          </Grid>

          {/* Filter part */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                marginTop: "5px",
              }}
            >
              {/*Queue Name */}
              <Autocomplete
                  options={process ? process : []}
                  value={selectedProcess}
                  onChange={(event, newValue) => {
                    setSelectedProcess(newValue); 
                    setPage(0)// Update state with selected process
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Process Name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "300px" }}
                />
              <ProcessListFiltersItems setStatus={setStatus} status={status} setPage={setPage} setSelectedProcess={setSelectedProcess}
              ETLProcessContent={ETLProcessContent} searchStatus={searchStatus} setSearchStatus={setSearchStatus} />
            </div>
            {/* Action Buttons */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Button
                variant="outlined"
                color="secondary"
                endIcon={<EditIcon color="secondary" />}
                onClick={editHandleOpen}
                disabled={selectedRow && selectedRow.length === 1 ? false : true }
              >
                Edit Proccess
              </Button> */}
              {/* <Button
                variant="contained"
                color="secondary"
                endIcon={<AddIcon />}
                onClick={handleOpen}
                sx={{ml:2}}
              >
                Create new Proccess
              </Button> */}
               <CreateProcess openModal={openModal} onCloseModal={handleClose} hasEditPermissions={hasEditPermissions} oauthTokenHeader={oauthTokenHeader} setReload={setReload}/>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
export default ProcessListCard;
