import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { sitePagesScreenLogic } from "./logic/sitePagesScreenLogic";
import { useNavigate } from "react-router-dom";
import { useHasPageManagementPermissions } from "../../../../logic/AssetManagement/PageManagement/Permissions/useHasPageBuildingPermissions";
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { selectIsLoading, selectUserData, setDisplayErrorSnackBar, setIsLoading } from "../../../../features/userSlice";
import { WebsitePermissions } from "../../../../data/user/WebsitePermissions";
import { PageGroup, SitePage } from "../../../../data/sitePages/SitePage";
import { WebsiteWithUuid } from "../../../../data/website/Website";
import { createPageEndPointLogic } from "../../../../logic/AssetManagement/PageManagement/CreatePage/createPageEndPointLogic";
import { sitePageEndPointLogic } from "../../../../logic/AssetManagement/PageManagement/SitePages/sitePagesEndPointLogic";
import store from "../../../../store";
import SimpleBackdrop from "../../../../components/BackDrop/BackDrop";
import SitePages from "../../../../components/AssetManagement/PageManagement/SitePages/SitePages";
import ErrorPopupAccToPageStatus from "../../../../components/ErrorPopupAccToPageStatus/ErrorPopupAccToPageStatus"
import hostname from "../../../../services/api/config.host";
import { fetchWrapper } from "../../../../services/api/fetch-wrapper";

export interface SitePagesScreenProps {
    userToken: string;
    selectedSiteName: string | undefined;
    setPageRefreshStatus:any;
    pageRefreshStatus:boolean;
}

export default function SitePagesScreen({
    selectedSiteName,
    userToken,
    setPageRefreshStatus,
    pageRefreshStatus
}: SitePagesScreenProps) {

  const apiUrl: string = `${hostname}/aladdin_api/v1`;

    // All Capabilities for this screen
    const hasGetPermissions = useHasPageManagementPermissions([ModulesPermissionNames.GET_API_PAGE, ModulesPermissionNames.GET_API_PAGES]);
    const hasEditPermissions = useHasPageManagementPermissions([
        ModulesPermissionNames.PUT_API_PAGE,
        ModulesPermissionNames.PUT_API_PAGES,
        ModulesPermissionNames.GET_API_PAGE_GROUPS,
        ModulesPermissionNames.PUT_API_PAGE_GROUPS,
        ModulesPermissionNames.GET_API_THEME,
        ModulesPermissionNames.PUT_API_THEME,
        ModulesPermissionNames.POST_API_PAGE,
        ModulesPermissionNames.POST_API_PAGES,
        ModulesPermissionNames.POST_API_PAGE_GROUPS,
    ]);

    const userWebsitePermissions = useSelector(selectUserData)?.data?.website_permissions as WebsitePermissions | undefined;
    const oauthTokenHeader = userToken;
    const navigate = useNavigate();
    const [createPageResponse, setCreatePageResponse] = useState() as any;
    const [pageContentAfterCreate, setPageContentAfterCreate] = useState() as any;
    const [pageGroups, setPageGroups] = useState<PageGroup[] | undefined>();
    const [openCreateNewPage, setOpenCreateNewPage] = useState<boolean>(false);
    const [errorAccToPageStatus, setErrorAccToPageStatus] = useState([])
    const [errorAccToPageStatusLength, setErrorAccToPageStatusLength] = useState(false)
    const [retry, setRetry] = useState(false)
    const [open, setOpen] = useState(true);
    const [,setPageStructureDesign] = useState()
    const [,setPageContent] = useState()
    const [,setPageDictionary] = useState();
    const [duplicatePageResponse,setDuplicatePageResponse] = useState();
    const [refresh , setRefresh] = useState(false);
    const [deletePageData, setDeletePageData] = useState<string>("");
    const [selectedOptionGroup, setSelectedOptionGroup] = useState([]) as any; // Selected option for group
    const [selectedOptionEdit, setSelectedOptionEdit] = useState<string>(""); // Selected option for edit
    const [selectedOptionStatus, setSelectedOptionStatus] = useState<string>("") // Selected option for status
    const [pageGroupForAssign, setPageGroupForAssign] = useState([])
    const [setSelectedPageForAssign] = useState([])
    const [pageDetails, setPageDetails] = useState([]);
    const [isMetaUpdated , setIsMetaUpdated] = useState(false);
    const pageUid = '' ;

    const selectedSite = useMemo(() => {
        return userWebsitePermissions?.find((website) => Object.values(website)[0].site_name === selectedSiteName) as WebsiteWithUuid ?? null;
        // eslint-disable-next-line
    }, [selectedSiteName]);
    const siteUid = selectedSite && Object.keys(selectedSite)[0] ; 

    const [sitePages, setSitePages] = useState<SitePage>() as any;
    const isLoading = useSelector(selectIsLoading);
    
    useEffect(() => {
        if (createPageResponse) {
            const siteUid = Object.keys(selectedSite)[0];
            createPageEndPointLogic.getPageContentAfterCreate(siteUid, createPageResponse?.shared_uid, oauthTokenHeader,
                setPageContentAfterCreate, setIsLoading, trackPromise);
        }
        // eslint-disable-next-line
    }, [createPageResponse])

    useEffect(() => {
        if (hasGetPermissions) {
          const fetchData = async () => {
            try {
              const siteUid = Object.keys(selectedSite)[0];
              let nextUrl;
              const localStorageKey = `sitePages_${siteUid}_${oauthTokenHeader}`;
              const storedPages = localStorage.getItem(localStorageKey);
              if (selectedSite && storedPages && createPageResponse === undefined && duplicatePageResponse === undefined && refresh===false && pageRefreshStatus===false && deletePageData.length===0 && isMetaUpdated === false) {
                  // If data exists in local storage, use it instead of calling the API
                  const combinedPages = JSON.parse(storedPages);
                  setSitePages(combinedPages);
                } else {
                  await sitePageEndPointLogic.getSitePages(siteUid, oauthTokenHeader, (data: any) => {
                 let currentPages=data?.records;
                 localStorage.setItem(`${localStorageKey}`, JSON.stringify(currentPages));
                setSitePages(data?.records)
                nextUrl = data?.next
                },setOpen, setErrorAccToPageStatus, trackPromise, setRetry, setIsLoading);
                if(nextUrl!==undefined){
                  while (nextUrl!==null) {
                    try {
                      // eslint-disable-next-line
                      await sitePageEndPointLogic.getNextPages(oauthTokenHeader, nextUrl, setIsLoading, setOpen).then((data:any)=> {
                      if (data?.reason || data?.reason === "Success.") {
                        nextUrl=data?.data?.next;
                        let  prevPages =  localStorage.getItem(`${localStorageKey}`) as any;
                        prevPages = prevPages ? JSON.parse(prevPages) : [];
                        data =  data?.data?.records;
                        const combinedPages = [...prevPages, ...data];                 
                        localStorage.setItem(`${localStorageKey}`, JSON.stringify(combinedPages));
                        setSitePages(combinedPages)
                      }
                    }
                     )
                    } catch (e) {
                      store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
                    }
                   }
                   setPageRefreshStatus(false);
                }

                }
              
            } catch (e) {
              setDisplayErrorSnackBar({ state: true, message: e, type: 'error' });
            }finally{
              store.dispatch(setIsLoading(false));
              setDuplicatePageResponse(undefined);
              setRefresh(false);
              setDeletePageData("")
              setIsMetaUpdated(false);
            }
          };
          fetchData();
        } else {
          store.dispatch(setDisplayErrorSnackBar({ message: 'User Cant get pages', type: 'error' }));
        }
        // eslint-disable-next-line
      }, [selectedSite, retry , createPageResponse , duplicatePageResponse , refresh , deletePageData?.length>0 ,pageRefreshStatus , isMetaUpdated]);         
   

    useEffect(() => {
        if (pageContentAfterCreate) {
            sitePagesScreenLogic.navigateToPageBuilding(navigate, selectedSiteName, createPageResponse, pageContentAfterCreate);
        }
        // eslint-disable-next-line
    }, [pageContentAfterCreate]);

    useEffect(() => {
        if (pageGroups && pageGroups?.length > 0) {
            setOpenCreateNewPage(true);
        }
    }, [pageGroups])


    const handelCreatePagePopUp = () => {
        if (hasEditPermissions) {
            sitePageEndPointLogic.getPageGroups(Object.keys(selectedSite)[0], oauthTokenHeader, setPageGroups, trackPromise, setIsLoading);

        } else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'User Cant Create pages', type: 'error' }));
        }
    }
    const pageGroupApiCall = () => {
        if (hasEditPermissions) {
            sitePageEndPointLogic.getPageGroups(Object.keys(selectedSite)[0], oauthTokenHeader, setPageGroupForAssign, trackPromise, setIsLoading);

        } else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'User Cant Create pages', type: 'error' }));
        }
    }

    const pageSharedUids = pageDetails.map((item:any) => item?.shared_uid);
    const assignPageGroup = async (data:any) => {
      try {
        // Map each page_shared_uid to a fetch request
        const requests = pageSharedUids?.map((pageSharedUid) => {
          const response = fetchWrapper.put(`${apiUrl}/asset_management/pages/${Object.keys(selectedSite)[0]}/${pageSharedUid}/`, data, oauthTokenHeader);
          
            if (!response) {
              throw new Error(`Failed to fetch: ${response}`);
            }
            return response;
          
        });
  
        // Execute all fetch requests in parallel and wait for all of them to complete
        const responses = await Promise.all(requests);
        responses.forEach((response) => {
          // Handle each response data as needed
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

   const handleCreatePage = (pageTitle: string, pageName: string, pageGroup: string, setShowErrorDialog: any) => {
        sitePagesScreenLogic.handleCreatePage(pageTitle, pageName, pageGroup, setIsLoading, trackPromise, setCreatePageResponse, selectedSite, oauthTokenHeader, setShowErrorDialog);
    }

    useEffect(() => {
      if(errorAccToPageStatus.length === 0){
        setErrorAccToPageStatusLength(false)
      }else{
        setErrorAccToPageStatusLength(true)
      }
    }, [errorAccToPageStatus])

    const sitePagesProps = {
        sitePages,
        selectedSite,
        handleCreatePage,
        pageGroups,
        handelCreatePagePopUp,
        openCreateNewPage,
        setOpenCreateNewPage,
        hasEditPermissions,
        oauthTokenHeader,
        duplicatePageResponse,
        setDuplicatePageResponse,
        setRefresh,
        deletePageData,
        setDeletePageData,
        setSelectedOptionGroup, // Selected option for group
        setSelectedOptionEdit, // Selected option for edit
        setSelectedOptionStatus, // Selected option for status
        selectedOptionGroup, // Selected option for group
        selectedOptionEdit, // Selected option for edit
        selectedOptionStatus, // Selected option for status
        isMetaUpdated,
        setIsMetaUpdated,
        pageGroupApiCall,
        pageGroupForAssign,
        setSelectedPageForAssign,
        setPageDetails,
        pageDetails,
        assignPageGroup
    }

    const ErrorPopupStatusProps = {
        pageUid,
        setPageStructureDesign,
        setPageDictionary,
        setIsLoading,
        setPageContent,
        oauthTokenHeader,
        selectedSite,
        trackPromise,
        siteUid,
        errorAccToPageStatus,
        setErrorAccToPageStatus,
        setRetry,
        setOpen,
        open
    }

 

    return (
        <>
            {isLoading && <SimpleBackdrop />}
            {errorAccToPageStatusLength && <ErrorPopupAccToPageStatus {...ErrorPopupStatusProps} />}
            {userWebsitePermissions && (sitePages && Object.values(sitePages)?.length > 0) && (selectedSiteName) ? <SitePages {...sitePagesProps} /> : ''}
        </>
    )
}
