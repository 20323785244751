import { NavigateFunction } from "react-router-dom";
import { WebsiteWithUuid } from "../../../../../data/website/Website";
import { createPageEndPointLogic } from "../../../../../logic/AssetManagement/PageManagement/CreatePage/createPageEndPointLogic";

const handleCreatePage = (pageTitle: string, pageName: string, pageGroup: string, setIsLoading: any, trackPromise: any, setCreatePageResponse: any,
    selectedSite: WebsiteWithUuid, oauthTokenHeader: string, setShowErrorDialog: any) => {
    // createPage api
    if (pageTitle) {
        const data = {
            "data": {
                "page_group": pageGroup,
                "post_type": "page",
                "post_title": pageTitle,
                "post_name": pageName,
                "post_status": "draft",
                "post_content": ""
            }
        }
        const siteUid = Object.keys(selectedSite)[0];
        createPageEndPointLogic.createPage(siteUid, data, setIsLoading, trackPromise, setCreatePageResponse, oauthTokenHeader, setShowErrorDialog);
    }
}

const navigateToPageBuilding = (navigate: NavigateFunction, selectedSiteName: string | undefined, createPageResponse: any, pageContentAfterCreate: any) => {
    navigate(
        `/assets/page_management/${selectedSiteName}/${pageContentAfterCreate?.post_name}/edit`,
        {
            state:
            {
                previewLink: `${createPageResponse?.preview_link}`,
                pageUid: `${createPageResponse?.shared_uid}`,
                pageTitle: `${pageContentAfterCreate?.post_title}`,
                postStatus: 'draft',
            }
        }
    );
}

// eslint-disable-next-line
export const sitePagesScreenLogic = {
    handleCreatePage,
    navigateToPageBuilding
};
