// LogoutScreen.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSignedOut } from '../../features/userSlice';
import Cookies from 'js-cookie';
import { setDisplayErrorSnackBar } from '../../features/userSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const LogoutScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    try {
      Cookies.remove('RT');
      dispatch(setSignedOut());
      localStorage.removeItem('signin');
      navigate("/login");
    } catch (error) {
      dispatch(setDisplayErrorSnackBar({ state: true, message: error }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <p>Logout successful! Redirecting...</p>
      )}
    </div>
  );
};

export default LogoutScreen;
