import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./LogResponse.css";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { AccountCircle } from "@mui/icons-material";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ETLLogsEndPointLogic } from "../../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";
import SharedStateContext from "../../../../SharedStateProvider";
import { Stack } from "@mui/system";
interface props {
  singleLog:any;
  error:string;
  setRefresh:any;
  token:string;
}

export default function LogResponse({singleLog,error,setRefresh,token}:props) {

  const navigate = useNavigate();
  const [open,setOpen] = useState(true);
  const [runStatus,setRunStatus] = useState("") as any;
  const [snackbarOpen,setSnackbarOpen] = useState(true);
  const [loading,setLoading] = useState(false);
  const {  setLoad } = useContext(SharedStateContext);


  const handleRetry =() => {
    setRefresh(true);
  }

  const handleClose = () => {
    setOpen(false);
    navigate(`/`)
  }
  const selectedProcessId = [singleLog[0]?.process_id];

  const runProcess = async () => {
    const ids = {"ids": selectedProcessId};
    try {
      if(selectedProcessId){
        setLoading(true);
        await ETLLogsEndPointLogic.runProcess(token,ids,setRunStatus);
      }
      else{
        setRunStatus('User don`t have permission')
      }
    } catch (error) {
      setRunStatus(error);
    }
  }

  const handleRunProcess = async () => {
    await runProcess().then(()=>{
      setRunStatus("success")
      setLoading(false);
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/admin/processes/etl-process-log");
      }, 1000);  
      setLoad(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
    {error && Object.values(error).length >=1 ? <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
         id="alert-dialog-title">
          {error}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Failed to Get
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
          <Button onClick={handleClose} autoFocus color='secondary'>
            Back
          </Button>
          <Button onClick={handleRetry} autoFocus color='secondary'>
            Retry
          </Button>
        </DialogActions>
      </Dialog> : <Paper className="chainsFiltersWrap">
        <div style={{ padding: "16px" }}>
          {/* Heading */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Log Response
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                Info
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="RunId:"
                label="RunId:"
                defaultValue={singleLog[0]?.run_id}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="ProcessName"
                label="Process Name"
                defaultValue={singleLog[0]?.process_name ? singleLog[0]?.process_name : "N/A"}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="Start"
                label="Start"
                defaultValue={new Date(singleLog[0]?.start_time).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="End"
                label="End"
                defaultValue={new Date(singleLog[0]?.end_time).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                Results
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="Created"
                label="Created:"
                defaultValue={singleLog[0]?.record_created}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="Updated"
                label="Updated:"
                defaultValue={singleLog[0]?.record_updated}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="Fetched"
                label="Fetched:"
                defaultValue={singleLog[0]?.record_total}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"flex-end"}>
              <Stack>
              <Typography
                mb={1}
                variant="h5"
                sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
              >
                Status:
              </Typography>
              {singleLog[0]?.status === "SUCCESS" ? (
                <Alert
                  iconMapping={{
                    success: (
                      <CheckCircleOutlineOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "#1E4620" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    backgroundColor: "#EDF7ED",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="success"
                >
                  Success
                </Alert>
              ) : singleLog[0]?.status === "FAILURE" ? (
                <Alert
                  iconMapping={{
                    error: (
                      <CancelOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "#5F2120" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    backgroundColor: "#FDEDED",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="error"
                >
                  Failure
                </Alert>
              ) : singleLog[0]?.status === "PENDING" ? (
                <Alert
                  iconMapping={{
                    error: (
                      <CancelOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "#5F2120" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    backgroundColor: "#FDEDED",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="error"
                >
                  Failure
                </Alert>
              ) : singleLog[0]?.status === "WARNING" ? (
                <Alert
                  iconMapping={{
                    warning: (
                      <ErrorOutlineOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "#5F2120" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    backgroundColor: "#FDEDED",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="warning"
                >
                  Warning
                </Alert>
              ) : singleLog[0]?.status === "CANCELLED" ? (
                <Alert
                  iconMapping={{
                    warning: (
                      <ErrorOutlineOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "#5F2120" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    backgroundColor: "#FDEDED",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="warning"
                >
                  CANCELLED
                </Alert>
              ) : (
                <Alert
                  iconMapping={{
                    info: (
                      <AutorenewOutlinedIcon
                        fontSize="inherit"
                        style={{ color: "rgba(123, 31, 162, 1)" }}
                      />
                    ),
                  }}
                  sx={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "6px",
                    color: "rgba(123, 31, 162, 1)",
                    borderColor: "rgba(156, 39, 176, 0.5)",
                    backgroundColor: "rgba(156, 39, 176, 0.12)",
                    ".MuiAlert-icon": {
                      marginRight: "5px",
                    },
                  }}
                  variant="outlined"
                  severity="info"
                >
                  Running
                </Alert>
              )}
              </Stack>
              <Button variant="contained" color="secondary" endIcon={<PlayCircleFilledIcon/>} onClick={handleRunProcess}>{loading ? <><SimpleBackdrop/> Run Process Now</> : "Run Process Now"}</Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="LogMessage"
                label="Log Message:"
                defaultValue={singleLog[0]?.full_log}
                variant="outlined"
                multiline
                color="secondary"
                fullWidth
                minRows={5}
                maxRows={5}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                Additional Info
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="RunBy"
                label="Run By:"
                defaultValue={singleLog[0]?.run_by}
                fullWidth
                variant="standard"
                color="secondary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="RunMethod"
                label="Run Method:"
                defaultValue={singleLog[0]?.run_method}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="CreatedOn"
                label="Created On:"
                defaultValue={singleLog[0]?.created ? new Date(singleLog[0]?.created).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }) : "N/A"}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="UpdatedOn:"
                label="Updated On"
                defaultValue={singleLog[0]?.modified ? new Date(singleLog[0]?.modified).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }) : "N/A"}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      </Paper>}
      {runStatus === "success" && <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={"success"} sx={{ width: "100%" }}>
          Run Process Successfully
        </Alert>
      </Snackbar>}
    </>
  );
}
