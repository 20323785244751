import { Button, Card, CardContent, CircularProgress, Grid, IconButton, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material'
import ManualExpenseComment from './ManualExpenseComment';
import ManualExpenseDocs from './ManualExpenseDocs';
import { trackPromise } from 'react-promise-tracker';
import { ManualExpenseEndPointLogic } from '../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

interface props {
  fileBase64: any,
  setFileBase64: any,
  setFileName: any,
  fileName: any,
  singleExpneseShareUID: any;
  oauthTokenHeader: string;
  siteNames: any;
  isCardVisible: any;
  setIsCardVisible: any
}

export const AdditionalContentManualExpense = ({ fileBase64, setFileBase64, setFileName, fileName, singleExpneseShareUID, oauthTokenHeader, siteNames, isCardVisible, setIsCardVisible }: props) => {

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const [isCardVisible, setIsCardVisible] = useState(false);

  const handleFileUpload = () => {
    setLoading(true);
    // Check if there are files to upload
    if (fileBase64 && fileBase64.length > 0) {
      // Use Promise.all to wait for all uploads to complete
      Promise.all(
        fileBase64.map((file: any) => {
          // Create file data for each file
          const singleFileData = {
            "object_type": "manual_adjustment_expenses",
            "file_name": file.name,
            "file": file.base64
          };

          // Upload each file one by one
          return ManualExpenseEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, singleExpneseShareUID, trackPromise);
        })
      )
        .then(() => {
          fetchUploadedFiles(); // Fetch the updated file list after all uploads
          setFileBase64([]); // Clear the files after upload
          setIsCardVisible(false);
        })
        .finally(() => {
          setLoading(false); // Stop the loading spinner after uploads are done
        })
        .catch((error: any) => {
          console.error("Error uploading files: ", error);
          setLoading(false); // Stop loading in case of error
          throw new Error("Error uploading files: ", error);
        });
    } else {
      setLoading(false); // Stop loading if there are no files
    }
  };


  const fetchUploadedFiles = () => {
    ManualExpenseEndPointLogic.getFiles(oauthTokenHeader, singleExpneseShareUID, trackPromise, setUploadedFiles)
      .catch((error: any) => {
        console.error("Error fetching uploaded files: ", error);
      });
  }

  useEffect(() => {
    fetchUploadedFiles();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container>
        <Grid xs={6} >
          <Typography variant='body1' mb={2}>Comments</Typography>
          <ManualExpenseComment singleExpneseShareUID={singleExpneseShareUID} oauthTokenHeader={oauthTokenHeader} />
        </Grid>
        <Grid xs={6} sx={{ paddingLeft: "32px" }}>
          <Typography mb={2} variant='body1'>Docs and Receipts</Typography>
          <ManualExpenseDocs setFileBase64={setFileBase64} setFileName={setFileName} fileName={fileName} setIsCardVisible={setIsCardVisible}
            isCardVisible={isCardVisible} fileBase64={fileBase64} />
          <Button variant='contained' color="secondary" sx={{ marginBlock: 1 }} onClick={handleFileUpload} disabled={fileBase64.length === 0}> {loading ? <CircularProgress size={24} color="inherit" /> : 'Upload Files'}</Button>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Uploaded Files
                </Typography>
                {uploadedFiles.length > 0 ? (
                  <List
                    sx={{
                      maxHeight: '200px', // Set max height for scrolling
                      overflowY: 'auto',  // Enable scrolling if the content exceeds the height
                    }}
                  >
                    {uploadedFiles.filter(file => file.file).map((file, index) => (
                      <>
                        <ListItem
                          key={index}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            marginBottom: '8px',
                            padding: '8px 16px',
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <ListItemText
                               primary={
                                <Link
                                  to={file.file} // Set the href to the file URL
                                  target="_blank" // Open the link in a new tab
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {file.file.split("/").pop()} {/* File name from URL */}
                                </Link>
                              }
                              secondary={`Completed`}
                              primaryTypographyProps={{ style: { fontWeight: 500 } }}
                              sx={{ marginLeft: '8px', flexGrow: 1 }}
                            />
                            <IconButton edge="end" disabled>
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            value={100} // Full progress to indicate completion
                            sx={{
                              width: '60%',
                              marginTop: '4px',
                              height: '4px',
                              backgroundColor: '#e0e0e0',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#9c27b0', // Customize the color of the completed progress
                              },
                            }}
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No files uploaded yet.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
