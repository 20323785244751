import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from "@mui/material";

import "./LogFilters.css";

interface props{
  logs:any;
  setSelectedStatus:any;
  selectedStatus:any;
  setSelectedMethod:any;
  selectedMethod:any;
}

export default function LogFiltersItems({logs,selectedStatus,setSelectedStatus,setSelectedMethod,selectedMethod}:props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>("bottom-start"); // Set default placement
  const [selectedContentIndex, setSelectedContentIndex] =
    React.useState<number>(0);

    const uniqueStatuses = Array.from(new Set(logs.map((log: any) => log.status)));
    const uniqueMethods = Array.from(new Set(logs.map((log: any) => log.run_method)));


  const [clicked, setClicked] = useState<boolean[]>([false, false, false, false]);

  const handleStatusOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
  
    setSelectedStatus((prevSelected: any[] = []) => {
      const newSelected = prevSelected.includes(value)
        ? prevSelected.filter((item: any) => item !== value)
        : [...prevSelected, value];
      return newSelected;
    });
    // setStatusSelected(true);
  };

  const handleMethodOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSelectedMethod((prevSelected: any[] = []) => {
      const newSelected = prevSelected.includes(value)
        ? prevSelected.filter((item: any) => item !== value)
        : [...prevSelected, value];
      return newSelected;
    });
    // setStatusSelected(true);
  };

  const handleClearFilter = () => {
    setSelectedStatus([]);
    setSelectedMethod([]);
  }
  useEffect(() => {
    if (!open) {
      setSelectedStatus((prevSelected: any[] = []) => [...prevSelected]);
      setSelectedMethod((prevSelected: any[] = []) => [...prevSelected]);
    }
    //eslint-disable-next-line
  }, [open]);


  const content = [
  [],
    <div className="tabDiv">
      <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>
        Select Status
      </Typography>
      <FormGroup>
      {(uniqueStatuses as string[]).map((status: string, index: number) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={selectedStatus.includes(status)}
              onChange={handleStatusOptionChange}
              value={status}
              color="secondary"
            />
          }
          label={status} // Display the status
        />
      ))}
      </FormGroup>
    </div>,
    <div className="tabDiv">
    <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>
    Select a Source 
    </Typography>
    <RadioGroup>
  {(uniqueMethods as string[]).map((method: string, index: number) => (
    <FormControlLabel
      key={index}
      // checked={selectedMethod.includes(method)}
      // value={method}
      control={<Checkbox
        checked={selectedMethod.includes(method)}
        onChange={handleMethodOptionChange}
        value={method}
        color="secondary"
      />}
      label={method} // Display the method as the label
    />
  ))}
</RadioGroup>
  </div>,
  ];

  const handleClick = (newPlacement: PopperPlacementType, index: number) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (anchorEl === event.currentTarget && open) {
      setOpen(false);
      setClicked([false, false, false, false]);
    } else {
      setAnchorEl(event.currentTarget);
      setOpen(true);
      setPlacement(newPlacement);
      setSelectedContentIndex(index);
      setClicked(prevClicked => prevClicked.map((_, i) => i === index));
    }
  };

    const popperRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (popperRef.current && !popperRef.current.contains(event.target as Node) && !anchorEl?.contains(event.target as Node)) {
         setOpen(false);
          setClicked([false, false, false, false]);
        }
      }; 
    
      if (open) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [open, anchorEl]);

  return (
    <Box sx={{ width: 500 }}>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={popperRef}>
              {content[selectedContentIndex]}
            </Paper>
          </Fade>
        )}
      </Popper>
      
      {[ "Start Date","Status","Method"].map((letter, index) => (
        <Button
        sx={{ '&:hover': { backgroundColor: 'rgba(156, 39, 176, 0.04)' } }}
        key={index}
        onClick={handleClick('bottom-start', index)}
        disabled={index===0}
      >
        <span style={((index === 1 && selectedStatus && selectedStatus?.length>=1) || (index === 2 && selectedMethod && selectedMethod?.length>=1)) ? {color: "#9C27B0"} : {color: clicked[index]? "#9C2780" : "#000000DE"}}>
          {letter}
        </span>  
        {/* Added span tag to display the count of selected options */}
        {(index === 1 && selectedStatus && selectedStatus.length>=1)? (
          <span style={{color: "#9C27B0"}}>+{selectedStatus?.length}</span>
        ):(index === 2 && selectedMethod && selectedMethod.length>=1) ? (
          <span style={{color: "#9C27B0"}}>{`+${selectedMethod?.length}`}</span>
        ) : (
            <KeyboardArrowDownIcon style={((index === 1 && selectedStatus?.length>=1) || (index === 2 && selectedMethod?.length>=1)) ? {color: "#9C27B0"} : {color: clicked[index]? "#9C2780" : "#000000DE"}} />
        )}
      </Button>
      ))}
      <Button
        sx={{
          textTransform: "capitalize",
          fontWeight: 500,
          color: "#000000DE",
          "&:hover": {
            color: "#9C27B0",
            backgroundColor: "rgba(156, 39, 176, 0.04)",
          },
        }}
        onClick={handleClearFilter}
      >
        Clear Filter
      </Button>
    </Box>
  );
}
