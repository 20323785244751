import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import "./CustomTableActivityNotes.css"
import { CircularProgress } from '@mui/material';

interface Props {
  oauthTokenHeader:any
  singleNoteShareUID:any
  singleActivityNotesContent:any
  loading:any
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


export default function ChipsArray({oauthTokenHeader,singleNoteShareUID,singleActivityNotesContent,loading}:Props) {
  const key = Object.keys(singleActivityNotesContent)?.[0];
  return (
    <Paper sx={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, boxShadow: "none", }} component="ul">
      {loading ? (
        <CircularProgress size={24} color="secondary" />
      ) : (
        <>
          {singleActivityNotesContent[key]?.[0]?.labels && singleActivityNotesContent[key]?.[0]?.labels?.length > 0 ? (
            singleActivityNotesContent[key]?.[0]?.labels?.map((data: any, index: number) => {
              let icon;
              return (
                <ListItem key={index}>
                  <Chip
                    icon={icon}
                    label={data}
                    color="secondary"
                  />
                </ListItem>
              );
            })
          ) : (
            <span>There are no labels</span>
          )}
        </>
      )}
    </Paper>
  );
}
