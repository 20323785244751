import { useHasAdvertisingAccountsPermission } from "../../../logic/PaidTrafficManagement/AdvertisingAccounts/Permissions/useHasAdvertisingAccountsPermission";
import { ModulesPermissionNames } from '../../../data/menu/ModulesPermissions';
import CardFilters from "../../../components/Paid Traffic/Advertising-Accounts/CardFilters/CardFilters";
import CustomTableNewFeatures from "../../../components/Paid Traffic/Advertising-Accounts/CustomTableNewFeatures/CustomTableNewFeatures";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { advertisingAccountsEndPointLogic } from "../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import SimpleBackdrop from "../../../components/BackDrop/BackDrop";
import ErrorPopupAccToPageStatus from "../../../components/ErrorPopupAccToPageStatus/ErrorPopupAccToPageStatus"
import { Box } from "@mui/system";
import { setIsLoading } from '../../../features/userSlice';
import store from "../../../store";

interface TrafficSourceData {
  post_title:string
  shared_Uid:string
  post_type:string
  account_id:string
  traffic_source:any
}
interface adAccountDetails{
  data: {}
}

interface adAccount {
  account_name: string;
  traffic_source: string;
  shared_uid: string;
  conv_actions: [];
  linked_assets:any;
  active:boolean
};

export default function AdvertisingAccountsScreen({ userToken ,response }: { userToken: any , response : any }) {
  const hasGetPermissions = useHasAdvertisingAccountsPermission([
    ModulesPermissionNames.GET_API_AD_ACCOUNT,
    ModulesPermissionNames.GET_API_AD_ACCOUNTS,
    ModulesPermissionNames.GET_API_SITE_ACCOUNT,
  ]);
 /*  const hasEditPermissions = useHasAdvertisingAccountsPermission([
    ModulesPermissionNames.POST_API_AD_ACCOUNTS,
    ModulesPermissionNames.PUT_API_AD_ACCOUNT,
    ModulesPermissionNames.PUT_API_SITE_ACCOUNT,
  ]); */

  const oauthTokenHeader = userToken
  const [isLoading, setIssLoading] = useState(false);
  const [advertisingAccounts, setAdvertisingAccounts] = useState<any[]>([]);
  const [errorAccToPageStatus, setErrorAccToPageStatus] = useState([]);
  const [errorAccToPageStatusLength, setErrorAccToPageStatusLength] = useState(false)
  const [selectedOptionSource, setSelectedOptionSource] = useState<string[]>([]);
  const [selectedOptionAsset, setSelectedOptionAsset] = useState<string[]>([]);
  const [selectedOptionTerritory, setSelectedOptionTerritory] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>(null)
  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [selectedAdvertisementSource, setSelectedAdvertisementSource] = useState(null);
  const [selectedTerritory, setSelectedTerritory] = useState(null);
  const [trafficSources, setTrafficSources] = useState([]);
  const [ isRowTrue, setIsRowTrue] = useState(false)
  const [ rowId, setRowId] = useState("")
  const [isEditBtnEnabled, setIsEditBtnEnabled] = useState<boolean>(true)
  const [trafficSourceData, setTrafficSourceData] = useState<TrafficSourceData[]>([])
  const [open, setOpen] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [singleAdAccountData, setSingleAdAccountData] = useState<adAccount[]>([])
  const [rowIndexNumber, setrowIndexNumber] = useState<number>(0);
  const dispatch = useDispatch();
  const [reloadAfterToggleEdit, setReloadAfterToggleEdit] = useState(false)
  const [accountUpdateStatus, setAccountUpdateStatus] = useState([])
  const [isCheckboxCheckedArray, setIsCheckboxCheckedArray] = useState<boolean[]>([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const selectedSite = ""
  const setRetrieveFailed = ""
  const [/* adAccountDetails */ , setAdAccountDetails] = useState<adAccountDetails[]>([]);

  // Filtering out the matching shared_uid
  const filteredTrafficSourceRetrieveData = trafficSources
    .filter((source:any) => source.name === trafficSourceData[0]?.post_type)
    .map((source:any) => source.shared_uid);
  const filteredTrafficSourceRetrieveDataForEdit = trafficSources
    .filter((source:any) => source.name === trafficSourceData[0]?.traffic_source)
    .map((source:any) => source.shared_uid);

  const tableHeadings = {
    "account_name": "Account name",
    "account_id": "ID",
    "active": "Sync data",
    "traffic_source": "Source",
    "industry": "Industry",
    "territory": "Territory",
    "modified": "Last edited",
    "modified_by" : "Edited By",
    "more_info": "More info"
  }

  useEffect(() => {
    const fetchAdAccountData = async () => {
      setIssLoading(true);
      try {
        if (hasGetPermissions) {
          await advertisingAccountsEndPointLogic.getAdvertisingAccountsContent(
            oauthTokenHeader,
            setAdvertisingAccounts,
            setErrorAccToPageStatus,
            dispatch
          );
        } else {
          throw new Error("User doesn't have permissions to fetch advertising accounts data");
        }
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      } finally {
        setIssLoading(false);
      }
    };
   fetchAdAccountData();
  }, [oauthTokenHeader, dispatch, hasGetPermissions, reloadAfterToggleEdit]);

  const getTrafficSource = async () => {
    try {
      if (hasGetPermissions) {
        await advertisingAccountsEndPointLogic.getTrafficSource(oauthTokenHeader, setTrafficSources)
      }
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  }

   const getAdAccountDetails = async () => {
    store.dispatch(setIsLoading(true));

     if (hasGetPermissions) {
       try {
     advertisingAccountsEndPointLogic.getAdAccountDetails(filteredTrafficSourceRetrieveData[0],trafficSourceData[0]?.post_title,setIsLoading,setRetrieveFailed,setAdAccountDetails,oauthTokenHeader)
     
   } catch (error) {
     console.error("Error fetching advertising accounts:", error);
   }
   /* store.dispatch(setIsLoading(false)); */
 }
   }
   const getAdAccountDetailsForEdit = async () => {
    store.dispatch(setIsLoading(true));

     if (hasGetPermissions) {
       try {
     advertisingAccountsEndPointLogic.getAdAccountDetails(filteredTrafficSourceRetrieveDataForEdit[0],trafficSourceData[0]?.account_id,setIsLoading,setRetrieveFailed,setAdAccountDetails,oauthTokenHeader)
     
   } catch (error) {
     console.error("Error fetching advertising accounts:", error);
   }
   /* store.dispatch(setIsLoading(false)); */
 }
   }

  const fetchAdAccountData = async () => {
    try {
      setIssLoading(true);
      if (hasGetPermissions) {
        await advertisingAccountsEndPointLogic.getAdvertisingAccountsContent(
          oauthTokenHeader,
          setAdvertisingAccounts,
          setErrorAccToPageStatus,
          dispatch
        );
      } else {
        throw new Error("User doesn't have permissions to fetch advertising accounts data");
      }
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    } finally {
      setIssLoading(false);
    }
  };


const postWebsiteToAcc = async (data:any) => {
  try {
    await advertisingAccountsEndPointLogic.postWebToAcc(
      oauthTokenHeader,
      data,
      singleAdAccountData[0]?.shared_uid
    );
  } catch (error) {
    console.error('Error fetching advertising accounts:', error);
  }
}

  const fetchSingleAdAccountData =  useCallback(async() => {
    try {
      store.dispatch(setIsLoading(true));
      if (hasGetPermissions && rowId) {
        await advertisingAccountsEndPointLogic.getSingleAdvertisingAccount(
          oauthTokenHeader,
         setSingleAdAccountData,
         setErrorAccToPageStatus,
         setOpen,
         rowId,
       )
      }
      } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
    store.dispatch(setIsLoading(false));
  }, [hasGetPermissions, oauthTokenHeader, rowId]);

  useEffect(() => {
    if(errorAccToPageStatus.length === 0){
      setErrorAccToPageStatusLength(false)
    }else{
      setErrorAccToPageStatusLength(true)
    }
  }, [errorAccToPageStatus])

  const siteNames = response.website_permissions?.map((permission: any) => { 
    const key = Object.keys(permission)[0];
    return {
      site: permission[key],
      key: key,
    };
   });

   const modifiedArray = advertisingAccounts.flatMap((account:any) =>
    account.linked_assets.map((asset:any) => ({
      websites__site_name: asset.websites__site_name,
      websites__shared_uid: asset.websites__shared_uid,
      start_date: asset.start_date,
      end_date: asset.end_date,
      account_name: account.account_name,
      account_id: account.account_id,
      shared_uid: account.shared_uid,
      traffic_source: account.traffic_source,
      territory: account.territory,
      industry: account.industry,
      geo: account.geo,
      active: account.active,
      modified: account.modified,
      modified_by: account.modified_by,
      conv_actions: account.conv_actions,
      mtd_data: account.mtd_data,
    }))
  );

  const ErrorPopupStatusProps = {
   setIssLoading,
    oauthTokenHeader,
    selectedSite,
    errorAccToPageStatus,
    setErrorAccToPageStatus,
    setOpen,
    open
}
  return (
    <Box>
      <CardFilters modifiedArray={modifiedArray} selectedRows={selectedRows} setSelectedRows={setSelectedRows} rows={advertisingAccounts} siteNames={siteNames} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} selectedAdvertisementSource={selectedAdvertisementSource} setSelectedAdvertisementSource={setSelectedAdvertisementSource} selectedTerritory={selectedTerritory} setSelectedTerritory={setSelectedTerritory}
       accountUpdateStatus={accountUpdateStatus} setAccountUpdateStatus={setAccountUpdateStatus} postWebsiteToAcc={postWebsiteToAcc} setIsCheckboxCheckedArray={setIsCheckboxCheckedArray} rowIndexNumber={rowIndexNumber} setrowIndexNumber={setrowIndexNumber} setRowId={setRowId} singleAdAccountData={singleAdAccountData} setSingleAdAccountData={setSingleAdAccountData} fetchSingleAdAccountData={fetchSingleAdAccountData} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} getAdAccountDetailsForEdit={getAdAccountDetailsForEdit} setTrafficSourceData={setTrafficSourceData} trafficSources={trafficSources} getTrafficSource={getTrafficSource} fetchAdAccountData={fetchAdAccountData} setReloadAfterToggleEdit={setReloadAfterToggleEdit} setIsEditBtnEnabled={setIsEditBtnEnabled} isCheckboxCheckedArray={isCheckboxCheckedArray} hasGetPermissions={hasGetPermissions} isEditBtnEnabled={isEditBtnEnabled} isRowTrue={isRowTrue} rowId={rowId} advertisingAccounts={advertisingAccounts} selectedOptionSource={selectedOptionSource}
        setSelectedOptionSource={setSelectedOptionSource} selectedOptionAsset={selectedOptionAsset} setSelectedOptionAsset={setSelectedOptionAsset} selectedOptionTerritory={selectedOptionTerritory}
         setSelectedOptionTerritory={setSelectedOptionTerritory} setSelectedAccount={setSelectedAccount} selectedAccount={selectedAccount} oauthTokenHeader={oauthTokenHeader}/>
         {errorAccToPageStatusLength && <ErrorPopupAccToPageStatus {...ErrorPopupStatusProps} />}
      {isLoading ? (
        <SimpleBackdrop />
      ) : (
        <CustomTableNewFeatures modifiedArray={modifiedArray} accountUpdateStatus={accountUpdateStatus} postWebsiteToAcc={postWebsiteToAcc} siteNames={siteNames} getAdAccountDetailsForEdit={getAdAccountDetailsForEdit} selectedRows={selectedRows} setSelectedRows={setSelectedRows} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} selectedAdvertisementSource={selectedAdvertisementSource} setSelectedAdvertisementSource={setSelectedAdvertisementSource} selectedTerritory={selectedTerritory} setSelectedTerritory={setSelectedTerritory} singleAdAccountData={singleAdAccountData} setSingleAdAccountData={setSingleAdAccountData} setReloadAfterToggleEdit={setReloadAfterToggleEdit}  hasGetPermissions={hasGetPermissions}
         isLoading={isLoading} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setAccountUpdateStatus={setAccountUpdateStatus} rowIndexNumber={rowIndexNumber} setrowIndexNumber={setrowIndexNumber} fetchSingleAdAccountData={fetchSingleAdAccountData} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} getAdAccountDetails={getAdAccountDetails} setTrafficSourceData={setTrafficSourceData} getTrafficSource={getTrafficSource} trafficSources={trafficSources} setIsCheckboxCheckedArray={setIsCheckboxCheckedArray} isCheckboxCheckedArray={isCheckboxCheckedArray} setIsEditBtnEnabled={setIsEditBtnEnabled} advertisingAccounts={advertisingAccounts} setRowId={setRowId}  oauthTokenHeader={oauthTokenHeader}
        trackPromise={dispatch} tableHeadings = {tableHeadings} fetchAdAccountData={fetchAdAccountData} selectedOptionSource={selectedOptionSource} selectedOptionAsset={selectedOptionAsset}
        selectedOptionTerritory={selectedOptionTerritory} selectedAccount={selectedAccount} setIsRowTrue={setIsRowTrue} />
      )}
    </Box>
  );
}
