import WebsiteSetting from "../../../components/AssetManagement/Website/WebsiteActions/SettingWebsite/WebsiteSetting"

function WebsiteSettingScreen() {
  return (
    <div>
        <WebsiteSetting></WebsiteSetting>
    </div>
  )
}

export default WebsiteSettingScreen