import { Autocomplete, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system';
import SearchIcon from "@mui/icons-material/Search";
import ThemeTable from './ThemeTable/ThemeTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import SharedStateContext from '../../../SharedStateProvider';
import { useHasDesignThemePermission } from '../../../../logic/AssetManagement/Permissions/useHasDesignThemePermission';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { DesignThemeEndPointLogic } from '../../../../logic/AssetManagement/Design/Theme/DesignThemeEndPointLogic';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import { DesignTemplatesEndPointLogic } from '../../../../logic/AssetManagement/Design/Templates/DesignTemplatesEndPointLogic';
import { useDispatch } from "react-redux";

function Theme({userToken,response}:any) {
    const tableHeadings = {
      "name": "Name",
      "groups_appearance": "Related Groups",
      "modified": "Last Updated",
      "Features": "Features",
      "quick_actions": "Quick Actions",
      "more_info":"More Info",
    }
    const oauthTokenHeader = userToken;
    const dispatch = useDispatch();
    const [selectedSharedUid,setSelectedSharedUid]=useState([]) as any;
    const [loading,setLoading] =useState(false);
    const [search,setSearch] = useState(false);
    const [page, setPage] = useState(0);
    const [dataShow,setDataShow] = useState(false);
    const [designThemeData, setDesignThemeData] = useState([]);
    const [designTemplatesData, setDesignTemplatesData] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [, setError] = useState("");
    const {shouldRunEffect, setLoad} = useContext(SharedStateContext)

    const siteOptions = useMemo(() => (
      response?.website_permissions?.map((siteObj: any) => {
          const uid = Object.keys(siteObj)[0];
          const siteName = siteObj[uid]?.site_name;
          return { uid, siteName };
      }) || []
  ), [response]);

  const combinedOptions = useMemo(() => (
      siteOptions.map((site: any) => ({
          label: site.siteName,
          value: site.uid,
      }))
  ), [siteOptions]);

    // Load selected website from localStorage on mount
    useEffect(() => {
      if (shouldRunEffect) {
      const savedUid = localStorage.getItem('selectedSharedUid');
      if (!savedUid) {
          setSelectedSharedUid(null);
      } else  {
          setSelectedSharedUid(savedUid);
      }
      // After handling the effect, reset the flag
      setLoad(false); // Reset load flag
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // Save selected website to localStorage whenever it changes
    useEffect(() => {
      if (selectedSharedUid) {
          localStorage.setItem('selectedSharedUid', selectedSharedUid);
      } else {
          localStorage.removeItem('selectedSharedUid');
      }
  }, [selectedSharedUid]);

  useEffect(() => {
    if (shouldRunEffect) {
        if (!selectedSharedUid) {
            setSearch(false);
            setDataShow(false);
        } else {
            handleSearch(); // Ensure handleSearch is defined
        }
        // After handling the effect, reset the flag
        setLoad(false); // Reset load flag
    }
}, [shouldRunEffect, selectedSharedUid, setLoad]);

    const handleSearch = () => {
        setSearch(true);
        setDataShow(true);
    };

    const handleSelectWebsite = (event: any, newValue: any) => {
      if (!newValue) {
          setSelectedSharedUid(null);
          setDataShow(false);
          localStorage.removeItem('selectedSharedUid'); // Clear from localStorage if cleared
      } else {
          setSelectedSharedUid(newValue.value);
      }
  };

    const hasGetPermissions = useHasDesignThemePermission([
      ModulesPermissionNames.GET_API_THEME,
      ModulesPermissionNames.GET_API_DESIGN_THEME_LIST,
      ModulesPermissionNames.GET_API_DESIGN_THEMES_DETAIL,
      ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_DETAILS,
      ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_LIST
    ]);

    const hasEditPermissions = useHasDesignThemePermission([
      ModulesPermissionNames.POST_API_THEME,
      ModulesPermissionNames.DELETE_API_THEME,
      ModulesPermissionNames.PUT_API_THEME,
      ModulesPermissionNames.POST_API_DESIGN_THEME_LIST,
      ModulesPermissionNames.DELETE_API_DESIGN_THEME_LIST,
      ModulesPermissionNames.PUT_API_DESIGN_THEME_LIST,
      ModulesPermissionNames.POST_API_DESIGN_THEMES_DETAIL,
      ModulesPermissionNames.DELETE_API_DESIGN_THEMES_DETAIL,
      ModulesPermissionNames.PUT_API_DESIGN_THEMES_DETAIL,
    ]);

    useEffect(() => {
      if(search === true && selectedSharedUid?.length !== 0) {
        const fetchDesignThemeData = async() => {
          setLoading(true);
          try {
            if (hasGetPermissions) {
              await Promise.all([
                DesignThemeEndPointLogic.getDesignTheme(oauthTokenHeader, selectedSharedUid, setDesignThemeData, dispatch),
                DesignTemplatesEndPointLogic.getDesignTemplates(oauthTokenHeader, selectedSharedUid, setDesignTemplatesData, setError)
              ]);
            } else {
              throw new Error("User doesn't have permissions to fetch data");
            }
          } catch(error:any) {
            console.error("Error fetching design data", error);
          } finally {
            setLoading(false);
          }
        };
        if(selectedSharedUid?.length !== 0 && selectedSharedUid !== null){
          fetchDesignThemeData();
        }
      }
    // eslint-disable-next-line
    }, [oauthTokenHeader, selectedSharedUid, search,dispatch]);
    

    const modifiedDesignThemeData = designThemeData.map((item:any) => {
      const labels = item.templates.flatMap((template:any) => template.labels || []);
      
      // Combine labels and groups_appearance without using Set
      const combinedLabels = [...labels, ...item.groups_appearance];
      const uniqueLabels = combinedLabels.filter((label, index) => combinedLabels.indexOf(label) === index);
  
      return {
          shared_uid: item.shared_uid,
          name: item.name,
          dictionary: item.dictionary,
          groups_appearance: item.groups_appearance,
          example_page: item.example_page,
          modified: item.modified,
          labels: uniqueLabels, // Use the unique labels array
          templates: item.templates.map((template:any) => ({
              shared_uid: template.shared_uid,
              component: template.component,
              name: template.name
          }))
      };
  });
  
  return (
    <>
        <Paper sx={{ padding: "8px 16px" }}>
        <Typography fontSize={24}>Themes</Typography>
        <Stack flexDirection={"row"} justifyContent={"space-between"} gap={4} mt={2}>
          <Autocomplete
            disablePortal
            sx={{ width: "100%" }}
            options={combinedOptions}
            getOptionLabel={(option) => option.label}
            value={combinedOptions.find((option: any) => option.value === selectedSharedUid) || null}
            onChange={handleSelectWebsite}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search site name or choose from the list"
                label="Select Website"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ marginLeft: "12px" }} />
                    </InputAdornment>
                  ),
                }}
                color="secondary"
              />
            )}
            size="small"
          />
          <Button variant="contained" color="secondary" disabled={ selectedSharedUid?.length === 0 || selectedSharedUid === null } sx={{ whiteSpace: "nowrap" }} onClick={handleSearch}>
            SELECT SITE
          </Button>
        </Stack>
        </Paper>
        <Paper sx={{ mt: 2 }}>
          <>
            {selectedSharedUid?.length !== 0 && dataShow && (
                loading ? 
                (<SimpleBackdrop />) : (
                  <ThemeTable tableHeadings={tableHeadings} modifiedDesignThemeData={modifiedDesignThemeData}  selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} combinedOptions={combinedOptions} hasGetPermissions={hasGetPermissions} hasEditPermissions={hasEditPermissions} selectedSharedUid={selectedSharedUid} oauthTokenHeader={oauthTokenHeader} setLoading={setLoading} loading={loading} search={search} setSearch={setSearch} page={page} setPage={setPage} designThemeData={designThemeData} setDesignThemeData={setDesignThemeData} setSelectedSharedUid={setSelectedSharedUid} designTemplatesData={designTemplatesData} />
            ))}
          </>
        </Paper>
    </>
  )
}

export default Theme
