import { Paper, Typography } from "@mui/material";
import React from "react";
import DeviceTypeFilterCard from "./DeviceTypeFilter/DeviceTypeFilterCard";
import DeviceTypeTable from "./DeviceTypeTable/DeviceTypeTable";

const tableHeadings = {
  device_type: "Device Type",
  adwords_value: "Adwords Value",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    device_type: "Mobile",
    adwords_value: "e",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    device_type: "Tablet",
    adwords_value: "t",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    device_type: "Desktop",
    adwords_value: "d",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const DeviceType = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Device Types List</Typography>
        <DeviceTypeFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <DeviceTypeTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default DeviceType;
