import {
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  DialogContent,
  DialogActions,
  CircularProgress
} from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useEffect, useState } from "react";
import { engagementIncentivesEndPointLogic } from "../../../../../logic/PaidTrafficManagement/EngagementIncentives/engagementIncentivesEndPointLogic";

interface BudgetControlModalProps {
  open: boolean;
  handleClose: () => void;
  exequteQueryContentQueryCreateData: any;
  userToken:string;
  startDate:any;
  setRefresh:any;
}

const BudgetControlModal = ({ open, handleClose, exequteQueryContentQueryCreateData,userToken,startDate,setRefresh }: BudgetControlModalProps) => {
  const [filteredLinks, setFilteredLinks] = useState<any[]>([]);
  const [selectedLinks, setSelectedLinks] = useState<any[]>([]); // To store selected options across multiple Autocompletes
  const [autocompleteCount, setAutocompleteCount] = useState<number>(1); // To limit number of Autocompletes
  const [promotedLinksOnly, setPromotedLinksOnly] = useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [linkToAdd, setLinkToAdd] = useState<{ index: number; link: any } | null>(null); // Store the link the user is trying to add
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [cost, setCost] = useState('');
  const [budget, setBudget] = useState('');
  const [exequteQueryMessage, setExequteQueryMessage] = useState('') as any;
  const [brandSharedUid, setBrandSharedUid] = useState('');
  const [loading, setloading] = useState(false);


  const handleBrandChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
) => {
    setIsBrandSelected(!!value);
    const selectedBrand = exequteQueryContentQueryCreateData.find((item: any) => item.brand_name === value);
    setSelectedLinks([]);
    
    if (selectedBrand) {
        setBrandSharedUid(selectedBrand.brand_shared_uid); // Set brand_shared_uid here
        const selectedBrandLinks = exequteQueryContentQueryCreateData
            .filter((item: any) => item.brand_name === value)
            .map((item: any) => ({
                label: item.link_name,
                uid: item.link_shared_uid,
                shown_in_active_table: item.shown_in_active_table
            }))
            

        setFilteredLinks(selectedBrandLinks);

        if (promotedLinksOnly) {
            const activeLink = selectedBrandLinks.find((link: any) => link.shown_in_active_table);
            if (activeLink) {
                setSelectedLinks([activeLink]);
            }
        }
    }
};




const handleLinkChange = (index: number, value: any | null) => {
  const updatedLinks = [...selectedLinks];
  
  if (value) {
    if (!value.shown_in_active_table) {
      setLinkToAdd({ index, link: value });
      setShowConfirmationDialog(true);
    } else {
      updatedLinks[index] = value;
      setSelectedLinks(updatedLinks);
    }
  } else {
    // If the field is cleared (value is null), set the respective index to null
    updatedLinks.splice(index, 1); // Remove the link at the specified index
    setSelectedLinks(updatedLinks);
  }
};

  
  

  const handleAddAnother = () => {
    // Only allow adding another Autocomplete if the last one has a selected link
    if (selectedLinks[autocompleteCount - 1]) {
      setAutocompleteCount(autocompleteCount + 1);
    }
  };

  const handlePromotedLinksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromotedLinksOnly(event.target.checked);
  
    if (event.target.checked) {
      // When promoted links only is checked, filter selectedLinks to include only shown_in_active_table
      const activeLinks = filteredLinks.filter(link => link.shown_in_active_table);
      setSelectedLinks(activeLinks.length > 0 ? [activeLinks[0]] : []);
    } else {
      // When unchecked, reset selected links based on previously selected links
      setSelectedLinks(selectedLinks);
    }
  };
  

  // Filter options to exclude already selected ones

  const getFilteredOptions = (index: number) => {
    if (promotedLinksOnly) {
        return filteredLinks.filter(option => 
            option && // Check that option is not null
            option.shown_in_active_table &&
            !selectedLinks.some(selected => selected && selected.uid === option.uid) // Check that selected is not null
        );
    }

    return filteredLinks.filter(option => 
        option && // Check that option is not null
        !selectedLinks.some(selected => selected && selected.uid === option.uid) // Check that selected is not null
    );
};


  
  const handleCloseModal = () => {
    // Reset all state variables when closing the modal
    setFilteredLinks([]);
    setSelectedLinks([]);
    setAutocompleteCount(1); // Reset to the initial count
    setPromotedLinksOnly(true); // Reset to default value
    setExequteQueryMessage("");
    setShowConfirmationDialog(false); // Ensure confirmation dialog is closed
    handleClose(); // Call the original handleClose prop function
    setCost('');
    setBudget('');
  };

  const handleAddBudget = async () => {
    const data = {
      "brand_shared_uid": brandSharedUid,
      "month": startDate,
      "cost_per_qftd": cost,
      "qftd_amount": budget,
      "links": selectedLinks
        .filter(link => link.uid !== null) // Filter out links with null uid
        .map(link => link.uid) // Map to extract the uids 
    };
  
    setloading(true);
    
    try {
      // Assuming the API returns a success or error status
      await engagementIncentivesEndPointLogic.postExequteQuery(userToken, data, setExequteQueryMessage);
    } catch (error) {
      // Handle any unexpected errors
      console.error('Error occurred while adding budget:', error);
    } finally {
      // Always stop the loading indicator
      setTimeout(() => setloading(false), 3000);
    }
  };

  useEffect(()=>{
    if (exequteQueryMessage && exequteQueryMessage.includes("Created") && !loading ) {
      // Close the modal if successful
      handleCloseModal();
      setRefresh(true);
    } 
    // eslint-disable-next-line
  },[exequteQueryMessage,loading])
  

  return (
  <>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      // disableEnforceFocus
      disableRestoreFocus
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 0px 0px" }}>
          Add Monthly Budget
        </DialogTitle>

        <Grid container columnSpacing={3} rowSpacing={2} sx={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", color: "#000000DE" }}>
              Brand Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              tabIndex={0}
              options={Array.from(new Set(exequteQueryContentQueryCreateData.map((item: any) => item?.brand_name)))}
              onChange={(event, value) => handleBrandChange(event, value as string | null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Brand or choose from the list"
                  label="Brand name *"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "8px" }} />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  size="small"
                  color="secondary"
                  checked={promotedLinksOnly}
                  onChange={handlePromotedLinksChange}
                />
              }
              label="Show Promoted links only"
            />
          </Grid>

          {/* Render Autocomplete components for links */}
          {Array.from({ length: autocompleteCount }).map((_, index) => (
            <Grid key={index} item xs={9}>
              <Autocomplete
                fullWidth
                options={getFilteredOptions(index)}
                tabIndex={index + 1}
                value={selectedLinks[index] || null}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => handleLinkChange(index, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose from the list"
                    label={`Link ${index + 1} *`}
                    InputProps={{ ...params.InputProps }}
                    color="secondary"
                  />
                )}
                size="small"
                disabled={!isBrandSelected}
              />
            </Grid>
          ))}

          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="medium"
              sx={{ py: "7px" }}
              fullWidth
              onClick={handleAddAnother}
              disabled={autocompleteCount >= 3 || !selectedLinks[autocompleteCount - 1]}
              color="secondary"
              endIcon={<AddIcon />}
            >
              Add another
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "12px", color: "#00000099" }}>
              All fields are mandatory.
            </Typography>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={2} sx={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", color: "#000000DE" }}>
              Budget Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth size="small" color="secondary">
              <InputLabel htmlFor="QFTDCost">QFTD Cost</InputLabel>
              <OutlinedInput
                id="QFTDCost"
                // tabIndex={autocompleteCount + 1}
                endAdornment={
                  <InputAdornment position="end" sx={{ color: "#00000061" }}>
                    <AttachMoneyIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  // Allow only digits, backspace, delete, and a single period
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "." && e.key !== "Tab" // Allow the period character
                  ) {
                    e.preventDefault();
                  }
            
                  // Prevent multiple periods
                  if (e.key === "." && e.currentTarget.value.includes(".")) {
                    e.preventDefault();
                  }
                }}
                label="QFTD Cost"
                placeholder="0.00"
                onChange={(e)=>setCost(e.target.value)}
              />
            </FormControl>
          </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth size="small" color="secondary">
                <InputLabel htmlFor="QFTDBudget">QFTD Budget</InputLabel>
                <OutlinedInput
                  id="QFTDBudget"
                  // tabIndex={autocompleteCount + 2}
                  onKeyDown={(e) => {
                    // Allow digits, backspace, delete, and one decimal point
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "." && e.key !== "Tab") {
                      e.preventDefault();
                    }

                    // Allow only one decimal point
                    if (e.key === "." && e.currentTarget.value.includes(".")) {
                      e.preventDefault();
                    }
                  }}
                  label="QFTD Budget"
                  placeholder="0.00"
                  onChange={(e) => setBudget(e.target.value)}
                // endAdornment={
                //   <InputAdornment position="end" sx={{ color: "#00000061" }}>
                //     <AttachMoneyIcon />
                //   </InputAdornment>
                // }
                />
              </FormControl>
            </Grid>
        </Grid>

        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
          <Button color="secondary" variant="outlined" onClick={handleCloseModal} sx={{ mr: 1 }}>
            CANCEL
          </Button>
          <Button variant="contained" color="secondary" endIcon={!loading && <DoneIcon />}
           disabled={(!cost) || !budget || selectedLinks.length === 0 || loading}
           onClick={handleAddBudget}>
            {loading ? <CircularProgress color="inherit" size="30px" /> : "Add new budget"}
          </Button>
        </Stack>
      </Paper>
    </Dialog>
    <Dialog
  open={showConfirmationDialog}
  onClose={() => setShowConfirmationDialog(false)}
  PaperProps={{ sx: { width: "400px", maxWidth: "400px" } }}
>
  <DialogTitle>Confirm Link Addition</DialogTitle>
  <DialogContent>
    <Typography>
      The link is not displayed in the active table. Do you want to add it anyway?
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowConfirmationDialog(false)} color="secondary">
      Cancel
    </Button>
    <Button
    onClick={() => {
    const updatedLinks = [...selectedLinks];
    if (linkToAdd) {
      updatedLinks[linkToAdd.index] = linkToAdd.link; // Add the link to the specified index
    }
    setSelectedLinks(updatedLinks);
    setShowConfirmationDialog(false);
  }}
  color="secondary"
>
  Yes, Add It
</Button>

  </DialogActions>
</Dialog>

    </>
    
  );
};

export default BudgetControlModal;
