import {
  Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Paper,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import {Stack } from "@mui/system";
  import AddIcon from '@mui/icons-material/Add';
  import { useEffect, useState } from 'react';
import { DesignThemeEndPointLogic } from '../../../../../logic/AssetManagement/Design/Theme/DesignThemeEndPointLogic';
import { setIsLoading } from '../../../../../features/userSlice';
  interface DuplicateThemeModalProps {
    open: boolean;
    onClose: any;
    selectedSharedUid: any;
    selectedThemeShareuid: any;
    oauthTokenHeader: any;
    hasGetPermissions: any;
    hasEditPermissions: any;
    designThemeData: any;
    setDesignThemeData: any;
  }
  function DuplicateThemeModal({open, onClose, selectedSharedUid, selectedThemeShareuid, hasGetPermissions,oauthTokenHeader, hasEditPermissions, designThemeData, setDesignThemeData}: DuplicateThemeModalProps) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [, setShowErrorMessage] = useState(false); // State for error message visibility
    const [themeName, setThemeName] = useState(""); // State for theme name
    const [dictionary, setDictionary] = useState('');
    const [pageStructure, setPageStructure] = useState<string | null>(null);
    const [comparisonTable, setComparisonTable] = useState<string | null>(null); // State for page structure templates
    const [popupOffer, setPopupOffer] = useState<string | null>(null); // State for page structure templates
    const [animation, setAnimation] = useState<string | null>(null); // State for page structure templates
    const [paymentMethods, setPaymentMethods] = useState<string | null>(null); // State for page structure templates
    const [prosandCons, setProsandCons] = useState<string | null>(null); // State for page structure templates
    const [offer1, setOffer1] = useState<string | null>(null); // State for page structure templates
    const [offer2, setOffer2] = useState<string | null>(null); // State for page structure templates
    const [offer3, setOffer3] = useState<string | null>(null); // State for page structure templates
    const [faq, setFaq] = useState<string | null>(null); // State for page structure templates
    const [ratings, setRatings] = useState<string | null>(null); // State for page structure templates
    const [carouselCards, setCarouselCards] = useState<string | null>(null); // State for page structure templates
    const [authorBox, setAuthorBox] = useState<string | null>(null); // State for page structure templates
    const [bestFeatures, setBestFeatures] = useState<string | null>(null); // State for page structure templates
    const [miniAuthorbox, setMiniAuthorbox] = useState<string | null>(null); // State for page structure templates
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false); // State for loading
    const [singlePageContent, setSinglePageContent] = useState([]) as any;
    const [, setError] = useState(""); // State for error message

    useEffect(() => {
      const fetchSingleTheme = async() => {
        setLoading(true);
        try{
          if (selectedThemeShareuid !== null && selectedSharedUid !== null) {
            await DesignThemeEndPointLogic.getSingleDesignTheme(oauthTokenHeader, selectedThemeShareuid, setSinglePageContent, setError);
          } else {
            throw new Error("Unauthorized access for fetching single theme");
          }
        } catch(error) {
          console.error("Error fetching single theme", error);
        } finally {
          setLoading(false);
        }
      };
      fetchSingleTheme();
    // eslint-disable-next-line
    }, [selectedThemeShareuid]);

    useEffect(() => {
      if (open && singlePageContent) {
      setThemeName(`${singlePageContent?.name}_copy`); // Set the theme name to the selected theme name
      let value = singlePageContent?.dictionary;
      value = value?.replace(/\\n/g, '')?.replace(/\\\\/g, '')?.replace(/\\"/g, '"'); // Remove "\n", "\\" and extra backslashes
      if (value?.startsWith('"') && value?.endsWith('"')) {  // Remove starting and ending quotes
          value = value?.slice(1, -1); // Remove the first and last character
      }
      setDictionary(value);
      const foundPageStructure = singlePageContent.templates?.find((template: any) => template.component === "Page Structure");
      setPageStructure(foundPageStructure?.shared_uid);
      const foundComparisonTable = singlePageContent.templates?.find((template: any) => template.component === "Comparison Table");
      setComparisonTable(foundComparisonTable?.shared_uid);
      const foundPopupOffer = singlePageContent.templates?.find((template: any) => template.component === "Popup Offer");
      setPopupOffer(foundPopupOffer?.shared_uid);
      const foundAnimation = singlePageContent.templates?.find((template: any) => template.component === "Animation");
      setAnimation(foundAnimation?.shared_uid);
      const foundPaymentMethods = singlePageContent.templates?.find((template: any) => template.component === "Payment Methods");
      setPaymentMethods(foundPaymentMethods?.shared_uid);
      const foundProsandCons = singlePageContent.templates?.find((template: any) => template.component === "Brand Pros and Cons");
      setProsandCons(foundProsandCons?.shared_uid);
      const foundOffer1 = singlePageContent.templates?.find((template: any) => template.component === "Offer 1");
      setOffer1(foundOffer1?.shared_uid);
      const foundOffer2 = singlePageContent.templates?.find((template: any) => template.component === "Offer 2");
      setOffer2(foundOffer2?.shared_uid);
      const foundOffer3 = singlePageContent.templates?.find((template: any) => template.component === "Offer 3");
      setOffer3(foundOffer3?.shared_uid);
      const foundFaq = singlePageContent.templates?.find((template: any) => template.component === "FAQ");
      setFaq(foundFaq?.shared_uid);
      const foundRatings = singlePageContent.templates?.find((template: any) => template.component === "Ratings");
      setRatings(foundRatings?.shared_uid);
      const foundCarouselCards = singlePageContent.templates?.find((template: any) => template.component === "Carousel Cards");
      setCarouselCards(foundCarouselCards?.shared_uid);
      const foundBestFeatures = singlePageContent.templates?.find((template: any) => template.component === "Best Features");
      setBestFeatures(foundBestFeatures?.shared_uid);
      const foundAuthorbox = singlePageContent.templates?.find((template: any) => template.component === "Authorbox");
      setAuthorBox(foundAuthorbox?.shared_uid);
      const foundMiniAuthorbox = singlePageContent.templates?.find((template: any) => template.component === "Mini Authorbox");
      setMiniAuthorbox(foundMiniAuthorbox?.shared_uid);
      }
    },[open, singlePageContent]);

    const duplicateTheme = async() => {
      const data = {
        website_shared_uid: selectedSharedUid,
        name: themeName,
        dictionary : dictionary,
        templates: [
          pageStructure, comparisonTable, popupOffer, animation, paymentMethods,
          prosandCons, offer1, offer2, offer3, faq, ratings, carouselCards,
          bestFeatures, authorBox, miniAuthorbox
        ].filter(item => item !== null && item !== undefined && item !== "" && !(Array.isArray(item) && item.length === 0)) // Filter out null, undefined, "", and []
      }
      try {
        setIsLoading(true);
        await DesignThemeEndPointLogic.createDesignTheme(oauthTokenHeader, data);
        setSnackbarOpen(true);
        setSnackbarMessage("Theme duplicated successfully");
        onClose();
        setIsLoading(true);
        await DesignThemeEndPointLogic.getDesignTheme(oauthTokenHeader, selectedSharedUid, setDesignThemeData, setError);
      } catch(error) {
        console.error("Error duplicating theme", error);
      } finally {
        setIsLoading(false);
        onClose();
      }
    }

    const validate = async() => {
      if (!themeName) {
        setSnackbarOpen(true);
        setSnackbarMessage('Please enter a theme name');
        setIsValid(false);
        return false;
      }
  
      // Check if themeName is at least 8 characters long and contains only letters and numbers
      const themeNameRegex = /^[a-zA-Z0-9-_&*$#!:|,./\\ ]{8,}$/;
      if (!themeNameRegex.test(themeName)) {
        setSnackbarOpen(true);
        setSnackbarMessage('Template name must be at least 8 characters long and must contain only letters, numbers underscores and hyphens');
        setIsValid(false);
        return false;
      }
  
      const isThemeNameUnique = Array.isArray(designThemeData) && designThemeData?.every((item:any) => item.name !== themeName) &&
      designThemeData?.filter((item: any) => item?.name === themeName && item?.name !== singlePageContent?.name).length === 0;
  
      if(!isThemeNameUnique) {
        setSnackbarOpen(true);
        setSnackbarMessage('Theme name must be unique');
        setIsValid(false);
        return false;
      }

      try {
        if (dictionary) {
          const cleanedDictionary = dictionary.replace(/\\n|\\+/g, ''); // Remove extra backslashes and newline characters
          const parsedAdditionalInfo = JSON.parse(cleanedDictionary);
          if (typeof parsedAdditionalInfo !== 'object') {
            throw new Error('Not an object');
          }
        }
      } catch (e) {
        setSnackbarOpen(true);
        setSnackbarMessage('Dictionary must be a valid JSON object');
        setShowErrorMessage(true);
        setIsValid(false);
        return false;
      }

      setIsValid(true); // Validation passed
      return true; // Validation passed
    }

    const handleDuplicateTheme = async() => {
      const isFormValid = await validate();
      if (!isFormValid) return; // Only proceed if the form is valid
      try {
        setLoading(true);
        await duplicateTheme(); // Call the createNewTheme function
      } catch(error) {
        console.error(error, "error");
      } finally {
        setLoading(false);
      }
    }

    const handleCloseDuplicateModal = () => {
      onClose();
    }

    const renderSnackbar = () => {
      return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('Error') || isValid ? "success" : "error"}
        >
            {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  };

    return (
      <>
        {renderSnackbar()}
        <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      >
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
          <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Duplicate Theme
          </DialogTitle>
          <TextField
            id="outlined-multiline-flexible"
            label="New Theme Name" // Set your label here
            value={loading ? "" : themeName}
            onChange={(e) => setThemeName(e.target.value)}
            placeholder="New name for the duplicated Theme" // Set your placeholder here
            multiline
            required
            color="secondary"
            size="small"
            maxRows={4}
            sx={{
              width: "852px",
            }}
          />
          <Typography sx={{fontSize:"12px", marginTop:"8px", color:"#00000099"}}>All fields are mandatory.</Typography>
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
            <Button color="secondary" onClick={handleCloseDuplicateModal} sx={{ textTransform: 'uppercase' }}>Cancel</Button>
            <Button variant="contained" color="secondary" sx={{ textTransform: 'uppercase' }} disabled={!themeName || themeName === ""} onClick={handleDuplicateTheme}><Stack flexDirection={"row"} gap={1}>{loading ? <CircularProgress size={24} color="inherit" /> : "Create New"}<AddIcon fontSize="small"/></Stack></Button>
          </Stack>
        </Paper>
      </Dialog>
      </>
    );
  }
  export default DuplicateThemeModal;
