import { Grid, TextField, Typography } from '@mui/material'
import "./LogTableAdditionalContent.css"

interface props{
  rowData:any;
}



export default function LogTableAdditionalContent({rowData}:props) {
  return (
    <>
    <Grid container>
        <Grid xs={12} >
            <Typography variant='body1' mb={2}>Process Details</Typography>

        </Grid>
        <Grid xs={2} pr={2}>
        <TextField
          id="ID"
          label="ID:"
          defaultValue={rowData?.process_id}
          variant="standard"
          color='secondary'
          fullWidth
          sx={{color:'#ccc'}}
        />
        </Grid>
        <Grid xs={3} pr={2}>
        <TextField
          id="Chain"
          label="Chain:"
          defaultValue="N/A"
          variant="standard"
          color='secondary'
          fullWidth
        />
        </Grid>
        <Grid xs={2} pr={2}>
        <TextField
          id="OrderInChain:"
          label="Order in Chain:"
          defaultValue="N/A"
          variant="standard"
          color='secondary'
          fullWidth
        />
        </Grid>
    </Grid>
    </>
  )
}
