import Template from '../../../../components/AssetManagement/Design/Templates/Template'

function TemplatesScreen(userToken:any, response:any) {
  const oauthTokenHeader = userToken?.userToken;
  const Websiteresponse = userToken?.response;

  return (
    <div>
      <Template oauthTokenHeader={oauthTokenHeader} response={Websiteresponse} />
    </div>
  )
}

export default TemplatesScreen
