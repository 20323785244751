
import Box from "@mui/material/Box";
import FilterComponent from "../../../../../ReusableFIlterComponent/FilterComponent";
import { useState } from "react";

interface Props {
  rows:any
  selectedStatus:any
  setSelectedStatus:any
  RequestedBy:any;
  setRequestedBy:any;
  setSelectedSearchValue:any
}

export default function PageScanRequestFilter({rows,setSelectedSearchValue,selectedStatus,setSelectedStatus,RequestedBy,setRequestedBy}:Props) {

  const [searchStatus, setSearchStatus] = useState('');
  const [searchRequestedBy, setSearchRequestedBy] = useState('');

  const filterConfig = [
    {
      key: 'scan status',
      label: 'Select Scan Status',
      searchTerm: searchStatus,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchStatus(e.target.value),
      selectedOption: selectedStatus,
      filterName: 'active',
      data: rows,
      onSelectionChange: (updatedSelection: any) => setSelectedStatus(updatedSelection),
      count: selectedStatus && selectedStatus.length,
      colorSelected: selectedStatus !== null && selectedStatus.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      showSearch: false,
      valueMapping: { "true": "Active", "false": "Inactive" }
    },
    {
      key: 'requested by',
      label: 'Select a user',
      searchTerm: searchRequestedBy,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchRequestedBy(e.target.value),
      selectedOption: RequestedBy,
      filterName: 'created_by',
      data: rows,
      onSelectionChange: (updatedSelection: any) => setRequestedBy(updatedSelection),
      count: RequestedBy && RequestedBy.length,
      colorSelected: RequestedBy !== null && RequestedBy.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    }
  ];

  const clearFilters = () => {
    setSelectedStatus(null);
    setRequestedBy(null);
    setSelectedSearchValue(null)
  }

  return (
    <Box>
    <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}
