import {
  Button,
  Dialog,
  DialogTitle,
  Paper,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from "react";

interface DuplicatePageGroupModalProps {
  open: boolean;
  handleClose: () => void;
}

function DuplicatePageGroupModal({
  open,
  handleClose,
}: DuplicatePageGroupModalProps) {

  const [groupName , setGroupName] = useState("");
  const [isDuplicateButtonDisabled, setIsDuplicateButtonDisabled] = useState(true);

  // Handle input change and validate the minimum 8 character condition
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]+$/; // Allow only letters and numbers
    if (regex.test(value)) {
      setGroupName(value);
      setIsDuplicateButtonDisabled(value.length < 8); // Enable if 8+ characters
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
        Duplicate Page Group
        </DialogTitle>
        <TextField
          id="outlined-multiline-flexible"
          label="New Group Name" // Set your label here
          placeholder="How you like the group to be called" // Set your placeholder here
          multiline
          required
          color="secondary"
          size="small"
          maxRows={4}
          value={groupName}
          sx={{
            width: "852px",
          }}
          onChange={handleInputChange} // Update state on change
        />
        <Typography sx={{fontSize:"12px", marginTop:"8px", color:"#00000099"}}>minimum of 8 characters, only letters and numbers.</Typography>
        <SnackbarContent
          sx={{
            background: "#FFF4E5",
            color: "#ED6C02",
            marginTop: "16px",
            boxShadow: "none",
            border: "1px solid #D32F2F80",
            padding: "0 16px",
          }}
          message={
            <Box display="flex">
              <ErrorOutlineIcon sx={{ marginRight: "8px", color: "#ED6C02" }} />
              <Box>
                <Typography variant="body1" fontWeight="500" sx={{ marginBottom: "4px" }}>
                  PLEASE NOTE
                </Typography>
                <Typography variant="body2">
                  Duplicating a page group with an active A/B test will result in only the first version of the theme and popup being copied.
                </Typography>
              </Box>
            </Box>
          }
        />
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
                <Button color="secondary" onClick={handleClose}>CANCEL</Button>
                <Button variant="contained" color="secondary" disabled={isDuplicateButtonDisabled}><Stack flexDirection={"row"} gap={1}>DUPLICATE <ContentCopyIcon/></Stack></Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}

export default DuplicatePageGroupModal;
