import { endpointsService } from "../../../services/endPoints.services";
import { setDisplayErrorSnackBar } from "../../../features/userSlice";
import store from "../../../store";

async function getETLProcessList(oauthTokenHeader: string, setETLProcessContent: any, trackPromise: any) {
    try {
        const response = await endpointsService.getETLProcessList(oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting ETL Process List', type: 'error' })));
        } else {
            setETLProcessContent(response?.data?.data);
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e || 'Unknown error occurred', type: 'error' })));
    }
}

async function createETLProcess(oauthTokenHeader: string, setETLProcess: any, trackPromise: any, data:any) {
    try {
        const response = await endpointsService.createETLProcess(data,oauthTokenHeader);
        if (!response?.status || response?.status !== 201) {
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating ETL Process List', type: 'error' })));
        } else {
            setETLProcess("success");
        }
    } catch (e) {
        // trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e || 'Unknown error occurred', type: 'error' })));
        setETLProcess(e);
    }
}

async function editETLProcess(oauthTokenHeader: string, setEditETLProcess: any, trackPromise: any, data:any) {
    try {
        const response = await endpointsService.editETLProcess(data,oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Edit ETL Process List', type: 'error' })));
        } else {
            setEditETLProcess(response?.data);
            
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e || 'Unknown error occurred', type: 'error' })));
    }
}

async function toggleETLProcess(oauthTokenHeader: string, setEditETLProcess: any, trackPromise: any, data:any , processId:number) {
    try {
        const response = await endpointsService.toggleETLProcess(processId,data,oauthTokenHeader);
        if (!response?.status || response?.status !== 204) {
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while toggle ETL Process List', type: 'error' })));
        } else {
            setEditETLProcess(response);
        }
    } catch (e:any) {
        if(e?.status !== undefined){
            setEditETLProcess(e?.parsedText?.reason);
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
        }
        else(
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: "Toggle Successfull", type: 'success' })))
        )
    }
}

export const ETLProcessListEndPointLogic = {
    getETLProcessList,
    createETLProcess,
    editETLProcess,
    toggleETLProcess
}