import { useDispatch } from "react-redux";
import { setDisplayErrorSnackBar } from "../../features/userSlice";
import ErrorMessage, { ErrorMessageProps } from "../../components/ErrorMessage/ErrorMessage";
import { AlertColor } from "@mui/material";
export interface ErrorMessageScreenProps {
    type: AlertColor | undefined,
    message: any,
}

export default function ErrorMessageScreen({
    message,
    type
}: ErrorMessageScreenProps) {

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setDisplayErrorSnackBar(null));
    };

    const errorsMessagesProps: ErrorMessageProps
        = {
        handleClose,
        message,
        type
    }

    return (
        <>
            {message ? <ErrorMessage {...errorsMessagesProps} /> : ''}
        </>
    )
}
