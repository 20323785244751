import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

export default function PageGroupFilter() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>("bottom-start"); // Set default placement
  const [selectedContentIndex, setSelectedContentIndex] =
    React.useState<number>(0);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [clicked, setClicked] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);

  const content = [
  <div className="tabDiv">
    <TextField
      color="secondary"
      label="Search User..."
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
    <Typography
      sx={{ fontWeight: "500", color: "#000000DE", paddingBlock: "16px" }}
    >
      Select one or more Theme
    </Typography>
    <Stack>
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="IE - Generic"
      />
      <FormControlLabel control={<Checkbox color="secondary" />} label="UK - Generic" />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="UK - Horse Racing"
      />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="IE - Football"
      />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="UK - Football"
      />
    </Stack>
  </div>,
  <div className="tabDiv">
    <TextField
      color="secondary"
      label="Search User..."
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
    <Typography
      sx={{ fontWeight: "500", color: "#000000DE", paddingBlock: "16px" }}
    >
      Select one or more Popup 
    </Typography>
    <Stack>
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="Football Popup"
      />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="Generic Popup"
      />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label="Horse Racing"
      />
    </Stack>
  </div>,
  <div className="tabDiv">
    <Typography
      sx={{ fontWeight: "500", color: "#000000DE", paddingBottom: "16px" }}
    >
      Currently Running A/B Test
    </Typography>
    <RadioGroup>
      <FormControlLabel
        value="Active"
        control={<Radio color="secondary" />}
        label="Yes"
      />
      <FormControlLabel
        value="Inactive"
        control={<Radio color="secondary" />}
        label="No"
      />
    </RadioGroup>
  </div>,
  ];

  const handleClick =
    (newPlacement: PopperPlacementType, index: number) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      // Check if the clicked button is not the same as the one currently associated with the popper
      if (anchorEl !== event.currentTarget) {
        setOpen(true); // Open the popper
      } else {
        setOpen((prevOpen) => !prevOpen); // Toggle the open state of the popper
      }
      setPlacement(newPlacement);
      setSelectedContentIndex(index);
      // Update the clicked state for the clicked button
      setClicked((prevClicked) => prevClicked.map((item, i) => i === index));
    };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        !anchorEl?.contains(event.target as Node)
      ) {
        setOpen(false);
        setClicked([false, false, false, false]);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, anchorEl]);

  return (
    <Box>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={popperRef}>{content[selectedContentIndex]}</Paper>
          </Fade>
        )}
      </Popper>
      {["Theme", "Popup","A/B Test"].map((letter, index) => (
        <Button
          sx={{
            mr: 1,
            textTransform: "capitalize",
            fontWeight: 500,
            color: clicked[index] ? "#9C27B0" : "#000000DE",
            "&:hover": { backgroundColor: "rgba(156, 39, 176, 0.04)" },
            padding: index === 0 ? "8px 8px 8px 0px" : undefined,
          }}
          key={index}
          onClick={handleClick("bottom-start", index)}
        >
          {letter} <KeyboardArrowDownIcon />
        </Button>
      ))}
      <Button
        sx={{
          textTransform: "capitalize",
          fontWeight: 500,
          color: "#000000DE",
          "&:hover": {
            color: "#9C27B0",
            backgroundColor: "rgba(156, 39, 176, 0.04)",
          },
        }}
      >
        Clear Filter
      </Button>
    </Box>
  );
}
