import { Box } from '@mui/system';
import styles from "./Breadcrumbs.module.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Breadcrumbs(props: any) {
  const location = useLocation();
  const pathname = location.pathname.split("/").filter((x: any) => x);
  const breadcrumbs = ["", ...pathname];
  const currentPath = breadcrumbs.join("/");
  return (
    <div className={styles.breadcrumbs}>
        <div className={styles.icon}>
          <img
            className={styles.starfilledIcon}
            alt=""
            src="/starfilled8.svg"
          />
        </div>
        <div className={styles.link1}>
        {breadcrumbs.map((name, index) => {
          const isHome = index === 0;
          const isFirstAfterHome = index === 1;
          let breadcrumbPath = isHome ? "" : `${breadcrumbs.slice(1, index + 1).join("/")}`;
          let newBreadcrumbs = breadcrumbs.slice(1, index + 1).join("/");
          const isCurrent = breadcrumbPath === currentPath;

          if (newBreadcrumbs === 'admin/processes/etl-sequences') {
            name = 'ETL-sequences';
          } else if (newBreadcrumbs === 'admin/processes/etl-process-list') {
            name = 'ETL-process-list';
          } else if (newBreadcrumbs === 'admin/processes/etl-process-log') {
            name = 'ETL-process-log';
          } else if (newBreadcrumbs === 'assets/health/page_speed_performance') {
            name = 'Page Speed Performance';
          } else if (newBreadcrumbs === 'assets/design/templates/create_new_template') {
            name = 'Create new Template'; // Custom name for this path
          } else if (newBreadcrumbs === 'assets/design/theme/create_new_theme') {
            name = 'Create new Theme'; // Custom name for this path
          } else if (newBreadcrumbs === 'assets/design/theme/edit_new_theme') {
            name = 'Edit new Theme'; // Custom name for this path
          } else if (newBreadcrumbs === 'assets/design/templates/edit_new_template') {
            name = 'Edit new Template'; // Custom name for this path
          } else if (newBreadcrumbs === 'assets/activity_notes') {
            name = 'Activity Notes'; // Custom name for this path
          }

          if (isHome && pathname.length === 1) { // Make the home page current
            return (
              <Link to={breadcrumbPath} key={breadcrumbPath} className={isCurrent ? styles.breadcrumbCurrent : styles.breadcrumbLink}>
                {(name ? name : "Home").split(" ").join("  ")}
              </Link>
            );
          } else if (isHome) { // Make the first page current
            return (
              <Link to={breadcrumbPath} key={breadcrumbPath} className={isCurrent ? styles.breadcrumbCurrent : styles.breadcrumbLink}>
                {(name ? name : "Home").split(" ").join("  ")}
              </Link>
            );
          } else if (index === breadcrumbs.length - 1) { // Make the last page current
            return (
              <span key={breadcrumbPath} className={styles.breadcrumbCurrent}>
                <Box sx={{padding:"0 8px"}}>/</Box>{(name).split(" ").join("  ")}
              </span>
            );
          } else if(isFirstAfterHome) { // Allowing to click only for the first item after Home
            return (
              <span key={breadcrumbPath} className={isCurrent ? styles.breadcrumbCurrent : styles.breadcrumbSeparator}>
                <Box sx={{padding:"0 8px"}}>/</Box> {(name).split(" ").join("  ")}
              </span>
            );
          } else if(index <= 2) {
            if (newBreadcrumbs === 'assets/page_management') {
            return (
              <Link style={{ textDecoration: "none" }} to={newBreadcrumbs} key={breadcrumbPath} className={isCurrent ? styles.breadcrumbCurrent : styles.breadcrumbSeparator}>
                <Box sx={{padding:"0 8px", cursor: "default", textDecoration: "none"}}>/</Box> <Box sx={{textDecoration:"underline"}}>{(name).split(" ").join("  ")}</Box>
              </Link>
            );
          } else {
              return (
                <span key={breadcrumbPath} className={styles.breadcrumbSeparator}>
                  <Box sx={{padding:"0 8px", cursor: "default", textDecoration: "none"}}>/</Box> {(name).split(" ").join("  ")}
                </span>
              );
            }
          } else if (index <= 3) { // Allowing to click only for the first four items
            return (
              <Link style={{ textDecoration: "none" }} to={newBreadcrumbs} key={breadcrumbPath} className={isCurrent ? styles.breadcrumbCurrent : styles.breadcrumbSeparator}>
                <Box sx={{padding:"0 8px", cursor: "default", textDecoration: "none"}}>/</Box> <Box sx={{textDecoration:"underline"}}>{(name).split(" ").join("  ")}</Box>
              </Link>
            );
          } else { // Make the rest of the pages normal
            return (
              <span key={breadcrumbPath} className={styles.breadcrumbSeparator}>
                <Box sx={{padding:"0 8px", cursor: "default", textDecoration: "none"}}>/</Box> {(name).split(" ").join("  ")}
              </span>
            );
          }
      })}
      </div>
    </div>
  );
};
