import React from 'react';
import Box from '@mui/material/Box';
import { SitePage } from '../../../../data/sitePages/SitePage';
import FilterComponent from '../../../ReusableFIlterComponent/FilterComponent';


// Interface for SitePagesFilterProps
interface SitePagesFilterProps {
  sitePages: SitePage | any;
  groupSelected: any;
  editedSelected: any;
  statusSelected: any;
  setGroupSelected: any;
  setEditedSelected: any;
  setStatusSelected: any;
  inputValueGroup: string;
  setInputValueGroup: any;
  inputValueEdited: string;
  setInputValueEdited: any;
  inputValueStatus: string;
  setInputValueStatus: any;
  pageOwnerSearchTerm: string;
  selectedPageOwners: string[];
  setPageOwnerSearchTerm: any;
  setSelectedPageOwners: any;
  selectedOptionGroup: string;
  selectedOptionEdit: string;
  selectedOptionStatus: string;
  setSelectedOptionGroup :  React.Dispatch<React.SetStateAction<string>>;
  setSelectedOptionStatus :  React.Dispatch<React.SetStateAction<string>>;
  setSelectedOptionEdit :  React.Dispatch<React.SetStateAction<string>>;
  setSearchText:any;
  setSelectedOption:any;
  selectedDate:any;
  setSelectedDate:any
}

export default function SitePagesFilter({
  sitePages,
  inputValueGroup,
  setInputValueGroup,
  inputValueEdited,
  setInputValueEdited,
  inputValueStatus,
  setInputValueStatus,
  setGroupSelected,
  setEditedSelected,
  setStatusSelected,
  pageOwnerSearchTerm,
  selectedPageOwners,
  setPageOwnerSearchTerm,
  setSelectedPageOwners,
  selectedOptionGroup,
  selectedOptionEdit,
  selectedOptionStatus,
  setSelectedOptionGroup,
  setSelectedOptionStatus,
  setSelectedOptionEdit,
  setSearchText,
  setSelectedOption,
  selectedDate,
  setSelectedDate
}: SitePagesFilterProps ) {

  const filteredSitePages = sitePages.filter((pages:any)=> pages?.shared_uid!==null && pages?.post_status!=="bin");

  // Clear all filters
  const handleClearFilters = () => {
    setPageOwnerSearchTerm('');
    setSelectedPageOwners([]);
    setGroupSelected(false);
    setEditedSelected(false);
    setStatusSelected(false);
    setSelectedOptionGroup('');
    setSelectedOptionEdit('');
    setSelectedOptionStatus('');
    setInputValueGroup('');
    setInputValueEdited('');
    setInputValueStatus('');
    setSearchText("");
    setSelectedOption(null);
    setSelectedDate([]);
  };

  const data = filteredSitePages;
  const filterConfig = [
    {
      key: 'group',
      label: 'Select Page Group',
      searchTerm: inputValueGroup,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValueGroup(e.target.value),
      selectedOption: selectedOptionGroup,
      filterName: 'page_group_name',
      data,
      onSelectionChange: (updatedSelection: any) => setSelectedOptionGroup(updatedSelection),
      count: selectedOptionGroup.length,
      colorSelected: selectedOptionGroup.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true
    },
    {
      key: 'Editor',
      label: 'Select Edited By',
      searchTerm: inputValueEdited,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValueEdited(e.target.value),
      selectedOption: selectedOptionEdit,
      filterName: 'modified_by',
      data,
      onSelectionChange: (updatedSelection: any) => setSelectedOptionEdit(updatedSelection),
      count: selectedOptionEdit.length,
      colorSelected: selectedOptionEdit.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true
    },
    {
      key: 'status',
      label: 'Select Page Status',
      searchTerm: inputValueStatus,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValueStatus(e.target.value),
      selectedOption: selectedOptionStatus,
      filterName: 'post_status',
      data,
      onSelectionChange: (updatedSelection: any) => setSelectedOptionStatus(updatedSelection),
      count: selectedOptionStatus.length,
      colorSelected: selectedOptionStatus.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
      // valueMapping: { "draft": "A", "pending": "B", "publish": "C" },
    },
    {
      key: 'owner',
      label: 'Select Page Owners',
      searchTerm: pageOwnerSearchTerm,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setPageOwnerSearchTerm(e.target.value),
      filterName: 'creating_user',
      data,
      selectedOption: selectedPageOwners,
      onSelectionChange: (updatedSelection: any) => setSelectedPageOwners(updatedSelection),
      count: selectedPageOwners.length,
      colorSelected: selectedPageOwners.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true
    },
    {
      key: 'date modified',
      label: 'Select Date Range',
      filterName: 'modified_date',
      data,
      selectedOption: selectedDate,
      onSelectionChange: (updatedSelection: any) => setSelectedDate(updatedSelection),
      count: 0,
      colorSelected: selectedDate.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      isDateRangeFilter: true
    },
  ];

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <FilterComponent filterConfig={filterConfig} clearFilters={handleClearFilters} />
    </Box>
  );
}
