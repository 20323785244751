import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import { useState } from "react";
//import CreateChains from "../Modal/CreateChains";
import "./LogFilters.css";
import LogFiltersItems from "./LogFiltersItems";
import { useState } from "react";
import RunNewProcessLog from "../Modal/RunNewProcessLog";
import RefreshIcon from '@mui/icons-material/Refresh';
import store from "../../../../../store";
import { setIsLoading } from "../../../../../features/userSlice";
import { ETLLogsEndPointLogic } from "../../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";
interface props {
  setRefresh:any;
  logs:any;
  setSelectedStatus:any;
  selectedStatus:any;
  selectedMethod:any;
  setSelectedMethod:any;
  setFilteredLogsByLastRun:any;
  selectedIds:any;
  token:any;
  setSelectedIds:any;
  selectedName:any;
  setSelectedName:any;
  page:any;
  setPage:any;
  websiteSharedUid:any;
}

const LogFilters = ({setRefresh,logs,setSelectedStatus,selectedStatus,setSelectedMethod,selectedMethod,setFilteredLogsByLastRun,selectedIds,
  token,setSelectedIds,setSelectedName,setPage,websiteSharedUid
}:props) => {
  const [openModal, setOpenModal] = useState(false);
  const [showLastRunOnly, setShowLastRunOnly] = useState(false);
  // const [loading,setLoading] = useState(false);
  const [runStatus,setRunStatus] = useState(false) as any;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const uniqueProcessNames = Array.from(new Set(logs.map((log: any) => log.process_name)));
  const specialProcessLogIds = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? [72, 48, 69] : [33, 59];

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleShowLastLogs = () => {
    setShowLastRunOnly(!showLastRunOnly);

    if (!showLastRunOnly) {
      const groupedLogs = logs.reduce((acc: any, log: any) => {
        const { process_id } = log;
        if (!acc[process_id]) {
          acc[process_id] = log; // first entry for the process_id
        } else {
          if (new Date(log.start_time) > new Date(acc[process_id].end_time)) {
            acc[process_id] = log; // update if the current log has a later end_time
          }
        }
        return acc;
      }, {});

      // Convert the object back to an array
      const latestLogs = Object.values(groupedLogs);
      setFilteredLogsByLastRun(latestLogs);
    } else {
      // Show all logs if "Last run only" is turned off
      setFilteredLogsByLastRun(logs);
    }
  };
  

  const runProcess = async () => {
    const ids = {"ids": selectedIds};
    try {
      if(selectedIds){
        store.dispatch(setIsLoading(true));
        // setLoading(true);
        await ETLLogsEndPointLogic.runProcess(token,ids,setRunStatus);
        setSelectedIds([]);
        setSnackbarMessage("Process ran successfully");
        setRefresh(true);
        setSnackbarOpen(true);
      }
      else{
        setRunStatus('User don`t have permission')
      }
    } catch (error) {
      setRunStatus(error);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  }

  return (
    <>
      <Paper className="chainsFiltersWrap">
        <div style={{ padding: "16px" }}>
          {/* Heading */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Logs
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Create new or edit existing ETL Process Logs.
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign={"end"}>
              <FormControlLabel
                value="LastRunOnly"
                control={<Switch color="secondary" onClick={handleShowLastLogs} />}
                label="Last run only"
                labelPlacement="start"
              />
              <Typography sx={{ display: "inline", mr: 1 , ml:4}}>
                Bulk Actions:
              </Typography>

              <IconButton color="secondary"  disabled={
                    (selectedIds && selectedIds.length === 0) || 
                    selectedIds?.some((id:number) => specialProcessLogIds.includes(id))
                  }         
                   onClick={runProcess}>
                <PlayCircleFilledWhiteIcon/>
              </IconButton>
              <IconButton color="secondary" disabled>
                <FileDownloadIcon  />
              </IconButton>
            </Grid>
          </Grid>

          {/* Filter part */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                marginTop: "5px",
              }}
            >
              {/*Queue Name */}
              <Autocomplete
                  options={uniqueProcessNames ? uniqueProcessNames.filter(Boolean) : []}
                  onChange={(event, newValue) => {
                    setSelectedName(newValue); 
                    setPage(0)// Update state with selected account
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Process Name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "300px" }}
                />

              <LogFiltersItems logs={logs} selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus} setSelectedMethod={setSelectedMethod} selectedMethod={selectedMethod}/>
            </div>
            {/* Action Buttons */}
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Tooltip title="Refresh">
                <IconButton onClick={() => setRefresh(true)}>
                  <RefreshIcon color="secondary" />
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<PlayCircleFilledWhiteIcon />}
                onClick={handleOpen}
              >
                run new Process
              </Button>
              <RunNewProcessLog openModal={openModal} onCloseModal={handleClose} token={token} setRefresh={setRefresh} websiteSharedUid={websiteSharedUid}/>
            </div>
          </div>
        </div>
      </Paper>
      {(runStatus === "Created." || runStatus === "Success.") && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleSnackbarClose} severity={"success"} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
export default LogFilters;
