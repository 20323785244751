import { useContext, useEffect, useState } from 'react';
import LogFilters from '../../../../components/Admin/Processes/ETL-Process-Log/LogFilters/LogFilters'
import LogTable from '../../../../components/Admin/Processes/ETL-Process-Log/LogTable/LogTable'
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { useHasETLLogsPermission } from '../../../../logic/Admin/Permissions/useHasETLLogsPermission';
import { ETLLogsEndPointLogic } from '../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic';
import SimpleBackdrop from '../../../../components/BackDrop/BackDrop';
import store from '../../../../store';
import { setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import SharedStateContext from '../../../../components/SharedStateProvider';


export default function ETLProcessLogScreen(userToken: any) {
  
  const hasGetPermissions = useHasETLLogsPermission([
    ModulesPermissionNames.GET_API_LOGS,
  ]);
  // const hasDeletePermissions = useHasETLLogsPermission([
  //   ModulesPermissionNames.DELETE_API_LOGS,
  // ]);

  const token = userToken?.userToken;
  let websiteSharedUids = userToken?.response?.website_permissions
  let websiteSharedUid = websiteSharedUids.map((item:any) => Object.keys(item)[0]);

  const [logs,setLogs] = useState([]) as any;
  const [error,setError] = useState('') as any;
  const [loading,setLoading] = useState(false);
  const [refresh , setRefresh] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState() as any;
  const [selectedMethod, setSelectedMethod] = useState() as any;
  const [selectedName, setSelectedName] = useState() as any;
  const [filteredLogsByLastRun, setFilteredLogsByLastRun] = useState(logs);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const {  setLoad,load } = useContext(SharedStateContext);

  useEffect(()=>{
    if(hasGetPermissions){
    const fetchLogs = async () => {
      let nextUrl;
      const localStorageKey = `logs__${token}`;
      const storedPages = localStorage.getItem(localStorageKey);
      try {   
        if(storedPages && refresh===false && load===false){
          const combinedLogs = JSON.parse(storedPages);
          setLogs(combinedLogs);
        }else{
          store.dispatch(setIsLoading(true))
          setLoading(true);
          await ETLLogsEndPointLogic.getLogs(token,(data:any)=>{
            let currentLogs = data?.records;
            localStorage.setItem(`${localStorageKey}`, JSON.stringify(currentLogs))
            setLogs(data?.records)
            nextUrl = data?.next
          });
          if(nextUrl!==undefined){
            while (nextUrl!==null) {
              try {
                // eslint-disable-next-line
                await ETLLogsEndPointLogic.getNextLogs(token,nextUrl).then((data:any)=> {
                if (data?.reason || data?.reason === "Success.") {
                  nextUrl=data?.data?.next;
                  let  prevPages =  localStorage.getItem(`${localStorageKey}`) as any;
                  prevPages = prevPages ? JSON.parse(prevPages) : [];
                  data =  data?.data?.records;
                  const combinedLogs = [...prevPages, ...data];                 
                  localStorage.setItem(`${localStorageKey}`, JSON.stringify(combinedLogs));
                  setLogs(combinedLogs);
                }
              }
               )
              } catch (e) {
                setError('Something went wrong while getting pages')
                store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
              }
             }
          }
        }  
      } catch (error) {
        setError('Something went wrong while getting pages')
      } finally {
        store.dispatch(setIsLoading(false))
        setLoading(false);
        setRefresh(false);
        setLoad(false);
        // setAutoRefresh(false);
      }
    }
    if(userToken){
      fetchLogs();
    }}
    else {
      setError('User Don`t have permissions');
    }
  },[token,hasGetPermissions,userToken,refresh,load,setLoad]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//         setAutoRefresh(true);
//     }, 5000); // 5000 milliseconds = 5 seconds

//     // Cleanup function to clear the interval when the component unmounts
//     return () => clearInterval(interval);
// }, []);


  const tableHeadings = {
    "run_id": "Run ID",
    "process_name": "Process Name",
    "start": "Started At",
    "end": "Ended At",
    "fetched": "Fetched",
    "created": "Created",
    "updated": "Updated",
    "status" : "Status",
    "response": "Response",
    // "run_by": "Run By",
    "method": "Method",
    "info": "Info",
  }

  return (
    <div>
      <LogFilters setRefresh={setRefresh} logs={logs}  selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} setSelectedMethod={setSelectedMethod} selectedMethod={selectedMethod} setFilteredLogsByLastRun={setFilteredLogsByLastRun}
      selectedIds={selectedIds} token={token} setSelectedIds={setSelectedIds} setSelectedName={setSelectedName} selectedName={selectedName} page={page} setPage={setPage} websiteSharedUid={websiteSharedUid}/>
      
      {loading ? <SimpleBackdrop/> : <LogTable tableHeadings={tableHeadings} logs={logs} selectedStatus={selectedStatus} selectedMethod={selectedMethod} error={error} setRefresh={setRefresh} filteredLogsByLastRun={filteredLogsByLastRun}
      setSelectedIds={setSelectedIds} selectedIds={selectedIds} setSelectedName={setSelectedName} selectedName={selectedName} page={page} setPage={setPage}/>}
    </div>
  )
}
