import PageSpeedPerformance from '../../../../components/AssetManagement/Health/PageSpeedPerformance/PageSpeedPerformance'

export default function PageSpeedPerformanceScreen(userToken:any, response:any) {
  const oauthTokenHeader = userToken?.userToken;
  const Websiteresponse = userToken?.response
  return (
    <>
      <PageSpeedPerformance oauthTokenHeader={oauthTokenHeader} response={Websiteresponse} />
    </>
  )
}
