import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
export default function SimpleBackdrop() {
  // const classes = useStyles1();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#9C27B0', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
