import { Autocomplete, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";

function WebsiteDetails() {
  const [groupName, setGroupName] = useState("");
  // const [isDuplicateButtonDisabled, setIsDuplicateButtonDisabled] = useState(true);
  const [, setIsDuplicateButtonDisabled] = useState(true);

  // Handle input change and validate the minimum 8 character condition
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]+$/; // Allow only letters and numbers
    if (regex.test(value)) {
      setGroupName(value);
      setIsDuplicateButtonDisabled(value.length < 8); // Enable if 8+ characters
    }
  };

  const websitelisting = [
    { label: "websiteexample1.com" },
    { label: "websiteexample2.com" },
    { label: "websiteexample3.com" },
    { label: "websiteexample4.com" },
    { label: "websiteexample5.com" },
  ];

  return (
    <div>
      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Website Name" // Set your label here
            placeholder="How you like the site to be called" // Set your placeholder here
            required
            fullWidth
            color="secondary"
            size="small"
            value={groupName}
            onChange={handleInputChange} // Update state on change
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography
            sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
          >
            minimum of 8 characters, only letters and numbers.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={websitelisting.map((websitename) => websitename.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Website type"
                color="secondary"
                InputLabelProps={{
                  shrink: true, // This will keep the label above the input field
                }}
                placeholder="Choose site type from the list"
                required
              />
            )}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={websitelisting.map((websitename) => websitename.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Industry"
                color="secondary"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Choose industry from the list"
                required
              />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={websitelisting.map((websitename) => websitename.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Geography"
                color="secondary"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Choose geography from the list"
                required
              />
            )}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Location" // Set your label here
            placeholder="How you like the site to be called" // Set your placeholder here
            required
            fullWidth
            color="secondary"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Site Title" // Set your label here
            placeholder="How you like the site to be called" // Set your placeholder here
            required
            fullWidth
            color="secondary"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
      >
        All fields are mandatory.
      </Typography>
    </div>
  );
}

export default WebsiteDetails;
