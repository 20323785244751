import { Autocomplete, Button, Dialog, DialogTitle, TextField, Box, Typography, Snackbar, Alert } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { sitePageEndPointLogic } from "../../logic/AssetManagement/PageManagement/SitePages/sitePagesEndPointLogic";
import { setIsLoading } from "../../features/userSlice";
import { useState } from "react";
import { pageBuildingEndPointLogic } from "../../logic/AssetManagement/PageManagement/PageBuilding/pageBuildingEndPointLogic";

export interface EditPageMetaProps {
  editPageMetaOpen: any;
  setEditPageMetaOpen: any;
  uniquePostTypes: any;
  uniquePostStatuses:any;
  singlePageTitle:any;
  setSinglePageTitle:any;
  setPageStatus:any;
  pageStatus:any;
  setPageType:any;
  pageType:any;
  setIsMetaUpdated:any;
  siteUid:any;
  pageUid:any;
  oauthTokenHeader:any;
  pageContent:any;

}

export default function EditPageMeta({ editPageMetaOpen, setEditPageMetaOpen , uniquePostTypes , uniquePostStatuses , pageStatus, pageType , singlePageTitle,
  setSinglePageTitle , setPageStatus , setPageType , setIsMetaUpdated ,siteUid,pageUid,oauthTokenHeader,pageContent
  }: EditPageMetaProps) {

  const [, setErrorMessages] = useState<any>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [ ,setDeletePageData] = useState([]);
  const [ ,setPreview] = useState([]);

    const updatedObject = {
        "data": {
          "post_content":pageContent?.post_content,
            "post_title": singlePageTitle,
            "post_status":pageStatus
        }
    }

    const updatedTitleObject = {
      "data": {
          "post_title":singlePageTitle
      }
  }


    const handleSaveChanges = async () => {
      let errors: any = {};
      let data;
  
      if (!singlePageTitle) errors.singlePageTitle = "Page Title is required";
      if (!pageStatus) errors.pageStatus = "Page Status is required";
      if (!pageType) errors.pageType = "Page Type is required";
  
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        setSnackbarMessage("Please fill in all required fields.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
  
      setErrorMessages({});
      setEditPageMetaOpen(false);
  
        
        if(pageStatus !== "bin"){
          await sitePageEndPointLogic.updatePageMeta(updatedObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setIsMetaUpdated,setPreview);
        }
        // Only call deletePage API if pageStatus is 'bin'
        if(pageStatus === "publish" || pageStatus==="draft") {
          await sitePageEndPointLogic.discardPage(siteUid,pageUid,setIsLoading,oauthTokenHeader,data);
          await sitePageEndPointLogic.updatePageMeta(updatedTitleObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setIsMetaUpdated,setPreview);
        }
        if (pageStatus === "bin") {
          await sitePageEndPointLogic.updatePageMeta(updatedTitleObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setIsMetaUpdated,setPreview);
          await pageBuildingEndPointLogic.deletePage(siteUid, pageUid, oauthTokenHeader, setIsLoading, setDeletePageData);
        }
        if (pageStatus === "pending") {
          await sitePageEndPointLogic.updatePageMeta(updatedTitleObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setIsMetaUpdated,setPreview);
        }
        setSnackbarMessage("Page meta updated successfully.");
        setSnackbarSeverity("success"); 
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };


  return (
    <>
    <Dialog open={editPageMetaOpen} fullWidth maxWidth="md" style={{padding: "16px 24px" , rowGap:5}}>
      <DialogTitle sx={{padding:"0px 0px 16px 0px",margin:"15px"}}>Edit Page Meta</DialogTitle>
      <Box p={3}>
        <Box mb={2} sx={{justifyContent:"center",display:"flex",alignSelf:"center"}}>
          <TextField 
           size="small"
           fullWidth label="Page Title"
           sx={{width:"852px"}} 
           onChange={(e)=>setSinglePageTitle(e.target.value)}
           value={singlePageTitle}
          />
        </Box>
        <Box display="flex" justifyContent="space-around" mb={2} paddingLeft="24px" paddingRight="24px">
          <Autocomplete
            disablePortal
            id="page-status"
            options={uniquePostStatuses}
            value={pageStatus}
            sx={{ width: 418 }}
            renderInput={(params) => <TextField {...params} label="Page Status" required size="small" />}
            onChange={(_,newvalue)=>setPageStatus(newvalue)}
          />
          <Autocomplete
            disablePortal
            id="page-type"
            options={uniquePostTypes}
            value={pageType}
            sx={{ width: 418 }}
            renderInput={(params) => <TextField {...params} label="Page Type" required size="small" />}
            onChange={(e,newValue)=>setPageType(newValue)}
            disabled
          />
        </Box>
        <Typography paddingLeft="24px" paddingRight="24px">All fields are mandatory.</Typography>
        <Box display="flex" justifyContent="flex-end" margin="24px" gap="8px">
          <Button variant="contained" color="secondary" endIcon={<SaveIcon />} size="medium" style={{ marginRight: '8px' }} onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <Button size="medium" onClick={() => setEditPageMetaOpen(false)} color="secondary">
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
      {snackbarMessage}
    </Alert>
  </Snackbar>
  </>
  );
}
