import React from 'react'
import MetaEntities from '../../../components/Admin/MetaEntities/MetaEntities'

const MetaEntitiesScreen = () => {
  return (
    <>
    <MetaEntities/>
    </>
  )
}

export default MetaEntitiesScreen