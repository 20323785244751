import React, {  useState } from "react";
import "./ProcessListFilters.css";
import FilterComponent from "../../../../ReusableFIlterComponent/FilterComponent";
interface Props{
  setStatus:React.Dispatch<React.SetStateAction<any>>;
  status:any;
  setPage:any;
  setSelectedProcess:any;
  ETLProcessContent:any;
  searchStatus:any;
  setSearchStatus:any;
}

export default function ProcessListFiltersItems({setStatus,status,setPage,setSelectedProcess,ETLProcessContent}:Props) {

  const clearFilters = () => {
    setSelectedProcess(null)
    setStatus(null);
  };

  const [searchStatus ,setSearchStatus] = useState("")


  const config = [
    {
        key: 'status',
        label: 'Select an Status ',
        searchTerm: searchStatus,
        onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchStatus(e.target.value),
        selectedOption: status,
        filterName: 'active',
        data: ETLProcessContent,
        onSelectionChange: (updatedSelection: any) => setStatus(updatedSelection),
        count: status && status.length,
        colorSelected: status !==null && status.length > 0 ? '#9C27B0' : '#000000DE',
        multiSelect: false,
        showSearch:false,
        valueMapping: { "true": "Enabled", "false": "Disabled" }
      },
    ]

  return (
    <>
    <FilterComponent filterConfig={config} clearFilters={clearFilters} />
    </>
  );
}
