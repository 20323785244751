import { Autocomplete, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

function DetailsConnectivity() {
  const [, setGroupName] = useState("");
  // const [isDuplicateButtonDisabled, setIsDuplicateButtonDisabled] = useState(true);
  const [, setIsDuplicateButtonDisabled] = useState(true);

  // Handle input change and validate the minimum 8 character condition
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]+$/; // Allow only letters and numbers
    if (regex.test(value)) {
      setGroupName(value);
      setIsDuplicateButtonDisabled(value.length < 8); // Enable if 8+ characters
    }
  };

  const websitelisting = [
    { label: "websiteexample1.com" },
    { label: "websiteexample2.com" },
    { label: "websiteexample3.com" },
    { label: "websiteexample4.com" },
    { label: "websiteexample5.com" },
  ];

  return (
    <>
      <Box>
        <Typography>Details & Connectivity</Typography>
        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2} mb={2}>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-flexible"
              label="Website Name" // Set your label here
              placeholder="How you like the site to be called" // Set your placeholder here
              required
              fullWidth
              color="secondary"
              size="small"
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography
              sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
            >
              minimum of 8 characters, only letters and numbers.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-flexible"
              label="Website URL" // Set your label here
              placeholder="How you like the site to be called" // Set your placeholder here
              required
              fullWidth
              color="secondary"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography
              sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
            >
              minimum of 8 characters, only letters and numbers.
            </Typography>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          sx={{ display: "flex", alignItems: "center" }}
          color="secondary"
          disabled
        >
          Link domain
          <InsertLinkIcon sx={{ ml: 1 }} />{" "}
          {/* Adjust the spacing with margin-left */}
        </Button>
      </Box>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Website type"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Industry"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Territory"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
        </Grid>

        <Typography
          sx={{ marginTop: "16px", fontSize: "12px", color: "#00000099" }}
        >
          All fields are mandatory.
        </Typography>
      </Box>
    </>
  );
}

export default DetailsConnectivity;
