import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EngagementIncentivesTabs from "./EngagementIncentivesTabs";
import { useState } from "react";

const EngagementIncentives = ({ userToken ,response }: { userToken: any , response : any }) => {

  const siteOptions = response?.website_permissions?.map((siteObj: any) => {
    const uid = Object.keys(siteObj)[0]; // UID of the site
    const siteName = siteObj[uid].site_name; // Site name
    return { uid, siteName }; // Return as an object with uid and siteName
  }) || [];

  const [selectedSiteUid, setSelectedSiteUid] = useState<string | null>(null); // State to store the selected site's UID
  const [showEngagementIncentivesTabs,setShowEngagementIncentivesTabs] = useState(false);

  const handleSelectSite = () =>{
    setShowEngagementIncentivesTabs(true);
  }

  return (
    <>
      <Paper sx={{ padding: "8px 16px", mb: 2 }}>
        <Typography fontSize={24}>Engagement Incentives</Typography>
        <Typography sx={{ color: "#00000099", paddingBottom: "16px" }}>
        View, Edit and Analyze budgets in relation to the actual performance of incentivized traffic
        </Typography>
        <Grid container mt={0} columnSpacing={3} mb={1}>
          <Grid item xs={10}>
            <Autocomplete
              sx={{ width: "100%" }}
              options={siteOptions}
              getOptionLabel={(option:any) => option.siteName}
              onChange={(event, newValue) => {
                setSelectedSiteUid(newValue?.uid || null); // Store the UID of the selected site
                setShowEngagementIncentivesTabs(false); // Set to false when value changes
              }}
              onInputChange={(event, inputValue, reason) => {
                if (reason === "clear") {
                  setShowEngagementIncentivesTabs(false); // Set to false when the clear button (cross) is clicked
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search site name or choose from the list"
                  label="Select Your Website"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={!selectedSiteUid}
              fullWidth
              size="large"
              color="secondary"
              sx={{ py: "7px" }}
              onClick={handleSelectSite}
            >
              SELECT SITE
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {showEngagementIncentivesTabs &&
      <Paper sx={{ padding: "8px 16px" }}>
      <EngagementIncentivesTabs userToken={userToken} selectedSiteUid={selectedSiteUid} />
      </Paper>}
    </>
  );
};

export default EngagementIncentives;
