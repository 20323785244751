import {useState, useEffect} from 'react'
import { Grid, Card,CardContent,List,ListItem,ListItemText,Typography, IconButton, LinearProgress} from "@mui/material";
import { trackPromise } from 'react-promise-tracker';
import { ManualExpenseEndPointLogic } from '../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


interface FileUploadProps {
  oauthTokenHeader: string;
  singleExpneseShareUID: any;
  open: boolean;
}

const ManualExpenceFileUpload = ({open, oauthTokenHeader, singleExpneseShareUID}:FileUploadProps) => {
const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

useEffect(() => {
  const fetchUploadedFiles = async () => {
    try {
      if(open) {
        await ManualExpenseEndPointLogic.getFiles(oauthTokenHeader, singleExpneseShareUID, trackPromise, setUploadedFiles);
      } else {
        throw new Error("User doesn't have permissions to fetch uploaded files.");
      }
    } catch (error) {
      console.error("Error while fetching uploaded files: ", error);
    }
  };

  if(open){
    fetchUploadedFiles();
  }
// eslint-disable-next-line
}, [open, oauthTokenHeader, singleExpneseShareUID]);

  return (
    <>
      <Grid item xs={12} sx={{marginBlock:1}}>
      <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Uploaded Files
                </Typography>
                {uploadedFiles.length > 0 ? (
                  <List
                    sx={{
                      maxHeight: '200px', // Set max height for scrolling
                      overflowY: 'auto',  // Enable scrolling if the content exceeds the height
                    }}
                  >
                    {uploadedFiles.filter(file => file.file).map((file, index) => (
                      <>
                        <ListItem
                          key={index}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            marginBottom: '8px',
                            padding: '8px 16px',
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <ListItemText
                              primary={ <Link
                                to={file.file} // Set the href to the file URL
                                target="_blank" // Open the link in a new tab
                                style={{ textDecoration: 'none', color: 'inherit' }}
                              >
                                {file.file.split("/").pop()} {/* File name from URL */}
                              </Link>}
                              secondary={`Completed`}
                              primaryTypographyProps={{ style: { fontWeight: 500 } }}
                              sx={{ marginLeft: '8px', flexGrow: 1 }}
                            />
                            <IconButton edge="end" disabled>
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            value={100} // Full progress to indicate completion
                            sx={{
                              width: '60%',
                              marginTop: '4px',
                              height: '4px',
                              backgroundColor: '#e0e0e0',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#9c27b0', // Customize the color of the completed progress
                              },
                            }}
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No files uploaded yet.
                  </Typography>
                )}
              </CardContent>
            </Card>
      </Grid>
    </>
  )
}

export default ManualExpenceFileUpload
