import React, { createContext, useState, Dispatch, SetStateAction, ReactNode, useCallback } from 'react';
interface SharedStateContextType {
    pageRefreshStatus: any;
    setPageRefreshStatus: Dispatch<SetStateAction<any>>;
    load: any;
    setLoad: Dispatch<SetStateAction<any>>;
    handleCreateClose: () => void;
    shouldRunEffect: boolean;
    setShouldRunEffect: React.Dispatch<React.SetStateAction<boolean>>;
}
const SharedStateContext = createContext<SharedStateContextType>({
    pageRefreshStatus: null,
    setPageRefreshStatus: () => {},
    load:null,
    setLoad:() => {},
    handleCreateClose: () => {},
    shouldRunEffect: false,
    setShouldRunEffect: () => {},
});
export const SharedStateProvider = ({ children }: { children: ReactNode }) => {
  const [pageRefreshStatus, setPageRefreshStatus] = useState<any>(false);
  const [load,setLoad] = useState(false);
  const [shouldRunEffect, setShouldRunEffect] = useState(false);

  const handleCreateClose = useCallback(() => {
    setShouldRunEffect(true); // Set flag to true to trigger the effect
  }, []);
  return (
    <SharedStateContext.Provider value={{ pageRefreshStatus, setPageRefreshStatus ,setLoad,load,handleCreateClose,shouldRunEffect, setShouldRunEffect }}>
      {children}
    </SharedStateContext.Provider>
  );
};
export default SharedStateContext;
