import { Typography } from "@mui/material";
import { Box} from "@mui/system";

function CustomDNSmanager() {
  return (
    <>
      <Box mt={1}>
        <Typography fontWeight={500}>Pros:</Typography>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Full Control
          </Typography>
          <Typography>
            Users retain full control over their DNS settings and
            configurations.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Flexibility
          </Typography>
          <Typography>
            Easier to make changes and adjustments as needed.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Independence
          </Typography>
          <Typography>
            Not reliant on a third-party provider for DNS management.
          </Typography>
        </Box>
      </Box>
      <Box mt={1}>
        <Typography fontWeight={500}>Cons:</Typography>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Technical Knowledge Required
          </Typography>
          <Typography>
            Users need to understand DNS management and configurations.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Maintenance
          </Typography>
          <Typography>
            Users are responsible for ongoing maintenance and troubleshooting.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Potential for Misconfiguration
          </Typography>
          <Typography>
            Higher risk of errors during setup, which could lead to downtime or
            faulty connection.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default CustomDNSmanager;
