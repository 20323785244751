import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Paper, Typography, InputAdornment, Autocomplete, CircularProgress, Snackbar, Alert } from "@mui/material";
import {useEffect, useState } from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {Stack } from "@mui/system";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import AdditionalContentAttachments from "../CustomTableActivityNotes/AdditionalContentAttachments";
import CancelIcon from '@mui/icons-material/Cancel';
import { setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import { ActivityNotesEndPointLogic } from '../../../../logic/AssetManagement/ActivityNotes/ActivityNotesLogic/ActivityNotesEndPointLogic';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import store from '../../../../store';
import { ActivityNotesDocs } from '../CustomTableActivityNotes/ActivityNotesDocs';
import { ActivityNotesFileUpload } from '../CustomTableActivityNotes/ActivityNotesFileUpload';

interface EditNoteDialogProps {
  oauthTokenHeader:any
  result:any
  siteNames:any
  ActivityNotesContent:any
  setActivityNotesContent:any
  setIsCardVisible:any
  isCardVisible:any
  fileBase64:any,
  setFileBase64:any,
  selectedRows:any,
  setSelectedRows:any
  selectedNoteShareduid:any
  setSelectedNoteShareduid:any
  open: boolean;
  handleClose: () => void;
  note: string;
  UpdateActivityNote: Function
  userData:any
  selectedAccount:any
  labelsContent:any
  postFileUpload:Function
  fileBase64s:any 
  setFileBase64s:any
  getFileContent:any 
  getFiles:any
  isThisAdd:any
  files:any 
  setFiles:any
  isDragActive:any
  setIsDragActive:any
  updatedActivityNotesContent:any
  isLoading:any
}

interface Option {
  label: string;
  value: string; // Add this line to define the value property
}

interface Note {
  shared_uid: any;
  note_type: any;
  note: any;
  labels: any;
  activity_date: any;
  modified: any;
  modified_by: any;
  created: any;
  created_by: any;
  website_Uid: any; // Make it optional if it's not always present
}

interface SingleActivityNotesContent {
  [key: string]: Note[]; // Dynamic keys with an array of Note objects
}

const EditNoteDialog: React.FC<EditNoteDialogProps> = ({result,ActivityNotesContent,siteNames,setActivityNotesContent,setIsCardVisible,isCardVisible,fileBase64,setFileBase64,selectedRows,setSelectedRows,oauthTokenHeader,labelsContent,selectedAccount, userData, open, handleClose, note, isLoading }) => {
const [AddnewNote, setAddnewNote] = useState(note);
const [date,setDate]=useState("");
const [alertOpen, setAlertOpen] = useState(false);
const [, setSelectedAsset] = useState("")
const [singleActivityNotesContent, setSingleActivityNotesContent] = useState<SingleActivityNotesContent>({});
const [selectedStakeHolder, setSelectedStakeHolder] = useState("")
const [selectedLabel, setSelectedLabel] = useState<any>([]); // Initialize as an array of strings
const [fileName, setFileName] = useState(''); // file name
const [loading, setLoading] = useState(true); // Add a loading state
const [success, setSuccess] = useState("");
// const [compareEmailForEdit, setCompareEmailForEdit] = useState(false)
const singleNoteShareUID = selectedRows[0]?.shared_uid;


// useEffect(() => {
//   if (selectedRows[0]?.created_by === userData?.data?.user_details?.email) {
//    setCompareEmailForEdit(true)
//   }
//  }, [selectedRows, userData]);

useEffect(() => {
  const fetchSingleActivityNotesData = async() => {
    setIsLoading(true);
    setLoading(true); // Set loading to true when fetching data
    try{
      if(singleNoteShareUID !== null && open){
        await ActivityNotesEndPointLogic.getSingleActivityNotesContent(oauthTokenHeader,setSingleActivityNotesContent, singleNoteShareUID,);
      }
    } catch(error:any) {
      throw new Error("Error fetching single activity notes data: ", error);
    } finally {
      setIsLoading(false);
      setLoading(false); // Set loading to false when data is fetched
    }
  }
  fetchSingleActivityNotesData();
}, [open,oauthTokenHeader,singleNoteShareUID]);

const updateData:any = {
  "data": {
    "note_type": `${selectedStakeHolder ? selectedStakeHolder : selectedRows[0]?.note_type}`,
    "note": `${AddnewNote ? AddnewNote : selectedRows[0]?.note}`,
    "labels": selectedLabel!==null ? selectedLabel : selectedRows[0]?.labels__labels,
    "activity_date": date ? date : selectedRows[0]?.activity_date,
  }
};

const handleFileUpload = async() => {
  setLoading(true); // Set loading to true when uploading files
  if(fileBase64 && fileBase64?.length > 0){
    Promise.all(
      fileBase64.map((file:any) => {
        const singleFileData = {
          "object_type": "business_unit_activity_notes",
          "file_name": file.name,
          "file": file.base64
        };
        return ActivityNotesEndPointLogic.fileUploadME(oauthTokenHeader,singleFileData, singleNoteShareUID)
      })
    ).then(() => {
      setFileBase64([]); // Clear the fileBase64 array
      setIsCardVisible(false);
    }).finally(() => {
       setLoading(false); // Set loading to false when all files are uploaded
    }).catch((error:any) => {
       console.error("Error uploading files: ", error);
       setLoading(false); // Stop loading in case of error
       throw new Error("Error uploading files: ", error);
    })
  } else {
    setLoading(false); // Stop loading if there are no files to upload
  }
};

const handleEdit = async() =>{
  setLoading(true);
  if(oauthTokenHeader){
    await ActivityNotesEndPointLogic.updateActivityNote(oauthTokenHeader,updateData,singleNoteShareUID);
    await handleFileUpload()
    .then(() => {
      handleClose();
      setDate('');
      setAddnewNote('');
      setSelectedStakeHolder('');
      setSelectedLabel([]);
      ActivityNotesEndPointLogic.getActivityNotesContent(oauthTokenHeader,setActivityNotesContent);
      setLoading(false);
      setSuccess("Expense Edited Successfully");
      setSelectedRows([]);
    })
  } else {
    store.dispatch(setDisplayErrorSnackBar({ message: "User Not Authorised", type: 'error' }));
  }
}

const stakeHolder = [
    { label: 'PPC' },
    { label: 'BizDev' },
]
const options: Option[] = userData?.data?.website_permissions?.map((item:any) => {
  const key = Object.keys(item)[0];
  const siteName = item[key].site_name;
  return { label: siteName, value: key };
});
const labels = Array.isArray(labelsContent) ? labelsContent.map((item) => item?.label) : [];

const handleAlertClose = () => {
  setAlertOpen(false);  // Close Snackbar
};

useEffect(() => {
  if (alertOpen) {
    const timer = setTimeout(() => {
      setAlertOpen(false);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timer when component unmounts or alertOpen changes
  }
}, [alertOpen]);

useEffect(() => {
  if (Object.keys(singleActivityNotesContent)?.length > 0) {
    const websiteSharedUid = Object.keys(singleActivityNotesContent)[0]; // Get the first key
    const activityNotes = singleActivityNotesContent[websiteSharedUid];
    setAddnewNote(activityNotes[0]?.note); // Example of setting the note content
    setSelectedStakeHolder(activityNotes[0]?.note_type); // Example of setting the stakeholder
    setSelectedLabel(activityNotes[0]?.labels || []); // Example of setting the labels
    setDate(dayjs(activityNotes[0]?.activity_date).format("YYYY-MM-DD")); // Example of setting the date
  }
}, [open, singleActivityNotesContent]); // Run this effect when singleActivityNotesContent changes

const handleCancel = () =>{
  handleClose();
  setSelectedAsset('')
  setSelectedStakeHolder('')
  setDate('')
  setAddnewNote('')
  setSelectedLabel([])
  setSelectedRows([]);
}

  return (
    <>
     {loading ? <SimpleBackdrop /> :
     <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "900px", maxWidth: "900px"}}}>
      <Paper sx={{padding: "24px 24px 20px 24px" }}>
      {Object.keys(success)?.length >= 1 && (
        <Snackbar onClose={handleAlertClose} autoHideDuration={3000}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Expense Created Successfully
          </Alert>
        </Snackbar>
      )}
        <DialogTitle sx={{padding:"0px 0px 16px 0px"}}>Edit Note</DialogTitle>
        <Typography>Details</Typography>
        <DialogContent sx={{padding:"0"}}>
          <TextField
          required
          size="small"
          color="secondary"
          sx={{width:"100%",mt:"8px", mb:"16px",padding:"0"}}
          id="outlined-helperText"
          value={AddnewNote}
          onChange={(e) => setAddnewNote(e.target.value)}
          />
          <Stack flexDirection="row" gap="16px">
          <Autocomplete
            disabled
            options={options}
            value={(siteNames.find((source: any) => source.key === selectedRows[0]?.website_shared_uid)?.site?.site_name || "")}
            onChange={(event, newValue:any) => {
              if (newValue) {
                setSelectedAsset(newValue ? newValue.label : "");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Related Asset *"
                placeholder="Please select an industry from the list."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* Optional content like icons */}
                    </InputAdornment>
                  ),
                }}
                color="secondary"
              />
            )}
            size="small"
            sx={{ width: "50%" }}
          />
          <Autocomplete
            disabled
            options={stakeHolder}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Related Brand"
                placeholder='Choose Brand from the list.'
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                    ),
                }}
              color="secondary"
            />
            )}
            size="small"
            sx={{ width: "50%"}}
          />
          </Stack>
          <Stack flexDirection="row" gap="16px" mt="16px" mb="16px">
          <Autocomplete
            disabled
            options={stakeHolder}
            value={[selectedStakeHolder ? selectedStakeHolder : selectedAccount?.note_type]}
            onChange={(event, value) => {
              if (value) {
                setSelectedStakeHolder(value?.label)
                }
              }}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Stakeholder *"
                placeholder='Please select an industry from the list.'
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                    ),
                }}
              color="secondary"
            />
            )}
            size="small"
            sx={{ width: "49%"}}
          />
          <LocalizationProvider  dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]} sx={{paddingTop:"0", overflow:"visible"}}>
            <DatePicker
              className="brandDataDatePicker"
              slotProps={{
                textField: {
                  size: "small",
                  color: "secondary",
                  required: true,
                  error: false,
                },
              }}
              format="DD-MM-YYYY"
              value={dayjs(selectedRows[0]?.activity_date)}
              onChange={(date: any) => {
                if (date && dayjs(date).isValid()) {
                  const formattedDate = dayjs(date).format("YYYY-MM-DD");
                  const updatedRows = [...selectedRows];
                  updatedRows[0].activity_date = formattedDate; // Use formatted date
                  setSelectedRows(updatedRows);
                  setDate(formattedDate);
                } else {
                  setDate(""); 
                }
              }}
              sx={{ width: "100%", minWidth:"418px !important"}}
              label="Start Date "
            />
          </DemoContainer>
          </LocalizationProvider>
          </Stack>
        </DialogContent>
        <Typography sx={{fontSize:"12px", lineHeight:"20px"}}>Fields marked with * are mandatory.</Typography>
        <Typography mt="16px" mb="8px">Labels</Typography>
          <Autocomplete
            multiple
            freeSolo // Allow free input
            id="size-small-outlined-multi"
            options={labels} // Using the labels array directly
            getOptionLabel={(option: any) => option} // No need for option.title, as option is already a string
            value={selectedLabel} // Use selectedLabel directly
            onChange={(event, newValue: any) => {
              // If the newValue is null or empty (cross clicked), clear the value
              setSelectedLabel(newValue ? newValue : []); // Set the selected labels
            }}
            renderInput={(params) => (
              <TextField {...params} label="Labels" placeholder="Favorites" color="secondary" />
            )}
            sx={{ width: '500px' }}
            ChipProps={{
              sx: {
                backgroundColor: '#9C27B0',
                color: '#ffffff',
              },
              deleteIcon: (
                <CancelIcon sx={{ color: "rgba(255, 255, 255, 0.5) !important" }} />
              ),
            }}
            ListboxProps={{
              sx: {
                backgroundColor: '#ffffff',
                color: '#000000',
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{
                backgroundColor: selected ? '#F7EEF9' : 'transparent',
              }}>
                {option}
              </li>
            )}
          />
        <Typography mt="16px" mb="8px">Attachments</Typography>
        {/* <AdditionalContentAttachments isDragActive={isDragActive} setIsDragActive={setIsDragActive} files={files} setFiles={setFiles} isThisAdd={isThisAdd} getFileContent={getFileContent} selectedAccount={selectedAccount} postFileUpload={postFileUpload} fileBase64s={fileBase64s} setFileBase64s={setFileBase64s} setFileName={setFileName} fileName={fileName} /> */}
        <ActivityNotesDocs setFileBase64={setFileBase64} setFileName={setFileName} fileName={fileName} setIsCardVisible={setIsCardVisible} isCardVisible={isCardVisible} fileBase64={fileBase64} />
        <ActivityNotesFileUpload open={open} singleNoteShareUID={singleNoteShareUID} oauthTokenHeader={oauthTokenHeader} />
        <DialogActions sx={{padding:"16px 0 0 0"}}>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button disabled={!AddnewNote || !date || isLoading} onClick={handleEdit} color="secondary" variant="contained">
            {isLoading ? <CircularProgress /> : <>SAVE <CheckOutlinedIcon fontSize="small" sx={{ marginLeft: "8px" }} /></>}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
     }
    </>
  );
};

export default EditNoteDialog;
