import {
  Autocomplete,
  Alert,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  Paper,
  TextField,
  Typography,
  InputAdornment
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack } from "@mui/system";
import { useState, useEffect } from "react";
import { QuarterlySpeedGoalsEndPointLogic } from '../../../../../../logic/AssetManagement/Health/Quarterly Website Speed Goals/QuarterlySpeedGoalsEndPointLogic';
import store from '../../../../../../store';
import { setDisplayErrorSnackBar } from '../../../../../../features/userSlice';

interface CreateSetNewModalProps {
  oauthTokenHeader: any;
  tableData: any;
  hasEditPermissions: any;
  hasGetPermissions: any;
  selectedSharedUid: any;
  openModal: boolean;
  onCloseModal: any;
  quarterlyGoalsData: any;
  setQuarterlyGoalsData: any;
}
const quateryear = [
  { label: "Q1"},
  { label: "Q2"},
  { label: "Q3"},
  { label: "Q4"},
];

const currentYear = new Date().getFullYear(); // Get the current year
const years = Array.from({ length: 13 }, (_, i) => currentYear - 10 + i).reverse();

export default function CreateSetNewGoal({oauthTokenHeader, hasEditPermissions, hasGetPermissions,tableData, selectedSharedUid, openModal,onCloseModal,setQuarterlyGoalsData,quarterlyGoalsData}: CreateSetNewModalProps) {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [quarter, setQuarter] = useState<any>('');
  const [year, setYear] = useState<any>('');
  const [mobileLCP, setMobileLCP] = useState('');
  const [mobileINP, setMobileINP] = useState('');
  const [mobileCLS, setMobileCLS] = useState('');
  const [mobileFCP, setMobileFCP] = useState('');
  const [mobileTTFB, setMobileTTFB] = useState('');
  const [mobileMobileSpeedScore, setMobileMobileSpeedScore] = useState('');
  const [desktopLCP, setDesktopLCP] = useState('');
  const [desktopINP, setDesktopINP] = useState('');
  const [desktopCLS, setDesktopCLS] = useState('');
  const [desktopFCP, setDesktopFCP] = useState('');
  const [desktopTTFB, setDesktopTTFB] = useState('');
  const [desktopFID, setDesktopFID] = useState('');
  const [device , setDevice] = useState<any[]>([]);

  useEffect(()=> {
    if (openModal) {
      QuarterlySpeedGoalsEndPointLogic.getDevices(oauthTokenHeader,setDevice); // Get devices
    }
  },[openModal,oauthTokenHeader])

  const mobileDevice = device.find((deviceName:any) => deviceName.device_type_name === "Mobile"); // Find mobile device
  const desktopDevice = device.find((deviceName:any) => deviceName.device_type_name === "Desktop"); // Find desktop device

  const createNewGoal = async () => {
    const MobileData = {
      year: parseInt(year),
      quarter: parseInt(quarter.replace('Q', '')), // Exclude 'Q' and convert to number
      device: mobileDevice?.shared_uid, // Mobile device
      largest_contentful_paint: parseFloat(mobileLCP),
      interaction_to_next_paint: parseFloat(mobileINP),
      cumulative_layout_shift: parseFloat(mobileCLS),
      first_contentful_paint: parseFloat(mobileFCP),
      time_to_first_byte: parseFloat(mobileTTFB),
      mobile_speed_score_from_ads_platform: parseInt(mobileMobileSpeedScore),
    };
    const DesktopData = {
      year: parseInt(year),
      quarter: parseInt(quarter.replace('Q', '')), // Exclude 'Q' and convert to number
      device: desktopDevice?.shared_uid, // Desktop device
      largest_contentful_paint: parseFloat(desktopLCP),
      interaction_to_next_paint: parseFloat(desktopINP),
      cumulative_layout_shift: parseFloat(desktopCLS),
      first_contentful_paint: parseFloat(desktopFCP),
      first_input_delay: parseFloat(desktopFID),
      time_to_first_byte: parseFloat(desktopTTFB),
      mobile_speed_score_from_ads_platform: parseInt(mobileMobileSpeedScore),
    };
    try {
      if(hasEditPermissions) {
        await QuarterlySpeedGoalsEndPointLogic.createQuarterlySpeedGoals(oauthTokenHeader,selectedSharedUid,MobileData); // Create mobile quarterly speed goals
        await QuarterlySpeedGoalsEndPointLogic.createQuarterlySpeedGoals(oauthTokenHeader,selectedSharedUid,DesktopData); // Create desktop quarterly speed goals
        setQuarter('');
        setYear('');
        setMobileLCP('');
        setMobileINP('');
        setMobileCLS('');
        setMobileFCP('');
        setMobileTTFB('');
        setMobileMobileSpeedScore('');
        setDesktopLCP('');
        setDesktopINP('');
        setDesktopCLS('');
        setDesktopFCP('');
        setDesktopTTFB('');
        setDesktopFID('');
        setSnackbarOpen(false);
        setSnackbarMessage('');
        onCloseModal();
      } else {
        throw new Error("User doesn't have permissions to create Quarterly Goals");
      }
      if(hasGetPermissions) {
        await QuarterlySpeedGoalsEndPointLogic.getQuarterlySpeedGoals(oauthTokenHeader, selectedSharedUid, setQuarterlyGoalsData, setError);
      }
    } catch(error) {
      setSnackbarOpen(true);
      setSnackbarMessage('error');
      console.error("Error while creating Sequence", error);
    } finally {
      setLoading(false);
      onCloseModal();
    }
  }

  useEffect(() => {
    if(openModal) {
      setQuarter('');
      setYear('');
      setMobileLCP('');
      setMobileINP('');
      setMobileCLS('');
      setMobileFCP('');
      setMobileTTFB('');
      setMobileMobileSpeedScore('');
      setDesktopLCP('');
      setDesktopINP('');
      setDesktopCLS('');
      setDesktopFCP('');
      setDesktopTTFB('');
      setDesktopFID('');
      setSnackbarOpen(false);
      setSnackbarMessage('');
    }
  }, [openModal]);

  const handleCloseCreateDialog = () => {
    onCloseModal();
  }

  const validate = async() => {
    // Validate quarter and year
    if (!quarter) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please select a quarter.");
      setIsValid(false); // Validation failed
      return false; // Validation failed
    }
    if (!year) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please select a year.");
      setIsValid(false); // Validation failed
      return false; // Validation failed
    }
    // Validate all fields
    if(!mobileLCP || !mobileINP || !mobileCLS || !mobileFCP || !mobileTTFB || !mobileMobileSpeedScore || !desktopLCP || !desktopINP || !desktopCLS || !desktopFCP || !desktopTTFB ) {
      setSnackbarOpen(true);
      setSnackbarMessage("All fields are mandatory.");
      setIsValid(false); // Validation failed
      return false; // Validation failed
    }
    // Validate mobile Mobile Speed Score
    if (mobileMobileSpeedScore) {
      const score = parseInt(mobileMobileSpeedScore, 10); // Convert the score to an integer
      if (score < 1 || score > 10) {
        setSnackbarOpen(true);
        setSnackbarMessage("Mobile Speed Score must be between 1 and 10.");
        setIsValid(false); // Validation failed
        return false; // Validation failed
      }
    }

    // Check if the quarterly goal is unique
    const selectedQuarter = parseInt(quarter.replace('Q', '')); // Convert 'Q1', 'Q2', etc. to 1, 2, etc.
    const selectedYear = parseInt(year); // Convert the year string to a number
    // Check if the quarterly goal is unique
    const isQuarterlyGoalUnique = tableData?.every((goal: any) => {
      const [goalQuarter, goalYear] = goal?.period?.split('/').map(Number); // Split '3/2006' into [3, 2006]
      return !(goalQuarter === selectedQuarter && goalYear === selectedYear); // Check if the quarter, year, and device match
    });
    if (!isQuarterlyGoalUnique && tableData !== undefined) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Goal already exists.', type: 'error' }));
      setIsValid(false);
      return false;
    }

    setIsValid(true); // Validation passed
    return true; // Validation passed
  }

  const handleCreateNewGoal = async () => {
    const isFormValid = await validate();
    if (!isFormValid) return; // Only proceed if the form is valid
    try {
      setLoading(true);
      await createNewGoal();
    } catch (error) {
      console.error("Error while creating Sequence", error);
    } finally {
      setLoading(false);
    }
  }

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={ !isValid || quarterlyGoalsData?.reason !== "success" ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
    };

  return (
    <>
      {renderSnackbar()}
      <Dialog
      open={openModal}
      onClose={onCloseModal}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px",overflow: "hidden" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px", overflow: "hidden" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
           Set New Quarterly Goals
        </DialogTitle>

        {/* preiod */}
        <Box>
        <DialogTitle sx={{ fontSize:"16px", padding: "0px 0px 8px 0px",fontWeight:"400" }}>
         Period
        </DialogTitle>
        <Stack flexDirection={"row"} gap={2}>
          <Box>
          <Autocomplete
            disablePortal
            options={quateryear.map((quater)=>quater.label)}
            sx={{ width: 418 }}
            size="small"
            value={quarter}
            onChange={(e, value) => setQuarter(value)}
            renderInput={(params) => <TextField {...params} label="Quarter *" color="secondary"/>}
        />
        <Typography sx={{fontSize:"12px", lineHeight:"20px", letterSpacing:"0.4", color:'#00000099', marginTop:"4px", marginLeft:"16px"}}>Pick a quarter that is later than the current date</Typography>
        </Box>
        <Box>
        <Autocomplete
          disablePortal
          options={years}
          sx={{ width: 418 }}
          size="small"
          value={year}
          onChange={(e, value) => setYear(value)}
          renderInput={(params) => <TextField {...params} label="Year *" color="secondary"/>}
        />
        <Typography sx={{fontSize:"12px", lineHeight:"20px", letterSpacing:"0.4", color:'#00000099', marginTop:"4px", marginLeft:"16px"}}>Pick a year that is later than the current date</Typography>
        </Box>
        </Stack>
        </Box>
        {/* snackbar
        {mobileCLS || mobileFCP || mobileINP || mobileLCP || mobileMobileSpeedScore || mobileTTFB || desktopCLS || desktopFCP || desktopINP || desktopLCP || desktopTTFB ? (
          null
        ) : (
        <SnackbarContent
          sx={{
            background: "#FFF4E5",
            color: "#ED6C02",
            marginTop: "16px",
            boxShadow: "none",
            border: "1px solid #D32F2F80",
            padding: "0 16px",
          }}
          message={
            <Box display="flex">
              <ErrorOutlineIcon sx={{ marginRight: "8px", color: "#ED6C02" }} />
              <Box>
                <Typography variant="body1" fontWeight="500" sx={{ marginBottom: "4px" }}>
                  Quarterly Goals Replicated from Last Quarter
                </Typography>
                <Typography variant="body2">
                  Review and customize them to align with your current goals.
                </Typography>
              </Box>
            </Box>
          }
        />
        )} */}
        {/* mobile */}
        <Box marginTop={2}>
          <DialogTitle sx={{ fontSize:"16px", padding: "0px 0px 8px 0px",fontWeight:"400" }}>
          Mobile
          </DialogTitle>
          <Stack gap={2}>
            <Stack flexDirection={"row"} gap={2}>
                  <TextField
                  id="outlined-multiline-flexible"
                  label="LCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileLCP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileLCP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="INP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileINP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileINP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="CLS"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileCLS}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileCLS(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
                  <TextField
                  id="outlined-multiline-flexible"
                  label="FCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileFCP} // Display value with unit "s"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileFCP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="TTFB"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileTTFB}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileTTFB(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Mobile Speed Score"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={mobileMobileSpeedScore}
                  onChange={(e) => setMobileMobileSpeedScore(e.target.value)}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
            </Stack>
          </Stack>
        </Box>
        {/* desktop */}
          <Box marginTop={2}>
          <DialogTitle sx={{ fontSize:"16px", padding: "0px 0px 8px 0px",fontWeight:"400" }}>
          Desktop
          </DialogTitle>
          <Stack gap={2}>
            <Stack flexDirection={"row"} gap={2}>
                  <TextField
                  id="outlined-multiline-flexible"
                  label="LCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={desktopLCP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopLCP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="INP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={desktopINP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopINP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="CLS"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={desktopCLS}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopCLS(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
                  <TextField
                  id="outlined-multiline-flexible"
                  label="FCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={desktopFCP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopFCP(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="FID"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  value={desktopFID}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopFID(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="TTFB"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  value={desktopTTFB}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setDesktopTTFB(e.target.value)}}
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
            </Stack>
          </Stack>
        </Box>
        <Typography sx={{fontSize:"12px", lineHeight:"20px", letterSpacing:"0.4", color:'#00000099', marginTop:"16px"}}>All fields are mandatory.</Typography>
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
            <Button color="secondary"  onClick={handleCloseCreateDialog}>CANCEL</Button>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleCreateNewGoal}
              disabled={!quarter || !year || !mobileLCP || !mobileINP || !mobileCLS || !mobileFCP || !mobileTTFB || !mobileMobileSpeedScore || !desktopLCP || !desktopINP || !desktopCLS || !desktopFCP || !desktopTTFB} // Use the isValid state to determine if the button should be enabled
            >
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}> {loading ? 'Creating...' : 'SET GOALS'} <CheckIcon /></Stack>
            </Button>
        </Stack>
      </Paper>
      </Dialog>
    </>
  );
}
