import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const CompetitorsPageSpeedDashboardFilter = () => {
  const competitorlisting = [
    { label: "Competitor 01" },
    { label: "Competitor 02" },
    { label: "Competitor 03" },
    { label: "Competitor 04" },
    { label: "Competitor 05" },
  ];

  const device = [{ label: "Desktop" }, { label: "Mobile" }];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{ my: 1 }}>
        <Grid item xs={5}>
          <Autocomplete
            multiple
            fullWidth
            size="small"
            color="secondary"
            options={competitorlisting}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => {
              const key = option.label;
              return (
                <li {...props} key={key}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="secondary"
                  />
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Competitor"
                fullWidth
                color="secondary"
                placeholder="Search Competitor or choose from the list"
                sx={{ whiteSpace: "nowrap" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ pt: 0 }}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "100%" }}
                label="Start Date"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ pt: 0 }}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "100%" }}
                label="End Date"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            fullWidth
            options={device.map((devicename) => devicename.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Device"
                placeholder="Select a Device "
                InputProps={{
                  ...params.InputProps,
                }}
                color="secondary"
              />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={1} textAlign={"right"}>
          <Button variant="contained" color="secondary">
            Compare
          </Button>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup row sx={{alignItems:'center'}}>
            <FormLabel id="demo-row-radio-buttons-group-label" sx={{mr:2}}>
            Display settings:
            </FormLabel>
            <FormControlLabel
              value="competitor"
              control={<Radio size="small" color="secondary" />}
              label="Group sites by competitor"
            />
            <FormControlLabel
              value="individually"
              control={<Radio size="small" color="secondary" />}
              label="Show every site individually"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default CompetitorsPageSpeedDashboardFilter;
