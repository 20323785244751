import EditWebsite from "../../../components/AssetManagement/Website/WebsiteActions/EditWebsite/EditWebsite"

function WebsiteEditScreen() {
  return (
    <div>
        <EditWebsite></EditWebsite>
    </div>
  )
}

export default WebsiteEditScreen