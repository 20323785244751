import { Autocomplete, TextField, Box, InputAdornment,Typography, Popper } from "@mui/material";
import "./Card.css";
import SearchIcon from '@mui/icons-material/Search';
import { WebsitePermissions } from "../../../../data/user/WebsitePermissions";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import { WebsiteWithUuid } from "../../../../data/website/Website";

export interface CardProps {
  selectedSiteName: string | undefined;
  setSelectedSiteName: (name: string) => void;
  userWebsitePermissions: WebsitePermissions;
}
export default function Card({
  selectedSiteName,
  setSelectedSiteName,
  userWebsitePermissions,
}: CardProps) {


 
  
  const location = useLocation(); // Hook to get the current location
  const defaultObject = useMemo(() => {  // Use the useMemo hook to store the default object
    return {
    "default": {
      'site_name': 'Search site name or choose from the list',
      'site_url': 'Search site name or choose from the list'
    }
  }
  }, []);

  const CustomPopper = (props: any) => {
    return <Popper {...props} modifiers={[{ name: 'flip', enabled: false }]} placement="bottom-start" />;
  };


  const selectedSite = useMemo(() => {
    return userWebsitePermissions.find((website) => Object.values(website)[0].site_name === selectedSiteName) ?? null;
    // eslint-disable-next-line
  }, [selectedSiteName])

  const [value, setValue] = useState(selectedSite || defaultObject) as any;
  useEffect(() => {
    if (location.pathname === '/assets/page_management')  // Check if the current path is '/assets/page_management'
    {
      setValue(defaultObject);
    }
  }, [location.pathname, defaultObject]); // Depend on the pathname to trigger on URL change

  const handelSelectSite = (event: any, selectedPermission: WebsiteWithUuid) => {
    if (selectedPermission) {
      setSelectedSiteName?.(Object.values(selectedPermission)[0].site_name);
      setValue(selectedPermission);
    }
  };
  return (
    <div className="card5">
      <div className="paper32">
        <div className="card-elements23">
          <div className="cardmedia23">
            <img
              className="library-placeholder-image23"
              alt=""
              src="/-library--placeholder-image.svg"
            />
          </div>
          <div className="cardheader-parent4">
            <div className="cardheader34">
              <div className="content55">
                <p className="header43">PAGE BUILDER - ASSET SELECTION</p>
                <div className="subheader47">{`{Subheader}`}</div>
              </div>
              <div className="iconbutton271">
                <img className="icon577" alt="" src="/icon2.svg" />
              </div>
            </div>
            <div className="cardactions42">
              <div className="button86">
                <div className="base28">
                  <img
                    className="masked-icon56"
                    alt=""
                    src="/masked-icon9.svg"
                  />
                  <div className="button87">DUPLICATE</div>
                  <img
                    className="masked-icon57"
                    alt=""
                    src="/masked-icon1111.svg"
                  />
                </div>
              </div>
              <div className="button86">
                <div className="base28">
                  <img
                    className="masked-icon56"
                    alt=""
                    src="/masked-icon.svg"
                  />
                  <div className="button87">PREVIEW</div>
                  <img
                    className="masked-icon57"
                    alt=""
                    src="/masked-icon2111.svg"
                  />
                </div>
              </div>
              <div className="button86">
                <div className="base28">
                  <img
                    className="masked-icon56"
                    alt=""
                    src="/masked-icon.svg"
                  />
                  <div className="button87">PUBLISH</div>
                  <img
                    className="masked-icon57"
                    alt=""
                    src="/masked-icon3.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cardcontent-parent9">
            <div className="cardcontent45">
            <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            handelSelectSite(event, newValue);
          }}
          noOptionsText={<Typography variant="body1">No matches were found</Typography>}
          getOptionLabel={(option: any) =>
            (Object.values(option)[0] as any)?.site_name?.toString()
          }
          options={userWebsitePermissions as any}
          sx={{
            width: 1400,
            '@media (max-width: 1435px)': {
              width: 900,
            },
            '@media (max-width: 1200px)': {
              width: 700,
            },
          }}
          renderOption={(propsOptions, option: any, { selected }) => (
            <Box
              component="li"
              {...propsOptions}
              key={(Object.values(option)[0] as any)?.site_name as any}
              sx={{
                backgroundColor: selected ? "#F7EEF9 !important" : "inherit",
              }}
            >
              {(Object.values(option)[0] as any)?.site_name as any}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Website"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
    />
  )}
  size="small"
  PopperComponent={CustomPopper}
/>

            </div>
            <div className="cardcontent46">
              <div className="autocomplete14">
                <div className="autocomplete14">
                  <div className="input44">
                    <div className="container272">
                      <div className="adorn-start-container44">
                        <img
                          className="icon577"
                          alt=""
                          src="/searchfilled2.svg"
                        />
                      </div>
                      <div className="autocompletetag27">
                        <div className="chip162">
                          <div className="avatar93">
                            <div className="border100">
                              <div className="badge100" />
                            </div>
                            <div className="op86">OP</div>
                            <div className="min-width283" />
                          </div>
                          <div className="typography387">
                            <div className="chip163">Chip</div>
                          </div>
                          <img
                            className="cancelfilled-icon81"
                            alt=""
                            src="/cancelfilled.svg"
                          />
                        </div>
                        <div className="chip164">
                          <div className="avatar93">
                            <div className="border100">
                              <div className="badge100" />
                            </div>
                            <div className="op86">OP</div>
                            <div className="min-width283" />
                          </div>
                          <div className="typography387">
                            <div className="chip163">Chip</div>
                          </div>
                          <img
                            className="cancelfilled-icon81"
                            alt=""
                            src="/cancelfilled.svg"
                          />
                        </div>
                        <div className="chip164">
                          <div className="avatar93">
                            <div className="border100">
                              <div className="badge100" />
                            </div>
                            <div className="op86">OP</div>
                            <div className="min-width283" />
                          </div>
                          <div className="typography387">
                            <div className="chip163">Chip</div>
                          </div>
                          <img
                            className="cancelfilled-icon81"
                            alt=""
                            src="/cancelfilled.svg"
                          />
                        </div>
                      </div>
                      <div className="min-height305" />
                      <div className="value44">Value</div>
                      <div className="placeholder44">Placeholder</div>
                      <div className="min-width286" />
                      <img
                        className="arrowdropdownfilled-icon21"
                        alt=""
                        src="/arrowdropdownfilled1.svg"
                      />
                      <div className="autocompleteclose27">
                        <img
                          className="closefilled-icon27"
                          alt=""
                          src="/closefilled1.svg"
                        />
                      </div>
                    </div>
                    <div className="label-container26">
                      <div className="label131">Select Page</div>
                    </div>
                  </div>
                  <div className="formhelpertext53">
                    <div className="helper-text53">Helper text</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider-horizontal255">
            <div className="min-height306" />
            {/* <div className="divider256" /> */}
          </div>
          <div className="cardcontent-parent10">
            <div className="cardcontent45">
              <div className="autocomplete14">
                <div className="input45">
                  <div className="label131">Page Group:</div>
                  <div className="content56">
                    <div className="adorn-start-container45">
                      <div className="icon578">
                        <img className="icon577" alt="" src="/icon2.svg" />
                      </div>
                    </div>
                    <div className="min-height307" />
                    <div className="value45">Value</div>
                    <div className="value44">Placeholder</div>
                    <div className="adorn-end-container17">
                      <img
                        className="removeredeyefilled-icon16"
                        alt=""
                        src="/removeredeyefilled.svg"
                      />
                    </div>
                    <div className="min-width287" />
                  </div>
                  <div className="underline12" />
                </div>
                <div className="formhelpertext54">
                  <div className="helper-text53">Helper text</div>
                </div>
              </div>
            </div>
            <div className="cardcontent45">
              <div className="autocomplete14">
                <div className="input45">
                  <div className="label131">Last Modified:</div>
                  <div className="content56">
                    <div className="adorn-start-container45">
                      <div className="icon578">
                        <img
                          className="icon577"
                          alt=""
                          src="/starfilled3.svg"
                        />
                      </div>
                    </div>
                    <div className="min-height307" />
                    <div className="value45">Value</div>
                    <div className="value44">Placeholder</div>
                    <div className="adorn-end-container17">
                      <img
                        className="removeredeyefilled-icon16"
                        alt=""
                        src="/removeredeyefilled1.svg"
                      />
                    </div>
                    <div className="min-width287" />
                  </div>
                  <div className="underline12" />
                </div>
                <div className="formhelpertext54">
                  <div className="helper-text53">Helper text</div>
                </div>
              </div>
            </div>
            <div className="cardactions44">
              <div className="textfield21">
                <div className="input45">
                  <div className="label131">Edited by:</div>
                  <div className="content56">
                    <div className="adorn-start-container45">
                      <div className="icon578">
                        <img
                          className="icon577"
                          alt=""
                          src="/starfilled4.svg"
                        />
                      </div>
                    </div>
                    <div className="min-height307" />
                    <div className="value45">Value</div>
                    <div className="value44">Placeholder</div>
                    <div className="adorn-end-container17">
                      <img
                        className="removeredeyefilled-icon16"
                        alt=""
                        src="/removeredeyefilled2.svg"
                      />
                    </div>
                    <div className="min-width287" />
                  </div>
                  <div className="underline12" />
                </div>
                <div className="formhelpertext54">
                  <div className="helper-text53">Helper text</div>
                </div>
              </div>
              <div className="library-instance-slot136">
                <div className="instance-slot173">Action</div>
              </div>
              <div className="library-instance-slot136">
                <div className="instance-slot173">Action</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
