import { useEffect, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CardContent, List, ListItem, ListItemText, Paper, Typography,Button, Card, LinearProgress, Grid, IconButton, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
// import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ActivityNotesEndPointLogic } from '../../../../logic/AssetManagement/ActivityNotes/ActivityNotesLogic/ActivityNotesEndPointLogic';
import SimpleBackdrop from '../../../BackDrop/BackDrop';

interface FileDetails {
  name: string;
  base64: string;
  progress: number;
  status: string;
  size: number; // Add size to track the file size
}

interface props {
  oauthTokenHeader:any;
  singleNoteShareUID:any;
  files: any;
  setFiles: any;
  setIsCardVisible:any;
  isCardVisible:any;
}
function ActivityNotesAdditionalFileUpload({oauthTokenHeader,files,setFiles,singleNoteShareUID,setIsCardVisible,isCardVisible}: props) {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [fileBase64, setFileBase64] = useState<FileDetails[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const timers: any[] = [];
    fileBase64 && fileBase64.forEach((file: any, index: number) => {
      if (file.progress < 100) {
        const timer = setInterval(() => {
          setFileBase64((prevFiles: any) => {
            const newFiles = [...prevFiles];
            newFiles[index].progress = Math.min(file.progress + Math.random() * 10, 100);
            if (newFiles[index].progress === 100) {
              newFiles[index].status = 'Uploaded'; // Change status to Uploaded
            }
            return newFiles;
          });
        }, 50);
        timers.push(timer);
      }
    });

    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, [fileBase64, setFileBase64]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      processFiles(Array.from(selectedFiles));
    }
  };

  const processFiles = (selectedFiles: File[]) => {
    selectedFiles.forEach((file) => {
      const fileSizeMB = file.size / (1024 * 1024);
      const fileType = file.type;
  
      // Check file size limit
      if (fileSizeMB > 5) {
        setUploadError('File size exceeds the 5 MB limit.');
        return;
      }
  
      // Check allowed file types
      if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'application/pdf') {
        setUploadError('Only PNG, JPG, or PDF files are allowed.');
        return;
      }
      setUploadError(''); // Clear any previous error
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1]; // Extract base64 part
        if (base64String) {
          const newFile: FileDetails = {
            name: file.name,
            base64: base64String,
            progress: 0,
            status: 'Uploading',
            size: file.size
          };
          setFileBase64((prevFiles) => [...prevFiles, newFile]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async () => {
    setLoading(true);
    if (fileBase64?.length > 0) {
      try {
        await Promise.all(
          fileBase64.map((file) => {
            const singleFileData = {
              "object_type": "business_unit_activity_notes",
              "file_name": file.name,
              "file": file.base64
            };
            return ActivityNotesEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, singleNoteShareUID);
          })
        )
        .then(() => {
          fetchUploadedFiles(); // Fetch the updated file list after all uploads
          setSnackbarOpen(true);
          setSnackbarMessage('File uploaded successfully');
          setFileBase64([]); // Clear the files after upload
        })
      } catch (error:any) {
        setLoading(false); // Stop loading in case of error
        throw new Error("Error uploading files: ", error);
      } finally {
        setLoading(false); // Stop the loading spinner after uploads are done
      }
    } else {
      setLoading(false);
    }
  };

  const fetchUploadedFiles = async() => {
    await ActivityNotesEndPointLogic.getFiles(oauthTokenHeader, singleNoteShareUID, setUploadedFiles)
    .catch((error: any) => {
      throw new Error("Error fetching uploaded files: ", error);
    });
  };

  useEffect(() => {
    fetchUploadedFiles();
    // eslint-disable-next-line
  }, []);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) {
      processFiles(Array.from(droppedFiles));
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = fileBase64.filter((_: any, i: number) => i !== index);
    setFileBase64(updatedFiles);
    if (updatedFiles?.length === 0) {
      setIsCardVisible(false);
    }
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity='success'
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
    };

  return (
    <>
    {renderSnackbar()}
    <Card>
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "8px",
            paddingBlock: "24px",
            backgroundColor: isDragging ? "#9C27B01A" : "#9C27B00A", // Change background on drag
            border: isDragging ? "2px dashed #9C27B0" : "none", // Add dashed border on drag
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <UploadFileIcon color="secondary" />
          <Typography
            sx={{ display: "flex", gap: "4px" }}
            component="label"
            role={undefined}
            tabIndex={-1}
          >
            <Typography color="secondary" sx={{ textDecoration: "underline", cursor: "pointer" }}>Click to upload</Typography> or drag and drop
            <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
          </Typography>
          <Typography sx={{ color: '#00000099' }}>PNG, JPG or PDF (max. 5MB)</Typography>
          {uploadError && <Typography color="error">{uploadError}</Typography>}
        </Paper>
    </Card>
      {fileBase64 && fileBase64.map((file, index) => (
        <Card key={index} sx={{ padding: "8px 16px", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
          <div>
            <Typography sx={{ fontWeight: "500" }}>{file.name}</Typography>
            <Typography sx={{ paddingBottom: "4px" }}>{(file.size / 1024).toFixed(2)} KB • {file.status}</Typography>
            <LinearProgress color="secondary" variant="determinate" value={file.progress} />
          </div>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleRemoveFile(index)} />
        </Card>
      ))}
      <Button
        variant="contained"
        sx={{ marginTop: "16px", marginBottom: "16px" }}
        color="secondary"
        onClick={handleFileUpload}
        disabled={loading || fileBase64?.length === 0}
      >
        {loading ? 'Uploading...' : 'Upload Files'}
      </Button>
       {loading ? <SimpleBackdrop /> :(
            <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Uploaded Files
                </Typography>
                {uploadedFiles?.length > 0 ? (
                  <List
                    sx={{
                      maxHeight: '200px', // Set max height for scrolling
                      overflowY: 'auto',  // Enable scrolling if the content exceeds the height
                    }}
                  >
                    {uploadedFiles.filter(file => file.file).map((file, index) => (
                      <>
                        <ListItem
                          key={index}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            marginBottom: '8px',
                            padding: '8px 16px',
                            width: '100%',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <ListItemText
                              primary={
                                <Link
                                  to={file.file} // Set the href to the file URL
                                  target="_blank" // Open the link in a new tab
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {file.file.split("/").pop()} {/* File name from URL */}
                                </Link>
                              }
                              secondary={`Completed`}
                              primaryTypographyProps={{ style: { fontWeight: 500 } }}
                              sx={{ marginLeft: '8px', flexGrow: 1 }}
                            />
                            <IconButton edge="end" disabled>
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            value={100} // Full progress to indicate completion
                            sx={{
                              width: '60%',
                              marginTop: '4px',
                              height: '4px',
                              backgroundColor: '#e0e0e0',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#9c27b0', // Customize the color of the completed progress
                              },
                            }}
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No files uploaded yet.
                  </Typography>
                )}
              </CardContent>
            </Card>
           </Grid>
      )}
    </>
  );
}
export default ActivityNotesAdditionalFileUpload;
