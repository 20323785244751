export const dataset = [
    { x: '01/01/25', y: 10 , z: 88 },
    { x: '05/02/25', y: 40 , z: 34 },
    { x: '07/03/25', y: 60 , z: 45  },
    { x: '08/04/25', y: 80 , z: 67  },
    { x: '09/05/25', y: 90 , z: 90  },
    { x: '10/06/25', y: 55 , z: 90   },
    { x: '11/07/25', y: 83 , z: 68  },
    { x: '14/08/25', y: 90 , z: 23  },
    { x: '16/09/25', y: 100 , z: 56  },
  ]; 


  export const paint = [
    { x: '05/02/25', y: 30, z: 55 , p: 80},
    { x: '07/03/25', y: 50, z: 22 , p: 55 },
    { x: '01/01/25', y: 60, z: 70 , p: 95 },
  ];  

  export const paintMilliseconds = [
    { x: '05/02/25', y: 0},
    { x: '07/03/25', y: 350 },
    { x: '01/01/25', y: 550 },
  ]; 