import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./CompetitorsPageSpeed.css"
import CompetitorsPageSpeedDashboard from "./CompetitorsPageSpeedDashboard/CompetitorsPageSpeedDashboard";
import CompetitorsPageScanRequests from "./CompetitorsPageScanRequests/CompetitorsPageScanRequests";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface CompetitorsPageSpeedProps {
    psiRequestRows:any;
    userToken:string;
    selectedSiteUid:any;
    setRefresh:any;
    refresh:any;
  }

const CompetitorsPageSpeedTabs = ({psiRequestRows,userToken,selectedSiteUid,setRefresh,refresh}:CompetitorsPageSpeedProps) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
        setValue(1)
  }, [refresh]);

  return (
    <>
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              color: "#00000099", // Default color for unselected tabs
              "&.Mui-selected": {
                color: "#9C27B0", // Change color when the tab is selected
              },
            },
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-start",
            },
            "& .MuiTab-root:first-of-type": {
              paddingLeft: 0,
            }
          }}
        >
          <Tab label="Page Speed Dashboard" {...a11yProps(0)} />
          <Tab label="Page Scan Requests" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <CompetitorsPageSpeedDashboard />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <CompetitorsPageScanRequests psiRequestRows={psiRequestRows} userToken={userToken} selectedSiteUid={selectedSiteUid}
      setRefresh={setRefresh}/>
      </CustomTabPanel>
    </Box>
    </>
  )
}

export default CompetitorsPageSpeedTabs;