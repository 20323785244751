import React, { useEffect, useState } from 'react'
import BudgetControlFilter from './BudgetControlFilter/BudgetControlFilter'
import BudgetControlCard from './BudgetControlCard/BudgetControlCard'
import BudgetControlTable from './BudgetControlTable/BudgetControlTable'
import { Divider } from '@mui/material'
import { engagementIncentivesEndPointLogic } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/engagementIncentivesEndPointLogic'
import SimpleBackdrop from '../../../BackDrop/BackDrop'
import { useHasEngagementIncentivesPermission } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/Permissions/useHasEngagementIncentivesPermission'
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions'

const tableHeadings = {
  "brand_name": "Brand name",
  "clicks": "Clicks",
  "registrations": "Registrations",
  "ftd": "FTD",
  "cost_per_qftd": "Cost Per QFTD",
  "qftd_budget": "QFTD Budget",
  "qftd_actual": "Actual QFTD",
  "utilization" : "Utilization",
  "total_cost": "Total Cost ($)",
  "modified_by": "Modified by",
  "info": "Info",
}

interface BudgetControlProps {
  selectedSiteUid: any;
  userToken: any;
}


const BudgetControl = ({ selectedSiteUid, userToken }:BudgetControlProps) => {
  const [period,setPeriod] = useState(null);
  const [exequteQueryContent,setExequteQueryContent] = useState([]) as any;
  const [exequteQueryContentQuery2,setExequteQueryContentQuery2] = useState([]) as any;
  const [exequteQueryContentQuery3,setExequteQueryContentQuery3] = useState([]) as any;
  const [exequteQueryContentQueryCreate,setExequteQueryContentQueryCreate] = useState([]) as any;
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(true);
  const [display, setDisplay] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const QUERY_ID = 5670392840585216;
  const QUERY_ID_2 = 5107442887163904;
  const QUERY_ID_3 = 5951867817295872;
  const QUERY_ID_CREATE = 5388917863874560;

  const hasGetPermissions = useHasEngagementIncentivesPermission([
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERIES_LIST,
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERY,
    ModulesPermissionNames.GET_API_RUN_BIZ_LAYER_QUERY,
  ]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (startDate && endDate && show && hasGetPermissions) {
        setExequteQueryContent([]);
        setExequteQueryContentQuery2([]);
        setExequteQueryContentQuery3([]);
        try {
          setLoading(true);
          setRefresh(false); // Ensure refresh is set to false initially
          await Promise.all([
            engagementIncentivesEndPointLogic.getExequteQuery(
              userToken,
              setExequteQueryContent,
              selectedSiteUid,
              startDate,
              endDate,
              QUERY_ID
            ),
            engagementIncentivesEndPointLogic.getExequteQuery(
              userToken,
              setExequteQueryContentQuery2,
              selectedSiteUid,
              startDate,
              endDate,
              QUERY_ID_2
            ),
            engagementIncentivesEndPointLogic.getExequteQuery(
              userToken,
              setExequteQueryContentQuery3,
              selectedSiteUid,
              startDate,
              endDate,
              QUERY_ID_3
            )
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          if (
            exequteQueryContent.length > 0 &&
            exequteQueryContentQuery2.length > 0 &&
            exequteQueryContentQuery3.length > 0
          ) {
            // setLoading(false);
          }
          setShow(false);
          // setShow(false);
        }
      }
    };
  
    fetchAllData();
    // eslint-disable-next-line
  }, [startDate, endDate, show]);

  useEffect(() => {
    if (
      Object.keys(exequteQueryContent).length > 0 &&
      Object.keys(exequteQueryContentQuery2).length > 0 &&
      Object.keys(exequteQueryContentQuery3).length > 0
    ) {
      setLoading(false);
    }
  }, [exequteQueryContent, exequteQueryContentQuery2, exequteQueryContentQuery3]);
  
  // Separate useEffect for refresh handling
  useEffect(() => {
    if (refresh) {
      const timer = setTimeout(() => {
        setRefresh(false);  // Reset refresh after delayed rerun
        setShow(true);      // This will trigger the main useEffect
      });
      
      return () => clearTimeout(timer); // Cleanup to prevent memory leaks
    }
  }, [refresh]);
  

  useEffect(() => {
    const fetchExequteQueryContentQueryCreate = async () => {
      if (startDate && endDate && show === true) {
        setLoading(true);
        try {
          // Await the API call to ensure it completes before moving on
          await engagementIncentivesEndPointLogic.getExequteQuery(
            userToken, 
            setExequteQueryContentQueryCreate, 
            selectedSiteUid, 
            startDate, 
            endDate, 
            QUERY_ID_CREATE
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          // This ensures loading is set to false after the API call, regardless of success or failure
          // setLoading(false);
        }
      }
    };
  
    fetchExequteQueryContentQueryCreate();
    // eslint-disable-next-line
  }, [period, show,refresh]);

  return (
    <>
    {loading ? (
      <SimpleBackdrop />
    ) : (
      <>
        <BudgetControlFilter 
          period={period} 
          setPeriod={setPeriod} 
          startDate={startDate} 
          setStartDate={setStartDate} 
          endDate={endDate} 
          setEndDate={setEndDate}
          setShow={setShow}
          setDisplay={setDisplay}
          exequteQueryContentQueryCreate={exequteQueryContentQueryCreate}
          userToken={userToken}
          setRefresh={setRefresh}
        />
        {period && Object.keys(exequteQueryContent).length !==0 && display && !loading && (
          <>
            <Divider sx={{ mb: '10px', pt: '10px' }} />
            <BudgetControlCard exequteQueryContent={exequteQueryContent} />
            <BudgetControlTable tableHeadings={tableHeadings} exequteQueryContentQuery2={exequteQueryContentQuery2}
            exequteQueryContentQuery3={exequteQueryContentQuery3} exequteQueryContentQueryCreate={exequteQueryContentQueryCreate} startDate={startDate}
            userToken={userToken} setRefresh={setRefresh}/>
          </>
        )}
      </>
    )}
  </>
  
  )
}

export default BudgetControl