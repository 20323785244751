import { useState } from "react";
import FilterComponent from "../../../../ReusableFIlterComponent/FilterComponent";


interface ThemeFilterProps {
  setSelectedTheme: any;
  designThemeData: any;
  setSelectedGroup: any;
  selectedGroup: any;
  setSelectedFeature: any;
  selectedFeature: any;
}

export default function ThemeFilter({ setSelectedTheme,designThemeData, setSelectedGroup, selectedGroup,
   setSelectedFeature, selectedFeature}: ThemeFilterProps) {

  const [searchFeature,setSearchFeature] = useState('');
  const [searchGroup,setSearchGroup] = useState('');

  const filterConfig = [
    {
      key: 'Group',
      label: 'Select one or more Groups',
      searchTerm: searchGroup,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchGroup(e.target.value),
      selectedOption: selectedGroup,
      filterName: 'groups_appearance',
      data: designThemeData,
      onSelectionChange: (updatedSelection: any) => setSelectedGroup(updatedSelection),
      count: selectedGroup && selectedGroup.length,
      colorSelected: selectedGroup !== null && selectedGroup.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    },
    {
      key: 'feature',
      label: 'Select one or more Feature',
      searchTerm: searchFeature,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchFeature(e.target.value),
      selectedOption: selectedFeature,
      filterRoot:["templates"], // labels are not in directly in data
      filterName: 'labels',
      data: designThemeData,
      onSelectionChange: (updatedSelection: any) => setSelectedFeature(updatedSelection),
      count: selectedFeature && selectedFeature.length,
      colorSelected: selectedFeature !== null && selectedFeature.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    }
  ];

  const clearFilters = () => {
    setSelectedGroup(null);
    setSelectedFeature(null);
    setSelectedTheme(null);
  };

 
  return (
    <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
  );
}
