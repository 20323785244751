import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface Data {
  order: number;
  process__name: string;
  process__id: number;
}

interface Props {
  processlist: Data[];
  onOrderChange: (updatedProcesses: Data[]) => void;  // this function will be called when the order of processes is changed
}

export default function BatchProcessManagement({ processlist,onOrderChange   }: Props) {
  const [rows, setRows] = useState(processlist);

  useEffect(() => {
    setRows(processlist);
  }, [processlist]);

  const handleDragEnd = (e: DropResult) => {
    if (!e.destination) return; // Create a copy of the current rows
    let tempData = Array.from(rows); // Remove the dragged item from its original position
    let [source_data] = tempData.splice(e.source.index, 1); // Insert the dragged item into the new position
    tempData.splice(e.destination.index, 0, source_data); // Update the order property based on the new positions
    const updatedRows = tempData.map((row, index) => ({
        ...row,order: index + 1})); // Set the updated rows and call the callback with updated order
    setRows(updatedRows);
    onOrderChange(updatedRows);
  };

  const moveUp = (index: number) => {
    if (index === 0) return; // Can't move up if it's the first item
    const newRows = [...rows];
    const temp = newRows[index];
    newRows[index] = newRows[index - 1];
    newRows[index - 1] = temp;
    // Update the order property
    const updatedRows = newRows.map((row, idx) => ({
      ...row,
      order: idx + 1 // 1-based index for order
    }));
    setRows(updatedRows);
    onOrderChange(updatedRows); // Call the callback with updated order
  };

  const moveDown = (index: number) => {
    if (index === rows.length - 1) return; // Can't move down if it's the last item
    const newRows = [...rows];
    const temp = newRows[index];
    newRows[index] = newRows[index + 1];
    newRows[index + 1] = temp;
    // Update the order property
    const updatedRows = newRows.map((row, idx) => ({
      ...row,
      order: idx + 1 // 1-based index for order
    }));
    setRows(updatedRows);
    onOrderChange(updatedRows); // Call the callback with updated order
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <DragDropContext onDragEnd={handleDragEnd}>
      <TableContainer sx={{ maxHeight: 256 }}>
          <Table
            className="BatchDragChainsTable"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" width={30}>Order</TableCell>
                <TableCell align="left" width={240}>Process Name</TableCell>
                <TableCell align="left">Process ID</TableCell>
              </TableRow>
            </TableHead>

            <Droppable droppableId="droppable-1">
              {(provider) => (
                <TableBody
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                >
                  {rows
                  ?.slice() // Create a shallow copy of rows to avoid mutating the state directly
                  ?.sort((a, b) => a.order - b.order) // Sort rows by the order property
                  ?.map((row:any, index:any) => (
                    <Draggable
                      key={row.process__id}
                      draggableId={String(row.process__id)}
                      index={index}
                    >
                      {(provider, snapshot) => {
                        const style = {
                          ...provider.draggableProps.style,
                          width: "616px",
                          display: snapshot.isDragging ? "table" : "",
                          backgroundColor: snapshot.isDragging ? "white" : "white",
                          boxShadow: snapshot.isDragging ? "0px 0px 3px #ccc" : "",
                          borderRadius: snapshot.isDragging ? "4px" : "",
                          right: snapshot.isDragging ? "0px" : "",
                        };
                        return (
                          <TableRow
                            key={row.process__id}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            style={style}
                          >
                            <TableCell>
                              <div style={{ textAlign: "center", fontSize: "20px" }}>
                                {index + 1}
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              {...provider.dragHandleProps}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <DragIndicatorIcon
                                  sx={{
                                    color: "rgb(0 0 0 / 54%)",
                                    marginRight: "10px",
                                  }}
                                />
                                {row?.process__name}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {row?.process__id}
                                <span className="processIdActionBtn">
                                  <Button
                                    color="secondary"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => moveUp(index)}
                                    disabled={index === 0}
                                  >
                                    <KeyboardArrowUpOutlinedIcon fontSize="small" />
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => moveDown(index)}
                                    disabled={index === rows.length - 1}
                                  >
                                    <KeyboardArrowDownOutlinedIcon fontSize="small" />
                                  </Button>
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
    </Paper>
  );
}
