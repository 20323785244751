import React, { useEffect, useState } from 'react'
import PerformanceDayBrandsFilter from './PerformanceDayBrandsFilter/PerformanceDayBrandsFilter'
import PerformanceDayBrandsGraph from './PerformanceDayBrandsGraph/PerformanceDayBrandsGraph'
import PerformanceDayBrandsTable from './PerformanceDayBrandsTable/PerformanceDayBrandsTable'
import { Divider } from '@mui/material'
import { engagementIncentivesEndPointLogic } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/engagementIncentivesEndPointLogic'
import SimpleBackdrop from '../../../BackDrop/BackDrop'
import { useHasEngagementIncentivesPermission } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/Permissions/useHasEngagementIncentivesPermission'
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions'


const tableHeadings = {
  "date_of_acquisition": "Date of Aquisition",
  "brand_name": "Brand Name",
  "clicks":"Clicks",
  "registrations":"Registrations",
  "ftd":"FTD",
  "qftd_actual":"Actual QFTD",
  "cost":"Cost ($)",
}

interface PerformanceDayBrandsProps{
  selectedSiteUid:any;
  userToken:string;
}

const PerformanceDayBrands = ({selectedSiteUid,userToken}:PerformanceDayBrandsProps) => {
  const [exequteQueryContentQuery4,setExequteQueryContentQuery4] = useState([]) as any;
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(true);
  const [display, setDisplay] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [brandName,setBrandName] = useState([]);
  const competitorlisting = exequteQueryContentQuery4?.query_output?.map((brandNames: any) => brandNames?.brand_name).filter(Boolean) || [];
  const QUERY_ID_4 = 6233342794006528;

  const hasGetPermissions = useHasEngagementIncentivesPermission([
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERIES_LIST,
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERY,
    ModulesPermissionNames.GET_API_RUN_BIZ_LAYER_QUERY,
  ]);
  
  useEffect(() => {
    const fetchExequteQueryContent = async () => {
      if (startDate && endDate && show === true && hasGetPermissions) {
        setLoading(true);
        try {
          // Await the API call to ensure it completes before moving on
          await engagementIncentivesEndPointLogic.getExequteQuery(
            userToken, 
            setExequteQueryContentQuery4, 
            selectedSiteUid, 
            startDate, 
            endDate, 
            QUERY_ID_4
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          // This ensures loading is set to false after the API call, regardless of success or failure
          setLoading(false);
          setShow(false);
        }
      }
    };
  
    fetchExequteQueryContent();
    // eslint-disable-next-line
  }, [startDate, endDate, show]);

  return (
      <>
      <PerformanceDayBrandsFilter
        startDate={startDate} 
        setStartDate={setStartDate} 
        endDate={endDate} 
        setEndDate={setEndDate}
        setShow={setShow}
        setDisplay={setDisplay}
        competitorlisting={competitorlisting}
        setBrandName={setBrandName}
        />
  {loading ? (
    <SimpleBackdrop />
  ) : (
    <>
      {exequteQueryContentQuery4 && display && (
        <>
          <Divider />
          <PerformanceDayBrandsGraph exequteQueryContentQuery4={exequteQueryContentQuery4}
          brandName={brandName}/>
          <PerformanceDayBrandsTable
            tableHeadings={tableHeadings}
            exequteQueryContentQuery4={exequteQueryContentQuery4}
            brandName={brandName}
          />
        </>
      )}
    </>
  )}
</>
  )
}

export default PerformanceDayBrands