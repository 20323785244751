import { Typography } from "@mui/material";
import { Box} from "@mui/system";

function RecomendedDNSmanager() {
  return (
    <>
      <Box mt={1}>
        <Typography fontWeight={500}>Pros:</Typography>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Simplified Process
          </Typography>
          <Typography>
            Easier setup as the third-party provider handles the DNS
            configurations.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Support
          </Typography>
          <Typography>
            Third-party providers often offer support for DNS-related issues.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Reliability
          </Typography>
          <Typography>
            Often comes with high availability and robust infrastructure.
          </Typography>
        </Box>
      </Box>
      <Box mt={1}>
        <Typography fontWeight={500}>Cons:</Typography>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Less Control
          </Typography>
          <Typography>
            Users have less control over DNS settings and configurations.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Dependency
          </Typography>
          <Typography>
            Reliant on the third-party provider for any changes or
            troubleshooting.
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography fontWeight={500} color="secondary">
            Cost
          </Typography>
          <Typography>
            May involve additional costs depending on the third-party provider.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default RecomendedDNSmanager;
