import { NavigateFunction } from "react-router-dom";
import { setDisplayErrorSnackBar } from "../../../../features/userSlice";
import { endpointsService } from "../../../../services/endPoints.services";
import store from "../../../../store";
async function getPageContent(siteUid: string, pageUid: string, oauthTokenHeader: string, setPageContent: any, setPageStructureDesign: any,
    setPageDictionary: any, setIsLoading: any,setErrorAccToPageStatus:any, setRetry:any, setOpen:any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getPageContent(siteUid, pageUid, oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        setPageContent(response?.data?.data);
        setPageDictionary(response?.data?.data?.theme_v1?.theme_dict);
        setPageStructureDesign(getPageStructureDesign(response?.data?.data));
        setErrorAccToPageStatus(false)
    } catch (e) {
        setRetry(false)
        setOpen(true)
       /*  store.dispatch(setDisplayErrorSnackBar({ message: String(e), type: 'error' })); */
    } finally {
        store.dispatch(setIsLoading(false));
    }
}
async function getSiteCurrentWidgets(siteUid: string, oauthTokenHeader: string, setSiteCurrentWidgetsWithValues: any, trackPromise: any, setIsLoading: any) {
    try {
        store.dispatch(setIsLoading(true));
        trackPromise(await endpointsService.getSiteCurrentWidgets(siteUid, oauthTokenHeader).then((response: any) => {
            if (!response.status || response.status !== 200) {
                store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Current Widgets site', type: 'error' }));
            } else {
                setSiteCurrentWidgetsWithValues((Object.values(response?.data?.data)[0] as any).widgets);
                store.dispatch(setIsLoading(false));
            }
        }));
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ message: e, type: 'error' }));
        store.dispatch(setIsLoading(false));
    }
}
async function getSiteAllAvailableWidgets(siteUid: string, oauthTokenHeader: string, setSiteAllAvailableWidgets: any,setErrorAccToPageStatus:any,setRetry:any,setOpen:any, trackPromise: any, setIsLoading: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getSiteAllAvailableWidgets(siteUid, oauthTokenHeader);
        if (!response || response?.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting All Available widgets site', type: 'error' }));
        } else {
            store.dispatch(setIsLoading(true));
            setSiteAllAvailableWidgets(response?.data?.data?.widgets);
        }
        
        trackPromise(Promise.resolve(response));
    } catch (e) {
        setRetry(false)
        setOpen(true)
        setErrorAccToPageStatus(true)
       /*  store.dispatch(setDisplayErrorSnackBar({ message: e, type: 'error' })); */
    } finally {
        store.dispatch(setIsLoading(true));
    }
}
const getPageStructureDesign = (pageContent: any) => {
    //TODO - need to know which theme
    const result = pageContent?.theme_v1?.records?.filter((value: any) => (value?.type === "Page Structure"))[0]?.css;
    return result;
}
async function getPageUrlContent(siteUrl: string, setPageUrlContent: any, setIsLoading: any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getPageUrlContent(siteUrl);
        if (!response.ok) {
            throw new Error('Failed to fetch page content');
        }
        const data = await response.text();
        setPageUrlContent({ __html: data });
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ state: true, message: String(e), type: 'error' }));
    }
}
async function getPageUrlContentPreviewPage(previewLink:string, setPageUrlContent:any, setIsLoading:any, trackPromise:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await fetch(previewLink);
        if (!response.status || response?.status===200 ) {
            throw new Error('Failed to fetch preview content');
        }
        const data = await response.text();
        setPageUrlContent({ __html: data });
        // store.dispatch(setIsLoading(true));
    } catch (error) {
        store.dispatch(setDisplayErrorSnackBar({ state: true, message: error || 'An error occurred', type: 'error' }));
        store.dispatch(setIsLoading(false));
    }
}
async function getPagePreviewForCurrentChanges(elementIndex: number, siteUid: string, pageUid: string, data: any, setIsLoading: any,
    trackPromise: any, setPreviewCurrentChanges: any, oauthTokenHeader: string , setPreviewLink: any) {
    try {
        store.dispatch(setIsLoading(true));
        await endpointsService.getPagePreviewForCurrentChanges(siteUid, pageUid, data, oauthTokenHeader).then((response) => {
            if (!response.status || response.status !== 200) {
               trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting preview for current changes', type: 'error' }))) ;
            } else {
                trackPromise(endpointsService.getPageUrlContentPreviewPage(response?.data?.data[0]?.preview_link).then((responseNew: any) => {                      
                    return responseNew.text()
                }).then((data: any) => {
                    setPreviewCurrentChanges({
                        "widgetDetails": elementIndex,
                        "previewContent": { __html: data }
                    });
                    setPreviewLink(response?.data?.data[0]?.preview_link)
                }))
            }
        });
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ message: e, type: 'error' }));
        store.dispatch(setIsLoading(false));
    }
}
async function updatePage(
    updateObject: any,
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    trackPromise: any,
    setPageIsUpdatedUid: any,
    pageIsUpdatedUid: number | undefined,
    setPreview:any
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.updatePage(updateObject, siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating page', type: 'error' }));
        } else {
            let newPageIsUpdateCount = 1;
            if (pageIsUpdatedUid !== undefined) {
                newPageIsUpdateCount = pageIsUpdatedUid + 1;
            }
            setPageIsUpdatedUid(newPageIsUpdateCount);
            setPreview(response?.data?.data[0]?.page_url);
        }
    } catch (e:any) {
        store.dispatch({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }); // Need to implement the displayErrorSnackBar later
    }
}

async function savePage(
    updateObject: any,
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    trackPromise: any,
    setPageIsUpdatedUid: any,
    pageIsUpdatedUid: number | undefined,
    setPreview:any
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.savePage(updateObject, siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating page', type: 'error' }));
        } else {
            let newPageIsUpdateCount = 1;
            if (pageIsUpdatedUid !== undefined) {
                newPageIsUpdateCount = pageIsUpdatedUid + 1;
            }
            setPageIsUpdatedUid(newPageIsUpdateCount);
            setPreview(response?.data?.data[0]?.preview_link);
            
        }
    } catch (e) {
        store.dispatch({ message: e, type: 'error' }); // Need to implement the displayErrorSnackBar later
    }
}

async function discardPage(
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    setPageDiscardData: any,
    data:any,
    setPageIsUpdatedUid: any,
    pageIsUpdatedUid: number | undefined,
    setPreview:any
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.discardPage(data ,siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while discarding page', type: 'error' }));
        } else {
            let newPageIsUpdateCount = 1;
            if (pageIsUpdatedUid !== undefined) {
                newPageIsUpdateCount = pageIsUpdatedUid + 1;
            }
            setPageIsUpdatedUid(newPageIsUpdateCount);
            setPageDiscardData(response?.data);
            setPreview(response?.data?.data[0]?.preview_link);
        }
    } catch (e) {
        store.dispatch({ message: e, type: 'error' }); 
    }
}

async function updatePageStatus(updateObject: any, siteUid: string, pageUid: string, setIsLoading: any,
    oauthTokenHeader: string, trackPromise: any, setPageIsUpdatedUid: any, pageIsUpdatedUid: number | undefined, setPostStatus: any) {
    try {
        store.dispatch(setIsLoading(true));
        await endpointsService.updatePage(updateObject, siteUid, pageUid, oauthTokenHeader).then((response: any) => {
            if (!response.status || response.status !== 200) {
                trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating page status', type: 'error' })));
            } else {
                setPostStatus('publish');
                let newPageIsUpdateCount = 1;
                if (pageIsUpdatedUid !== undefined) {
                    newPageIsUpdateCount = pageIsUpdatedUid + 1;
                }
                setPageIsUpdatedUid(newPageIsUpdateCount);
            }
        });
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ message: e, type: 'error' }));
    }
}

async function deletePage(siteUid: string , page_shared_uid: string , oauthTokenHeader: string, setIsLoading: any , setDeletePageData:any) {
    try {
    store.dispatch(setIsLoading(true));
    const response = await endpointsService.deletePage(siteUid,page_shared_uid,oauthTokenHeader);
    if (!response.status || response.status !== 200) {
        /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
        store.dispatch(setIsLoading(false));
    } else {
        store.dispatch(setIsLoading(false));
        setDeletePageData(response?.data?.data[0]?.message);   
    }
    } catch (error) {
    store.dispatch(setDisplayErrorSnackBar({ message: error, type: 'error' }))
    }
}
async function imageUpload(siteUid: string, oauthTokenHeader: string, data: any, setIsLoading: any, setImageData: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.imageUpload(siteUid, oauthTokenHeader, data);
        if (!response.status || response.status !== 200 || response?.response?.status === 500) {
            const errorMessage = 'Something went wrong uploading image';
            store.dispatch(setDisplayErrorSnackBar({ message: errorMessage, type: 'error' }));
            throw new Error(errorMessage);  // Throw an error to be caught by the calling function
        } else {
            setImageData(response?.data?.data[0]);
        }
    } catch (error) {
        const errorMessage = error || 'An unexpected error occurred';
        store.dispatch(setDisplayErrorSnackBar({ message: errorMessage, type: 'error' }));
        throw error;  // Rethrow the error to be caught by the calling function
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

const navigateTo404 = (navigate: NavigateFunction) => {
    navigate(`/404`);
}
// eslint-disable-next-line
export const pageBuildingEndPointLogic = {
    getPageContent,
    getSiteCurrentWidgets,
    getSiteAllAvailableWidgets,
    getPageUrlContent,
    updatePage,
    getPageUrlContentPreviewPage,
    navigateTo404,
    updatePageStatus,
    getPagePreviewForCurrentChanges,
    deletePage,
    imageUpload,
    discardPage,
    savePage
};
