import {Autocomplete,Button,InputAdornment,Paper,TextField,} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import AddNewAccountDialog from "./AddNewAccount";
import { useSelector } from "react-redux";
import { selectUserData, /* setIsLoading */ } from '../../../../features/userSlice';
import { Box } from "@mui/system";
import FilterComponent from '../../../ReusableFIlterComponent/FilterComponent';

  interface Props {
    rows: any;
    modifiedArray:any;
    selectedRows:any,
    setSelectedRows:any,
    advertisingAccounts: any[];
    selectedOptionSource: string[];
    setSelectedOptionSource: React.Dispatch<React.SetStateAction<string[]>>;
    selectedOptionAsset: string[];
    setSelectedOptionAsset :  React.Dispatch<React.SetStateAction<string[]>>;
    selectedOptionTerritory:string[];
    setSelectedOptionTerritory: React.Dispatch<React.SetStateAction<string[]>>;
    selectedAccount:string;
    setSelectedAccount:React.Dispatch<React.SetStateAction<string>>;
    selectedAsset:any;
    setSelectedAsset:any;
    selectedAdvertisementSource:any;
    setSelectedAdvertisementSource:any;
    selectedTerritory:any;
    setSelectedTerritory:any;
    siteNames:any;
    oauthTokenHeader:string
    isRowTrue:any;
    rowId:any;
    isEditBtnEnabled:any;
    hasGetPermissions:any
    isCheckboxCheckedArray:any
    setIsEditBtnEnabled:any
    setReloadAfterToggleEdit:any
    fetchAdAccountData:any
    getTrafficSource:any
    trafficSources:any
    setTrafficSourceData:any
    getAdAccountDetailsForEdit:Function
    editDialogOpen:any 
    setEditDialogOpen:any
    fetchSingleAdAccountData:Function
    singleAdAccountData:any
    setSingleAdAccountData:any
    setRowId:any
    rowIndexNumber:any
    setrowIndexNumber:any
    setIsCheckboxCheckedArray:any
    postWebsiteToAcc:Function
    setAccountUpdateStatus:any
    accountUpdateStatus:any
  }



 const CardFilters = ({
    rows,
    modifiedArray,
    selectedRows,
    setSelectedRows,
    advertisingAccounts,
    selectedOptionSource,
    setSelectedOptionSource,
    selectedOptionAsset,
    setSelectedOptionAsset,
    selectedOptionTerritory,
    setSelectedOptionTerritory,
    siteNames,
    selectedAsset,
    setSelectedAsset,
    selectedAdvertisementSource,
    setSelectedAdvertisementSource,
    selectedTerritory,
    setSelectedTerritory,
    selectedAccount,
    setSelectedAccount,
    oauthTokenHeader, 
    isRowTrue,
    rowId,
    isEditBtnEnabled,
    hasGetPermissions,
    isCheckboxCheckedArray,
    setReloadAfterToggleEdit,
    setIsEditBtnEnabled,
    fetchAdAccountData,
    getTrafficSource,
    trafficSources,
    setTrafficSourceData,
    getAdAccountDetailsForEdit,
    editDialogOpen,
    setEditDialogOpen,
    fetchSingleAdAccountData,
    singleAdAccountData,
    setSingleAdAccountData,
    setRowId,
    rowIndexNumber,
    setrowIndexNumber,
    setIsCheckboxCheckedArray,
    postWebsiteToAcc,
    setAccountUpdateStatus,
    accountUpdateStatus
  }: Props) => {

    const [errorAccToPageStatus, /* setErrorAccToPageStatus */] = useState([])
    const [open, setOpen] = useState(false)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    // const [accountUpdateError, setAccountUpdateError] = useState(false);
    const [searchTerritory, setSearchTerritory] = useState("");
    const [searchAsset, setSearchAsset] = useState("");
    const [searchAdSource, setSearchAdSource] = useState("");

    // const isLoading = useSelector(selectIsLoading);
    const userData = useSelector(selectUserData)

    const clearFilters = () => {
      setSelectedAsset(null);
      setSelectedAdvertisementSource(null);
      setSelectedTerritory(null);
      setSelectedAccount("");
      setSearchAdSource("");
      setSearchAsset("");
      setSearchTerritory("");
    };

    const asset = (advertisingAccounts.map((account) =>
        Object.keys(account.linked_assets).map((asset) => (asset))
      ));
    
      const websitePermissions = userData?.data?.website_permissions && userData?.data?.website_permissions;
      const mergedAssets = Array.from(new Set(asset.flat()));
      const excludedTrafficSources = ["direct", "direct_clickout", "manual_adjustment"];

      const account = advertisingAccounts?.filter((item:any) => !excludedTrafficSources.includes(item?.traffic_source))
      .map((item:any) => item?.account_name);

      const handleDialogOpen = (): void => {
        getTrafficSource()
        setReloadAfterToggleEdit(false)
        setDialogOpen(true);
      };

    const handleDialogClose = (): void => {
      setDialogOpen(false);
    };
    const allFalse = isCheckboxCheckedArray.every((element:any) => element === false);

    const [/* stringArray */, setStringArray] = useState([]);

    useEffect(() => {
      if (rowId) { // Check if rowId is defined
        const addStringToArray = (rowId: string) => {
          setStringArray((prevArray:any) => {
            if (prevArray.includes(rowId)) {
              return prevArray.filter((str:string) => str !== rowId);
            } else {
              return [...prevArray, rowId];
            }
          });
        };
        addStringToArray(rowId);
      }
    }, [rowId, allFalse]);

    // const handleEditDialogClose = (): void => {
    //   setEditDialogOpen(false);
    // };

    const filterConfig = [
      {
        key: 'assets',
        label: "Select am Asset",
        searchTerm: searchAsset,
        onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchAsset(e.target.value),
        selectedOption: selectedAsset,
        filterName: 'websites__site_name',
        data: modifiedArray,
        onSelectionChange: (updatedSelection: any) => setSelectedAsset(updatedSelection),
        count: selectedAsset?.length,
        colorSelected: selectedAsset?.length > 0 ? '#9C27B0' : '#000000DE',
        multiSelect: true,
        showSearch: true,
        siteNames: siteNames
      },
      {
        key: 'Traffic Source',
        label: 'Select a Source',
        searchTerm: searchAdSource,
        onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchAdSource(e.target.value),
        selectedOption: selectedAdvertisementSource,
        filterName: 'traffic_source',
        data: modifiedArray,
        onSelectionChange: (updatedSelection: any) => setSelectedAdvertisementSource(updatedSelection),
        count: selectedAdvertisementSource && selectedAdvertisementSource?.length,
        colorSelected: selectedAdvertisementSource !== null && selectedAdvertisementSource?.length > 0 ? '#9C27B0' : '#000000DE',
        multiSelect: false,
        showSearch: true,
      },
      {
        key: 'Territory',
        label: 'Select One or More Territories',
        searchTerm: searchTerritory,
        onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerritory(e.target.value),
        selectedOption: selectedTerritory,
        filterName: 'territory',
        data: modifiedArray,
        onSelectionChange: (updatedSelection: any) => setSelectedTerritory(updatedSelection),
        count: selectedTerritory && selectedTerritory?.length,
        colorSelected: selectedTerritory !== null && selectedTerritory?.length > 0 ? '#9C27B0' : '#000000DE',
        multiSelect: true,
        showSearch: true,
      }
    ];

    const selectedSite = ""
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ErrorPopupStatusProps = {
       selectedSite,
       errorAccToPageStatus,
       setOpen,
       open
   }

 return (
      <>
        <Paper>
          <div style={{ padding: "16px" }}>
            <div style={{ fontSize: "24px" }}>Advertising Account Management</div>
            <p style={{ fontSize: "14px" }}>
              Create new or edit existing advertising accounts.{" "}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  marginTop: "5px",
                }}
              >
                {/*Autocomplete */}
                <Autocomplete
                  options={account ? account : []}
                  value={selectedAccount}
                  onChange={(event, newValue) => {
                    setSelectedAccount(newValue);
                    setIsEditBtnEnabled(true) // Update state with selected account
                  }}
                    renderOption={(account, option: any, { selected }) => (
                      <Box
                        component="li"
                        {...account}
                        sx={{
                          backgroundColor: selected ? "#F7EEF9 !important" : "inherit",
                        }}
                      >
                        {option}
                      </Box>
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account Name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                      
                    />
                  )}
                  size="small"
                  sx={{ width: "300px"}}
                />
                <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
              </div>
              {/* Action Buttons */}
              <div aria-hidden="true" style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Button
                 aria-hidden="false"
                  variant="contained"
                  color="secondary"
                  endIcon={<AddIcon />}
                  onClick={handleDialogOpen}
                >
                  ADD NEW ACCOUNT
                </Button>
                <AddNewAccountDialog siteNames={siteNames} websitePermissions={websitePermissions} setReloadAfterToggleEdit={setReloadAfterToggleEdit} open={dialogOpen} handleClose={handleDialogClose} trafficSources={trafficSources} oauthTokenHeader={oauthTokenHeader} mergedAssets={mergedAssets} />
              </div>
            </div>
          </div>
        </Paper>
      </>
    );
  };
  export default CardFilters;
  