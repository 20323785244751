import { Paper, Typography } from "@mui/material";
import React from "react";
import OrganizationFilterCard from "./OrganizationFilter/OrganizationFilterCard";
import OrganizationTable from "./OrganizationTable/OrganizationTable";

const tableHeadings = {
  organization_name: "Organization Name",
  organization_number: "Organization Number",
  keeper_folder_id: "Keeper Folder ID",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    organization_name: "Optival LTD",
    organization_number: "1,333",
    keeper_folder_id: "dxrsctgyvdhjbwjdnk675764",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    organization_name: "Optival LTD",
    organization_number: "1,333",
    keeper_folder_id: "dxrsctgyvdhjbwjdnk675764",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    organization_name: "Optival LTD",
    organization_number: "1,333",
    keeper_folder_id: "dxrsctgyvdhjbwjdnk675764",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const Organization = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Organizations List</Typography>
        <OrganizationFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <OrganizationTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default Organization;
