import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PerformanceDashboardRealTimePerformance from "./PerformanceDashboardRealTimePerformance";
import PerformanceDashboardRealUsersExperience from "./PerformanceDashboardRealUsersExperience";

const PerformanceDashboardRealData = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="dashboardAccordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>
          Real Users’ Experience
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PerformanceDashboardRealUsersExperience/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className="dashboardAccordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>Real-Time Performance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PerformanceDashboardRealTimePerformance/>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PerformanceDashboardRealData;
