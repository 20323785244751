import React, { FC, useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PercentIcon from "@mui/icons-material/Percent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Grid, InputAdornment, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import { advertisingAccountsEndPointLogic } from "../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import { Box, Stack } from "@mui/system";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { trackPromise } from "react-promise-tracker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import "../../PaidTraffic.css";
import SimpleBackdrop from '../../../BackDrop/BackDrop';

interface CreateAccountDialogProps {
  open: boolean;
  handleClose: () => void;
  adAccountDetails: any;
  selectedSource: any;
  adAccountGeoDetails: any;
  adAccountIndustryDetails: any;
  oauthTokenHeader: string;
  mergedAssets: any;
  adAccountTerritoryDetails: any;
  setAdAccountId: any;
  adAccountId: any;
  handleCloseRetrieve: any;
  setReloadAfterToggleEdit: any;
  setAdAccLength: any;
  setAdAccountDetails: any;
  setSelectedSource: any;
  createAccRes: any;
  setCreateAccRes: any;
  setShowCreateAccountDialog: any;
  errorSnackbarOpen: any;
  websitePermissions: any;
  setErrorSnackbarOpen: any;
  getAdAccountDetails: Function;
  siteNames: any;
}

type AdAccountCreateResponse = {
  shared_uid: string;
  // Other properties, if any
};

type errorTextWhileCreate = {
  text: string;
};

type DivItem = {
  id: number;
  assetType: string;
  assetName: string;
  startDate: Date | null;
  endDate: Date | null;
  disabled: boolean;
};

const CreateAccountDialog: FC<CreateAccountDialogProps> = ({
  siteNames,
  getAdAccountDetails,
  setErrorSnackbarOpen,
  websitePermissions,
  errorSnackbarOpen,
  setShowCreateAccountDialog,
  setCreateAccRes,
  createAccRes,
  setSelectedSource,
  setAdAccountDetails,
  setAdAccLength,
  setReloadAfterToggleEdit,
  handleCloseRetrieve,
  adAccountId,
  setAdAccountId,
  handleClose,
  adAccountDetails,
  selectedSource,
  adAccountGeoDetails,
  adAccountIndustryDetails,
  oauthTokenHeader,
  mergedAssets,
  adAccountTerritoryDetails,
}) => {
  const handleDisagree = (): void => {
    setAdAccountDetails([]);
    handleClose();
    setAdAccountId("");
    setCreateAccRes(false);
    handleCloseRetrieve();
  };
  const handleBack = () => {
    setAdAccountDetails([]);
    setAdAccLength(true);
    setCreateAccRes(false);
    handleClose();
    setAdAccountId("");
    setSelectedSource("");
  };

  const [loading, setLoading] = useState(false);
  const [geoSelected, setGeoSelected] = useState("");
  const [industryTyped, setIndustryTyped] = useState("");
  const [industrySelected, setIndustrySelected] = useState("");
  const [territorySelected, setTerritorySelected] = useState("");
  const [taxationAmount, setTaxationAmount] = useState(0.0);
  const [disableGeo, setDisableGeo] = useState(false);
  const [newGeo, setNewGeo] = useState("");
  const [disableIndustry, setDisableIndustry] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [adAccCreateResponseData, setAdAccCreateResponseData] = useState<AdAccountCreateResponse[]>([]);
  const [errorTextWhileCreate, setErrorTextWhileCreate] = useState<errorTextWhileCreate[]>([]);
  const [, /* snackbarMessage */ setSnackbarMessage] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const handleAddButtonClick = () => {
    setTaxationAmount((prevAmount) =>
      parseFloat((prevAmount + 0.1).toFixed(2))
    );
  };
  const handleMinusButtonClick = () => {
    setTaxationAmount((prevAmount) =>
      parseFloat((prevAmount - 0.1).toFixed(2))
    );
  };
  const removeNewIndustry = () => {
    setDisableIndustry(false);
  };
  const removeNewGeo = () => {
    setDisableGeo(false);
  };

  async function createAdAccount() {
    const data = {
      data: {
        account_id: adAccountDetails[0]?.data?.account_id,
        account_name: `${adAccountDetails[0]?.data?.account_name}`,
        account_taxation: Number(taxationAmount),
        geo: `${geoSelected || newGeo}`,
        industry: `${industrySelected || industryTyped}`,
        traffic_source: `${selectedSource}`,
      },
    };
    try {
      setLoading(true);
      await advertisingAccountsEndPointLogic.createAdAccount(oauthTokenHeader,data,setErrorTextWhileCreate,setCreateAccRes,setAdAccCreateResponseData,trackPromise);
      /* setCreateAccRes(true); */
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    } finally {
      setLoading(false);
    }
  }
  async function postGeo() {
    const data1 = {
      data: {
        geo: `${newGeo}`,
        territory: `${territorySelected}`,
      },
    };
    try {
      advertisingAccountsEndPointLogic.postGeo(oauthTokenHeader, data1);
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  }
  async function postIndustry() {
    const data2 = {
      data: {
        industry: `${industryTyped}`,
      },
    };
    try {
      setLoading(true);
      advertisingAccountsEndPointLogic.postIndustry(oauthTokenHeader, data2);
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    } finally{
      setLoading(false);
    }
  }

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (value === "") {
      setTaxationAmount(0.0);
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setTaxationAmount(parsedValue);
      }
    }
  };

  const [divs, setDivs] = useState<DivItem[]>([
    {
      id: 1,
      assetType: "",
      assetName: "",
      startDate: null,
      endDate: null,
      disabled: false,
    },
  ]);

  // Function to add a new div
  const addNewDiv = () => {
    setDivs([
      ...divs,
      {
        id: divs.length + 1,
        assetType: "",
        assetName: "",
        startDate: null,
        endDate: null,
        disabled: false,
      },
    ]);
  };

  const handleAssetChange = <K extends keyof DivItem>(
    index: number,
    field: K,
    value: DivItem[K]
  ) => {
    const newDivs = [...divs];
    newDivs[index][field] = value;
    setDivs(newDivs);
  };

  const postWebsiteToAcc = useCallback(async () => {
    const formattedSites = divs.map((item) => ({
      site_name: item?.assetName,
      start_date: item?.startDate?.toISOString().split("T")[0], // Format to "YYYY-MM-DD"
      end_date: item?.endDate?.toISOString().split("T")[0], // Format to "YYYY-MM-DD"
    }));

    const data = {
      data: {
        sites: formattedSites,
      },
    };

    try {
      setLoading(true);
      await advertisingAccountsEndPointLogic.postWebToAcc(oauthTokenHeader,data,adAccCreateResponseData?.[0]?.shared_uid);
    } catch (error) {
      console.error("Error posting websites to account:", error);
    } finally {
      setLoading(false);
    }
  }, [divs, oauthTokenHeader, adAccCreateResponseData]);

  const handleAgree = async () => {
    if (geoSelected && industrySelected) {
      setLoading(true);
      try {
        setAdAccountId("");
        await postGeo(); // Await the postGeo function
        await postIndustry(); // Await the postIndustry function
        await postWebsiteToAcc(); // Await the postWebsiteToAcc function
        await createAdAccount(); // Await the createAdAccount function
      } catch (error) {
        console.error("Error during account creation process:", error);
        setSnackbarMessage("An error occurred while creating the account. Please try again.");
        setSuccessSnackbarOpen(true);
      }finally {
        setLoading(false);
      }
    } else {
      setSnackbarMessage("Input missing, Please try again");
      setSuccessSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (adAccCreateResponseData?.length > 0) {
      setReloadAfterToggleEdit(true);
      setErrorSnackbarOpen(true);
      handleCloseRetrieve();
      handleClose();
      postWebsiteToAcc();
    }
  }, [adAccCreateResponseData,setErrorSnackbarOpen,handleClose,handleCloseRetrieve,setReloadAfterToggleEdit,postWebsiteToAcc]);

  useEffect(() => {
    if (errorSnackbarOpen === true) {
      setShowCreateAccountDialog(true);
    }
  }, [errorSnackbarOpen, setShowCreateAccountDialog]);

  useEffect(() => {
    if (adAccountDetails) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // 3000 ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [adAccountDetails]);

  useEffect(() => {
    if (successSnackbarOpen) {
      setSuccessSnackbarOpen(true);
      const timer = setTimeout(() => {
        setSuccessSnackbarOpen(false);
      }, 3000); // 3000 ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [successSnackbarOpen]);
  useEffect(() => {
    if (createAccRes) {
      setCreateAccRes(true);
      const timer = setTimeout(() => {
        setCreateAccRes(false);
      }, 3000); // 3000 ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [createAccRes, setCreateAccRes]);

  const [switchChecked, setSwitchChecked] = useState(true);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };
  /* const convActionsIds = adAccountDetails?.account_conv_actions?.map((a:any) => a.id) */
  const geoDropDown = adAccountGeoDetails?.map((a: any) => a.name);
  const territoryDropDown = adAccountTerritoryDetails?.map((a: any) => a?.territory_name);
  const industryDropDown = adAccountIndustryDetails?.map((a: any) => a?.industry_name);
  const dialogContentRef = useRef<HTMLDivElement>(null);

  const jsonString = errorTextWhileCreate[0]?.text;
  const jsonObject = jsonString && JSON.parse(jsonString);
  const reason = jsonObject?.reason;
  useEffect(() => {
    // Reset scroll position on component mount or dialog open
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = 0;
    }
    if ((successSnackbarOpen || createAccRes) && dialogContentRef?.current) {
      dialogContentRef?.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [successSnackbarOpen, createAccRes]);

  return (
    <>
      {loading ? (
        <SimpleBackdrop /> 
      ):(
        <Paper ref={dialogContentRef} sx={{ overflowY: "auto" }}>
        <DialogTitle id="responsive-dialog-title">
          {"Add New Account"}
        </DialogTitle>
        <DialogContent sx={{ overflowY: "auto" }}>
          <DialogContentText>
            {showAlert && (
              <Alert
                iconMapping={{
                  success: <CheckCircleOutlineIcon sx={{ color: "#1E4620" }} />,
                }}
                sx={{
                  height: "50%",
                  fontWeight: "500",
                  backgroundColor: "#EDF7ED",
                  color: "#1E4620",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 4px",
                  border: "1px solid #2E7D3280",
                }}
                severity="success"
              >
                Account retrieved successfully!
              </Alert>
            )}
            {successSnackbarOpen && (
              <Alert
                sx={{
                  height: "50%",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 4px",
                }}
                severity="warning"
              >
                Input missing, Please try again!
              </Alert>
            )}
            {createAccRes && (
              <Alert
                sx={{
                  height: "50%",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 4px",
                }}
                severity="error"
              >
                {reason}
              </Alert>
            )}

            <div
              style={{
                display: "flex",
                paddingBottom: "16px",
                paddingTop: "16px",
                gap: "16px",
              }}
            >
              <TextField
                disabled
                id="outlined-disabled"
                label="Source"
                defaultValue={selectedSource}
                size="small"
                sx={{ width: "50%", background: "#0000000F" }}
              />
              <TextField
                disabled
                id="outlined-disabled"
                label="ID at source"
                value={
                  adAccountDetails[0]
                    ? adAccountDetails[0]?.data?.account_id
                    : "N/A"
                }
                size="small"
                sx={{ width: "50%", background: "#0000000F" }}
              />
            </div>
            <div
              style={{ display: "flex", paddingBottom: "16px", gap: "16px" }}
            >
              <TextField
                disabled
                id="outlined-disabled"
                label="Account Name"
                value={
                  adAccountDetails[0]
                    ? adAccountDetails[0]?.data?.account_name
                    : "N/A"
                }
                size="small"
                sx={{ width: "49%", background: "#0000000F" }}
              />
              <TextField
                id="outlined-disabled"
                size="small"
                sx={{ width: "318px" }}
                color="secondary"
                label="Taxation"
                value={taxationAmount}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon sx={{ marginLeft: "12px" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <AddIcon
                onClick={handleAddButtonClick}
                cursor="pointer"
                color="secondary"
                sx={{ marginLeft: "5px", marginTop: "6px" }}
              />
              <RemoveIcon
                onClick={handleMinusButtonClick}
                cursor="pointer"
                color="secondary"
                sx={{ marginLeft: "5px", marginTop: "6px" }}
              />
            </div>
            <div style={{ display: "flex", paddingBottom: "16px" }}>
              <div>
                <Autocomplete
                  disabled={disableGeo}
                  options={geoDropDown}
                  onChange={(event, newValue: any) => {
                    setGeoSelected(newValue);
                  }}
                  renderOption={(account, option: any, { selected }) => (
                    <Box
                      component="li"
                      {...account}
                      sx={{
                        backgroundColor: selected
                          ? "#F7EEF9 !important"
                          : "inherit",
                      }}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Geo *"
                      placeholder="Please select a Geo from the list."
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "430px", paddingRight: "16px" }}
                />
                {disableGeo && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: "16px",
                        paddingTop: "16px",
                      }}
                    >
                      <TextField
                        id="outlined-disabled"
                        label="New Geo"
                        value={newGeo}
                        onChange={(e) => setNewGeo(e.target.value)}
                        size="small"
                        sx={{ width: "40%" }}
                      />
                      <Autocomplete
                        options={territoryDropDown}
                        onChange={(event, newValue: any) => {
                          setTerritorySelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Territory *"
                            placeholder="Please select an industry from the list."
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                              ),
                            }}
                            color="secondary"
                          />
                        )}
                        size="small"
                        sx={{ width: "40%", marginLeft: "15px" }}
                      />
                      <Button onClick={removeNewGeo} color="secondary">
                        <CloseOutlinedIcon color="secondary" />
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div>
                <Autocomplete
                  disabled={disableIndustry}
                  options={industryDropDown}
                  onChange={(event, newValue: any) => {
                    setIndustrySelected(newValue);
                  }}
                  renderOption={(account, option: any, { selected }) => (
                    <Box
                      component="li"
                      {...account}
                      sx={{
                        backgroundColor: selected
                          ? "#F7EEF9 !important"
                          : "inherit",
                      }}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industry *"
                      placeholder="Please select an industry from the list."
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "418px" }}
                />
                {disableIndustry && (
                  <>
                    <TextField
                      id="outlined-disabled"
                      label="Add new Industry"
                      value={industryTyped}
                      onChange={(e) => setIndustryTyped(e.target.value)}
                      size="small"
                      sx={{ width: "80%", marginTop: "12px" }}
                    />
                    <Button
                      onClick={removeNewIndustry}
                      color="secondary"
                      sx={{ marginTop: "14px" }}
                    >
                      <CloseOutlinedIcon
                        color="secondary"
                        sx={{ padding: "0px" }}
                      />
                    </Button>
                  </>
                )}
              </div>
            </div>

            <FormHelperText sx={{ marginBlock: "16px" }}>
              All fields are mandatory.
            </FormHelperText>
            <FormHelperText
              sx={{ marginBlock: "16px", fontSize: "16px", color: "#000000" }}
            >
              Linked Asset{" "}
            </FormHelperText>
            <div>
              <Grid container>
                {divs.map((item, index) => (
                  <Grid
                    container
                    key={item.id}
                    alignItems="center"
                    columnSpacing={2}
                    sx={{ marginBottom: 2 }}
                  >
                    {/* Asset Type Autocomplete */}
                    <Grid item xs={12} sm={3}>
                      <Autocomplete
                        disabled={item.disabled}
                        options={["website"]}
                        value={item.assetType}
                        onChange={(event, newValue) =>
                          handleAssetChange(index, "assetType", newValue || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asset type"
                            placeholder="Select type from the list."
                            color="secondary"
                          />
                        )}
                        size="small"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    {/* Asset Name Autocomplete */}
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        disabled={item.disabled}
                        options={siteNames.map((site:any) => site.site.site_name)} // Extract site names
                        onChange={(event, newValue:any) =>
                          handleAssetChange(index, "assetName", newValue || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asset name"
                            placeholder="Please search or select an asset from the list."
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon sx={{ marginLeft: "12px" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        size="small"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    {/* Date Range Picker */}
                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          name="allowedRange"
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                              color: "secondary",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Typography fontSize={12} sx={{ marginBlock: "8px 6px" }}>
                More than one linked asset?
              </Typography>
              <Button variant="outlined" color="secondary" onClick={addNewDiv}>
                Add New
              </Button>
            </div>

            <FormControlLabel
              control={
                <Switch
                  disabled
                  color="success"
                  checked={switchChecked}
                  onChange={handleSwitchChange}
                />
              }
              label="Account Status"
            />
            <Stack
              padding={1}
              marginY={2}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Typography style={{ fontSize: "1rem" }}>
                Conversion Actions
              </Typography>
              <Button
                onClick={() => getAdAccountDetails()}
                variant="outlined"
                color="secondary"
                sx={{
                  border: "none", // Removes the border
                  "&:hover": {
                    border: "none", // Ensures no border on hover as well
                  },
                }}
              >
                <ReplayOutlinedIcon color="secondary" />
              </Button>
            </Stack>
            {adAccountDetails[0]?.data?.account_conv_actions?.map(
              (data: any, index: any) => (
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "16px",
                    gap: "16px",
                  }}
                >
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Action name"
                    defaultValue={data?.id}
                    size="small"
                    sx={{ width: "225px", background: "#0000000F" }}
                  />
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Action ID at source"
                    defaultValue={data?.action}
                    size="small"
                    sx={{ width: "600px", background: "#0000000F" }}
                  />
                </div>
              )
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 20px 24px" }}>
          <Button
            autoFocus
            onClick={handleDisagree}
            sx={{
              color: "#9C27B0",
              "&:hover": { background: "#ffffff", color: "#9C27B0" },
            }}
          >
            CANCEL
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            <ChevronLeftIcon />
            BACK
          </Button>
          <Button
            onClick={handleAgree}
            autoFocus
            sx={{
              background: "#9C27B0",
              color: "#ffffff",
              "&:hover": {
                background: "#9C27B0",
                color: "#ffffff",
              },
            }}
          >
            create account
          </Button>
        </DialogActions>
      </Paper>
      )}
    </>
  );
};

export default CreateAccountDialog;
