import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { LineChart } from "@mui/x-charts";
import { dataset } from './ScoreDataset';

const PerformanceDashboardOverallScoreGraph = () => {
  return (
    <>
      <Box
        sx={{
          background: "#EDF7ED",
          border: "1px solid #2E7D3280",
          padding: "1rem",
          borderRadius:'4px'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
            Overall Score
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#00000099", fontSize: "12px" }}
            >
              Reflects website speed, based on: Ads Mobile Speed, Real Users' Experience, and Real-Time Performance.
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{display:'flex'}} justifyContent={"center"}>
          <Typography variant="h2">92%</Typography>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ml:'1rem'
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  mr: "6px",
                  fontSize: "12px"
                }}
              >
               13.7% <TrendingUpIcon />
              </Typography>
              <Typography
                sx={{ color: "#000000", fontSize: "10px", fontWeight: "500" }}
              >
                compared to the <br/>
                previous quarter. 
              </Typography>
            </Box>
          </Grid>
        </Grid>
        

        <LineChart 
          dataset={dataset}
          series={[
            { dataKey: 'y', color: '#2E7D32',showMark: false,curve: "linear",}
          ]}
          xAxis={[{ scaleType :'point', dataKey: 'x', disableLine: true,}]}
          yAxis={[
            {
              valueFormatter: (value) => `${value}%`,disableLine: true,disableTicks: true,
            },
          ]}
          height={275}
          margin={{ left: 30, right: 40, top: 16, bottom: 30 }}
          grid={{ vertical: false, horizontal: true }}
          leftAxis={null}
          rightAxis={{}}
          slotProps={{ legend: { hidden: true } }}
        />
        

      </Box>
    </>
  );
};

export default PerformanceDashboardOverallScoreGraph;
