import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CompetitorsPageSpeedTabs from "./CompetitorsPageSpeedTabs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PsiCompetitors } from "../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/CompetitorsPageSpeedEndPointLogic";
import store from "../../../../store";
import { setIsLoading } from "../../../../features/userSlice";
import { useHasCompetitorsPageSpeedPermission } from "../../../../logic/MarketPositioning/Scout/CompetitorsPageSpeed/Permission/useHasCompetitorsPageSpeedPermission";
import { ModulesPermissionNames } from "../../../../data/menu/ModulesPermissions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const CompetitorsPageSpeed = ({ userToken ,response }: { userToken: any , response : any }) => {

  const hasGetPermissions = useHasCompetitorsPageSpeedPermission([
    ModulesPermissionNames.GET_API_PSI_COMPETITORS_RESULT,
    ModulesPermissionNames.GET_API_PSI_COMPETITORS,
  ]);


  const siteOptions = useMemo(() => {
    return response?.website_permissions?.map((siteObj: any) => {
      const uid = Object.keys(siteObj)[0]; // UID of the site
      const siteName = siteObj[uid].site_name; // Site name
      return { uid, siteName }; // Return as an object with uid and siteName
    }) || [];
  }, [response]); // Only recompute when `response` changes
  
  const [selectedSiteUid, setSelectedSiteUid] = useState<string | null>(null); // State to store the selected site's UID
  const [showCompetitorsPageSpeedTabs,setShowCompetitorsPageSpeedTabs] = useState(false);
  const [psiRequestsRows,setPsiRequestsRows] = useState([]);
  const [refresh,setRefresh] = useState(false);

  const dispatch = useDispatch();
  const handleSelectSite = useCallback(() => {
    setShowCompetitorsPageSpeedTabs(true);
  }, []);
  
  const isLoading = useSelector((state: any) => state.user.isLoading);

  // Fetch PsiRequests data
  useEffect(() => {
    const fetchPsiRequestData = async () => {
      setRefresh(false);
      store.dispatch(setIsLoading(true));
      try {
        if (hasGetPermissions && showCompetitorsPageSpeedTabs) {
          await PsiCompetitors.getPsiRequestsOfCompetitors(
            userToken,
            selectedSiteUid,
            setIsLoading,
            setPsiRequestsRows,
            dispatch
          )
        } else {
          throw new Error("User doesn't have permissions to fetch Psi Competitors data");
        }
      } catch (error) {
        console.error("Error fetching Psi Competitors:", error);
      } finally {
        store.dispatch(setIsLoading(false));
      }
    };

    fetchPsiRequestData();
    // eslint-disable-next-line
  }, [hasGetPermissions, userToken, dispatch,showCompetitorsPageSpeedTabs===true,refresh]);

  return (
    <>
      <Paper sx={{ padding: "8px 16px", mb:2 }}>
        <Typography fontSize={24}>Competitors Page Speed</Typography>
        <Typography sx={{ color: "#00000099", paddingBottom: "16px" }}>
          Analyze competitor website speeds and measure your performance against
          them
        </Typography>
        <Grid container mt={0} columnSpacing={3} mb={1}>
          <Grid item xs={10}>
          <Autocomplete
              sx={{ width: "100%" }}
              options={siteOptions}
              getOptionLabel={(option:any) => option.siteName} // Display siteName in the Autocomplete
              onChange={(event, newValue) => {
                setSelectedSiteUid(newValue?.uid || null); // Store the UID of the selected site
                setShowCompetitorsPageSpeedTabs(false); // Set to false when value changes
              }}
              onInputChange={(event, inputValue, reason) => {
                if (reason === "clear") {
                  setShowCompetitorsPageSpeedTabs(false); // Set to false when the clear button (cross) is clicked
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search site name or choose from the list"
                  label="Select Your Website"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={!selectedSiteUid}
              fullWidth
              size="large"
              color="secondary"
              sx={{ py: "7px" }}
              onClick={handleSelectSite}
            >
              SELECT SITE
            </Button>
          </Grid>
        </Grid>
      </Paper>
      
        {isLoading ? (
          <Grid container justifyContent="center" mt={2}>
            <CircularProgress color="secondary"/>
          </Grid>
        ) : (
          
          showCompetitorsPageSpeedTabs && <Paper sx={{ padding: "8px 16px" }}> <CompetitorsPageSpeedTabs psiRequestRows={psiRequestsRows} userToken={userToken}
          selectedSiteUid={selectedSiteUid} setRefresh={setRefresh} refresh={refresh}/></Paper>
          
        )}
      
    </>
  );
};

export default CompetitorsPageSpeed;
