import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Link from '@mui/material/Link';

interface Props {
  tableHeadings: { [key: string]: string };
  rows: any[];
}

const PerformanceDashboardBottomRatedPages = ({
  tableHeadings,
  rows,
}: Props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const headCells = Object.entries(tableHeadings);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer
        // component={Paper}
        sx={{ maxHeight: 800, background: "#ffffff", mt: "10px" }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(([key, value], index) => (
                <TableCell key={index} align="left">
                  <TableSortLabel>
                    {value && typeof value === "string" ? value : ""}
                  </TableSortLabel>
                </TableCell>
              ))}
              {/* <TableCell align="center">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <React.Fragment key={row.cron_id}>
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    {headCells.map(([key], idx) => (
                      <TableCell key={idx} align="left">
                         {key === "page_name" ? (
                          <>
                            <Link href="#" color="secondary">
                              {row[key] ? row[key] : "N/A"}
                            </Link>
                          </>
                        ) : row[key] ? (
                          row[key]
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={1}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel":
                {
                  display: "none",
                },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
              display:'none'
            }}
          />
          <Pagination
            count={rows.length}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
              display:'none'
            }}
          />
        </Stack>
      </TableContainer>
    </>
  );
};

export default PerformanceDashboardBottomRatedPages;
