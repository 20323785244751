import { Button, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrandDialogue from "./TrandDialogue/TrandDialogue";

const PerformanceDashboardOverallScoreTrend = () => {

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box
        className="trendWrap"
        sx={{ background: "#FFF4E5", border: "1px solid #ED6C0280" }}
      >
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            Real Users’ Experience
          </Typography>
          <Button
            size="small"
            sx={{
              width: "120px",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "0",
            }}
            onClick={handleOpenModal}
            variant="outlined"
            color="warning"
            
          >
            view trend <LeaderboardIcon sx={{ width: "16px", ml: "5px" }} />
          </Button>
        </Stack>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={2}>
            <Typography variant="h3">50%</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  mr: "6px",
                }}
              >
                13.7% <TrendingFlatIcon />{" "}
              </Typography>
              <Typography
                sx={{ color: "#000000", fontSize: "12px", fontWeight: "500" }}
              >
                compared to the previous quarter.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="body1"
              sx={{ color: "#00000099", fontSize: "12px" }}
            >
              The weighted average of achievement ratios, with each sub-metric
              multiplied by its weight.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="trendWrap"
        my={2}
        sx={{ background: "#EDF7ED", border: "1px solid #2E7D3280" }}
      >
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            Ads Mobile Page Speed Score
          </Typography>
          <Button
            size="small"
            sx={{
              width: "120px",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "0",
            }}
            variant="outlined"
            color="success"
            onClick={handleOpenModal}
          >
            view trend <LeaderboardIcon sx={{ width: "16px", ml: "5px" }} />
          </Button>
        </Stack>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={2}>
            <Typography variant="h3">50%</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  mr: "6px",
                }}
              >
                13.7% <TrendingDownIcon />{" "}
              </Typography>
              <Typography
                sx={{ color: "#000000", fontSize: "12px", fontWeight: "500" }}
              >
                compared to the previous quarter.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="body1"
              sx={{ color: "#00000099", fontSize: "12px" }}
            >
              The weighted average of achievement ratios, with each sub-metric
              multiplied by its weight.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="trendWrap"
        sx={{ background: "#FDEDED", border: "1px solid #D32F2F80" }}
      >
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            Real Users’ Experience
          </Typography>
          <Button
            size="small"
            sx={{
              width: "120px",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "0",
            }}
            variant="outlined"
            color="error"
            onClick={handleOpenModal}
          >
            view trend <LeaderboardIcon sx={{ width: "16px", ml: "5px" }} />
          </Button>
        </Stack>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={2}>
            <Typography variant="h3">50%</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  mr: "6px",
                }}
              >
                0% <TrendingDownIcon />{" "}
              </Typography>
              <Typography
                sx={{ color: "#000000", fontSize: "12px", fontWeight: "500" }}
              >
                compared to the previous quarter.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="body1"
              sx={{ color: "#00000099", fontSize: "12px" }}
            >
              The weighted average of achievement ratios, with each sub-metric
              multiplied by its weight.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <TrandDialogue openModal={openModal} onCloseModal={handleCloseModal} />
    </>
  );
};

export default PerformanceDashboardOverallScoreTrend;
