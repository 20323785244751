import { setDisplayErrorSnackBar,setIsLoading } from "../../../../features/userSlice";
import { endpointsService } from "../../../../services/endPoints.services";
import store from "../../../../store";

async function getActivityNotesContent(oauthTokenHeader: string,setRows:any,) {
    try {
        const response = await endpointsService.getActivityNotes(oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else {
            setRows(response?.data?.data); // Set the fetched data
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function getSingleActivityNotesContent(oauthTokenHeader: string, setSingleAdvertisingAccountContent: any,note_shared_uid:any) {
    try {
        const response = await endpointsService.getSingleActivityNote(oauthTokenHeader, note_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
        } else {
            setSingleAdvertisingAccountContent(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function postActivityNotesContent(oauthTokenHeader: string, setPostedActivityNotesContent: any,setErrorAccToPageStatus:any,data:any, setIsLoading:any, trackPromise:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.postActivityNotes(oauthTokenHeader, data);
        if (!response.status || response.status !== 201) {
            setErrorAccToPageStatus([response])
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            store.dispatch(setIsLoading(true))
            setPostedActivityNotesContent([response?.data?.data]);
            // trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: "Create Successfull", type: 'success' })))
        }
    } catch (e:any) {
        // store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}
async function postComment(oauthTokenHeader: string, setCommentContent: any,setErrorAccToPageStatus:any,data:any,note_shared_uid:any, setIsLoading:any) {
    try {
        const response = await endpointsService.postComment(oauthTokenHeader, data, note_shared_uid);
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            store.dispatch(setIsLoading(true))
            setCommentContent([response?.data?.data]);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function updateActivityNote(oauthTokenHeader: string, data:any, object_shared_uid:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.updateActivityNote(oauthTokenHeader,data,object_shared_uid)
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to edit expense');
        }else {
            store.dispatch(setDisplayErrorSnackBar({ message: "Update Successfull", type: 'success' }))
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function getLabels(oauthTokenHeader: string, setLabelsContent: any,setErrorAccToPageStatus:any, trackPromise: any) {
    try {
        const response = await endpointsService.getLabels(oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            setLabelsContent(response?.data?.data);
        }
    } catch (e:any) {
        // store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}
async function getComment(oauthTokenHeader: string, note_shared_uid:any, setCommentContent: any,setErrorAccToPageStatus:any, trackPromise: any) {
    try {
        const response = await endpointsService.getComment(oauthTokenHeader, note_shared_uid);
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            setCommentContent(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function deleteActivityNote(oauthTokenHeader: string, note_shared_uid:any, setSuccess: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.deleteActivityNote(oauthTokenHeader, note_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to update comments');
        }
        else {
            setSuccess(response?.reason);
            store.dispatch(setDisplayErrorSnackBar({ message: "Delete Successfull", type: 'success' }))
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function fileUploadME(oauthTokenHeader: string, data:any, object_shared_uid:string) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.fileUploadME(oauthTokenHeader,data,object_shared_uid);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to upload file');
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
    }
}

async function getFiles(oauthTokenHeader: string, object_shared_uid:string,setUploadedFiles:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getFiles(oauthTokenHeader,object_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get files');
        }
        else {
            setUploadedFiles(response?.data?.data)
        }
    } catch (e:any) {
        // if(e?.status !== undefined){
        //     store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText  || 'Unknown error occurred', type: 'error' }));
        // }else {
        //     store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        // }
    }
}

// eslint-disable-next-line
export const ActivityNotesEndPointLogic = {
    getActivityNotesContent,
    postActivityNotesContent,
    updateActivityNote,
    getLabels,
    postComment,
    getComment,
    fileUploadME,
    deleteActivityNote,
    getFiles,
    getSingleActivityNotesContent
};
