import { useSelector } from "react-redux";
import { selectUserMenu } from "../../../features/userSlice";
import { useMemo } from "react";
import { ModulesPermissionNames } from "../../../data/menu/ModulesPermissions";

export function useHasETLSequencesPermission(uiNames: ModulesPermissionNames[]): boolean {
    const etlSequencesManagement = useSelector(selectUserMenu)?.["admin"] ?? null;
    return useMemo(() => {
        let flag = false;
        const capabilities = etlSequencesManagement?.find((item) => item["processes"])?.["etl_sequences"] ?? null;
        // eslint-disable-next-line
        uiNames?.map((uiName: ModulesPermissionNames) => {
            const res = !!Object.values(capabilities ?? {}).map(items => items &&
                Object.values(items).map((item:any,index:any) =>item[index]?.ui_name === uiName)
              );    
            if (!res) {
                flag = false;
                return flag;
            } else {
                flag = true;
            }
        })
        return flag;
    }, [etlSequencesManagement, uiNames]);
}
