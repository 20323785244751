import { Paper, Typography } from "@mui/material";
import DetailsConnectivity from "./EditWebsiteElements/DetailsConnectivity";
import AdditionalInfo from "./EditWebsiteElements/AdditionalInfo";

function EditWebsite() {
  return (
    <>
      <Paper sx={{ padding: "8px 16px" }}>
        <Typography fontSize={24}>Edit Website</Typography>
        <DetailsConnectivity></DetailsConnectivity>
        <AdditionalInfo></AdditionalInfo>
      </Paper>
    </>
  );
}

export default EditWebsite;
