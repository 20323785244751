import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from "react";
import SimpleBackdrop from "../../../../../BackDrop/BackDrop";

interface PageScanRequestModalProps {
  open: boolean;
  handleClose: () => void;
  addNewPageReqText:any,
  setAddNewPageReqText:any
  postPageSpeed:Function
  errorAccToPageStatus:any
  postedPageSpeedContent:any
  setOpenModal:any,
  setErrorAccToPageStatus:any
}

function PageScanRequestModal({
  open,
  handleClose,
  addNewPageReqText, 
  setAddNewPageReqText,
  postPageSpeed,
  errorAccToPageStatus,
  postedPageSpeedContent,
  setOpenModal,
  setErrorAccToPageStatus,
}: PageScanRequestModalProps) {
  const [showAlert, setShowAlert] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [/* requestStatus */, setRequestStatus] = useState('');
  const handleRequest = async () => {
   
   const data = {
      url_name: addNewPageReqText,
    };
    
    try {
      await postPageSpeed(data); // Your API call
      setRequestStatus('success');
      
    } catch (error) {
      setRequestStatus('error');
    } finally {
      // setLoading(false); // Reset loading state
    }
  };

useEffect(() => {
  if (errorAccToPageStatus?.length > 0) {
    setShowAlert(true);
    setOpenModal(true)
    // Hide the alert after 3 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }else{
    setOpenModal(false)
  }
}, [errorAccToPageStatus, setOpenModal]);

  return (
    <>
      {loading  ? <SimpleBackdrop /> :
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Add New Page Scan Request
        </DialogTitle>
        <TextField
          id="outlined-multiline-flexible"
          value={addNewPageReqText}
          onChange={(e) => setAddNewPageReqText(e.target.value)}
          label="URL*" // Set your label here
          placeholder="Paste the URL of the page you would like to scan here" // Set your placeholder here
          multiline
          color="secondary"
          size="small"
          maxRows={4}
          sx={{
            width: "852px",
          }}
        />
        <Typography sx={{fontSize:"12px", marginTop:"8px", color:"#00000099"}}>Mandatory field</Typography>
        <Typography sx={{fontSize:"12px",color:"#00000099"}}>New Page Scan Requests are created with a default active state.</Typography>
        {
        showAlert && 
        <Alert
                iconMapping={{
                    success: <CheckCircleOutlineIcon sx={{color: "#1E4620"}}/>,
                }}
                sx={{
                    height: "50%",
                    fontWeight: "500",
                    backgroundColor: "#FDEDED",
                    color: "#5F2120",
                    display:"flex",
                    alignItems:"center",
                    padding:"0 4px",
                    border: "1px solid #D32F2F80",
                    marginBottom:"10px",
                    marginTop:"10px"
                }}
                severity="warning"
                >
                {errorAccToPageStatus[0]?.parsedText?.reason}
        </Alert>
      }
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
                <Button color="secondary" onClick={handleClose}>CANCEL</Button>
                <Button variant="contained" color="secondary" onClick={handleRequest} disabled={addNewPageReqText.trim() === ""}>REQUEST SCAN</Button>
        </Stack>
      </Paper>
      
    </Dialog>
    }
    </>
  );
}

export default PageScanRequestModal;
