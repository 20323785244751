import React, { FC, useEffect, useState } from 'react';
import { Autocomplete, InputAdornment, Snackbar, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CreateAccountDialog from './CreateAccount';
import Paper from '@mui/material/Paper';
import { advertisingAccountsEndPointLogic } from '../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { useHasAdvertisingAccountsPermission } from "../../../../logic/PaidTrafficManagement/AdvertisingAccounts/Permissions/useHasAdvertisingAccountsPermission";
import { useSelector } from 'react-redux';
import { selectIsLoading, setIsLoading } from '../../../../features/userSlice';
import store from '../../../../store';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import { Box } from '@mui/system';

interface TrafficSource {
  shared_uid: string;
  traffic_source_name: string;
}

interface adAccountDetails{
  data: {}
}

interface AddNewAccountDialogProps {
  open: boolean;
  handleClose: () => void;
  trafficSources:any;
  oauthTokenHeader:string;
  mergedAssets:any
  setReloadAfterToggleEdit:any
  websitePermissions:any
  siteNames:any
}

const AddNewAccountDialog: FC<AddNewAccountDialogProps> = ({siteNames,websitePermissions,setReloadAfterToggleEdit, open, handleClose , trafficSources ,oauthTokenHeader, mergedAssets}) => {
/*   const currencies = [
    {
      value: "Value",
      label: "Value",
    },
  ]; */

  const hasGetPermissions = useHasAdvertisingAccountsPermission([
    ModulesPermissionNames.GET_API_AD_ACCOUNT,
    ModulesPermissionNames.GET_API_AD_ACCOUNTS,
    ModulesPermissionNames.GET_API_SITE_ACCOUNT,
  ]);
  const [switchChecked, setSwitchChecked] = useState(true);
  const [retrieveFailed, setRetrieveFailed] = useState(false)
  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  const [, setSelectedId] = useState("");
  const [adAccountId, setAdAccountId] = useState("")
  const [adAccountDetails , setAdAccountDetails] = useState<adAccountDetails[]>([]);
  const [adAccountGeoDetails , setAdAccountGeoDetails] = useState([]);
  const [adAccountIndustryDetails , setAdAccountIndustryDetails] = useState([]);
  const [adAccountTerritoryDetails , setAdAccountTerritoryDetails] = useState([]);
  const uniqueTrafficSources:TrafficSource[] = trafficSources && Array.from(new Set(trafficSources));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [adAccLength, setAdAccLength] = useState(true)
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [createAccRes, setCreateAccRes] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const isLoading = useSelector(selectIsLoading);

const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };
const handleRetrieveAccount = (): void => {
    if ( (!adAccountId || !selectedSource)) {
      setSnackbarMessage("Source or Id is missing")
      setSnackbarOpen(true)
      setShowCreateAccountDialog(false);
    }/* if(adAccountDetails[0]?.data && Object.keys(adAccountDetails[0]?.data).length === 0){
      setRetrieveFailed(true)
      setShowCreateAccountDialog(false);
    } */
      else{
      getAdAccountDetails();
      getTerritories()
      getGeo()
      getIndustries()
     setShowCreateAccountDialog(true); 
     }
  };

  const handleCreateAccountDialogClose = (): void => {
    setShowCreateAccountDialog(false);
  };

  const handleCancel = (): void => {
    setAdAccountId("")
    setSelectedSource("");
    setSelectedId("");
    handleClose();
  };

  const handleDialogClose = (): void => {
    setSelectedSource("");
    setSelectedId("");
    handleClose();
  };
  const options = uniqueTrafficSources?.map(source => source?.traffic_source_name);
const trafficSourceUid = uniqueTrafficSources.find(source => source.traffic_source_name === selectedSource);
 
  async function getAdAccountDetails(){
   store.dispatch(setIsLoading(true));
    if (hasGetPermissions) {
      try {
    advertisingAccountsEndPointLogic.getAdAccountDetails(trafficSourceUid?.shared_uid as string,adAccountId,setIsLoading,setRetrieveFailed,setAdAccountDetails,oauthTokenHeader)
    
  } catch (error) {
    console.error("Error fetching advertising accounts:", error);
  }
  /* store.dispatch(setIsLoading(false)); */
}
  }
  function getGeo(){

    try {
      advertisingAccountsEndPointLogic.getGeo(setAdAccountGeoDetails,oauthTokenHeader)
     
 } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  }
  function getIndustries(){

    try {
      advertisingAccountsEndPointLogic.getIndustry(setAdAccountIndustryDetails,oauthTokenHeader)
     
 } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  }
  function getTerritories(){

    try {
      advertisingAccountsEndPointLogic.getTerritory(setAdAccountTerritoryDetails,oauthTokenHeader)
     
 } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    }
  }

 useEffect(() => {
    if (createAccRes === true) {
      setSnackbarMessage("Error")
     }
  }, [createAccRes])

  useEffect(() => {
    if (retrieveFailed) {
        const timer = setTimeout(() => {
            setRetrieveFailed(false);
        }, 2000);
        return () => clearTimeout(timer); // Clear the timer on component unmount
    }
}, [retrieveFailed]);

useEffect(() => {
  if(adAccountDetails.length === 0){
    setAdAccLength(true)
  }else if(adAccountDetails?.length > 0 && Object.keys(adAccountDetails[0]?.data).length === 0){
    setAdAccLength(true)
    setRetrieveFailed(true)
  }else{
    setRetrieveFailed(false)
    setAdAccLength(false)
  }
}, [adAccountDetails])

return (
    <>
      <Dialog maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title" sx={{overflow:"scroll"}}>
     
        <Paper>
          
          {adAccLength && (
            <>
              <DialogTitle id="responsive-dialog-title" sx={{ paddingBottom: "0px !important" }}>
              {
          retrieveFailed && 
          <Alert
                iconMapping={{
                    success: <CheckCircleOutlineIcon sx={{color: "#1E4620"}}/>,
                }}
                sx={{
                    height: "50%",
                    fontWeight: "500",
                    backgroundColor: "#FDEDED",
                    color: "#5F2120",
                    display:"flex",
                    alignItems:"center",
                    padding:"0 4px",
                    border: "1px solid #D32F2F80",
                    marginBottom:"10px"
                }}
                severity="warning"
                >
                Unable to retrieve account, please check the ID number and try again.
        </Alert>
        }
                {"Add New Account"}
              </DialogTitle>
              <DialogContent sx={{ paddingTop: "16px !important" }}>
                <DialogContentText sx={{ paddingBottom: "16px" }}>
                <div style={{display:"flex", gap:"16px"}}>
                <Autocomplete
                  options={options}
                  /* onInputChange={(event, newInputValue) => {
                    setSelectedSource(newInputValue);
                  }} */
                    onChange={(event, newValue:any) => {
                      
                      setSelectedSource(newValue);
                  }}
                  renderOption={(account, option: any, { selected }) => (
                    <Box
                      component="li"
                      {...account}
                      sx={{
                        backgroundColor: selected ? "#F7EEF9 !important" : "inherit",
                      }}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      helperText="Advertisement source platform (Google, Bing etc...)"
                      {...params}
                      label="Source *"
                      InputProps={{
                        ...params.InputProps,
                       
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                     
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "418px"}}
                />
                <TextField
                    value={adAccountId}
                    onChange={(e) => setAdAccountId(e.target.value)}
                    helperText="Generated by the advertisement source"
                    id="outlined-disabled"
                    label="ID at Source *"
                    size='small'
                    sx={{
                      width: "418px",
                      color: "secondary",
                      "& input": {
                        "&::selection": {
                          backgroundColor: "#D3D3D3", // Light grey color for the text selection
                        },
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 100px #ffffff inset", // Change the background for autofilled inputs
                          WebkitTextFillColor: "#000", // Change text color for autofilled inputs
                        },
                      },
                    }}
                    color="secondary"
                />
                </div>
                </DialogContentText>
                <FormHelperText>All fields are mandatory.</FormHelperText>
                <DialogContentText sx={{ paddingTop: "16px", display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <FormControlLabel control={
                      <Switch color="success"
                      disabled
                        checked={switchChecked}
                        onChange={handleSwitchChange} />
                    } label="Sync data with source" />
                    <FormHelperText>New advertising accounts are created with a default Enabled state.</FormHelperText>
                  </div>
                  <Alert
                    iconMapping={{
                      success: <CheckCircleOutlineIcon sx={{ color: switchChecked ? "#1E4620" : "#5F2120" }} />,
                    }}
                    sx={{
                      width: "50%",
                      height: "50%",
                      fontWeight: "500",
                      backgroundColor: switchChecked ? "#EDF7ED" : "#FDEDED",
                      color: switchChecked ? "#1E4620" : "#5F2120",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 4px",
                      border: switchChecked ? "1px solid #2E7D3280" : "1px solid #D32F2F80"
                    }}
                    severity="success"
                  >
                    {switchChecked ? "Enabled" : "Disabled"}
                  </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ padding: "0px 24px 20px 24px" }}>
                <Button autoFocus onClick={handleCancel}
                  sx={{
                    color: "#9C27B0",
                    "&:hover":
                    { background: "#ffffff", color: "#9C27B0" }
                  }}>
                  CANCEL
                </Button>
                <Button
                  onClick={handleRetrieveAccount}
                  autoFocus
                  sx={{
                    background: "#9C27B0",
                    color: "#ffffff",
                    "&:hover": {
                      background: "#9C27B0",
                      color: "#ffffff"
                    },
                  }}
                >
                  Retrieve Account <BrowserUpdatedIcon sx={{ marginLeft: "10px" }} />
                </Button>
              <Snackbar sx={{ top:"100px !important" }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="error"  variant="filled" sx={{ width: '100%', zIndex:"9999999", top:"100px" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
              </DialogActions>
            </>
          )}
           
          </Paper>
<>
          {isLoading  && <SimpleBackdrop />}
          {/* !showCreateAccountDialog && !adAccountDetails[0] && isLoading ? <SimpleBackdrop/> : */
          !adAccLength && showCreateAccountDialog && 
          <CreateAccountDialog
          siteNames={siteNames}
          getAdAccountDetails={getAdAccountDetails}
          websitePermissions={websitePermissions}
          setErrorSnackbarOpen={setErrorSnackbarOpen}
          errorSnackbarOpen={errorSnackbarOpen}
          setShowCreateAccountDialog={setShowCreateAccountDialog}
          setCreateAccRes={setCreateAccRes}
          createAccRes={createAccRes}
          setSelectedSource={setSelectedSource}
          setAdAccountDetails={setAdAccountDetails}
          setAdAccLength={setAdAccLength} 
          handleCloseRetrieve={handleClose}
          adAccountId= {adAccountId}
          setAdAccountId={setAdAccountId}
          open={showCreateAccountDialog} 
          handleClose={handleCreateAccountDialogClose} 
          adAccountDetails={adAccountDetails} 
          selectedSource={selectedSource} 
          adAccountGeoDetails={adAccountGeoDetails} 
          adAccountIndustryDetails={adAccountIndustryDetails}
          oauthTokenHeader={oauthTokenHeader}
          mergedAssets={mergedAssets}
          adAccountTerritoryDetails={adAccountTerritoryDetails}
          setReloadAfterToggleEdit={setReloadAfterToggleEdit}
          />
        }
       
        </>
      </Dialog>
      {/* <Snackbar sx={{ top:"100px !important" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error"  variant="filled" sx={{ width: '100%', zIndex:"9999999", top:"100px" }}>
          {"Account creation failed."}
        </Alert>
      </Snackbar> */}
      <Snackbar sx={{ top:"100px !important" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="success"  variant="filled" sx={{ width: '100%', zIndex:"9999999", top:"100px" }}>
          {"Account creation successfull."}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddNewAccountDialog;
