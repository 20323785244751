import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import React, { useState } from "react";
import "../../EngagementIncentives.css";

interface Props {
  tableHeadings: { [key: string]: string };
  exequteQueryContentQuery4: any;
  brandName:any;
}

const PerformanceDayBrandsTable = ({ tableHeadings, exequteQueryContentQuery4 ,brandName }: Props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const rows = exequteQueryContentQuery4?.query_output || [];

  const headCells = Object.entries(tableHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = brandName.length!==0
    ? rows.filter((row: any) => row.brand_name?.toLowerCase() === brandName.toLowerCase())
    : rows;

  const handleRequestSort = (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
  };  
  const sortedRows = filteredRows.sort((a: any, b: any) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    }
    return 0;
  });

  return (
    <>
      <TableContainer
        className="PageScantablewrap"
        component={Paper}
        sx={{ maxHeight: 800 }}
      >
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(([key, value], index) => (
                <TableCell key={index} align="left">
                  <TableSortLabel
                  active={orderBy === key}
                  direction={orderBy === key ? order : "asc"}
                  onClick={() => handleRequestSort(key)}>
                    {value && typeof value === "string" ? value : ""}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any, rowIndex:number) => (
                <React.Fragment key={rowIndex}>
                  <TableRow
                    sx={{
                      "& > *": {
                        borderBottom:
                          "1px solid rgba(224, 224, 224, 1) !important",
                      },
                    }}
                  >
                    {headCells.map(([key], cellIndex) => (
                      <TableCell key={cellIndex} align="left">
                        {key === "date_of_aquisition" ? (
                          <>{row[key] ? row[key] : "N/A"}</>
                        ) : key === "brand_name" ? (
                          <> {row[key] ? row[key] : "N/A"}</>
                        ) : key === "clicks" ? (
                          <> {row[key] ? row[key] : "0"}</>
                        ) : key === "registrations" ? (
                          <>{row[key] ? row[key] : "0"}</>
                        ) : key === 'cost' ? (
                          <>{row[key] ? `${row[key]}$` : "0"}</>
                        ) : key === "ftd" ? (
                          <>{row[key] ? row[key] : "0"}</>
                        ) : row[key] ? (
                          row[key]
                        ) : (
                          "0"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel":
                {
                  display: "none",
                },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={Math.ceil(filteredRows.length/rowsPerPage)}
            onChange={(event, value) => setPage(value - 1)}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: 'hidden-displayed-rows', // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
            }}
          />
        </Stack>
      </TableContainer>
    </>
  )
}

export default PerformanceDayBrandsTable