import {
  Grid,
  Typography,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface Props {
  additionalHeadings: { [key: string]: string };
  additionalRows: any[];
}

const BudgetControlTableAdditionalContent = ({ additionalHeadings, additionalRows }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const headCells = Object.entries(additionalHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyClick = (targetLink:any) => {
    if (targetLink) {
      navigator.clipboard.writeText(targetLink)
        .then(() => {
          setSnackbarOpen(true);  // Show Snackbar
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);  // Close Snackbar after display
  };

  return (
    <>
      <Grid container columnSpacing={2} sx={{ marginTop: "0px" }}>
        <Grid item xs={12}>
          <Typography>Links</Typography>
        </Grid>
        <Grid item xs={10}>
        <TableContainer className="BudgetTableWrap" component={Paper} sx={{ maxHeight: 800 }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel>
                  {value && typeof value === "string" ? value : ""}
                </TableSortLabel>
              </TableCell>
            ))}
            {/* <TableCell align="center">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {additionalRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((additionalRows, index) => (
              <React.Fragment key={additionalRows.cron_id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                {headCells.map(([key], idx) => (
                   <TableCell key={idx} align="left">
                   {key === 'id' ? (
                     <>{index+1}</>
                   ):key ==='offer_line_1' ? (
                    <>
                    {additionalRows?.offer_line_1 && <>{additionalRows.offer_line_1}<br /></>}
                    {additionalRows?.offer_line_2 && <>{additionalRows.offer_line_2}</>}
                    </>
                   ): key === 'target_lending_page' ? (
                    <IconButton
                      aria-label="copy"
                      size="small"
                      color="secondary"
                      onClick={() => handleCopyClick(additionalRows?.target_lending_page)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  )  : (
                     additionalRows[key] ? additionalRows[key] : "N/A"
                   )}
                 </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={additionalRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
            display:'none'
          }}
        />
        <Pagination
          count={additionalRows.length}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
            display:'none'
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={additionalRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".css-levciy-MuiTablePagination-displayedRows": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
            display:'none'
          }}
        />
      </Stack>
    </TableContainer>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          icon={<CheckCircleIcon fontSize="inherit" />}
        >
          Link copied!
        </Alert>
      </Snackbar>
    </>
  );
};

export default BudgetControlTableAdditionalContent;
