import QuaterlyGoalTable from "./QuarterlyGoalTable/QuaterlyGoalTable"
import QuaterlyGoalFilterCard from "./QuaterlyGoalsFilter/QuaterlyGoalFilterCard"
import { useHasPageSpeedPerformancePermission } from '../../../../../logic/AssetManagement/Health/Permissions/useHasPageSpeedPerformancePermission';
import { ModulesPermissionNames } from '../../../../../data/menu/ModulesPermissions';
import { useEffect, useState } from 'react';
import { QuarterlySpeedGoalsEndPointLogic } from '../../../../../logic/AssetManagement/Health/Quarterly Website Speed Goals/QuarterlySpeedGoalsEndPointLogic';
import SimpleBackdrop from '../../../../BackDrop/BackDrop';

interface QuarterlyGoalsProps {
  selectedSharedUid:any;
  oauthTokenHeader:any;
  hasEditPermissions:any;
  selectedDevice:any;
  setSelectedDevice:any;
  goalsRequestedBy:any;
  setGoalsRequestedBy:any;
  selectedgoalsDate:any;
  setSlectedgoalsDate:any
}


function QuarterlyGoals({selectedSharedUid,oauthTokenHeader,hasEditPermissions,selectedDevice,setSelectedDevice,goalsRequestedBy,setGoalsRequestedBy,selectedgoalsDate,setSlectedgoalsDate}:QuarterlyGoalsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [quarterlyGoalsData, setQuarterlyGoalsData] = useState([]) as any;
  const [ , setErrorAccToPageStatus] = useState([]);

  const hasGetPermissions = useHasPageSpeedPerformancePermission([
    ModulesPermissionNames.GET_API_PSI_GOALS,
  ]);

  useEffect(() => {
    const fetchQuarterlyGoalsData = async() => {
      if (hasGetPermissions) {
        setLoading(true);
        try {
          await QuarterlySpeedGoalsEndPointLogic.getQuarterlySpeedGoals(oauthTokenHeader,selectedSharedUid,setQuarterlyGoalsData,setErrorAccToPageStatus);
        } catch (error) {
          throw new Error("Error fetching goals");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchQuarterlyGoalsData();
  // eslint-disable-next-line
  },[selectedSharedUid,oauthTokenHeader]);

  const tableHeadings = {
    "edit_page": "Edit",
    "period": "Period",
    "device":"Device",
    "LCP": "LCP",
    "INP":"INP",
    "CLS":"CLS",
    "FCP":"FCP",
    "TTFB":"TTFB",
    "mobile_speed_score":"Mobile Speed Score",
    "requested_on":"Requested On",
    "requested_by":"Requested By",
  }

  return (
    <>
      <QuaterlyGoalFilterCard oauthTokenHeader={oauthTokenHeader} hasEditPermissions={hasEditPermissions} hasGetPermissions={hasGetPermissions} tableData={quarterlyGoalsData?.data} selectedSharedUid={selectedSharedUid} setLoading={setLoading} loading={loading} quarterlyGoalsData={quarterlyGoalsData} setQuarterlyGoalsData={setQuarterlyGoalsData} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} goalsRequestedBy={goalsRequestedBy} setGoalsRequestedBy={setGoalsRequestedBy} selectedgoalsDate={selectedgoalsDate} setSlectedgoalsDate={setSlectedgoalsDate}/>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <>
          <QuaterlyGoalTable tableHeadings={tableHeadings} selectedSharedUid={selectedSharedUid} oauthTokenHeader={oauthTokenHeader} hasEditPermissions={hasEditPermissions} hasGetPermissions={hasGetPermissions} quarterlyGoalsData={quarterlyGoalsData} setQuarterlyGoalsData={setQuarterlyGoalsData} setLoading={setLoading} loading={loading} tableData={quarterlyGoalsData?.data} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} goalsRequestedBy={goalsRequestedBy} setGoalsRequestedBy={setGoalsRequestedBy} selectedgoalsDate={selectedgoalsDate} setSlectedgoalsDate={setSlectedgoalsDate}/>
        </>
      )}
    </>
  )
}

export default QuarterlyGoals
