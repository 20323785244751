/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  Snackbar,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState,useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from 'dayjs';
// Tab function
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Day {
  name: string;
  isSelected: boolean;
}

interface FrequencyTabsProps {
  onSelectedHourlyTimeChange: (time: string) => void;
  onSelectedRepeatPerDayTimeChange: (time: string) => void;
  onSelectedRepeatEveryDayTimeChange: (time: string) => void;
  onSelectedMonthlyTimeChange: (time: string) => void;
  onSelectedWeeklyTimeChange: (time: string) => void;
}

const FrequencyTabs = ({ onSelectedHourlyTimeChange, onSelectedRepeatEveryDayTimeChange,onSelectedMonthlyTimeChange,onSelectedWeeklyTimeChange,onSelectedRepeatPerDayTimeChange }: FrequencyTabsProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = useState<Dayjs | null>(dayjs().hour(0).minute(0)); // Initialize to "00:00"
  const [selectedHourlyTime, onSelectedHourlyTime] = useState('');
  const [minute, setMinute] = useState<any>();
  const [selectedHour, setSelectedHour] = useState<Dayjs | null>(null); // Initialize to valid Dayjs object
  const [formattedTime, setFormattedTime] = useState(''); // New state variable to store the formatted time
  const [dailyGap, setDailyGap] = useState<any>(); // Gap duration in minutes or hours
  const [dailyGapUnit, setDailyGapUnit] = useState(""); // "minutes" or "hours"
  const [repeatEveryDayTimeSelected, setRepeatEveryDayTimeSelected] = useState(false);
  const [weeklyTimeSelected, setWeeklyTimeSelected] = useState(false);
  const [selectMonthTime, setSelectMonthTime] = useState(null);
  const [selectMonthDate, setSelectMonthDate] = useState(null);
  const [selectedEveryDayRepeatTime, setSelectedEveryDayRepeatTime] = useState<Dayjs | null>(null); // New state variable to store the selected time
  const [selectedPerDayRepeatTime, setSelectedPerDayRepeatTime] = useState<Dayjs | null>(null); // New state variable to store the selected time
  const [selectedWeekTime, setSelectedWeekTime] = useState<Dayjs | null>(null); // New state variable to store the selected time
  const [selectedMonthsTime, setSelectedMonthsTime] = useState<Dayjs | null>(null); // New state variable to store the selected time
  const [selectedRepeatEveryDayTime, setSelectedRepeatEveryDayTime] = useState('');
  const [formattedRepeatEveryDayTime, setFormattedRepeatEveryDayTime] = useState('');
  const [selectedPerDayTime, setSelectedPerDayTime] = useState<string>('');
  const [selectedMonthlyTime, setSelectedMonthlyTime] = useState('');
  const [selectedWeeklyTime, setSelectedWeeklyTime] = useState('');
  const [selectedWeeks, setSelectedWeeks] = useState<number>(1);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<number[]>([]);
  const [everyValue, setEveryValue] = useState<number>(1);
  const [selectedDay, setSelectedDay] = useState<number>(1);
  const [selectedMonthTime, setSelectedMonthTime] = useState<Dayjs | null>(dayjs());
  const [selectedTimesPerDay, setSelectedTimesPerDay] = useState<any>(1); // Number of times per day
  const [selectedMinuteInterval, setSelectedMinuteInterval] = useState<Dayjs | null>(dayjs('00:30')); // Minute interval
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(Date.now()));
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event:any) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "RepeatEveryDay") {
      setSelectedMinuteInterval(null);
      setSelectedPerDayRepeatTime(null);
      setSelectedPerDayTime("");
      onSelectedRepeatPerDayTimeChange("");
    } else if (event.target.value === "Repeat") {
      setSelectedEveryDayRepeatTime(null);
      setSelectedRepeatEveryDayTime("");
      setFormattedRepeatEveryDayTime("");
      onSelectedRepeatEveryDayTimeChange("");
    }
  };

  const handleDateChange = (newDate:any) => {
    if (newDate) {
      setSelectedDate(newDate);
      setSelectMonthDate(newDate);
      setSelectedDay(newDate.date());
      handleDayChange(newDate.date());
      setSelectedDate(newDate);
    }
  };

  // Set the default value to the current time if no value is provided
  useEffect(() => { 
    if (!value) {
      setValue(dayjs());
    }
  }, [value]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMinuteChange = (event: any) => {
    const minuteSelected = event.target.value;
    setMinute(minuteSelected);
    const cronExpression = `${minuteSelected} */${selectedHour} * * *`;
    onSelectedHourlyTimeChange(cronExpression); // Call the callback function with the selected time
    onSelectedHourlyTime(cronExpression);
  };
  
  const handleHourChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isValid()) { // Check if newValue is valid
        setValue(newValue);
        setSelectedHour(newValue);
        const hour = newValue.hour();
        const cronExpression = `${minute} */${hour} * * *`;
        setFormattedTime(newValue.format('hh:mm A'));
        onSelectedHourlyTimeChange(cronExpression);
    }
};

  const handleRepeatEveryDayTimeChange = (newValue:any) => {
    setValue(newValue);
    setSelectedEveryDayRepeatTime(newValue);
    setRepeatEveryDayTimeSelected(!!newValue); // Set repeatEveryDayTimeSelected to true when a time is selected
    if (newValue) {
      const hour = newValue.hour();
      const minute = newValue.minute();
      const cronExpression = `${minute} ${hour} * * *`;
      const formattedTime = newValue.format('hh:mm A');
      setSelectedRepeatEveryDayTime(cronExpression);
      setFormattedRepeatEveryDayTime(formattedTime);
      onSelectedRepeatPerDayTimeChange("") // Clear the selected time
      onSelectedRepeatEveryDayTimeChange(cronExpression); // Call the callback function with the selected time
    } else {
      setSelectedRepeatEveryDayTime('');
      setFormattedRepeatEveryDayTime('');
      onSelectedRepeatEveryDayTimeChange(''); // Clear the selected time
      console.error('No time selected');
    }
    setSelectedMinuteInterval(null);
    setSelectedPerDayRepeatTime(null);
    setSelectedPerDayTime("");
    onSelectedRepeatPerDayTimeChange("");
  };

  // Handlers for state updates
  const handleRepeatedTimesPerDayChange = (event:any) => {
    setSelectedTimesPerDay(event.target.value);
  };

  const handleRepeatedTimeChange = (newValue: Dayjs | null) => {
    setSelectedPerDayRepeatTime(newValue);
  };

  const handleDailyGapChange = (event:any) => {
    setDailyGap(event.target.value);
  };

  const handleGapUnitChange = (event:any) => {
    setDailyGapUnit(event.target.value);
  };

  // Function to generate cron expression based on user inputs
  const updateDailyCronExpression = () => {
    if (!selectedPerDayRepeatTime || selectedTimesPerDay === 0) return;

    // Get the starting hour and minute from the TimePicker (using Dayjs methods)
    const startHour = selectedPerDayRepeatTime.hour();
    const startMinute = selectedPerDayRepeatTime.minute();

    // Array to hold the run times for the cron expression
    const cronMinute = [];
    const cronHour = [];

    // For hourly gaps, calculate the next run hours
    if (dailyGapUnit === "hours") {
      let currentHour = startHour;
      for (let i = 0; i < selectedTimesPerDay; i++) {
        cronHour.push(currentHour); // Store the current hour
        currentHour = (currentHour + dailyGap) % 24; // Calculate the next hour and ensure it's in 24-hour format
      }
      // Create cron expression for hour-based intervals: minute hour1,hour2,... * * *
      const cronExpression = `${startMinute} ${cronHour.join(',')} * * *`;
      onSelectedRepeatEveryDayTimeChange(cronExpression);
    }

    // For minute gaps, calculate the next run minutes
    else if (dailyGapUnit === "minutes") {
      let currentMinute = startMinute;
      for (let i = 0; i < selectedTimesPerDay; i++) {
        cronMinute.push(currentMinute); // Store the current minute
        currentMinute = (currentMinute + dailyGap) % 60; // Calculate the next minute
      }
      // Create cron expression for minute-based intervals: minute1,minute2,... hour * * *
      const cronExpression = `${cronMinute.join(',')} ${startHour} * * *`;
      onSelectedRepeatEveryDayTimeChange(cronExpression);
    }
    else {
      const cronExpression = `${startMinute} ${startHour} * * *`; // If selectedTimesPerDay is 1, generate a simple cron expression
      onSelectedRepeatEveryDayTimeChange(cronExpression);
    }
  };

  // Update the cron expression whenever inputs change
  useEffect(() => {
    updateDailyCronExpression();
  // eslint-disable-next-line
  }, [selectedTimesPerDay, selectedPerDayRepeatTime, dailyGap, dailyGapUnit]);

  const handleEveryChange = (event: SelectChangeEvent<number>) => {
    const newEveryValue = event.target.value as number;
    setEveryValue(newEveryValue);
    updateCronExpression(selectedMonthTime, selectedDay, newEveryValue);
  };

  const handleDayChange = (day: number) => {
    setSelectedDay(day);
    updateCronExpression(selectedMonthTime, day, everyValue);
  };

  const handleMonthTimeChange = (newValue: any) => {
    setSelectedMonthTime(newValue);
    setSelectedMonthsTime(newValue);
    setSelectMonthTime(newValue);
    updateCronExpression(newValue, selectedDay, everyValue);
  };

  const updateCronExpression = (timeValue: Dayjs | null, day: number, monthInterval: number) => {
    if (timeValue && monthInterval > 0) {
      const hour = timeValue.hour();
      const minute = timeValue.minute();
      const cronExpression = `${minute} ${hour} ${day} */${monthInterval} *`;
      setSelectedMonthlyTime(cronExpression);
      onSelectedMonthlyTimeChange(cronExpression); // Call the callback function with the selected time
    } else {
      setSelectedMonthlyTime('');
      onSelectedMonthlyTimeChange(''); // Clear the selected time
      console.error('No time selected or invalid month interval');
      setSnackbarOpen(true);
      setSnackbarMessage('No time selected or invalid month interval');
    }
  };

  const handleWeeksChange = (event: SelectChangeEvent<number>) => {
    setSelectedWeeks(event.target.value as number);
  };

  const handleWeeklyTimeChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setValue(newValue);
      setSelectedWeekTime(newValue);
      setWeeklyTimeSelected(!!newValue); // Set weeklyTimeSelected to true when a time is selected
      const hour = newValue.hour();
      const minute = newValue.minute();
      const cronExpression = `${minute} ${hour} * * ${selectedDaysOfWeek.join(',')}`;
      setSelectedWeeklyTime(cronExpression);
      onSelectedWeeklyTimeChange(cronExpression); // Call the callback function with the selected time
    } else {
      setSelectedWeeklyTime('');
      onSelectedWeeklyTimeChange(''); // Clear the selected time
      console.error('No time selected');
    }
  };

  const tabHandleChange = (event: React.SyntheticEvent, tabValue: number) => {
    setTabValue(tabValue);
  };

  // Initialize the days array with each day having a selected state of false
  const days: Day[] = [
    { name: "Sun", isSelected: false },
    { name: "Mon", isSelected: false },
    { name: "Tue", isSelected: false },
    { name: "Wed", isSelected: false },
    { name: "Thu", isSelected: false },
    { name: "Fri", isSelected: false },
    { name: "Sat", isSelected: false },
  ];

  const formatDay = (day: number) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  // Handle button click to toggle the selected state of the clicked day
  const handleDayButtonClick = (index: number) => {
    const isSelected = selectedDaysOfWeek.includes(index);
    let updatedDaysOfWeek = [...selectedDaysOfWeek];
    if (isSelected) {
      updatedDaysOfWeek = updatedDaysOfWeek.filter(day => day !== index);
    } else {
      updatedDaysOfWeek.push(index);
    }
    setSelectedDaysOfWeek(updatedDaysOfWeek);
    updateWeeklyCronExpression(value, updatedDaysOfWeek); // Update cron expression with selected time and updated days
  };

  const updateWeeklyCronExpression = (timeValue: Dayjs | null, daysOfWeek: number[]) => {
    if (timeValue) {
      const hour = timeValue.hour();
      const minute = timeValue.minute();
      const cronExpression = `${minute} ${hour} * * ${daysOfWeek.join(',')}`;
      onSelectedWeeklyTimeChange(cronExpression); // Call the callback function with the updated cron expression
    } else {
      onSelectedWeeklyTimeChange(''); // Clear the selected time
    }
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          indicatorColor="secondary"
          textColor="secondary"
          variant="standard"
          value={tabValue}
          onChange={tabHandleChange}
          aria-label="Setup sequence reoccurrence dates and times"
        >
          <Tab label="Hourly" {...a11yProps(0)} />
          <Tab label="Daily" {...a11yProps(1)} />
          <Tab label="Weekly" {...a11yProps(2)} />
          <Tab label="Monthly" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <div className="hourlyList">
          <Typography>Every</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-minute"
              value={minute}
              label="Minute"
              variant="standard"
              color="secondary"
              onChange={handleMinuteChange}
              sx={{ mx: 1 }}
            >
              {[...Array(60)].map((_, index) => (
                <MenuItem key={index} value={index}>{index}</MenuItem>
              ))}
            </Select> 
          </LocalizationProvider>
          <Typography>minutes past the hour, starting</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
              sx={{ width: "160px", mx: 1 }}
              label="Select an hour"
              value={selectedHour}
              onChange={handleHourChange}
              views={['hours']} // Limit the views to only hours
              slotProps={{textField: { size: "small", color: "secondary" },}}
            />
          </LocalizationProvider>
        </div>
        { minute && selectedHour && (
        <Typography sx={{ fontSize: 13 }}>
           {`The Sequence will run at ${minute} minutes past every ${selectedHour.format('hh:mm A')}.`}
        </Typography>
      )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <div className="dailyList">
          <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 2 }}>
            Select and setup reoccurrence pattern:
          </Typography>
          <FormControl sx={{ ml: 1 }}>
            <RadioGroup name="radio-buttons-group" value={selectedValue} onChange={handleChange}>
              <div className="repeatDay">
                <FormControlLabel
                  value="RepeatEveryDay"
                  control={<Radio color="secondary" />}
                  label="Repeat every day at "
                />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ width: "160px", mx: 1 }}
                      label="Select a time"
                      value={selectedEveryDayRepeatTime}
                      onChange={handleRepeatEveryDayTimeChange}
                      slotProps={{ textField: { size: "small", color: "secondary" } }}
                    />
                  </LocalizationProvider>
              </div>
              <div className="repeatTime">
                <FormControlLabel
                  value="Repeat"
                  control={<Radio color="secondary" />}
                  label="Repeat"
                />
                <div className="customSelect">
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedTimesPerDay}
                    label="Times per day"
                    variant="standard"
                    color="secondary"
                    onChange={handleRepeatedTimesPerDayChange}
                    sx={{ mr: 1 }}
                  >
                    {[1, 2, 3].map((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </div>
                <Typography>times a day, starting at </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ width: "200px", mx: 1 }}
                      label="Select start time"
                      value={selectedPerDayRepeatTime}
                      onChange={handleRepeatedTimeChange}
                      slotProps={{textField: { size: "small", color: "secondary" },}}
                    />
                  </LocalizationProvider>
                  {selectedTimesPerDay > 1 && (
                <>
                  <Typography>with a </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-gap"
                    value={dailyGap}
                    label="Gap"
                    variant="standard"
                    color="secondary"
                    onChange={handleDailyGapChange}
                    sx={{ mx: 1 }}
                  >
                    {[...Array(60)].map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                  </LocalizationProvider>
                  <div className="customSelect">
                  <Select
                    labelId="gap-unit-label"
                    id="gap-unit-select"
                    value={dailyGapUnit}
                    label="Gap Unit"
                    variant="standard"
                    color="secondary"
                    onChange={handleGapUnitChange}
                    sx={{ mx: 1 }}
                  >
                    <MenuItem value="minutes">Minutes</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                  </Select>
                  </div>
                  <Typography>gap. </Typography>
                </>
                )}
              </div>
            </RadioGroup>
          </FormControl>
          {selectedValue === "RepeatEveryDay" && repeatEveryDayTimeSelected && (
            <Typography sx={{ fontSize: 13, mt: 1 }}>
              The Sequence will run every day at {formattedRepeatEveryDayTime}.
            </Typography>
          )}

          {selectedValue === "Repeat" && selectedTimesPerDay && selectedPerDayRepeatTime && (
            <Typography sx={{ fontSize: 13, mt: 1 }}>
              {selectedTimesPerDay === 1 ? (
                `The Sequence will run only ${selectedTimesPerDay} time, at ${selectedPerDayRepeatTime?.format('hh:mm A')} without any gap.`
              ) : (
                `The Sequence will run ${selectedTimesPerDay} times a day, starting at ${selectedPerDayRepeatTime?.format('hh:mm A')} with a ${dailyGap} ${dailyGapUnit} gap.`
              )}
            </Typography>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <div className="weeklyList">
          <div className="weeklyListItem">
            <Typography>Every</Typography>
            <div className="customSelect">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Time"
                variant="standard"
                color="secondary"
                value={selectedWeeks}
                onChange={handleWeeksChange}
                sx={{ mx: 1 }}
              >
              {[1, 2, 3].map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))}
              </Select>
            </div>
            <Typography>weeks, on</Typography>
            <Box className="buttonGroup" sx={{ mx: 1 }} gap={2}>
              {days.map((day, index) => (
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => handleDayButtonClick(index)}
                  color={selectedDaysOfWeek.includes(index) ? "secondary" : "inherit"}
                >
                  {day.name}
                </Button>
              ))}
            </Box>
            <Typography>at</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={selectedWeekTime}
                onChange={handleWeeklyTimeChange}
                sx={{ width: "160px", mx: 1 }}
                label="Select a time"
                slotProps={{textField: { size: "small", color: "secondary" },}}
              />
            </LocalizationProvider>
          </div>
        {selectedWeeks && selectedDaysOfWeek.length > 0 && weeklyTimeSelected && (
          <Typography sx={{ fontSize: 13, mt: 1 }}>
            The Sequence will run every {selectedWeeks} weeks on {selectedDaysOfWeek.map((day) => days[day].name).join(', ')} at {value?.format('hh:mm A')}.
          </Typography>
        )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <div className="monthlyList">
          <Grid container >
            <Grid item xs={4}>
              <div className="monthlyListItem">
                <Typography>Every</Typography>
                <div className="customSelect">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={everyValue}
                    onChange={handleEveryChange}
                    label="Time"
                    variant="standard"
                    color="secondary"
                    sx={{ mx: 1 }}
                  >
                    {[...Array(12)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <Typography>months, on the:</Typography>
              </div>
              <div className="monthlyListItem">
                <Typography>at</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    sx={{ width: "160px", mx: 1 }}
                    label="Select a time"
                    value={selectedMonthsTime}
                    onChange={handleMonthTimeChange}
                    slotProps={{textField: { size: "small", color: "secondary" },}}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate} // Bind value to state
                  onChange={handleDateChange}
                  views={['day']}
                  slotProps={{
                    calendarHeader: { format: 'MM' },
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#9c27b0", // Custom selected day color
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {everyValue && selectMonthDate && selectMonthTime && (
          <Typography sx={{ fontSize: 13, mt: 1 }}>
            The Sequence will run every {everyValue} months on the {formatDay(selectedDay)} at {selectedMonthTime?.format('hh:mm A')}.
          </Typography>
        )}
        </div>
      </CustomTabPanel>
      <Snackbar open={snackbarOpen} autoHideDuration={4000}  onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FrequencyTabs;
