import { useState } from 'react'
import PerformanceDashboardFilter from './PerformanceDashboardFilter/PerformanceDashboardFilter'
import PerformanceDashboardOverallScore from './PerformanceDashboardOverallScore/PerformanceDashboardOverallScore'
import PerformanceDashboardRatedTable from './PerformanceDashboardRatedTable/PerformanceDashboardRatedTable'
import PerformanceDashboardRealData from './PerformanceDashboardRealData/PerformanceDashboardRealData'
import './PerformanceDashboard.css';
import { Divider } from '@mui/material'
import SimpleBackdrop from '../../../../BackDrop/BackDrop'

const PerformanceDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <PerformanceDashboardFilter/>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <>
          <Divider/>
          <PerformanceDashboardOverallScore/>
          <PerformanceDashboardRatedTable/>
          <PerformanceDashboardRealData/>
        </>
      ) }
    </>
  )
}

export default PerformanceDashboard
