import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Paper, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

interface FileDetails {
  name: string;
  size: number;
  base64: string;
  progress: number;
  status:string;
}

interface Props {
  setFileBase64: any;
  setFileName: any;
  fileName: any;
  isCardVisible: any;
  setIsCardVisible: any;
  fileBase64:any;
}

function ManualExpenseDocs({ setFileBase64, setFileName, setIsCardVisible, fileBase64 }: Props) {
  const [uploadError, setUploadError] = useState('');
  const [isDragging, setIsDragging] = useState(false); // New state to track dragging

  useEffect(() => {
    const timers: any[] = [];

    fileBase64 && fileBase64.forEach((file: any, index: number) => {
      if (file.progress < 100) {
        const timer = setInterval(() => {
          setFileBase64((prevFiles: any) => {
            const newFiles = [...prevFiles];
            newFiles[index].progress = Math.min(file.progress + Math.random() * 10, 100);

            if (newFiles[index].progress === 100) {
              newFiles[index].status = 'Uploaded'; // Change status to Uploaded
            }
            return newFiles;
          });
        }, 50);
        timers.push(timer);
      }
    });

    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, [fileBase64, setFileBase64]);

  const handleFileChange = (event: any) => {
    const selectedFiles = Array.from(event.target.files);
    processFiles(selectedFiles);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(false); // Reset drag state
    const droppedFiles = Array.from(event.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const processFiles = (selectedFiles: any[]) => {
    selectedFiles.forEach((file: any) => {
      const fileSizeMB = file.size / (1024 * 1024);
      const fileType = file.type;
  
      // Check file size limit
      if (fileSizeMB > 5) {
        setUploadError('File size exceeds the 5MB limit.');
        return;
      }
  
      // Check allowed file types
      if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'application/pdf') {
        setUploadError('Only PNG, JPG, or PDF files are allowed.');
        return;
      }
  
      setUploadError(''); // Clear any previous error
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1]; // Extract base64 part
        const newFile: FileDetails = {
          name: file.name,
          size: file.size,
          base64: base64String || '',
          progress: 0,
          status: 'Uploading', // Add status here
        };
        setFileBase64((prevFiles: any) => [...prevFiles, newFile]);
        setIsCardVisible(true);
      };
      reader.readAsDataURL(file);
    });
  };
  

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true); // Set dragging state to true
  };

  const handleDragLeave = () => {
    setIsDragging(false); // Reset dragging state when leaving the drop area
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = fileBase64.filter((_: any, i: number) => i !== index);
    setFileBase64(updatedFiles);
    if (updatedFiles.length === 0) {
      setIsCardVisible(false);
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
      <Card>
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "8px",
            paddingBlock: "24px",
            backgroundColor: isDragging ? "#9C27B01A" : "#9C27B00A", // Change background on drag
            border: isDragging ? "2px dashed #9C27B0" : "none", // Add dashed border on drag
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <UploadFileIcon color="secondary" />
          <Typography
            sx={{ display: "flex", gap: "4px" }}
            component="label"
            role={undefined}
            tabIndex={-1}
          >
            <Typography color="secondary" sx={{ textDecoration: "underline", cursor: "pointer" }}>Click to upload</Typography> or drag and drop
            <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
          </Typography>
          <Typography sx={{ color: '#00000099' }}>PNG, JPG or PDF (max. 5MB)</Typography>
          {uploadError && <Typography color="error">{uploadError}</Typography>}
        </Paper>
      </Card>

      {fileBase64 && fileBase64.map((file: any, index: number) => (
        <Card key={index} sx={{ padding: "8px 16px", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
          <div>
            <Typography sx={{ fontWeight: "500" }}>{file.name}</Typography>
            <Typography sx={{ paddingBottom: "4px" }}>{(file.size / 1024).toFixed(2)} KB • {file.status}</Typography>
            <LinearProgress color="secondary" variant="determinate" value={file.progress} />
          </div>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleRemoveFile(index)} />
        </Card>
      ))}
    </div>
  );
}

export default ManualExpenseDocs;
