import { Paper, Typography } from "@mui/material";
import React from "react";
import TerritoryFilterCard from "./TerritoryFilter/TerritoryFilterCard";
import TerritoryTable from "./TerritoryTable/TerritoryTable";

const tableHeadings = {
  territory_name: "Territory Name",
  language: "Language",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    territory_name: "MX",
    language: "en - US",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    territory_name: "RO",
    language: "ro - RO",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    territory_name: "UK",
    language: "en - GB",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const Territory = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Territories List</Typography>
        <TerritoryFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <TerritoryTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default Territory;
