import "./PageHeadlineDetails.css";
import { WebsiteWithUuid } from "../../../../data/website/Website";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Tooltip, DialogActions, TextField, Box, Typography, Snackbar, Autocomplete, InputAdornment,IconButton, Grid} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import SimpleBackdrop from "../../../BackDrop/BackDrop";
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { createPageEndPointLogic } from "../../../../logic/AssetManagement/PageManagement/CreatePage/createPageEndPointLogic";
import { selectUserData, setDisplayErrorSnackBar, setIsLoading } from "../../../../features/userSlice";
import { trackPromise } from "react-promise-tracker";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import store from "../../../../store";
import { useSelector } from "react-redux";
import { WebsitePermissions } from "../../../../data/user/WebsitePermissions";
import { pageBuildingEndPointLogic } from "../../../../logic/AssetManagement/PageManagement/PageBuilding/pageBuildingEndPointLogic";
import { sitePageEndPointLogic } from "../../../../logic/AssetManagement/PageManagement/SitePages/sitePagesEndPointLogic";
import { pageBuildingHelpers } from "../../../../pages/AssetManagement/PageManagement/PageBuilding/logic/PageBuildingHelpers";

export interface PageHeadlineDetailsProps {
  pageUid: string;
  pageName: string | undefined;
  siteName: string | undefined;
  publishPage: any;
  savePage:any;
  selectedSite: WebsiteWithUuid | null;
  previewLink:string | undefined;
  pageContent : any;
  previewCurrentChange:any;
  siteUid:string;
  oauthTokenHeader:any;
  discardPage:any;
  isPublishOpen:any;
  isElementSaved:any;
  pageContentPageId: any;
  ids:any;
  hasEditPermissions:any;
  setIsYesBtnClicked:any;
  hasPermissions:any;
  isYesBtnClicked:any;
  setPageContent:any;
  setPageStructureDesign:any;
  setPageDictionary:any;
  setPageUrlContent:any;
  setSiteAllAvailableWidgets:any;
  siteAllAvailableWidgets:any;
  setPageRefreshStatus:any;
  currentpageElements:any;
  currentpageElementsOriginal:any;
  setSaveTrigger:any;
  setPreview:any;
  setSuccessMessage:any;
}

export default function PageHeadlineDetails({
  pageUid,
  pageName,
  siteName,
  publishPage,
  savePage,
  selectedSite,
  previewLink ,
  pageContent,
  setPageContent,
  previewCurrentChange,
  siteUid,
  oauthTokenHeader,
  discardPage,
  isPublishOpen,
  isElementSaved,
  pageContentPageId,
  ids,
  hasEditPermissions,
  setIsYesBtnClicked,
  hasPermissions,
  isYesBtnClicked,
  setPageStructureDesign,
  setPageDictionary,
  setPageUrlContent,
  setSiteAllAvailableWidgets,
  siteAllAvailableWidgets,
  setPageRefreshStatus,
  currentpageElements,
  currentpageElementsOriginal,
  setSaveTrigger,
  setPreview,
  setSuccessMessage
}: PageHeadlineDetailsProps) {

const postStatus1 = pageContent?.post_status;
const navigate = useNavigate();

  let siteURL;
  if (selectedSite) {
    siteURL = Object.values(selectedSite)[0].site_url;
  }
  
  const previewCurrentChangesLink = previewLink ? previewLink : "";
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [iframeWidth, setIframeWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const iframeHeight = ((iframeWidth /16)*9);
  const [duplicatePageResponse,setDuplicatePageResponse] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editDuplicatePageConfirmationOpen, setEditDuplicatePageConfirmationOpen] = useState(false);
  const [discardOpen, setdiscardOpen] = useState(false);
  const postTitle = pageContent?.post_title;
  const postStatus = pageContent?.post_status;
  const [pageTitle, setPageTitle] = useState<string>();
  const [openDuplicateDialogBox, setOpenDuplicateDialogBox] = useState(false);
  const userWebsitePermissions = useSelector(selectUserData)?.data?.website_permissions as WebsitePermissions;
  const duplicatePageShareUid = Object.values(duplicatePageResponse)[0];
  const duplicatePagePreviewLink = Object.values(duplicatePageResponse)[1];
  const [showDuplicateErrorDialog, setShowDuplicateErrorDialog] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isDuplicateSuccessful, setIsDuplicateSuccessful] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedPageTitle, setSelectedPageTitle] = useState<string>(pageContent?.post_title || '');
  const [selectedPageStatus, setSelectedPageStatus] = useState<string>(pageContent?.post_status || '');
  const duplicateMessage: string = "Page Duplicated Succesfully";
  const [, setErrorAccToPageStatus] = useState(false)
  const [metaDataStatus, setMetaDataStatus] = useState(true)
  const [, setRetry] = useState(false);
 

  const pageStatusData = ["publish","draft","pending"]

  const [originalPageMeta, setOriginalPageMeta] = useState({
    post_title: pageContent?.post_title,
    post_status: pageContent?.post_status,
  });

  useEffect(() => {
    if (pageContent?.post_status) {
      setSelectedPageStatus(pageContent.post_status);
    }
  }, [pageContent?.post_status]);

 const data = {
  "data": {
    "post_title":`${pageTitle}`
}
}
  const handleClickOpen = async () => {
    setLoading(true);
     setOpen(true);
    await previewCurrentChange();
    setLoading(false);
  };
  
  const handleClose = () => {
    setOpen(false);
    setSelectedVersion('');
    setIframeWidth(0)
    setOpenDialog(false)
  };

  const handleCloseDuplicateDialogBox = () => {
    setOpenDuplicateDialogBox(false)
  }

  const handleVersionChange = (event : any) => {
    const value = event.target.value;
    setSelectedVersion(value);
  };

  const handleDeviceChange = (event : any) => {
    const value = event.target.value;
    setLoading(true);
    if (value === '1440px') {
      setIframeWidth(1440);
    } else if (value === '768px') {
      setIframeWidth(768);
    } else if (value === '360px') {
      setIframeWidth(414);
    } else {
      setIframeWidth(0);
    }
    setOpen(false)
    setOpenDialog(true);
  }

  const validate = async () => {
    if (!pageTitle) {
      console.error("Missing Mandatory Fields");
    } else {
      setOpenDuplicateDialogBox(false); // Close the duplicate page dialog
      try {
        setIsLoading(true);
        await duplicatePages();
        setSnackbarMessage(duplicateMessage ? duplicateMessage : "Page duplicated successfully");
        setShowSuccessPopup(true);
        setIsDuplicateSuccessful(true); // Set state to indicate successful duplication
      } catch (error) {
        setShowDuplicateErrorDialog(true); // Show error dialog for duplicate page title
      } finally {
        setPageRefreshStatus(true);
        store.dispatch(setIsLoading(false));
      }
    }
  };
  const site = useMemo(() => {
    return (userWebsitePermissions.find((website) => Object.values(website)[0].site_name === siteName) as WebsiteWithUuid ?? null);
    // eslint-disable-next-line
}, [siteName]);

useEffect(() => {
  try {
      store.dispatch(setIsLoading(true));
      if (isYesBtnClicked && hasPermissions) {
          store.dispatch(setIsLoading(true));
              pageBuildingEndPointLogic.getPageUrlContent(duplicatePagePreviewLink, setPageUrlContent,
                  setIsLoading, trackPromise);
          pageBuildingEndPointLogic.getSiteAllAvailableWidgets(Object.keys(site)[0], oauthTokenHeader,
              setSiteAllAvailableWidgets,setErrorAccToPageStatus,setRetry,setOpen, trackPromise, setIsLoading) as any;
              store.dispatch(setIsLoading(false));

      } else {
          // pageBuildingEndPointLogic.navigateTo404(navigate);
          store.dispatch(setIsLoading(false));

      }
  } catch (e) {
      store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
      store.dispatch(setIsLoading(false));
      navigate(`/`);
  }
  // eslint-disable-next-line
}, [ isYesBtnClicked===true]);

  useEffect(() => {
    try {
        if (siteAllAvailableWidgets && isYesBtnClicked===true) {
            store.dispatch(setIsLoading(true));
            pageBuildingEndPointLogic.getPageContent((Object.keys(site))[0], duplicatePageShareUid, oauthTokenHeader, setPageContent, setPageStructureDesign,
                setPageDictionary, setIsLoading,setErrorAccToPageStatus,setRetry,setOpen, trackPromise) as any;
        }
        store.dispatch(setIsLoading(false));
    } catch (e) {
        store.dispatch(setDisplayErrorSnackBar({ state: true, message: e, type: 'error' }));
        store.dispatch(setIsLoading(false));
        navigate("/");
    }
    // eslint-disable-next-line
}, [siteAllAvailableWidgets]) ;

const customEncodeURI = (str:any) => {
  return str
    .split(' ') // Split by spaces
    .join('-')  // Join with hyphens
    .replace(/[^a-zA-Z0-9-]/g, encodeURIComponent); // Encode other special characters
};

  //handling the duplicate dialouge box

  const handleDuplicateConfirmation = () => {
    setConfirmationOpen(true);
  };
  
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setShowSuccessPopup(false); // Reset showSuccessPopup to false
  };
  
  const handleDuplicateConfirmationYes = () => {
    setOpenDuplicateDialogBox(true)
    setConfirmationOpen(false);
    setPageTitle(`${postTitle}-copy`);
  };
  
  const handleDuplicateConfirmationNo = () => {
    setConfirmationOpen(false);
  };
////handling the Editduplicate dialouge box

  const handleEditDuplicatePageConfirmation = () => {
    setEditDuplicatePageConfirmationOpen(true);
  };
  
  const handleEditDuplicatePageConfirmationClose = () => {
    setEditDuplicatePageConfirmationOpen(false);
  };
  
  const handleEditDuplicatePageConfirmationYes = () => {
    setIsYesBtnClicked(true);
    store.dispatch(setIsLoading(false)); 
    setEditDuplicatePageConfirmationOpen(false);
    const encodedPageTitle = customEncodeURI(pageTitle);
  
    navigate(`/assets/page_management/${siteName}/${encodedPageTitle}/edit`, {
      state: {
        pageUid: `${duplicatePageShareUid}`,
        pageTitle: `${pageContent?.post_title}`,
        postStatus: `${pageContent?.post_status}`,
        previewLink: `${pageContent?.preview_link}`,
      },
    });
};

  const handleEditDuplicatePageConfirmationNo = () => {
    setPageTitle("")
    setEditDuplicatePageConfirmationOpen(false);
  };

  const duplicatePages = async () => {
    try {
      await createPageEndPointLogic.duplicatePage(siteUid,data,setIsLoading,trackPromise,oauthTokenHeader,setDuplicatePageResponse,pageUid, setShowDuplicateErrorDialog);
      handleEditDuplicatePageConfirmation();
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  }

  const handleRetryDuplicate = () => {
    setShowDuplicateErrorDialog(false); // Close the error dialog
    setOpenDuplicateDialogBox(true); // Open the duplicate page dialog again
    setShowSuccessPopup(false); // Reset the success message state
    setIsDuplicateSuccessful(false); // Reset the successful duplication state
  };

  const handleCancelDuplicateErrorDialog = () => {
    setShowDuplicateErrorDialog(false); // Close Duplicate Error Dialog
    if (editDuplicatePageConfirmationOpen) {
      setEditDuplicatePageConfirmationOpen(false); // Close Confirmation of Editing Dialog
    }
    setShowSuccessPopup(false); // Close Snackbar
  };

  const handleDiscardConfirmation = () => {
    setdiscardOpen(true);
  };
  
  const handlediscardConfirmationClose = () => {
    setdiscardOpen(false);
  };
  
  const handleDiscardConfirmationYes = () => {
    if (pageContent?.unpublished_change ? () => discardPage() : undefined) {
      discardPage();
    }
    setSaveTrigger((prev:any) => !prev)
    setdiscardOpen(false);
  };
  
  const handleDiscardConfirmationNo = () => {
    setdiscardOpen(false);
  };

 // Handle snackbar close
 const handleSnackbarClose = () => {
  setShowSuccessPopup(false);
  // Optionally clear snackbar message state
};

// Function to render the snackbar for delete
const renderSnackbar = () => {
  if (showDuplicateErrorDialog || !isDuplicateSuccessful) {
    return null; // Do not render the snackbar if duplicate error dialog is open or duplication was not successful
  }
  
  return (
    <Snackbar
    open={showSuccessPopup && isDuplicateSuccessful}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleSnackbarClose} severity="success" variant="filled">
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

const [isEditable, setIsEditable] = useState(false);
const [isEditing, setIsEditing] = useState(false);


const toggleEditMode = () => {
  setIsEditing(prev => {
    const newEditingState = !prev;
    setIsEditable(newEditingState);
    setMetaDataStatus((prevStatus) => !prevStatus);
    if (newEditingState) {
      setOriginalPageMeta({
        post_title: pageContent?.post_title,
        post_status: pageContent?.post_status,
      });
      setSelectedPageTitle(pageContent?.post_title || '');
      setSelectedPageStatus(pageContent?.post_status || '');
    }
    return newEditingState;
  });
};

  const pageContentJson = pageBuildingHelpers.parseRelevantElementsToJson(currentpageElements, currentpageElementsOriginal);

  const updatedObject = {
    "data": {
          "post_content":pageContentJson,
          "post_title":selectedPageTitle,
          "post_status":selectedPageStatus
        }
}

const updatedTitleObject = {
  "data": {
      "post_title":selectedPageTitle
  }
}


  useEffect(() => {
    setSelectedPageTitle(pageContent?.post_title || '');
  }, [pageContent]);

  const handleSaveChanges = async () => {
    setIsEditing(prev => {
      const newEditingState = !prev;
      setIsEditable(newEditingState);
      setMetaDataStatus((prevStatus) => !prevStatus);
      return newEditingState;
    });
    try {
      // First API call to update the page title metadata
        await sitePageEndPointLogic.updatePageMeta(updatedObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setPageRefreshStatus,setPreview);
      // Only call deletePage API if pageStatus is 'bin'
      if(selectedPageStatus === "publish" || selectedPageStatus==="draft") {
        await sitePageEndPointLogic.discardPage(siteUid,pageUid,setIsLoading,oauthTokenHeader,data);
        await sitePageEndPointLogic.updatePageMeta(updatedTitleObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setPageRefreshStatus,setPreview);
      }
      if (selectedPageStatus === "pending") {
        await sitePageEndPointLogic.updatePageMeta(updatedTitleObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setPageRefreshStatus,setPreview);
      }
      await sitePageEndPointLogic.updatePageMeta(updatedObject, siteUid, pageUid, setIsLoading, oauthTokenHeader, setPageRefreshStatus,setPreview);
       store.dispatch(setIsLoading(false))
      // Optionally, you can handle success here (e.g., show a success message)
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Failed to update page meta:", error);
    } 
    setSaveTrigger((prev:any) => !prev);// Toggles the state to ensure useEffect runs
    setSuccessMessage("Page Meta updated Successfully")
  };
  const handleCancel = () => {
    setSelectedPageTitle(originalPageMeta.post_title || '');
    setSelectedPageStatus(originalPageMeta.post_status || '');
    toggleEditMode();
  };

  return (
    <>
    {isDuplicateSuccessful && renderSnackbar()}
      <div className="PageHeadlineDetails5">
        <div className="paper32">
          <div className="PageHeadlineDetails-elements23">
            <div className="PageHeadlineDetailsmedia23">
              <img className="library-placeholder-image23" alt="" src="/-library--placeholder-image.svg" />
            </div>
            <div className="PageHeadlineDetailsheader-parent4">
              <div className="PageHeadlineDetailsheader34">
                <div className="content55">
                  <p className="header43">PAGE BUILDER</p>
                  <div className="subheader47">{`{Subheader}`}</div>
                </div>
                <div className="iconbutton271">
                  <img className="icon577" alt="" src="/icon2.svg" />
                </div>
              </div>
              <div className="PageHeadlineDetailsactions42">
                <div className="button86">
                  <div className="base28">
                  <Tooltip title="Discard" placement="top">
                    <IconButton disabled={(pageContent?.unpublished_change === false) || isEditing}>
                  <HistoryIcon 
                color={(pageContent?.unpublished_change && !isEditing) ? "secondary" : "disabled"} 
                sx={{cursor: pageContent?.unpublished_change ? "pointer" : "not-allowed"}}
                onClick={handleDiscardConfirmation}
                />
                </IconButton>
                  </Tooltip>
                  <Dialog open={discardOpen} onClose={handlediscardConfirmationClose}>
                      <DialogTitle>Confirmation of discard</DialogTitle>
                      <DialogContent>
                        Are you sure you want to discard this change ?
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDiscardConfirmationNo} color="secondary">No</Button>
                        <Button onClick={handleDiscardConfirmationYes}color="secondary" variant="contained">Yes</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
                <div className="button86">
                  <div className="base28">
                    <Tooltip title="Duplicate" placement="top">
                      {(postStatus1 === "pending") ? 
                      <IconButton disabled={isEditing}>
                      <ContentCopyIcon
                      sx={{ cursor: "pointer" }}
                      /></IconButton> :
                      <IconButton disabled={isEditing}>
                      <ContentCopyIcon
                      color={isEditing ? "disabled":"secondary"}
                        sx={{ cursor: "pointer" }}
                        onClick={handleDuplicateConfirmation}
                      />
                      </IconButton>
                      }
                    </Tooltip>
                    <Dialog
                      open={confirmationOpen}
                      onClose={handleConfirmationClose}
                    >
                      <DialogTitle>Confirmation of duplicate</DialogTitle>
                      <DialogContent>
                        Are you sure you want to duplicate this page?
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleDuplicateConfirmationNo}
                          color="secondary"
                        >
                          No
                        </Button>
                        <Button
                          onClick={handleDuplicateConfirmationYes}
                          color="secondary"
                          variant="contained"
                        >
                          Yes
                        </Button>
                       </DialogActions>
                    </Dialog>
                    <Dialog
                      open={editDuplicatePageConfirmationOpen && isDuplicateSuccessful && !showDuplicateErrorDialog}
                      onClose={handleEditDuplicatePageConfirmationClose}
                    >
                      <DialogTitle>Confirmation of Editing</DialogTitle>
                      <DialogContent>
                        Do you want to edit the duplicate page?
                      </DialogContent>
                      <DialogActions>
                        <Link
                          to={`/assets/page_management/${siteName}/${pageName}/edit`}
                          state={{
                            pageUid: pageUid,
                            pageTitle: postTitle,
                            postStatus: postStatus,
                            previewLink: previewLink,
                          }}
                        >
                          <Button
                            onClick={handleEditDuplicatePageConfirmationNo}
                            color="secondary"
                          >
                            No
                          </Button>
                        </Link>
                        {hasEditPermissions &&

                        <Button
                          onClick={handleEditDuplicatePageConfirmationYes}
                          color="secondary"
                          variant="contained"
                        >
                          Yes
                        </Button>
                        }
                       </DialogActions>
                    </Dialog>
                  </div>
                </div>

      
        <Dialog
          open={openDuplicateDialogBox}
          fullWidth
          maxWidth="md"
        >
         <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
            <DialogTitle sx={{padding:"0px 0px 16px 0px"}}>Duplicate Page</DialogTitle>
            {/* {errorValidate ? <Alert severity="warning" sx={{marginBottom:"10px"}} >{errorValidate}</Alert> : "" } */}
            <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  required
                  fullWidth
                  id="pageTitle"
                  label="Page Title"
                  value={pageTitle}
                  onChange={(e) => setPageTitle(e.target.value)}
                />
              </div>
            </div>
            <Box
              component="div"
              sx={{
                p: 1,
                borderRadius: 1,
                backgroundColor: '#F7EEF9',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon style={{ marginRight: '8px' }} color="secondary" />
                <Typography variant="h6" color="#9C27B0">PLEASE NOTE:</Typography>
              </div>
              <Typography color="#9C27B0" sx={{ paddingLeft: 5 }}>
                All pages are duplicated with either a "Publish" or "Draft" status.
              </Typography>
            </Box>
          </div>
          <DialogActions>
            <Button onClick={handleCloseDuplicateDialogBox} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => validate()}
              variant="contained"
              color="secondary"
              endIcon={<AutoFixHighIcon />}
            >
              Duplicate
            </Button>
          </DialogActions>
        </Dialog>
      {/* Duplicate Error Dialog */}
      <Dialog
        open={showDuplicateErrorDialog}
        onClose={() => setShowDuplicateErrorDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Duplicate Page Title</DialogTitle>
        <DialogContent>
          <Typography>
            A page with this title already exists or there was an error while duplicating the page. Do you want to retry?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDuplicateErrorDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRetryDuplicate} color="secondary" variant="contained">
            Retry
          </Button>
        </DialogActions>
      </Dialog>

                
                <div className="button86">
                  <div className="base28">
                  <Tooltip title="Preview" placement="top">
                  <IconButton disabled={isEditing}>
                    <PreviewIcon onClick={handleClickOpen} sx={{cursor:"pointer"}} color={isEditing ? "disabled": "secondary"} />
                    </IconButton>
                  </Tooltip>
                    {
                          (loading && (!previewCurrentChangesLink || previewCurrentChangesLink === "" || previewCurrentChangesLink===undefined)) ? (
                            <div>
                              <SimpleBackdrop />
                            </div>
                          ) : (
                            (pageContent?.theme_v2 !== null) ? (
                              <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                  Page Preview
                                </DialogTitle>
                                <div style={{ padding: "10px" }}>
                                  <FormControl component="fieldset" disabled={pageContent?.theme_v2 === null}>
                                    <FormLabel component="legend">Version to Show</FormLabel>
                                    <RadioGroup row value={selectedVersion} onChange={handleVersionChange} style={{ margin: "15px" }}>
                                      <FormControlLabel value="Version 1" disabled={pageContent?.theme_v2 === null} control={<Radio color="secondary" />} label="Version 1" />
                                      <FormControlLabel value="Version 2" disabled={pageContent?.theme_v2 === null} control={<Radio color="secondary" />} label="Version 2" />
                                    </RadioGroup>
                                  </FormControl>
                                  {selectedVersion && (
                                    <FormControl component="fieldset">
                                      <FormLabel component="legend">Additional Options</FormLabel>
                                      <RadioGroup row value={selectedVersion} onChange={handleDeviceChange}>
                                        <div style={{ margin: "15px" }}>
                                          <FormControlLabel value="1440px" control={<Radio color="secondary" />} label="Desktop (1440px)" />
                                          <FormControlLabel value="768px" control={<Radio color="secondary" />} label="Tablet (768px)" />
                                          <FormControlLabel value="360px" control={<Radio color="secondary" />} label="Mobile (360px)" />
                                        </div>
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                  <div style={{ display: "flex", justifyContent: "end", gap: "5px" }}>
                                    <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                                  </div>
                                </div>
                              </Dialog>
                            ) : (
                              <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                  Page Preview
                                </DialogTitle>
                                <div style={{ padding: "10px" }}>
                                  <FormControl component="fieldset" disabled={pageContent?.theme_v2 === null}>
                                    <FormLabel component="legend">Version to Show</FormLabel>
                                    <RadioGroup row value={selectedVersion} onChange={handleVersionChange} style={{ margin: "15px" }}>
                                      <FormControlLabel value="Version 1" disabled={pageContent?.theme_v2 === null} control={<Radio color="secondary" />} label="Version 1" />
                                      <FormControlLabel value="Version 2" disabled={pageContent?.theme_v2 === null} control={<Radio color="secondary" />} label="Version 2" />
                                    </RadioGroup>
                                    <DialogContent>This option is only available when an A/B Test is running.</DialogContent>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">Additional Options</FormLabel>
                                    <RadioGroup row value={selectedVersion} onChange={handleDeviceChange}>
                                      <div style={{ margin: "15px" }}>
                                        <FormControlLabel value="1440px" control={<Radio color="secondary" />} label="Desktop (1440px)" />
                                        <FormControlLabel value="768px" control={<Radio color="secondary" />} label="Tablet (768px)" />
                                        <FormControlLabel value="360px" control={<Radio color="secondary" />} label="Mobile (360px)" />
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                  <div style={{ display: "flex", justifyContent: "end", gap: "5px" }}>
                                    <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                                  </div>
                                </div>
                              </Dialog>
                            )
                          )
                        }
                  </div>
                </div>
                <div className="button86">
                  <div className="base28">
                    <Tooltip title="Save" placement="top">
                      {(postStatus1 === "draft" || postStatus1 === "publish" || postStatus1 === "pending") &&
                      isElementSaved === true ? (
                        <IconButton disabled={isEditing}>
                        <SaveIcon
                          onClick={() => savePage()}
                          color={isEditing?"disabled":"secondary"}
                          sx={{ cursor: "pointer" }}
                        />
                        </IconButton>
                      ) : (
                        <SaveIcon sx={{ cursor: "pointer" }} />
                      )}
                    </Tooltip>
                  </div>
                </div>
                {postStatus1 === 'publish' ? (
                <Button disabled={(!isPublishOpen && !ids.includes(pageContentPageId)) || isEditing} sx={{display:"flex", alignItems:"center"}} size="small" variant="contained"  color="secondary" onClick={() => publishPage()} >PUBLISH <PublishIcon sx={{width:"18px", marginLeft:"8px", marginTop: "-3px"}} /></Button>
                ) : (
                <Button disabled={(isElementSaved && !isPublishOpen) || isEditing} sx={{display:"flex", alignItems:"center"}} size="small" variant="contained" color="secondary" onClick={() => publishPage()} >PUBLISH <PublishIcon sx={{width:"18px", marginLeft:"8px", marginTop: "-3px"}} /></Button>

                )}
                { postStatus1 === "publish" ?
                  <Link
                 to={`${siteURL}?page_id=${pageContent?.page_id}&nowprocket`}
                 target="_blank"
                 style={{
                 textDecoration: "underline",
                color: "#9C27B0",
                 }}
                >
                 {
                   <Button
                   sx={{ display: "flex", alignItems: "center"}}
                   size="small"
                   variant="contained"
                   color="secondary"
                   disabled = {postStatus1 === "draft" || postStatus1 === "pending"}
                 >
                   VISIT PAGE{" "}
                   <ArrowRightIcon
                     sx={{
                       width: "24px",
                       marginLeft: "0px",
                       marginTop: "-1px",
                       marginRight: "-3px",
                     }}
                   />{" "}
                 </Button>
                 }
               </Link> : 
                  <Button
                   sx={{ display: "flex", alignItems: "center"}}
                   size="small"
                   variant="contained"
                   color="secondary"
                   disabled = {postStatus1 === "draft" || postStatus1 === "pending"}
                 >
                   VISIT PAGE{" "}
                   <ArrowRightIcon
                     sx={{
                       width: "24px",
                       marginLeft: "0px",
                       marginTop: "-1px",
                       marginRight: "-3px",
                     }}
                   />{" "}
                 </Button>
                 }
               </div>
            </div>
              {/* Page meta contents  */}
              <Box sx={{display:"flex", gap:"32px", margin:"2px 16px",width: "90%"}}>
                <Grid container spacing={2} direction={"column"}>
                  <Grid item xs={6}>
                  <TextField
                    disabled={!isEditable}
                    fullWidth
                    id="outlined-required"
                    label="Page Title:"
                    value={selectedPageTitle}
                    onChange={(e) => setSelectedPageTitle(e.target.value)}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}  // Forces the label to stay at the top
                  />
                  </Grid>
                  <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    id="outlined-required"
                    label="Page Group:"
                    value={pageContent?.page_group_name}
                    size="small"
                    color="secondary"
                    InputLabelProps={{ shrink: true }}
                   
                  />
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction={"column"}>
                <Grid item xs={6}>
                <Autocomplete
                  disabled={!isEditable}
                  disablePortal
                  id="combo-box-demo"
                  options={pageStatusData}
                  size="small"
                  value={selectedPageStatus}
                  onChange={(event, value) => setSelectedPageStatus(value ? value : "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Page Status:"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="outlined-required"
                  label="Last Modified:"
                  value={pageContent?.post_date ? pageContent.post_date.split(' ')[0] : ''}
                  size="small"
                  color="secondary"
                  InputLabelProps={{ shrink: true }}  // Forces the label to stay at the top
                />
                </Grid>
                </Grid>
                <Grid container spacing={2} direction={"column"}>
                <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  id="outlined-required"
                  label="Page Type:"
                  value={pageContent?.post_type}
                  size="small"
                  color="secondary"
                  InputLabelProps={{ shrink: true }}  // Forces the label to stay at the top
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  id="outlined-required"
                  label="Edited by:"
                  value="N/A"
                  size="small"
                  color="secondary"
                />
                </Grid>
                </Grid>
              <Box sx={{display:"flex", flexDirection:"column", gap:"16px",width: "100%"}}>
              <Typography sx={{color:"#000000DE", fontWeight:"500"}}>Page Meta Actions:</Typography>
              <Box sx={{display:"flex"}}>
                <Tooltip title="Discard" placement="top">
                  <IconButton disabled={pageContent?.unpublished_change === false || !isEditing}>
                  <HistoryIcon 
                color={pageContent?.unpublished_change && isEditing ? "secondary" : "disabled"} 
                sx={{cursor: pageContent?.unpublished_change ? "pointer" : "not-allowed"}}
                onClick={handleDiscardConfirmation}
                />
                </IconButton>
                  </Tooltip>
                <Tooltip title="Save Changes" placement="top">
                <IconButton 
                disabled={metaDataStatus || (selectedPageStatus === "" || selectedPageTitle === "")}
                onClick={handleSaveChanges} 
                >
                <SaveIcon color={metaDataStatus || (selectedPageStatus === "" || selectedPageTitle === "") ? "disabled" : "secondary"}
                sx={{cursor:(selectedPageStatus !== "" && selectedPageTitle !== "") ? "pointer" : "not-allowed" }} />
                </IconButton>
                </Tooltip>
                <Tooltip title={!isEditing ? "Edit" : "Cancel"} placement="top">
                <IconButton onClick={handleCancel} style={{ cursor: 'pointer' }}>
                  {isEditing ? <CloseIcon color="secondary" /> : <EditIcon color="secondary" />}
                </IconButton>
                </Tooltip>
              </Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      {previewCurrentChangesLink  && iframeWidth !== 0 && (
        <Dialog sx={{zIndex:"9999999"}} open={openDialog} maxWidth={"xl"}>
          <div style={{position: "relative",overflowY: 'hidden'}}>
            {loading && (
              <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <CircularProgress sx={{color:"#9C27B0"}} />
              </div>
            )}
            <div className="title-version">
            <DialogTitle sx={{ padding: '0' }}>Page Preview</DialogTitle>
            <CloseIcon color="secondary" onClick={handleClose} sx={{cursor:"pointer", float:"right"}} />
            </div>
            {pageContent?.theme_v2 !== null ? <iframe
              title="Page Preview"
              src = {(selectedVersion === "Version 1") // if version 1
                ? (iframeWidth === 768) //if tablet
                  ? `${previewCurrentChangesLink}&react_devices=tablet&v=1`
                  : (iframeWidth === 414) // if mobile
                    ? `${previewCurrentChangesLink}&react_devices=mobile&v=1`
                    : `${previewCurrentChangesLink}&v=1` // if desktop
                : (selectedVersion === "Version 2") // if version 2
                  ? (iframeWidth === 768) //if tablet
                    ? `${previewCurrentChangesLink}&react_devices=tablet&v=2`
                    : (iframeWidth === 414) // if mobile
                      ? `${previewCurrentChangesLink}&react_devices=mobile&v=2`
                      : `${previewCurrentChangesLink}&v=2`
                : `${previewCurrentChangesLink}&v=2`}
              style={{
                maxWidth:'100%',
                width: `${iframeWidth}px`,
                height: iframeWidth === 414 ? "600px" : (iframeWidth === 768 ? "550px" : "600px"),
                background:"white", 
                padding:"16px 24px 24px 24px"
              }}
              
              onLoad={() => setLoading(false)}
            ></iframe> : <iframe
            title="Page Preview"
            src={(iframeWidth === 768) //if tablet
              ? `${previewCurrentChangesLink}&react_devices=tablet&v=1`
              : (iframeWidth === 414) // if mobile
                ? `${previewCurrentChangesLink}&react_devices=mobile&v=1`
                : `${previewCurrentChangesLink}&v=1`} // if desktop
            style={{width: `${iframeWidth}px`, height: iframeWidth === 414 ? "650px" : iframeHeight, border: "1px solid #ccc"}}
            onLoad={() => setLoading(false)}
          ></iframe>  }
          </div>
        </Dialog>
    )}
    </>
  );
}
