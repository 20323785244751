import { Paper, Typography } from "@mui/material";
import React from "react";
import CurrencyFilterCard from "./CurrencyFilter/CurrencyFilterCard";
import CurrencyTable from "./CurrencyTable/CurrencyTable";

const tableHeadings = {
  currency_name: "Currency Name",
  currency_code: "Currency Code",
  currency_symbol: "Currency Symbol",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    currency_name: "United States Dollar",
    currency_code: "USD",
    currency_symbol: "$",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    currency_name: "United States Dollar",
    currency_code: "USD",
    currency_symbol: "$",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    currency_name: "United States Dollar",
    currency_code: "USD",
    currency_symbol: "$",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const Currency = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Currencies List</Typography>
        <CurrencyFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <CurrencyTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default Currency;
