import WebsitePageGroup from '../../../components/AssetManagement/Website/PageGroups/WebsitePageGroup'

function WebsitePageGroupScreen() {
  return (
    <>
    <WebsitePageGroup></WebsitePageGroup>
    </>
  )
}

export default WebsitePageGroupScreen