import { Stack } from "@mui/system";
import PageScanRequestFilter from "./PageScanRequestFilter";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PageScanRequestModal from "../PageScanRequestModal/PageScanRequestModal";
import { useState } from "react";
import SimpleBackdrop from "../../../../../BackDrop/BackDrop";

interface Props{
  addNewPageReqText:any,
  setAddNewPageReqText:any
  postPageSpeed:Function
  errorAccToPageStatus:any
  postedPageSpeedContent:any
  setErrorAccToPageStatus:any
  loaderForPageSpeed:any
  rows:any
  setSelectedSearchValue:any
  selectedSearchValue:any
  selectedStatus:any
  setSelectedStatus:any
  RequestedBy:any;
  setRequestedBy:any;
}

function PageScanRequestFilterCard({selectedSearchValue,setSelectedSearchValue,rows,loaderForPageSpeed,setErrorAccToPageStatus,postedPageSpeedContent,errorAccToPageStatus,postPageSpeed, addNewPageReqText, setAddNewPageReqText, selectedStatus, setSelectedStatus,RequestedBy,setRequestedBy}:Props) {
  // const [openModal, setOpenModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const websitelisting = rows.map((row:any) => ({
    label: row.page_name
  }));

  const handleOpenModal = () => {
    setOpenModal(true);

  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={4}
        sx={{ paddingBlock: "16px" }}
      >
       <Autocomplete
          sx={{ width: "300px" }}
          options={websitelisting.map((website:any) => website.label)}
          value={selectedSearchValue}
          onChange={(event, newValue) => {
            setSelectedSearchValue(newValue);  // Update selectedSearchValue with the chosen option
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Page Name or URL"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
          filterOptions={(options, { inputValue }) =>
            options.filter((option:any) =>
              option.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          {/* Page scan request filter */}
          
          <PageScanRequestFilter setSelectedSearchValue={setSelectedSearchValue} rows={rows} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} RequestedBy={RequestedBy} setRequestedBy={setRequestedBy}/>

          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenModal}
          >
            Create new Request<AddIcon />
          </Button>
        </Stack>
      </Stack>
      <>
      {
        loaderForPageSpeed ? <SimpleBackdrop /> :
      <PageScanRequestModal setErrorAccToPageStatus={setErrorAccToPageStatus} setOpenModal={setOpenModal} postedPageSpeedContent={postedPageSpeedContent} errorAccToPageStatus={errorAccToPageStatus} postPageSpeed={postPageSpeed} open={openModal} handleClose={handleCloseModal} addNewPageReqText={addNewPageReqText} setAddNewPageReqText={setAddNewPageReqText} />
      }
      </>
    </>
  );
}

export default PageScanRequestFilterCard;
