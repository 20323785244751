import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import BudgetControlTableAdditionalContent from "./BudgetControlTableAdditionalContent";
import "../../EngagementIncentives.css"
import BudgetControlModalEdit from "../BudgetControlModal/BudgetControlModalEdit";

interface Props {
  tableHeadings: { [key: string]: string };
  exequteQueryContentQuery2:any;
  exequteQueryContentQuery3:any;
  exequteQueryContentQueryCreate:any;
  userToken:any;
  setRefresh:any;
  startDate:any;
}



const additionalHeadings = {
  id: "#",
  link_name: "Name",
  offer_line_1: "Offer",
  link_min_deposit: "Minimum Deposit",
  target_lending_page: "Copy",
};

const BudgetControlTable = ({ tableHeadings,exequteQueryContentQuery2,exequteQueryContentQuery3,exequteQueryContentQueryCreate,userToken,setRefresh,startDate}: Props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const [additionalRows, setAdditionalRows] = React.useState<any[]>([]);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const headCells = Object.entries(tableHeadings);
  const rows = exequteQueryContentQuery2?.query_output || [];
  const expandedRowsDataUnfiltered = exequteQueryContentQuery3?.query_output || [];
  const [rowSelected,setRowSelected] = React.useState([]);
  const [linkDetails,setLinkDetails] = React.useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (index: number, shared_uid: string) => {
      if (openRow === index) {
        setOpenRow(null); // Collapse row if already open
        // setAdditionalRows([]); // Clear additional rows when collapsing
      } else {
        setOpenRow(index); // Expand the row
        // Find matching rows based on shared_uid
        const matchedRows = expandedRowsDataUnfiltered.filter((item:any) => 
          item.incentivized_traffic_budget_shared_uid === shared_uid
        )
        setAdditionalRows(matchedRows); // Set additional rows for expanded content
      }
    };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row:any) => {
    const matchedRows = expandedRowsDataUnfiltered.filter((item: any) => 
      item.incentivized_traffic_budget_shared_uid === row.shared_uid
    );
    setLinkDetails(matchedRows);
    setOpenModal(true);
    setRowSelected(row);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const sortedRows = rows.sort((a: any, b: any) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    }
    return 0;
  });

  return (
    <TableContainer className="BudgetTableWrap" component={Paper} sx={{ maxHeight: 800 }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : "asc"}
                onClick={() => handleRequestSort(key)}>
                  {value && typeof value === "string" ? value : ""}
                </TableSortLabel>
              </TableCell>
            ))}
            {/* <TableCell align="center">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row:any, index:number) => (
              <React.Fragment key={row.cron_id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                {headCells.map(([key], idx) => (
                    <TableCell key={idx} align="left">
                      {key === 'brand_name' ? (
                        <>
                          <IconButton
                          aria-label="edit"
                          size="small"
                          color="secondary"
                          sx={{width:'35px', height:'35px', mr:1}}
                          onClick={() => handleOpenModal(row)} 
                        >
                          <img className="icon4" alt="" src="/icon4.svg" />
                        </IconButton>
                          {row[key] ? row[key] : ""}
                        </>
                      ):  key === 'total_cost' ? (
                        `${(row?.total_cost * row?.qftd_actual || 0).toLocaleString()}$`
                      ) :key === 'utilization' ? (
                        `${Math.round(row.utilization * 100)}%`
                      ) : key === 'cost_per_qftd' ? (
                        `${row?.cost_per_qftd !== null && row?.cost_per_qftd ? `${row.cost_per_qftd}$` : "-"}`
                      ) : key === 'modified_by' ? (
                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                          <Avatar alt={row[key] ? row[key] : ""} src="#" sx={{ width: 24, height: 24 }} />
                          {row[key] ? row[key] : ""}
                        </Stack>
                      ) : key === 'info' ? (
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          color="secondary"
                          onClick={() => handleRowClick(index, row.shared_uid)}
                        >
                          {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ) : (
                        row[key] ? row[key] : 0
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={Object.keys(tableHeadings).length + 1}>
                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                      <Box sx={{ padding: '20px 0px' }}>
                        <BudgetControlTableAdditionalContent additionalHeadings={additionalHeadings}  additionalRows={additionalRows}/>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <BudgetControlModalEdit 
        open={openModal} 
        handleClose={handleCloseModal} 
        exequteQueryContentQueryCreateData={exequteQueryContentQueryCreate} 
        startDate={startDate}
        setRefresh={setRefresh}
        userToken={userToken}
        rowSelected={rowSelected}
        linkDetails={linkDetails}
      />
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={Math.ceil(rows.length/rowsPerPage)}
          onChange={(event, value) => setPage(value - 1)}
          // page={page}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
    </TableContainer>
    
  )
}

export default BudgetControlTable