import { Paper, Typography } from "@mui/material";
import React from "react";
import TrafficSourceFilterCard from "./TrafficSourceFilter/TrafficSourceFilterCard";
import TrafficSourceTable from "./TrafficSourceTable/TrafficSourceTable";

const tableHeadings = {
  traffic_source_name: "Traffic Source Name",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    traffic_source_name: "bing ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    traffic_source_name: "bing ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    traffic_source_name: "bing ads search",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const TrafficSource = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Traffic Source List</Typography>
        <TrafficSourceFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <TrafficSourceTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default TrafficSource;
