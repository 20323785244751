import Theme from '../../../../components/AssetManagement/Design/Theme/Theme'

function ThemeScreen({ userToken ,response }: { userToken: any , response : any } ) {
  return (
    <div>
        <Theme userToken={userToken} response={response} />
    </div>
  )
}

export default ThemeScreen
