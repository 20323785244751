import { CardMedia, FormControlLabel, Radio, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Card from "@mui/material/Card";

function WebsiteImageOption() {
  return (
    <div>
      <Stack direction="row" spacing={4} sx={{ mt: 1, flexGrow: 1 }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            value="option"
            control={<Radio color="secondary"/>}
            label="Option 1"
          />
          <Box
            sx={{
              flex: 1,
              width: "100%",
              border: "7px solid white",
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia component="img" height="194" image="#" alt="#" />
            </Card>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            value="option"
            control={<Radio color="secondary"/>}
            label="Option 2"
          />
          <Box
            sx={{
              flex: 1,
              width: "100%",
              border: "7px solid white",
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia component="img" height="194" image="#" alt="#" />
            </Card>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            value="option"
            control={<Radio color="secondary"/>}
            label="Option 3"
          />
          <Box
            sx={{
              flex: 1,
              width: "100%",
              border: "7px solid white",
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia component="img" height="194" image="#" alt="#" />
            </Card>
          </Box>
        </Box>
      </Stack>
      <Typography sx={{ marginTop: "16px" }}>
        Select a design layout - coming soon!
      </Typography>
      <Typography
        sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
      >
        You will be able to change the layout later.
      </Typography>
    </div>
  );
}

export default WebsiteImageOption;
