import { endpointsService } from "../../../../services/endPoints.services";
import { setDisplayErrorSnackBar, setIsLoading } from "../../../../features/userSlice";
import store from "../../../../store";

async function getAdvertisingAccountsContent(oauthTokenHeader: string, setAdvertisingAccountContent: any,setErrorAccToPageStatus:any, trackPromise: any) {
    try {
        const response = await endpointsService.getAdvertisingAccounts(oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            setAdvertisingAccountContent(response?.data?.data);
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function advertisingAccountsToggle(oauthTokenHeader:string,setAdvertisingAccountToggleContent:any,data:any,account_shared_uid: any) {
    try {
        const response = await endpointsService.advertisingAccountsToggle(oauthTokenHeader,data,account_shared_uid,)
        if (!response.status || response.status !== 200) {
            // store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while toggling status', type: 'error' }));
        } else {
            store.dispatch(setIsLoading(true))
            setAdvertisingAccountToggleContent([response?.data?.data]);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function getTrafficSource( oauthTokenHeader: string , setTrafficSource:any) {
        try {
            const response = await endpointsService.getTrafficSource(oauthTokenHeader)
            if (!response.status || response.status !== 200){
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
            } else {
                setTrafficSource(response?.data?.data)
            }
        } catch (e:any) {
            store.dispatch(setDisplayErrorSnackBar({ message:e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
}
async function getGeo( setAdAccountGeoDetails:any, oauthTokenHeader: string ) {
        try {
            const response = await endpointsService.getGeo(oauthTokenHeader)
            if (!response.status || response.status !== 200){
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
            } else {
                setAdAccountGeoDetails(response?.data?.data)
                
            }
        } catch (e:any) {
            store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
}
async function postGeo( oauthTokenHeader: string, data:any,  ) {
        try {
            const response = await endpointsService.postGeo(oauthTokenHeader,data)
            if (!response.status || response.status !== 200){
            /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
            } else {
              }
        } catch (e:any) {
            /* store.dispatch(setDisplayErrorSnackBar({ message: e.parsedText.reason || 'Unknown error occurred', type: 'error' })); */
        }
}
async function postIndustry( oauthTokenHeader: string, data:any,  ) {
        try {
            const response = await endpointsService.postIndustry(oauthTokenHeader,data)
            if (!response.status || response.status !== 200){
            /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
        } else {
        }
        } catch (e:any) {
            /* store.dispatch(setDisplayErrorSnackBar({ message: e.parsedText.reason || 'Unknown error occurred', type: 'error' })); */
        }
}
async function getIndustry( setAdAccountIndustryDetails:any, oauthTokenHeader: string ) {
        try {
            const response = await endpointsService.getIndustries(oauthTokenHeader)
            if (!response.status || response.status !== 200){
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
            } else {
                setAdAccountIndustryDetails(response?.data?.data)
            }
        } catch (e:any) {
            store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
}
async function getTerritory( setAdAccountTerritoryDetails:any, oauthTokenHeader: string ) {
        try {
            const response = await endpointsService.getTerritories(oauthTokenHeader)
            if (!response.status || response.status !== 200){
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
            } else {
                setAdAccountTerritoryDetails(response?.data?.data)
            }
        } catch (e:any) {
            store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        }
}

async function createAdAccount( oauthTokenHeader: string,data:any,setErrorTextWhileCreate:any,setCreateAccRes:any, setAdAccCreateResponseData:any, trackPromise:any ) {
    try {
        const response = await endpointsService.createAdAccount(oauthTokenHeader, data)
        if (response?.status !== 201){
             setCreateAccRes(true);
           /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
        } else{
            setAdAccCreateResponseData([response?.data?.data])
        }
    } catch (e:any) {
        if(e?.status !== undefined){
            setErrorTextWhileCreate([e])
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
        }
        else(
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: "Toggle Successfull", type: 'success' })))
        )
    }
}
async function updateAdAccount(page_shared_uid: string, data:any, setAccountUpdateStatus:any,setAccountUpdateError:any, oauthTokenHeader: string, trackPromise:any/* , setSnackbarOpen:any  */) {
    try {
        const response = await endpointsService.updateAdAccount(page_shared_uid, oauthTokenHeader, data)
        if (!response.status || response.status !== 200){
           /*  setSnackbarOpen(false) */
           setAccountUpdateError(true)
        /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
        } else{            
             setAccountUpdateStatus([response.data.data]);
             trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: "Updated Account Successfully", type: 'success' })))
        }
    } catch (e:any) {
        if(e?.status !== undefined){
          /*   setErrorTextWhileCreate([e]) */
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
        }
        else(
            trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: "update Successfull", type: 'error' })))
        )
    }
}

async function getAdAccountDetails(trafficSourceUid:string, adAccountId:string,setIsLoading:any,setRetrieveFailed:any,setAdAccountDetails:any, oauthTokenHeader:string){
    try {
        /* const response = await endpointsService.getAdAccountDetails(trafficSourceUid, adAccountId, oauthTokenHeader) */
        const response = await endpointsService.getAdAccountDetails(trafficSourceUid, adAccountId, oauthTokenHeader)
        if (!response.status || response.status !== 200) {
            setRetrieveFailed(true)
            /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
        } else {
            store.dispatch(setIsLoading(true))
            setAdAccountDetails([response?.data])
        }
        store.dispatch(setIsLoading(false))
    } catch (e) {
        // store.dispatch(setDisplayErrorSnackBar({ message: e || 'Unknown error occurred', type: 'error' }));
        setRetrieveFailed(true)
        store.dispatch(setIsLoading(false))
    }
}

async function getSingleAdvertisingAccount(oauthTokenHeader: string, setSingleAdAccountData: any,setErrorAccToPageStatus:any, rowId: any, trackPromise: any) {
    try {
        const response = await endpointsService.getSingleAdvertisingAccount(rowId, oauthTokenHeader );
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
        } else {
            setSingleAdAccountData(response?.data?.data);
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function postWebToAcc( oauthTokenHeader: string,data:any, account_shared_uid:any,  ) {
    try {
        const response = await endpointsService.postWebsiteToAccount(oauthTokenHeader, data, account_shared_uid)
        if (!response.status || response.status !== 200){
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }));
        } else {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Website Added Successfully', type: 'success' }));
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

export const advertisingAccountsEndPointLogic = {
    getAdvertisingAccountsContent,
    advertisingAccountsToggle,
    getTrafficSource,
    getAdAccountDetails,
    getSingleAdvertisingAccount,
    getGeo,
    getIndustry,
    createAdAccount,
    getTerritory,
    postGeo,
    postIndustry,
    updateAdAccount,
    postWebToAcc
}
