
export function validEmail(email: string): string {
    if (!email) return 'Required email'
    if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
        return 'Invalid email address';
    }
    return '';
}

export function validPassword(password: string): string {
    if (!password) {
        return 'Required password, Must be 8 or more characters (English only) and contain at least 1 number and 1 special character.';
    }
    if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(password))) {
        return 'Must be 8 or more characters (English only) and contain at least 1 number and 1 special character.';
    }
    return '';
}


