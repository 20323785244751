
const requestSearch = (searchedVal: string, originalRows: any) => {
    const filteredRows = originalRows.filter((row: any) => {
        return row?.shared_uid?.includes(searchedVal);
    });
    return filteredRows
};


// eslint-disable-next-line
export const customTableHelpers = {
    requestSearch,
};