import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

interface DeactivateWebsiteModalProps {
  open: boolean;
  handleClose: () => void;
}

function DeactivateWebsiteModal({
  open,
  handleClose,
}: DeactivateWebsiteModalProps) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
        Deactivate Website
        </DialogTitle>
        <Box>
          <Typography>
            Are you sure you want to deactivate this website?<br />
            Deactivating the website will make it inaccessible to all users and will cause the need for further actions such as pausing any active campaign or service associated with it. This action can be reversed from the edit account screen.<br />
            <Box component="span" sx={{ display: "block", fontWeight: 500, marginTop: "8px" }}>
              If you would like to continue, please mark the following checkbox and fill the text field:
            </Box>
          </Typography>
          <Box mt={1} mx={1}>
          <FormControlLabel control={<Checkbox color="secondary"/>} label="I would like to deactivate the website" />
          </Box>
          <Box mt={1}>
          <TextField
                  id="outlined-multiline-flexible"
                  label="Website Name" // Set your label here
                  placeholder="How you like the site to be called" // Set your placeholder here
                  required
                  fullWidth
                  color="secondary"
                  size="small"
                />
                <Typography
                  sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
                >
                  As it is written in the edit website screen
                </Typography>
          </Box>
        </Box>
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
          <Button color="secondary" onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="contained" color="secondary" disabled>
            <Stack flexDirection={"row"} gap={1}>
            deactivate
            </Stack>
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}

export default DeactivateWebsiteModal;
