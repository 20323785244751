import parse from "html-react-parser";
import React, { Children, useEffect, useState } from "react";
import styles from "./PageBuilding.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  Icon,
  Paper,
  Typography,
} from "@mui/material";
import styles2 from "../PageBuilderStructure/PageBuilderStructure.module.css";
import DraggableElement from "./DraggableElement";
import ComponentOptions from "./ComponentOptions";
import Box from "@mui/system/Box";
import Card from "@mui/material/Card";
import TitleIcon from "@mui/icons-material/Title";
import DialogContent from "@mui/material/DialogContent";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import {
  AllStyles,
  GlobalStyles,
} from "../../../../pages/AssetManagement/PageManagement/PageBuilding/logic/ElementsDesign";
import WidgetListPopUp, { WidgetListPopUpProps } from "./WidgetListPopUp";
import ImageUploader from "../../../ImageUploader/ImageUploader";
import ImageIcon from '@mui/icons-material/Image';
import EmptyPlaceholder from "./EmptyPlaceholder";
import ViewCozyIcon from '@mui/icons-material/ViewCozy';

export interface PageBuildingProps {
  currentpageElements: any;
  pageUrlComponents: any;
  onDragEnd: any;
  handleFileChange: any;
  onChangeDndItem: any;
  inputRef: any;
  tasks: any;
  onClickItem: any;
  pageDictionary: any;
  pageBuildingStructure: any;
  header: any;
  footer: any;
  headerMenu: any;
  pageStructureStyle: any;
  htmlWrapperDiv: string;
  setCurrentPageElements: any;
  currentpageElementsOriginal: any;
  setCurrentPageElementsOriginal: any;
  siteAllAvailableWidgets: any;
  onEditWidget: any;
  oauthTokenHeader: string,
  siteUid: string,
  setisPublishOpen: any;
  setIsElementSaved: any;

}

export default function PageBuilding({
  currentpageElements,
  onChangeDndItem,
  onClickItem,
  onDragEnd,
  pageUrlComponents,
  tasks,
  pageDictionary,
  pageBuildingStructure,
  header,
  headerMenu,
  pageStructureStyle,
  htmlWrapperDiv,
  setCurrentPageElements,
  footer,
  currentpageElementsOriginal,
  siteAllAvailableWidgets,
  onEditWidget,
  setCurrentPageElementsOriginal,
  oauthTokenHeader,
  siteUid,
  setisPublishOpen,
  setIsElementSaved,
}: PageBuildingProps) {
  const [open, setOpen] = useState<any>(false);
  const [selectedWidgettype, setSelectedWidgetType] = useState<any>();
  const [tableRotation, setTableRotation] = useState(false);
  const [allWidgetsByType, setAllWidgetsByType] = useState<any>();
  const [dragingDataWidgetList, setDragingDataWidgetList] = useState<any>();
  const tasksValues = Object.values(tasks) as any;
  const tasksKeys = Object.keys(tasks);
  const [statusClicked, setStatusClicked] = useState<any | undefined>();
  const [deviceEdited, setDeviceEdited] = useState<any | undefined>();
  const [ElementStyle, setElementStyle] = useState() as any;
  const [isImageBeingDropped, setIsImageBeingDropped] = useState(false);
  const [isImageBeingEdited, setIsImageBeingEdited] = useState(false);
  const [imageIndex, setImageIndex] = useState<any | undefined>();
  const [shareUidAvailable, setShareUidAvailable] = useState<any | undefined>();
  const [theme, setTheme] = useState(null);
  const [indexvalue, setIndexValue] = useState(null);
  const [isDragged,setIsDragged] = useState(false);

  const checkHtmlWrapper = () => {
    if (htmlWrapperDiv.length > 0) {
      return htmlWrapperDiv;
    } else {
      const result = pageBuildingStructure?.filter(
        (value: any) => value[0].htmlWrapper
      );
      if (result) {
        return !!result[0][0]?.id && result[0][0]?.name;
      }
    }
  };
  const htmlWrapper = checkHtmlWrapper();
  const AllStyle =
    !!pageStructureStyle && (AllStyles(pageStructureStyle, htmlWrapper) as any);


  const getWidgetType = (originalShortCode: string) => {
    let result = "" as any;
    let widgetType;
    let rotation = false; // Initialize rotation as false
    // eslint-disable-next-line
    siteAllAvailableWidgets?.map((widget: any) => {
      let rotateShortcode;
      const splitedShortCode = originalShortCode
        .split(" ")[1]
        .split("=")[1]
        .replace(/'/g, "")
        .replace(/]/g, "");
      if (splitedShortCode.includes(",")) { // it means there is rotation 
        rotation = true;
        rotateShortcode = splitedShortCode
          .replace(/:1/g, "")
          .split(",");
        if (widget?.shared_uid.includes(rotateShortcode[0])) {
          result = widget;
          widgetType = result?.type;
          return widgetType;
        }
      } else {
        if (widget?.shared_uid.includes(splitedShortCode)) {
          result = widget;
          widgetType = result?.type;
          return widgetType;
        }
      }
    });
    setTableRotation(rotation); // Set the rotation state here
    return widgetType;
  };

  const extractSharedUIDs = (shortCode: any) => {
    // Regular expression to match the shared_uids attribute
    const uidPattern = /shared_uids?=['"]([^'"]+)['"]/;
    const match = uidPattern.exec(shortCode);

    // If the pattern matches and captures groups are found
    if (match && match[1]) {
      // Split the captured group by commas and return the resulting array
      const uids = match[1].split(',').map(uid => uid.split(':')[0]);
      return uids;
    }

    // If no match is found, return an empty array
    return [];
  };


  useEffect(() => {
    const data = JSON.parse(pageDictionary);  // Parse the page dictionary string into a JavaScript object
    const themeValue = data?.settings?.theme;  // Extract the theme value from the parsed data
    setTheme(themeValue); // Set the extracted theme value into the state variable 'theme'
  }, [pageDictionary]);

  useEffect(() => {
    if (theme === "black") {
      // If the theme is black, import the Black.css file
      import("../PageBuilding/css/canvas/Black.css");
    } else {
      // If the theme is not black, import the Default.css file
      import("../PageBuilding/css/canvas/Default.css");
    }
  }, [theme]);

  const editWidgetShortCode = (originalShortCode: any) => {
    const widgetType = getWidgetType(originalShortCode);
    if (widgetType) {
      setSelectedWidgetType(widgetType);
      setOpen(true);
      onClickItem(widgetType, setAllWidgetsByType);
    }
    const sharedUIDs = extractSharedUIDs(originalShortCode);
    setShareUidAvailable(sharedUIDs);
  };


  const getDeviceEdited = (originalShortCode: string) => {
    if (originalShortCode.includes("mobile")) {
      return "mobile";
    } else if (originalShortCode.includes("tablet")) {
      return "tablet";
    }
    return "desktop";
  };
  const handleClickEditElement = (index: number, elementClickedUpdate: any) => {
    if (
      currentpageElements[index]?.props?.className.includes(
        "internal-widget"
      ) &&
      !currentpageElements[index]?.props?.className.includes("main date")
    ) {
      const originalShortCode =
        currentpageElementsOriginal[index]?.props?.children;
      const device = getDeviceEdited(originalShortCode);
      setStatusClicked(index);
      setDeviceEdited(device);
      editWidgetShortCode(originalShortCode);
      elementClickedUpdate(false);
    } else if (currentpageElements[index]?.props?.className.includes("image-container")) {
      setIsImageBeingDropped(true);
      setImageIndex(index);
      setIsImageBeingEdited(true);
    } else {
      setElementStyle(GlobalStyles(currentpageElements[index]));
      elementClickedUpdate(true);
    }
  };

  const updateChangedElements = (textChanged: string, index: number) => {
    let changedElement = parse(textChanged) as any;
    const newCurrentPageElements = Array.from(currentpageElements);
    newCurrentPageElements[index] = changedElement;
    setCurrentPageElements(newCurrentPageElements);
  };
  const handelCLickEditWidget = (
    elementIndex: number,
    widgetShortCode: string,
    deviceChecked: any
  ) => {
    onEditWidget(elementIndex, widgetShortCode, deviceChecked, siteAllAvailableWidgets);

  };
  const handelCLickSaveElement = (
    textChanged: string,
    index: number,
    elementClickedUpdate: any
  ) => {
    updateChangedElements(textChanged, index);
    elementClickedUpdate(false);
  };
  const handelClickDeleteElement = (index: number) => {
    let newCurrentElements = Array.from(currentpageElements);
    let newOriginalElements = Array.from(currentpageElementsOriginal);

    newCurrentElements.splice(index, 1);
    setCurrentPageElements(newCurrentElements);

    newOriginalElements.splice(index, 1);
    setCurrentPageElementsOriginal(newOriginalElements);

    setIsElementSaved(true)
    setisPublishOpen(true)
  };

  const handleImageUpload = (imagePath: string) => {
    // Constructing the image element with the uploaded image path
    const imageElement = (
      <img
        src={imagePath}
        alt=""
        className="image-element"
        style={{ width: "100%" }}
      />
    );

    // Constructing the div element with CSS class "image-container" to contain the image
    const imageContainer = (
      <section className="image-container main" style={{ width: "100%" }}>
        {imageElement}
      </section>
    );

    // Update the page elements with the uploaded image
    const newItem = [...currentpageElements];
    const newOriginalItem = [...currentpageElementsOriginal]; // Add this line to create a copy of currentPageElementsOriginal
    if (isImageBeingEdited) {
      newItem[imageIndex] = imageContainer;
      newOriginalItem[imageIndex] = imageContainer; // Update the corresponding element in currentPageElementsOriginal
    } else {
      newItem.splice(imageIndex, 0, imageContainer);
      newOriginalItem.splice(imageIndex, 0, imageContainer); // Insert the new image element in currentPageElementsOriginal
    }
    setCurrentPageElements(newItem);
    setCurrentPageElementsOriginal(newOriginalItem); // Update the currentPageElementsOriginal state
    setIsImageBeingEdited(false);
  };

  const onDragEndHandel = (result: any, type?: any) => {
    setShareUidAvailable([]);
    if (type === "widgetList") {
      let shortCodesResult = result;
      onDragEnd(dragingDataWidgetList, shortCodesResult);
      setOpen(false);

    } else {
      if (result?.draggableId?.includes("Widget")) {
        setStatusClicked("dragged");
        setDeviceEdited(null);
        setDragingDataWidgetList(result);

        const widetType = tasks?.Widgets[result.source.index]?.type;
        setSelectedWidgetType(widetType);
        setOpen(true);
        onClickItem(widetType, setAllWidgetsByType);
      } else if (result?.draggableId?.includes("Image")) {
        setStatusClicked("dragged");
        setDeviceEdited(null);
        setIsImageBeingDropped(true);

        const newIndex = result.destination.index;
        // Update the state with the new index
        setImageIndex(newIndex);
      } else {
        setIsElementSaved(true);
        setisPublishOpen(true);
        onDragEnd(result);
        const destinationIndex = result.destination.index;
        // handleClickEditElement(destinationIndex, () => {});
        setIndexValue(destinationIndex); // Set the index value to the destinationIndex;
        setIsDragged(true);
      }
    }
  };

  const handelClose = (value: any) => {
    setOpen(false);
    setIsDragged(false);
  };


  const WidgetListPopUpProps: WidgetListPopUpProps = {
    allWidgetsByType,
    statusClicked,
    deviceEdited,
    onDragEndHandel,
    handelCLickEditWidget,
    setOpen,
    setisPublishOpen,
    setIsElementSaved,
    selectedWidgettype,
    shareUidAvailable
  };
  const iconNames = [
    <TitleIcon key="TitleIcon" />,
    <FormatAlignLeftIcon key="FormatAlignLeftIcon" />,
    <ViewCozyIcon key="ViewCozyIcon" />,
    <AutoAwesomeMosaicIcon key="AutoAwesomeMosaicIcon" />,
    <ImageIcon key="ImageIcon" />
  ];



  return (
    <>
      {isImageBeingDropped && <ImageUploader setisPublishOpen={setisPublishOpen} setIsElementSaved={setIsElementSaved} imageBeingDropped={isImageBeingDropped} setIsImageBeingDropped={setIsImageBeingDropped} oauthTokenHeader={oauthTokenHeader}
        siteUid={siteUid} handleImageUpload={handleImageUpload} />}
      <Box component="section" sx={{ background: "#ffffff", height: "100vh" }}>
        <Card sx={{ borderRadius: 0 }}>
          <div
            className="pageLayout-content"
            style={{
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "16px", margin: 0, padding: "16px" }}>
              Page Layout
            </Typography>
          </div>
        </Card>
        <div className="pageLayoutBody" style={{ padding: "8px 0px 0px 16px" }}>
          <DragDropContext onDragEnd={onDragEndHandel}>
            <div className={styles.newDragAndDrop}>
              <ul className={styles.ul}>
                {tasksValues?.map((values: any, index: any) => (
                  <Accordion
                    className={styles.accordion}
                    key={index}
                    sx={{ boxShadow: "none" }}
                  >
                    <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                      <div
                        className="iconContainerHeader"
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          gap: "32px",
                        }}
                      >
                        <div className="iconContainer">
                          {iconNames[index % iconNames.length]}
                        </div>
                        <Typography>{tasksKeys[index]}</Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails key={index}>
                      <DraggableElement
                        values={values}
                        prefix={index}
                        key={index}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </ul>
            </div>
            <div className={styles2.cardcontent}>
              <Card sx={{ overflowY: "scroll", width: "100%" }}>
                <Droppable droppableId="PageBuilding">
                  {(provided, snapshot: any) => (
                    // total background color change
                    <div className="canvas-bg-color"
                      style={
                        currentpageElements?.length > 0
                          ? {
                            marginTop: "10px",
                            width: "100%",
                            backgroundColor: "#eeeeee",
                          }
                          : {
                            marginTop: "10px",
                            width: "100%",
                            backgroundColor: "#eeeeee",
                            height: "900px",
                          }
                      }
                      ref={provided.innerRef}
                      data-is-dragging={snapshot?.isDragging}
                      {...provided.droppableProps}
                    >
                      <div
                        id={htmlWrapper ? htmlWrapper : ""}
                        className={htmlWrapper ? "htmlWrapper" : ""}
                      >
                        {pageUrlComponents as any}
                        {header as any}
                        {headerMenu as any}
                        {currentpageElements.length === 0 && <EmptyPlaceholder pageDictionary={pageDictionary} />} {/* this is an empty placeholder */}
                        <AllStyle as any />
                        {Children.toArray(currentpageElements)?.map(
                          (element: any, index: number) =>
                            element !== " " &&
                              (element?.props?.children ||
                                element?.props?.className?.includes(
                                  "internal-widget"
                                )) ? (
                              <React.Fragment key={index}>
                                <Draggable
                                  draggableId={index?.toString()}
                                  index={index}
                                  key={index}
                                >
                                  {(provided, snapshot) => (
                                    <>
                                      <div
                                        ref={provided.innerRef}
                                        id={`element-${index?.toString()}`}
                                        onClick={(e) => onChangeDndItem(e)}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        data-is-dragging={snapshot.isDragging}
                                      >
                                        <Paper
                                          elevation={1}
                                          // Editor background color
                                          className="Editor-bg-color"
                                          style={{
                                            backgroundColor: "white",
                                            marginBottom: "30px",
                                            marginTop: "30px",
                                            maxWidth: "none",
                                            padding: "8px 16px",
                                          }}
                                        >
                                          <ComponentOptions
                                            pageDictionary={pageDictionary}
                                            setIsPublishOpen={
                                              setisPublishOpen
                                            }
                                            setIsElementSaved={
                                              setIsElementSaved
                                            }
                                            handleClickEditElement={
                                              handleClickEditElement
                                            }

                                            index={index}
                                            element={element}
                                            handelCLickSaveElement={
                                              handelCLickSaveElement
                                            }
                                            handelClickDeleteElement={
                                              handelClickDeleteElement
                                            }
                                            indexvalue={indexvalue}
                                            isDragged={isDragged}
                                            setIsDragged={setIsDragged}
                                          />
                                          <Typography fontSize={13}>{React.isValidElement(element) ? element : null}</Typography>
                                        </Paper>
                                      </div>
                                      {ElementStyle ? (
                                        <ElementStyle as any />
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </Draggable>
                              </React.Fragment>
                            ) : (
                              ""
                            )
                        )}
                        {provided.placeholder}
                        {footer as any}
                      </div>
                    </div>
                  )}
                </Droppable>
              </Card>
            </div>

          </DragDropContext>
          <div>
            <Dialog onClose={handelClose} open={open} fullWidth maxWidth="sm">
              {deviceEdited ? (
                <DialogTitle>
                  {" "}
                  WIDGET TYPE : {tableRotation ? "Rotated" : ""} {selectedWidgettype}
                </DialogTitle>
              ) : (
                <DialogTitle>
                  {" "}
                  Layout Widgets: {selectedWidgettype}
                </DialogTitle>
              )}
              <DialogContent>
                <WidgetListPopUp {...WidgetListPopUpProps} />
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </Box>
    </>
  );
}
