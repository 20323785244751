import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PageGroupFilter from "../PageGroupFilter/PageGroupFilter";
import CreateNewRequestModal from "../PageGroupModal/CreateNewRequestModal";
import { useState } from "react";


function PageGroupFilterCard() {

  const websitelisting = [
    { label: "websiteexample1.com"},
    { label: "websiteexample2.com"},
    { label: "websiteexample3.com"},
    { label: "websiteexample4.com"},
    { label: "websiteexample5.com"},
  ];

  const [openModal, setopenModal] = useState(false);

  const handleOpenModal = () => {
    setopenModal(true);
  }

  const handleCloseModal = () =>{
    setopenModal(false);
  }

  return (
    <>
    <Typography fontSize={24}>Page Group List</Typography>
    <Typography sx={{ color: "#00000099", paddingBottom: "16px" }}>Edit a page group or create a new one.</Typography>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={2}
      >
        <Autocomplete
          sx={{ width: "300px" }}
          options={websitelisting.map((websitename)=>websitename.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Page Group Name"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <PageGroupFilter></PageGroupFilter>
          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenModal}
          >
            Create new Request <AddIcon />
          </Button>
        </Stack>
        <CreateNewRequestModal open={openModal} handleClose={handleCloseModal}></CreateNewRequestModal>
      </Stack>
    </>
  );
}

export default PageGroupFilterCard;
