import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, LinearProgress, Typography } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { pageBuildingEndPointLogic } from '../../logic/AssetManagement/PageManagement/PageBuilding/pageBuildingEndPointLogic';
import { setIsLoading } from '../../features/userSlice';

interface AlertDialogProps {
  imageBeingDropped: boolean;
  setIsImageBeingDropped: React.Dispatch<React.SetStateAction<boolean>>;
  oauthTokenHeader: string;
  siteUid: string;
  handleImageUpload: any;
  setIsElementSaved:any;
  setisPublishOpen:any
}

export default function AlertDialog({
  setIsImageBeingDropped,
  oauthTokenHeader,
  siteUid,
  handleImageUpload,
  setIsElementSaved,
  setisPublishOpen
}: AlertDialogProps) {
  const [open, setOpen] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [base64String, setBase64String] = useState('');
  const [loading, setLoading] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [, setImageData] = useState([]);
  const [error, setError] = useState<string | null>(null);

  const fetchImageData = async () => {
    const data = {
      data: {
        base64_image: base64String,
        file_name: image?.name,
      },
    };
    try {
      setLoading(true); // Show loader when API call starts
      setError(null);
      await pageBuildingEndPointLogic.imageUpload(siteUid, oauthTokenHeader, data, setIsLoading, (updatedImageData: any) => {
        if (updatedImageData) {
          setImageData(updatedImageData);
          handleImageUpload(updatedImageData?.upload_path);
          setisPublishOpen(true)
          setIsElementSaved(true)
          handleClose()
        }else {
          setisPublishOpen(false);
          setIsElementSaved(false);
    }})
   
      
     /*  await pageBuildingEndPointLogic.imageUpload(siteUid, oauthTokenHeader, data, setIsLoading, (updatedImageData: any) => {
        setImageData(updatedImageData);
        handleImageUpload(updatedImageData?.upload_path);
        handleClose();
      }); */
      
    } catch (e) {
      setError('Failed to upload the image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsImageBeingDropped(false);
    setError(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
      handleFileUpload(file);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setBase64String(base64String);
        setIsImageSelected(true);
      };

      reader.readAsDataURL(file);
      setImage(file);
      handleFileUpload(file);
    }
    e.target.value = '';
  };

  const handleUploadClick = () => {
    const inputElement = document.getElementById('fileInput') as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleFileUpload = (file: File) => {
    setBase64String('');
    setLoading(false);
    setImage(file);
  };

  const handleClear = () => {
    setImage(null);
    setIsImageSelected(false);
    setError(null);
  };

  const handleSave = async () => {
    await fetchImageData();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Paper sx={{ width: '600px' }}>
          <DialogTitle id="alert-dialog-title">{"Media element - Image"}</DialogTitle>
          <div style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}>
            <div style={{ display: 'flex', paddingLeft: '70px', paddingRight: '70px', justifyContent: 'center', color: '#9c27b0', borderBottom: '2px solid #9c27b0', marginTop: '10px' }}>
              <h1 style={{ marginBottom: '10px', fontWeight: '600' }}>UPLOAD NEW IMAGE</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '70px', paddingRight: '70px', color: '#817f82', borderBottom: '1px solid #817f82', marginTop: '10px' }}>
              <h1 style={{ marginBottom: '10px', fontWeight: '600' }}>CHOOSE EXISTING</h1>
            </div>
          </div>
          <DialogContent>
            <div>
              <div style={{ border: '2px dashed #ccc', padding: '40px', textAlign: 'center' }} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                {image ? (
                  <img src={URL.createObjectURL(image)} alt="" style={{ maxWidth: '100%', maxHeight: '200px', marginBottom: '15px' }} />
                ) : (
                  <div>
                    <div style={{ marginBottom: '15px', color: '#9c27b0' }}>
                      <UploadFileOutlinedIcon />
                    </div>
                    <span onClick={handleUploadClick} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#9c27b0', fontSize: '16px' }}>Click to upload</span>{' '}
                    or drag and drop
                    <p style={{ marginTop: '20px', color: '#817f82' }}>PNG, JPG or PDF (max. 5MB)</p>
                    <input type="file" id="fileInput" accept="image/*" style={{ display: 'none', width: '200px' }} onChange={handleInputChange} />
                  </div>
                )}
              </div>
            </div>
            {image && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                <span>{image?.name}</span>
                <CloseOutlinedIcon style={{ justifyContent: 'flex-end', cursor: 'pointer' }} onClick={handleClear} />
              </div>
            )}
            {error && (
              <Typography color="error" style={{ marginTop: '10px', textAlign: 'center' }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            {loading && <LinearProgress sx={{ flexGrow: 1, marginRight: '10px' }} />}
            <Button style={{ color: '#9c27b0' }} onClick={handleClose}>Cancel</Button>
            {!loading && isImageSelected && !error && (
              <Button style={{ display: 'flex', background: '#9c27b0', color: 'white', alignItems: 'center' }} onClick={handleSave} autoFocus>
                Add Image
                <AddOutlinedIcon style={{ fontSize: '20px', marginBottom: '4px', marginLeft: '10px' }} />
              </Button>
            )}
            {!loading && error && (
              <Button style={{ display: 'flex', background: '#9c27b0', color: 'white', alignItems: 'center' }} onClick={handleSave} autoFocus>
                Retry
                <ReplayIcon style={{ fontSize: '20px', marginBottom: '4px', marginLeft: '10px' }} />
              </Button>
            )}
          </DialogActions>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}
