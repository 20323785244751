import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

function WebsiteConnectivity() {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div>
      <Grid container>
        <Grid xs={12}>
          <Typography sx={{ marginTop: "8px" }}>Connectivity</Typography>
        </Grid>
        <Grid xs={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="WP Username" // Set your label here
            placeholder="email address that will use you for WP login " // Set your placeholder here
            required
            fullWidth
            color="secondary"
            size="small"
            margin="dense"
          />
          <Typography
            sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
          >
            must be a valid email address.
          </Typography>
        </Grid>
        <Grid xs={6} sx={{ pl: 2 }}>
          <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined" fullWidth>
            <InputLabel
              htmlFor="outlined-adornment-password"
              color="secondary"
              size="small"
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              size="small"
              color="secondary"
              placeholder="Password that will use you for WP login "
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Typography
            sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}
          >
            A min of 8 characters. We don't store your password
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default WebsiteConnectivity;
