import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CardContent, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
/* import { styled } from '@mui/material/styles'; */
import { Link } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
interface props {
  fileBase64s: any;
  setFileBase64s: any;
  setFileName: any;
  fileName: any;
  postFileUpload: any;
  selectedAccount: any;
  singleNoteShareUID:any;
  getFileContent: any;
  isThisAdd: any;
  files: any;
  setFiles: any;
  isDragActive:any
  setIsDragActive:any
}
function AdditionalContentAttachments({
  files,
  setFiles,
  isThisAdd,
  getFileContent,
  selectedAccount,
  singleNoteShareUID,
  postFileUpload,
  fileBase64s,
  setFileBase64s,
  setFileName,
  fileName,
  isDragActive,
  setIsDragActive // Default value is false to maintain current functionality
}: props) {
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isDragActive1, setIsDragActive1] = useState(false)
useEffect(() => {
    let timer: any;
    if (isCardVisible) {
      timer = setInterval(() => {
        setFiles((oldFiles: any) =>
          oldFiles.map((fileObj: any) => {
            const newProgress = Math.min(fileObj.progress + Math.random() * 10, 100);
            return {
              ...fileObj,
              progress: newProgress,
            };
          })
        );
      }, 50);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isCardVisible, setFiles]);
  const handleFileChange = (event: any) => {
    const selectedFiles = Array.from(event.target.files as FileList);
    processFiles(selectedFiles);
  };
  const processFiles = (selectedFiles: File[]) => {
    const validFiles: any[] = [];
    let isError = false;
    for (const file of selectedFiles) {
      const fileSizeMB = file.size / (1024 * 1024);
      const fileType = file.type
      if (fileSizeMB > 5) {
        setUploadError(`File size exceeds the 5MB limit for ${file.name}.`);
        isError = true;
        continue;
      }
      if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'application/pdf') {
        setUploadError('Only PNG, JPG, or PDF files are allowed.');
        return;
      }
      setUploadError(null);
      validFiles.push({ file, progress: 0 });
      // Convert file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader?.result?.toString().split(',')[1] ?? ''; // Extract base64 part
        setFileBase64s((prevBase64Strings: any) => [...prevBase64Strings, base64String]);
        if (isDragActive) {
          const fileData = {
            "object_type": "business_unit_activity_notes",
            "file_name": file.name,
            "file": base64String
          };
          // Auto-upload file when drag-and-drop occurs and file is valid
          postFileUpload(fileData, singleNoteShareUID);
        }
      };
      reader.readAsDataURL(file);
    }
    if (!isError) {
      setFiles((prevFiles: any) => [...prevFiles, ...validFiles]);
      setIsCardVisible(true);
    }
  };


 const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_: any, i: any) => i !== index);
    setFiles(updatedFiles);
    setFileBase64s((prevBase64Strings: any) => prevBase64Strings.filter((_: any, i: any) => i !== index));
    if (updatedFiles?.length === 0) {
      setIsCardVisible(false);
    }
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
    setIsDragActive1(true);
  };
  const handleDragLeave = () => {
    setIsDragActive1(false);

  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive1(false);   

    const droppedFiles: File[] = Array.from(event.dataTransfer.files as FileList); // Explicitly cast as FileList
    processFiles(droppedFiles); // Pass the files to your file handling logic
    
    
  };
  return (
    <div>
      <Card
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        sx={{
          padding: '16px',
          textAlign: 'center',
          backgroundColor: isDragActive1 ? '#F0F0F0' : 'transparent',
          boxShadow: 'none'
        }}
      >
        <Paper sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '8px', paddingBlock: '24px', backgroundColor: '#9C27B00A' }}>
          <UploadFileIcon color='secondary' />
            <Typography sx={{ display: 'flex', gap: '4px' }} component="label" role={undefined} tabIndex={-1}>
              <Typography color="secondary" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Click to upload
              </Typography>{' '}
              or drag and drop
              <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
            </Typography>
          <Typography sx={{ color: '#00000099' }}>PNG, JPG or PDF (max. 5MB each)</Typography>
          {uploadError && <Typography color="error">{uploadError}</Typography>}
        </Paper>
      </Card>

      {files && files?.map((fileObj: any, index: any) => (
        <Card key={index} sx={{ padding: '8px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
          <div>
            <Typography sx={{ fontWeight: '500' }}>{fileObj?.file?.name}</Typography>
            <Typography sx={{ paddingBottom: '4px' }}>{(fileObj?.file?.size / 1024).toFixed(2)} KB • {fileObj?.progress === 100 ? 'Uploaded' : 'Uploading'}</Typography>
            <LinearProgress color="secondary" variant="determinate" value={fileObj?.progress} />
          </div>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveFile(index)} />
        </Card>
      ))}
      {!isThisAdd && (
        <Card variant="outlined">
          <CardContent>
            <Typography variant='h6' gutterBottom>Uploaded Files</Typography>
            {Array.isArray(getFileContent) && getFileContent?.length > 0 ? (
              <List sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                {getFileContent?.map((file: any, index: any) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <AttachFileIcon />
                    </ListItemIcon>
                    <Link to={file?.file} target="_blank">
                      <ListItemText primary={file?.file?.split('/').pop()} />
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant='body2' color="textSecondary">No files uploaded yet.</Typography>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
}
export default AdditionalContentAttachments;
