import CreateNewTheme from '../../../../components/AssetManagement/Design/Theme/CreateNewTheme/CreateNewTheme'

function CreateNewThemeScreen(userToken:any, response:any) {
  const oauthTokenHeader = userToken?.userToken;
  const Websiteresponse = userToken?.response;
  return (
    <div>
      <CreateNewTheme oauthTokenHeader={oauthTokenHeader} response={Websiteresponse} />
    </div>
  )
}

export default CreateNewThemeScreen
