import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMemo, useState } from "react";
import ActivityNotesFilterItems from "./ActivityNotesItems";
import AddNoteDialog from "./AddNewNote";
import "./ActivityNotesFilter.css";
import EditNoteDialog from "./EditNewNote";
import { ActivityNotesEndPointLogic } from '../../../../logic/AssetManagement/ActivityNotes/ActivityNotesLogic/ActivityNotesEndPointLogic';
import { setIsLoading } from '../../../../features/userSlice';

interface Props {
  oauthTokenHeader:any
  siteNames:any;
  setLoading:any
  selectedNoteShareduid:any
  setSelectedNoteShareduid:any
  result:any;
  selectedNote: any
  setSelectedNote:any;
  fileBase64:any,
  setFileBase64:any,
  isCardVisible:any,
  setIsCardVisible:any,
  selectedRows:any,
  setSelectedRows:any,
  activityNotesTableDataUpdate:any
  setActivityNotesContent:any
  postActivityNotes:any
  userData:any
  ActivityNotesContent:any
  setSelectedAccount:any
  setSelectedAsset:any
  setSelectedStakeHolder:any
  selectedStakeHolder:any
  setSelectedLabels:any
  selectedLabels:any
  isEditEnabled:boolean
  setIsEditEnabled:any
  UpdateActivityNote:Function
  selectedOptionAsset:any
  setSelectedOptionAsset:any
  selectedAsset:any
  getLabels:Function
  labelsContent:any
  setrowIndexNumber:any
  rowIndexNumber:any
  postedActivityNotesContent:any
  commentContent:any
  setCommentContent:any
  postFileUpload:Function
  compareEmailForDelete:any
  deleteNote:Function
  fileBase64s:any
  setFileBase64s:any
  setPostedActivityNotesContent:any
  setDeleteNoteContent:any
  getFileContent:any
  getFiles:any
  isThisAdd:any 
  setIsThisAdd:any
  files:any 
  setFiles:any
  selectedAccount:any
  isDragActive:any
  setIsDragActive:any
  data:any
  updatedActivityNotesContent:any
  setUpdatedActivityNotesContent:any
  isLoading:any
  selectedNoteDate:any
  setSelectedNoteDate:any
}

const ActivityNotesFilters = ({result,selectedRows,setSelectedRows,selectedNote,setSelectedNote,siteNames,activityNotesTableDataUpdate,selectedNoteShareduid,setSelectedNoteShareduid,setUpdatedActivityNotesContent,updatedActivityNotesContent,data,isDragActive, setIsDragActive, selectedAccount,files, setFiles,fileBase64,setFileBase64,isCardVisible,setIsCardVisible,isThisAdd, setIsThisAdd,getFileContent, getFiles,setDeleteNoteContent,setPostedActivityNotesContent,fileBase64s,setFileBase64s,deleteNote,compareEmailForDelete,postFileUpload,oauthTokenHeader,setCommentContent,commentContent, postedActivityNotesContent,setrowIndexNumber,rowIndexNumber,labelsContent,getLabels,selectedAsset,setSelectedOptionAsset,selectedOptionAsset,UpdateActivityNote,isEditEnabled,setIsEditEnabled,selectedLabels,setSelectedLabels,selectedStakeHolder,setSelectedStakeHolder,setSelectedAsset,setSelectedAccount,ActivityNotesContent,setActivityNotesContent,userData, postActivityNotes,isLoading,setLoading,selectedNoteDate,setSelectedNoteDate}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenEdit, setIsDialogOpenEdit] = useState(false);
  const [, setIsEditDialogOpen] = useState(false);
  const [,setDeleteSuccess] = useState([]);
  const [selectedLabelAdd, setSelectedLabelAdd] = useState([]);
  const notes = result?.map((note:any) => note?.note);

  const selectedAccountNew = useMemo(() => {
    return  data[rowIndexNumber];
  }, [ data, rowIndexNumber]);

  const handleEditClick = (note: any) => {
    setUpdatedActivityNotesContent([])
    setFiles([])
    setIsThisAdd(false)
    getLabels()
    getFiles(selectedNoteShareduid);
    setSelectedNote(note);
    setIsEditDialogOpen(true);
    setIsDialogOpenEdit(true);
  };

  const handleAddClick = (note:any) => {
    setFiles([])
    setIsThisAdd(true)
    getLabels()
    setSelectedNote(note);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsEditDialogOpen(false);
    setIsDialogOpen(false);
    setCommentContent([])
    setSelectedNote("")
    setIsDialogOpenEdit(false);
    setSelectedLabelAdd([])
  };

  const handleDeleteNote = async (selectedRows: any) => {
    setLoading(true); // Start loading state
    setIsLoading(true);
    try {
      await ActivityNotesEndPointLogic.deleteActivityNote(oauthTokenHeader, selectedRows[0]?.shared_uid, setDeleteSuccess);
      if(oauthTokenHeader) {
        setLoading(true);
        await ActivityNotesEndPointLogic.getActivityNotesContent(oauthTokenHeader, setActivityNotesContent);
      } else {
        throw new Error("No token found");
      }
    } catch (error) {
        console.error("Error deleting note:", error);
    } finally {
        setSelectedRows([]);
        setLoading(false); // Stop loading state
        setIsLoading(false);
    }
};

  const activityNotesTableData = Object.values(ActivityNotesContent || []);
  activityNotesTableDataUpdate = activityNotesTableData?.filter(Boolean)?.flat();

  return (
    <>
     {/* {loading ? <SimpleBackdrop /> : ( */}
      <>
         <Paper className="chainsFiltersWrap">
        <div style={{ padding: "16px" }}>
          {/* Heading */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Business Activity Notes
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Add or edit business activity notes to document your work or catch up on the latest changes.
              </Typography>
            </Grid>
          </Grid>

          {/* Filter part */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                marginTop: "5px",
              }}
            >
              {/* Queue Name */}
              <Autocomplete
                  options={notes ? notes :[]}
                  value={selectedNote}
                  onChange={(event, newValue) => {
                    setSelectedNote(newValue); // Update state with selected account
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Note Title or Content"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ marginLeft: "12px" }} />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  sx={{ width: "300px" }}
                />
              <ActivityNotesFilterItems siteNames={siteNames} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} result={result} selectedStakeHolder={selectedStakeHolder} setSelectedStakeHolder={setSelectedStakeHolder} selectedNoteDate={selectedNoteDate} setSelectedNoteDate={setSelectedNoteDate} selectedLabels={selectedLabels} setSelectedLabels={setSelectedLabels} setSelectedNote={setSelectedNote}/>
            </div>
            {/* Action Buttons */}
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Button
                disabled={selectedRows?.length > 1 || selectedRows?.length === 0}
                color="secondary"
                onClick={() => handleDeleteNote(selectedRows)}
              >
                 <DeleteIcon />
              </Button>
              
              <Button
                 variant="outlined"
                 color="secondary"
                 endIcon={<IconButton color="secondary" size="small" disabled={selectedRows?.length>1 || selectedRows?.length===0 }><EditIcon fontSize="small"/></IconButton>}
                 onClick={() => handleEditClick("")}
                 disabled={selectedRows?.length>1 || selectedRows?.length===0 }
              >
                EDIT NOTE
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<AddIcon />}
                onClick={() => handleAddClick("")}
              >
                WRITE A NEW NOTE
              </Button>
            </div>
          </div>
        </div>
      </Paper>
      <AddNoteDialog selectedRows={selectedRows} setActivityNotesContent={setActivityNotesContent} isLoading={isLoading} isDragActive={isDragActive} setIsDragActive={setIsDragActive} selectedLabel={selectedLabelAdd} setSelectedLabel={setSelectedLabelAdd} files={files} setFiles={setFiles} isThisAdd={isThisAdd} getFileContent={getFileContent} getFiles={getFiles} setPostedActivityNotesContent={setPostedActivityNotesContent} fileBase64s={fileBase64s} setFileBase64s={setFileBase64s} selectedAccount={selectedAccountNew} postFileUpload={postFileUpload} oauthTokenHeader={oauthTokenHeader} postedActivityNotesContent={postedActivityNotesContent} labelsContent={labelsContent} userData={userData} postActivityNotes={postActivityNotes} open={isDialogOpen} handleClose={handleCloseDialog} selectedNote={selectedNote} setSelectedNote={setSelectedNote} />
      <EditNoteDialog result={result} isLoading={isLoading} ActivityNotesContent={ActivityNotesContent} setActivityNotesContent={setActivityNotesContent} siteNames={siteNames} selectedRows={selectedRows}  setSelectedRows={setSelectedRows} fileBase64={fileBase64} setFileBase64={setFileBase64} isCardVisible={isCardVisible} setIsCardVisible={setIsCardVisible} selectedNoteShareduid={selectedNoteShareduid} setSelectedNoteShareduid={setSelectedNoteShareduid} updatedActivityNotesContent={updatedActivityNotesContent} isDragActive={isDragActive} setIsDragActive={setIsDragActive} files={files} setFiles={setFiles} isThisAdd={isThisAdd} getFileContent={getFileContent} getFiles={getFiles} fileBase64s={fileBase64s} setFileBase64s={setFileBase64s} postFileUpload={postFileUpload} oauthTokenHeader={oauthTokenHeader} labelsContent={labelsContent} selectedAccount={selectedAccountNew} userData={userData} UpdateActivityNote={UpdateActivityNote} open={isDialogOpenEdit} handleClose={handleCloseDialog} note={selectedNote} />
      </>
    </>
  );
};

export default ActivityNotesFilters;
