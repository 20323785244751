import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import TrafficClickIdentifier from "./TrafficClickIdentifier/TrafficClickIdentifier";
import Territory from "./Territory/Territory";
import Currency from "./Currency/Currency";
import Geo from "./Geo/Geo";
import Organization from "./Organization/Organization";
import TrafficSource from "./TrafficSource/TrafficSource";
import Industry from "./Industry/Industry";
import ProductType from "./ProductType/ProductType";
import DeviceType from "./DeviceType/DeviceType";

const MetaEntities = () => {
  const entityList = [
    { label: "Traffic Click Identifier" },
    { label: "Territory" },
    { label: "Currency" },
    { label: "Geo" },
    { label: "Organization" },
    { label: "Traffic Source" },
    { label: "Industry" },
    { label: "Product Type" },
    { label: "Device Type" },
  ];

  return (
    <>
      <Paper sx={{ padding: "8px 16px", mb: 2 }}>
        <Typography fontSize={24}>Meta Entities</Typography>
        <Typography sx={{ color: "#00000099", paddingBottom: "16px" }}>
          Create, Edit and Manage the system’s meta entities for a stremlined
          workflow
        </Typography>
        <Grid container mt={0} columnSpacing={3} mb={1}>
          <Grid item xs={10}>
            <Autocomplete
              sx={{ width: "100%" }}
              options={entityList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search entity type or choose from the list"
                  label="Select Entity Type"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              //disabled
              fullWidth
              size="large"
              color="secondary"
              sx={{ py: "7px" }}
            >
              Select Entity
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <TrafficClickIdentifier />
      <br/>
      <Territory/>
      <br/>
      <Currency/>
      <br/>
      <Geo/>
      <br/>
      <Organization/>
      <br/>
      <TrafficSource/>
      <br/>
      <Industry/>
      <br/>
      <ProductType/>
      <br/>
      <DeviceType/>
    </>
  );
};

export default MetaEntities;
