import { Chip, Grid, TableContainer, Typography } from '@mui/material'
import { Box} from '@mui/system'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./AdditionalContentTheme.css";
import { useEffect, useState } from 'react';
import { DesignThemeEndPointLogic } from '../../../../../logic/AssetManagement/Design/Theme/DesignThemeEndPointLogic';
import SimpleBackdrop from '../../../../BackDrop/BackDrop';

interface ThemeProps {
  designThemeData: any
  loading: any
  selectedThemeShareuid: any
  oauthTokenHeader: any
}

interface WidgetStructure {
  shared_uid: string;
  name: string;
  widget_meta: string;
}

function AdditionalContentTheme({designThemeData,loading, selectedThemeShareuid, oauthTokenHeader}: ThemeProps) {

  const selectedTheme = designThemeData?.find((theme: any) => theme.shared_uid === selectedThemeShareuid);
  const templates = selectedTheme?.templates;
  const [widgetStructure, setWidgetStructure] = useState<WidgetStructure[]>([]); //  State for widget structure

  useEffect(() => {
    const fetchWebsiteStructure = async() => {
      try {
        if(oauthTokenHeader) {
          await DesignThemeEndPointLogic.getWidgetStructures(oauthTokenHeader, setWidgetStructure);
        } else {
          throw new Error('Unauthorized access for template');
        }
      } catch (error) {
        console.error(error);
      }
      }
      fetchWebsiteStructure();
  }, [oauthTokenHeader]);

  const widgetNames = widgetStructure?.map(widget => widget.name);
  const ComponentNames = templates?.map((template:any) => template.component) || [];
  const availableWidgetNames = widgetNames.filter(name => !ComponentNames.includes(name));
  const labels = selectedTheme?.templates?.map((template: any) => template.labels) || [];
  const flattenedLabels = labels.flat(); // Flatten the array of arrays

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
      <Grid item xs={5}>
        <Typography>Assign Templates to Objects</Typography>
        <Box>
         <TableContainer component={Paper} className="themewrap" sx={{ maxHeight: 248, overflowY: 'auto', boxShadow:"0"}}>
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ margin: "16px" }}>#</TableCell>
                <TableCell align="left">Object Name</TableCell>
                <TableCell align="left">Assigned Template</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates?.map((themes:any, index:number) => (
                <TableRow
                  key={themes.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" >{index + 1}</TableCell>
                  <TableCell align="left" >{themes.component}</TableCell>
                  <TableCell align="left" >{themes.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
         </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Typography>Inactive Components</Typography>
        <Box>
         <TableContainer component={Paper} className="themewrap" sx={{ maxHeight: 248, overflowY: 'auto', boxShadow:"0"}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ margin: "16px" }}>#</TableCell>
                <TableCell align="left">Object Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? <SimpleBackdrop /> : availableWidgetNames?.map((widgetName:any, index:number) => (
                <TableRow
                  key={widgetName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" >{index + 1}</TableCell>
                  <TableCell align="left" >{widgetName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
         </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Typography>Available Features in Theme</Typography>
        <Box mt={1}>
        <Grid container spacing={1}>
          {flattenedLabels?.map((label: any, index: number) => (
            <Grid item key={index}>
              <Chip label={label} variant="outlined" color="secondary" sx={{ borderColor: '#9C27B0' }} />
            </Grid>
          ))}
        </Grid>
        </Box>
      </Grid>
      </Grid>
      </Box>
    </div>
  )
}

export default AdditionalContentTheme
