import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PageScanRequest from "./PageScanRequest/PageScanRequest";
import QuarterlyGoals from "./QuarterlyGoals/QuarterlyGoals";
import PerformanceDashboard from "./PerformanceDashboard/PerformanceDashboard";
import "./PageSpeedPerformanceTabs.css"
import { useState } from "react";
import { useHasPageSpeedPerformancePermission } from '../../../../logic/AssetManagement/Health/Permissions/useHasPageSpeedPerformancePermission';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PageSpeedPerformanceTabsProps {
  selectedSharedUid:any;
  oauthTokenHeader:any;
  selectedStatus:any;
  setSelectedStatus:any;
  RequestedBy:any;
  setRequestedBy:any;
  selectedDevice:any;
  setSelectedDevice:any;
  goalsRequestedBy:any;
  setGoalsRequestedBy:any;
  selectedgoalsDate:any;
  setSlectedgoalsDate:any;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PageSpeedPerformanceTabs({selectedSharedUid,oauthTokenHeader,selectedStatus,setSelectedStatus,RequestedBy,setRequestedBy,selectedDevice,setSelectedDevice,goalsRequestedBy,setGoalsRequestedBy,selectedgoalsDate,setSlectedgoalsDate}:PageSpeedPerformanceTabsProps) {
  const [value, setValue] = useState(0);

  const hasEditPermissions = useHasPageSpeedPerformancePermission([
    ModulesPermissionNames.POST_API_PSI_GOALS,
    ModulesPermissionNames.PUT_API_PSI_GOALS,
    ModulesPermissionNames.DELETE_API_PSI_GOALS,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
  <>
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              color: "#00000099", // Default color for unselected tabs
              "&.Mui-selected": {
                color: "#9C27B0", // Change color when the tab is selected
              },
            },
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-start",
            },
            "& .MuiTab-root:first-of-type": {
              paddingLeft: 0,
            }
          }}
        >
          <Tab label="Performance Dashboard" {...a11yProps(0)} />
          <Tab label="Page Scan Requests" {...a11yProps(1)} />
          <Tab label="Quarterly Goals" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PerformanceDashboard />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PageScanRequest  selectedSharedUid={selectedSharedUid} token={oauthTokenHeader} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} RequestedBy={RequestedBy} setRequestedBy={setRequestedBy}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <QuarterlyGoals selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} selectedSharedUid={selectedSharedUid} oauthTokenHeader={oauthTokenHeader} hasEditPermissions={hasEditPermissions} goalsRequestedBy={goalsRequestedBy} setGoalsRequestedBy={setGoalsRequestedBy} selectedgoalsDate={selectedgoalsDate} setSlectedgoalsDate={setSlectedgoalsDate}/>
      </CustomTabPanel> 
    </Box>
</>
  );
}
