import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DeviceTypeModal from "../DeviceTypeModal/DeviceTypeModal";

const DeviceTypeFilterCard = () => {
  const [openModal, setOpenModal] = useState(false);

  const demoLising = [
    { label: "List Item One" },
    { label: "List Item Two" },
    { label: "List Item Three" },
    { label: "List Item Four" },
    { label: "List Item Five" },
  ];

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={4}
        sx={{ paddingBlock: "16px" }}
      >
        <Autocomplete
          sx={{ width: "350px" }}
          options={demoLising.map((listname) => listname.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Device Type"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "10px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"end"}
          sx={{ width: "100%" }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenModal}
          >
            Create New Device Type <AddIcon sx={{ ml: 1 }} />
          </Button>
        </Stack>
      </Stack>
      <DeviceTypeModal open={openModal} handleClose={handleCloseModal} />
    </>
  );
};

export default DeviceTypeFilterCard;
