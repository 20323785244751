import { useEffect, useState } from "react";
import { useHasManualExpensePermission } from "../../../logic/Finance/ProviderExpense/Permission/useHasManualExpensePermission";
import { ModulesPermissionNames } from "../../../data/menu/ModulesPermissions";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import CustomTableManualExpense from "../../../components/Finance/ProvidersExpenses/ManualExpense/CustomTableManualExpenseNotes/CustomTableManualExpense";
import ManualExpenseFilter from "../../../components/Finance/ProvidersExpenses/ManualExpense/ManualExpenseFilter/ManualExpenseFilter";
import { ManualExpenseEndPointLogic } from "../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic";
import { advertisingAccountsEndPointLogic } from "../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import SimpleBackdrop from "../../../components/BackDrop/BackDrop";
import { setIsLoading } from "../../../features/userSlice";


export default function ManualExpenseScreen({ userToken ,response }: { userToken: any , response : any }) {
  const hasGetPermissions = useHasManualExpensePermission([
    ModulesPermissionNames.GET_API_EXPENSE,
    ModulesPermissionNames.GET_API_EXPENSES,
  ]);

  const hasEditPermissions = useHasManualExpensePermission([
    ModulesPermissionNames.POST_API_EXPENSE,
    ModulesPermissionNames.DELETE_API_EXPENSE,
    ModulesPermissionNames.PUT_API_EXPENSE,
    ModulesPermissionNames.POST_API_EXPENSES,
    ModulesPermissionNames.DELETE_API_EXPENSES,
    ModulesPermissionNames.PUT_API_EXPENSES,
  ]);

  const oauthTokenHeader = userToken;
  const dispatch = useDispatch();
  const [manualexpenserows, setManualexpenserows] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [trafficSources, setTrafficSources] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [selectedSource, setSelectedSource] = useState<string[]>([]);
  const [selectedExpense, setSelectedExpense] = useState<string[]>([]);
  const [fileBase64, setFileBase64] = useState('');
  const [fileName, setFileName] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [deleteRefresh, setDeleteRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedDate , setSelectedDate] = useState('');
  let manualExpenseTableDataUpdate;

  const tableHeadings = {
    post_title: "Expense Name",
    related_asset: "Related to",
    source: "Source",
    date: "Date",
    clicks: "Clicks",
    impressions: "Impressions",
    expenses: "Expenses",
    created: "Created on",
    created_by: "Created_by",
    more_info: "More info",
  };

  // Fetch manual expense data
  useEffect(() => {
    const fetchManualExpenseData = async () => {
      setLoading(true);
      try {
        if (hasGetPermissions) {
          await ManualExpenseEndPointLogic.getManualExpenseContent(
            oauthTokenHeader,
            setIsLoading,
            setManualexpenserows,
            dispatch
          )
        } else {
          throw new Error("User doesn't have permissions to fetch manual expense data");
        }
      } catch (error) {
        console.error("Error fetching manual expense data:", error);
      } finally {
        setLoading(false);
        setDeleteRefresh(false);
      }
    };

    fetchManualExpenseData();
    // eslint-disable-next-line
  }, [hasGetPermissions, oauthTokenHeader, dispatch,deleteRefresh===true]);

  // Fetch traffic sources
  useEffect(() => {
    const fetchTrafficSources = async () => {
      try {
        await advertisingAccountsEndPointLogic.getTrafficSource(
          oauthTokenHeader,
          setTrafficSources
        );
      } catch (error) {
        console.error("Error fetching traffic sources:", error);
      }
    };

    fetchTrafficSources();
    setRefresh(false);
  }, [oauthTokenHeader,refresh]);
  
   // Assume index and value are required by ManualExpenseFilter
   const [filterIndex,] = useState(0); // Example index value
   const [filterValue,] = useState(0); // Example value

   const siteNames = response.website_permissions.map((permission: any) => { 
    const key = Object.keys(permission)[0];
    return {
      site: permission[key],
      key: key,
    };
   });

   const result = [] as any;

   if (manualexpenserows?.data) {
    Object.keys(manualexpenserows.data).forEach(websiteSharedUid => {
        const items = manualexpenserows.data[websiteSharedUid];
        items.forEach((item:any) => {
            result.push({
                ...item,
                website_shared_uid: websiteSharedUid
            });
        });
    });
  }

  const manualExpense = {
    manualexpenserows,
    setManualexpenserows,
    hasEditPermissions,
    trafficSources,
    manualExpenseTableDataUpdate,
    siteNames,
    selectedAsset,
    setSelectedAsset,
    selectedSource,
    setSelectedSource,
    oauthTokenHeader,
    fileBase64,
    setFileBase64,
    setFileName,
    fileName,
    setRefresh,
    selectedRows,
    setSelectedRows,
    setIsCardVisible,
    isCardVisible,
    setSelectedExpense,
    selectedExpense,
    setDeleteRefresh,
    selectedDate,
    setSelectedDate
   }

  
  return (
    <Box>
      <ManualExpenseFilter index={filterIndex} value={filterValue} result={result} {...manualExpense}/>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <CustomTableManualExpense 
          tableHeadings={tableHeadings} 
          manualexpenserows={manualexpenserows} 
          trafficSources={trafficSources} 
          manualExpenseTableDataUpdate={manualExpenseTableDataUpdate}
          siteNames={siteNames}
          result={result}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          fileBase64={fileBase64}
          setFileBase64={setFileBase64}
          setFileName={setFileName}
          fileName={fileName}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          oauthTokenHeader={oauthTokenHeader}
          setIsCardVisible={setIsCardVisible}
          isCardVisible={isCardVisible}
          selectedExpense={selectedExpense}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
    </Box>
  );
}
