import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./SequenceModal.css";

type ProcessTableProps = {
  tableProcesses: any[];
  setTableProcesses: (processes: any[]) => void;
  handleDeleteProcess: (processId: number) => void;
};

export default function ProcessesChainTable({
  tableProcesses,
  setTableProcesses,
  handleDeleteProcess,
}: ProcessTableProps) {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    // Filter unique processes based on process__id
    const uniqueProcesses = tableProcesses.filter(
      (process, index, self) =>
        index === self.findIndex((t) => t.process__id === process.process__id)
    );
    setRows(uniqueProcesses);
  }, [tableProcesses]);

  const handleDragEnd = (e: DropResult) => {
    if (!e.destination) return;
    let tempData = Array.from(rows);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setRows(tempData);
    setTableProcesses(tempData); // Update tableProcesses state with reordered data
  };

  const moveProcessUp = (index: number) => {
    if (index === 0) return;
    const newRows = [...rows];
    const temp = newRows[index];
    newRows[index] = newRows[index - 1];
    newRows[index - 1] = temp;
    setRows(newRows);
    setTableProcesses(newRows); // Update tableProcesses state with reordered data
  };

  const moveProcessDown = (index: number) => {
    if (index === rows.length - 1) return;
    const newRows = [...rows];
    const temp = newRows[index];
    newRows[index] = newRows[index + 1];
    newRows[index + 1] = temp;
    setRows(newRows);
    setTableProcesses(newRows); // Update tableProcesses state with reordered data
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
        <TableContainer sx={{ maxHeight: 240 }}>
            <Table className="dragChainsTable" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Order</TableCell>
                  <TableCell align="left">Process Name</TableCell>
                  <TableCell align="left">Process ID</TableCell>
                  <TableCell align="center">Remove from Chain</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                    {rows.map((row: any, index: number) => (
                      <Draggable
                        key={row?.process__id}
                        draggableId={row?.process__name}
                        index={index}
                      >
                        {(provider, snapshot) => {
                          const style = {
                            ...provider.draggableProps.style,
                            width: "805px",
                            display: snapshot.isDragging ? "table" : "",
                            backgroundColor: snapshot.isDragging ? "white" : "white",
                            boxShadow: snapshot.isDragging ? "0px 0px 3px #ccc" : "",
                            borderRadius: snapshot.isDragging ? "4px" : "",
                            left: snapshot.isDragging ? "40px" : "",
                          };
                          return (
                            <TableRow
                              key={row?.process__id}
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                              style={style}
                            >
                              <TableCell>
                                <div style={{ textAlign: "center", fontSize: "20px" }}>
                                  {index + 1}
                                </div>
                              </TableCell>
                              <TableCell align="left" {...provider.dragHandleProps}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <DragIndicatorIcon
                                    sx={{
                                      color: "rgb(0 0 0 / 54%)",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {row?.process__name}
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {row?.process__id}
                                  <span className="processIdActionBtn">
                                    <Button
                                      color="secondary"
                                      disabled={index === 0}
                                      size="small"
                                      variant="outlined"
                                      onClick={() => moveProcessUp(index)}
                                    >
                                      <KeyboardArrowUpOutlinedIcon fontSize="small" />
                                    </Button>
                                    <Button
                                      color="secondary"
                                      size="small"
                                      variant="outlined"
                                      onClick={() => moveProcessDown(index)}
                                      disabled={index === rows.length - 1}
                                    >
                                      <KeyboardArrowDownOutlinedIcon fontSize="small" />
                                    </Button>
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell onClick={() => handleDeleteProcess(row?.process__id)} align="center">
                                <IconButton aria-label="delete" color="error">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </Paper>
      <Typography variant="body1" sx={{ fontSize: 13, mt: 1 }}>
        {rows?.length} Processes selected
      </Typography>
    </>
  );
}
