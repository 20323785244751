import hostname from './api/config.host';
import { fetchWrapper } from './api/fetch-wrapper';
const apiUrl: string = `${hostname}/aladdin_api/v1`;
const WpApiUrl: string = `${hostname}/wp_api/v1`;
console.log(hostname);

function getRandomTwoDigitNumber(): number {
    return Math.floor(Math.random() * 90) + 10;
  }
function getSitePages(siteUid: string, oauthTokenHeader: string) {
     return fetchWrapper.get(`${apiUrl}/asset_management/pages/${siteUid}/`, oauthTokenHeader); 
}

function getNextPages(nextUrl:any,oauthTokenHeader: string) {
    return fetchWrapper.get(`${nextUrl}`, oauthTokenHeader);
}

function getSiteCurrentWidgets(siteUid: string, oauthTokenHeader: string) {
    return fetchWrapper.get(`${WpApiUrl}/widgets/${siteUid}/`, oauthTokenHeader);
}
function getSiteAllAvailableWidgets(siteUid: string | undefined, oauthTokenHeader: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/widgets/${siteUid}/`, oauthTokenHeader);
}

function getPageContent(siteUid: string, pageUid: string, oauthTokenHeader: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/`, oauthTokenHeader);
}
function getPageUrlContent(siteUrl: string) {
    let randomNumber: number = getRandomTwoDigitNumber();
    return fetchWrapper.getUrl(`${siteUrl}/?remove_lazy=1&v=1&id=${randomNumber}`);
  }
function getPageUrlContentPreviewPage(siteUrl: string) {
    return fetchWrapper.getUrl(`${siteUrl}`);
}
function createPage(data: any, siteUid: string, oauthTokenHeader: string) {
    return fetchWrapper.post(`${apiUrl}/asset_management/pages/${siteUid}/`, data, oauthTokenHeader);
}
function duplicatePage(data: any, siteUid: string, oauthTokenHeader: string , pageUid: string) {
    return fetchWrapper.post(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/?duplicate=1/`, data, oauthTokenHeader);
}
function updatePage(data: any, siteUid: string, pageUid: string, oauthTokenHeader: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/`, data, oauthTokenHeader);
}
function updatePageGroup(data: any, siteUid: string, pageUid: string, oauthTokenHeader: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/`, data, oauthTokenHeader);
}
function savePage(data: any, siteUid: string, pageUid: string, oauthTokenHeader: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/`, data, oauthTokenHeader);
}
function getPageGroups(siteUid: string, oauthTokenHeader: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/page_groups/${siteUid}/`, oauthTokenHeader);
}
function getAdvertisingAccounts(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/advertising_management/advertising_accounts/`, oauthTokenHeader);
}
function getSingleAdvertisingAccount(account_shared_uid: string,oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/advertising_management/advertising_accounts/${account_shared_uid}/`, oauthTokenHeader);
}
function advertisingAccountsToggle(oauthTokenHeader: string,data:any,account_shared_uid:any){
    return fetchWrapper.put(`${apiUrl}/advertising_management/advertising_accounts/toggle/${account_shared_uid}/`,data, oauthTokenHeader)
}
function createETLSequence(oauthTokenHeader:string,data:any){
    return fetchWrapper.post(`${apiUrl}/admin/processes/sequences/`, data, oauthTokenHeader);
}
function getETLSequences(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/admin/processes/sequences/`, oauthTokenHeader);
}
function getProcessList(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/admin/processes/`,oauthTokenHeader)
};
function updateETLSequence(sequence_id:any, oauthTokenHeader:string,data:any){
    return fetchWrapper.put(`${apiUrl}/admin/processes/sequences/${sequence_id}/`, data, oauthTokenHeader);
}
function etlSequenceToggle(sequence_id:any,data:any,oauthTokenHeader:any){
    return fetchWrapper.put(`${apiUrl}/admin/processes/sequences/toggle/${sequence_id}/` ,data, oauthTokenHeader)
}
function deployETLSequence(data:any,oauthTokenHeader:string){
    return fetchWrapper.post(`${apiUrl}/admin/processes/deploy/`, data, oauthTokenHeader);
}
function getPagePreviewForCurrentChanges(siteUid: string, pageUid: string, data: any, oauthTokenHeader: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/?preview=1/`, data, oauthTokenHeader);
}
function deletePage(siteUid: string , page_shared_uid: string , oauthTokenHeader: string) {
    return fetchWrapper.delete(`${apiUrl}/asset_management/pages/${siteUid}/${page_shared_uid}/?delete=1`, oauthTokenHeader)
}
function imageUpload(siteUid:string , oauthTokenHeader:string , data:any) {
    return fetchWrapper.post(`${apiUrl}/asset_management/images/${siteUid}/` , data , oauthTokenHeader)
}
function discardPage(data: any, siteUid: string, pageUid: string, oauthTokenHeader: string){
    return fetchWrapper.put(`${apiUrl}/asset_management/pages/${siteUid}/${pageUid}/?discard=1`, data , oauthTokenHeader)
}
// api calles for ETL process List
function getETLProcessList(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/admin/processes/`,oauthTokenHeader)
}

function createETLProcess(data:any,oauthTokenHeader:string){
    return fetchWrapper.post(`${apiUrl}/admin/processes/`,data,oauthTokenHeader);
}

function editETLProcess(data:any,oauthTokenHeader:string){
    return fetchWrapper.put(`${apiUrl}/admin/processes/`,data,oauthTokenHeader);
}

function toggleETLProcess(process_id:number,data:any,oauthTokenHeader:string){
    return fetchWrapper.put(`${apiUrl}/admin/processes/toggle/${process_id}/`,data,oauthTokenHeader);
}

function getTrafficSource(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/meta/traffic_sources/`,oauthTokenHeader)
}
function getGeo(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/meta/geos/` , oauthTokenHeader)
}
function postGeo(oauthTokenHeader: string,data:any ){
    return fetchWrapper.post(`${apiUrl}/meta/geos/` , data,oauthTokenHeader)
}
function postIndustry(oauthTokenHeader: string,data:any ){
    return fetchWrapper.post(`${apiUrl}/meta/industries/` , data,oauthTokenHeader)
}
function getIndustries(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/meta/industries/` , oauthTokenHeader)
}
function getTerritories(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/meta/territories/` , oauthTokenHeader)
}

function createAdAccount(oauthTokenHeader:string , data:any) {
    return fetchWrapper.post(`${apiUrl}/advertising_management/advertising_accounts/` , data , oauthTokenHeader)
}

function updateAdAccount(page_shared_uid: string, oauthTokenHeader:string , data:any) {
    return fetchWrapper.put(`${apiUrl}/advertising_management/advertising_accounts/${page_shared_uid}/` , data , oauthTokenHeader)
}

function getAdAccountDetails(traffic_shared_uid: string,ad_account_id:string,oauthTokenHeader:string ){
    return fetchWrapper.get(`${apiUrl}/advertising_management/ad_account_details/?traffic_source=${traffic_shared_uid}&account_id=${ad_account_id}`,oauthTokenHeader)
 }

function postWebsiteToAccount(oauthTokenHeader: string,data:any, account_shared_uid:any ){
    return fetchWrapper.post(`${apiUrl}/advertising_management/advertising_accounts/asset_link/${account_shared_uid}/` , data,oauthTokenHeader)
}
// api calles for ETL Logs
 function getETLLogs(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/admin/processes/logs/`,oauthTokenHeader);
}
function getNextLogs(nextUrl:any,oauthTokenHeader: string) {
    return fetchWrapper.get(`${nextUrl}`, oauthTokenHeader);
}
function getETLLogById(runId:number,oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/admin/processes/logs/${runId}/`,oauthTokenHeader);
}
function runProcessLogs(ids:any,oauthTokenHeader:string){
    return fetchWrapper.post(`${apiUrl}/admin/processes/logs/`,ids,oauthTokenHeader)
}
function getDBTables(oauthTokenHeader:string,selectedSource:any,selectedDestination:any){
    return fetchWrapper.get(`${apiUrl}/admin/processes/get_tables/?source=${selectedSource}&destination=${selectedDestination}`,oauthTokenHeader)
}
function postMigrate(oauthTokenHeader:string,data:any){
    return fetchWrapper.post(`${apiUrl}/admin/processes/migrate/`,data,oauthTokenHeader)
}

function getBrands(oauthTokenHeader: string, websiteSharedUID: string[]) {
    const params = new URLSearchParams();

    // Append each website_shared_uid as a separate query parameter
    websiteSharedUID.forEach((uid) => {
      params.append('website_shared_uid', uid);
    });
  
    // Build the full URL with the parameters
    const url = `${apiUrl}/admin/processes/brands_data/?${params.toString()}`;
  
    // Fetch using the constructed URL
    return fetchWrapper.get(url, oauthTokenHeader);
  }
  

function postBrands(oauthTokenHeader:string,data:any){
    return fetchWrapper.post(`${apiUrl}/admin/processes/brands_data/`,data,oauthTokenHeader)
}

function getActivityNotes(oauthTokenHeader:string ){
    return fetchWrapper.get(`${apiUrl}/asset_management/activity_notes/`,oauthTokenHeader)
 }
function getSingleActivityNote(oauthTokenHeader:string, note_shared_uid:any ){
    return fetchWrapper.get(`${apiUrl}/asset_management/activity_notes/${note_shared_uid}/`,oauthTokenHeader)
 }
function postActivityNotes(oauthTokenHeader:string, data:any ){
    return fetchWrapper.post(`${apiUrl}/asset_management/activity_notes/`,data,oauthTokenHeader )
 }

 function updateActivityNote(oauthTokenHeader:string,data:any,note_shared_uid: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/activity_notes/${note_shared_uid}/`, data, oauthTokenHeader)
}

function getLabels(oauthTokenHeader:string ){
    return fetchWrapper.get(`${apiUrl}/utilities/labels/`,oauthTokenHeader)
 }

 function postComment(oauthTokenHeader:string , data:any, note_shared_uid:any) {
    return fetchWrapper.post(`${apiUrl}/utilities/comments/${note_shared_uid}/` , data , oauthTokenHeader)
}
 function getComment(oauthTokenHeader:string ,note_shared_uid:any) {
    return fetchWrapper.get(`${apiUrl}/utilities/comments/${note_shared_uid}/` , oauthTokenHeader)
}

function deleteActivityNote(oauthTokenHeader: string, note_shared_uid:any) {
    return fetchWrapper.delete(`${apiUrl}/asset_management/activity_notes/${note_shared_uid}/`, oauthTokenHeader)
}

// Manual Expense Endpoints

function getManualExpenseTableContent(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/finance/providers/manual_expenses/` ,oauthTokenHeader)
}

function getCurrencies(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/meta/currencies/` , oauthTokenHeader)
}

function createExpense(oauthTokenHeader: string,data:any){
    return fetchWrapper.post(`${apiUrl}/finance/providers/manual_expenses/` ,data, oauthTokenHeader)
}

function editExpense(oauthTokenHeader: string,data:any, expense_shared_uid:any){
    return fetchWrapper.put(`${apiUrl}/finance/providers/manual_expenses/${expense_shared_uid}/` ,data, oauthTokenHeader)
}

function deleteExpense(oauthTokenHeader: string, expense_shared_uid:any){
    return fetchWrapper.delete(`${apiUrl}/finance/providers/manual_expenses/${expense_shared_uid}/`, oauthTokenHeader)
}


// Design Theme Endpoints
function getAllThemes(oauthTokenHeader: string, website_shared_uid: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/design/themes/?website_shared_uid=${website_shared_uid}`, oauthTokenHeader);
};

function createTheme(oauthTokenHeader: string, data: any) {
    return fetchWrapper.post(`${apiUrl}/asset_management/design/themes/`, data, oauthTokenHeader);
}

function getSingleTheme(oauthTokenHeader: string, theme_shared_uid: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/design/themes/${theme_shared_uid}/`, oauthTokenHeader);
}

function editTheme(oauthTokenHeader: string, data: any, theme_shared_uid: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/design/themes/${theme_shared_uid}/`, data, oauthTokenHeader);
}

function requestTheme(oauthTokenHeader: any, data: any) {
    return fetchWrapper.post(`${apiUrl}/utilities/tasks/`, data, oauthTokenHeader);
}

// Quarterly Goals Endpoints

function getQuarterlyGoals(oauthTokenHeader:string ,website_shared_uid:string){
    return fetchWrapper.get(`${apiUrl}/asset_management/health/quarterly_goal/${website_shared_uid}/`,oauthTokenHeader)
}

function createQuarterlyGoals(oauthTokenHeader:any, website_shared_uid:any, data:any) {
    return fetchWrapper.post(`${apiUrl}/asset_management/health/quarterly_goal/${website_shared_uid}/`,data, oauthTokenHeader);
}

function getDevices(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/meta/devices/`,oauthTokenHeader)
}

function editQuarterlyGoals(oauthTokenHeader:any, website_shared_uid:any, data:any) {
    return fetchWrapper.put(`${apiUrl}/asset_management/health/quarterly_goal/${website_shared_uid}/`,data, oauthTokenHeader);
}

// Design Templates Endpoints

function getAllTemplates(oauthTokenHeader: string, website_shared_uid: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/design/templates/?website_shared_uid=${website_shared_uid}`, oauthTokenHeader);
};

function createTemplate(oauthTokenHeader: string, data: any) {
    return fetchWrapper.post(`${apiUrl}/asset_management/design/templates/`, data, oauthTokenHeader);
}

function getWidgetStructure(oauthTokenHeader:string){
    return fetchWrapper.get(`${apiUrl}/meta/widgets_structure`,oauthTokenHeader)
}

function getSingleTemplate(oauthTokenHeader: string, template_shared_uid: string) {
    return fetchWrapper.get(`${apiUrl}/asset_management/design/templates/${template_shared_uid}/`, oauthTokenHeader);
}

function editTemplate(oauthTokenHeader: string, data: any, template_shared_uid: string) {
    return fetchWrapper.put(`${apiUrl}/asset_management/design/templates/${template_shared_uid}/`, data, oauthTokenHeader);
}

function postCommentME(oauthTokenHeader: string,data:any,object_shared_uid:any){
    return fetchWrapper.post(`${apiUrl}/utilities/comments/${object_shared_uid}/`,data, oauthTokenHeader)
}

function fileUploadME(oauthTokenHeader: string,data:any,object_shared_uid:any){
    return fetchWrapper.post(`${apiUrl}/utilities/file/${object_shared_uid}/`,data, oauthTokenHeader)
}

function getFiles(oauthTokenHeader: string,object_shared_uid:any){
    return fetchWrapper.get(`${apiUrl}/utilities/file/${object_shared_uid}/`, oauthTokenHeader)
}

function getComments(oauthTokenHeader: string,object_shared_uid:any){
    return fetchWrapper.get(`${apiUrl}/utilities/comments/${object_shared_uid}/`, oauthTokenHeader)
}

function putComments(oauthTokenHeader: string,data:any,object_shared_uid:any){
    return fetchWrapper.put(`${apiUrl}/utilities/comments/${object_shared_uid}/`,data, oauthTokenHeader)
}
// Competitors Page Speed Endpoints
function getPsiRequestsOfCompetitors(oauthTokenHeader: string,website_shared_uid:any){
    return fetchWrapper.get(`${apiUrl}/market_positioning/pagespeed_tests/${website_shared_uid}/`, oauthTokenHeader)
}

function putPsiRequestOfCompetitors(oauthTokenHeader:string,website_shared_uid:any,psi_shared_uid:any,data:any){
    return fetchWrapper.put(`${apiUrl}/market_positioning/pagespeed_tests/${website_shared_uid}/?psi=${psi_shared_uid}`,data,oauthTokenHeader)
}

function getSerpCompetitors(oauthTokenHeader: string){
    return fetchWrapper.get(`${apiUrl}/market_positioning/serp_competitor/`, oauthTokenHeader)
}

function postCompetitors(oauthTokenHeader: string,website_shared_uid:any,data:any){
    return fetchWrapper.post(`${apiUrl}/market_positioning/pagespeed_tests/${website_shared_uid}/`, data, oauthTokenHeader);
}

function postSerpCompetitor(oauthTokenHeader: string,data:any){
    return fetchWrapper.post(`${apiUrl}/market_positioning/serp_competitor/`, data, oauthTokenHeader);
}

// page speed

function getPageViewInternalList(oauthTokenHeader: string,website_shared_uid:any){
    return fetchWrapper.get(`${apiUrl}/asset_management/health/pagespeed_tests/${website_shared_uid}/`, oauthTokenHeader)
}

function putTogglePageSpeed(oauthTokenHeader: string,data:any,website_shared_uid:any, psi_shared_uid:any){
    return fetchWrapper.put(`${apiUrl}/asset_management/health/pagespeed_tests/${website_shared_uid}/?psi=${psi_shared_uid}`,data, oauthTokenHeader)
}

function postPageSpeedInternal(oauthTokenHeader: string,data:any,website_shared_uid:any){
    return fetchWrapper.post(`${apiUrl}/asset_management/health/pagespeed_tests/${website_shared_uid}/`,data, oauthTokenHeader)
}
// Health Monitoring Endpoints

function getMonitoring(oauthTokenHeader:string ,website_shared_uid:string,start_date:any,end_date:any){
    return fetchWrapper.get(`${apiUrl}/asset_management/monitoring/?website_shared_uid=${website_shared_uid}&from=${start_date}&to=${end_date}`,oauthTokenHeader)
}

function getSystemMonitoring(oauthTokenHeader:string ,start_date:any,end_date:any){
    return fetchWrapper.get(`${apiUrl}/asset_management/monitoring/?systems=1&from=${start_date}&to=${end_date}`,oauthTokenHeader)
}

// Engagement Incentives
function getExequteQuery(oauthTokenHeader:string ,website_shared_uid:string,start_date:any,end_date:any,QUERY_ID:any){
    return fetchWrapper.get(`${apiUrl}/biz_layer_query/run/${QUERY_ID}/?start_date=${start_date}&end_date=${end_date}&website_shared_uid=${website_shared_uid}`,oauthTokenHeader)
}

function postExequteQuery(oauthTokenHeader:string ,data:any){
    return fetchWrapper.post(`${apiUrl}/advertising_management/incentivezed_traffic/`,data,oauthTokenHeader)
}

function putExequteQuery(oauthTokenHeader:string ,data:any,itb_shared_uid:any){
    return fetchWrapper.put(`${apiUrl}/advertising_management/incentivezed_traffic/?itb_shared_uid=${itb_shared_uid}`,data,oauthTokenHeader)
}


export const endpointsService = {
    getSitePages,
    getNextPages,
    getPageContent,
    getSiteCurrentWidgets,
    getSiteAllAvailableWidgets,
    getPageUrlContent,
    createPage,
    getPageUrlContentPreviewPage,
    updatePage,
    getPageGroups,
    getAdvertisingAccounts,
    getSingleAdvertisingAccount,
    createETLSequence,
    getETLSequences,
    getProcessList,
    updateETLSequence,
    etlSequenceToggle,
    deployETLSequence,
    getPagePreviewForCurrentChanges,
    advertisingAccountsToggle,
    getTrafficSource,
    getAdAccountDetails,
    deletePage,
    imageUpload,
    duplicatePage,
    discardPage,
    savePage,
    getETLProcessList,
    createETLProcess,
    editETLProcess,
    toggleETLProcess,
    getGeo,
    getIndustries,
    createAdAccount,
    getTerritories,
    getCurrencies,
    postGeo,
    postIndustry,
    updateAdAccount,
    updatePageGroup,
    postWebsiteToAccount,
    getManualExpenseTableContent,
    getActivityNotes,
    getSingleActivityNote,
    postActivityNotes,
    getETLLogs,
    getETLLogById,
    getNextLogs,
    updateActivityNote,
    getLabels, 
    postComment,
    getComment,
    fileUploadME,
    deleteActivityNote,
    getFiles,
    runProcessLogs,
    createExpense,
    editExpense,
    getAllThemes,
    createTheme,
    getSingleTheme,
    editTheme,
    requestTheme,
    getQuarterlyGoals,
    createQuarterlyGoals,
    editQuarterlyGoals,
    getAllTemplates,
    createTemplate,
    getWidgetStructure,
    getSingleTemplate,
    editTemplate,
    getDevices,
    postCommentME,
    getComments,
    putComments,
    getDBTables,
    postMigrate,
    getBrands,
    postBrands,
    getPsiRequestsOfCompetitors,
    putPsiRequestOfCompetitors,
    getSerpCompetitors,
    postCompetitors,
    postSerpCompetitor,
    getPageViewInternalList,
    putTogglePageSpeed,
    postPageSpeedInternal,
    getMonitoring,
    getSystemMonitoring,
    getExequteQuery,
    postExequteQuery,
    putExequteQuery,
    deleteExpense
}
