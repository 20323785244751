import React, { useState } from "react";
import Box from "@mui/material/Box";
import FilterComponent from "../../../../ReusableFIlterComponent/FilterComponent";


interface TemplateFilterProps {
  designTemplatesData: any;
  clearSelectedTemplate: any;
  selectedFeatures:any;
  selectedComponents:any;
  setSelectedFeatures:any;
  setSelectedComponents:any;
}

export default function TemplatesFilter({ designTemplatesData,clearSelectedTemplate, selectedFeatures, selectedComponents, setSelectedFeatures, setSelectedComponents }: TemplateFilterProps) {

  const [searchComponent,setSearchComponent] = useState('');
  const [searchFeature,setSearchFeature] = useState('');

  const filterConfig = [
    {
      key: 'features',
      label: 'Select labels',
      searchTerm: searchFeature,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchFeature(e.target.value),
      selectedOption: selectedFeatures,
      filterName: 'labels',
      data: designTemplatesData,
      onSelectionChange: (updatedSelection: any) => setSelectedFeatures(updatedSelection),
      count: selectedFeatures && selectedFeatures.length,
      colorSelected: selectedFeatures !== null && selectedFeatures.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    },
    {
      key: 'component',
      label: 'Select component',
      searchTerm: searchComponent,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchComponent(e.target.value),
      selectedOption: selectedComponents,
      filterName: 'component',
      data: designTemplatesData,
      onSelectionChange: (updatedSelection: any) => setSelectedComponents(updatedSelection),
      count: selectedComponents && selectedComponents.length,
      colorSelected: selectedComponents !== null && selectedComponents.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    }
  ];
 
  const clearFilters = () => {
    clearSelectedTemplate(null)
    setSelectedComponents(null)
    setSelectedFeatures(null)
  };

  return (
    <Box>
      <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}
