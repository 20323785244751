import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface QuarterlyGoalsModalProps {
  open: boolean;
  handleClose: () => void;
}

export default function QuarterlyGoalsDrawer({
  open,
  handleClose,
}: QuarterlyGoalsModalProps) {
  const list = () => (
    <Box
      sx={{ width: 260, padding: "16px" }}
      role="presentation"
      onKeyDown={handleClose} // Close drawer on keyboard interaction
    >
      <Stack flexDirection={"row"} alignItems={"center"} gap={3}>
        <CloseIcon color="secondary" onClick={handleClose} sx={{cursor:"pointer"}}/>
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "32px",
            letterSpacing: "0.15",
            fontWeight: "500",
          }}
        >
          Goals index
        </Typography>
      </Stack>
      {/* Box for LCP */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          LCP
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          Reports the render time of the largest image or text block visible in
          the viewport, relative to when the user first navigated to the page.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            2.5 S
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            4 S
          </Typography>
        </Stack>
      </Box>
      {/* Box for INP */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          INP
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          Measures a page's responsiveness by tracking the latency of all user
          interactions (clicks, taps, and keyboard) during a visit.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            200 MS
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            500 MS
          </Typography>
        </Stack>
      </Box>
      {/* Box for CLS */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          CLS
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          A measure of the largest burst of unexpected layout shifts during a
          page's lifecycle.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            0.1 MS
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            0.25 MS
          </Typography>
        </Stack>
      </Box>
      {/* Box for FCP */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          FCP
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          Measures the time from navigation to the first content rendering on
          the screen.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            1.8 S
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            3 S
          </Typography>
        </Stack>
      </Box>
      {/* Box for FID */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          FID
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          Measures the time from a user's first interaction with a page to when
          the browser starts processing event handlers.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            100 MS
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            300 MS
          </Typography>
        </Stack>
      </Box>
      {/* Box for LCP */}
      <Box paddingTop={2}>
        <Typography color="secondary" fontWeight={"bold"}>
          TTFB
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.4",
            fontWeight: "400",
          }}
        >
          Measures the time between the request for a resource and when the
          first byte of a response begins to arrive.
        </Typography>
        <Stack flexDirection={"row"} marginTop={1}>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#4CAF50",
              color: "white",
              fontSize: "12px",
            }}
          >
            Good
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#FF9800",
              color: "white",
              fontSize: "12px",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            MEDICORE
          </Box>
          <Box
            sx={{
              padding: "8px 18.5px",
              background: "#EF5350",
              color: "white",
              fontSize: "12px",
            }}
          >
            POOR
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"center"} gap={9}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            800 MS
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.4",
              fontWeight: "400",
            }}
          >
            1800 MS
          </Typography>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{ zIndex: "99999" }}
      anchor="right"
      open={open} // Use the prop directly
      onClose={handleClose} // Call the handleClose function when closing
    >
      {list()}
    </Drawer>
  );
}
