import {
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TableBody,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import "./AdditionalPageGroupContent.css";
  import { Alert } from "@mui/material";
  import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
  import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
  
  function createTheme(Version: number, name: string, proportion: number) {
    return { Version, name, proportion };
  }
  
  function createPopup(
    Version: number,
    name: string,
    DesktopContent: string,
    MobileContent: string,
    Proportion: number
  ) {
    return { Version, name, DesktopContent, MobileContent, Proportion };
  }
  
  function createDefaultMeta(
    device: string,
    secondheadline: string,
    banneriamge: string
  ) {
    return { device, secondheadline, banneriamge };
  }
  
  function createComparisonTable(
    number: number,
    tablename: string,
    status: string
  ) {
    return { number, tablename, status };
  }
  
  function createAssignedTrafficSource(
    number: number,
    sourcename: string,
    status: string
  ) {
    return { number, sourcename, status };
  }
  
  const themerow = [
    createTheme(1, "Uk-generic", 50),
    createTheme(2, "Uk-generic New", 50),
  ];
  
  const popuprow = [
    createPopup(
      1,
      "Football Popup",
      "New Football Popup",
      "Mobile Football Popup",
      60
    ),
    createPopup(
      2,
      "Generic Popup",
      "Main Desktop Popup V2",
      "Main Mobile New ",
      40
    ),
  ];
  
  const defaultmetarow = [
    createDefaultMeta(
      "Desktop",
      "Example of Second Headline",
      "image_name.png"
    ),
    createDefaultMeta(
      "Tablet",
      "Example of Second Headline",
      "image_name.png"
    ),
    createDefaultMeta(
      "Mobile",
      "Example of Second Headline",
      "image_name.png"
    ),
  ];
  
  const AssignedComparisonTablerow = [
    createComparisonTable(1, "Casino Mini", "Active"),
    createComparisonTable(2, "Horse Racing", "Inactive"),
    createComparisonTable(3, "Desktop", "Active"),
  ];
  
  const createAssignedTrafficSourcerow = [
    createAssignedTrafficSource(1, "Casino Mini", "Active"),
    createAssignedTrafficSource(2, "Horse Racing", "Inactive"),
    createAssignedTrafficSource(3, "Desktop", "Active"),
  ];
  
  function AdditionalPageGroupContent() {
    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={3} md={3}>
              <Box marginBottom={4}>
                <Typography>Theme</Typography>
                <TableContainer className="pagegroupwrap">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" sx={{ margin: "16px" }}>
                          V
                        </TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Proportion</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {themerow.map((themerow) => (
                        <TableRow
                          key={themerow.Version}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {themerow.Version}
                          </TableCell>
                          <TableCell align="left">{themerow.name}</TableCell>
                          <TableCell align="left">
                            {themerow.proportion}%
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={7} md={7}>
              <Box>
                <Typography>Popup</Typography>
                <TableContainer className="pagegroupwrap">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">V</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Desktop Content</TableCell>
                        <TableCell align="left">Mobile Content</TableCell>
                        <TableCell align="left">Proportion</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {popuprow.map((popuprow) => (
                        <TableRow
                          key={popuprow.Version}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {popuprow.Version}
                          </TableCell>
                          <TableCell align="left">{popuprow.name}</TableCell>
                          <TableCell align="left">
                            {popuprow.DesktopContent}
                          </TableCell>
                          <TableCell align="left">
                            {popuprow.MobileContent}
                          </TableCell>
                          <TableCell align="left">
                            {popuprow.Proportion}%
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography>Assigned Comparison Tables</Typography>
                <TableContainer className="pagegroupwrap">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">Table Name</TableCell>
                        <TableCell align="left">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {AssignedComparisonTablerow.map(
                        (AssignedComparisonTablerow) => (
                          <TableRow
                            key={AssignedComparisonTablerow.number}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {AssignedComparisonTablerow.number}
                            </TableCell>
                            <TableCell align="left">
                              {AssignedComparisonTablerow.tablename}
                            </TableCell>
                            <TableCell align="left">
                              {AssignedComparisonTablerow.status === "Active" ? (
                                <Alert
                                  sx={{ padding: "2px", width:"75%",fontSize:"13px", height:"25px", display:"flex", alignItems:"center",backgroundColor: "#EDF7ED", border:"1px solid #2E7D3280",color:"#1E4620"}}
                                  icon={<CheckCircleOutlineRoundedIcon fontSize="small" sx={{color:"#1E4620"}}/>}
                                >
                                  {AssignedComparisonTablerow.status}
                                </Alert>
                              ) : (
                                <Alert
                                  sx={{ padding: "2px" ,width:"75%",fontSize:"13px", display:"flex", alignItems:"center",height:"25px",backgroundColor: "#E0E0E0", border:"1px solid #9E9E9E",color:"#00000099" }}
                                  icon={<CancelOutlinedIcon fontSize="small" sx={{color:"#00000099"}}/>}
                                >
                                  {AssignedComparisonTablerow.status}
                                </Alert>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography>Assigned Traffic Sources</Typography>
                <TableContainer className="pagegroupwrap">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">Source Name</TableCell>
                        <TableCell align="left">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {createAssignedTrafficSourcerow.map(
                        (createAssignedTrafficSourcerow) => (
                          <TableRow
                            key={createAssignedTrafficSourcerow.number}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {createAssignedTrafficSourcerow.number}
                            </TableCell>
                            <TableCell align="left">
                              {createAssignedTrafficSourcerow.sourcename}
                            </TableCell>
                            <TableCell align="left">
                              {createAssignedTrafficSourcerow.status ===
                              "Active" ? (
                                <Alert
                                  severity="success"
                                  sx={{ padding: "2px" ,width:"75%", height:"25px",fontSize:"13px", display:"flex", alignItems:"center",backgroundColor: "#EDF7ED", border:"1px solid #2E7D3280",color:"#1E4620"}}
                                  icon={<CheckCircleOutlineRoundedIcon fontSize="small" sx={{color:"#1E4620"}}/>}
                                >
                                  {createAssignedTrafficSourcerow.status}
                                </Alert>
                              ) : (
                                <Alert
                                  sx={{ padding: "2px" ,width:"75%",fontSize:"13px", display:"flex", alignItems:"center",height:"25px",backgroundColor: "#E0E0E0", border:"1px solid #9E9E9E",color:"#00000099"}}
                                  icon={<CancelOutlinedIcon fontSize="small" sx={{color:"#00000099"}}/>}
                                >
                                  {createAssignedTrafficSourcerow.status}
                                </Alert>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box>
                <Typography>Default Meta Settings</Typography>
                <TableContainer className="pagegroupwrap">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Device</TableCell>
                        <TableCell align="left">Second Headline</TableCell>
                        <TableCell align="left">Banner Image</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {defaultmetarow.map((defaultmetarow) => (
                        <TableRow
                          key={defaultmetarow.device}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {defaultmetarow.device}
                          </TableCell>
                          <TableCell align="left">
                            {defaultmetarow.secondheadline}
                          </TableCell>
                          <TableCell align="left">
                            {defaultmetarow.banneriamge}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
  
  export default AdditionalPageGroupContent;
  