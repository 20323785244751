import {Grid } from "@mui/material";
import React from "react";
import PerformanceDashboardOverallScoreTrend from "./PerformanceDashboardOverallScoreTrend";
import PerformanceDashboardOverallScoreGraph from "./PerformanceDashboardOverallScoreGraph";


const PerformanceDashboardOverallScore = () => {
  return (
    <>
      <Grid container spacing={3} mb={2} mt={0} className="overallScoreWrap">
        <Grid item xs={6}>
          <PerformanceDashboardOverallScoreGraph/>
        </Grid>
        <Grid item xs={6}>
          <PerformanceDashboardOverallScoreTrend/>
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceDashboardOverallScore;
