
import { useEffect } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import './ErrorMessage.css';
// import { error } from 'console';
export interface ErrorMessageProps {
  type: AlertColor | undefined;
  message: any;
  handleClose: any;
  autoHideDuration?: number;
}
export default function ErrorMessage({
  message,
  type,
  handleClose,
  autoHideDuration = 4000,
}: ErrorMessageProps) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, autoHideDuration);
    return () => clearTimeout(timeoutId);
  }, [autoHideDuration, handleClose]);
  return (
    <Snackbar
      open={message && Object.keys(message).length > 0} // Open the Snackbar only when there's a message
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning of Snackbar
    >
      {message?.reason !== undefined ? <Alert className="cookieAlert" severity={type} sx={{ width: '100%', marginBottom: 1 }} onClose={handleClose}>
      <>
      {message?.reason}
      </>
    </Alert> : <Alert className="cookieAlert" severity={type} sx={{ width: '100%', marginBottom: 1 }} onClose={handleClose}>
      <>
      {message}
      </>
    </Alert>}
    </Snackbar>
  );
}