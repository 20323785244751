import React from 'react'
import CompetitorsPageSpeedDashboardFilter from './CompetitorsPageSpeedDashboardFilter/CompetitorsPageSpeedDashboardFilter'
import CompetitorsPageSpeedDashboardGraph from './CompetitorsPageSpeedDashboardGraph/CompetitorsPageSpeedDashboardGraph'
import { Divider } from '@mui/material'

const CompetitorsPageSpeedDashboard = () => {
  return (
    <>
      <CompetitorsPageSpeedDashboardFilter/>
      <Divider />
      <CompetitorsPageSpeedDashboardGraph/>
    </>
  )
}

export default CompetitorsPageSpeedDashboard