import {Alert, Box, Button, Checkbox, Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Grid,Pagination,Paper,Snackbar,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TableSortLabel, TextField, Typography} from "@mui/material";
import { useEffect, useState , useMemo} from "react";
import { customTableHelpers } from "./customTableHelpers";
import "./customTable.css";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import { WebsiteWithUuid } from "../../data/website/Website";
import { pageBuildingEndPointLogic } from "../../logic/AssetManagement/PageManagement/PageBuilding/pageBuildingEndPointLogic";
import { setIsLoading } from "../../features/userSlice";
import { createPageEndPointLogic } from "../../logic/AssetManagement/PageManagement/CreatePage/createPageEndPointLogic";
import { trackPromise } from "react-promise-tracker";
import Tooltip from '@mui/material/Tooltip';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Avatar from '@mui/material/Avatar';
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LightbulbCircleOutlinedIcon from '@mui/icons-material/LightbulbCircleOutlined';
import EditPageMeta from "./EditPageMeta";
import AssignPageGroup from "../AssetManagement/PageManagement/AssignPageGroup/AssignPageGroup";
import { sitePageEndPointLogic } from "../../logic/AssetManagement/PageManagement/SitePages/sitePagesEndPointLogic";
import store from "../../store";

export interface CustomTableProps {
  sitePages: any;
  headCells: any;
  selectedPage: string | undefined;
  selectedSite: WebsiteWithUuid;
  hasEditPermissions: boolean;
  oauthTokenHeader:string;
  siteUid:string;
  duplicatePageResponse:any;
  setDuplicatePageResponse:any[];
  deletePageData:any;
  setDeletePageData:any;
  selectedOptionGroup: string,
  selectedOptionStatus: string,
  selectedOptionEdit: string,
  selectedPageOwners: string[];
  searchText:any;
  setSearchText:any;
  setIsAssignPageGroupOpen: any;
  setIsAssignPageGroupEnable:any
  setPageDetails:any
  setIsCheckboxCheckedArray:any
  isCheckboxCheckedArray:any
  setRefresh:any
  assignPageGroup:any
  pageGroupForAssign:any
  pageGroups:any
  pageDetails:any
  assignPageGroupDialogOpen:any
  setAssignPageGroupDialogOpen:any;
  setIsMetaUpdated:any;
  isMetaUpdated:any;
  setSelectedDate:any;
  selectedDate:any;
}
export default function CustomTable({
  headCells,
  selectedPage,
  sitePages,
  selectedSite,
  hasEditPermissions,
  oauthTokenHeader,
  siteUid,
  duplicatePageResponse,
  setDuplicatePageResponse,
  deletePageData,
  setDeletePageData,
  selectedOptionGroup,
  selectedOptionEdit,
  selectedOptionStatus,
  selectedPageOwners,
  searchText, 
  setSearchText,
  setIsAssignPageGroupOpen,
  setIsAssignPageGroupEnable,
  setPageDetails,
  setIsCheckboxCheckedArray,
  isCheckboxCheckedArray,
  setRefresh,
  assignPageGroup,
  pageGroupForAssign,
  pageGroups,
  pageDetails,
  assignPageGroupDialogOpen,
  setAssignPageGroupDialogOpen,
  setIsMetaUpdated,
  isMetaUpdated,
  setSelectedDate,
  selectedDate
}: CustomTableProps) {

  const getTimestamp = () => new Date().getTime();  // Or use a static counter that increments
  const reloadJavaScriptAndCSS = () => {
    const version = getTimestamp();
  
    const scripts = document.querySelectorAll('script[src*="main"], script[src*="bundle"]');
    scripts.forEach((element) => {
      const oldScript = element as HTMLScriptElement; // Cast to HTMLScriptElement
      const newScript = document.createElement('script');
      newScript.src = oldScript.src.split('?')[0] + '?v=' + version;  // Adding a timestamp to bust cache
      newScript.async = true;
  
      newScript.onload = () => {
        if (oldScript.parentNode) {
          oldScript.parentNode.replaceChild(newScript, oldScript);
        }
      };
  
      document.head.appendChild(newScript);
    });
  
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    links.forEach((element) => {
      const oldLink = element as HTMLLinkElement; // Cast to HTMLLinkElement
      const newLink = document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.href = oldLink.href.split('?')[0] + '?v=' + version;
  
      newLink.onload = () => {
        if (oldLink.parentNode) {
          oldLink.parentNode.replaceChild(newLink, oldLink);
        }
      };
  
      document.head.appendChild(newLink);
    });
  };

  const originalSitePages = useMemo(() => {
    return sitePages || [];
  }, [sitePages]);


  const siteName = selectedSite &&  Object.values(selectedSite)[0].site_name;
  const siteURL = selectedSite &&  Object.values(selectedSite)[0].site_url;
  const [rows, setRows] = useState(
    originalSitePages ? originalSitePages : null
  );
/*   const [isCheckboxCheckedArray, setIsCheckboxCheckedArray] = useState(
    Array(originalSitePages ? originalSitePages.length : 0).fill(false)
  ); */
  const [page, setPage] = useState(sitePages?.page - 1 || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  const [selectedPageToDelete, setSelectedPageToDelete] = useState([]);
  const [selectedPageToDuplicate, setSelectedPageToDuplicate] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [, setPageStructure] = useState([]);
  const [, setPageDictionary] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  /* const [snackbarMessage, setSnackbarMessage] = useState(""); */

  const [pageTitle, setPageTitle] = useState<string>();
  const [openDuplicateDialogBox, setOpenDuplicateDialogBox] = useState(false)
 
  const [, setErrorAccToPageStatus] = useState(false)
  const [, setRetry] = useState(false)
  const [, setOpen] = useState(true);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: any
  ) => {
    setPage(newPage);
  };

 const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCheckboxChange = (index: number, page:any) => {
    const updatedCheckboxState = [...isCheckboxCheckedArray];
    updatedCheckboxState[index] = !updatedCheckboxState[index];
    setIsCheckboxCheckedArray(updatedCheckboxState);
    setPageDetails((prevDetails:any) => {
      const exists = prevDetails.some((detail:any) => detail.id === page.id);
      const pageWithIndex = { ...page, index };
      if (exists) {
        return prevDetails.filter((detail:any) => detail.id !== page.id);
      } else {
        return [...prevDetails, pageWithIndex];
      }
    });
    
  };

  const handleHeaderCheckboxChange = () => {
    const updatedCheckboxState = Array(rows ? rows.length : 0).fill(
      !isHeaderCheckboxChecked
    );
    setIsHeaderCheckboxChecked(!isHeaderCheckboxChecked);
    setIsCheckboxCheckedArray(updatedCheckboxState);
  };

  useEffect(() => {
    if (selectedPage) {
      const searchedResult = customTableHelpers.requestSearch(
        selectedPage,
        originalSitePages
      );
      setRows(searchedResult);
      setPage(0);
    } else {
      setRows(originalSitePages);
    }
    // eslint-disable-next-line
  }, [selectedPage]);
  useEffect(() => {
    setRows(originalSitePages);
    setPage(0);
  }, [siteURL, originalSitePages ,duplicatePageResponse ]);

 const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isDuplicateConfirmationOpen, setIsDuplicateConfirmationOpen] = useState(false);
  const selectedPageValue = Object.values(selectedPageToDelete);
  const selectedPageValueDuplicate = Object.values(selectedPageToDuplicate)
  const shareUid = selectedPageValue[10];
  const shareUidDuplicate = selectedPageValueDuplicate[10];
  const [showDuplicateErrorDialog, setShowDuplicateErrorDialog] = useState(false);
  const [, setIsDuplicateSuccessful] = useState(false);
  const [editPageMetaOpen, setEditPageMetaOpen] = useState(false);
// states for editing page meta
  const [singlePageTitle, setSinglePageTitle] = useState<string>();
  const [pageStatus, setPageStatus] = useState(null);
  const [pageType, setPageType] = useState(null);
  const [pageUid , setPageUid] = useState(null);

  const uniquePostStatuses = originalSitePages.reduce((acc: string[], item: any) => {
    if (item.post_status !== "pending" && !acc.includes(item.post_status)) {
      acc.push(item.post_status);
    }
    return acc;
  }, ["publish", "bin", "draft","pending"]);
  
  
  const uniquePostTypes = originalSitePages.reduce((acc: string[], item: any) => {
    if (!acc.includes(item.post_type)) {
      acc.push(item.post_type);
    }
    return acc;
  }, []);
  
  const data = {
    "data": {
      "post_title":`${pageTitle}`
  }
  }

  // State to manage the sorting key and direction
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({ keytoSort: undefined, direction: undefined });

  // Headers for the table columns with id, KEY for sorting, and LABEL for display
  const tableheaders = [
    { id: 1, KEY: 'post_name', LABEL: 'Name', sortable: true },
    { id: 2, KEY: 'post_title', LABEL: 'Title', sortable: true },
    { id: 3, KEY: 'page_group_name', LABEL: 'Group', sortable: true },
    { id: 4, KEY: 'post_type', LABEL: 'Type', sortable: false },
    { id: 5, KEY: 'post_id', LABEL: 'Page Id', sortable: true },
    { id: 6, KEY: 'post_status', LABEL: 'Status', sortable: true },
    { id: 7, KEY: 'modified_date', LABEL: 'Date Modified', sortable: true },
    { id: 8, KEY: 'modified_by', LABEL: 'Edited By', sortable: true },
    { id: 9, KEY: 'creating_user', LABEL: 'Page Owner', sortable: true },
    { id: 10, KEY: 'Actions', LABEL: 'Quick Actions', sortable: false },
  ];

  // Function to handle click on table headers
  function handleHeaderClick(header: any) {
    if (!header.sortable) return; // Ignore click if column is not sortable

    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  }
  // Function to get sorted data based on current sort state
  function getSortedData(arrayToSort: any[]) {
    // If no sorting key is set, return the original array
    if (!sort.keytoSort || !sort.direction) {
      return arrayToSort;
    }

    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
      // Check if sort.keytoSort is not undefined and has the property
      const keytoSort = sort.keytoSort;

      if (keytoSort === undefined) {
        return 0; // Return 0 if the sorting key is undefined
      }

      const aValue = a[keytoSort];
      const bValue = b[keytoSort];

      // Handle undefined values for sorting
      if (aValue === undefined) {
        return 1; // Move undefined values to the end
      }
      if (bValue === undefined) {
        return -1; // Move undefined values to the end
      }

      // Handle "N/A" values for sorting
      if (aValue === "") {
        return 1; // Move "N/A" values to the end
      }
      if (bValue === "") {
        return -1; // Move "N/A" values to the end
      }

      // Perform sorting based on the direction and type of values
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // String comparison (localeCompare for correct string sorting)
        return sort.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        // Numeric comparison
        return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (aValue instanceof Date && bValue instanceof Date) {
        // Date comparison
        return sort.direction === 'asc'
          ? aValue.getTime() - bValue.getTime()
          : bValue.getTime() - aValue.getTime();
      } else {
        // Fallback to string comparison if values are of different types
        return sort.direction === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }
    });
  }
  const sortedRows = getSortedData(rows || []); // Use getSortedData to sort the rows

useEffect(() => {
    const getPageContent = async () => {
      try {
        await pageBuildingEndPointLogic.getPageContent(siteUid, shareUidDuplicate, oauthTokenHeader, setPageContent, setPageStructure, setPageDictionary, setIsLoading,setErrorAccToPageStatus,setRetry,setOpen, trackPromise);
      } catch (error) {
        console.error("Error fetching page content:", error);
      }
    };

    if (isDuplicateConfirmationOpen && isLoaderVisible) {
      getPageContent();
      setIsLoaderVisible(false); // loading is disable here.
    }
  }, [isDuplicateConfirmationOpen, isLoaderVisible, siteUid, shareUidDuplicate, oauthTokenHeader, setPageContent, setPageStructure, setPageDictionary]);

  // Filter the data based on the selected options

  useEffect(() => {
    const filteredData = sitePages?.filter((option: any) => {
      // If selectedDate contains two dates and they're the same, we check for equality with that date
      const isDateInRange = selectedDate.length === 2 && selectedDate[0] && selectedDate[1]
        ? selectedDate[0] === selectedDate[1] // If both dates are the same, check for equality
          ? new Date(option?.modified_date).toDateString() === new Date(selectedDate[0]).toDateString()
          : new Date(option?.modified_date) >= new Date(selectedDate[0]) && new Date(option?.modified_date) <= new Date(new Date(selectedDate[1]).setHours(23, 59, 59, 999))
        : true;
  
      return (
        ((!searchText || option?.post_title?.toLowerCase().includes(searchText?.toLowerCase())) ||
        (!searchText || option?.preview_link?.toLowerCase().includes(searchText?.toLowerCase()))) &&
        (!selectedOptionGroup.length || selectedOptionGroup.includes(option?.page_group_name)) &&
        (!selectedOptionEdit.length || selectedOptionEdit.includes(option?.modified_by)) &&
        (!selectedOptionStatus.length || selectedOptionStatus.includes(option?.post_status)) &&
        (!selectedPageOwners.length || selectedPageOwners.includes(option?.modified_by)) &&
        isDateInRange
      );
    }).map((option: any, index: any) => ({
      id: index + 1,
      post_name: option?.post_name,
      post_title: option?.post_title,
      page_group_name: option?.page_group_name ? option?.page_group_name : "",
      post_type: option?.post_type,
      post_id: option?.post_id ? option?.post_id : "",
      creating_user: option?.creating_user ? option?.creating_user : "",
      modified_date: option?.modified_date ? option?.modified_date : "",
      post_status: option?.post_status ? option?.post_status : "",
      modified_by: option?.creating_user ? option?.creating_user : "",
      shared_uid: option?.shared_uid,
      preview_link: option?.preview_link
    }));
  
    setRows(filteredData);
    setIsCheckboxCheckedArray(new Array(filteredData.length).fill(false));
  }, [sitePages, selectedOptionGroup, selectedDate, selectedOptionEdit, selectedOptionStatus, selectedPageOwners, setIsCheckboxCheckedArray, searchText]);
   // Update the filtered data when the selected options change
  
  useEffect(() => {
    setPage(0); // Reset page to 0 when rows change
  }, [rows]);

  useEffect(() => {
    setIsCheckboxCheckedArray([]);
    setPageDetails([]);
    // eslint-disable-next-line
  }, [page])

  // Function to open confirmation dialog
  const openDeleteConfirmation = (page:any) => {
    setSelectedPageToDelete(page);
    setIsDeleteConfirmationOpen(true);
  };

  const openDuplicateConfirmation = async (page:any) => {
     setSelectedPageToDuplicate(page);
     /* setSelectedPageToDuplicatePageName(page.post_name) */
     setIsDuplicateConfirmationOpen(true);
     setPageTitle(`${page?.post_title}-copy`);
  };

// Function to close confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setIsDuplicateConfirmationOpen(false);
  };

  const deletePage = async () => {
    try {
      // Perform data fetching for
       await pageBuildingEndPointLogic.deletePage(
       siteUid,
       shareUid,
       oauthTokenHeader,
       setIsLoading,
       setDeletePageData
      );

    } catch (error) {
      // Handle errors
      console.error("Error Deleting Accounts:", error);
    }
  };

  useEffect(() => {
    const trueCount = Array.isArray(isCheckboxCheckedArray) ? isCheckboxCheckedArray.filter(value => value === true).length : [];
    if (trueCount !== 0) {
      setIsAssignPageGroupEnable(false)
    }else{
      setIsAssignPageGroupEnable(true)
    }
  }, [setIsAssignPageGroupEnable, isCheckboxCheckedArray])

  // Function to handle deletion
  const handleDelete = async () => {
    setIsDeleteConfirmationOpen(false);
    try {
      store.dispatch(setIsLoading(true));
      await deletePage();
      closeDeleteConfirmation();
      setShowSuccessPopup(true);
    } catch (error) {
      // Handle errors
      console.error("Error deleting page:", error);
    }
  };

  //Function to dupliacate the page
  const duplicatePage = async () => {
    try {
      await createPageEndPointLogic.duplicatePage(siteUid,data,setIsLoading,trackPromise,oauthTokenHeader,setDuplicatePageResponse,shareUidDuplicate, setShowDuplicateErrorDialog);
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  }

  const handleRetryDuplicate = () => {
    setShowDuplicateErrorDialog(false); // Close the error dialog
    setOpenDuplicateDialogBox(true); // Open the duplicate page dialog again
  };

  const handleCloseDuplicateDialogBox = () => {
    setOpenDuplicateDialogBox(false);
  };

  const handleDuplicate = () => {
    setIsDuplicateConfirmationOpen(false); // Close the duplicate confirmation dialog
    setOpenDuplicateDialogBox(true); // Open the duplicate page dialog
  };

  const validate = async () => {
    if (!pageTitle) {
      console.error("Missing Mandatory Fields");
    } else {
      setOpenDuplicateDialogBox(false); // Close the duplicate page dialog
      try {
        setIsLoading(true);
        await duplicatePage();
        closeDeleteConfirmation(); // Close any delete confirmation dialogs
        setIsDuplicateSuccessful(true); // Set state to indicate successful duplication
      } catch (error) {
        // Handle error scenarios
        setShowDuplicateErrorDialog(true); // Show error dialog for duplicate page title
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Handle snackbar close
  const handleSnackbarClose = () => {
    setShowSuccessPopup(false);
    // Optionally clear snackbar message state
  };

  function formatDate(dateString:any) {
    if (!dateString) return '';
    
    // Split the date and time parts
    const [datePart, timePart] = dateString.split(' ');
  
    // Parse the date part
    const date = new Date(datePart);
  
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
  
    // Return formatted date with time
    return `${day}-${month}-${year} ${timePart}`;
  }


  // Function to render the snackbar for delete
  const renderSnackbar = () => {
    return (
      <Snackbar
      sx={{zIndex:"9999999"}}
        open={showSuccessPopup}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {
          deletePageData !== "" ?
        <Alert onClose={handleSnackbarClose} severity="success" variant="filled">
        {"Page deleted successfully!"}
        </Alert> :
        <Alert onClose={handleSnackbarClose} severity="warning" variant="filled">
        {"Error deleting page."}
        </Alert>
        }
      </Snackbar>
    );
  };
  const handleTuneIconClick=async(page:any)=>{
    await sitePageEndPointLogic.getPageContent(siteUid,page?.shared_uid,oauthTokenHeader,setPageContent,setIsLoading)
    setEditPageMetaOpen(true);
    setSinglePageTitle(page?.post_title);
    setPageType(page?.post_type);
    setPageStatus(page?.post_status);
    setPageUid(page?.shared_uid)
  }

return (
    <>
    {renderSnackbar()}
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container item style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {hasEditPermissions ? (
                  <>
                    <TableCell sx={{ padding: "0 16px 0 16px" }}>
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={isHeaderCheckboxChecked}
                        onChange={handleHeaderCheckboxChange}
                      />
                    </TableCell>
                    {tableheaders.map((header, index) => (
                      <TableCell
                        key={header.id}
                        sx={{
                          padding: index === 0 ? "16px 2px" : "default", // Change padding for the second cell
                        }}
                      >
                        <TableSortLabel
                          active={sort.keytoSort === header.KEY}
                          direction={sort.keytoSort === header.KEY ? sort.direction : "asc"}
                          onClick={() => handleHeaderClick(header)}
                          disabled={!header.sortable}
                        >
                          {header.LABEL}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </>
                ) : (
                  tableheaders.map((header, index) => (
                    <TableCell
                      key={header.id}
                      sx={{
                        padding: index === 0 ? "8px 16px" : "default", // Change padding for the second cell
                      }}
                    >
                      <TableSortLabel
                        active={sort.keytoSort === header.KEY}
                        direction={sort.keytoSort === header.KEY ? sort.direction : "asc"}
                        onClick={() => handleHeaderClick(header)}
                        disabled={!header.sortable}
                      >
                        {header.LABEL}
                      </TableSortLabel>
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
              <TableBody>
                {sortedRows && sortedRows.length > 0 ? (
                  sortedRows
                  ?.filter((row) => row.shared_uid !== null && row?.post_status !== "bin")
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((page, index) => (
                      <>
                      <TableRow
                        key={index}
                        className="draggable-dialog-title"
                        hover={true}
                        sx={{padding:"8px 16px"}}
                      >
                        <TableCell
                          key={index}
                          className={
                            isCheckboxCheckedArray[index] ? "background" : ""
                          }
                          sx={{padding:"0px 0px 0px 16px", width:"0%"}}
                        >
                          <Checkbox
                            key={index}
                            color="secondary"
                            checked={isCheckboxCheckedArray[index]}
                            onChange={() => handleCheckboxChange(index,page)}
                            size="small"
                            sx={{
                              padding: '9px', // Adjust this value to reduce the padding
                            }}
                          />
                        </TableCell>
                        {Object.keys(headCells)?.map(
                          (headCell, cellIndex) => {
                            if (headCell === "post_name")
                              return (
                                <TableCell
                                    key={cellIndex}
                                    align="left"
                                    className={isCheckboxCheckedArray[index] ? "background" : ""}
                                    sx={{padding:"10px 0"}}
                                  >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                      {hasEditPermissions && (
                                        <Tooltip title="Edit" placement="top">
                                          <Link
                                            to={`/assets/page_management/${siteName}/${page.post_name}/edit`}
                                            state={{
                                              pageUid: `${page.shared_uid}`,
                                              pageTitle: `${page.post_title}`,
                                              postStatus: `${page.post_status}`,
                                              previewLink: `${page.preview_link}`,
                                            }}
                                            onClick={reloadJavaScriptAndCSS}
                                          >
                                            <img
                                                className="icon4"
                                                alt=""
                                                src="/icon4.svg"
                                              />
                                          </Link>
                                        </Tooltip>
                                      )}
                                      <Box>{page[headCell] ? page[headCell] : ""}</Box>
                                    </Box>
                                  </TableCell>
                              );
                              else if (headCell === "edited_by")
                              return (
                                <TableCell
                                  key={cellIndex}
                                  align={"left"}
                                  sx={{padding:"7px 16px"}}
                                  className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                >
                                  <div className="Avatar-head">
                                    <Avatar sx={{width:"24px", height:"24px"}} />{page?.modified_by ? page?.modified_by : ""}
                                    </div>
                                </TableCell>
                              );
                            else if (headCell === "post_id")
                              return (
                                <TableCell
                                  key={cellIndex}
                                  align={"left"}
                                  className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                >
                                  <Link
                                    to={`${siteURL}?page_id=${page[headCell]}&nowprocket`}
                                    style={{
                                      textDecoration: "underline",
                                      color: "#9C27B0",
                                    }}
                                    target="_blank"
                                  >
                                    {page[headCell] ? page[headCell] : ""}
                                  </Link>
                                </TableCell>
                              );
                              else if (headCell === "post_status") {
                                return (
                                  <TableCell key={cellIndex}
                                    className={isCheckboxCheckedArray[index] ? "background" : ""}
                                  >
                                    {page[headCell] === "publish" ? (
                                      <Alert
                                      icon={<CheckCircleOutlineIcon sx={{ color: "#1E4620",fontSize:"16px" }} fontSize="inherit" />}
                                        sx={{
                                          color: "#1E4620",
                                          padding: "0px 4px",
                                          border: "1px solid #2E7D3280",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "left",
                                          textTransform: "capitalize",
                                          height: "26px",
                                          width:"93px"
                                        }}
                                      >
                                        {page[headCell]}
                                      </Alert>
                                    ) : page[headCell] === "draft" ? (
                                      <Alert
                                      icon={<LightbulbCircleOutlinedIcon sx={{ color: "#00000099", fontSize:"16px" }} fontSize="small"/>}
                                        sx={{
                                          color: "#00000099",
                                          padding: "0px 4px",
                                          border: "1px solid #9E9E9E",
                                          background: "#E0E0E0",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "left",
                                          textTransform: "capitalize",
                                          height: "26px",
                                          width:"74px"
                                        }}
                                      >
                                        {page[headCell]}
                                      </Alert>
                                    ) : (
                                      <Alert
                                        icon={<PendingOutlinedIcon sx={{ color: "#014361",fontSize:"16px" }} fontSize="inherit" />}
                                        sx={{
                                          color: "#014361",
                                          padding: "0px 4px",
                                          border: "1px solid #2196F380",
                                          background: "#E5F6FD",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "left",
                                          textTransform: "capitalize",
                                          height: "26px",
                                          width:"93px"
                                        }}
                                      >
                                        {page[headCell]}
                                      </Alert>
                                    )}
                                  </TableCell>
                                );
                              }
                              else if (headCell === "creating_user")
                                return(
                                  <TableCell
                                   key={cellIndex}
                                   align={"left"}
                                   sx={{padding:"7px 16px"}}
                                   className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                  >
                                    <div className="Avatar-head">
                                    <Avatar sx={{width:"24px", height:"24px"}} />{page[headCell] ? page[headCell] : ""}
                                    </div>
                                  </TableCell>
                              );
                              else if(headCell === "modified_date"){
                                return(
                                  <TableCell
                                   key={cellIndex}
                                   align={"left"}
                                   sx={{padding:"7px 16px"}}
                                   className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                  >
                                    <div className="Avatar-head">
                                    {page[headCell] ? formatDate(page[headCell]) : ""}
                                    </div>
                                  </TableCell>
                                )
                              }

                            else if (headCell === "Actions")
                              return (
                                <TableCell
                                  key={cellIndex}
                                  sx={{ padding: 0 }}
                                  align={"left"}
                                  className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                >
                                  {page?.shared_uid ? (
                                    <div className="tablecell10">
                                      <div className="box132">
                                        <div className="stack15">
                                          <div className="instance-115">
                                          <TuneIcon
                                          sx={{cursor:"pointer", width:"19px"}}
                                          color="secondary"
                                          onClick={()=>handleTuneIconClick(page)}
                                          />
                                          </div>
                                          <div className="instance-115">
                                          <Tooltip title="Duplicate" placement="top">
                                            <Button disabled={page.post_status === 'pending'} onClick={() => openDuplicateConfirmation(page)}>
                                              <img
                                                className={page?.post_status === 'pending' ? "duplicateDisableBtnClass" : "icon72"}
                                                 alt=""
                                                src="/icon12.svg"
                                              />
                                            </Button>
                                         </Tooltip>
                                          </div>
                                          <div className="instance-115">
                                          <Tooltip title="Delete" placement="top">
                                          <Button onClick={() => openDeleteConfirmation(page)}>
                                              <img
                                                className="icon72"
                                                alt=""
                                                src="/icon13.svg"
                                              />
                                            </Button>
                                          </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    "Error: No shared_uid for this page"
                                  )}
                                </TableCell>
                              );
                            else if (headCell)
                              return (
                                <TableCell
                                  className={
                                    isCheckboxCheckedArray[index]
                                      ? "background"
                                      : ""
                                  }
                                  key={cellIndex}
                                >
                                  {page[headCell] ? page[headCell] : ""}
                                </TableCell>
                              );
                            else if (page[headCell] !== null)
                              return <TableCell>{page[headCell]}</TableCell>;
                            else return <TableCell>{""}</TableCell>;
                          }
                        )}
                      </TableRow>
                      </>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={tableheaders.length + 1} align="center">
                       <Typography variant="h6">No Page Names matching. Try selecting from the search field</Typography> {/* No data available message */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
              <TablePagination
                component="div"
                count={
                  sortedRows.filter(
                    (page:any) => page.shared_uid !== null && page?.post_status !== "bin"
                  ).length
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[]}
                sx={{
                  ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                    display: "none",
                  },
                  ".MuiTablePagination-actions": {
                    display: "none",
                  },
                  ".MuiTablePagination-toolbar": {
                    paddingLeft: "16px",
                  },
                }}
              />
              <Pagination
                count={Math.ceil(sortedRows.filter((page:any)=> page.shared_uid !== null && page?.post_status !== "bin").length / rowsPerPage)}
                showFirstButton
                showLastButton
                className="pagination-button"
                color="secondary"
                size="small"
                sx={{
                  ".css-wjh20t-MuiPagination-ul": {
                    justifyContent: "center",
                  },
                }}
                onChange={(event, value) =>
                  handleChangePage(null, value - 1)
                }
              />
              <TablePagination
                component="div"
                count={
                  originalSitePages.filter(
                    (page:any) => page.shared_uid !== null && page?.post_status !== "bin"
                  ).length
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                  displayedRows: 'hidden-displayed-rows', // Apply the custom class
                }}
                sx={{
                  ".MuiTablePagination-actions": {
                    display: "none",
                  },
                }}
              />
            </Stack>
          </TableContainer>
      <Dialog open={isDeleteConfirmationOpen} onClose={closeDeleteConfirmation}>
        <DialogTitle >Confirmation of Delete</DialogTitle>
        <DialogContent>
          <DialogContentText >
            Are you sure you want to delete this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}  color="secondary">No</Button>
          <Button onClick={handleDelete} color="secondary"
                  style={{ backgroundColor: "#9C27B0", color: "white" }}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDuplicateConfirmationOpen} onClose={closeDeleteConfirmation}>
        <DialogTitle >Confirmation of Duplicate</DialogTitle>
        <DialogContent>
          <DialogContentText >
            Are you sure you want to duplicate this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}  color="secondary">No</Button>
          <Button onClick={handleDuplicate} color="secondary"
                  style={{ backgroundColor: "#9C27B0", color: "white" }}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={openDuplicateDialogBox}
          fullWidth
          maxWidth="md"
        >
         <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
            <DialogTitle sx={{padding:"0px 0px 16px 0px"}}>Duplicate Page</DialogTitle>
            {/* {errorValidate ? <Alert severity="warning" sx={{marginBottom:"10px"}} >{errorValidate}</Alert> : "" } */}
            <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  required
                  fullWidth
                  id="pageTitle"
                  label="Page Title"
                  value={pageTitle}
                  onChange={(e) => setPageTitle(e.target.value)}
                />
              </div>
            </div>
            <Box
              component="div"
              sx={{
                p: 1,
                borderRadius: 1,
                backgroundColor: '#F7EEF9',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon style={{ marginRight: '8px' }} color="secondary" />
                <Typography variant="h6" color="#9C27B0">PLEASE NOTE:</Typography>
              </div>
              <Typography color="#9C27B0" sx={{ paddingLeft: 5 }}>
                All pages are duplicated with either a "Publish" or "Draft" status.
              </Typography>
            </Box>
          </div>
          <DialogActions>
            <Button onClick={handleCloseDuplicateDialogBox} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => validate()}
              variant="contained"
              color="secondary"
              endIcon={<AutoFixHighIcon />}
            >
              Duplicate
            </Button>
          </DialogActions>
        </Dialog>
         {/* Assign page group Dialog */}
      <AssignPageGroup isCheckboxCheckedArray={isCheckboxCheckedArray} setIsCheckboxCheckedArray={setIsCheckboxCheckedArray} setRefresh={setRefresh} setPageDetails={setPageDetails} assignPageGroup={assignPageGroup} pageGroupForAssign={pageGroupForAssign} pageGroups={pageGroups} pageDetails={pageDetails} assignPageGroupDialogOpen={assignPageGroupDialogOpen} setAssignPageGroupDialogOpen={setAssignPageGroupDialogOpen} />
       {/* Duplicate Error Dialog */}
      <Dialog
        open={showDuplicateErrorDialog}
        onClose={() => setShowDuplicateErrorDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Duplicate Page Title</DialogTitle>
        <DialogContent>
          <Typography>
            A page with this title already exists or there was an error while duplicating the page. Do you want to retry?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDuplicateErrorDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRetryDuplicate} color="secondary" variant="contained">
            Retry
          </Button>
        </DialogActions>
      </Dialog>
        </Grid>
      </Grid>
    </Grid>
    {<EditPageMeta editPageMetaOpen={editPageMetaOpen} setEditPageMetaOpen={setEditPageMetaOpen} uniquePostStatuses={uniquePostStatuses} 
      uniquePostTypes={uniquePostTypes} singlePageTitle={singlePageTitle} setSinglePageTitle={setSinglePageTitle} setPageStatus={setPageStatus}
      pageStatus={pageStatus} setPageType={setPageType} pageType={pageType} oauthTokenHeader={oauthTokenHeader} siteUid={siteUid} pageUid={pageUid}
      setIsMetaUpdated={setIsMetaUpdated} pageContent={pageContent}/>}
    </>
  );
}
