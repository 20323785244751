import { setDisplayErrorSnackBar, setIsLoading } from "../../../features/userSlice";
import { endpointsService } from "../../../services/endPoints.services";
import store from "../../../store";

async function getLogs(oauthTokenHeader: string,setLogs:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getETLLogs(oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else{
            setLogs(response?.data?.data)
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText?.reason, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function getNextLogs(oauthTokenHeader: string,nextURL:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getNextLogs(nextURL,oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        return response?.data;
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText?.reason, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function getLogsById(oauthTokenHeader: string,runId:number,setSingleLog:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getETLLogById(runId,oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else{
            setSingleLog(response?.data?.data)
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText?.reason, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function runProcess(oauthTokenHeader: string,ids:any,setRunStatus:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.runProcessLogs(ids,oauthTokenHeader);
        if (!response || !response?.status || response.status !== 201) {
            throw new Error('Failed to get page content');
        }
        else{
            setRunStatus(response?.data?.reason);
        }
    } catch (e:any) {
        setRunStatus(e?.parsedText?.reason);
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function getDBTables(oauthTokenHeader: string,selectedSource:any,selectedDestination:any,setTables:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getDBTables(oauthTokenHeader,selectedSource,selectedDestination);
        if (!response || !response?.status || response.status !== 200) {
            throw new Error('Failed to get Tables');
        }
        else{
            setTables(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function postMigrate(oauthTokenHeader: string,data:any,setMigrateResponse:any,setSuccess:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.postMigrate(oauthTokenHeader,data);
        if (!response || !response?.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else{
            setMigrateResponse(response?.data?.data);
            setSuccess("success")
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function getBrands(oauthTokenHeader: string,data:any,setBrands:any,setSuccess:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getBrands(oauthTokenHeader,data);
        if (!response || !response?.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else{
            setBrands(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function postBrands(oauthTokenHeader: string,data:any,setSuccess:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.postBrands(oauthTokenHeader,data);
        if (!response || !response?.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else{
            setSuccess("success");
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText, type: 'error' })); 
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

export const ETLLogsEndPointLogic = {
    getLogs,
    getNextLogs,
    getLogsById,
    runProcess,
    getDBTables,
    postMigrate,
    getBrands,
    postBrands
}