import Box from "@mui/material/Box";
import FilterComponent from "../../../../../ReusableFIlterComponent/FilterComponent";
import { useState } from "react";

interface Props {
  psiRequestRows:any;
  selectedCompetitorName:any;
  setSelectedCompetitorName:any;
  selectedStatus:any;
  setSelectedStatus:any;
  selectedUser:any;
  setSelectedUsers:any;
  selectedDate:any;
  setSelectedCompetitorURL:any;
  setSelectedDate:any;
}

const CompetitorsPageScanRequestsFilter = ({psiRequestRows,selectedCompetitorName,setSelectedCompetitorName,selectedStatus,setSelectedStatus,
  selectedUser,setSelectedUsers,selectedDate,setSelectedDate,setSelectedCompetitorURL
}:Props) => {

  const [searchCompetitor, setSearchCompetitor] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchUser, setSearchUser] = useState('');


  const filterConfig = [
    {
      key: 'competitor name',
      label: 'Select Competitor Name',
      searchTerm: searchCompetitor,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchCompetitor(e.target.value),
      selectedOption: selectedCompetitorName,
      filterName: 'competitor_name',
      data: psiRequestRows,
      onSelectionChange: (updatedSelection: any) => setSelectedCompetitorName(updatedSelection),
      count: selectedCompetitorName !== null &&selectedCompetitorName.length,
      colorSelected: selectedCompetitorName !== null && selectedCompetitorName.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    },
    {
      key: 'scan status',
      label: 'Select Scan Status',
      searchTerm: searchStatus,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchStatus(e.target.value),
      selectedOption: selectedStatus,
      filterName: 'active',
      data: psiRequestRows,
      onSelectionChange: (updatedSelection: any) => setSelectedStatus(updatedSelection),
      count: selectedStatus !== null && selectedStatus.length,
      colorSelected: selectedStatus !== null && selectedStatus.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      valueMapping: { "true": "Enabled", "false": "Disabled" }
    },
    {
      key: 'requested by',
      label: 'Select A User',
      selectedOption: selectedUser,
      searchTerm: searchUser,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchUser(e.target.value),
      filterName: 'created_by',
      data: psiRequestRows,
      onSelectionChange: (updatedSelection: any) => setSelectedUsers(updatedSelection),
      count: selectedUser !== null && selectedUser.length,
      colorSelected: selectedUser !== null && selectedUser.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    },
    {
      key: 'last scan',
      label: 'Select A Date Range',
      selectedOption: selectedDate,
      filterName: 'last_run',
      data: psiRequestRows,
      onSelectionChange: (updatedSelection: any) => setSelectedDate(updatedSelection),
      count: 0,
      colorSelected: selectedUser !== null && selectedUser.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      isDateRangeFilter: true
    }
  ];

  const clearFilters = () => {
    setSelectedCompetitorName(null);
    setSelectedStatus(null);
    setSelectedUsers(null);
    setSelectedDate(null);
    setSelectedCompetitorURL(null);
  }

  return (
    <Box>
      <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}

export default CompetitorsPageScanRequestsFilter