import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
export interface PageElementErrorProps {
    pageUid: string;
    setPageStructureDesign: any;
    setPageDictionary: any;
    setIsLoading:any;
    setPageContent : any;
    oauthTokenHeader: string
    selectedSite: any
    trackPromise: any}
export default function AlertDialog(
    {
       selectedSite
        
      }: PageElementErrorProps
    
) {
  const [open, setOpen] = React.useState(true);
const navigate = useNavigate();
const obj: any = Object.values(selectedSite)[0];
const handleClose = () => {
 
   navigate(`/assets/page_management/${obj.site_name}`)
    setOpen(false);
  };
 
  return (
    
    <>
      
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Unable to Load"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Invalid Page Structure or Page Group Missing
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} autoFocus color='secondary'>
            back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
