import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AvatarGroup from "@mui/material/AvatarGroup";
import "./TemplatesTable.css";
import {
  Chip,
  Typography,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DuplicateTemplateModal from "../TemplateModal/DuplicateTemplateModal";
import SimpleBackdrop from '../../../../BackDrop/BackDrop';
import { Link as RouterLink} from 'react-router-dom';
import TemplatesFilterCard from '../TemplatesFilter/TemplatesFilterCard';
import IconButton from '@mui/material/IconButton';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box } from "@mui/system";

interface Props {
  tableHeadings: { [key: string]: string };
  combinedOptions: any;
  selectedSharedUid:any;
  selectedTemplate: any
  setSelectedTemplate:any;
  oauthTokenHeader:any;
  setLoading:any;
  search:any;
  setSearch:any;
  loading:any;
  designTemplatesData:any;
  setDesignTemplatesData:any;
  setSelectedSharedUid:any;
  page:any;
  setPage:any;
  hasGetPermissions : any;
  hasEditPermissions: any;
  selectedFeatures:any;
  selectedComponents:any;
  setSelectedFeatures:any;
  setSelectedComponents:any;
}
function TemplatesTable({combinedOptions,tableHeadings, setSelectedTemplate, selectedSharedUid, setSelectedSharedUid, oauthTokenHeader, setDesignTemplatesData, selectedTemplate, designTemplatesData, setLoading, search, setSearch, loading, page, setPage,hasGetPermissions, hasEditPermissions, selectedFeatures, selectedComponents, setSelectedFeatures, setSelectedComponents }: Props) {
  const [, setRows] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedTemplateShareuid, setSelectedTemplateShareuid] = useState<any>(null); // New state for selected template's shared_uid
  const [openModal, setOpenModal] = useState(false);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({
    keytoSort: 'modified',
    direction: 'desc'
  });
  const [sortedData, setSortedData] = useState<any[]>([]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: number) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setRows(filteredData);
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  };

  const handleOpenModal = (shared_uid: any) => {
    setSelectedTemplateShareuid(shared_uid); // Set the selected template's shared_uid
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const filteredData = designTemplatesData?.filter((template:any )=>
    (!selectedTemplate || template?.name === selectedTemplate)
  )

  const filteredTemplateData = filteredData.filter((template: any) => {

    const statusComponents = selectedComponents && selectedComponents.length !== 0
      ? selectedComponents.includes(template?.component)
      : true; // If no Asset is selected, treat it as a match
    
    const statusFeatures = selectedFeatures && selectedFeatures?.length !== 0
    ? selectedFeatures.some((group:any) => template?.labels?.includes(group))
    : true; // If no Asset is selected, treat it as a match
  
    return statusComponents && statusFeatures ;
  });

  useEffect(() => {
    setPage(0);
  },[setPage,selectedFeatures,selectedComponents]);

  // headers for the table columns with sortable option for each column
  const tableheaders = [
    {id:0, KEY: "name", label: "Name", sortable: true},
    {id:1, KEY: "component", label: "Component", sortable: true},
    {id:2, KEY: "modified", label: "Last Updated", sortable: true},
    {id:3, KEY: "labels", label: "Features", sortable: true},
    {id:4, KEY: "quick_actions", label: "Quick Actions", sortable: false},
  ];

  // function to handle click on table headers
  const handleHeaderClick = (header : any) => {
    if (!header.sortable) return; // Ignore click if column is not sortable
  
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSortedData = (arrayToSort: any[]) => {
    // If no sorting key is set, return the original array
    if (!sort.keytoSort || !sort.direction) {
      return arrayToSort;
    }

    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
    const keytoSort = sort.keytoSort;

    if (keytoSort === undefined) {
      return 0; // Return 0 if the sorting key is undefined
    }
  
    const aValue = a[keytoSort];
    const bValue = b[keytoSort];

    // Handle undefined values for sorting
    if (aValue === undefined) {
      return 1; // Move undefined values to the end
    }
    if (bValue === undefined) {
      return -1; // Move undefined values to the end
    }

    // Handle "N/A" values for sorting
    if (aValue === "") {
      return 1; // Move "N/A" values to the end
    }
    if (bValue === "") {
      return -1; // Move "N/A" values to the end
    }
  
    // Custom sorting for labels
    if (keytoSort === 'labels') {
      // Sort based on the length of labels array first
      const aLabelCount = Array.isArray(aValue) ? aValue.length : 0;
      const bLabelCount = Array.isArray(bValue) ? bValue.length : 0;

      // For descending order, empty labels come first
      if (sort.direction === 'desc') {
        if (aLabelCount === 0 && bLabelCount > 0) return -1; // a is empty, b is not
        if (bLabelCount === 0 && aLabelCount > 0) return 1;  // b is empty, a is not
      } else {
        // For ascending order, empty labels come last
        if (aLabelCount === 0 && bLabelCount > 0) return 1; // a is empty, b is not
        if (bLabelCount === 0 && aLabelCount > 0) return -1; // b is empty, a is not
      }
    }

    // Perform sorting based on the direction and type of values
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // String comparison (localeCompare for correct string sorting)
      return sort.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Numeric comparison
      return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (aValue instanceof Date && bValue instanceof Date) {
      // Date comparison
      return sort.direction === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    } else {
      // Fallback to string comparison if values are of different types
      return sort.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });
  };

  useEffect(() => {
    const sortedRows = getSortedData(filteredTemplateData || []);
    setSortedData(sortedRows);
  }, [filteredTemplateData, getSortedData, sort]);

  useEffect(() => {
    setRows(filteredTemplateData);
    const newPageCount = Math.ceil(filteredTemplateData?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[filteredTemplateData, rowsPerPage]);

  // Check if there's data to display
  if (filteredTemplateData?.length === 0) {
    return (
      <Paper className="FailedProcessWrap" sx={{ width: "100%", overflow: "hidden", boxShadow: 'none' }}>
        <Typography variant="h6" align="center">No Failed Process</Typography>
      </Paper>
    );
  }

  return (
    <>
      {loading ? (<SimpleBackdrop />):(
      <>
      <TemplatesFilterCard combinedOptions={combinedOptions} oauthTokenHeader={oauthTokenHeader} selectedTemplate={selectedTemplate} selectedSharedUid={selectedSharedUid} setSelectedSharedUid={setSelectedSharedUid} designTemplatesData={designTemplatesData} setDesignTemplatesData={setDesignTemplatesData} setSelectedTemplate={setSelectedTemplate} setSelectedFeatures={setSelectedFeatures} setSelectedComponents={setSelectedComponents} selectedFeatures={selectedFeatures} selectedComponents={selectedComponents}/>
      <TableContainer className="templatesWrap" component={Paper} sx={{ marginTop: "16px", boxShadow:"none" }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
          {tableheaders.map((header,index) => (
              <TableCell key={header.id}>
                <TableSortLabel
                  active={sort.keytoSort === header.KEY}
                  direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                  onClick={() => handleHeaderClick(header)}
                  disabled={!header.sortable}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {sortedData && sortedData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row:any, index:number) => (
                <React.Fragment key={index}>
                  <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.component}</TableCell>
                  <TableCell align="left">{formatDate(row.modified)}</TableCell>
                  <TableCell align="left">
                    <div className="chip-data">
                    {Array.isArray(row.labels) && row.labels.length > 0 ? (
                      <>
                      <Chip
                        label={row?.labels[0]} // Display the first label
                        sx={{
                          backgroundColor: "#7B1FA2",
                          color: "#ffffff",
                          height: "26px",
                        }}
                      />
                      {row.labels.length > 1 && ( // Check if there are additional labels
                      <AvatarGroup
                        total={row?.labels?.length - 1}
                        variant="circular"
                      ></AvatarGroup>
                          )}
                        </>
                      ) : null} {/* Render nothing if there are no labels */}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                  <>
                  <Box display="flex" gap={1}>
                        <Tooltip title="Edit" placement="top">
                          <Box>
                            <RouterLink
                              to="edit_new_template"
                              state={{
                                selectedTemplateShareuid: row?.shared_uid,
                                oauthTokenHeader: oauthTokenHeader,
                                selectedSharedUid: selectedSharedUid,
                                combinedOptions: combinedOptions,
                                designTemplatesData: designTemplatesData
                              }}
                            >
                              <IconButton size="small">
                              <CreateOutlinedIcon fontSize="small" color="secondary"/>
                              </IconButton>
                            </RouterLink>
                          </Box>
                        </Tooltip>
                        <Tooltip title="Duplicate" placement="top">
                          <IconButton size="small" onClick={() => handleOpenModal(row?.shared_uid)}>
                            <ContentCopyOutlinedIcon fontSize="small" color="secondary"/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                          <IconButton size="small" disabled color="primary">
                            <DeleteOutlineOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                  </Box>
                  </>
                  </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            }
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={sortedData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={pageCount}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
          onChange={(event, value) =>
            handleChangePage(null, value - 1)
          }
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
      <DuplicateTemplateModal open={openModal} onClose={handleCloseModal} selectedTemplateShareuid={selectedTemplateShareuid} selectedSharedUid={selectedSharedUid} hasGetPermissions={hasGetPermissions} hasEditPermissions={hasEditPermissions} oauthTokenHeader={oauthTokenHeader} designTemplatesData={designTemplatesData} setDesignTemplatesData={setDesignTemplatesData} />
    </TableContainer>
        </>
      )}
    </>
  );
}
export default TemplatesTable;
