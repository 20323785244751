import hostname from "../../../services/api/config.host";
import { validEmail } from "../../Login/logic/validation";

export const resetPassword = async (email: string): Promise<string> => {
  const emailValidationResult = validEmail(email);
  if (emailValidationResult) {
    throw new Error("Invalid email format. Please provide a valid email address.");
  }
  try {
    const response = await fetch(`${hostname}/aladdin_api/v1/users/reset_password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    if (response.ok) {
      return "Password reset email sent successfully!";
    } else if (response.status === 400) {
      throw new Error("User not found. Please make sure the email address is correct.");
    } else {
      throw new Error("Failed to send password reset email. Please try again later.");
    }
  } catch (error) {
    throw new Error(`Error occurred during password reset: ${error}`);
  }
};
