import {Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Button from "@mui/material/Button";

function DomainIntregationCongrats() {
  return (
    <div>
      <Stack>
          <img alt="Edit" src="/congratulations.jpg" />
          <Typography fontSize={34} align="center" sx={{ mt: 2 }}>
           Great!<br></br>Your Domain Integration Request Has Been Received!
          </Typography>
          <Typography fontSize={20} align="center" fontWeight={500} mt={2}>
           We’re working on it, and will update you soon with additional information
          </Typography>
        </Stack>
        <Stack alignItems={"center"} mt={2}>
          <Button color="secondary" variant="contained">
            Close
          </Button>
        </Stack>
    </div>
  );
}

export default DomainIntregationCongrats;
