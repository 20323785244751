import React, { useState } from "react";
import ConfirmPasswordSection from "../../components/ResetPasswordSection/ConfirmPasswordSection";
import { confirmPassword } from "./logic/confirmPasswordHelper";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const ConfirmPasswordScreen: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [reNewPassword, setReNewPassword] = useState<string>("");
  const [passwordError,] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const { uid, token } = useParams<{ uid: string; token: string }>();
  const navigate = useNavigate();

  const handleResetPasswordConfirm = async () => {
    try {
      if (!uid || !token) {
        throw new Error("Invalid parameters for password confirmation");
      }

       await confirmPassword(
        uid,
        token,
        newPassword,
        reNewPassword
      );
      setSnackbarMessage("Password Reset Successfully ");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setNewPassword("");
      setReNewPassword("");
      setTimeout(() => {
        navigate("/login", { state: { successMessage: "Your password has been changed successfully. Please use your new password to log in." } });
      }, 3000);
    } catch (error: any) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };


  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
    <ConfirmPasswordSection
      newPassword={newPassword}
      reNewPassword={reNewPassword}
      passwordError={passwordError}
      showPassword={showPassword}
      onNewPasswordChange={setNewPassword}
      onReNewPasswordChange={setReNewPassword}
      onToggleShowPassword={handleClickShowPassword}
      onResetPasswordConfirm={handleResetPasswordConfirm}
      snackbarOpen={snackbarOpen}
      snackbarMessage={snackbarMessage}
      snackbarSeverity={snackbarSeverity}
      onSnackbarClose={handleSnackbarClose}
    />
          <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConfirmPasswordScreen;
