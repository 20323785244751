import { setDisplayErrorSnackBar } from "../../../../features/userSlice";
import { endpointsService } from "../../../../services/endPoints.services";
import store from "../../../../store";

async function getSitePages(siteUid: string, oauthTokenHeader: string, setSitePages: any, setOpen:any,setErrorAccToPageStatus:any, trackPromise: any,setRetry:any, setIsLoading: any) {
    try {
        
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getSitePages(siteUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            setErrorAccToPageStatus([response])
            /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Pages', type: 'error' })); */
        } else {
             setOpen(false)
            setSitePages(response?.data?.data);
        }
        
    } catch (e) {
        setRetry(false)
        setOpen(true)
    } 
}

async function getNextPages(oauthTokenHeader:string,nextUrl:any,setIsLoading: any,setOpen:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getNextPages(nextUrl,oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Pages', type: 'error' }));
        }
        return response?.data;
    } catch (error) {
        store.dispatch(setDisplayErrorSnackBar({ message: error, type: 'error' }));
    }
}


async function getPageGroups(siteUid: string, oauthTokenHeader: string, setPageGroups: any, trackPromise: any, setIsLoading: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getPageGroups(siteUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Groups', type: 'error' }));
        } else {
            setPageGroups(response?.data?.data);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function updatePageGroup(data:any, siteUid: string, pageUid:any, oauthTokenHeader: string, setIsLoading: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.updatePageGroup(data, siteUid,pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Groups', type: 'error' }));
        } else {
            /* setPageGroups(response?.data); */
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    } finally {
        store.dispatch(setIsLoading(false));
    }
}

async function updatePageMetaPageBuildingDetails(
    updateObject: any,
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    setMetaData: any
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.updatePage(updateObject, siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating page meta', type: 'error' }));
            store.dispatch(setIsLoading(false));
        } else {
            setMetaData(response);
            store.dispatch(setIsLoading(false));
        }
        store.dispatch(setIsLoading(false));
    } catch (e:any) {
        store.dispatch({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }); // Need to implement the displayErrorSnackBar later
    }
}

async function updatePageMeta(
    updateObject: any,
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    setIsMetaUpdated: any,
    setPreview:any
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.updatePage(updateObject, siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Updating page meta', type: 'error' }));
            store.dispatch(setIsLoading(false));
        } else {
            setIsMetaUpdated(true);
            setPreview(
                updateObject?.data?.post_status === "pending" ||
                updateObject?.data?.post_status === "draft" ||
                updateObject?.data?.post_status === undefined
                  ? response?.data?.data[0]?.preview_link
                  : response?.data?.data[0]?.page_url
              );              
            // store.dispatch(setIsLoading(false));
        }
    } catch (e:any) {
        store.dispatch({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }); // Need to implement the displayErrorSnackBar later
    }
}
async function discardPage(
    siteUid: string,
    pageUid: string,
    setIsLoading: any,
    oauthTokenHeader: string,
    data:any,
) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.discardPage(data ,siteUid, pageUid, oauthTokenHeader);
        if (!response.status || response.status !== 200) {
            store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while discarding page', type: 'error' }));
        }
    } catch (e:any) {
        store.dispatch({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }); 
    }
}

async function getPageContent(siteUid: string, pageUid: string, oauthTokenHeader: string, setPageContent: any, setIsLoading: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getPageContent(siteUid, pageUid, oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        setPageContent(response?.data?.data);
    }  finally {
        store.dispatch(setIsLoading(false));
    }
}

// eslint-disable-next-line
export const sitePageEndPointLogic = {
    getSitePages,
    getNextPages,
    getPageGroups,
    updatePageGroup,
    updatePageMeta,
    updatePageMetaPageBuildingDetails,
    getPageContent,
    discardPage
};