import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import AddManualExpenseDialog from "./AddManualExpense";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import EditManualExpenseDialog from "./EditManualExpense";
import { ManualExpenseEndPointLogic } from "../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic";
import FilterComponent from "../../../../ReusableFIlterComponent/FilterComponent";


interface ManuelExpenseFilterProps {
  children?: React.ReactNode;
  hasEditPermissions: any;
  setManualexpenserows: any;
  index: number;
  value: number;
  manualexpenserows?: any;
  trafficSources?: any;
  manualExpenseTableDataUpdate: any;
  result: any;
  siteNames: any;
  selectedAsset: any;
  setSelectedAsset: any;
  selectedSource: any;
  setSelectedSource: any;
  oauthTokenHeader: string;
  fileBase64: any,
  setFileBase64: any,
  setFileName: any,
  fileName: any,
  setRefresh: any;
  selectedRows: any,
  setSelectedRows: any,
  isCardVisible: any;
  setIsCardVisible: any;
  setSelectedExpense: any;
  selectedExpense: any;
  setDeleteRefresh: any;
  selectedDate: any;
  setSelectedDate: any;
}

export default function ManualExpenseFilter({
  manualexpenserows, setManualexpenserows, hasEditPermissions, trafficSources, manualExpenseTableDataUpdate, result, siteNames, setSelectedAsset, setSelectedSource, selectedAsset, selectedSource,
  oauthTokenHeader, fileBase64, setFileBase64, setFileName, fileName, setRefresh, selectedRows, setSelectedRows, isCardVisible, setIsCardVisible, setSelectedExpense,
  selectedExpense, setDeleteRefresh, selectedDate, setSelectedDate
}: ManuelExpenseFilterProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenEdit, setIsDialogOpenEdit] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [searchAsset, setSearchAsset] = useState("");
  const [searchSource, setSearchSource] = useState("");
  const [, setDeleteSuccess] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleAddClick = (note: any) => {
    setSelectedNote(note);
    setIsDialogOpen(true);
  };
  const handleEditClick = (note: any) => {
    setSelectedNote(note);
    setIsDialogOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsDialogOpenEdit(false);
  };

  const manualExpenseTableData = Object.values(manualexpenserows?.data || []);
  manualExpenseTableDataUpdate = manualExpenseTableData.filter(Boolean).flat();

  const filteredAssets = result.filter((item: any) => {
    const rowSharedUid = item?.traffic_source__shared_uid;
    const trafficSource = trafficSources.find(
      (source: any) => source.shared_uid === rowSharedUid
    );
    const trafficSourceName = trafficSource?.traffic_source_name || "N/A";
    // Filter based on the search term
    return trafficSourceName.toLowerCase().includes(searchSource.toLowerCase());
  });



  const handleClearFilters = () => {
    setSelectedAsset('');
    setSelectedSource('');
    setSearchAsset('');
    setSearchSource('');
    setSelectedExpense(null);
    setSelectedDate([]); // Clear the selected expense
  }

  const uniqueAssetNames = Array.from(new Set(result.map((data: any) => data.expense_name)));

  const handleDelete = async (selectedRows: any) => {
    await ManualExpenseEndPointLogic.deleteExpanse(oauthTokenHeader, selectedRows[0]?.shared_uid, setDeleteSuccess);
    setSnackbarMessage('Expence Deleted Successfully');
    setSnackbarOpen(true);
    setSelectedRows([]);
    setTimeout(() => setDeleteRefresh(true), 500);
  }

  const filterConfig = [
    {
      key: 'asset',
      label: 'Select an Asset ',
      searchTerm: searchAsset,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchAsset(e.target.value),
      selectedOption: selectedAsset,
      filterName: 'website_shared_uid',
      data: filteredAssets,
      onSelectionChange: (updatedSelection: any) => setSelectedAsset(updatedSelection),
      count: selectedAsset.length,
      colorSelected: selectedAsset.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
      siteNames: siteNames
    },
    {
      key: 'source',
      label: 'Select a Source',
      searchTerm: searchSource,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchSource(e.target.value),
      selectedOption: selectedSource,
      filterName: 'traffic_source__shared_uid',
      data: filteredAssets,
      onSelectionChange: (updatedSelection: any) => setSelectedSource(updatedSelection),
      count: selectedSource.length,
      colorSelected: selectedSource.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
      trafficSources: trafficSources
    },
    {
      key: 'date',
      label: 'Date',
      selectedOption: selectedDate,
      filterName: 'date',
      data: filteredAssets,
      onSelectionChange: (updatedSelection: any) => setSelectedDate(updatedSelection),
      count: 0,
      colorSelected: selectedDate.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      isDateRangeFilter: true,
    }
  ];


  return (
    <>
      <Paper className="chainsFiltersWrap">
        <Box sx={{ p: 2 }}>
          {/* Heading */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Manual Expenses
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Create new or edit existing Manual Expenses.
              </Typography>
            </Grid>
          </Grid>
          {/* Filter part */}
          <Box display={"flex"} justifyContent={"space-between"} >
            <Box display={"flex"} alignItems={"center"} marginTop={1}>
              {/* Queue Name */}
              <Autocomplete
                options={uniqueAssetNames}
                value={selectedExpense}
                onChange={(event, value) => setSelectedExpense(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Expense Name"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ marginLeft: "12px" }} />
                        </InputAdornment>
                      ),
                    }}
                    color="secondary"
                  />
                )}
                size="small"
                sx={{ width: "300px", marginRight: "16px" }}
              />
              {/* filter items */}
              < FilterComponent
                filterConfig={filterConfig}
                clearFilters={handleClearFilters} />
            </Box>
            {/* Action Buttons */}
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <IconButton
                color="secondary"
                disabled={selectedRows.length > 1 || selectedRows.length === 0}
                onClick={() => handleDelete(selectedRows)} // Use an arrow function
              >
                <DeleteIcon />
              </IconButton>
              <Button
                variant="outlined"
                color="secondary"
                endIcon={<IconButton color="secondary" size="small" disabled={selectedRows.length > 1 || selectedRows.length === 0}><EditIcon fontSize="small" /></IconButton>}
                onClick={() => handleEditClick("")}
                disabled={selectedRows.length > 1 || selectedRows.length === 0}
              >
                EDIT EXPENSE
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<AddIcon />}
                onClick={() => handleAddClick("")}
              >
                ADD NEW EXPENSE
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
      <AddManualExpenseDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        setManualexpenserows={setManualexpenserows}
        note={selectedNote}
        oauthTokenHeader={oauthTokenHeader}
        siteNames={siteNames}
        fileBase64={fileBase64}
        setFileBase64={setFileBase64}
        setFileName={setFileName}
        fileName={fileName}
        setRefresh={setRefresh}
        setIsCardVisible={setIsCardVisible}
        isCardVisible={isCardVisible}
      />
      <EditManualExpenseDialog
        hasEditPermissions={hasEditPermissions}
        setManualexpenserows={setManualexpenserows}
        trafficSources={trafficSources}
        result={result}
        selectedAsset={selectedAsset}
        manualexpenserows={manualexpenserows}
        open={isDialogOpenEdit}
        handleClose={handleCloseDialog}
        note={selectedNote}
        oauthTokenHeader={oauthTokenHeader}
        siteNames={siteNames}
        fileBase64={fileBase64}
        setFileBase64={setFileBase64}
        setFileName={setFileName}
        fileName={fileName}
        setRefresh={setRefresh}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setIsCardVisible={setIsCardVisible}
        isCardVisible={isCardVisible}
      />

      <Snackbar
        sx={{ zIndex: '9999999' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('Error') ? 'error' : 'success'}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );

}
