import { Autocomplete, InputAdornment, Stack, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PercentIcon from '@mui/icons-material/Percent';
import Grid from '@mui/material/Grid';

function Features() {

  const websitelisting = [
    { label: "websiteexample1.com" },
    { label: "websiteexample2.com" },
    { label: "websiteexample3.com" },
    { label: "websiteexample4.com" },
    { label: "websiteexample5.com" },
  ];

  return (
    <>
      <Typography>Features</Typography>
      <Box mt={1}>
        <Typography fontWeight={500}>Animation</Typography>
        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={4}>
            <TextField
            fullWidth
              size="small"
              required
              id="outlined-required"
              label="Appearance Percentage"
              color="secondary"
              InputLabelProps={{
                shrink: true, // This will keep the label above the input field
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <PercentIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton aria-label="Minus" color="secondary" size="small" sx={{padding:"0"}}>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton aria-label="Add" color="secondary" size="small" sx={{padding:"0"}}>
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              required
              id="outlined-required"
              label="Appearance Latency"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              required
              id="outlined-required"
              label="Minimal Position"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              required
              id="outlined-required"
              label="Maximal Position"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Typography mt={1} fontWeight={500}>Special Offer</Typography>
        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Popup Name"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Starting Tier"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
        <Stack>
          <Typography>DeBug</Typography>
          <Switch
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#2E7D32",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#2E7D32",
                      },
                    }}
          />
        </Stack>
        </Box>
      </Box>
    </>
  );
}

export default Features;
