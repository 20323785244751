import { Grid, Typography } from "@mui/material";
import React from "react";
import { LineChart } from "@mui/x-charts";
import { ChartsReferenceLine } from "@mui/x-charts/ChartsReferenceLine";
import { paint } from "./ScoreDataset";

const CompetitorsPageSpeedDashboardGraph = () => {
  return (
    <>
      <Grid container spacing={3} mb={2} mt={1} className="ratedTableWrap">
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "18px", color: "#000000" }}>
            LCP (Largest Contentful Paint)
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
            in Seconds
          </Typography>
          <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "z",
                color: "#4285F4",
                showMark: false,
                curve: "linear",
              }
              ,
              {
                dataKey: "p",
                color: "#EB3693",
                showMark: false,
                curve: "linear",
              }
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={350}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={4}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "18px", color: "#000000" }}>
            INP (Interaction to Next Paint)
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
            in Milliseconds
          </Typography>
          <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "z",
                color: "#4285F4",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "p",
                color: "#EB3693",
                showMark: false,
                curve: "linear",
              }
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={350}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={400}
              lineStyle={{ stroke: "#EF5350", strokeDasharray: "3" }}
            />
            <ChartsReferenceLine
              y={200}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "18px", color: "#000000" }}>
            CLS (Cumulative Layout Shift)
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
            in Milliseconds
          </Typography>
          <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "z",
                color: "#4285F4",
                showMark: false,
                curve: "linear",
              }
              ,
              {
                dataKey: "p",
                color: "#EB3693",
                showMark: false,
                curve: "linear",
              }
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={350}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={4}
              lineStyle={{ stroke: "#EF5350", strokeDasharray: "3" }}
            />
            <ChartsReferenceLine
              y={2}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "18px", color: "#000000" }}>
            FCP (First Contentful Paint)
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
            in Seconds
          </Typography>
          <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "z",
                color: "#4285F4",
                showMark: false,
                curve: "linear",
              }
              ,
              {
                dataKey: "p",
                color: "#EB3693",
                showMark: false,
                curve: "linear",
              }
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={350}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={4}
              lineStyle={{ stroke: "#EF5350", strokeDasharray: "3" }}
            />
            <ChartsReferenceLine
              y={2}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "18px", color: "#000000" }}>
            TTFB (Time to First Byte)
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
            in Seconds
          </Typography>
          <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "z",
                color: "#4285F4",
                showMark: false,
                curve: "linear",
              }
              ,
              {
                dataKey: "p",
                color: "#EB3693",
                showMark: false,
                curve: "linear",
              }
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={350}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={4}
              lineStyle={{ stroke: "#EF5350", strokeDasharray: "3" }}
            />
            <ChartsReferenceLine
              y={2}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </Grid>
      </Grid>
    </>
  )
}

export default CompetitorsPageSpeedDashboardGraph