import { Paper, Typography } from "@mui/material";
import React from "react";
import GeoFilterCard from "./GeoFilter/GeoFilterCard";
import GeoTable from "./GeoTable/GeoTable";

const tableHeadings = {
  geo_name: "Geo Name",
  associated_territory: "Associated Territory",
  time_zone: "Time Zone",
  created_on: "Created on",
  created_by: "Created By",
  last_edited: "Last edited",
  edited_by: "Edited By",
};
const rows = [
  {
    geo_name: "UK",
    associated_territory: "UK",
    time_zone: "Europe/London",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    geo_name: "UK",
    associated_territory: "UK",
    time_zone: "Europe/London",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
  {
    geo_name: "UK",
    associated_territory: "UK",
    time_zone: "Europe/London",
    created_on: "Feb. 26, 2024, 6:53 a.m.	",
    created_by: "User Name",
    last_edited: "Feb. 26, 2024, 6:53 a.m.	",
    edited_by: "User Name",
  },
];

const Geo = () => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          padding: "8px 16px 0px",
          borderEndEndRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <Typography fontSize={24}>Geo List</Typography>
        <GeoFilterCard />
      </Paper>
      <Paper
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <GeoTable tableHeadings={tableHeadings} rows={rows} />
      </Paper>
    </>
  );
};

export default Geo;
