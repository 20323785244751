import { createGlobalStyle } from 'styled-components';

let result: any;

const getClassName = (elementClass: any, fatherClass: any) => {

  return `.${(fatherClass)?.replaceAll(" ", ".")} ${(elementClass)?.replaceAll(" ", ".")}`;
};

const getElementStyle = (element: any, fatherClass: string) => {
  let Style;
  if (element && !element?.type?.includes("internal-widget") && !element?.type?.includes("external-widget") &&
    !element?.type?.includes("img") && !element?.props?.className?.includes("date-today")) {
    const elementClass = element?.type;
    Style = `${getClassName(elementClass, fatherClass)} {
      -webkit-user-modify: read-write;
    }`;
  }

  return Style;
}

const elementDesign = (elements: any) => {
  const fatherClass = elements?.props?.className;
  let result = [] as any;
  // eslint-disable-next-line
  if (elements?.props?.children?.length) {
    // eslint-disable-next-line
    elements?.props?.children?.map((element: any) => {
      result.push(getElementStyle(element, fatherClass));
    })
  } else {
    let element = elements?.props?.children;
    result.push(getElementStyle(element, fatherClass));
  }

  return result;

}


export const GlobalStyles = (elements: any) => {
  if (!elements?.props?.className?.includes("htmlTemplate")) {
    result = elementDesign(elements) as any;
  }

  return createGlobalStyle` 
    ${result?.map((value: any) => {
    return value;
  })}`
}

export const AllStyles = (elements: any, htmlWrapper: string) => {
  const allStyle = [] as any;
  allStyle.push(`
  ${elements}`);
  allStyle.push(`.main.date.internal-widget h1 {
    font-size: 25px;
    margin-left: 4px;
    font-weight: 900;
  }`);
  allStyle.push(`#${htmlWrapper} section {
    top: 0px !important;
  }`);
  allStyle.push(`${htmlWrapper}.section {
    top: 0px !important;
    border-style: solid;
    
  }`);
  allStyle.push(`.internal-widget.mobile, .internal-widget.tablet{
    background-color: #FDEDED;
  }`);
  allStyle.push(`.internal-widget.mobile::before {
    content: '';  
    display: none; // temporarily hidden
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0px;
    left: -40px;
    background: #FDEDED url(./errorIcon.svg) no-repeat 0px 0px;
  }`);

  return createGlobalStyle` ${allStyle}`;
}