import {
    Autocomplete,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    DialogContent,
    DialogActions,
    CircularProgress
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import DoneIcon from "@mui/icons-material/Done";
  import SearchIcon from "@mui/icons-material/Search";
  import FormControlLabel from "@mui/material/FormControlLabel";
  import Checkbox from "@mui/material/Checkbox";
  import AddIcon from "@mui/icons-material/Add";
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  import { useEffect, useState } from "react";
import { engagementIncentivesEndPointLogic } from "../../../../../logic/PaidTrafficManagement/EngagementIncentives/engagementIncentivesEndPointLogic";
  
  interface BudgetControlModalEditProps {
    open: boolean;
    handleClose: () => void;
    exequteQueryContentQueryCreateData: any;
    startDate:any;
    setRefresh:any;
    userToken:any;
    rowSelected:any;
    linkDetails:any;
  }
  
  const BudgetControlModalEdit = ({ open, handleClose, exequteQueryContentQueryCreateData,startDate,setRefresh,userToken,rowSelected,linkDetails}: BudgetControlModalEditProps) => {
    const [filteredLinks, setFilteredLinks] = useState<any[]>([]);
    const [selectedLinks, setSelectedLinks] = useState(Array(3).fill(null));
    const [disabledLinks, setDisabledLinks] = useState(Array(3).fill(true)); 
    const [autocompleteCount, setAutocompleteCount] = useState<number>(1); // To limit number of Autocompletes
    const [promotedLinksOnly, setPromotedLinksOnly] = useState(true);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [linkToAdd, setLinkToAdd] = useState<{ index: number; link: any } | null>(null); // Store the link the user is trying to add
    const [, setIsBrandSelected] = useState(false);
    const [cost, setCost] = useState('');
    const [budget, setBudget] = useState('');
    // eslint-disable-next-line
    const exequteQueryContentQueryCreateDataOutput = exequteQueryContentQueryCreateData ? exequteQueryContentQueryCreateData?.query_output : [];
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [brandSharedUid, setBrandSharedUid] = useState('');
    const [loading, setloading] = useState(false);
    const [exequteQueryMessage, setExequteQueryMessage] = useState('') as any;
    const [newLinkValue, setNewLinkValue] = useState('') as any;

    useEffect(() => {
      if (open && rowSelected) {
        setSelectedBrand(rowSelected?.brand_name);
        setBrandSharedUid(rowSelected?.shared_uid);
        setBudget(rowSelected?.qftd_budget)
        setCost(rowSelected?.cost_per_qftd);
        const initialLinks = exequteQueryContentQueryCreateDataOutput
          .filter((item: any) => item?.brand_name === rowSelected?.brand_name)
          .map((item: any) => ({
            label: item.link_name,
            uid: item.link_shared_uid,
            shown_in_active_table: item.shown_in_active_table
          }));
        const initialLinksDetails = linkDetails
          .map((item: any) => ({
            label: item.link_name,
            uid: item.link_shared_uid,
            shown_in_active_table: item.shown_in_active_table
          }));
        setSelectedLinks(initialLinksDetails);
        setFilteredLinks(initialLinks);
        setIsBrandSelected(!!rowSelected?.brand_name);
        setAutocompleteCount(initialLinksDetails?.length)
      }
      // eslint-disable-next-line
    }, [open, rowSelected, promotedLinksOnly, exequteQueryContentQueryCreateDataOutput]);
    
  
  
    const handleLinkChange = (index: number, value: any | null) => {
      // Create a copy of selectedLinks to modify
      const updatedLinks = [...selectedLinks]; // Copy the current selectedLinks state
      updatedLinks[index] = value; // Update the current link
    
      // Log the newly added Autocomplete value
      if (value) {
        // Update newLinkValue to include the new link while keeping existing ones
        setNewLinkValue((prevNewLinkValues:any) => {
          // Filter out any existing link with the same uid
          const updatedNewLinkValues =prevNewLinkValues &&  prevNewLinkValues.filter((link:any) => link.uid !== value.uid);
          
          // Add the new link
          return [...updatedNewLinkValues, { label: value.label, uid: value.uid }];
        });
      }
    
      if (value && !value.shown_in_active_table) {
        setLinkToAdd({ index, link: value }); // Store index along with the link
        setShowConfirmationDialog(true);
        return; // Early return if it's a special case
      }
    
      // Disable all previous links if any new link is added
        setDisabledLinks((prev) => {
        const newDisabledLinks = [...prev];
        for (let i = 0; i < index; i++) {
          newDisabledLinks[i] = true; // Disable all previous links
        }
        newDisabledLinks[index] = false; // Ensure the current one is enabled
        return newDisabledLinks;
      });
    
      setSelectedLinks(updatedLinks); // Update the state with the modified links
    };

    

  
    const handleAddAnother = () => {
      const nextIndex = autocompleteCount; // Get current count (for example, if 2 Autocompletes are rendered, this will be 2)
      if (nextIndex < 3) {
        setDisabledLinks((prev) => {
          const newDisabledLinks = [...prev];
          newDisabledLinks[nextIndex] = false; // Enable the new one
          return newDisabledLinks;
        });
        setAutocompleteCount(nextIndex + 1); // Increase the count
      }
    };
  
    const handlePromotedLinksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPromotedLinksOnly(event.target.checked);
    
      if (event.target.checked) {
        // When promoted links only is checked, filter selectedLinks to include only shown_in_active_table
        const activeLinks = filteredLinks.filter(link => link.shown_in_active_table);
        setSelectedLinks(activeLinks.length > 0 ? [activeLinks[0]] : []);
      } else {
        // When unchecked, reset selected links based on previously selected links
        setSelectedLinks(selectedLinks);
      }
    };
    
  
    // Filter options to exclude already selected ones
    const getFilteredOptions = (index: number) => {
      return filteredLinks.filter((option) => !selectedLinks.some((selected) => selected?.uid === option.uid));
    };
    
    const handleCloseModal = () => {
      // Reset all state variables when closing the modal
      setFilteredLinks([]);
      setSelectedLinks([]);
      setAutocompleteCount(1); // Reset to the initial count
      setPromotedLinksOnly(true); // Reset to default value
      setShowConfirmationDialog(false); // Ensure confirmation dialog is closed
      handleClose(); // Call the original handleClose prop function
      setCost('');
      setBudget('');
      setNewLinkValue([]);
    };

    const handleEditBudget = async () => {
      const data = {
        "cost_per_qftd": cost,
        "qftd_amount": budget,
        "links": (newLinkValue || []) // Use [] if newLinkValue is undefined or null
        .filter((link: any) => link !== null) // Filter out null values
        .map((link: any) => link.uid) // Map to get only the uid values
      };
    
      setloading(true);
      
      try {
        // Assuming the API returns a success or error status
        await engagementIncentivesEndPointLogic.putExequteQuery(userToken, data,brandSharedUid,setExequteQueryMessage );
      } catch (error) {
        // Handle any unexpected errors
        console.error('Error occurred while adding budget:', error);
      } finally {
        // Always stop the loading indicator
        setTimeout(() => setloading(false), 3000);
      }
    };

    useEffect(()=>{
      // Check if response indicates success (e.g., status 200 or success boolean)
      if (exequteQueryMessage && !loading) {
        // Close the modal if successful
        handleCloseModal();
        setRefresh(true);
      } 
      // eslint-disable-next-line
    },[exequteQueryMessage,loading])

  
    return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      >
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
          <DialogTitle sx={{ padding: "0px 0px 0px 0px" }}>
            Edit Monthly Budget
          </DialogTitle>
  
          <Grid container columnSpacing={3} rowSpacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px", color: "#000000DE" }}>
                Brand Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={Array.from(new Set((exequteQueryContentQueryCreateDataOutput || []).map((item: any) => item?.brand_name)))}
                // onChange={(event, value) => handleBrandChange(event, value as string | null)}
                value={selectedBrand}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Brand or choose from the list"
                    label="Brand name *"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ marginLeft: "8px" }} />
                        </InputAdornment>
                      ),
                    }}
                    color="secondary"
                  />
                )}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={selectedLinks.some((item: any) => item?.shown_in_active_table === true)}
                    onChange={handlePromotedLinksChange}
                    disabled
                  />
                }
                label="Show Promoted links only"
              />
            </Grid>
  
            {/* Render Autocomplete components for links */}
            {Array.from({ length: autocompleteCount }).map((_, index) => (
              <Grid key={index} item xs={9}>
                <Autocomplete
                  fullWidth
                  options={getFilteredOptions(index)}
                  value={selectedLinks[index] || null}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => handleLinkChange(index, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose from the list"
                      label={`Link ${index + 1} *`}
                      InputProps={{ ...params.InputProps }}
                      color="secondary"
                    />
                  )}
                  size="small"
                  disabled={disabledLinks[index]}
                />
              </Grid>
            ))}
  
            <Grid item xs={3}>
              <Button
                variant="outlined"
                size="medium"
                sx={{ py: "7px" }}
                fullWidth
                onClick={handleAddAnother}
                disabled={autocompleteCount >= 3 || !selectedLinks[autocompleteCount - 1]}
                color="secondary"
                endIcon={<AddIcon />}
              >
                Add another
              </Button>
            </Grid>
  
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "12px", color: "#00000099" }}>
                All fields are mandatory.
              </Typography>
            </Grid>
          </Grid>
  
          <Grid container columnSpacing={3} rowSpacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px", color: "#000000DE" }}>
                Budget Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth size="small" color="secondary">
                <InputLabel htmlFor="QFTDCost">QFTD Cost</InputLabel>
                <OutlinedInput
                  id="QFTDCost"
                  endAdornment={
                    <InputAdornment position="end" sx={{ color: "#00000061" }}>
                      <AttachMoneyIcon />
                    </InputAdornment>
                  }
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, delete, and a single period
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "." && e.key !== "Tab"// Allow the period character
                    ) {
                      e.preventDefault();
                    }
              
                    // Prevent multiple periods
                    if (e.key === "." && e.currentTarget.value.includes(".")) {
                      e.preventDefault();
                    }
                  }}
                  label="QFTD Cost"
                  onChange={(e)=>setCost(e.target.value)}
                  value={cost}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="QFTD Budget"
                onChange={(e)=>setBudget(e.target.value)}
                value={budget}
                placeholder=""
                multiline
                color="secondary"
                size="small"
                onKeyDown={(e:any) => {
                  // Allow digits, backspace, delete, and one decimal point
                  const validKeys = /[0-9.]/;
                  // Check if the key pressed is valid or if it is backspace/delete
                  if (!validKeys.test(e.key) && e.key !== "Backspace" && e.key !== "Delete"&& e.key !== "Tab") {
                    e.preventDefault();
                  }
              
                  // Allow only one decimal point
                  if (e.key === "." && (e.currentTarget.value || "").includes(".")) {
                    e.preventDefault();
                  }
                }}
                fullWidth
              />
            </Grid>
          </Grid>
  
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
            <Button color="secondary" variant="outlined" onClick={handleCloseModal} sx={{ mr: 1 }}>
              CANCEL
            </Button>
            <Button variant="contained" color="secondary" endIcon={<DoneIcon /> } 
            onClick={handleEditBudget} disabled={loading}>
              {loading ? <CircularProgress color="inherit" size="30px" /> : "Save Changes"}
            </Button>
          </Stack>
        </Paper>
      </Dialog>
      <Dialog
    open={showConfirmationDialog}
    onClose={() => setShowConfirmationDialog(false)}
    PaperProps={{ sx: { width: "400px", maxWidth: "400px" } }}
  >
    <DialogTitle>Confirm Link Addition</DialogTitle>
    <DialogContent>
      <Typography>
        The link is not displayed in the active table. Do you want to add it anyway?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setShowConfirmationDialog(false)} color="secondary">
        Cancel
      </Button>
      <Button
      onClick={() => {
      const updatedLinks = [...selectedLinks];
      if (linkToAdd) {
        updatedLinks[linkToAdd.index] = linkToAdd.link; // Add the link to the specified index
      }
      setSelectedLinks(updatedLinks);
      setShowConfirmationDialog(false);
    }}
    color="secondary"
  >
    Yes, Add It
  </Button>
  
    </DialogActions>
  </Dialog>
  
      </>
      
    );
  };
  
  export default BudgetControlModalEdit;
  