import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ThemeFilter from "./ThemeFilter";
import { Link as RouterLink } from "react-router-dom";
import RequestNewModal from "../ThemeModal/RequestNewModal";
import { useState } from "react";

interface ThemeFilterCardProps {
  combinedOptions: any;
  oauthTokenHeader: any;
  selectedTheme: any;
  setSelectedTheme: any;
  selectedSharedUid: any;
  setSelectedSharedUid: any;
  designThemeData: any;
  setDesignThemeData: any;
  designTemplatesData: any;
  setSelectedGroup: any;
  selectedGroup: any;
  setSelectedFeature: any;
  selectedFeature: any;
}

function ThemeFilterCard({combinedOptions, oauthTokenHeader, selectedTheme, setSelectedTheme, selectedSharedUid, setSelectedSharedUid, designThemeData, setDesignThemeData, designTemplatesData , setSelectedGroup, selectedGroup ,
  setSelectedFeature , selectedFeature
}:ThemeFilterCardProps) {
  const themes = designThemeData?.map((themes:any) => themes?.name);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }
  
  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={2}
        sx={{ padding: "16px 16px 0px 16px" }}
      >
        <Autocomplete
          sx={{ width: "300px" }}
          options={themes ? themes : []}
          value={selectedTheme}  // Set value to selected template
          onChange={(event, newValue:any) => {
            setSelectedTheme(newValue); // Update state with selected template 
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Theme Name"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <ThemeFilter setSelectedTheme={setSelectedTheme} designThemeData={designThemeData} setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} setSelectedFeature={setSelectedFeature} selectedFeature={selectedFeature}/>
          <Stack flexDirection={"row"} gap={2}>
            <Button
                variant="outlined"
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
                onClick={handleOpenModal}
              >
              Request New Design
            </Button>
            <RouterLink to="create_new_theme" state={{selectedSharedUid: selectedSharedUid,designThemeData: designThemeData,combinedOptions: combinedOptions, designTemplatesData: designTemplatesData}}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              <Stack flexDirection={"row"} gap={1}>
                Create Theme <AddIcon fontSize="small" />
              </Stack>
            </Button>
            </RouterLink>
          </Stack>
        </Stack>
        <RequestNewModal open={openModal} onClose={handleCloseModal} oauthTokenHeader={oauthTokenHeader} selectedSharedUid={selectedSharedUid} designThemeData={designThemeData} setDesignThemeData={setDesignThemeData}></RequestNewModal>
      </Stack>
    </>
  );
}
export default ThemeFilterCard;
