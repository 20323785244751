import { Outlet, useNavigate, useParams } from "react-router-dom";
import Page from "../../../components/Page/Page";
import CardScreen from "./Card/CardScreen";
import SitePagesScreen from "./SitePages/SitePagesScreen";
import { useContext } from "react";
import SharedStateContext from "../../../components/SharedStateProvider";

export default function PageManagementScreen(userToken: any) {

    const oauthTokenHeader = userToken.userToken;
    const { siteName } = useParams();
    const navigate = useNavigate();
    const { pageRefreshStatus , setPageRefreshStatus } = useContext(SharedStateContext);
    const setSelectedSiteName = (name: string) => {
        navigate(`/assets/page_management/${name}`);
    }

    return (
        <Page>
            <CardScreen selectedSiteName={siteName} setSelectedSiteName={setSelectedSiteName} />
            <SitePagesScreen userToken={oauthTokenHeader} selectedSiteName={siteName} pageRefreshStatus={pageRefreshStatus} setPageRefreshStatus={setPageRefreshStatus}/>
            <Outlet />
        </Page>
    )
}
