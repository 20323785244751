import {
  Autocomplete,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  Paper,
  TextField,
  Typography, 
  InputAdornment 
} from "@mui/material";
import { useState, useEffect } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack } from "@mui/system";
import { QuarterlySpeedGoalsEndPointLogic } from '../../../../../../logic/AssetManagement/Health/Quarterly Website Speed Goals/QuarterlySpeedGoalsEndPointLogic';

interface PageScanRequestModalProps {
  oauthTokenHeader: any;
  hasEditPermissions: any;
  hasGetPermissions: any;
  selectedSharedUid: any;
  selectedRowData: any
  setSelectedRowData: any
  setSelected: any
  rowId: any
  quarterlyGoalsData: any;
  setQuarterlyGoalsData: any;
  setLoading: any;
  loading: any;
  open: boolean;
  onClose: any;
}

const quateryear = [
  { label: "Q1", year: 2001 },
  { label: "Q2", year: 2002 },
  { label: "Q3", year: 2003 },
  { label: "Q4", year: 2004 },
  { label: "Q5", year: 2005 },
];

function EditGoalModal({selectedSharedUid,oauthTokenHeader, rowId,selectedRowData,setSelectedRowData, setSelected, hasEditPermissions,hasGetPermissions,quarterlyGoalsData,setQuarterlyGoalsData,setLoading,loading,open,onClose}: PageScanRequestModalProps) {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [mobileLCP, setMobileLCP] = useState('');
  const [mobileINP, setMobileINP] = useState('');
  const [mobileCLS, setMobileCLS] = useState('');
  const [mobileFCP, setMobileFCP] = useState('');
  const [mobileTTFB, setMobileTTFB] = useState('');
  const [mobileMobileSpeedScore, setMobileMobileSpeedScore] = useState('');


  useEffect(() => {
    if(open) {
      setMobileLCP(selectedRowData?.largest_contentful_paint);
      setMobileINP(selectedRowData?.interaction_to_next_paint);
      setMobileCLS(selectedRowData?.cumulative_layout_shift);
      setMobileFCP(selectedRowData?.first_contentful_paint);
      setMobileTTFB(selectedRowData?.time_to_first_byte);
      setMobileMobileSpeedScore(selectedRowData?.mobile_speed_score_from_ads_platform);
    }
  },[open,selectedRowData]);


  const handleCloseEditModal = () => {
    setSelectedRowData("");
    onClose();
    setSelected([]);
  }

  const UpdateGoal = async() => {
    const data = {
      psi_goal: selectedRowData?.shared_uid,
      largest_contentful_paint: parseFloat(mobileLCP),
      interaction_to_next_paint: parseFloat(mobileINP),
      cumulative_layout_shift: parseFloat(mobileCLS),
      first_contentful_paint: parseFloat(mobileFCP),
      time_to_first_byte: parseFloat(mobileTTFB),
      mobile_speed_score_from_ads_platform: parseInt(mobileMobileSpeedScore),
    }
    try {
      if(hasEditPermissions){
        await QuarterlySpeedGoalsEndPointLogic.editQuarterlySpeedGoals(oauthTokenHeader, selectedSharedUid, data);
        setSnackbarOpen(true);
        setSnackbarMessage("Quarterly Goals Updated Successfully");
        setMobileLCP("");
        setMobileINP("");
        setMobileCLS("");
        setMobileFCP("");
        setMobileTTFB("");
        setMobileMobileSpeedScore("");
        setSelected([]);
        setSelectedRowData("");
        onClose();
      } else {
        throw new Error("User doesn't have permissions to edit quarterly goals");
      }
      if(hasGetPermissions) {
        setLoading(true);
        await QuarterlySpeedGoalsEndPointLogic.getQuarterlySpeedGoals(oauthTokenHeader, selectedSharedUid, setQuarterlyGoalsData, setError);
      } else {
        throw new Error("User doesn't have permissions to get quarterly goals");
      }
    } catch(error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error updating sequence');
      console.error(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validate = async () => {
    // Validate all fields
    if(!mobileLCP || !mobileINP || !mobileCLS || !mobileFCP || !mobileTTFB || !mobileMobileSpeedScore) {
      setSnackbarOpen(true);
      setSnackbarMessage("All fields are mandatory.");
      setIsValid(false); // Validation failed
      return false; // Validation failed
    }
    if (mobileMobileSpeedScore) {
      const score = parseInt(mobileMobileSpeedScore, 10); // Convert the score to an integer
      if (score < 1 || score > 10) {
        setSnackbarOpen(true);
        setSnackbarMessage("Mobile Speed Score must be between 1 and 10.");
        setIsValid(false); // Validation failed
        return false; // Validation failed
      }
    }
    setIsValid(true); // Validation passed
    return true; // Validation passed
  };

  const handleUpdateGoal = async() => {
    const isFormValid = await validate();
    if(!isFormValid) return;
    try {
      setLoading(true);
      await UpdateGoal();
    } catch(error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: '9999999' }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('Error') || isValid ? "success" : "error"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  };

  return (
    <>
      {renderSnackbar()}
      <Dialog
      open={open}
      onClose={handleCloseEditModal}
      PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
    >
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
           Edit Quarterly Goals
        </DialogTitle>

        {/* preiod */}
        <Box>
        <DialogTitle sx={{ fontSize:"16px", padding: "0px 0px 8px 0px",fontWeight:"400" }}>
         Period
        </DialogTitle>
        <Stack flexDirection={"row"} gap={2}>
          <Box>
          <Autocomplete
          options={quateryear}
          disabled={true}
          value={quateryear.find(q => q.label === `Q${selectedRowData?.period?.split('/')[0]}`) || null}
          disablePortal
          sx={{ width: 418 }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Quarter" color="secondary"/>}
        />
        </Box>
        <Box>
        <Autocomplete
          disabled={true}
          disablePortal
          options={quateryear}
          value={selectedRowData?.period?.split('/')[1]}
          sx={{ width: 418 }}
          size="small"
          color="secondary"
          renderInput={(params) => <TextField {...params} label="Year" color="secondary"/>}
        />
        </Box>
        </Stack>
        </Box>
        {/* mobile */}
        <Box marginTop={2}>
          <DialogTitle sx={{ fontSize:"16px", padding: "0px 0px 8px 0px",fontWeight:"400" }}>
           {selectedRowData?.device}
          </DialogTitle>
          <Stack gap={2}>
            <Stack flexDirection={"row"} gap={2}>
                  <TextField
                  id="outlined-multiline-flexible"
                  label="LCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  sx={{
                    width: "274px",
                  }}
                  value={mobileLCP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileLCP(e.target.value)}}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="INP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  sx={{
                    width: "274px",
                  }}
                  value={mobileINP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileINP(e.target.value)}}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="CLS"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  sx={{
                    width: "274px",
                  }}
                  value={mobileCLS}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ms</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileCLS(e.target.value)}}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="FCP"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  sx={{ width: "274px" }}
                  value={mobileFCP}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileFCP(e.target.value)}}
                  onKeyDown={(e) => {
                    // Allow only digits, decimal point, backspace, delete, and arrow keys
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="TTFB"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  required
                  sx={{
                    width: "274px",
                  }}
                  value={mobileTTFB}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">s</InputAdornment>,
                  }}
                  onChange={(e) => {setMobileTTFB(e.target.value)}}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                {selectedRowData?.device === "Mobile" ? (
                  <TextField
                  id="outlined-multiline-flexible"
                  label="Mobile Speed Score"
                  multiline
                  color="secondary"
                  size="small"
                  maxRows={4}
                  value={mobileMobileSpeedScore}
                  onChange={(e) => setMobileMobileSpeedScore(e.target.value)}
                  required
                  sx={{
                    width: "274px",
                  }}
                  onKeyDown={(e) => {
                    // Allow only digits, backspace, and delete
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                ) :(
                  null
                )}
            </Stack>
          </Stack>
        </Box>
        <Typography sx={{fontSize:"12px", lineHeight:"20px", letterSpacing:"0.4", color:'#00000099', marginTop:"16px"}}>All fields are mandatory.</Typography>
        <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2}>
            <Button color="secondary"  onClick={handleCloseEditModal}>CANCEL</Button>
            <Button variant="contained" color="secondary" onClick={handleUpdateGoal}><Stack flexDirection={"row"} alignItems={"center"} gap={1}>{loading ? 'Updating...' : 'SAVE CHANGES'} <SaveIcon /></Stack></Button>
        </Stack>
      </Paper>
    </Dialog>
    </>
  );
}

export default EditGoalModal;
