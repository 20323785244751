export enum ModulesPermissionNames {
	POST_API_PSI_GOALS = "POST_API_PSI_GOALS",
  DELETE_API_PSI_GOALS = "DELETE_API_PSI_GOALS",
  PUT_API_PSI_GOALS = "PUT_API_PSI_GOALS",
  GET_API_PSI_GOALS = "GET_API_PSI_GOALS",
  POST_API_MONITOR_SITE = "POST_API_MONITOR_SITE",
  DELETE_API_MONITOR_SITE = "DELETE_API_MONITOR_SITE",
  PUT_API_MONITOR_SITE = "PUT_API_MONITOR_SITE",
  GET_API_MONITOR_SITE = "GET_API_MONITOR_SITE",
  POST_API_PSI_RESULT = "POST_API_PSI_RESULT",
  DELETE_API_PSI_RESULT = "DELETE_API_PSI_RESULT",
  PUT_API_PSI_RESULT = "PUT_API_PSI_RESULT",
  GET_API_PSI_RESULT = "GET_API_PSI_RESULT",
  POST_API_SERP_COMPETITOR = "POST_API_SERP_COMPETITOR",
  DELETE_API_SERP_COMPETITOR = "DELETE_API_SERP_COMPETITOR",
  PUT_API_SERP_COMPETITOR = "PUT_API_SERP_COMPETITOR",
  GET_API_SERP_COMPETITOR = "GET_API_SERP_COMPETITOR",
  POST_API_PSI_SITE = "POST_API_PSI_SITE",
  DELETE_API_PSI_SITE = "DELETE_API_PSI_SITE",
  PUT_API_PSI_SITE = "PUT_API_PSI_SITE",
  GET_API_PSI_SITE = "GET_API_PSI_SITE",
  POST_API_EXPENSE = "POST_API_EXPENSE",
  DELETE_API_EXPENSE = "DELETE_API_EXPENSE",
  PUT_API_EXPENSE = "PUT_API_EXPENSE",
  GET_API_EXPENSE = "GET_API_EXPENSE",
  POST_API_EXPENSES = "POST_API_EXPENSES",
  DELETE_API_EXPENSES = "DELETE_API_EXPENSES",
  PUT_API_EXPENSES = "PUT_API_EXPENSES",
  GET_API_EXPENSES = "GET_API_EXPENSES",
  POST_API_COMMENT = "POST_API_COMMENT",
  DELETE_API_COMMENT = "DELETE_API_COMMENT",
  PUT_API_COMMENT = "PUT_API_COMMENT",
  GET_API_COMMENT = "GET_API_COMMENT",
  POST_API_LABELS = "POST_API_LABELS",
  DELETE_API_LABELS = "DELETE_API_LABELS",
  PUT_API_LABELS = "PUT_API_LABELS",
  GET_API_LABELS = "GET_API_LABELS",
  POST_API_ACTIVITY_NOTE = "POST_API_ACTIVITY_NOTE",
  DELETE_API_ACTIVITY_NOTE = "DELETE_API_ACTIVITY_NOTE",
  PUT_API_ACTIVITY_NOTE = "PUT_API_ACTIVITY_NOTE",
  GET_API_ACTIVITY_NOTE = "GET_API_ACTIVITY_NOTE",
  POST_API_ACTIVITY_NOTES = "POST_API_ACTIVITY_NOTES",
  DELETE_API_ACTIVITY_NOTES = "DELETE_API_ACTIVITY_NOTES",
  PUT_API_ACTIVITY_NOTES = "PUT_API_ACTIVITY_NOTES",
  GET_API_ACTIVITY_NOTES = "GET_API_ACTIVITY_NOTES",
  POST_API_CRONWORKFLOW = "POST_API_CRONWORKFLOW",
  DELETE_API_CRONWORKFLOW = "DELETE_API_CRONWORKFLOW",
  PUT_API_CRONWORKFLOW = "PUT_API_CRONWORKFLOW",
  GET_API_CRONWORKFLOW = "GET_API_CRONWORKFLOW",
  POST_API_WORKFLOWTEMPLATE = "POST_API_WORKFLOWTEMPLATE",
  DELETE_API_WORKFLOWTEMPLATE = "DELETE_API_WORKFLOWTEMPLATE",
  PUT_API_WORKFLOWTEMPLATE = "PUT_API_WORKFLOWTEMPLATE",
  GET_API_WORKFLOWTEMPLATE = "GET_API_WORKFLOWTEMPLATE",
  POST_API_WORKFLOW = "POST_API_WORKFLOW",
  DELETE_API_WORKFLOW = "DELETE_API_WORKFLOW",
  PUT_API_WORKFLOW = "PUT_API_WORKFLOW",
  GET_API_WORKFLOW = "GET_API_WORKFLOW",
  POST_API_KUMA = "POST_API_KUMA",
  DELETE_API_KUMA = "DELETE_API_KUMA",
  PUT_API_KUMA = "PUT_API_KUMA",
  GET_API_KUMA = "GET_API_KUMA",
  POST_API_PRODUCT = "POST_API_PRODUCT",
  DELETE_API_PRODUCT = "DELETE_API_PRODUCT",
  PUT_API_PRODUCT = "PUT_API_PRODUCT",
  GET_API_PRODUCT = "GET_API_PRODUCT",
  POST_API_DEVICE = "POST_API_DEVICE",
  DELETE_API_DEVICE = "DELETE_API_DEVICE",
  PUT_API_DEVICE = "PUT_API_DEVICE",
  GET_API_DEVICE = "GET_API_DEVICE",
  POST_API_INDUSTRY = "POST_API_INDUSTRY",
  DELETE_API_INDUSTRY = "DELETE_API_INDUSTRY",
  PUT_API_INDUSTRY = "PUT_API_INDUSTRY",
  GET_API_INDUSTRY = "GET_API_INDUSTRY",
  POST_API_GEO = "POST_API_GEO",
  POST_API_PAGE_GROUPS = "POST_API_PAGE_GROUPS",
  DELETE_API_GEO = "DELETE_API_GEO",
  PUT_API_GEO = "PUT_API_GEO",
  GET_API_GEO = "GET_API_GEO",
  POST_API_TERRITORY = "POST_API_TERRITORY",
  DELETE_API_TERRITORY = "DELETE_API_TERRITORY",
  PUT_API_TERRITORY = "PUT_API_TERRITORY",
  GET_API_TERRITORY = "GET_API_TERRITORY",
  POST_API_TRAFFIC_SOURCES = "POST_API_TRAFFIC_SOURCES",
  DELETE_API_TRAFFIC_SOURCES = "DELETE_API_TRAFFIC_SOURCES",
  PUT_API_TRAFFIC_SOURCES = "PUT_API_TRAFFIC_SOURCES",
  GET_API_TRAFFIC_SOURCES = "GET_API_TRAFFIC_SOURCES",
  POST_API_CHAIN_TOGGLE = "POST_API_CHAIN_TOGGLE",
  DELETE_API_CHAIN_TOGGLE = "DELETE_API_CHAIN_TOGGLE",
  PUT_API_CHAIN_TOGGLE = "PUT_API_CHAIN_TOGGLE",
  GET_API_CHAIN_TOGGLE = "GET_API_CHAIN_TOGGLE",
  POST_API_CHAIN = "POST_API_CHAIN",
  DELETE_API_CHAIN = "DELETE_API_CHAIN",
  PUT_API_CHAIN = "PUT_API_CHAIN",
  GET_API_CHAIN = "GET_API_CHAIN",
  POST_API_LOGS = "POST_API_LOGS",
  DELETE_API_LOGS = "DELETE_API_LOGS",
  PUT_API_LOGS = "PUT_API_LOGS",
  GET_API_LOGS = "GET_API_LOGS",
  POST_API_PROCESS_TOGGLE = "POST_API_PROCESS_TOGGLE",
  DELETE_API_PROCESS_TOGGLE = "DELETE_API_PROCESS_TOGGLE",
  PUT_API_PROCESS_TOGGLE = "PUT_API_PROCESS_TOGGLE",
  GET_API_PROCESS_TOGGLE = "GET_API_PROCESS_TOGGLE",
  POST_API_PROCESSES = "POST_API_PROCESSES",
  DELETE_API_PROCESS = "DELETE_API_PROCESS",
  PUT_API_PROCESSES = "PUT_API_PROCESSES",
  GET_API_PROCESSES = "GET_API_PROCESSES",
  POST_API_DEPLOY_ETL = "POST_API_DEPLOY_ETL",
  DELETE_API_DEPLOY_ETL = "DELETE_API_DEPLOY_ETL",
  PUT_API_DEPLOY_ETL = "PUT_API_DEPLOY_ETL",
  GET_API_DEPLOY_ETL = "GET_API_DEPLOY_ETL",
  POST_API_SEQUENCE_TOGGLE = "POST_API_SEQUENCE_TOGGLE",
  DELETE_API_SEQUENCE_TOGGLED = "DELETE_API_SEQUENCE_TOGGLE",
  PUT_API_SEQUENCE_TOGGLE = "PUT_API_SEQUENCE_TOGGLE",
  GET_API_SEQUENCE_TOGGLE = "GET_API_SEQUENCE_TOGGLE",
  POST_API_SEQUENCE_ID = "POST_API_SEQUENCE_ID",
  DELETE_API_SEQUENCE_ID = "DELETE_API_SEQUENCE_ID",
  PUT_API_SEQUENCE_ID = "PUT_API_SEQUENCE_ID",
  GET_API_SEQUENCE_ID = "GET_API_SEQUENCE_ID",
  POST_API_SEQUENCES = "POST_API_SEQUENCES",
  DELETE_API_SEQUENCES = "DELETE_API_SEQUENCES",
  PUT_API_SEQUENCES = "PUT_API_SEQUENCES",
  GET_API_SEQUENCES = "GET_API_SEQUENCES",
  POST_API_AD_ACCOUNT_TOGGLE = "POST_API_AD_ACCOUNT_TOGGLE",
  DELETE_API_AD_ACCOUNT_TOGGLE = "DELETE_API_AD_ACCOUNT_TOGGLE",
  PUT_API_AD_ACCOUNT_TOGGLE = "PUT_API_AD_ACCOUNT_TOGGLE",
  GET_API_AD_ACCOUNT_TOGGLE = "GET_API_AD_ACCOUNT_TOGGLE",
  GET_API_DESIGN_TEMPLATES_DETAILS = "GET_API_DESIGN_TEMPLATES_DETAILS",
  PUT_API_DESIGN_TEMPLATES_DETAILS = "PUT_API_DESIGN_TEMPLATES_DETAILS",
  DELETE_API_DESIGN_TEMPLATES_DETAILS = "DELETE_API_DESIGN_TEMPLATES_DETAILS",
  POST_API_DESIGN_TEMPLATES_DETAILS = "POST_API_DESIGN_TEMPLATES_DETAILS",
  GET_API_DESIGN_TEMPLATES_LIST = "GET_API_DESIGN_TEMPLATES_LIST",
  PUT_API_DESIGN_TEMPLATES_LIST = "PUT_API_DESIGN_TEMPLATES_LIST",
  DELETE_API_DESIGN_TEMPLATES_LIST = "DELETE_API_DESIGN_TEMPLATES_LIST",
  POST_API_DESIGN_TEMPLATES_LIST = "POST_API_DESIGN_TEMPLATES_LIST",
  POST_API_PAGE_PREVIEW = "POST_API_PAGE_PREVIEW",
  DELETE_API_PAGE_PREVIEW = "DELETE_API_PAGE_PREVIEW",
  PUT_API_PAGE_PREVIEW = "PUT_API_PAGE_PREVIEW",
  GET_API_PAGE_PREVIEW = "GET_API_PAGE_PREVIEW",
  POST_API_AD_ACCOUNT_DETAILS = "POST_API_AD_ACCOUNT_DETAILS",
  DELETE_API_AD_ACCOUNT_DETAILS = "DELETE_API_AD_ACCOUNT_DETAILS",
  PUT_API_AD_ACCOUNT_DETAILS = "PUT_API_AD_ACCOUNT_DETAILS",
  GET_API_AD_ACCOUNT_DETAILS = "GET_API_AD_ACCOUNT_DETAILS",
  POST_WP_API_WEBSITE_WIDGETS = "POST_WP_API_WEBSITE_WIDGETS",
  DELETE_WP_API_WEBSITE_WIDGETS = "DELETE_WP_API_WEBSITE_WIDGETS",
  PUT_WP_API_WEBSITE_WIDGETS = "PUT_WP_API_WEBSITE_WIDGETS",
  GET_WP_API_WEBSITE_WIDGETS = "GET_WP_API_WEBSITE_WIDGETS",
  POST_WP_API_WEBSITE_TEMPLATES = "POST_WP_API_WEBSITE_TEMPLATES",
  DELETE_WP_API_WEBSITE_TEMPLATES = "DELETE_WP_API_WEBSITE_TEMPLATES",
  PUT_WP_API_WEBSITE_TEMPLATES = "PUT_WP_API_WEBSITE_TEMPLATES",
  GET_WP_API_WEBSITE_TEMPLATES = "GET_WP_API_WEBSITE_TEMPLATES",
  POST_WP_API_WEBSITE_PAGES = "POST_WP_API_WEBSITE_PAGES",
  DELETE_WP_API_WEBSITE_PAGES = "DELETE_WP_API_WEBSITE_PAGES",
  PUT_WP_API_WEBSITE_PAGES = "PUT_WP_API_WEBSITE_PAGES",
  GET_WP_API_WEBSITE_PAGES = "GET_WP_API_WEBSITE_PAGES",
  POST_WP_API_PAGES = "POST_WP_API_PAGES",
  DELETE_WP_API_PAGES = "DELETE_WP_API_PAGES",
  PUT_WP_API_PAGES = "PUT_WP_API_PAGES",
  GET_WP_API_PAGES = "GET_WP_API_PAGES",
  POST_WP_API_WIDGETS = "POST_WP_API_WIDGETS",
  DELETE_WP_API_WIDGETS = "DELETE_WP_API_WIDGETS",
  PUT_WP_API_WIDGETS = "PUT_WP_API_WIDGETS",
  GET_WP_API_WIDGETS = "GET_WP_API_WIDGETS",
  POST_WP_API_TEMPLATES = "POST_WP_API_TEMPLATES",
  DELETE_WP_API_TEMPLATES = "DELETE_WP_API_TEMPLATES",
  PUT_WP_API_TEMPLATES = "PUT_WP_API_TEMPLATES",
  GET_WP_API_TEMPLATES = "GET_WP_API_TEMPLATES",
  POST_API_BRANDS_DATA = "POST_API_BRANDS_DATA",
  DELETE_API_BRANDS_DATA = "DELETE_API_BRANDS_DATA",
  PUT_API_BRANDS_DATA = "PUT_API_BRANDS_DATA",
  GET_API_BRANDS_DATA = "GET_API_BRANDS_DATA",
  POST_API_FILE = "POST_API_FILE",
  DELETE_API_FILE = "DELETE_API_FILE",
  PUT_API_FILE = "PUT_API_FILE",
  GET_API_FILE = "GET_API_FILE",
  POST_API_USER_CLICK_OUT = "POST_API_USER_CLICK_OUT",
  DELETE_API_USER_CLICK_OUT = "DELETE_API_USER_CLICK_OUT",
  PUT_API_USER_CLICK_OUT = "PUT_API_USER_CLICK_OUT",
  GET_API_USER_CLICK_OUT = "GET_API_USER_CLICK_OUT",
  POST_API_USER_CLICK_IN = "POST_API_USER_CLICK_IN",
  DELETE_API_USER_CLICK_IN = "DELETE_API_USER_CLICK_IN",
  PUT_API_USER_CLICK_IN = "PUT_API_USER_CLICK_IN",
  GET_API_USER_CLICK_IN = "GET_API_USER_CLICK_IN",
  POST_API_SITE_ACCOUNT = "POST_API_SITE_ACCOUNT",
  DELETE_API_SITE_ACCOUNT = "DELETE_API_SITE_ACCOUNT",
  PUT_API_SITE_ACCOUNT = "PUT_API_SITE_ACCOUNT",
  GET_API_SITE_ACCOUNT = "GET_API_SITE_ACCOUNT",
  POST_API_AD_ACCOUNT = "POST_API_AD_ACCOUNT",
  DELETE_API_AD_ACCOUNT = "DELETE_API_AD_ACCOUNT",
  PUT_API_AD_ACCOUNT = "PUT_API_AD_ACCOUNT",
  GET_API_AD_ACCOUNT = "GET_API_AD_ACCOUNT",
  POST_API_AD_ACCOUNTS = "POST_API_AD_ACCOUNTS",
  DELETE_API_AD_ACCOUNTS = "DELETE_API_AD_ACCOUNTS",
  PUT_API_AD_ACCOUNTS = "PUT_API_AD_ACCOUNTS",
  GET_API_AD_ACCOUNTS = "GET_API_AD_ACCOUNTS",
  POST_API_WIDGETS = "POST_API_WIDGETS",
  DELETE_API_WIDGETS = "DELETE_API_WIDGETS",
  PUT_API_WIDGETS = "PUT_API_WIDGETS",
  GET_API_WIDGETS = "GET_API_WIDGETS",
  POST_API_THEME = "POST_API_THEME",
  DELETE_API_THEME = "DELETE_API_THEME",
  PUT_API_THEME = "PUT_API_THEME",
  GET_API_THEME = "GET_API_THEME",
  GET_API_DESIGN_THEME_LIST = "GET_API_DESIGN_THEME_LIST",
  POST_API_DESIGN_THEME_LIST = "POST_API_DESIGN_THEME_LIST",
  DELETE_API_DESIGN_THEME_LIST = "DELETE_API_DESIGN_THEME_LIST",
  PUT_API_DESIGN_THEME_LIST = "PUT_API_DESIGN_THEME_LIST",
  GET_API_DESIGN_THEMES_DETAIL = "GET_API_DESIGN_THEMES_DETAIL",
  POST_API_DESIGN_THEMES_DETAIL = "POST_API_DESIGN_THEMES_DETAIL",
  DELETE_API_DESIGN_THEMES_DETAIL = "DELETE_API_DESIGN_THEMES_DETAIL",
  PUT_API_DESIGN_THEMES_DETAIL = "PUT_API_DESIGN_THEMES_DETAIL",
  POST_API_PAGE = "POST_API_PAGE",
  DELETE_API_PAGE = "DELETE_API_PAGE",
  PUT_API_PAGE = "PUT_API_PAGE",
  GET_API_PAGE = "GET_API_PAGE",
  POST_API_PAGES = "POST_API_PAGES",
  DELETE_API_PAGES = "DELETE_API_PAGES",
  PUT_API_PAGES = "PUT_API_PAGES",
  GET_API_PAGES = "GET_API_PAGES",
  DELETE_API_PAGE_GROUPS = "DELETE_API_PAGE_GROUPS",
  PUT_API_PAGE_GROUPS = "PUT_API_PAGE_GROUPS",
  GET_API_PAGE_GROUPS = "GET_API_PAGE_GROUPS",
  POST_API_IMAGES = "POST_API_IMAGES",
  DELETE_API_IMAGES = "DELETE_API_IMAGES",
  PUT_API_IMAGES = "PUT_API_IMAGES",
  GET_API_IMAGES = "GET_API_IMAGES",
  GET_API_PSI_COMPETITORS_RESULT = 'GET_API_PSI_COMPETITORS_RESULT',
  GET_API_PSI_COMPETITORS = 'GET_API_PSI_COMPETITORS',
  GET_API_BIZ_LAYER_QUERIES_LIST = 'GET_API_BIZ_LAYER_QUERIES_LIST',
  GET_API_BIZ_LAYER_QUERY= 'GET_API_BIZ_LAYER_QUERY',
  GET_API_RUN_BIZ_LAYER_QUERY='GET_API_RUN_BIZ_LAYER_QUERY'
}
