import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  Autocomplete,
  Alert,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/system";
import dayjs from "dayjs"; // Import dayjs
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
/* import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; */
import AdditionalContentAttachments from "../CustomTableActivityNotes/AdditionalContentAttachments";
import CancelIcon from "@mui/icons-material/Cancel";
import { ActivityNotesEndPointLogic } from '../../../../logic/AssetManagement/ActivityNotes/ActivityNotesLogic/ActivityNotesEndPointLogic';
import SimpleBackdrop from '../../../BackDrop/BackDrop';

interface AddNoteDialogProps {
  oauthTokenHeader: any;
  selectedRows:any;
  setActivityNotesContent: any;
  open: boolean;
  handleClose: () => void;
  selectedNote: string;
  setSelectedNote: any;
  postActivityNotes: Function;
  userData: any;
  labelsContent: any;
  postedActivityNotesContent: any;
  postFileUpload: Function;
  selectedAccount: any;
  fileBase64s: any;
  setFileBase64s: any;
  setPostedActivityNotesContent: any;
  getFileContent: any;
  getFiles: any;
  isThisAdd: any;
  files: any;
  setFiles: any;
  setSelectedLabel: any;
  selectedLabel: any;
  isDragActive: any;
  setIsDragActive: any;
  isLoading:any
}

interface Option {
  label: string;
  value: string; // Add this line to define the value property
}

const AddNoteDialog: React.FC<AddNoteDialogProps> = ({
  isDragActive,
  selectedRows,
  setActivityNotesContent,
  setIsDragActive,
  setSelectedLabel,
  selectedLabel,
  files,
  setFiles,
  isThisAdd,
  getFileContent,
  getFiles,
  setPostedActivityNotesContent,
  fileBase64s,
  setFileBase64s,
  selectedAccount,
  postFileUpload,
  oauthTokenHeader,
  postedActivityNotesContent,
  labelsContent,
  userData,
  open,
  handleClose,
  selectedNote,
  setSelectedNote,
  postActivityNotes,
  isLoading
}) => {
  const [date,setDate]=useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedStakeHolder, setSelectedStakeHolder] = useState("");
  const [note, setNote] = useState("");
  const [isInputMissing, setIsInputMissing] = useState(false);
  const [fileName, setFileName] = useState(""); // file name
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const hasCommentBeenPosted = useRef(false);
  const singleNoteShareUID = selectedRows[0]?.shared_uid;

  const handleSave = async () => {
    const data = {
      data: {
        website_shared_uid: `${selectedAsset}`,
        note_type: `${selectedStakeHolder}`,
        "note": `${note}`,
        labels: selectedLabel,
        activity_date: date
      },
    };

    if (note && selectedAsset && selectedStakeHolder && date) {
      setLoading(true);
      await postActivityNotes(data);
      await ActivityNotesEndPointLogic.getActivityNotesContent(oauthTokenHeader, setActivityNotesContent);
      // Ensure shared_uid is available from postedActivityNotesContent before calling postComment
      setSnackbarOpen(true);
      setSnackbarMessage('Note Created Successfully');
      handleClose();
      setLoading(false);
      setSelectedAsset('')
      setSelectedStakeHolder('')
      setNote('');
      setDate("");
      setSelectedLabel([])
    } else {
      setIsInputMissing(true);
    }
  };

  const handleCancel = () =>{
    handleClose();
    setSelectedAsset('')
    setSelectedStakeHolder('')
    setDate('')
    setNote('')
    setSelectedLabel([])
  }

  const stakeHolder = [{ label: "PPC" }, { label: "BizDev" }];
  const options: Option[] = userData?.data?.website_permissions?.map(
    (item: any) => {
      const key = Object.keys(item)[0];
      const siteName = item[key].site_name;
      return { label: siteName, value: key };
    }
  );
  const labels = labelsContent?.map((item: any) => item?.label);

  useEffect(() => {
    if (isInputMissing) {
      const timer = setTimeout(() => {
        setIsInputMissing(false);
      }, 3000);
      return () => clearTimeout(timer); // Clear the timer on component unmount
    }
  }, [isInputMissing]);

  const dialogContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Reset scroll position on component mount or dialog open
    if (dialogContentRef?.current) {
      dialogContentRef.current.scrollTop = 0;
    }
    if (isInputMissing && dialogContentRef?.current) {
      dialogContentRef?.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isInputMissing]);

  useEffect(() => {
    /* const sharedUid = postedActivityNotesContent[0]?.shared_uid; */
    try {
      if (
        postedActivityNotesContent[0] &&
        !hasCommentBeenPosted.current &&
        files?.length > 0
      ) {
        // Pass the shared_uid to postComment
        for (let i = 0; i < fileBase64s?.length; i++) {
          const fileData = {
            object_type: "business_unit_activity_notes",
            file_name: `${files[i]?.file?.name}`, // Optional: You can give a unique name for each file
            file: fileBase64s[i], // Get the corresponding base64 string
          };

          postFileUpload(fileData, postedActivityNotesContent[0]); // Upload the file
        }
        setFileBase64s([]);
        hasCommentBeenPosted.current = true; // Mark as called
        /*  setFiles([]) */
        setPostedActivityNotesContent([]);
      }
    } catch (error) {
      console.error("error");
    }
  }, [
    postedActivityNotesContent,
    setPostedActivityNotesContent,
    fileBase64s,
    postFileUpload,
    setFileBase64s,
    files,
    setFiles,
  ]);

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity='success'
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
    };

  return (
    <>
      {loading ? <SimpleBackdrop /> : (
      <>
      {renderSnackbar()}
      <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: { width: "900px", maxWidth: "900px", overflowY: "auto" },
      }}
    >
      <Paper
        sx={{
          padding: "24px 24px 20px 24px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        ref={dialogContentRef} // Apply the ref to the scrollable content
      >
        <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
          Add New Note
        </DialogTitle>
        {isInputMissing && (
          <Alert
            sx={{
              height: "50%",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              padding: "0 4px",
            }}
            severity="warning"
          >
            Input missing, Please try again!
          </Alert>
        )}
        <Typography>Details</Typography>
        <DialogContent sx={{ padding: "0" }}>
          <TextField
            required
            size="small"
            color="secondary"
            sx={{ width: "100%", mt: "8px", mb: "16px", padding: "0" }}
            id="filled-size-normal"
            label="Note Title"
            placeholder="Please select an industry from the list."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Stack flexDirection="row" gap="16px">
            <Autocomplete
              options={options}
              onChange={(event, newValue:any) => {
                setSelectedAsset(newValue ? newValue.value : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Asset *"
                  placeholder="Choose Asset from the list."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
              sx={{ width: "50%" }}
            />
            <Autocomplete
              disabled
              options={stakeHolder}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Brand"
                  placeholder="Choose Brand from the list."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
              sx={{ width: "50%" }}
            />
          </Stack>
          <Stack flexDirection="row" gap="16px" mt="16px" mb="16px">
            <Autocomplete
              options={stakeHolder}
              onChange={(event, newValue:any) => {
                  setSelectedStakeHolder(newValue ? newValue?.label : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stakeholder *"
                  placeholder="Choose Stakeholder from the list."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              size="small"
              sx={{ width: "49%" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ paddingTop: "0", overflow: "visible" }}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  className="brandDataDatePicker"
                  slotProps={{
                    textField: {
                      size: "small",
                      color: "secondary",
                      required: true,
                      error: false, // Ensure error state is false
                      // Add other props you want to pass to TextField here
                    },
                  }}
                  onChange={(date) => {
                    if (date && dayjs(date).isValid()) {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setDate(formattedDate);
                    } else {
                      setDate(""); // Reset date if invalid
                    }
                  }}
                  sx={{ width: "100%", minWidth: "418px !important" }}
                  label="Start Date"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </DialogContent>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          Fields marked with * are mandatory.
        </Typography>
        <Typography mt="16px" mb="8px">
          Labels
        </Typography>
        <Autocomplete
          multiple
          freeSolo // Allow free input
          id="size-small-outlined-multi"
          options={labels || ["N/A"]} // Using the labels array directly
          getOptionLabel={(option: any) => option} // No need for option.title, as option is already a string
          value={selectedLabel} // Bind selected value to state
          onChange={(event, newValue: any) => {
            setSelectedLabel(newValue); // Update the state with selected options including free input
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="labels"
              placeholder="Favorites"
              color="secondary"
            />
          )}
          sx={{ width: "500px" }}
          ChipProps={{
            sx: {
              backgroundColor: "#9C27B0",
              color: "#ffffff",
            },
            deleteIcon: (
              <CancelIcon
                sx={{ color: "rgba(255, 255, 255, 0.5) !important" }}
              />
            ),
          }}
          ListboxProps={{
            sx: {
              backgroundColor: "#ffffff",
              color: "#000000",
            },
          }}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                backgroundColor: selected ? "#F7EEF9" : "transparent",
              }}
            >
              {option}
            </li>
          )}
        />
        <Typography mt="16px" mb="8px">
          Attachments
        </Typography>
        <AdditionalContentAttachments
          singleNoteShareUID={singleNoteShareUID}
          isDragActive={isDragActive}
          setIsDragActive={setIsDragActive}
          files={files}
          setFiles={setFiles}
          isThisAdd={isThisAdd}
          getFileContent={getFileContent}
          selectedAccount={selectedAccount}
          postFileUpload={postFileUpload}
          fileBase64s={fileBase64s}
          setFileBase64s={setFileBase64s}
          setFileName={setFileName}
          fileName={fileName}
        />
        <DialogActions sx={{ padding: "16px 0 0 0" }}>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={!note || !selectedStakeHolder || !selectedAsset || !date || loading } color="secondary" variant="contained">
            {loading ? <CircularProgress color="inherit" size="30px" /> : "ADD NOTE"}
          </Button>
        </DialogActions>
      </Paper>
      </Dialog>
        </>
      )}
      
    </>
  );
};

export default AddNoteDialog;
