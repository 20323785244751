import {
    Autocomplete,
    Button,
    Dialog,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    Paper,
    Radio,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box, Stack} from "@mui/system";
  import ChevronRightIcon from '@mui/icons-material/ChevronRight';
  import { useState } from "react";
  import Grid from '@mui/material/Grid';
  import AddIcon from '@mui/icons-material/Add';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  import PercentIcon from '@mui/icons-material/Percent';
  import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
  import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
  import IconButton from '@mui/material/IconButton';
  
  interface EditNewRequestModalProps {
    open: boolean;
    handleClose: () => void;
  }
  
  function EditNewRequestModal({
    open,
    handleClose,
  }: EditNewRequestModalProps) {
    const [groupName, setGroupName] = useState("");
    const [pageStructureProportion, setPageStructureProportion] = useState("");
    const [popupProportion, setPopupProportion] = useState("");
    const [, setIsDuplicateButtonDisabled] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
  
    // Handle input change and validate the minimum 8 character condition
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^[a-zA-Z0-9]*$/; // Allow only letters and numbers (empty string included)
  
      if (regex.test(value)) {
        setGroupName(value);
        setIsDuplicateButtonDisabled(value.length < 8); // Enable if 8+ characters
      }
    };
  
    const handleProportionChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow empty value or numeric input restricted to 1-100
        if (value === "" || (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= 100)) {
          setter(value);
        }
      };
    };
  
    const websitelisting = [
      { label: "websiteexample1.com" },
      { label: "websiteexample2.com" },
      { label: "websiteexample3.com" },
      { label: "websiteexample4.com" },
      { label: "websiteexample5.com" },
    ];
  
    const [trafficSources, setTrafficSources] = useState([{ id: 1 }]); // Start with one Autocomplete
  
    const handleAddTrafficSource = () => {
      setTrafficSources([...trafficSources, { id: trafficSources.length + 1 }]); // Add a new item
    };
  
    const handleRemoveTrafficSource = (id:any) => {
      setTrafficSources((prevSources) =>
        prevSources.filter((source) => source.id !== id)
      );
    };
  
    // Handle the "Next" button click
    const handleNext = () => {
      setCurrentPage(currentPage + 1); // Move to the next page
    };
  
    // Handle the "Back" button click
    const handleBack = () => {
      setCurrentPage(currentPage - 1); // Move to the previous page
    };
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      >
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
            <DialogTitle sx={{ padding: "0px 0px 4px 0px" }}>
             Edit Page Group
            </DialogTitle>
            {currentPage === 1 &&(
            <>
            <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
            <Grid container>
              <Grid xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Group Name" // Set your label here
                  placeholder="How you like the site to be called" // Set your placeholder here
                  required
                  fullWidth
                  color="secondary"
                  size="small"
                  margin="dense"
                  value={groupName}
                  onChange={handleInputChange} // Update state on change
                />
                <Typography sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}>
                  minimum of 8 characters, only letters and numbers.
                </Typography>
              </Grid>
            </Grid>
            <Box mt={2} sx={{ flexGrow: 1 }}>
              <Typography>Page Structure</Typography>
              <Grid container mt={0.5}>
                <Grid xs={6}>
                  <Autocomplete
                    sx={{ width: 418 }}
                    disablePortal
                    size="small"
                    options={websitelisting}
                    renderInput={(params) => <TextField {...params} label="Theme" required color="secondary" placeholder="Choose Page Structure Theme from the list" />}
                  />
                </Grid>
                <Grid xs={3} sx={{ pl: 1 }}>
                  <TextField
                    id="page-structure-proportion"
                    label="Proportion"
                    required
                    color="secondary"
                    size="small"
                    fullWidth
                    value={pageStructureProportion}
                    onChange={handleProportionChange(setPageStructureProportion)} // Use the setter for page structure
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} sx={{ flexGrow: 1 }}>
              <Typography>Popup</Typography>
              <Grid container mt={0.5}>
                <Grid xs={6}>
                  <Autocomplete
                    sx={{ width: 418 }}
                    disablePortal
                    size="small"
                    options={websitelisting}
                    renderInput={(params) => <TextField {...params} label="popup" required color="secondary" placeholder="Choose Popup Content from the list" />}
                  />
                </Grid>
                <Grid xs={3} sx={{ pl: 1 }}>
                  <TextField
                    id="popup-proportion"
                    label="Proportion"
                    required
                    color="secondary"
                    size="small"
                    fullWidth
                    value={popupProportion}
                    onChange={handleProportionChange(setPopupProportion)} // Use the setter for popup
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
            <Typography>Assign Comparison Tables</Typography>
            <Stack spacing={2} mt={0.5}>
              {trafficSources.map((source, index) => (
                <Stack flexDirection="row" gap={2} key={source.id}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={websitelisting}
                    sx={{ width: 418 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Traffic Source"
                        required
                        color="secondary"
                        placeholder="Choose traffic source from the list"
                      />
                    )}
                  />
                  {index === trafficSources.length - 1 ? (
                    // Show "Add another" button on the last item
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleAddTrafficSource}
                      sx={{ padding: "6px 16px", width: "192px" }}
                    >
                      <Stack alignItems="center" flexDirection="row" gap={1}>
                        Add another <AddIcon fontSize="small" />
                      </Stack>
                    </Button>
                  ) : (
                    // Show "Remove" button on all previous items
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveTrafficSource(source.id)}
                      sx={{ padding: "6px 16px", width: "192px" }}
                    >
                      <Stack alignItems="center" flexDirection="row" gap={1}>
                        Remove <AddIcon fontSize="small" />
                      </Stack>
                    </Button>
                  )}
                </Stack>
              ))}
            </Stack>
            </Box>
            <Box mt={2}>
            <Typography>Assign Traffic Source</Typography>
            <Stack spacing={2} mt={0.5}>
              {trafficSources.map((source, index) => (
                <Stack flexDirection="row" gap={2} key={source.id}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={websitelisting}
                    sx={{ width: 418 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Traffic Source"
                        required
                        color="secondary"
                        placeholder="Choose traffic source from the list"
                      />
                    )}
                  />
                  {index === trafficSources.length - 1 ? (
                    // Show "Add another" button on the last item
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleAddTrafficSource}
                      sx={{ padding: "6px 16px", width: "192px" }}
                    >
                      <Stack alignItems="center" flexDirection="row" gap={1}>
                        Add another <AddIcon fontSize="small" />
                      </Stack>
                    </Button>
                  ) : (
                    // Show "Remove" button on all previous items
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveTrafficSource(source.id)}
                      sx={{ padding: "6px 16px", width: "192px" }}
                    >
                      <Stack alignItems="center" flexDirection="row" gap={1}>
                        Remove <AddIcon fontSize="small" />
                      </Stack>
                    </Button>
                  )}
                </Stack>
              ))}
            </Stack>
            </Box>
            <Typography sx={{ fontSize: "12px", marginTop: "8px", color: "#00000099" }}>
              Fields marked with * are mandatory.
            </Typography>
            </Box>
            <Box>
                  <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
                    <Button color="secondary" onClick={handleClose}>CANCEL</Button>
                    <Button variant="contained" color="secondary" onClick={handleNext}>
                      <Stack flexDirection={"row"}>
                        Next <ChevronRightIcon />
                      </Stack>
                    </Button>
                  </Stack>
            </Box>
            </>
            )}
            {currentPage === 2 && (
              <>
              <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Second Headline:</Typography>
                  </Grid>
                  {/* for Desktop */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Desktop:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="female" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit headline from website" 
                      sx={{marginLeft:"0"}}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:" 
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
                  {/* for Tablet */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Mobile:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="mobile" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit headline from website" 
                      sx={{marginLeft:"0"}}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:" 
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
                  {/* for mobile */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Tablet:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="tablet" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit headline from website"
                      sx={{marginLeft:"0"}}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:" 
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
  
                  {/* banner image  */}
                  <Grid item xs={12} mt={2}>
                    <Typography variant="h6">Banner Image:</Typography>
                  </Grid>
                  {/* for Desktop */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Desktop:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="female" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit Image from website"
                      sx={{marginLeft:"0"}}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:" 
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <IconButton>
                      <FileUploadOutlinedIcon color="secondary"/>
                  </IconButton>
                  </Grid>
                  {/* for Tablet */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Mobile:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="mobile" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit Image from website"
                      sx={{marginLeft:"0"}} 
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:"
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <IconButton>
                      <FileUploadOutlinedIcon color="secondary"/>
                  </IconButton>
                  </Grid>
                  {/* for mobile */}
                  <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1">Tablet:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="tablet" 
                      control={<Radio color="secondary"/>} 
                      label="Inherit Image from website"
                      sx={{marginLeft:"0"}}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Stack flexDirection={"row"}>
                      <FormControlLabel
                        value="male" 
                        control={<Radio color="secondary" />} 
                        label="Custom:" 
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Banner Image" // Set your label here
                        placeholder="New desktop headline" // Set your placeholder here
                        fullWidth
                        color="secondary"
                        size="small"
                        value={groupName}
                        onChange={handleInputChange} // Update state on change
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <IconButton>
                      <FileUploadOutlinedIcon color="secondary"/>
                  </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
                  <Button color="secondary" onClick={handleClose}>
                    CANCEL
                  </Button>
                  <Button color="secondary" onClick={handleBack} variant="outlined">
                    <Stack flexDirection={"row"} gap={1}>
                      <KeyboardArrowLeftIcon /> BACK
                    </Stack>
                  </Button>
                  <Button color="secondary" variant="contained">
                    <Stack flexDirection={"row"} gap={1}>
                      Create <AutoFixHighIcon />
                    </Stack>
                  </Button>
                </Stack>
              </Box>
              </>
            )}
        </Paper>
      </Dialog>
    );
  }
  
  export default EditNewRequestModal;
  