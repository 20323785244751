import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AvatarGroup from "@mui/material/AvatarGroup";
import Link from "@mui/material/Link";
import {
  Chip,
  Collapse,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import "./PageGroupCustomTable.css";
import AdditionalPageGroupContent from "./AdditionalPageGroupContent";
import DuplicatePageGroupModal from "../PageGroupModal/DuplicatePageGroupModal";
import EditNewRequestModal from "../PageGroupModal/EditNewRequestModal";
import IconButton from "@mui/material/IconButton";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box } from "@mui/system";

interface Props {
  tableHeadings: { [key: string]: string };
  rows: any[];
}

function PageGroupCustomTable({ tableHeadings, rows }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [openRow, setOpenRow] = React.useState<number | null>(null);
  const headCells = Object.entries(tableHeadings);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openRow, setOpenRow] = React.useState<number | null>(null);

  const handleRowClick = (index: number) => {
    setOpenRow((prev) => (prev === index ? null : index));
  };

  const [openDuplicateModal, setopenDuplicateModal] = useState(false);

  const handleOpenDuplicateModal = () => {
    setopenDuplicateModal(true);
  };

  const handleCloseDuplicateModal = () => {
    setopenDuplicateModal(false);
  };

  const [openEditnewModal, setopenEditnewModal] = useState(false);

  const handleopenEditnewModal = () => {
    setopenEditnewModal(true);
  };

  const handlecloseEditnewModal = () => {
    setopenEditnewModal(false);
  };

  return (
    <TableContainer
      className="quaterlygoalswrap"
      component={Paper}
      sx={{ maxHeight: 800, marginTop: "16px" }}
    >
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel>
                  {value && typeof value === "string" ? value : ""}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <React.Fragment key={row.cron_id}>
                <TableRow
                  sx={{
                    "& > *": {
                      borderBottom:
                        "1px solid rgba(224, 224, 224, 1) !important",
                    },
                  }}
                >
                  {headCells.map(([key], idx) => (
                    <TableCell key={idx} align="left">
                      {key === "more_info" ? (
                        <TableCell align="left" sx={{ border: "none" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            color="secondary"
                            onClick={() => handleRowClick(index)}
                          >
                            {openRow === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      ) : key === "theme" ? (
                        <div className="chip-data">
                          <Chip
                            label={row[key]}
                            sx={{
                              backgroundColor: "#7B1FA2",
                              color: "#ffffff",
                              height: "26px",
                            }}
                          />
                          <AvatarGroup
                            total={row[key]?.length - 1}
                            variant="circular"
                          ></AvatarGroup>
                        </div>
                      ) : key === "popup" ? (
                        <div className="chip-data">
                          <Chip
                            label={row[key]}
                            sx={{
                              backgroundColor: "#7B1FA2",
                              color: "#ffffff",
                              height: "26px",
                            }}
                          />
                          <AvatarGroup
                            total={row[key]?.length - 1}
                            variant="circular"
                          ></AvatarGroup>
                        </div>
                      ) : key === "abTest" ? (
                        <>
                          {row[key] === "No" ? (
                            row[key] || "N/A"
                          ) : (
                            <Link href="#" color="secondary">
                              {row[key] || "N/A"}
                            </Link>
                          )}
                        </>
                      ) : key === "quick_actions" ? (
                        <>
                          <Box display="flex" gap={1}>
                            <Tooltip title="Edit" placement="top">
                              <Box>
                                <IconButton
                                  size="small"
                                  onClick={handleopenEditnewModal}
                                >
                                  <CreateOutlinedIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </IconButton>
                              </Box>
                            </Tooltip>
                            <Tooltip
                              title="Duplicate"
                              placement="top"
                              onClick={handleOpenDuplicateModal}
                            >
                              <IconButton size="small">
                                <ContentCopyOutlinedIcon
                                  fontSize="small"
                                  color="secondary"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                              <IconButton size="small" disabled color="primary">
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </>
                      ) : key === "pages_in_group" ? (
                        <>
                          <Link href="#" color="secondary">
                            {row[key] ? row[key] : "N/A"}
                          </Link>
                        </>
                      ) : row[key] ? (
                        row[key]
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                    colSpan={Object.keys(tableHeadings).length + 1}
                  >
                    <Collapse
                      in={openRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ padding: "20px 0px" }}>
                        {/* Additional content here */}
                        <AdditionalPageGroupContent />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel":
              {
                display: "none",
              },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={rows.length}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".css-levciy-MuiTablePagination-displayedRows": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
      <DuplicatePageGroupModal
        open={openDuplicateModal}
        handleClose={handleCloseDuplicateModal}
      />
      <EditNewRequestModal
        open={openEditnewModal}
        handleClose={handlecloseEditnewModal}
      />
    </TableContainer>
  );
}

export default PageGroupCustomTable;
