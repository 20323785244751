import Box from "@mui/material/Box";
import "./ActivityNotesFilter.css";
import FilterComponent from "../../../ReusableFIlterComponent/FilterComponent";
import { useState } from "react";



interface Props {
  siteNames:any;
  selectedAsset:any;
  setSelectedAsset:any;
  result:any;
  selectedStakeHolder:any;
  setSelectedStakeHolder:any;
  selectedNoteDate:any;
  setSelectedNoteDate:any;
  selectedLabels:any;
  setSelectedLabels:any;
  setSelectedNote:any;
}

export default function ActivityNotesFilterItems({siteNames,selectedAsset,setSelectedAsset,result,selectedStakeHolder,setSelectedStakeHolder,selectedNoteDate,setSelectedNoteDate,selectedLabels,setSelectedLabels,setSelectedNote}:Props) {

  const [searchAsset, setSearchAsset] = useState('');
  const [searchStakeHolder, setSearchStakeHolder] = useState('');
  const [searchLabel, setSearchLabel] = useState('');

  const filterConfig = [
    {
      key: 'asset',
      label: 'Select an Asset',
      searchTerm: searchAsset,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchAsset(e.target.value),
      selectedOption: selectedAsset,
      filterName: 'website_shared_uid',
      data: result,
      onSelectionChange: (updatedSelection: any) => setSelectedAsset(updatedSelection),
      count: selectedAsset && selectedAsset.length,
      colorSelected: selectedAsset !== null && selectedAsset.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
      siteNames: siteNames
    },
    {
      key: 'stakeholder',
      label: 'Select a stakeholder',
      searchTerm: searchStakeHolder,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchStakeHolder(e.target.value),
      selectedOption: selectedStakeHolder,
      filterName: 'note_type',
      data: result,
      onSelectionChange: (updatedSelection: any) => setSelectedStakeHolder(updatedSelection),
      count: selectedStakeHolder && selectedStakeHolder.length,
      colorSelected: selectedStakeHolder !== null && selectedStakeHolder.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    },
    {
      key: 'date',
      label: 'Select Date Range',
      filterName: 'created',
      data: result,
      selectedOption: selectedNoteDate ,
      onSelectionChange: (updatedSelection: any) => setSelectedNoteDate(updatedSelection),
      count: 0,
      colorSelected: selectedNoteDate !== null && selectedNoteDate.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      isDateRangeFilter: true
    },{
      key: 'label',
      label: 'Select a label',
      searchTerm: searchLabel,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchLabel(e.target.value),
      selectedOption: selectedLabels,
      filterName: 'labels',
      data: result,
      onSelectionChange: (updatedSelection: any) => setSelectedLabels(updatedSelection),
      count: selectedLabels && selectedLabels.length,
      colorSelected: selectedLabels !== null && selectedLabels.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: true,
      showSearch: true,
    }
  ];

  const clearFilters = () => {
    setSelectedAsset(null);
    setSelectedStakeHolder(null);
    setSelectedNoteDate([]);
    setSelectedLabels(null);
    setSelectedNote(null);
  }

  return (
    <Box sx={{paddingTop:"10px"}}>
    <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}
