import { InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { useState } from "react";
import ConnectDomainModal from "../../../DomainIntregation/EndUserTypePermission/ConnectDomainModal";
// import VerificationMethod from "../../../DomainIntregation/AdminUserTypePermission/DnsManagerMethod/DnsManagerDomainVerfication/VerificationMethod";
// import FinalNotificationModal from "../../../DomainIntregation/AdminUserTypePermission/DnsManagerMethod/DnsManagerMethodModal/FinalNotificationModal";
// import DnsManagerDomainConnection from "../../../DomainIntregation/AdminUserTypePermission/DnsManagerMethod/DnsManagerDomainConnection/DnsManagerDomainConnection";
// import DnsRecordDomainVerficationContent from "../../../DomainIntregation/AdminUserTypePermission/DnsRecordMethod/DnsRecordDomianVerification/DnsRecordDomainVerficationContent";
// import DnsRecordDomainVerficationModal from "../../../DomainIntregation/AdminUserTypePermission/DnsRecordMethod/DnsRecordDomianVerification/DnsRecordDomainVerficationModal";
// import DnsManagerDomainVerficationModal from "../../../DomainIntregation/AdminUserTypePermission/DnsManagerMethod/DnsManagerDomainVerfication/DnsManagerDomainVerficationModal";
// import DnsManagerMethodModal from "../../../DomainIntregation/AdminUserTypePermission/DnsManagerMethod/DnsManagerMethodModal";
// import DnsRecordMethodModal from "../../../DomainIntregation/AdminUserTypePermission/DnsRecordMethod/DnsRecordMethodModal/DnsRecordMethodModal";
// import ConnectDomainModal from "../../../DomainIntregation/EndUserTypePermission/ConnectDomainModal";

function WebsiteAdditionalInfo() {
  
  const [openNewWebsite, setopenNewWebsite] = useState(false);

  const handleOpenNewWebsite = () => {
    setopenNewWebsite(true);
  }

  const handleCloseNewWebsite = () => {
    setopenNewWebsite(false);
  }

  return (
    <>
    <Grid container mt={2} spacing={2}>
    <Grid item xs={6}>
    <TextField
          id="standard-helperText"
          label="URL:"
          defaultValue="https://www.toppariurisportiveonline.ro/"
          variant="standard"
          fullWidth
          color="secondary"
        />
    </Grid>
    <Grid item xs={2}>
    <TextField
          id="standard-helperText"
          label="Default Theme"
          defaultValue="Casino - RO"
          variant="standard"
          fullWidth
          color="secondary"
        />
    </Grid>
    <Grid item xs={2}>
    <TextField
      id="standard-helperText"
      label="Created by"
      defaultValue="Shay Yoash"
      variant="standard"
      fullWidth
      color="secondary"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Avatar alt="Shay Yoash" src="/path-to-avatar.jpg" sx={{ width: 24, height: 24 }}/>
          </InputAdornment>
        ),
      }}
    />
    </Grid>
    <Grid item xs={2}>
    <TextField
          id="standard-helperText"
          label="Created on"
          defaultValue="28/07/2022"
          variant="standard"
          fullWidth
          color="secondary"
        />
    </Grid>
    </Grid>
    <Grid container mt={2} spacing={2}>
    <Grid item xs={6}>
        <Button variant="contained" color="secondary" onClick={handleOpenNewWebsite}>
            Connect a domain
        </Button>
    </Grid>
    <Grid item xs={3}>
    <TextField
          id="standard-helperText"
          label="G4 Property ID:"
          defaultValue="34567899"
          variant="standard"
          fullWidth
          color="secondary"
        />
    </Grid>
    <Grid item xs={3}>
    <TextField
          id="standard-helperText"
          label="Finance ID:"
          defaultValue="5874"
          variant="standard"
          fullWidth
          color="secondary"
        />
    </Grid>
    </Grid>
    <ConnectDomainModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/>
    {/* <DnsManagerMethodModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <DnsRecordMethodModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <DnsManagerDomainVerficationModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <DnsRecordDomainVerficationModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <DnsManagerDomainConnection open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <FinalNotificationModal open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    {/* <VerificationMethod open={openNewWebsite} handleClose={handleCloseNewWebsite}/> */}
    </>
  )
}

export default WebsiteAdditionalInfo