import { redirect } from "react-router-dom";
import store from "./store";
import { selectUserToken } from "./features/userSlice";

/** rejects with a redirect (to login) if not logged in */
export const isLoggedInLoader = async (userToken: string | null): Promise<null> => {
    const token = userToken || selectUserToken(store.getState());
    if (token) {
        return null;
    } else {
        throw redirect("/login");
    }
};