
import { Alert, AlertTitle, Autocomplete, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import "./RunNewProcessLog.css";
import RunNewProcessLogTag from "./RunNewProcessLogTag";
import { useEffect } from "react";
import { ETLLogsEndPointLogic } from "../../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";

const processName = [
  { label: "OPTIVAL-PRODUCTION" },
  { label: "OPTIVAL-STAGING" }
];


interface Props {
  token:string;
  setSelectedSource:any;
  selectedSource:any;
  setSelectedDestination:any;
  selectedDestination:any;
  structureOnly:any;
  setStructureOnly:any;
  setSelectedTable:any;
  setTable:any;
  table:any;
}

export default function RunNewProcessLogDBMigration({token,selectedSource,setSelectedSource,selectedDestination,setSelectedDestination,
  structureOnly,setStructureOnly,setSelectedTable,setTable,table
}:Props) {
  // const [selectedSource, setSelectedSource] = useState<string | null>(null);
  // const [selectedDestination, setSelectedDestination] = useState<string | null>(null);
  // const [structureOnly,setStructureOnly]=useState(false);
  const handleSourceChange = (event: any, newValue: string | null) => {
    setSelectedSource(newValue);

    // Automatically set the opposite value in Destination
    if (newValue === "OPTIVAL-PRODUCTION") {
      setSelectedDestination("OPTIVAL-STAGING");
    } else if (newValue === "OPTIVAL-STAGING") {
      setSelectedDestination("OPTIVAL-PRODUCTION");
    }
  };

  const handleDestinationChange = (event: any, newValue: string | null) => {
    setSelectedDestination(newValue);

    // Automatically set the opposite value in Source
    if (newValue === "OPTIVAL-PRODUCTION") {
      setSelectedSource("OPTIVAL-STAGING");
    } else if (newValue === "OPTIVAL-STAGING") {
      setSelectedSource("OPTIVAL-PRODUCTION");
    }
  };

  useEffect(()=>{
    if(selectedDestination && selectedSource){
      ETLLogsEndPointLogic.getDBTables(token,selectedSource,selectedDestination,setTable)
    }
    // eslint-disable-next-line
  },[selectedSource,selectedDestination,token]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
          <Typography
            variant="body2"
            sx={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "16px" }}
          >
            Migrate Database
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "15px !important" }}>
        <Autocomplete
          id="sourceAutocomplete"
          disablePortal
          options={processName.map(option => option.label)} // Extract only labels
          fullWidth
          size="small"
          value={selectedSource}
          onChange={handleSourceChange} // Handle source change
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...params}
              required
              label="Source (From)"
            />
          )}
        />
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "15px !important" }}>
        <Autocomplete
          id="destinationAutocomplete"
          disablePortal
          options={processName.map(option => option.label)} // Extract only labels
          fullWidth
          size="small"
          value={selectedDestination}
          onChange={handleDestinationChange} // Handle destination change
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...params}
              required
              label="Destination (To)"
            />
          )}
        />
        </Grid>
        {/* <Grid item xs={12} sx={{ paddingTop: "15px !important" }}>
          <Autocomplete
            id="processName"
            disablePortal
            options={processName}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                helperText="All fields are mandatory."
                color="secondary"
                {...params}
                required
                label="Desired Tables"
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
          <Typography
            variant="body2"
            sx={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "16px", mb:1 }}
          >
            Selected Tables
          </Typography>
            <RunNewProcessLogTag table={table} setSelectedTable={setSelectedTable}/>
        
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
          <Typography
            variant="body2"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Information to copy
          </Typography>
          <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={structureOnly ? "structureOnly" : "dataStructure"}
            onChange={(e) => setStructureOnly(e.target.value === "structureOnly")}
          >
            <FormControlLabel
              value="dataStructure"
              control={<Radio color="secondary" />}
              label="Data & Structure"
            />
            <FormControlLabel
              value="structureOnly"
              control={<Radio color="secondary" />}
              label="Structure Only"
            />
          </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
          <Alert
            severity="warning"
            sx={{
              borderRadius: 2,
              px: 1,
              py: 0,
              color: "#ED6C02",
              border: "1px solid #ED6C0280",
            }}
          >
            <AlertTitle sx={{ mb: "2px" }}>
              Please note! This action should be preformed with caution!
            </AlertTitle>
            Make sure to notify all relevant team members.
          </Alert>
        </Grid>
      </Grid>
    </>
  )
}
