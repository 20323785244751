import { Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/system";
import SiteWideText from "./SettingWebsiteElements/SiteWideText";
import DefaultAppearance from "./SettingWebsiteElements/DefaultAppearance";
import Features from "./SettingWebsiteElements/Features";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

function WebsiteSetting() {
  return (
    <>
      <Paper sx={{ padding: "16px 16px" }}>
        <Typography fontSize={24}>Website Settings</Typography>
        <Box>
          <Grid container mt={2}>
            <Grid xs={4} sx={{ pr: 2}}>
              <SiteWideText />
            </Grid>
            <Grid xs={4} sx={{ pl: 2, borderLeft:"1px solid #E0E0E0" }}>
              <DefaultAppearance />
              <Features />
            </Grid>
            <Grid xs={4} sx={{ 
              pl: 2, 
              display: "flex", 
              flexDirection: "column",  
              justifyContent: "flex-end",
              alignItems: "flex-end"
            }}>
             <Button variant="contained" color="secondary" sx={{ display:"flex"}}><Stack alignItems={"center"} flexDirection={"row"} gap={1}>Save Changes<SaveOutlinedIcon/></Stack></Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default WebsiteSetting;
