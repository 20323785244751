// import { setDisplayErrorSnackBar } from "../../../../features/userSlice";
import { endpointsService } from "../../../../services/endPoints.services";
// import store from "../../../../store";

async function getMonitoring(oauthTokenHeader: string, website_shared_uid: string,start_date:any, end_date: any,setData:any,setError:any,setisDataAvilable:any) {
    try {
        const response = await endpointsService.getMonitoring(oauthTokenHeader,website_shared_uid,start_date,end_date);
        if (!response.status || response.status !== 200) {
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            setData(response?.data?.data);
            setisDataAvilable(true);
        }
    } catch (e:any) {
        setData([]);
        setError(e);
        // store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        setisDataAvilable(false);
    }
}

async function getPreviousMonitoring(oauthTokenHeader: string, website_shared_uid: string,start_date:any, end_date: any,setData:any,setError:any,setisPreviousDataAvilable:any) {
    try {
        const response = await endpointsService.getMonitoring(oauthTokenHeader,website_shared_uid,start_date,end_date);
        if (!response.status || response.status !== 200) {
            /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
        } else {
            setData(response?.data?.data);
            setisPreviousDataAvilable(true);
        }
    } catch (e:any) {
        setError(e);
        // store.dispatch(setDisplayErrorSnackBar({ message: "Previous Records Not Found", type: 'error' }));
        setisPreviousDataAvilable(false);
        setData([]);
    }
}

async function getSystemMonitoring(oauthTokenHeader: string,start_date:any, end_date: any,setData:any,setError:any, setisDataAvilable:any) {
    try {
        const response = await endpointsService.getSystemMonitoring(oauthTokenHeader,start_date,end_date);
        if (!response.status || response.status !== 200) {
        } else {
            setData(response?.data?.data);
            setisDataAvilable(true);
        }
    } catch (e:any) {
        setError(e);
        // store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
        setisDataAvilable(false);
    }
}

export const MonitoringEndPointLogic = {
    getMonitoring,
    getPreviousMonitoring,
    getSystemMonitoring
};