import {
  Autocomplete,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/system";
import PageGroupFilterCard from "./PageGroupList/PageGroupFilterCard";
import PageGroupCustomTable from "./PageGroupTable/PageGroupCustomTable";



function WebsitePageGroup() {
  const top100Films = [
    { label: "websitenameexample1.com"},
    { label: "websitenameexample2.com"},
    { label: "websitenameexample3.com"},
    { label: "websitenameexample4.com"},
    { label: "websitenameexample5.com"},
  ];

  const tableHeadings = {
    "name": "Name",
    "pages_in_group": "Pages in Group",
    "theme": "Theme",
    "popup": "Popup",
    "abTest": "A/B Test",
    "quick_actions": "Quick Actions",
    "more_info": "More Info",
  }

  const rows = [
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "The test name",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "No",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "The test name",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "No",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "The test name",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "No",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "The test name",
      "quick_actions": "",
      "more_info": "",
    },
    {
      "name": "Bing - IE - Generic",
      "pages_in_group": "21",
      "theme": "helo",
      "popup": "Football popup",
      "abTest": "No",
      "quick_actions": "",
      "more_info": "",
    }
  ];

  return (
    <>
      <Paper sx={{ padding: "8px 16px" }}>
        <Typography fontSize={24}>Page Groups</Typography>
        <Stack flexDirection={"row"} justifyContent={"space-between"} gap={4} mt={2}>
          <Autocomplete
            sx={{ width: "100%" }}
            options={top100Films}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search site name or choose from the list"
                label="Select Website"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ marginLeft: "12px" }} />
                    </InputAdornment>
                  ),
                }}
                color="secondary"
              />
            )}
            size="small"
          />
          <Button variant="contained" disabled sx={{ whiteSpace: "nowrap" }}>
            SELECT SITE
          </Button>
        </Stack>
      </Paper>
      <Paper sx={{ padding: "8px 16px", marginTop:"8px" }}>
      <PageGroupFilterCard></PageGroupFilterCard>
      <PageGroupCustomTable tableHeadings={tableHeadings} rows={rows}></PageGroupCustomTable>
      </Paper>
    </>
  );
}

export default WebsitePageGroup