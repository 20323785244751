import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AvatarGroup from '@mui/material/AvatarGroup';
import {
  Chip,
  Collapse,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ThemeFilterCard from '../ThemeFilter/ThemeFilterCard';
import "./Themetable.css"
import { Box } from "@mui/system";
import AdditionalContentTheme from "./AdditionalContentTheme";
import { Link as RouterLink } from "react-router-dom";
import DuplicateThemeModal from "../ThemeModal/DuplicateThemeModal";
import SimpleBackdrop from '../../../../BackDrop/BackDrop';
import IconButton from '@mui/material/IconButton';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
interface Props {
  page: any;
  modifiedDesignThemeData:any,
  setPage: any;
  tableHeadings: { [key: string]: string };
  combinedOptions: any;
  hasGetPermissions: any;
  hasEditPermissions: any;
  selectedSharedUid:any;
  oauthTokenHeader:any;
  setLoading:any;
  search:any;
  setSearch:any;
  loading:any;
  setSelectedTheme:any;
  selectedTheme:any;
  designThemeData:any;
  setDesignThemeData:any;
  setSelectedSharedUid :any;
  designTemplatesData:any;
}
function ThemeTable({ tableHeadings,modifiedDesignThemeData, combinedOptions, setSelectedTheme, selectedTheme, designTemplatesData,  hasGetPermissions, hasEditPermissions, selectedSharedUid,oauthTokenHeader, page, setPage, setLoading,loading, search, setSearch, designThemeData, setDesignThemeData, setSelectedSharedUid}:Props) {
  const [, setRows] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedThemeShareuid, setSelectedThemeShareuid] = useState<any>(null); // New state for selected template's shared_uid
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({
    keytoSort: 'modified',
    direction: 'desc'
  });
  const [selectedGroup, setSelectedGroup] = useState<any>([]); // New state for selected template's shared_uid
  const [selectedFeature, setSelectedFeature] = useState<any>([]); // New state for selected template's shared_uid

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: number) => {
    setPage(newPage);
  };
    

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    // setRows(filteredthemedata);
    // const newPageCount = Math.ceil(filteredthemedata?.length / rowsPerPage);
    // setPageCount(newPageCount);
  };

  const handleRowClick = (row:any) => {
    setOpenRow(openRow === row.shared_uid ? null : row.shared_uid);
    setSelectedThemeShareuid(row.shared_uid); // Use the row's unique identifier
  };

  const handleOpenModal = (shared_uid:any) => {
    setSelectedThemeShareuid(shared_uid); // Set the selected theme's shared_uid
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  // const filteredData = designThemeData?.filter((theme:any )=>
  //   (!selectedTheme || theme?.name === selectedTheme)
  // )

// console.log(filteredData)

  useEffect(() => {
    setPage(0);
  },[setPage,selectedTheme,selectedGroup,selectedFeature]);

  const tableheaders = [
    {id:0, KEY: "name", label: "Name", sortable: true},
    {id:1, KEY: "groups_appearance", label: "Groups", sortable: true},
    {id:2, KEY: "modified", label: "Last Updated", sortable: true},
    {id:3, KEY: "labels", label: "Features", sortable: true},
    {id:4, KEY: "quick_actions", label: "Quick Actions", sortable:false},
    {id:5, KEY: "more_info", label: "More Info", sortable:false},
  ];

   // function to handle click on table headers
  const handleHeaderClick = (header : any) => {
    if (!header.sortable) return; // Ignore click if column is not sortable
  
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };


  //function to filter data **********
  const filteredthemedata = modifiedDesignThemeData?.filter((theme: any) => {
    const statusThemeName = selectedTheme && selectedTheme.length !== 0
    ? selectedTheme.includes(theme?.name)
    : true; // If no Asset is selected, treat it as a match
    
    const statusGroups = selectedGroup && selectedGroup?.length !== 0
    ? selectedGroup.some((group:any) => theme?.groups_appearance.includes(group))
    : true; // If no Asset is selected, treat it as a match

      const statusFeatures = selectedFeature && selectedFeature?.length !== 0
      ? selectedFeature.some((feature:any) => theme?.labels.includes(feature))
      : true; // If no Asset is selected, treat it as a match
  
    return statusThemeName &&statusGroups && statusFeatures;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSortedData = (arrayToSort: any[]) => {
    if (!sort.keytoSort || !sort.direction) {
        return arrayToSort;
    }

    return arrayToSort.slice().sort((a, b) => {
      const keytoSort = sort.keytoSort;

      if (keytoSort === undefined) {
          return 0; // Return 0 if the sorting key is undefined
      }

      let aValue, bValue;

      if (keytoSort === 'labels') {
          // Aggregate labels from templates into a single string for sorting
          aValue = a.templates?.flatMap((template: any) => template.labels).join(', ') || '';
          bValue = b.templates?.flatMap((template: any) => template.labels).join(', ') || '';
      } else {
          aValue = a[keytoSort];
          bValue = b[keytoSort];
      }

      // Handle undefined values for sorting
      if (aValue === undefined) {
          return 1; // Move undefined values to the end
      }
      if (bValue === undefined) {
          return -1; // Move undefined values to the end
      }

      // Custom sorting for labels
      if (keytoSort === 'labels') {
          // For descending order, empty labels come first
          if (sort.direction === 'desc') {
              if (aValue === '' && bValue !== '') return -1; // a is empty, b is not
              if (bValue === '' && aValue !== '') return 1;  // b is empty, a is not
          } else {
              // For ascending order, empty labels come last
              if (aValue === '' && bValue !== '') return 1; // a is empty, b is not
              if (bValue === '' && aValue !== '') return -1; // b is empty, a is not
          }
      }

      // Perform sorting based on the direction and type of values
      if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sort.direction === 'asc'
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (aValue instanceof Date && bValue instanceof Date) {
          return sort.direction === 'asc'
              ? aValue.getTime() - bValue.getTime()
              : bValue.getTime() - aValue.getTime();
      } else {
          return sort.direction === 'asc'
              ? String(aValue).localeCompare(String(bValue))
              : String(bValue).localeCompare(String(aValue));
      }
  });
};

  // useEffect(() => {
  //   const sortedRows = getSortedData(filteredthemedata || []);
  //   setSortedData(sortedRows);
  // }, [filteredthemedata, getSortedData, sort]);

  const sortedRows = getSortedData(filteredthemedata || []);
  const paginatedThemes = sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    const newPageCount = Math.ceil(filteredthemedata?.length / rowsPerPage);
    setPageCount(newPageCount);
  }, [rowsPerPage, filteredthemedata])

  useEffect(() => {
    setRows(filteredthemedata);
    const newPageCount = Math.ceil(filteredthemedata?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[filteredthemedata, rowsPerPage]);

  return (
    <>
      {loading ? (<SimpleBackdrop />) : (
    <>
      <ThemeFilterCard combinedOptions={combinedOptions} oauthTokenHeader={oauthTokenHeader} selectedTheme={selectedTheme} selectedSharedUid={selectedSharedUid} setSelectedSharedUid={setSelectedSharedUid} designThemeData={designThemeData} setDesignThemeData={setDesignThemeData} setSelectedTheme={setSelectedTheme} designTemplatesData={designTemplatesData} 
      setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} setSelectedFeature={setSelectedFeature} selectedFeature={selectedFeature} />
      <TableContainer className="themeWrap" component={Paper} sx={{ marginTop:"16px" }}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
          {tableheaders?.map((header:any,index:any) => (
              <TableCell key={header.id}>
                <TableSortLabel
                  active={sort.keytoSort === header.KEY}
                  direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                  onClick={() => handleHeaderClick(header)}
                  disabled={!header.sortable}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {paginatedThemes.length > 0 && paginatedThemes
        ?.map((row:any, index:number) => (
          <React.Fragment key={index}>
                  <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">
                    <div className="chip-data">
                    {Array.isArray(row.groups_appearance) && row.groups_appearance?.length > 0 ? (
                      <>
                        {/* Display the first group */}
                        <Chip
                          label={row.groups_appearance[0]}
                          sx={{
                            backgroundColor: "#7B1FA2",
                            color: "#ffffff",
                            height: "26px",
                          }}
                        />
                        {/* Display the second group if it exists */}
                        {row.groups_appearance?.length > 1 && (
                          <Chip
                            label={row.groups_appearance[1]}
                            sx={{
                              backgroundColor: "#7B1FA2",
                              color: "#ffffff",
                              height: "26px",
                            }}
                          />
                        )}

                        {/* Check if there are additional related groups and render AvatarGroup */}
                        {row.groups_appearance?.length > 2 && (
                          <AvatarGroup
                            total={row.groups_appearance?.length - 2} // Show the number of additional groups
                            variant="circular"
                          />
                        )}
                      </>
                    ) : null}
                    </div>
                  </TableCell>
                  <TableCell align="left">{formatDate(row.modified)}</TableCell>
                  <TableCell align="left">
                    <div className="chip-data">
                    {Array.isArray(row.labels) && row.labels?.length > 0 ? (
                      <>
                        {/* Display the first group */}
                        <Chip
                          label={row.labels[0]}
                          sx={{
                            backgroundColor: "#7B1FA2",
                            color: "#ffffff",
                            height: "26px",
                          }}
                        />
                        {/* Display the second group if it exists */}
                        {row.labels?.length > 1 && (
                          <Chip
                            label={row.labels[1]}
                            sx={{
                              backgroundColor: "#7B1FA2",
                              color: "#ffffff",
                              height: "26px",
                            }}
                          />
                        )}

                        {/* Check if there are additional related groups and render AvatarGroup */}
                        {row.labels?.length > 2 && (
                          <AvatarGroup
                            total={row.labels?.length - 2} // Show the number of additional groups
                            variant="circular"
                          />
                        )}
                      </>
                    ) : null}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                  <>
                  <Box display="flex" gap={1}>
                  <Tooltip title="Edit" placement="top">
                    <Box>
                      <RouterLink to="edit_new_theme" state={{ selectedThemeShareuid: row?.shared_uid, oauthTokenHeader: oauthTokenHeader, selectedSharedUid: selectedSharedUid, combinedOptions: combinedOptions, designTemplatesData : designTemplatesData,designThemeData : designThemeData }}>
                        <IconButton size="small">
                          <CreateOutlinedIcon fontSize="small" color="secondary"/>
                        </IconButton>
                      </RouterLink>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Duplicate" placement="top">
                    <IconButton size="small" onClick={() => handleOpenModal(row?.shared_uid)}>
                      <ContentCopyOutlinedIcon fontSize="small" color="secondary"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <IconButton size="small" disabled color="primary">
                      <DeleteOutlineOutlinedIcon fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                  </Box>
                  </>
                  </TableCell>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={Object.keys(tableHeadings).length + 1}>
                    <IconButton aria-label="expand row" size="small"color="secondary" onClick={() => handleRowClick(row)}>
                      { openRow === row.shared_uid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={Object.keys(tableHeadings).length + 1}>
                    <Collapse in={openRow === row.shared_uid} timeout="auto" unmountOnExit>
                      <Box sx={{ padding: '20px 0px' }}>
                        <AdditionalContentTheme designThemeData={designThemeData} loading={loading} selectedThemeShareuid={selectedThemeShareuid} oauthTokenHeader={oauthTokenHeader} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
                </React.Fragment>
        ))
        }
        </TableBody>
      </Table>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={sortedRows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={pageCount}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
          onChange={(event, value) =>
            handleChangePage(null, value - 1)
          }
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedRows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
        <DuplicateThemeModal open={openModal} onClose={handleCloseModal} selectedThemeShareuid={selectedThemeShareuid}  selectedSharedUid={selectedSharedUid} hasGetPermissions={hasGetPermissions} hasEditPermissions={hasEditPermissions} oauthTokenHeader={oauthTokenHeader} designThemeData={designThemeData} setDesignThemeData={setDesignThemeData} />
      </TableContainer>
        </>
      )}
    </>
  )
}
export default ThemeTable
