export const dataset = [
    { x: '01/01/25', y: 10 },
    { x: '05/02/25', y: 40},
    { x: '07/03/25', y: 60 },
    { x: '08/04/25', y: 80 },
    { x: '09/05/25', y: 90 },
    { x: '10/06/25', y: 55  },
    { x: '11/07/25', y: 83 },
    { x: '14/08/25', y: 90 },
    { x: '16/09/25', y: 100 },
  ]; 


  export const paint = [
    { x: '05/02/25', y: 0},
    { x: '07/03/25', y: 5 },
    { x: '01/01/25', y: 2.5 },
  ];  

  export const paintMilliseconds = [
    { x: '05/02/25', y: 0},
    { x: '07/03/25', y: 350 },
    { x: '01/01/25', y: 550 },
  ]; 