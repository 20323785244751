import Cookies from 'js-cookie';
/* eslint-disable import/prefer-default-export */

interface IResponse {
  ok: boolean;
  text: Function;
  statusText: string;
  status: number;
}

const setHeader = () => ({ Authorization: (`Bearer ${Cookies.get('user-token')}`) });
const rejected = (result: any) => result;

// helper functions
async function handleResponse(response: IResponse): Promise<any> {
  return response.text().then(async (text: string) => {
    if (!response.ok) {
      const errorObject = { response, text };
      await Promise.reject(errorObject).then(rejected);
    }
    // created a new stracture for getting data
    const data = JSON.parse(text);
    const status = response?.status;
    const successObject = {status , data}
    return successObject;
    // eslint-disable-next-line
  }).catch(function (error: any) {
    try {
      // Create a combined object with both status and text
      const parsedError = {
        status: error?.response?.status,
        text: error?.text,
      };
  
      // Try parsing the text if it's JSON, and merge it with the status
      const parsedText = JSON.parse(parsedError.text || '{}');
      const combinedError = { ...parsedError, parsedText };
  
      return Promise.reject(combinedError);
  
    } catch (e) {
      // If parsing fails, return the original error with status and text
      return Promise.reject({
        status: error?.response?.status,
        text: error?.text,
      });
    }
  });
}

function get(url: string, oauthTokenHeader?: string) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', Authorization: oauthTokenHeader ? `Token ${oauthTokenHeader}` : setHeader().Authorization },
    RequestCredentials: 'include',
    method: 'GET',
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function getUrl(url: any) {
  return fetch(url);
}

function post(url: string, body: object, oauthTokenHeader?: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: oauthTokenHeader ? `Token ${oauthTokenHeader}` : setHeader().Authorization },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: object, oauthTokenHeader?: string) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: oauthTokenHeader ? `Token ${oauthTokenHeader}` : setHeader().Authorization },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function deleteMethod(url: string, oauthTokenHeader?: string) {
  const requestOptions = {
    headers: { ...setHeader(), Authorization: `Token ${oauthTokenHeader}` },
    method: 'DELETE',
  };
  return fetch(url, requestOptions).then(handleResponse);
}


export const fetchWrapper = {
  get,
  post,
  put,
  getUrl,
  delete: deleteMethod,
};
