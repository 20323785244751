import {
    TextField,
    Typography,
    SnackbarContent,
    Switch,
    Alert,
  } from "@mui/material";
  import Grid from "@mui/material/Grid";
  import { Box } from "@mui/system";
  import Button from "@mui/material/Button";
  import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
  import Stack from "@mui/material/Stack";
  import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
  import DeactivateWebsiteModal from "../EditWebsiteModal/DeactivateWebsiteModal";
  // import ReactivateWebsiteModal from "../EditWebsiteModal/ReactivateWebsiteModal";
  import { useState } from "react";
  
  function AdditionalInfo() {
    const [deactivateWebsite, setdeactivateWebsite] = useState(false);
    // const [reactivateWebsite, setreactivateWebsite] = useState(false);
  
    const handleOpendeactivateWebsite = () => {
      setdeactivateWebsite(true);
    };
  
    const handleClosedeactivateWebsite = () => {
      setdeactivateWebsite(false);
    };
  
    // const handleOpenReactivateWebsite = () => {
    //   setreactivateWebsite(true);
    // };
  
    // const handleCloseReactivateWebsite = () => {
    //   setreactivateWebsite(false);
    // };
    return (
      <>
        <Box>
          <Typography mt={1}>Additional info</Typography>
          <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2} mb={2}>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="G4 Property ID" // Set your label here
                placeholder="How you like the site to be called" // Set your placeholder here
                required
                fullWidth
                color="secondary"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="Finance ID" // Set your label here
                placeholder="How you like the site to be called" // Set your placeholder here
                required
                fullWidth
                color="secondary"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Typography
            sx={{ marginTop: "16px", fontSize: "12px", color: "#00000099" }}
          >
            All fields are mandatory.
          </Typography>
        </Box>
        <Box>
          <Typography mt={1}>Website Status and sync</Typography>
          <SnackbarContent
            sx={{
              background: "#FFF4E5",
              color: "#ED6C02",
              marginTop: "8px",
              boxShadow: "none",
              border: "1px solid #D32F2F80",
              width: "1000px",
            }}
            message={
              <Box display="flex">
                <ErrorOutlineOutlinedIcon
                  sx={{ marginRight: "8px", color: "#ED6C02" }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    sx={{ marginBottom: "4px" }}
                  >
                    Please edit the following changes with extreme caution!
                  </Typography>
                </Box>
              </Box>
            }
          />
          <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2} mb={2}>
            <Grid item xs={12} md={2}>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>Sync data</Typography>
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#2E7D32",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#2E7D32",
                    },
                  }}
                />
                <Alert
                  variant="outlined"
                  severity="success"
                  icon={
                    <CheckCircleOutlinedIcon
                      sx={{ color: "#1E4620" }}
                      fontSize="small"
                    />
                  }
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBlock: "8px",
                    padding: "0px 4px",
                    fontWeight: "500",
                    background: "#EDF7ED",
                    width: "120px",
                    border: "1px solid #2E7D3280",
                    color: "#1E4620",
                  }}
                >
                  Enabled
                </Alert>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>Website status</Typography>
                <Alert
                  variant="outlined"
                  severity="success"
                  icon={
                    <CheckCircleOutlinedIcon
                      sx={{ color: "#1E4620" }}
                      fontSize="small"
                    />
                  }
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBlock: "8px",
                    padding: "0px 4px",
                    fontWeight: "500",
                    background: "#EDF7ED",
                    width: "120px",
                    border: "1px solid #2E7D3280",
                    color: "#1E4620",
                  }}
                >
                  Active
                </Alert>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                color="secondary"
                size="large"
                onClick={handleOpendeactivateWebsite}
              >
                deactivate website
              </Button>
              {/* <Button
                color="secondary"
                size="large"
                onClick={handleOpenReactivateWebsite}
              >
                reactivate website temporary button
              </Button> */}
            </Grid>
          </Grid>
        </Box>
        <DeactivateWebsiteModal
          open={deactivateWebsite}
          handleClose={handleClosedeactivateWebsite}
        ></DeactivateWebsiteModal>
        {/* <ReactivateWebsiteModal
          open={reactivateWebsite}
          handleClose={handleCloseReactivateWebsite}
        ></ReactivateWebsiteModal> */}
      </>
    );
  }
  
  export default AdditionalInfo;
  