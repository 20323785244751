import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  InputAdornment
} from "@mui/material";
import React, { useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";


interface ClickDataFilterProps{
  startDate:any;
  setStartDate:any;
  endDate:any;
  setEndDate:any;
  setShow:any;
  setDisplay:any;
  competitorlisting:any;
  setBrandName:any;
}

const ClickDataFilter = ({startDate,setStartDate,endDate,setEndDate,setShow,setDisplay,competitorlisting,setBrandName}:ClickDataFilterProps) => {
  const calculateStartAndEndDate = (selectedPeriod: string) => {
    const [month, year] = selectedPeriod.split("/").map(Number);
    const start = dayjs(`${year}-${month}-01`).format("YYYY-MM-DD"); // First day of the month
    const end = dayjs(start).endOf("month").format("YYYY-MM-DD"); // Last day of the month

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const currentMonth =  dayjs().format('MM/YYYY');
    calculateStartAndEndDate(currentMonth); // Calculate start and end date
    // eslint-disable-next-line
    }, []); 
    
    const handleShow = () => {
      setShow(true);
      setDisplay(true);
    };
    const filteredCompetitorListing = Array.from(new Set(competitorlisting));

  return (
    <>
      <Grid container spacing={2} alignItems="center">
      <Grid item xs={2} >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ py: 2 }}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "100%" }}
                value={startDate ? dayjs(startDate) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setStartDate(formattedDate);
                    setEndDate(null);
                  }
                }}
                label="Start Date"
                format="DD/MM/YY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ py: 2 }}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "100%" }}
                label="End Date"
                disabled={!startDate}
                value={endDate ? dayjs(endDate) : null}
                minDate={startDate ? dayjs(startDate) : undefined}
                format="DD/MM/YY"
                onChange={((newValue)=>{
                  if(newValue){
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setEndDate(formattedDate);
                  }
                })}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={5}>
            <Autocomplete
              sx={{ width: "100%",py: 2 }}
              options={filteredCompetitorListing}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Brand or choose from the list"
                  label="Competitor (optional)"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                />
              )}
              onChange={(event, newValue) => {
                // Set the selected brand name into state
                setBrandName(newValue ? newValue : []); // or just newValue if it's already the desired format
              }}
              size="small"
            />
          </Grid>
        <Grid item xs={3} textAlign={"right"}>
          <Button variant="contained" color="secondary" sx={{my: 2}} onClick={handleShow}
          disabled={!endDate || !startDate}>
          show
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ClickDataFilter;
