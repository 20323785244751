import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  IconButton,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./QuaterlyGoalTable.css";
import EditGoalModal from "../QuaterlyGoalModal/EditGoal";
import SimpleBackdrop from '../../../../../BackDrop/BackDrop';

interface QuaterlyGoalTableProps {
  tableHeadings: { [key: string]: string };
  selectedSharedUid:any;
  oauthTokenHeader:any;
  hasEditPermissions: any;
  hasGetPermissions: any;
  quarterlyGoalsData: any;
  setQuarterlyGoalsData: any;
  setLoading:any;
  loading:any;
  tableData:any;
  selectedDevice:any; 
  setSelectedDevice:any;
  goalsRequestedBy:any; 
  setGoalsRequestedBy:any;
  selectedgoalsDate:any;
  setSlectedgoalsDate:any;
}

function QuaterlyGoalTable({ selectedSharedUid,oauthTokenHeader, hasEditPermissions,tableHeadings,hasGetPermissions,quarterlyGoalsData,setQuarterlyGoalsData,setLoading,loading,tableData,selectedDevice,goalsRequestedBy,selectedgoalsDate,setSlectedgoalsDate }: QuaterlyGoalTableProps) {
  const [, setRows] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRowData, setSelectedRowData] = useState<any>(null); // State to store selected row data
  const [selected, setSelected] = useState<any[]>([]);
  const [rowId, setRowId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({keytoSort: 'modified',direction: 'desc'});

  // Function to integrate filter component with the table
  const filteredData = tableData?.filter((goal:any) => {
    const statusDevice = selectedDevice && selectedDevice !== 0
    ? selectedDevice.includes(goal?.device)
    : true;

    const statusRequestedBy = goalsRequestedBy && goalsRequestedBy !== 0
    ? goalsRequestedBy.includes(goal?.created_by)
    : true;

    // function for date range filter
    const isDateInRange = Array.isArray(selectedgoalsDate) && selectedgoalsDate.length === 2 && selectedgoalsDate[0] && selectedgoalsDate[1]
    ? selectedgoalsDate[0] === selectedgoalsDate[1] // If both dates are the same, check for equality
      ? new Date(goal?.created).toDateString() === new Date(selectedgoalsDate[0]).toDateString()
      : new Date(goal?.created) >= new Date(selectedgoalsDate[0]) &&
      new Date(goal?.created) <= new Date(new Date(selectedgoalsDate[1]).setHours(23, 59, 59, 999))
    : true;

    return statusDevice && statusRequestedBy && isDateInRange;
    
  });

  const tableHeaders = [
    { id: 0, KEY: 'edit', label: 'Edit', sortable: false },
    { id: 1, KEY: 'period', label: 'Period', sortable: true },
    { id: 2, KEY: 'device', label: 'Device', sortable: true },
    { id: 3, KEY: 'largest_contentful_paint', label: 'LCP', sortable: true },
    { id: 4, KEY: 'interaction_to_next_paint', label: 'INP', sortable: true },
    { id: 5, KEY: 'cumulative_layout_shift', label: 'CLS', sortable: true },
    { id: 6, KEY: 'first_contentful_paint', label: 'FCP', sortable: true },
    { id: 7, KEY: 'time_to_first_byte', label: 'TTFB', sortable: true },
    { id: 8, KEY: 'mobile_speed_score_from_ads_platform', label: 'Mobile Speed Score', sortable: true },
    { id: 9, KEY: 'created', label: 'Requested On', sortable: true },
    { id: 10, KEY: 'created_by', label: 'Requested By', sortable: true },
  ];

  const handleHeaderClick = (header: any) => {
    if (!header.sortable) return; // If the header is not sortable, return
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
const getSortedData = (arrayToSort: any[]) => {
  // If no sorting key is set, return the original array
  if (!sort.keytoSort || !sort.direction) {
   return arrayToSort;
 }

 // Clone the array to avoid mutating the original array
 return arrayToSort.slice().sort((a, b) => {
   // Check if sort.keytoSort is not undefined and has the property
   const keytoSort = sort.keytoSort;

   if (keytoSort === 'period') {
    const [aQuarter, aYear] = a[keytoSort].split('/').map(Number);
    const [bQuarter, bYear] = b[keytoSort].split('/').map(Number);
    const aDate = new Date(aYear, aQuarter - 1); // Month is 0-indexed
    const bDate = new Date(bYear, bQuarter - 1);

    // Use getTime() to compare the dates
    return sort.direction === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
  }


   if (keytoSort === undefined) {
     return 0; // Return 0 if the sorting key is undefined
   }

   const aValue = a[keytoSort];
   const bValue = b[keytoSort];

   // Handle undefined values for sorting
   if (aValue === undefined) {
     return 1; // Move undefined values to the end
   }
   if (bValue === undefined) {
     return -1; // Move undefined values to the end
   }

   // Handle "N/A" values for sorting
   if (aValue === "") {
     return 1; // Move "N/A" values to the end
   }
   if (bValue === "") {
     return -1; // Move "N/A" values to the end
   }

   // Perform sorting based on the direction and type of values
   if (typeof aValue === 'string' && typeof bValue === 'string') {
     // String comparison (localeCompare for correct string sorting)
     return sort.direction === 'asc'
       ? aValue.localeCompare(bValue)
       : bValue.localeCompare(aValue);
   } else if (typeof aValue === 'number' && typeof bValue === 'number') {
     // Numeric comparison
     return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
   } else if (aValue instanceof Date && bValue instanceof Date) {
     // Date comparison
     return sort.direction === 'asc'
       ? aValue.getTime() - bValue.getTime()
       : bValue.getTime() - aValue.getTime();
   } else {
     // Fallback to string comparison if values are of different types
     return sort.direction === 'asc'
       ? String(aValue).localeCompare(String(bValue))
       : String(bValue).localeCompare(String(aValue));
   }
 });
};


  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  }

  const sortedRows = getSortedData(filteredData || []);
  const paginatedGoals = sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const handleRowClick = (row:any, id:any) => {
    const selectedRow = selected?.indexOf(id);
    setSelected(selectedRow === -1 ? [id] : []);
    setSelectedRowData(row);
    setRowId(id);
    setOpenModal(true);
  }

  React.useEffect(()=>{
    setPage(0);
  },[setPage,selectedDevice, goalsRequestedBy, selectedgoalsDate]);

  useEffect(() => {
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  }, [rowsPerPage, filteredData])


  useEffect(() => {
    setRows(filteredData);
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[filteredData,rowsPerPage]);

  return (
    <>
      { loading ? <SimpleBackdrop/> : <TableContainer className="quaterlygoalswrap" component={Paper} sx={{ maxHeight: 800, marginTop:"16px" }}>
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
            {tableHeaders?.map((header:any,index:any) => (
                <TableCell key={header.id}>
                  <TableSortLabel
                    active={sort.keytoSort === header.KEY}
                    direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                    onClick={() => handleHeaderClick(header)}
                    disabled={!header.sortable}
                >
                   {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
           {paginatedGoals?.length > 0 && paginatedGoals
            ?.map((row:any, index) => (
              <React.Fragment key={row?.cron_id}>
                  <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}>
                    <TableCell align='left'>
                      <IconButton aria-label="edit" size="small" color="secondary" onClick={() => handleRowClick(row, row?.id)}>
                        <img className="icon4" alt="" src="/icon4.svg" />
                      </IconButton>
                    </TableCell> 
                    <TableCell align='left'>{`Q${row?.period}`}</TableCell>
                    <TableCell align='left'>{row?.device}</TableCell> 
                    <TableCell align='left'>{`${row?.largest_contentful_paint} s`}</TableCell> 
                    <TableCell align='left'>{`${row?.interaction_to_next_paint} ms`}</TableCell> 
                    <TableCell align='left'>{`${row?.cumulative_layout_shift} ms`}</TableCell> 
                    <TableCell align='left'>{`${row?.first_contentful_paint} s`}</TableCell>
                    <TableCell align='left'>{`${row?.time_to_first_byte} s`}</TableCell>
                    <TableCell align='left'>{row?.device === 'Mobile' ? row?.mobile_speed_score_from_ads_platform : ""}</TableCell>
                    <TableCell align='left'>{formatDate(row?.created)}</TableCell>
                    <TableCell align='left'>
                      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                        <Avatar alt={row?.created_by} src="#" sx={{ width: 24, height: 24 }}></Avatar> {row?.created_by}
                      </Stack>
                    </TableCell> 
                  </TableRow>
                </React.Fragment>
            ))}
            {paginatedGoals?.length === 0 && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Typography variant="body1" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TablePagination
            component="div"
            count={sortedRows?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[]}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                display: "none",
              },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={pageCount}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
            onChange={(event, value) =>
              handleChangePage(null, value - 1)
            }
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={paginatedGoals?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: 'hidden-displayed-rows', // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
            }}
          />
        </Stack>
        <EditGoalModal selectedSharedUid={selectedSharedUid} oauthTokenHeader={oauthTokenHeader} hasEditPermissions={hasEditPermissions} hasGetPermissions={hasGetPermissions} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} setSelected={setSelected} rowId={rowId} quarterlyGoalsData={quarterlyGoalsData} setQuarterlyGoalsData={setQuarterlyGoalsData} setLoading={setLoading} loading={loading} open={openModal} onClose={handleCloseModal}></EditGoalModal>
      </TableContainer>
      }
    </>
  )
}

export default QuaterlyGoalTable
