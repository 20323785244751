import React, { useEffect, useState } from "react";
import DowntimeCalculator from "./DowntimeCalculator";
import DowntimeIncidentsTable from "./DowntimeIncidentsTable";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Monitoring.css";
import dayjs from "dayjs";

const assetName = [
  { label: "aladdin_v2", value: "Aladdin V2" },
  { label: "aladdin_v1", value: "Aladdin V1" },
  { label: "metabase", value: "Metabase" },
];


const tableHeadings = {
  "event_date": "Date",
  "down_timestamp": "Downtime Start",
  "up_timestamp": "Downtime End",
  "downtime_duration": "Downtime Duration ",
}

const Monitoring = ({userToken,response}:any) => {

  const token = userToken;
  
  const siteOptions = response?.website_permissions?.map((siteObj: any) => {
    const uid = Object.keys(siteObj)[0]; // UID of the site
    const siteName = siteObj[uid].site_name; // Site name
    return { uid, siteName }; // Return as an object with uid and siteName
  }) || [];

  // Merging the site options with assetName options
  const combinedOptions = [
    ...siteOptions.map((site:any) => ({
      label: site.siteName, // Display the site name
      value: site.uid, // Store the UID
      isAsset: false, // To differentiate between asset and site
    })),
    ...assetName.map((asset) => ({
      label: asset.value, // Display the asset label
      value: asset.label, // Store the asset label
      isAsset: true, // To differentiate between asset and site
    })),
  ];
   
  const [selectedSharedUid,setSelectedSharedUid]=useState([]) as any;
  const [selectedVersion,setSelectedVersion]=useState([]) as any;
  const [startDate,setStartDate] = useState([]) as any;
  const [endDate,setEndDate] = useState([]) as any;
  const [loading,setLoading] =useState(false);
  const [search,setSearch] = useState(false);
  const [dataShow,setDataShow] = useState(false);
  const [isDataAvilable,setisDataAvilable] = useState(false);
  const [isPreviousDataAvilable,setIsPreviousDataAvilable] = useState(false);
  const [tabledata,setTabledata] = useState([]);

  const handleSearch=()=>{
    setTabledata([]);
    setSearch(true);
    setDataShow(true);
  }

  useEffect(() => {
    const now = dayjs(); // Get the current date
    const startOfMonth = now.startOf('month'); // Get the first day of the current month
    const endOfMonth = now.endOf('month'); // Get the last day of the current month

    setStartDate(startOfMonth.format("YYYY-MM-DD")); // Set start date in YYYY-MM-DD format
    setEndDate(endOfMonth.format("YYYY-MM-DD")); // Set end date in YYYY-MM-DD format
  }, []); 


  return (
    <>
      {/* Monitoring Filter */}
      <Paper className="monitoringFiltersWrap" sx={{ padding: "16px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h3" sx={{ fontSize: "24px" }}>
              Monitoring
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              Analyze Website Performance Trends to Keep Your Website Running Smoothly
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" pt={1}>
          <Grid item xs={4} md={6} textAlign={"end"}>
          <Autocomplete
              id="assetOrSiteName"
              disablePortal
              options={combinedOptions} // Use the combined options for Autocomplete
              getOptionLabel={(option) => option.label} // Display the label (siteName or assetName)
              fullWidth
              size="small"
              sx={{ pt: "8px" }}
              onChange={(event, newValue) => {
                setDataShow(false);
                setTabledata([])
                if (!newValue) {
                  // If newValue is null, clear both selected states
                  setSelectedSharedUid([]);
                  setSelectedVersion([]);
                  setDataShow(false);
                } else if (newValue.isAsset) {
                  // If an asset is selected, clear selectedSharedUid and set selectedVersion
                  setSelectedSharedUid([]);
                  setSelectedVersion(newValue.value);
                } else {
                  // If a site is selected, clear selectedVersion and set selectedSharedUid
                  setSelectedVersion([]);
                  setSelectedSharedUid(newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  color="secondary"
                  {...params}
                  required
                  label="Asset"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ marginLeft: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid className="monitoringDatePicker" item xs={12} sm={6} md={2} textAlign={"end"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: "small",
                      color: "secondary",
                      required: true,
                      fullWidth: true,
                    },
                  }}
                  format="DD/MM/YYYY" 
                  value={dayjs(startDate)}
                  sx={{ width: "100%", minWidth:"unset !important"}}
                  label="Downtime Start"
                  onChange={(newValue) => {
                    if (newValue) {
                      const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
          
                      // If the selected start date is later than the end date, reset it
                      if (formattedDate > endDate) {
                        // Optionally, reset the end date as well
                        setStartDate(formattedDate);
                        setEndDate(formattedDate); // Reset endDate if needed
                      } else {
                        setStartDate(formattedDate);
                      }
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid className="monitoringDatePicker" item xs={12} sm={6} md={2} textAlign={"end"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: "small",
                      color: "secondary",
                      required: true,
                      fullWidth: true,
                    },
                  }}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%", minWidth:"unset !important"}}
                  label="Downtime End"
                  disabled={startDate.length===0}
                  minDate={startDate ? dayjs(startDate) : undefined}
                  value={dayjs(endDate)}
                  onChange={((newValue)=>{
                    if(newValue){
                      const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                      setEndDate(formattedDate);
                    }
                  })}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"end"}>
            <Button sx={{marginTop:'5px'}} size="large" fullWidth variant="contained" color="secondary" onClick={handleSearch}
              disabled={(selectedSharedUid.length===0 || startDate.length===0 || endDate.length===0) &&
                (selectedVersion.length===0 || startDate.length===0 || endDate.length===0)
               }>Run Health Check</Button>
          </Grid>
        </Grid>
      </Paper>
      {/* Monitoring Filter End*/}
      {((selectedSharedUid.length!==0 && startDate.length!==0 && endDate.length!==0 && dataShow) || (selectedVersion.length!==0 && startDate.length!==0 && endDate.length!==0 && dataShow)) &&<DowntimeCalculator selectedSharedUid={selectedSharedUid} selectedVersion={selectedVersion}
      startDate={startDate} endDate={endDate} token={token} setLoading={setLoading} loading={loading} search={search} setSearch={setSearch} setisDataAvilable={setisDataAvilable} isDataAvilable={isDataAvilable} dataShow={dataShow}
      tableData={tabledata} setIsPreviousDataAvilable={setIsPreviousDataAvilable} isPreviousDataAvilable={isPreviousDataAvilable}/>}

      {((selectedSharedUid.length!==0 && startDate.length!==0 && endDate.length!==0 && dataShow) || (selectedVersion.length!==0 && startDate.length!==0 && endDate.length!==0 && dataShow)) &&
      <DowntimeIncidentsTable tableHeadings={tableHeadings} selectedSharedUid={selectedSharedUid} selectedVersion={selectedVersion}
      startDate={startDate} endDate={endDate} token={token} setLoading={setLoading} loading={loading} search={search} setSearch={setSearch} setisDataAvilable={setisDataAvilable} isDataAvilable={isDataAvilable}
      setTabledata={setTabledata} tableData={tabledata}/> }
    </>
  );
};

export default Monitoring;
