import TemplatesTable from './TemplatesTable/TemplatesTable'
import { Autocomplete, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system';
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useMemo, useContext } from 'react';
import { useHasDesignTemplatePermission } from '../../../../logic/AssetManagement/Permissions/useHasDesignTemplatePermission';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import { DesignTemplatesEndPointLogic } from '../../../../logic/AssetManagement/Design/Templates/DesignTemplatesEndPointLogic';
import SharedStateContext from '../../../SharedStateProvider';
import SimpleBackdrop from '../../../BackDrop/BackDrop';

interface TemplateProps {
    oauthTokenHeader: any;
    response: any;
}

function Template({ oauthTokenHeader, response }: TemplateProps) {
    const tableHeadings = {
        "name": "Name",
        "component": "Component Type",
        "modified": "Last Updated",
        "labels": "Features",
        "quick_actions": "Quick Actions",
    }

    const [selectedSharedUid, setSelectedSharedUid] = useState([]) as any;
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [dataShow, setDataShow] = useState(false);
    const [designTemplatesData, setDesignTemplatesData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [page, setPage] = useState(0);
    const [, setError] = useState("");
    const { shouldRunEffect, setLoad } = useContext(SharedStateContext);
    const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
    const [selectedComponents, setSelectedComponents] = useState<string[]>([]);
    

    const siteOptions = useMemo(() => (
        response?.website_permissions?.map((siteObj: any) => {
            const uid = Object.keys(siteObj)[0];
            const siteName = siteObj[uid]?.site_name;
            return { uid, siteName };
        }) || []
    ), [response]);

    const combinedOptions = useMemo(() => (
        siteOptions.map((site: any) => ({
            label: site.siteName,
            value: site.uid,
        }))
    ), [siteOptions]);

    // Load selected website from localStorage on mount
    useEffect(() => {
        if (shouldRunEffect) {
        const savedUid = localStorage.getItem('selectedSharedUid');
        if (!savedUid) {
            setSelectedSharedUid(null);
        } else  {
            setSelectedSharedUid(savedUid);
        }
        // After handling the effect, reset the flag
        setLoad(false); // Reset load flag
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Save selected website to localStorage whenever it changes
    useEffect(() => {
        if (selectedSharedUid) {
            localStorage.setItem('selectedSharedUid', selectedSharedUid);
        } else {
            localStorage.removeItem('selectedSharedUid');
        }
    }, [selectedSharedUid]);

    useEffect(() => {
        if (shouldRunEffect) {
            if (!selectedSharedUid) {
                setSearch(false);
                setDataShow(false);
            } else {
                handleSearch(); // Ensure handleSearch is defined
            }
            // After handling the effect, reset the flag
            setLoad(false); // Reset load flag
        }
    }, [shouldRunEffect, selectedSharedUid, setLoad]);

    const handleSearch = () => {
        setSearch(true);
        setDataShow(true);
    };

    const handleSelectWebsite = (event: any, newValue: any) => {
        if (!newValue) {
            setSelectedSharedUid(null);
            setDataShow(false);
            localStorage.removeItem('selectedSharedUid'); // Clear from localStorage if cleared
        } else {
            setSelectedSharedUid(newValue.value);
        }
    };

    const hasGetPermissions = useHasDesignTemplatePermission([
        ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_DETAILS,
        ModulesPermissionNames.GET_API_DESIGN_TEMPLATES_LIST
    ]);

    const hasEditPermissions = useHasDesignTemplatePermission([
        ModulesPermissionNames.PUT_API_DESIGN_TEMPLATES_DETAILS,
        ModulesPermissionNames.POST_API_DESIGN_TEMPLATES_DETAILS,
        ModulesPermissionNames.DELETE_API_DESIGN_TEMPLATES_DETAILS,
        ModulesPermissionNames.PUT_API_DESIGN_TEMPLATES_LIST,
        ModulesPermissionNames.POST_API_DESIGN_TEMPLATES_LIST,
        ModulesPermissionNames.DELETE_API_DESIGN_TEMPLATES_LIST
    ]);

    useEffect(() => {
        if (search === true && selectedSharedUid?.length !== 0) {
            const fetchDesignTemplatesData = async () => {
                setLoading(true);
                try {
                    if (oauthTokenHeader && hasGetPermissions) {
                        await DesignTemplatesEndPointLogic.getDesignTemplates(oauthTokenHeader, selectedSharedUid, setDesignTemplatesData, setError);
                    } else {
                        throw new Error("User doesn't have permissions to fetch design templates data");
                    }
                } catch (error) {
                    console.error("Error fetching design templates data", error);
                } finally {
                    setLoading(false);
                }
            };
            if (selectedSharedUid?.lenght !== 0 && selectedSharedUid !== null) {
            fetchDesignTemplatesData();
            }
        }
        // eslint-disable-next-line
    }, [oauthTokenHeader, selectedSharedUid, search]);

    return (
        <>
            <Paper sx={{ padding: "8px 16px" }}>
                <Typography fontSize={24}>Templates</Typography>
                <Stack flexDirection={"row"} justifyContent={"space-between"} gap={4} mt={2}>
                    <Autocomplete
                        disablePortal
                        sx={{ width: "100%" }}
                        options={combinedOptions}
                        getOptionLabel={(option) => option.label}
                        value={combinedOptions.find((option: any) => option.value === selectedSharedUid) || null}
                        onChange={handleSelectWebsite}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Search site name or choose from the list"
                                label="Select Website"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ marginLeft: "12px" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                color="secondary"
                            />
                        )}
                        size="small"
                    />
                    <Button variant="contained" onClick={handleSearch} disabled={selectedSharedUid?.length === 0 || selectedSharedUid === null } color="secondary" sx={{ whiteSpace: "nowrap" }}>
                        SELECT SITE
                    </Button>
                </Stack>
            </Paper>
            <Paper sx={{ mt: 2 }}>
            <> 
                {selectedSharedUid?.length !== 0 && dataShow && (
                loading ? 
                (<SimpleBackdrop />) : (
                    <TemplatesTable  combinedOptions={combinedOptions} tableHeadings={tableHeadings} selectedSharedUid={selectedSharedUid} setSelectedSharedUid={setSelectedSharedUid} oauthTokenHeader={oauthTokenHeader} hasGetPermissions={hasGetPermissions} hasEditPermissions={hasEditPermissions} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} designTemplatesData={designTemplatesData} setDesignTemplatesData={setDesignTemplatesData} setLoading={setLoading} loading={loading} search={search} setSearch={setSearch} page={page} setPage={setPage} selectedFeatures={selectedFeatures} setSelectedFeatures={setSelectedFeatures}
                    selectedComponents={selectedComponents} setSelectedComponents={setSelectedComponents}/>
                ))}
            </>
            </Paper>
        </>
    )
}

export default Template;
