/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Autocomplete,
    Button,
    Dialog,
    DialogTitle,
    FormControlLabel,
    Grid,
    LinearProgress,
    Paper,
    Radio,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box, Stack } from "@mui/system";
  import CheckIcon from '@mui/icons-material/Check';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import UploadFileIcon from '@mui/icons-material/UploadFile';
  import CloseIcon from '@mui/icons-material/Close';
  import Card from '@mui/material/Card';
  import { useState, useEffect } from 'react';
  import { DesignThemeEndPointLogic } from '../../../../../logic/AssetManagement/Design/Theme/DesignThemeEndPointLogic';
  import { setIsLoading } from '../../../../../features/userSlice';

  interface FileDetails {
    name: string;
    base64: string;
    progress: number;
    status: string;
    size: number; // Add size to track the file size
  }

  interface RequestModalProps {
    open: any;
    onClose: any;
    oauthTokenHeader: any;
    selectedSharedUid: any;
    designThemeData: any;
    setDesignThemeData: any;
  }


  function RequestNewModal({ open, onClose,oauthTokenHeader, selectedSharedUid, designThemeData, setDesignThemeData }: RequestModalProps) {
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [themeName, setThemeName] = useState("");
    const [themeshared_uid, setThemeSharedUid] = useState("");
    const [description, setDescription] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [fileBase64, setFileBase64] = useState<FileDetails[]>([]);
    const [uploadError, setUploadError] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [request, setRequest] = useState([]) as any;
    const [isCardVisible, setIsCardVisible] = useState(false);
    const [isExistingDesignSelected, setIsExistingDesignSelected] = useState(false); // New state variable to check if existing design is selected
    const [selectedOption, setSelectedOption] = useState<string | null>(null); // Track selected option
    const Themeoptions = designThemeData?.map((themes: any) => ({
      name: themes?.name,
      shared_uid: themes?.shared_uid
    }));

    const requestNewTheme = () =>{
      const data = {
          "task_structure_shared_id": "b57c93b5aed3483e8ad0ed03d1510159",
          "owner": 30,
          "assignee": 19,
          "reference_id": selectedSharedUid,
          "data": {
          "domain_url": "optival.com",
          "integration_type": "theme",
          "design_name": selectedOption === "scratch" ? null : themeName, // Set design_name to null if "scratch" is selected
          "base_theme": selectedOption === "scratch" ? null : themeshared_uid, // Set base_theme to null if "scratch" is selected
          "description": "This is a description"
        }
      }
        setIsLoading(true);
        if(oauthTokenHeader) {
          DesignThemeEndPointLogic.requestANewTheme(oauthTokenHeader,setRequest,data)
        } else {
          throw new Error("User doesn't have permissions to request a new theme");
        }
    };


    useEffect(() => {
      if (request?.data && request?.data !== null && request?.data !== undefined) {
        if (fileBase64.length > 0) {
          try {
             Promise.all(
              fileBase64.map((file) => {
                const singleFileData = {
                  "object_type": "tasks",
                  "file_name": file.name,
                  "file": file.base64
                };
                return DesignThemeEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, request?.data);
              })
            )
            .then(() => {
              setFileBase64([]); // Clear the files after upload
            })
          } catch (error:any) {
            setLoading(false); // Stop loading in case of error
            throw new Error("Error uploading files: ", error);
          } finally {
            setLoading(false); // Stop the loading spinner after uploads are done
            onClose();
          }
        } else {
          setLoading(false);
        }
      }
    // eslint-disable-next-line
    },[request]);
    
    const validate = async() => {
      if (selectedOption === "existing" && !themeName) {
        setSnackbarOpen(true);
        setSnackbarMessage("Please select a theme");
        setIsValid(false);
        return false;
      }

      if(!description) {
        setSnackbarOpen(true);
        setSnackbarMessage("Please enter a description");
        setIsValid(false);
        return false;
      }

      setIsValid(true); // Validation passed
      return true; // Validation passed
    }

    const handleRequestNewTheme = () => {
      const isFormValid = validate();
      if (!isFormValid) return; // Only proceed if the form is valid
      try {
        setLoading(true);
        requestNewTheme();
      } catch (error:any) {
        throw new Error("Error requesting new theme: ", error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      const timers: any[] = [];
      fileBase64 && fileBase64.forEach((file: any, index: number) => {
        if (file.progress < 100) {
          const timer = setInterval(() => {
            setFileBase64((prevFiles: any) => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = Math.min(file.progress + Math.random() * 10, 100);
              if (newFiles[index].progress === 100) {
                newFiles[index].status = 'Uploaded'; // Change status to Uploaded
              }
              return newFiles;
            });
          }, 50);
          timers.push(timer);
        }
      });
  
      return () => {
        timers.forEach((timer) => clearInterval(timer));
      };
    }, [fileBase64, setFileBase64]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (selectedFiles) {
        processFiles(Array.from(selectedFiles));
      }
    };
  
    const processFiles = (selectedFiles: File[]) => {
      selectedFiles.forEach((file) => {
        const fileSizeMB = file.size / (1024 * 1024);
        const fileType = file.type;
    
        // Check file size limit
        if (fileSizeMB > 5) {
          setUploadError('File size exceeds the 5 MB limit.');
          return;
        }
    
        // Check allowed file types
        if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'application/pdf') {
          setUploadError('Only PNG, JPG, or PDF files are allowed.');
          return;
        }
        setUploadError(''); // Clear any previous error
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString().split(',')[1]; // Extract base64 part
          if (base64String) {
            const newFile: FileDetails = {
              name: file.name,
              base64: base64String,
              progress: 0,
              status: 'Uploading',
              size: file.size
            };
            setFileBase64((prevFiles) => [...prevFiles, newFile]);
          }
        };
        reader.readAsDataURL(file);
      });
    };

    const handleCloseRequestModal = () => {
      onClose();
      setFileBase64([]); // Clear the files after upload
      setIsExistingDesignSelected(false); // Reset the radio button
      setThemeName(""); // Reset the theme name
      setThemeSharedUid(""); // Reset the theme shared uid
      setDescription(""); // Reset the description
    };

    const handleFileUpload = async () => {
      setLoading(true);
      if (fileBase64.length > 0) {
        try {
          await Promise.all(
            fileBase64.map((file) => {
              const singleFileData = {
                "object_type": "tasks",
                "file_name": file.name,
                "file": file.base64
              };
              return DesignThemeEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, themeshared_uid);
            })
          )
          .then(() => {
            setFileBase64([]); // Clear the files after upload
          })
        } catch (error:any) {
          setLoading(false); // Stop loading in case of error
          throw new Error("Error uploading files: ", error);
        } finally {
          setLoading(false); // Stop the loading spinner after uploads are done
        }
      } else {
        setLoading(false);
      }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles) {
        processFiles(Array.from(droppedFiles));
      }
    };
  
    const handleRemoveFile = (index: number) => {
      const updatedFiles = fileBase64.filter((_: any, i: number) => i !== index);
      setFileBase64(updatedFiles);
      if (updatedFiles.length === 0) {
        setIsCardVisible(false);
      }
    };

    const renderSnackbar = () => {
      return (
      <Snackbar
        sx={{ zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('Error') || isValid ? "success" : "error"}
        >
            {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  };

    return (
      <>
        {renderSnackbar()}
        <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}
      >
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
          <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>
            Request New Design
          </DialogTitle>
          <Box sx={{ flexGrow: 1,maxHeight: "800px", overflowY: "auto"}}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={5}>
                <FormControlLabel control={<Radio color="secondary"/>}
                  checked={selectedOption === "existing"} // Check if the radio is selected 
                 onChange={() => {
                    setSelectedOption("existing");
                    setIsExistingDesignSelected(true);
                  }} label="Choose an existing design for reference" />
              </Grid>
              <Grid item xs={8} md={7}>
              <Autocomplete
                disablePortal
                options={Themeoptions}
                disabled={!isExistingDesignSelected} // Disable if the radio is not selected
                getOptionLabel={(option:any) => option.name}
                onChange={(event, newValue) => {
                  setThemeName(newValue ? newValue.name : null); // Update state with selected theme
                  setThemeSharedUid(newValue ? newValue.shared_uid : null);
                }}
                // sx={{ width: auto }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Theme" color="secondary" placeholder="Choose Theme from the list"/>}
              />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControlLabel value="female" control={<Radio color="secondary"/>}
                checked={selectedOption === "scratch"} // Check if the radio is selected
                onChange={() => {
                    setSelectedOption("scratch");
                    setIsExistingDesignSelected(false);
                  }} label="Start from scratch" />
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={12} md={12}>
                <Accordion sx={{marginTop:"16px"}}>
                  <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  >
                  Requested Features
                  <Typography fontSize={12} textAlign={"center"} sx={{position:"absolute",top:"50%",transform:"translate(-50%, -50%)", left:"50%"}}>Coming soon</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Coming soon features will be listed here
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid container mt={4}>
              <Grid item xs={12} md={12}>
                  <TextField
                  color="secondary"
                  fullWidth
                  id="outlined-multiline-static"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  label="Design Description"
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
            <Grid container mt={4}>
            <Grid item xs={12} md={12}>
              <Typography>Attachments</Typography>
              <Paper onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "8px",
                  paddingBlock: "24px",
                  backgroundColor: isDragging ? "#9C27B01A" : "#9C27B00A", // Change background on drag
                  border: isDragging ? "2px dashed #9C27B0" : "none", // Add dashed border on drag
                  opacity: selectedOption === "scratch" ? 0.5 : 1, // Change opacity if disabled
                  pointerEvents: selectedOption === "scratch" ? 'none' : 'auto' // Disable pointer events
                }}
              >
              <UploadFileIcon color='secondary' />
                <Typography sx={{ display: 'flex', gap: '4px' }} component="label" role={undefined} tabIndex={-1}>
                  <Typography color="secondary" sx={{ textDecoration: "underline", cursor: selectedOption === "scratch" ? 'not-allowed' : 'pointer' }}>
                    Click to upload
                  </Typography> or drag and drop
                  <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
                </Typography>
                <Typography sx={{ color: '#00000099' }}>PNG, JPG or PDF (max. 5MB each)</Typography>
                {uploadError && <Typography color="error">{uploadError}</Typography>}
                  <Typography color="textSecondary" sx={{ marginTop: '8px' }}>
                    Uploads are disabled when starting from scratch.
                  </Typography>
              </Paper>
              {fileBase64 && fileBase64.map((file, index) => (
              <Card key={index} sx={{ padding: '8px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px', margin: "8px 16px" }}>
                <div>
                  <Typography sx={{ fontWeight: '500' }}>{file.name}</Typography>
                  <Typography sx={{ paddingBottom: '4px' }}>{(file.size / 1024).toFixed(2)} KB • {file.status}</Typography>
                  <LinearProgress color="secondary" variant="determinate" value={file.progress} />
                </div>
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveFile(index)} />
              </Card>
              ))}
            </Grid>
            </Grid>
          </Box>
          <Box>
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={2} gap={1}>
            <Button color="secondary" onClick={handleCloseRequestModal} sx={{ textTransform: 'uppercase' }} variant="outlined">cancel</Button>
            <Button variant="contained" disabled={!selectedOption || !themeName || !description } onClick={requestNewTheme} color="secondary" sx={{ textTransform: 'uppercase' }}>
              <Stack flexDirection={"row"} gap={1}>
                submit Request
                <CheckIcon fontSize="small" />
              </Stack>
            </Button>
          </Stack>
          </Box>
        </Paper>
      </Dialog>
      </>
    );
  }
  
  export default RequestNewModal;
  