import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AvatarGroup from '@mui/material/AvatarGroup';
import {
  Avatar,
  Checkbox,
  Chip,
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { AdditionalContentActivityNotes } from "./AdditionalContentActivityNotes";
import "./CustomTableActivityNotes.css";
import { useEffect, useState } from 'react';
import SimpleBackdrop from '../../../BackDrop/BackDrop';

interface Props {
  oauthTokenHeader:any;
  siteNames:any;
  result:any;
  tableHeadings: { [key: string]: string };
  onSelectNote:any
  ActivityNotesContent:any
  setActivityNotesContent:any
  selectedRows:any;
  setSelectedRows:any;
  selectedNote: any
  setSelectedNote:any;
  isCardVisible:any;
  setIsCardVisible:any;
  activityNotesTableDataUpdate:any;
  fileBase64:any,
  setFileBase64:any,
  userData:any
  selectedAccount:any
  selectedAsset:any
  selectedStakeHolder:any
  selectedLabels:any
  UpdateActivityNote:Function
  isEditEnabled:boolean
  setIsEditEnabled:any
  setIsCheckboxCheckedArray:any
  isCheckboxCheckedArray:any
  rowIndexNumber:any
  setrowIndexNumber:any
  fileBase64s:any 
  setFileBase64s:any
  setFileName:any,
  fileName:any
  setCompareEmailForDelete:any
  postFileUpload:any
  getFiles:Function
  getFileContent:any
  setGetFileContent:any
  isThisAdd:any 
  setIsThisAdd:any
  files:any 
  setFiles:any
  isDragActive:any
  setIsDragActive:any
  data:any
  setUpdatedActivityNotesContent:any
  isLoading:any
  selectedNoteDate:any
  setSelectedNoteDate:any
}


export default function CustomTableActivityNotes({oauthTokenHeader,siteNames,result,onSelectNote,selectedNote,setSelectedNote,data,isDragActive, setIsDragActive,files, setFiles,isThisAdd, setIsThisAdd,setGetFileContent,getFileContent,getFiles,postFileUpload,setCompareEmailForDelete,setrowIndexNumber,rowIndexNumber,setIsCheckboxCheckedArray, isCheckboxCheckedArray,setIsEditEnabled,isEditEnabled,UpdateActivityNote,selectedLabels,selectedStakeHolder,selectedAsset,selectedAccount, userData,tableHeadings,activityNotesTableDataUpdate,fileBase64,setFileBase64,isCardVisible,setIsCardVisible,selectedRows,setSelectedRows, ActivityNotesContent,setActivityNotesContent,fileBase64s, setFileBase64s,setFileName,fileName ,isLoading,selectedNoteDate,setSelectedNoteDate}: Props) {
  const [, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRow, setOpenRow] = useState<number | null>(null);
  const headCells = Object.entries(tableHeadings);
  const [singleNoteShareUID , setSingleNoteShareUID] = useState(null);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({
    keytoSort: 'created',
    direction: 'desc'
  });
  const [sortedData,] = useState<any[]>([]);

  const activityNotesTableData = Object.values(ActivityNotesContent || []);
  activityNotesTableDataUpdate = activityNotesTableData?.filter(Boolean)?.flat();


  //filtered functions
  const filteredData = result?.filter((notes:any) => {
    const statusAssets = selectedAsset && selectedAsset?.length !== 0
    ? selectedAsset.includes(notes?.website_shared_uid)
    : true; // If no Asset is selected, treat it as a match

    const statusStakeholder = selectedStakeHolder && selectedStakeHolder?.length !== 0
    ? selectedStakeHolder.includes(notes?.note_type)
    : true; // If no Stakeholder is selected, treat it as a match

    const notesName = selectedNote && selectedNote?.length !== 0
    ? selectedNote.includes(notes?.note)
    : true; // If no Note is selected, treat it as a match

    const notesLabels = selectedLabels && selectedLabels?.length !== 0
    ? selectedLabels.some((label: any) => notes?.labels?.includes(label))
    : true; // If no Label is selected, treat it as a match
    
    // function for date range filter
    const isDateInRange = Array.isArray(selectedNoteDate) && selectedNoteDate.length === 2 && selectedNoteDate[0] && selectedNoteDate[1]
    ? selectedNoteDate[0] === selectedNoteDate[1] // If both dates are the same, check for equality
      ? new Date(notes?.created).toDateString() === new Date(selectedNoteDate[0]).toDateString()
      : new Date(notes?.created) >= new Date(selectedNoteDate[0]) &&
      new Date(notes?.created) <= new Date(new Date(selectedNoteDate[1]).setHours(23, 59, 59, 999))
    : true;

    return statusAssets && notesName && statusStakeholder && isDateInRange && notesLabels;
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setSelectedRows([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 const handleCheckboxChange = (row: any,index: number) => {
  setSelectedRows((prevSelected: any) => {
    // Check if the row is already selected based on shared_uid
    const isRowSelected = prevSelected.some((selectedRow: any) => selectedRow?.shared_uid === row?.shared_uid);
    if (isRowSelected) {
      // If already selected, remove it
      return prevSelected.filter((selectedRow: any) => selectedRow?.shared_uid !== row?.shared_uid);
    } else {
      // Otherwise, add the whole row
      return [...prevSelected, row];
    }
  });
};

const formatDate = (dateString:any) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const formatDateToDDMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-11) and pad
  const year = date.getFullYear(); // Get year
  return `${day}/${month}/${year}`; // Return in DD/MM/YYYY format
};

useEffect(() => {
 setPage(0);
}, [selectedAsset,selectedLabels,selectedNote,selectedStakeHolder]);

  // headers for the table columns with sortable option for each column
  const tableheaders = [
    {id:0, KEY: "note", label: "Note Title", sortable: true},
    {id:1, KEY: "website_shared_uid", label: "Related Asset", sortable: true},
    {id:2, KEY: "related_brand", label: "Related Brand", sortable: true},
    {id:3, KEY: "note_type", label: "Stakeholder", sortable: true},
    {id:4, KEY: "activity_date", label: "Date", sortable: true},
    {id:5, KEY: "labels", label: "Labels", sortable: true},
    {id:6, KEY: "created", label: "Created On", sortable: true},
    {id:7, KEY: "created_by", label: "Created By", sortable: true},
    {id:8, KEY: "more_info", label: "More Info", sortable: false},
  ];

  // function to handle click on table headers
  const handleHeaderClick = (header : any) => {
    if (!header.sortable) return; // Ignore click if column is not sortable
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSortedData = (arrayToSort: any[]) => {
    // If no sorting key is set, return the original array
    if (!sort.keytoSort || !sort.direction) {
      return arrayToSort;
    }

    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
    const keytoSort = sort.keytoSort;

    if (keytoSort === undefined) {
      return 0; // Return 0 if the sorting key is undefined
    }
  
    let aValue = a[keytoSort];
    let bValue = b[keytoSort];
  
    // If sorting by website_shared_uid, get the site name
    if (keytoSort === 'website_shared_uid') {
      aValue = siteNames.find((source: any) => source.key === a.website_shared_uid)?.site?.site_name || "";
      bValue = siteNames.find((source: any) => source.key === b.website_shared_uid)?.site?.site_name || "";
    }
  
    if (aValue === undefined) {
      return 1; // Move undefined values to the end
    }
    if (bValue === undefined) {
      return -1; // Move undefined values to the end
    }

    // Handle "N/A" values for sorting
    if (aValue === "") {
      return 1; // Move "N/A" values to the end
    }
    if (bValue === "") {
      return -1; // Move "N/A" values to the end
    }
  
    // Custom sorting for labels
    if (keytoSort === 'labels') {
      // Sort based on the length of labels array first
      const aLabelCount = Array.isArray(aValue) ? aValue?.length : 0;
      const bLabelCount = Array.isArray(bValue) ? bValue?.length : 0;

      // For descending order, empty labels come first
      if (sort.direction === 'desc') {
        if (aLabelCount === 0 && bLabelCount > 0) return -1; // a is empty, b is not
        if (bLabelCount === 0 && aLabelCount > 0) return 1;  // b is empty, a is not
      } else {
        // For ascending order, empty labels come last
        if (aLabelCount === 0 && bLabelCount > 0) return 1; // a is empty, b is not
        if (bLabelCount === 0 && aLabelCount > 0) return -1; // b is empty, a is not
      }
    }

    // Perform sorting based on the direction and type of values
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // String comparison (localeCompare for correct string sorting)
      return sort.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Numeric comparison
      return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (aValue instanceof Date && bValue instanceof Date) {
      // Date comparison
      return sort.direction === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    } else {
      // Fallback to string comparison if values are of different types
      return sort.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });
  };

  // useEffect(() => {
  //   const sortedRows = getSortedData(filteredData || []);
  //   setSortedData(sortedRows);
  // }, [filteredData, getSortedData, sort]);
  const sortedRows = getSortedData(filteredData || []);
  const paginatedNotes = sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  useEffect(() => {
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  }, [rowsPerPage, filteredData])

  const handleRowClick = (row:any,index: number) => {
    setIsThisAdd(false)
    setOpenRow(openRow === row?.shared_uid ? null : row?.shared_uid);
    setSingleNoteShareUID(row?.shared_uid);
    if (row?.shared_uid) {
      onSelectNote(row?.shared_uid); // Call onSelectNote with the current row's shared_uid
    }
  };

  useEffect(() => {
    setRows(filteredData);
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[filteredData, rowsPerPage]);

return (
  <>
  {isLoading ? (<SimpleBackdrop />) : <TableContainer className="chainsTableWrap" component={Paper} >
  <Table aria-label="collapsible table" size="small" stickyHeader>
    <TableHead>
      <TableRow>
      {tableheaders.map((header,index) => (
          <TableCell key={header.id}>
            <TableSortLabel
              active={sort.keytoSort === header.KEY}
              direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
              onClick={() => handleHeaderClick(header)}
              disabled={!header.sortable}
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
        <TableBody>
          {paginatedNotes.length === 0 ? (
            <TableRow>
              <TableCell colSpan={headCells?.length} align="center">
                No Data
              </TableCell>
            </TableRow>
          ) : (
            paginatedNotes?.map((row: any, index: number) => (
              <React.Fragment key={row.cron_id}>
                <TableRow
                  sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}
                  style={{
                    backgroundColor: isCheckboxCheckedArray[index] ? "#F7EEF9" : "inherit",
                    boxShadow: row.selectedAccount ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
                  }}
                >
                  <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                      <Checkbox
                        checked={selectedRows.some((selectedRow: any) => selectedRow.shared_uid === row.shared_uid)}
                        color="secondary"
                        onChange={() => handleCheckboxChange(row, index)}
                      />
                      {row?.note?.split(' ')?.slice(0, 3).join(' ')}
                    </div>
                  </TableCell>
                  <TableCell align="left">{(siteNames.find((source: any) => source.key === row?.website_shared_uid)?.site?.site_name || "")}</TableCell>
                  <TableCell align="left">{row?.brand}</TableCell>
                  <TableCell align="left">{row?.note_type}</TableCell>
                  <TableCell align="left">{formatDateToDDMMYYYY(row?.activity_date)}</TableCell>
                  <TableCell align="left">
                    <div className="chip-data">
                      {Array.isArray(row.labels) && row.labels?.length > 0 ? (
                        <>
                          <Chip
                            label={row.labels[0]}
                            sx={{ backgroundColor: "#7B1FA2", color: "#ffffff", height: "26px" }}
                          />
                          <AvatarGroup
                            total={row.labels?.length - 1}
                            variant="circular"
                      >
                      </AvatarGroup>
                        </>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell align="left">{formatDate(row?.created)}</TableCell>
                  <TableCell align="left">
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                      <Avatar sx={{ width: 24, height: 24 }}>{row?.created_by && row?.created_by.charAt(0)}</Avatar>
                      {row?.created_by ? row?.created_by : "N/A"}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      color="secondary"
                      onClick={() => handleRowClick(row, index)}
                    >
                      {openRow === row.shared_uid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={headCells?.length}>
                    <Collapse in={openRow === row.shared_uid} timeout="auto" unmountOnExit>
                      <Box sx={{ padding: '20px 0px' }}>
                        <AdditionalContentActivityNotes
                          open={openRow}
                          oauthTokenHeader={oauthTokenHeader}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          ActivityNotesContent={ActivityNotesContent}
                          setActivityNotesContent={setActivityNotesContent}
                          isCardVisible={isCardVisible}
                          setIsCardVisible={setIsCardVisible}
                          fileBase64={fileBase64}
                          setFileBase64={setFileBase64}
                          isDragActive={isDragActive}
                          singleNoteShareUID={singleNoteShareUID}
                          setIsDragActive={setIsDragActive}
                          files={files}
                          setFiles={setFiles}
                          isThisAdd={isThisAdd}
                          getFileContent={getFileContent}
                          fileBase64s={fileBase64s}
                          setFileBase64s={setFileBase64s}
                          postFileUpload={postFileUpload}
                          UpdateActivityNote={UpdateActivityNote}
                          userData={userData}
                          data={data}
                          rowIndexNumber={rowIndexNumber}
                          setFileName={setFileName}
                          fileName={fileName} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
  </Table>
  <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={sortedData?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
          display: "none",
        },
        ".MuiTablePagination-actions": {
          display: "none",
        },
        ".MuiTablePagination-toolbar": {
          paddingLeft: "16px",
        },
      }}
    />
    <Pagination
      count={pageCount}
      showFirstButton
      showLastButton
      className="pagination-button"
      color="secondary"
      size="small"
      sx={{
        ".css-wjh20t-MuiPagination-ul": {
          justifyContent: "center",
        },
      }}
      onChange={(event, value) => handleChangePage(null, value - 1)}/>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
  </Stack>
  </TableContainer>
  }
  </>
  );
}
