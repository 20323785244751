import React from "react";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import resetPasswordStyles from "./ResetPasswordStyles.module.css";
import { Link } from "react-router-dom";

interface ResetPasswordUIProps {
  email: string;
  onEmailChange: (value: string) => void;
  onResetPasswordClick: () => void;
  showSuccessPopup: boolean;
  showErrorPopup: boolean;
  onCloseSuccessPopup: () => void;
  onCloseErrorPopup: () => void;
  errorPopupMessage: string;
}

const ResetPasswordSection: React.FC<ResetPasswordUIProps> = ({
  email,
  onEmailChange,
  onResetPasswordClick,
  showSuccessPopup,
  showErrorPopup,
  onCloseSuccessPopup,
  onCloseErrorPopup,
  errorPopupMessage,
}) => {
  return (
    <section className={resetPasswordStyles.resetPasswordWindowContant}>
      <div className={resetPasswordStyles.resetPasswordLogoSection}>
        <img
          className={resetPasswordStyles.optivalLogo1Icon}
          alt=""
          src="/optivallogo-1@2x.png"
        />
      </div>
      <div className={resetPasswordStyles.resetPasswordTitleSection}>
        <h4 className={resetPasswordStyles.typography1}>
          <div className={resetPasswordStyles.h4}>
            Confirm Your E-mail to Reset Password
          </div>
        </h4>
      </div>
      <form className={resetPasswordStyles.resetPasswordActionsSection}>
        <div className={resetPasswordStyles.resetPasswordTextFieldsSection}>
          <TextField
            className={resetPasswordStyles.textfield}
            color="primary"
            variant="outlined"
            type="text"
            label="Email"
            placeholder="Enter Your Email"
            size="medium"
            margin="none"
            required
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
          />
        </div>

        <Button
          className={resetPasswordStyles.resetPasswordButton}
          variant="contained"
          color="secondary"
          onClick={onResetPasswordClick}
        >
          RESET PASSWORD
        </Button>
        <Link to="/login" className={resetPasswordStyles.backToLoginButton}>
          <Button variant="outlined" color="secondary">
            Back to Login
          </Button>
        </Link>
      </form>

      <Snackbar
        open={showSuccessPopup}
        autoHideDuration={6000}
        onClose={onCloseSuccessPopup}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={onCloseSuccessPopup} 
          severity="success" 
          action={
            <Button color="inherit" size="small" onClick={onCloseSuccessPopup}>
              CLOSE
            </Button>
          }
        >
          Password reset email sent successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorPopup}
        autoHideDuration={6000}
        onClose={onCloseErrorPopup}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={onCloseErrorPopup} 
          severity="error" 
          action={
            <Button color="inherit" size="small" onClick={onCloseErrorPopup}>
              CLOSE
            </Button>
          }
        >
          {errorPopupMessage}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default ResetPasswordSection;
