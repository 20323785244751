import { Dialog, DialogActions,DialogContent, DialogTitle, Button,TextField, Paper, Typography, InputAdornment, Autocomplete, Alert, Snackbar, CircularProgress,} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Stack } from "@mui/system";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ManualExpenseDocs from "../CustomTableManualExpenseNotes/ManualExpenseDocs";
import { advertisingAccountsEndPointLogic } from "../../../../../logic/PaidTrafficManagement/AdvertisingAccounts/AdvertisingAccounts/advertisingAccountsEndPointLogic";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ManualExpenseEndPointLogic } from "../../../../../logic/Finance/ProviderExpense/ManualExpenseEndpointLogic";
import { trackPromise } from "react-promise-tracker";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../../../../features/userSlice';
import ManualExpenceFileUpload from '../CustomTableManualExpenseNotes/ManualExpenceFileUpload';

interface EditManualExpenseDialogProps {
  hasEditPermissions: any;
  result: any;
  setManualexpenserows: any;
  selectedAsset: any;
  manualexpenserows: any;
  trafficSources:any;
  open: boolean;
  handleClose: () => void;
  note: string;
  oauthTokenHeader:string;
  siteNames:any;
  fileBase64:any,
  setFileBase64:any,
  setFileName:any,
  fileName:any,
  setRefresh:any;
  selectedRows:any;
  setSelectedRows:any;
  isCardVisible:any;
  setIsCardVisible:any;
}

const EditManualExpenseDialog: React.FC<EditManualExpenseDialogProps> = ({ hasEditPermissions,setManualexpenserows, trafficSources, result, selectedAsset, manualexpenserows, open, handleClose, note,oauthTokenHeader,siteNames,fileBase64,setFileBase64,setFileName,fileName,setRefresh,selectedRows,setSelectedRows,
  isCardVisible,setIsCardVisible
 }) => {

  const [trafficSource,setTrafficSource]=useState([]);
  const [date,setDate]=useState("");
  const [impressions,setImpressions]=useState("") as any;
  const [currencies,setCurrencies]=useState([]);  
  const [currencyCode, setCurrencyCode] = useState<any>("");
  const [expense,setExpense]=useState("") as any;// need to check this
  const [clicks,setClicks]=useState("") as any;
  const [comment,setComment]=useState("") as any;
  const [loading, setLoading] = useState(false);  // Add state for error handling
  const [alertOpen, setAlertOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();
  const singleExpneseShareUID = selectedRows[0]?.shared_uid;

  const filteredWebsiteShareUid = result.filter((log:any) => {
    const statusAssets = selectedAsset && selectedAsset.length > 0 ? selectedAsset.includes(log.related_asset) : true;
    return statusAssets;
  });

  const selectedWebsiteSharedUid = filteredWebsiteShareUid.find((source: any) => source.key === selectedRows[0]?.website_shared_uid);
  const data :any = {
      "website_shared_uid": selectedWebsiteSharedUid && selectedWebsiteSharedUid.key,
      "currency": currencyCode,
      "date": date ? date : selectedRows[0]?.date,
      "impressions": impressions ? impressions : selectedRows[0]?.impressions,
      "clicks": clicks ? clicks : selectedRows[0]?.clicks,
      "expenses": expense ? expense : selectedRows[0]?.expenses,
      "comment" : comment ? comment : selectedRows[0]?.expense_name
    };

useEffect(() => {
  let currentdate = manualexpenserows?.data?.[selectedWebsiteSharedUid]?.[0]?.date;
  setDate(currentdate);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const handleFileUpload = async() => {
  setLoading(true);
  if (fileBase64 && fileBase64.length > 0) {
    Promise.all(
      fileBase64.map((file:any) => {
        const singleFileData = {
          "object_type": "manual_adjustment_expenses",
          "file_name": file.name,
          "file": file.base64
        };
         // Upload each file one by one
        return ManualExpenseEndPointLogic.fileUploadME(oauthTokenHeader, singleFileData, singleExpneseShareUID, trackPromise);
      })
    )
    .then(() => {
      setFileBase64([]); // Clear the files after upload
      setIsCardVisible(false);
    })
    .finally(() => {
      setLoading(false); // Stop the loading spinner after uploads are done
    })
    .catch((error: any) => {
      console.error("Error uploading files: ", error);
      setLoading(false); // Stop loading in case of error
      throw new Error("Error uploading files: ", error);
    })
  } else {
    setLoading(false); // Stop loading if there are no files
  }
}


useEffect(()=> {
  if (open) {
    advertisingAccountsEndPointLogic.getTrafficSource(oauthTokenHeader, setTrafficSource);
    ManualExpenseEndPointLogic.getCurrencies(oauthTokenHeader,setCurrencies);
  }
},[open,oauthTokenHeader])


const handleSave = async () => {
  setLoading(true);
   await ManualExpenseEndPointLogic.editExpense(oauthTokenHeader, data, selectedRows[0]?.shared_uid, trackPromise)
   setSnackbarOpen(true);
   setSnackbarMessage('Expence Edited Successfully');
   await handleFileUpload()
    .then(() => {
      handleClose();
      setRefresh(true);
      setDate("");
      setImpressions("");
      setClicks("");
      setExpense("");
      ManualExpenseEndPointLogic.getManualExpenseContent(oauthTokenHeader, setIsLoading, setManualexpenserows, dispatch); // Refresh the table after save
      setLoading(false);
      setSuccess("Expense Edited Successfully");
      setSelectedRows([]);
    })
    .catch((error:any) => {
      setSnackbarOpen(true);
      setSnackbarMessage('Error Editing Expense');
      console.error("Error during save:", error);
    });
};

const handleAlertClose = () => {
  setAlertOpen(false);  // Close Snackbar
};

useEffect(() => {
  if (alertOpen) {
    const timer = setTimeout(() => {
      setAlertOpen(false);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timer when component unmounts or alertOpen changes
  }
}, [alertOpen]);

const renderSnackbar = () => {
  return (
    <Snackbar
      sx={{ zIndex: '9999999' }}
      open={snackbarOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarMessage.includes('Error') ? 'error' : 'success'}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
};
const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    // Find the currency based on the selectedRows when the component mounts or updates
    const initialCurrency = currencies.find(
      (currency:any) => currency.currency_code === selectedRows[0]?.currency__currency_code
    );
    const initialCurrencySharedUid = currencies.find(
      (currency:any) => currency.currency_code === selectedRows[0]?.currency__currency_code
    ) as any;
    setSelectedCurrency(initialCurrency || null); // Set to null if not found
    setCurrencyCode(initialCurrencySharedUid?.shared_uid);
  }, [currencies, selectedRows]);

  useEffect(()=>{
    setImpressions(selectedRows[0]?.impressions);
    setClicks(selectedRows[0]?.clicks);
    setExpense(selectedRows[0]?.expenses);
    setComment(selectedRows[0]?.expense_name)
  },[open,selectedRows])


  const handleChange = (event:any, option:any) => {
    setSelectedCurrency(option); // Update the selected currency
    setCurrencyCode(option?.shared_uid); // Assuming setCurrencyCode is defined elsewhere
  };

  return (
    <>
    {renderSnackbar()}
    {loading ? <SimpleBackdrop/> :
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}>
    <Paper sx={{ padding: "24px 24px 20px 24px", height: "836px", overflowY: "auto" }}>
      {alertOpen && (
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          All mandatory fields are required!
        </Alert>
      )}
      {Object.keys(success).length >= 1 && (
        <Snackbar onClose={handleAlertClose} autoHideDuration={3000}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Expense Created Successfully
          </Alert>
        </Snackbar>
      )}
      <DialogTitle sx={{ padding: "0px 0px 16px 0px" }}>Edit Expense</DialogTitle>
      <Typography sx={{ marginBottom: "16px" }}>Details</Typography>
      <DialogContent sx={{ padding: "8px 0px" }}>
        <Stack flexDirection="row" gap="16px">
          <Autocomplete
            options={siteNames}
            disabled={true}
            value={siteNames.find((source: any) => source.key === selectedRows[0]?.website_shared_uid)}
            getOptionLabel={(option: any) => option?.site?.site_name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Related To"
                placeholder='Choose Asset from the list.'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "273px" }}
          />
          <Autocomplete
            options={trafficSource}
            disabled={true}
            getOptionLabel={(option: any) => option.traffic_source_name}
            value={trafficSources.find((source: any) => source.shared_uid === selectedRows[0]?.traffic_source__shared_uid)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source"
                placeholder='Choose Source from the list.'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "273px" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ paddingTop: "0", overflow: "visible" }}>
              <DatePicker
                className="brandDataDatePicker"
                slotProps={{
                  textField: {
                    size: "small",
                    color: "secondary",
                    required: true,
                  },
                }}
                sx={{ width: "273px" }}
                format="DD-MM-YYYY"
                label="Start Date "
                value={dayjs(selectedRows[0]?.date)}
                onChange={(date: any) => {
                  const updatedRows = [...selectedRows];
                  updatedRows[0].date = date;
                  setDate(dayjs(date).format("YYYY-MM-DD"));
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogContent sx={{ padding: "0" }}>
        <TextField
          size="small"
          color="secondary"
          sx={{ mt: "16px", mb: "16px", padding: "0", width: "49%", marginRight: "16px" }}
          id="outlined-helperText"
          label="Impressions"
          onKeyDown={(e) => {
            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "Tab") { // Allow only digits, backspace, and delete
              e.preventDefault();
            }
          }}
          value={impressions}
          onChange={(e: any) => {
            const value = e.target.value;
            setImpressions(value === "" ? "" : parseInt(value, 10));
          }}
        />
        <TextField
          size="small"
          color="secondary"
          sx={{ mt: "16px", mb: "16px", padding: "0", width: "49%" }}
          id="outlined-helperText"
          label="Clicks"
          onKeyDown={(e) => {
            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "Tab") { // Allow only digits, backspace, and delete
              e.preventDefault();
            }
          }}
          value={clicks}
          onChange={(e: any) => {
            const value = e.target.value;
            setClicks(value === "" ? "" : parseInt(value,10));
          }}
        />
      </DialogContent>
      <DialogContent sx={{ padding: "0" }}>
        <Stack flexDirection="row">
        <TextField
            size="small"
            color="secondary"
            sx={{ margin: "8px 0", padding: "0", width: "49%", marginRight: "16px" }}
            id="outlined-helperText"
            label="Amount"
            onKeyDown={(e) => {
              const inputValue = (e.target as HTMLInputElement).value;

              // Allow digits, backspace, delete, and '-' (only at the beginning)
              if (
                !/[0-9]/.test(e.key) &&
                e.key !== "Backspace" &&
                e.key !== "Delete" &&
                !(e.key === "-" && inputValue === "")
                &&
                !(e.key === "-" && inputValue.length === 0) && // Allow '-' only if input is empty
                !(e.key === "-" && inputValue[0] === '-') && e.key !== "Tab"
              ) {
                e.preventDefault();
              }

              // Prevent '-' in any position other than the first
              if (e.key === "-" && inputValue.includes("-")) {
                e.preventDefault();
              }
            }}
            value={expense}
            onChange={(e: any) => {
              const value = e.target.value;
              
              // Handle empty value and allow negative numbers as valid input
              setExpense(value === "" || value === "-" ? value : parseInt(value, 10));
            }}
          />
          <Autocomplete
            options={currencies}
            getOptionLabel={(option: any) =>
              option ? `${option.currency_symbol} ${option.currency_name}` : ''
            }
            value={selectedCurrency} // Set the value to the selected currency
            onChange={handleChange} // Handle currency change
            renderInput={(params) => (
              <TextField
                {...params}
                label="Currency"
                placeholder="Choose Currency from the list."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                }}
                color="secondary"
                required
              />
            )}
            size="small"
            sx={{ width: "49%", marginTop: "8px" }}
          />
        </Stack>
      </DialogContent>
      <Typography sx={{ marginBottom: "16px", marginTop: "8px", fontSize: "12px", lineHeight: "20px", color: "#00000099" }}>
        Fields marked with * are mandatory.
      </Typography>
      <Typography mt="16px" mb="8px">Expense Description</Typography>
      <TextField
        id="outlined-multiline-flexible"
        label="Statement" // Set your label here
        placeholder="Add your description here" // Set your placeholder here
        multiline
        color="secondary"
        size="medium"
        maxRows={4}
        value={comment}
        sx={{
          width: '852px',
        }}
        onChange={(e) => setComment(e.target.value)}
      />
      <Typography mt="16px" mb="8px">Attachments</Typography>
      <ManualExpenseDocs setFileBase64={setFileBase64} setFileName={setFileName} fileName={fileName} setIsCardVisible={setIsCardVisible} isCardVisible={isCardVisible} fileBase64={fileBase64} />
      <ManualExpenceFileUpload open={open} singleExpneseShareUID={singleExpneseShareUID} oauthTokenHeader={oauthTokenHeader} />
    </Paper>
    <Paper sx={{ padding: "8px 16px 12px 16px" }}>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="secondary" variant="contained">
          {loading ? <CircularProgress/> : <>EDIT expense <CheckOutlinedIcon fontSize="small" sx={{ marginLeft: "8px" }} /></>}
        </Button>
      </DialogActions>
    </Paper>
  </Dialog>
   }
    </>
  );
};

export default EditManualExpenseDialog;
