import { Paper, Typography } from '@mui/material'
import WebsiteActionTable from './WebsiteActionsElements/WebsiteActionTable/WebsiteActionTable'
import { Grid } from "@mui/material"
import WebsiteActionFilterCard from './WebsiteActionsElements/WebsiteActionFilterCard/WebsiteActionFilterCard'

function WebsiteActions() {
  const tableHeadings = {
    "site_name": "Site Name",
    "type": "Type",
    "territory": "Territory",
    "industry": "Industry",
    "status": "Status",
    "time_zone": "Time Zone",
    "last_edited": "Last Edited",
    "edited_by": "Edited By",
    "quick_actions":"Quick Actions",
    "more_info":"More info",
  }

 const rows = [
    {
        "site_name": "LuckyBingo",
        "type": "PPC",
        "territory": "UK",
        "industry": "Gaming",
        "status": "active",
        "last_edited": "10/15/23",
        "edited_by": "Alice",
        "time_zone": "Europe/London",
        "quick_actions": "",
        "more_info": "Details"
    },
    {
        "site_name": "MegaWinCasino",
        "type": "PPC",
        "territory": "Germany",
        "industry": "Casino",
        "status": "active",
        "last_edited": "09/22/23",
        "edited_by": "Carlos",
        "time_zone": "Europe/Berlin",
        "quick_actions": "",
        "more_info": "Stats"
    },
    {
        "site_name": "PlayTimeSports",
        "type": "PPC",
        "territory": "France",
        "industry": "Sports Betting",
        "status": "inactive",
        "last_edited": "11/05/23",
        "edited_by": "Marie",
        "time_zone": "Europe/Paris",
        "quick_actions": "",
        "more_info": "Overview"
    },
    {
        "site_name": "FunSlots",
        "type": "SEO",
        "territory": "Canada",
        "industry": "Slots",
        "status": "active",
        "last_edited": "08/30/23",
        "edited_by": "David",
        "time_zone": "America/Toronto",
        "quick_actions": "",
        "more_info": "Info"
    },
    {
        "site_name": "GameArena",
        "type": "SEO",
        "territory": "USA",
        "industry": "E-Sports",
        "status": "active",
        "last_edited": "07/25/23",
        "edited_by": "Linda",
        "time_zone": "America/New_York",
        "quick_actions": "",
        "more_info": "Update"
    },
    {
        "site_name": "SuperJackpot",
        "type": "SEO",
        "territory": "Australia",
        "industry": "Lottery",
        "status": "inactive",
        "last_edited": "06/18/23",
        "edited_by": "Tom",
        "time_zone": "Australia/Sydney",
        "quick_actions": "",
        "more_info": "Archived"
    },
    {
        "site_name": "BingoFest",
        "type": "SEO",
        "territory": "Spain",
        "industry": "Bingo",
        "status": "inactive",
        "last_edited": "05/21/23",
        "edited_by": "Sofia",
        "time_zone": "Europe/Madrid",
        "quick_actions": "",
        "more_info": "Logs"
    },
    {
        "site_name": "ProBetHub",
        "type": "SEO",
        "territory": "Italy",
        "industry": "Sports Betting",
        "status": "inactive",
        "last_edited": "10/08/23",
        "edited_by": "Marco",
        "time_zone": "Europe/Rome",
        "quick_actions": "",
        "more_info": "Beta Test"
    },
    {
        "site_name": "GoldenSlots",
        "type": "PPC",
        "territory": "New Zealand",
        "industry": "Slots",
        "status": "active",
        "last_edited": "07/15/23",
        "edited_by": "Ella",
        "time_zone": "Pacific/Auckland",
        "quick_actions": "",
        "more_info": "Closed Info"
    },
    {
        "site_name": "CasinoRoyal",
        "type": "PPC",
        "territory": "Sweden",
        "industry": "Casino",
        "status": "inactive",
        "last_edited": "08/01/23",
        "edited_by": "Lars",
        "time_zone": "Europe/Stockholm",
        "quick_actions": "",
        "more_info": "Top Players"
    }
];
  return (
    <>
    <Paper sx={{padding:"8px 16px"}}>
     <Grid container alignItems="center" sx={{ mb: 2 }} >
        <Grid item xs={8}>
          <Typography variant="h3" sx={{ fontSize: "24px" }}>
            Websites
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            Create new or edit existing websites.
          </Typography>
        </Grid>
      </Grid>
      <WebsiteActionFilterCard/>
      <WebsiteActionTable tableHeadings={tableHeadings} rows={rows}/>
    </Paper>
    </>
  )
}

export default WebsiteActions