import Box from "@mui/material/Box";
import "./SequenceFilters.css";
import FilterComponent from "../../../../ReusableFIlterComponent/FilterComponent";
import { useState } from "react";

interface Props {
  etlSequence:any
  updatedArray:any
  filteredDataSequence:any;
  selectedLastRunDate: any;
  setSelectedLastRunDate: any;
  selectedStatuses: any;
  setSelectedStatuses: any;
  selectedLastRun:any;
  setSelectedLastRun:any;
  selectedFrequencyDate:any;
  setSelectedFrequencyDate:any;
  selectedChain:any,
  setSelectedChain:any,
}

export default function SequenceFilterItems({etlSequence,updatedArray,selectedLastRunDate,setSelectedLastRunDate,selectedStatuses,setSelectedStatuses,selectedLastRun,setSelectedLastRun,selectedFrequencyDate,setSelectedFrequencyDate,setSelectedChain} : Props) {

  const [searchStatus, setSearchStatus] = useState('');
  const filteredChainData = updatedArray;
  const filterConfig = [
    {
      key: 'last run',
      label: 'Select Last Run',
      filterName: 'last_run',
      data: filteredChainData,
      selectedOption: selectedLastRunDate,
      onSelectionChange: (updatedSelection: any) => setSelectedLastRunDate(updatedSelection),
      count: 0,
      colorSelected: selectedLastRunDate  !== null && selectedLastRunDate.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      isDateRangeFilter: true,
    },
    {
      key: 'status',
      label: 'Select Status',
      searchTerm: searchStatus,
      onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchStatus(e.target.value),
      selectedOption: selectedStatuses,
      filterName: 'active',
      data: filteredChainData,
      onSelectionChange: (updatedSelection: any) => setSelectedStatuses(updatedSelection),
      count: selectedStatuses && selectedStatuses.length,
      colorSelected: selectedStatuses !== null && selectedStatuses?.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      showSearch: false,
      valueMapping: { "true": "Active", "false": "Inactive" }
    },
    {
      key: 'Frequency',
      label: 'Select Frequency',
      filterName: 'cron_job', //need to be fixed
      data: filteredChainData, //need to be fixed
      selectedOption: selectedFrequencyDate ,
      onSelectionChange: (updatedSelection: any) => setSelectedFrequencyDate(updatedSelection),
      count: 0,
      colorSelected: selectedFrequencyDate !== null && selectedFrequencyDate?.length > 0 ? '#9C27B0' : '#000000DE',
      multiSelect: false,
      isDateRangeFilter: true,
    },
  ];

  const clearFilters = () => {
    setSelectedStatuses(null);
    setSelectedLastRun([]);
    setSelectedFrequencyDate([]);
    setSelectedLastRunDate([]);
    setSelectedChain(null);
  }

  return (
    <Box sx={{paddingTop:"10px"}}>
      <FilterComponent filterConfig={filterConfig} clearFilters={clearFilters} />
    </Box>
  );
}
