import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Alert,
  CircularProgress,
  Pagination,
  Snackbar,
  Stack,
  Switch,
  TablePagination,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Link from '@mui/material/Link';
import { setIsLoading } from '../../../../../../features/userSlice';
import { QuarterlySpeedGoalsEndPointLogic } from '../../../../../../logic/AssetManagement/Health/Quarterly Website Speed Goals/QuarterlySpeedGoalsEndPointLogic';
import { useDispatch } from 'react-redux';

interface Props {
  tableHeadings: { [key: string]: string };
  oauthTokenHeader: any;
  selectedSharedUid: any;
  setPageInternalListData: any;
  rows: any[];
  selectedSearchValue:any;
  loading: any;
  selectedStatus:any;
  setSelectedStatus:any; 
  RequestedBy:any; 
  setRequestedBy:any; 
}

function PageScanRequestTable({selectedSearchValue,oauthTokenHeader,selectedSharedUid,setPageInternalListData, tableHeadings, rows,selectedStatus,RequestedBy}: Props) {
  const [, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = React.useState<number>(0);
  const headCells = Object.entries(tableHeadings);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState<number | null>(null);
  const dispatch = useDispatch();
  const [, setTogglePageSpeedData] = useState<any[]>([]);
  const [sort, setSort] = useState<{ keytoSort: string | undefined, direction: 'asc' | 'desc' | undefined }>({keytoSort: 'created',direction: 'desc'});

  // Function to integrate filter component with the table
  const  filteredData = rows?.filter((scan:any) => {
    const isActive = selectedStatus?.[0] === 'true';

    const requestedName = RequestedBy && RequestedBy?.length !== 0 ? RequestedBy?.includes(scan?.created_by): true;

    //filter for Search Field
    const pageName = selectedSearchValue && selectedSearchValue.length !== 0
    ? selectedSearchValue.includes(scan?.page_name)
    : true;

    return (
      (requestedName) && (pageName) &&
      (selectedStatus === null || (isActive && scan?.active === true) || (!isActive && scan?.active === false))
    )
  });

  const tableHeaders = [
    {id:0, KEY: 'page_name', label: 'Page Name',sortable: true},
    {id:1, KEY: 'active', label: 'Scan Status',sortable: true},
    {id:2, KEY: 'tests_amount', label: 'Scan Count', sortable: true},
    {id:3, KEY: 'last_run', label: 'Last Scan',sortable: true},
    {id:4, KEY: 'created', label: 'Requested On', sortable: true},
    {id:5, KEY: 'created_by', label: 'Requested By', sortable: true},
  ];

  const handleHeaderClick = (header: any) => {
    if (!header.sortable) return; // If the header is not sortable, return
    setSort({
      keytoSort: header.KEY,
      // Toggle sort direction if the same column is clicked, otherwise default to ascending
      direction: header.KEY === sort.keytoSort && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSortedData = (arrayToSort: any[]) => {
      // If no sorting key is set, return the original array
      if (!sort.keytoSort || !sort.direction) {
      return arrayToSort;
    }

    // Clone the array to avoid mutating the original array
    return arrayToSort.slice().sort((a, b) => {
      // Check if sort.keytoSort is not undefined and has the property
      const keytoSort = sort.keytoSort;

      if (keytoSort === undefined) {
        return 0; // Return 0 if the sorting key is undefined
      }

      const aValue = a[keytoSort];
      const bValue = b[keytoSort];

      // Handle undefined values for sorting
      if (aValue === undefined) {
        return 1; // Move undefined values to the end
      }
      if (bValue === undefined) {
        return -1; // Move undefined values to the end
      }

      // Handle "N/A" values for sorting
      if (aValue === "") {
        return 1; // Move "N/A" values to the end
      }
      if (bValue === "") {
        return -1; // Move "N/A" values to the end
      }

      // Perform sorting based on the direction and type of values
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // String comparison (localeCompare for correct string sorting)
        return sort.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        // Numeric comparison
        return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (aValue instanceof Date && bValue instanceof Date) {
        // Date comparison
        return sort.direction === 'asc'
          ? aValue.getTime() - bValue.getTime()
          : bValue.getTime() - aValue.getTime();
      } else {
        // Fallback to string comparison if values are of different types
        return sort.direction === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }
    });
    };

  const sortedRows = getSortedData(filteredData || []);
  const paginatedRequests = sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
    setPage(0);
  },[setPage,selectedStatus, RequestedBy, selectedSearchValue])

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  useEffect(() => {
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  }, [rowsPerPage, filteredData])

  useEffect(() => {
    setRows(filteredData);
    const newPageCount = Math.ceil(filteredData?.length / rowsPerPage);
    setPageCount(newPageCount);
  },[filteredData,rowsPerPage]);

const handleStatusChange = async(row:any) =>{
  setIsLoading(true);
  setLoading(row?.shared_uid);
  const newActiveState = !row.active;
  const data = { active: newActiveState };
  try {
    await QuarterlySpeedGoalsEndPointLogic.togglePageSpeed(oauthTokenHeader,data,selectedSharedUid,row?.shared_uid,setTogglePageSpeedData);
    setSnackbarOpen(true);
    setSnackbarMessage('Status updated successfully');
    if(oauthTokenHeader) {
      setIsLoading(true);
      await QuarterlySpeedGoalsEndPointLogic.getPageViewInternalList(oauthTokenHeader,selectedSharedUid,setPageInternalListData,setPageInternalListData,dispatch);
    } else {
      throw new Error("User doesn't have permissions to fetch advertising accounts data");
    }
  } catch(error){
    console.error("Error updating page speed:", error);
  } finally {
    setIsLoading(false);
    setLoading(null);
    setSnackbarOpen(true);
    setSnackbarMessage('Status updated successfully');
  }
};

const renderSnackbar = () => {
  return (
    <Snackbar
      sx={{ zIndex: "9999999" }}
      open={snackbarOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={'success'}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  };

/* const label = { inputProps: { 'aria-label': 'Color switch demo' } }; */

  return (
    <>
    {renderSnackbar()}
      <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
            {tableHeaders?.map((header:any,index:any) => (
                <TableCell key={header.id}>
                  <TableSortLabel
                    active={sort.keytoSort === header.KEY}
                    direction={sort.keytoSort === header.KEY ? sort.direction : 'asc'}
                    onClick={() => handleHeaderClick(header)}
                    disabled={!header.sortable}
                >
                   {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRequests?.length > 0 && paginatedRequests
              ?.map((row, actualIndex) => (
                <React.Fragment key={actualIndex}>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1) !important" } }}
                    >
                      {headCells?.map(([key], cellIndex) => (
                        <TableCell key={cellIndex} align="left">
                          {key === 'page_name' ? (
                            <Link href={row.request_url} color="secondary" target="_blank" rel="noopener noreferrer">
                              {row[key] ? row[key] : " "}
                            </Link>
                          ) : key === 'tests_amount' ?(
                             row[key] ? row[key] : "0"
                          ): key === 'last_run' ?(
                            row[key] ? formatDate(row[key]) : " "
                          ): key === 'created' ?(
                            row[key] ? formatDate(row[key]) : " "
                          ): key === 'created_by' ?(
                            row[key] ? row[key] : " "
                          ): key === 'active' ? (
                            <>
                          {loading === row?.shared_uid ? ( // Check if the current row is loading
                            <CircularProgress size={24} color='secondary' />
                          ) : (
                            <>
                              <Switch
                                checked={row?.active}
                                onChange={() => handleStatusChange(row)} // Pass row to handler
                                sx={{
                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#2E7D32',
                                  },
                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#2E7D32',
                                  }
                                }}
                              />
                              {row.active ? 'Active' : 'Inactive'}
                            </>
                          )}
                        </>
                          ) : (
                            row[key] ? row[key] : " "
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
              ))}
              {paginatedRequests?.length === 0 && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Typography variant="body1" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={sortedRows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
                display: "none",
              },
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-toolbar": {
                paddingLeft: "16px",
              },
            }}
          />
          <Pagination
            count={pageCount}
            page={page+1}
            showFirstButton
            showLastButton
            className="pagination-button"
            color="secondary"
            size="small"
            sx={{
              ".css-wjh20t-MuiPagination-ul": {
                justifyContent: "center",
              },
            }}
            onChange={(event, value) =>
              handleChangePage(null, value - 1)
            }
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortedRows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: 'hidden-displayed-rows', // Apply the custom class
            }}
            sx={{
              ".MuiTablePagination-actions": {
                display: "none",
              },
              ".MuiTablePagination-input": {
                marginRight: "15px",
              },
            }}
          />
        </Stack>
      </TableContainer>
    </>
    
  );
}

export default PageScanRequestTable;
