import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import "./CreateProcess.css";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { CheckCircleOutline } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { setIsLoading } from "../../../../../features/userSlice";
import store from "../../../../../store";
import { ETLProcessListEndPointLogic } from "../../../../../logic/Admin/ETL Process List/ETLProcessListEndPointLogic";
import { trackPromise } from "react-promise-tracker";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "#fff",
  padding: "20px 24px",
  borderRadius: "4px",
  boxShadow: "0px 0px 16px #50505085",
  zIndex: "999",
  maxHeight: "80vh",
  overflowY: "auto",
  overflowX: "hidden",
};

const modalHeadin = {
  fontSize: "20px",
  fontWeight: "500",
  marginBottom: "16px",
};

type EditProcessProps = {
  editOpenModal: boolean;
  editOnCloseModal: any;
  selectedRow:any;
  setSelectedRow:any;
  oauthTokenHeader:string; 
  setReload:any;
};



const EditProcess = (props: EditProcessProps) => {
  const { editOpenModal, editOnCloseModal,selectedRow,oauthTokenHeader,setReload,setSelectedRow } = props;
  const [id,setId] = useState(selectedRow?.length!==0 && selectedRow[0]?.id);
  const [name,setName] = useState(selectedRow?.length!==0 && selectedRow[0]?.name);
  const [command,setCommand] = useState(selectedRow?.length!==0 && selectedRow[0]?.command);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [,setETLProcess] = useState([]);

  useEffect(() => {
    if (selectedRow?.length !== 0) {
      setId(selectedRow?.id);
      setName(selectedRow?.name);
      setCommand(selectedRow?.command ? selectedRow?.command : "N/A");
    }
  }, [selectedRow]);



  const editETLProcess = async (data: any) => {
    store.dispatch(setIsLoading(true));
    try {
      // if (hasEditPermissions) {
        await ETLProcessListEndPointLogic.editETLProcess(
          oauthTokenHeader,
          setETLProcess,
          trackPromise,
          data
        );
        setSnackbarSeverity("success");
        setSnackbarMessage("ETL Process created successfully!");
      // } else {
      //   throw new Error("User doesn't have permissions to create ETL process");
      // }
    } catch (error) {
      console.error("Error creating ETL process:", error);
    } finally {
      // setIsLoad(false);
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async () => {
    if (!name || !command) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Both fields are mandatory.");
      setSnackbarOpen(true);
      return;
    }

    const data = {
      data: {
        id:id,
        name: name,
        command: command,
      },
    };
    await editETLProcess(data);
    editOnCloseModal();
    setReload(true);
    setName([]);
    setCommand([]);
    setSelectedRow([]);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };




  return (
    <div>
      <Modal
        open={editOpenModal}
        onClose={editOnCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Typography
            sx={modalHeadin}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Process
          </Typography>

          {/* Modal Body */}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="processName"
                required
                label="Process Name"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                size="small"
                fullWidth
                color="secondary"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="processCommand"
                required
                label="Process Command"
                value={command}
                onChange={(e)=>setCommand(e.target.value)}
                size="small"
                fullWidth
                color="secondary"
              />
            </Grid>
            <Grid
              item
              xs={6}
              justifyContent={"space-between"}
              sx={{ display: "flex" }}
            >
              <FormControlLabel
                control={<Switch defaultChecked={selectedRow[0]?.active ? true : false} color="success" />}
                label="Process Status"
                disabled
              />
              <Button
                // sx={{ color: "#2E7D32", background: "#EDF7ED" }}
                color={selectedRow?.active ? "success" : "warning"}
                variant="outlined"
                startIcon={<CheckCircleOutline />}
              >
                {selectedRow?.active ? "Enable" : "Disable"}
              </Button>
            </Grid>
            <Grid item xs={6}>
            <TextField
                id="ID"
                disabled
                label="ID"
                value={id}
                size="small"
                fullWidth
                color="secondary"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="p"
                sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
              >
                All fields are mandatory.
              </Typography>
            </Grid>
          </Grid>

          {/* Modal footer */}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Grid
              item
              xs={12}
              justifyContent={"flex-end"}
              sx={{ display: "flex" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={editOnCloseModal}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<SaveOutlinedIcon />}
                sx={{ marginLeft: 1 }}
                onClick={handleSubmit}
              >
                save changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Snackbar for alerts */}
      {snackbarMessage && <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>}
    </div>
  );
};

export default EditProcess;
