import { Outlet } from "react-router-dom";

const Root = () => {

  // const navigate = useNavigate();

  // useEffect(() => {
  //   onAppDidMount(navigate);
  // }, [navigate]);

  return (
    <Outlet />
  );
}
export default Root;
