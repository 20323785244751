import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Alert,
  Snackbar,
  AlertTitle,
  CircularProgress
} from "@mui/material";
import "./RunNewProcessLog.css";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from "@mui/icons-material/Add";
// import RunNewProcessLogRevModelPredictions from "./RunNewProcessLogRevModelPredictions";
import RunNewProcessLogBrandDataAPI from "./RunNewProcessLogBrandDataAPI";
import RunNewProcessLogDBMigration from "./RunNewProcessLogDBMigration";
import { useEffect, useState } from "react";
import { useHasETLProcessListPermission } from "../../../../../logic/Admin/Permissions/useHasETLProcessListPermission";
import { ModulesPermissionNames } from "../../../../../data/menu/ModulesPermissions";
import { ETLProcessListEndPointLogic } from "../../../../../logic/Admin/ETL Process List/ETLProcessListEndPointLogic";
import { trackPromise } from "react-promise-tracker";
import { ETLLogsEndPointLogic } from "../../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";
import SimpleBackdrop from "../../../../BackDrop/BackDrop";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#fff",
  padding: "20px 24px",
  borderRadius: "4px",
  boxShadow: "0px 0px 16px #50505085",
  zIndex: "999",
  maxHeight: "80vh",
  overflowY: "auto",
  overflowX: "hidden",
};

const modalHeadin = {
  fontSize: "20px",
  fontWeight: "500",
  marginBottom: "16px",
};

type RunNewProcessProps = {
  openModal: boolean;
  onCloseModal: any;
  token: string;
  setRefresh:any;
  websiteSharedUid:any
};

const RunNewProcessLog = (props: RunNewProcessProps) => {
  const hasGetPermissions = useHasETLProcessListPermission([
    ModulesPermissionNames.GET_API_PROCESSES,
    ModulesPermissionNames.GET_API_PROCESS_TOGGLE,
  ]);

  const { openModal, onCloseModal, token ,setRefresh ,websiteSharedUid } = props;
  const [processes, setProcesses] = useState([]) as any;
  const [autocompleteCount, setAutocompleteCount] = useState([1]);
  const [selectedProcesses, setSelectedProcesses] = useState<string[]>([""]); // State to manage selected values
  const [selectedProcessIds, setSelectedProcessIds] = useState([]) as any; // Store selected process IDs
  const [alert,setAlert] = useState("");
  const [runStatus,setRunStatus] = useState("") as any;
  const [success,setSuccess] = useState("") as any;
  const [succesMsg,setSuccessMsg] = useState("");
  const [loading,setLoading] = useState(false);
  const [snackbarOpen,setSnackbarOpen] = useState(true);
  const [openDBMigration, setOpenDBMigration] = useState(false);
  const [openBrandData, setOpenBrandData] = useState(false);
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<string | null>(null);
  const [structureOnly,setStructureOnly]=useState(false);
 // Determine which IDs to use based on the environment
  const specialProcessIds = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'
  ? [72, 48, 69]
  : [33, 59];
  // Check if selectedProcessIds includes any of the special process IDs
  const hasSpecialProcess = selectedProcessIds.some((id:any) => specialProcessIds.includes(id));

  // Check if selectedProcessIds includes any IDs that are not in specialProcessIds
  const hasNormalProcess = selectedProcessIds.some((id:any) => !specialProcessIds.includes(id));
  const [selectedTable,setSelectedTable] = useState([]) as any;
  const [,setMigrateProcess]=useState([]);
  const [table,setTable] = useState([]) as any;
  const [brandID,setBrandID] = useState([]) as any;
  const [fromDate,setFromDate] = useState("");
  const [toDate,setToDate] = useState("");
  const [gaming,setGaming] = useState(false);
  const [bigRep,setBigRep] = useState(false);
  const [backend,setbackend] = useState(false);
  const [barnds, setBrands] = useState([{ name: "" }]) as any;
  // Set process IDs based on environment
  const dbMigrationProcessId = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? 72 : 59;
  const brandDataProcessId = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? 48 : 33;

  const handleAddAutocomplete = () => {
    const allSelected = selectedProcesses.every((process) => process !== "");
    
    if (!allSelected) {
      setAlert("Please select a value for each process before adding another.");
      return; // Don't add another autocomplete if any are unselected
    }

    setAutocompleteCount([...autocompleteCount, autocompleteCount.length + 1]);
    setSelectedProcesses((prev) => [...prev, ""]); // Add a new empty value
  };

  const handleCancel = () => {
    onCloseModal();
    setAutocompleteCount([1]);
    setSelectedProcesses([""]); // Reset selections
    setOpenDBMigration(false);
    setOpenBrandData(false);
    setSelectedProcessIds([]);
    setSelectedDestination(null);
    setSelectedSource(null);
    setSelectedTable([]);
  };

  const handleProcessChange = (value: string, index: number) => {
    const updatedProcesses = [...selectedProcesses];
    updatedProcesses[index] = value ?? ""; // Update the specific value in the array
  
    // Find the corresponding process ID from the `processes` array
    const processId = processes.find((process:any) => process.name === value)?.id;
  
    if (processId) {
      const updatedProcessIds = [...selectedProcessIds] as any;
      updatedProcessIds[index] = processId; // Update the specific process ID in the array
      setSelectedProcessIds(updatedProcessIds);
    }

    if (processId === dbMigrationProcessId) {
      setOpenDBMigration(true);
    } else {
      setOpenDBMigration(false);
    }
    
    // Open/close Brand Data based on process ID
    if (processId === brandDataProcessId) {
      setOpenBrandData(true);
    } else {
      setOpenBrandData(false);
    }
  
    setSelectedProcesses(updatedProcesses);
  };
  useEffect(() => {
    const fetchETLProcessList = async () => {
      try {
        if (hasGetPermissions) {
          await ETLProcessListEndPointLogic.getETLProcessList(
            token,
            setProcesses,
            trackPromise
          );
        } else {
          throw new Error("User doesn't have permissions to fetch process list data");
        }
      } catch (error) {
        console.error("Error fetching process list:", error);
      }
    };

    if (token) {
      fetchETLProcessList();
    }
    // eslint-disable-next-line
  }, [openModal === true, token, hasGetPermissions]);

  const runProcess = async () => {
    const ids = {"ids": selectedProcessIds};
    try {
      if(selectedProcessIds){
        // store.dispatch(setIsLoading(true));
        setLoading(true);
        await ETLLogsEndPointLogic.runProcess(token,ids,setRunStatus);

        // setSnackbarMessage("Process ran successfully");
      }
      else{
        setRunStatus('User don`t have permission')
      }
    } catch (error) {
      setRunStatus(error);
    }
  }

  const runDBMigrationProcess = async () => {
    const data = {
      "source": selectedSource,
      "destination": selectedDestination,
      "tables": selectedTable,
      "structure_only": structureOnly
    };
    try {
      if(selectedProcessIds){
        // store.dispatch(setIsLoading(true));
        setLoading(true);
        await ETLLogsEndPointLogic.postMigrate(token,data,setMigrateProcess,setSuccess);
      }
      else{
        setRunStatus('User don`t have permission')
      }
    } catch (error) {
      setRunStatus(error);
    }
  }

  const runBrandsDataAPI = async () => {
    // Your existing data template
    const baseData = {
      ma_record: false,
      db_set: process.env.NODE_ENV === "development" ? "stage" : "prod",
      from_date: fromDate,
      to_date: toDate,
      no_gaming: gaming,
      no_biz_rep: bigRep,
      no_backend: backend
    };
    
  
    try {
      if (selectedProcessIds) {
        setLoading(true);
  
        // Loop through each brand ID and make the API call
        for (const id of brandID) {
          const data = {
            ...baseData,  // Spread base data
            brand_id: id  // Set the brand ID for the current iteration
          };
  
          // Call the API for the current brand ID
          await ETLLogsEndPointLogic.postBrands(token, data, setMigrateProcess);
        }
  
        setLoading(false);  // Optionally stop loading after all calls are done
      } else {
        setRunStatus('User doesn’t have permission');
      }
    } catch (error) {
      setRunStatus(error);
      setLoading(false);  // Ensure loading is stopped on error
    }
  };

  const handleRunProcess = async () => {
    if (hasSpecialProcess && hasNormalProcess) {
      setAlert("You cannot run Special and Normal Processes Together.");
      return;
    }
    const allSelected = selectedProcesses.every((process) => process !== "");
    if (!allSelected) {
      setAlert("Please select a value for each process.");
      return;
    }
    // Define restricted process IDs based on the environment
    const restrictedProcessIds = 
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' 
    ? [69, 48, 72] 
    : [33, 59];

    // Check if any selected process IDs match the restricted process IDs
    const hasRestrictedProcessIds = selectedProcessIds.some((id: any) => restrictedProcessIds.includes(id));

    if(!hasRestrictedProcessIds){
      await runProcess().then(()=>{
        onCloseModal();
        setAutocompleteCount([1]);
        setSelectedProcesses([""]);
        setAlert('');
        setLoading(false);
        setRunStatus("success");
        setRefresh(true);
        setSnackbarOpen(true);
      });
    }

      // Check for the DB Migration process
      if (selectedProcessIds.includes(dbMigrationProcessId)) {
        await runDBMigrationProcess().then(() => {
          onCloseModal();
          setAutocompleteCount([1]);
          setSelectedProcesses([""]);
          setAlert('');
          setLoading(false);
          setRefresh(true);
          setSnackbarOpen(true);
          setOpenDBMigration(false);
          setSelectedProcessIds([]);
          setSelectedDestination(null);
          setSelectedSource(null);
          setSelectedTable([]);
        });
      }

      // Check for the Brands Data API process
      if (selectedProcessIds.includes(brandDataProcessId)) {
        await runBrandsDataAPI().then(() => {
          onCloseModal();
          setAutocompleteCount([1]);
          setSelectedProcesses([""]);
          setAlert('');
          setLoading(false);
          setRefresh(true);
          setSnackbarOpen(true);
          setOpenBrandData(false);
          setBrandID([]);
          setBigRep(false);
          setbackend(false);
          setGaming(false);
          setFromDate("");
          setToDate("");
        });
      }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert("");
      }, 2000);

      // Cleanup the timer on component unmount or alert change
      return () => clearTimeout(timer);
    }
  }, [alert]);



  useEffect(()=>{
    const data = websiteSharedUid;
    ETLLogsEndPointLogic.getBrands(token,data,setBrands,setSuccessMsg)
    //eslint-disable-next-line
  },[])

  return (
    <div>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ 
            ...modalStyle, 
            width: '900px',  // Set the modal width to 900px
            maxWidth: '100%',  // Ensure it is responsive on smaller screens
            margin: 'auto',    // Center the modal horizontally
        }}  className="ProcessLogModal">
        {Object.values(alert).length>=1 ?  <Alert severity="warning">{alert}</Alert> : ""}
          {/* Modal Header */}
          <Typography
            sx={modalHeadin}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Run New Process
          </Typography>
          {openDBMigration && hasSpecialProcess && hasNormalProcess && <Alert severity="warning" sx={{marginBottom:5}}>You cannot run special and normal processes together</Alert>}

          {/* Modal Body */}
          <Grid container spacing={3}>
            {autocompleteCount.map((item, index) => {
              // Filter options to exclude selected processes
              const filteredOptions = processes
                ? processes
                    .filter(
                      (process: any) => process.active && !selectedProcesses.includes(process.name)
                    ) // Filter active processes and exclude selected ones
                    .map((process: any) => process.name)
                : [];


              return (
                <Grid item xs={9} key={index}>
                  <Autocomplete
                    id={`processName-${index}`}
                    disablePortal={false}
                    options={filteredOptions} // Use filtered options
                    fullWidth
                    size="medium"
                    value={selectedProcesses[index] || ""}
                    onChange={(event, newValue) => handleProcessChange(newValue as string, index)} // Update selected value
                    renderInput={(params) => (
                      <TextField
                        color="secondary"
                        {...params}
                        required
                        label={`Process Name ${index + 1}`}
                      />
                    )}
                  />
                </Grid>
              );
            })}
            {(!selectedProcessIds.includes(dbMigrationProcessId) && !selectedProcessIds.includes(brandDataProcessId)) && <Grid item xs={3}>
              <Typography
                component="p"
                sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", mb: 0.7 }}
              >
                Want to run more processes?
              </Typography>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                endIcon={<AddIcon />}
                onClick={handleAddAutocomplete}
                disabled={selectedProcessIds.includes(72) || selectedProcessIds.includes(48) || selectedProcessIds.includes(69)}
              >
                Add another
              </Button>
            </Grid>}
            {(selectedProcessIds.includes(dbMigrationProcessId) || selectedProcessIds.includes(brandDataProcessId))  && hasSpecialProcess && !hasNormalProcess && <Grid item xs={3}>
          <Alert
            severity="warning"
            sx={{
              borderRadius: 2,
              px: 1,
              py: 0,
              color: "#ED6C02",
              border: "1px solid #ED6C0280",
            }}
          >
            <AlertTitle sx={{ mb: "2px" }}>
              Additional data required to run this process.
            </AlertTitle>
            Please fill the following fields.
          </Alert>
        </Grid>}
          </Grid>
          {openDBMigration && hasSpecialProcess && !hasNormalProcess && <RunNewProcessLogDBMigration token={token} setSelectedSource={setSelectedSource} selectedSource={selectedSource}
           setSelectedDestination={setSelectedDestination} selectedDestination={selectedDestination}setStructureOnly={setStructureOnly}
           structureOnly={structureOnly} setSelectedTable={setSelectedTable} setTable={setTable} table={table}/>}
          {openBrandData && hasSpecialProcess && !hasNormalProcess ? (
            barnds.length===1 ? (
              <SimpleBackdrop />
            ) : (
              <RunNewProcessLogBrandDataAPI 
                token={token} 
                setSelectedSource={setSelectedSource} 
                selectedSource={selectedSource} 
                setBrandID={setBrandID} 
                brandID={brandID} 
                websiteSharedUid={websiteSharedUid} 
                setFromDate={setFromDate} 
                setToDate={setToDate} 
                setGaming={setGaming} 
                setBigRep={setBigRep} 
                setbackend={setbackend} 
                barnds={barnds}
                setBrands={setBrands}
              />
            )
          ) : null}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Grid
              item
              xs={12}
              justifyContent={"flex-end"}
              sx={{ display: "flex" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {<Button
                variant="contained"
                color="secondary"
                endIcon={<PlayCircleFilledWhiteIcon />}
                sx={{ marginLeft: 1 }}
                onClick={handleRunProcess}
                disabled={
                  (
                    selectedProcessIds.includes(dbMigrationProcessId) &&
                    (!selectedSource || !selectedDestination || !selectedTable || selectedTable.length === 0)
                  ) ||
                  (
                    selectedProcessIds.includes(brandDataProcessId) &&
                    (fromDate === "" || toDate === "" || brandID.length === 0)
                  ) ||
                  selectedProcessIds.includes(69) // Include the additional process ID (69)
                }
              >
                {loading ? <CircularProgress color="inherit" size={25} /> : "Run Process"}
              </Button>}
              
            </Grid>
          </Grid>
        </Box>
      </Modal>
      
      {(runStatus === "success" || success=== "success" || succesMsg ==="success") && <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={"success"} sx={{ width: "100%" }}>
          Run Process Successfully
        </Alert>
      </Snackbar>}  
    </div>
  );
};


export default RunNewProcessLog;
