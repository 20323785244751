import { useEffect, useState } from "react";
import PageScanRequestFilterCard from "./PageScanRequestFilter/PageScanRequestFilterCard"
import PageScanRequestTable from "./PageScanRequestTable/PageScanRequestTable"
import { setIsLoading } from "../../../../../features/userSlice";
import { QuarterlySpeedGoalsEndPointLogic } from "../../../../../logic/AssetManagement/Health/Quarterly Website Speed Goals/QuarterlySpeedGoalsEndPointLogic";
import { useDispatch } from 'react-redux';
import SimpleBackdrop from '../../../../BackDrop/BackDrop';

interface PageScanProps {
  selectedSharedUid:any;
  token:any;
  selectedStatus:any;
  setSelectedStatus:any;
  RequestedBy:any;
  setRequestedBy:any;
}

function PageScanRequest({selectedSharedUid,token,selectedStatus,setSelectedStatus,RequestedBy,setRequestedBy}:PageScanProps) {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [pageInternalListData, setPageInternalListData] = useState<any[]>([]);
  const [, setTogglePageSpeedData] = useState<any[]>([]);
  const [postedPageSpeedContent, setPostedPageSpeedContent] = useState<any[]>([]);
  const [ errorAccToPageStatus,  setErrorAccToPageStatus] = useState([]);
  const [addNewPageReqText, setAddNewPageReqText] = useState("");
  const [loaderForPageSpeed, setLoaderForPageSpeed] = useState(false);
  const [selectedSearchValue, setSelectedSearchValue] = useState(null);
  const dispatch = useDispatch();
  const tableHeadings = {
    "page_name": "Page Name",
    "active": "Scan Status",
    "tests_amount":"Scan Count",
    "last_run": "Last Scan",
    "created":"Requested On",
    "created_by":"Requested By",
  }

    useEffect(() => {
    const fetchPageSpeedData = async (website_shared_uid:any) => {
      setLoading(true)
      try {
        await QuarterlySpeedGoalsEndPointLogic.getPageViewInternalList(token,website_shared_uid,setPageInternalListData,setErrorAccToPageStatus,dispatch);
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      } finally {
        setLoading(false)
        setTogglePageSpeedData([])
        setPostedPageSpeedContent([])
        setErrorAccToPageStatus([])
      }
    };

    fetchPageSpeedData(selectedSharedUid);
  }, [setErrorAccToPageStatus, selectedSharedUid, token, dispatch, setLoading]);

  const postPageSpeed = async (data:any) => {
    setLoaderForPageSpeed(true)
    try {
       if (token) { 
        await QuarterlySpeedGoalsEndPointLogic.postPageSpeedInternal(token,data,selectedSharedUid,setPostedPageSpeedContent,setErrorAccToPageStatus,setIsLoading);
       } else {
        throw new Error("User doesn't have permissions to fetch advertising accounts data");
      }
      if (token) {
        await QuarterlySpeedGoalsEndPointLogic.getPageViewInternalList(token,selectedSharedUid,setPageInternalListData,setErrorAccToPageStatus,dispatch);
      } else {
        throw new Error("Permission Denied");
      } 
    } catch (error) {
      console.error("Error fetching advertising accounts:", error);
    } finally {
      setLoaderForPageSpeed(false)
    }
  };

  return (
    <>
    <PageScanRequestFilterCard selectedSearchValue={selectedSearchValue} setSelectedSearchValue={setSelectedSearchValue} rows={pageInternalListData ? pageInternalListData : []} loaderForPageSpeed={loaderForPageSpeed} setErrorAccToPageStatus={setErrorAccToPageStatus} postedPageSpeedContent={postedPageSpeedContent}  errorAccToPageStatus={errorAccToPageStatus} postPageSpeed={postPageSpeed} addNewPageReqText={addNewPageReqText} setAddNewPageReqText={setAddNewPageReqText} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} RequestedBy={RequestedBy} setRequestedBy={setRequestedBy}/>
    {loading ? (
        <SimpleBackdrop />
      ) : (
        <>
           <PageScanRequestTable oauthTokenHeader={token} selectedSharedUid={selectedSharedUid} setPageInternalListData={setPageInternalListData} selectedSearchValue={selectedSearchValue} tableHeadings={tableHeadings} rows={pageInternalListData ? pageInternalListData : []} loading={loading} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} RequestedBy={RequestedBy} setRequestedBy={setRequestedBy}/>
        </>
      )}
    </>
  )
}

export default PageScanRequest
