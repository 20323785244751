/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Box, Switch, Button, Typography, Alert, Snackbar } from '@mui/material';
import BatchProcessManagement from './BatchProcessManagement';
import SimpleBackdrop from '../../../../BackDrop/BackDrop';
import FailedProcesses from './FailedProcesses';
// import SequenceStatus from './SequenceStatus';
import { ETLSequencesEndPointLogic } from '../../../../../logic/Admin/ETLSequences/ETLSequencesEndPointLogic';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../../../features/userSlice';

interface Props {
  etlSequence: any;
  setEtlSequence: any;
  hasGetPermissions: any;
  hasEditPermissions: any;
  oauthTokenHeader: string;
  expandedRow: any;
  trackPromise: any;
  onChainStatusChange: any;
  selectedChain: any;
  data: any;
  rowId: any;
}

const SequenceTableAdditionalContent = ({
  etlSequence,
  hasGetPermissions,
  hasEditPermissions,
  setEtlSequence,
  oauthTokenHeader,
  expandedRow,
  selectedChain,
  trackPromise,
  onChainStatusChange,
  data,
  rowId,
 }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [etlChainContent, setETLChainContent] = useState({});
  const [fetchDataCalled, setFetchDataCalled] = useState(false);
  const isSwitchChecked = false;
  const chainUid = data; // Use data prop instead of etlChains?.chains[expandedRow]
  const selectedChainData = useMemo(() => {
    return data; // Use data prop instead of etlChains?.chains[expandedRow || 0]
  }, [data]);

  selectedChain = useMemo(() => {
    return etlSequence?.data[expandedRow || 0];
  },[etlSequence, expandedRow]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const updatedChainStatus: boolean = Object.values(etlChainContent)[1] as boolean;
  const [updatedPayload, setUpdatedPayload] = useState({ processes: [] } as { processes: any[] });
  const originalProcesses = [...updatedPayload.processes?.map((process) => process.process__id)];
  const [changesSaved, setChangesSaved] = useState(false); // To check if changes are saved

  const handleProcessChange = () => {
    setChangesSaved(false);
  };

  const handleChainStatusUpdate = useCallback(() => {
    onChainStatusChange(updatedChainStatus);
  }, [onChainStatusChange, updatedChainStatus]);

  useEffect(() => {
    handleChainStatusUpdate();
  }, [handleChainStatusUpdate,updatedChainStatus]);

  const handleSwitchChange = async (rowId:any) => {
    setLoading(true);
    let data = '';
    try{
      await ETLSequencesEndPointLogic.etlSequenceToggle(oauthTokenHeader,setETLChainContent,data,rowId);
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence status updated successfully. Please make sure to Deploy & Sync the Sequence Configurations.');
      if (hasGetPermissions) {
        setLoading(true);
        await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading, setEtlSequence, dispatch);
      } else {
        throw new Error("User doesn't have permissions to fetch ETL Sequence data");
      }
    }catch (error){
      setSnackbarOpen(true);
      setSnackbarMessage('Error while toggling sequence status');
      console.error("Error while toggling sequence status:", error);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage('Sequence status updated successfully. Please make sure to Deploy & Sync the Sequence Configurations.');
    }
  };

  useEffect(() => {
    if (!loading && fetchDataCalled) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Sequence status updated successfully. Please make sure to Deploy & Sync the Sequence Configurations.`);
    }
  }, [loading, fetchDataCalled]);

  const processlist = selectedChain?.processes || [];
  const failedProcesses = selectedChain?.failed_processes || [];


  const handleSaveChanges = async () => {
    try {
      if (hasEditPermissions) {
        const data = {
          "processes": updatedPayload?.processes || [],  // Sequence's Processes
        };
        setLoading(true);
        await ETLSequencesEndPointLogic.editETLSequence(Object.values(chainUid)[0], oauthTokenHeader, data);
        setSnackbarOpen(true);
        setSnackbarMessage(`ETL Sequence updated successfully. Please make sure to Deploy & Sync the Sequence Configurations`);
        setChangesSaved(true);
        if (hasGetPermissions) {
          setLoading(true);
          await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading, setEtlSequence, dispatch);
        } else {
          throw new Error("User doesn't have permissions to fetch ETL Sequence data");
        }
      } else {
        throw new Error("User doesn't have permissions to edit ETL Sequence");
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error updating sequence');
      console.error("Error while saving changes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRevertChanges = async () => {
    try {
      if(hasEditPermissions) {
        const data = {
          "processes": processlist?.map((process: any) => process.process__id),
        }
        setLoading(true);
        await ETLSequencesEndPointLogic.editETLSequence(Object.values(chainUid)[0], oauthTokenHeader, data);
        setSnackbarOpen(true);
        setSnackbarMessage('The Changes have been reverted successfully to original state. Please make sure to Deploy & Sync the Sequence Configurations');
        setChangesSaved(true);
        if (hasGetPermissions) {
          setLoading(true);
          await ETLSequencesEndPointLogic.getETLSequenceContent(oauthTokenHeader,setIsLoading, setEtlSequence, dispatch);
        } else {
          throw new Error("User doesn't have permissions to fetch ETL Sequence data");
        }
      } else {
        throw new Error("User doesn't have permissions to edit ETL Sequence");
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error reverting changes');
      console.error("Error while reverting changes:", error);
    } finally {
      setLoading(false);
    }
  }

  const renderSnackbar = () => {
    return (
      <Snackbar
        sx={{zIndex: "9999999" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity="success"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <>
    {renderSnackbar()}
    {loading ? (
      <SimpleBackdrop />
    ) : (
    <Grid container spacing={2}>
      {errorAlert && (
      <Grid item xs={12}>
        <Alert severity="error" sx={{ marginBottom: '16px' }}>
          {errorAlert}
        </Alert>
      </Grid>
      )}
      <Grid item xs={4} sm={5} sx={{ px: 2 }}>
        <Typography variant='body1' mb={1}>Batch Process Management</Typography>
        <BatchProcessManagement
          processlist={processlist}
          onOrderChange={(updatedProcesses) => {
            setUpdatedPayload({
              processes: updatedProcesses?.map((process: any) => process.process__id),
            });
            handleProcessChange();
          }}
        />
      </Grid>
      <Grid item xs={6} sm={4} sx={{ px: 2 }}>
        <Typography mb={1} variant='body1'>Failed Processes</Typography>
        <FailedProcesses failedProcesses={failedProcesses} />
      </Grid>
      <Grid item xs={3} sm={3} sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant='body1' sx={{ mr : "70px" }}>Sequence Status</Typography>
          <Switch color='success' checked={selectedChain?.active === false ? isSwitchChecked : !isSwitchChecked} onChange={() => handleSwitchChange(selectedChain?.id)} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, gap:1}}>
          <Button size="small" color='secondary' variant="outlined" disabled={changesSaved || originalProcesses.toString() === updatedPayload.processes.toString()} sx={{ fontSize: '13px', whiteSpace:"nowrap" }} onClick={handleRevertChanges}>
            REVERT Changes 
          </Button>
          <Button size="small" color='secondary' variant="contained" disabled={changesSaved || originalProcesses.toString() === updatedPayload.processes.toString()} sx={{ fontSize: '13px' ,whiteSpace:"nowrap" }} onClick={handleSaveChanges}>
            SAVE Changes
          </Button>
        </Box>
      </Grid>
    </Grid>
    )}
  </>
  );
};

export default SequenceTableAdditionalContent;
