import React, { useEffect, useState } from 'react'
import ClickDataFilter from './ClickDataFilter/ClickDataFilter'
import ClickDataTable from './ClickDataTable/ClickDataTable'
import { Divider } from '@mui/material'
import { engagementIncentivesEndPointLogic } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/engagementIncentivesEndPointLogic'
import SimpleBackdrop from '../../../BackDrop/BackDrop'
import { useHasEngagementIncentivesPermission } from '../../../../logic/PaidTrafficManagement/EngagementIncentives/Permissions/useHasEngagementIncentivesPermission'
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions'

const tableHeadings = {
  "date_of_acquisition": "Date of Aquisition",
  "brand_name": "Brand Name",
  "campaign_id":"Campaign ID",
  "incentivezed_click_id": "Incentive Click ID",
  "optival_click_id":"Optival Click ID",
  "registrations":"Registrations",
  "ftd":"FTD",
  "qualified_ftd":"Qualified FTD",
  "deposit_amount":"Deposit Amount",
  "abs_net_revenue":"Abs Net Revenue",
}


interface ClickDataProps{
  selectedSiteUid:any;
  userToken:string;
}

const ClickData = ({selectedSiteUid,userToken}:ClickDataProps) => {
  const [exequteQueryContentQuery5,setExequteQueryContentQuery5] = useState([]) as any;
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(true);
  const [display, setDisplay] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [brandName,setBrandName] = useState([]);
  const competitorlisting = exequteQueryContentQuery5?.query_output?.map((brandNames: any) => brandNames?.brand_name).filter(Boolean) || [];
  const QUERY_ID_5 = 4825967910453248;

  const hasGetPermissions = useHasEngagementIncentivesPermission([
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERIES_LIST,
    ModulesPermissionNames.GET_API_BIZ_LAYER_QUERY,
    ModulesPermissionNames.GET_API_RUN_BIZ_LAYER_QUERY,
  ]);

  useEffect(() => {
    const fetchExequteQueryContent = async () => {
      if (startDate && endDate && show === true && hasGetPermissions) {
        setLoading(true);
        try {
          // Await the API call to ensure it completes before moving on
          await engagementIncentivesEndPointLogic.getExequteQuery(
            userToken, 
            setExequteQueryContentQuery5, 
            selectedSiteUid, 
            startDate, 
            endDate, 
            QUERY_ID_5
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          // This ensures loading is set to false after the API call, regardless of success or failure
          setLoading(false);
          setShow(false);
        }
      }
    };
  
    fetchExequteQueryContent();
    // eslint-disable-next-line
  }, [startDate, endDate, show]);
  return (
    <>
      <ClickDataFilter
       startDate={startDate} 
       setStartDate={setStartDate} 
       endDate={endDate} 
       setEndDate={setEndDate}
       setShow={setShow}
       setDisplay={setDisplay}
       competitorlisting={competitorlisting}
       setBrandName={setBrandName}
        />
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <>
          {exequteQueryContentQuery5 && display && (
            <>
              <Divider />
              <ClickDataTable tableHeadings={tableHeadings} exequteQueryContentQuery5={exequteQueryContentQuery5}
            brandName={brandName} />
            </>
          )}
        </>
      )}
    </>
  );
  
  
 
}

export default ClickData;