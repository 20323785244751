import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { MuiColorInput } from "mui-color-input";
import { useState } from "react";
import Grid from "@mui/material/Grid";

function DefaultAppearance() {
  const websitelisting = [
    { label: "websiteexample1.com" },
    { label: "websiteexample2.com" },
    { label: "websiteexample3.com" },
    { label: "websiteexample4.com" },
    { label: "websiteexample5.com" },
  ];

  const [value, setValue] = useState("");

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography>Default appearance</Typography>
      <Box mt={1} mb={2}>
        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Theme"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Popup"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              )}
              size="small"
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={6}>
            <MuiColorInput
              format="hex"
              value={value}
              onChange={handleChange}
              size="small"
              color="secondary"
              label="CTA Button color"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              required
              id="outlined-required"
              label="CTA Button text"
              InputLabelProps={{
                shrink: true,
              }}
              color="secondary"
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={11}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Desktop Banner Image"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton aria-label="delete">
              <FileUploadOutlinedIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={11}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tablet Banner Image"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton aria-label="delete">
              <FileUploadOutlinedIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item xs={12} md={11}>
            <Autocomplete
              fullWidth
              options={websitelisting.map((websitename) => websitename.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mobile Banner Image"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton aria-label="delete">
              <FileUploadOutlinedIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} mt={2}>
          <Grid item>
            <Typography
              sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
            >
              All fields are mandatory.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DefaultAppearance;
