import { endpointsService } from './../../../../services/endPoints.services';
import { setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import store from '../../../../store';

async function getDesignTheme(oauthTokenHeader: string, website_shared_uid: string,setData:any,trackPromise:any) {
  try {
    store.dispatch(setIsLoading(true));
    const response = await endpointsService.getAllThemes(oauthTokenHeader, website_shared_uid);
    if (!response || !response.status || response.status !== 200) {
       trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Quarterly goals', type: 'error' })));
    } else {
      setData(response?.data?.data);
    }
    } catch (e:any) {
      trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
  }
};

async function createDesignTheme(oauthTokenHeader:any, data:any) {
  try {
    const response = await endpointsService.createTheme(oauthTokenHeader, data);
    if (!response.status || response.status !== 201) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating a new theme', type: 'error' }));
    }
    else {
      // store.dispatch(setDisplayErrorSnackBar({ message: 'Template Have Been Created Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
};

async function getWidgetStructures(oauthTokenHeader: string, setWidgetStructures:any) {
  try {
    store.dispatch(setIsLoading(true));
    const response = await endpointsService.getWidgetStructure(oauthTokenHeader);
    if (!response || !response.status || response.status !== 200) {
      throw new Error('Failed to get page content');
    }
    setWidgetStructures(response?.data?.data); // Set the fetched data
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function getSingleDesignTheme(oauthTokenHeader: string, themeUid: string,setDesignThemeData:any,setError:any) {
  try {
    const response = await endpointsService.getSingleTheme(oauthTokenHeader, themeUid);
    if (!response.status || response.status !== 200) {
       /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Quarterly goals', type: 'error' }))); */
    } else {
      setDesignThemeData(response?.data?.data);
    }
    } catch (e:any) {
    setError(e);
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function editDesignTheme(oauthTokenHeader:any, data:any, themeUid:any) {
  try {
    const response = await endpointsService.editTheme(oauthTokenHeader, data, themeUid);
    if (!response.status || response.status !== 200) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Editing a theme', type: 'error' }));
    }
    else {
      // store.dispatch(setDisplayErrorSnackBar({ message: 'Template Have Been Created Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

const requestANewTheme = async (oauthTokenHeader: any,setRequest:any, data: any) => {
  try {
    const response = await endpointsService.requestTheme(oauthTokenHeader, data);
    if (!response.status || response.status !== 201) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating a new theme', type: 'error' }));
    }
    else {
      setRequest(response?.data);
      store.dispatch(setDisplayErrorSnackBar({ message: 'Template Request Sent Successfully', type: 'success' }));
    }
  } catch (e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
};

async function fileUploadME(oauthTokenHeader: string, data:any, object_shared_uid:string) {
  try {
      store.dispatch(setIsLoading(true));
      const response = await endpointsService.fileUploadME(oauthTokenHeader,data,object_shared_uid);
      if (!response || !response.status || response.status !== 201) {
          throw new Error('Failed to upload file');
      }
  } catch (e:any) {
      store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))
  }
}

async function getFiles(oauthTokenHeader: string, object_shared_uid:string,setUploadedFiles:any) {
  try {
      store.dispatch(setIsLoading(true));
      const response = await endpointsService.getFiles(oauthTokenHeader,object_shared_uid);
      if (!response || !response.status || response.status !== 200) {
          throw new Error('Failed to get files');
      }
      else {
          setUploadedFiles(response?.data?.data)
      }
  } catch (e:any) {
      // if(e?.status !== undefined){
      //     store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText  || 'Unknown error occurred', type: 'error' }));
      // }else {
      //     store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
      // }
  }
}

export const DesignThemeEndPointLogic =  {
  getDesignTheme,
  createDesignTheme,
  getWidgetStructures,
  getSingleDesignTheme,
  editDesignTheme,
  requestANewTheme,
  fileUploadME,
  getFiles
}
