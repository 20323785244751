import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./ProcessListTable.css";
import {
  Pagination,
  Stack,
  TablePagination,
  TableSortLabel,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SnackbarContent,
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import EditProcess from "../Modal/EditProcess";
import { ETLProcessListEndPointLogic } from "../../../../../logic/Admin/ETL Process List/ETLProcessListEndPointLogic";
import store from "../../../../../store";
import { setIsLoading ,selectUserToken } from "../../../../../features/userSlice";
import { trackPromise } from "react-promise-tracker";
import { Box} from "@mui/system";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { ETLLogsEndPointLogic } from "../../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic";
import { useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";

interface Props {
  tableHeadings: { [key: string]: string };
  ETLProcessContent: any[];
  setSelectedRow:any;
  selectedRow:any;
  selectedProcess:any;
  status:any;
  oauthTokenHeader:string;
  setReload:any;
  setIsLoad:any;
  page:number;
  setPage:any;
}

export default function ProcessListTable({ tableHeadings, ETLProcessContent,setSelectedRow,selectedRow ,selectedProcess,status,oauthTokenHeader,setReload,setIsLoad,
  page,setPage
}: Props) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const headCells = Object.entries(tableHeadings);
  const [editOpenModal, setEditOpenModal] = useState(false);
  const [, setEditETLProcess] = useState("") ;
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State for Snackbar message
  const previousPageRef = React.useRef(page);
  const [open, setOpen] = React.useState(false);
  const [, setSelectedIndex] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState<string | null>(null); // To track the column to sort by
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // To track the sort order
  const [loading,setLoading] = useState(false);
  const [,setRunStatus] = useState("") as any;
  const [loadingRowId, setLoadingRowId] = useState(null); // Track which row is loading
  const token = useSelector(selectUserToken) as any;
  const navigate = useNavigate();


  React.useEffect(() => {
    setPage(previousPageRef.current); // Restore the previous page after reload
  }, [ETLProcessContent, setPage]);

  const toggleETLProcess = async (id: any) => {
    store.dispatch(setIsLoading(true));
    setIsLoad(true);
    let data = ''
    try {
      // Call the toggle API
      await ETLProcessListEndPointLogic.toggleETLProcess(
        oauthTokenHeader,
        setEditETLProcess,
        trackPromise,
        data,
        id
      );
      //  reload data after the toggle
      // setReload((prev:any) => !prev);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Process toggled successfully!");
      
      console.error("Error toggling ETL process:", error);
    } finally {
      setSnackbarOpen(true);
      setSnackbarMessage("Process toggled successfully!");
      
      // store.dispatch(setIsLoading(false));
      // setIsLoad(false)
      setReload((prev:any) => !prev);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredData = ETLProcessContent.filter(process => {
    const isActive = status?.[0] === "true"; // Convert status to a boolean
    return (
      (!selectedProcess || process?.name === selectedProcess) &&
      (status === null || (isActive && process?.active === true) || (!isActive && process?.active === false))
    );
  });
  

  const editHandleClose = () => {
    setEditOpenModal(false);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortBy(property);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortBy === null) return 0; // No sorting applied

    const aValue = a[sortBy];
    const bValue = b[sortBy];

    const aStatus = sortBy === 'status' ? a.active : aValue;
    const bStatus = sortBy === 'status' ? b.active : bValue;

    const aLastRun = sortBy === 'last_run' ? new Date(a.last_run) : aValue;
    const bLastRun = sortBy === 'last_run' ? new Date(b.last_run) : bValue;

    if (aStatus < bStatus) return sortOrder === 'asc' ? -1 : 1;
    if (aStatus > bStatus) return sortOrder === 'asc' ? 1 : -1;

    if (aLastRun < bLastRun) return sortOrder === 'asc' ? -1 : 1;
    if (aLastRun > bLastRun) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const runProcess = async (row:any) => {
    const ids = {"ids": [row?.id]};
    try {
      if(ids){
        setLoading(true);
        await ETLLogsEndPointLogic.runProcess(token,ids,setRunStatus);
      }
      else{
        setLoading(false);
        setRunStatus('User don`t have permission')
      }
    } catch (error) {
      setLoading(false);
      setRunStatus(error);
    }
  }

  const handleRunProcess = async (row:any) => {
    setLoadingRowId(row.id);
    await runProcess(row).then(()=>{
      setRunStatus("success")
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Process Ran Ruccessfully");
    });
    setTimeout(() => {
      navigate("/admin/processes/etl-process-log");
    },1000);
  };


  return (
    <>
    <TableContainer
      className="processListTableWrap"
      component={Paper}
      sx={{ maxHeight: 800 }}
    >
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map(([key, value], index) => (
              <TableCell key={index} align="left">
                <TableSortLabel
          active={sortBy === key}
          direction={sortBy === key ? sortOrder : 'asc'}
          onClick={() => handleRequestSort(key)}
        >
          {value && typeof value === "string" ? value : ""}
        </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody> 
          {sortedData.length === 0 ? (
        <TableRow>
          <TableCell colSpan={headCells.length} align="center">
            No data available to show
          </TableCell>
        </TableRow>
      ) : sortedData
            .filter( process=> (!selectedProcess || process?.name === selectedProcess))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <React.Fragment key={index}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  {headCells.map(([key], idx) => (
                    <TableCell key={idx} align="left" style={{paddingTop:"3px",paddingBottom:"3px"}}>
                      {key === "id" ? (
                        <>
                          {row[key]}
                        </>
                      ) :key === "edit" ? (
                        <IconButton disabled>
                        </IconButton>
                      ) : key === "status" ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <Alert 
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "86px",
                            height: "26px",
                            padding: "3px",
                            border: "1px solid",
                            borderColor: row?.active === true ? "#2E7D3280" : "#D32F2F80", // Change border color
                            backgroundColor: row?.active === true ? "#EDF7ED" : "#FDEDED", // Change background color
                            color: row?.active === true ? "#1E4620" : "#5F2120", // Change text color
                          }} 
                          icon={row?.active === true ? <CheckCircleOutlinedIcon fontSize="inherit" sx={{ color: "#1E4620", width:"13px", height:"13px" }} /> : <CancelOutlinedIcon fontSize="inherit" sx={{ color: "#5F2120",width:"13px", height:"13px" }}/>}>
                          {row?.active === true ? "Enabled" : "Disabled"}
                          </Alert>
                          <PowerSettingsNewOutlinedIcon 
                            color="secondary" 
                            sx={{ cursor: "pointer", marginLeft:"14.5px" }} 
                            onClick={() => {
                              setSelectedRow(row);
                              setSelectedIndex(index);
                              handleOpenDialog();
                            }}
                          />
                          </Box>
                      ) : key === "last_run" ? (
                        row?.last_run ? new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }).format(new Date(row.last_run)) : ""
                      ) : key === "run_process" ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ width: "200px" }}
                          endIcon={<PlayCircleFilledIcon />}
                          onClick={() => handleRunProcess(row)}
                          disabled={
                            (loadingRowId === row.id && loading) || 
                            ( row.active === false) || 
                            ((process.env.NODE_ENV === "development" && [72, 48, 69].includes(row.id)) ||
                             (process.env.REACT_APP_ENV === "staging" && [72, 48, 69].includes(row.id)) ||
                             (process.env.REACT_APP_ENV === "production" && [59, 33].includes(row.id))) // Disable for specific IDs in production
                          }                          
                        >
                          {loadingRowId === row.id && loading ? (
                            <CircularProgress size={30} color="secondary" />
                          ) : (
                            "Run Process Now"
                          )}
                        </Button>
                      ) : row[key]}
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
        <Dialog open={open} onClose={handleCloseDialog} PaperProps={{ sx: { width: "900px", maxWidth: "900px", padding:"0px 16px 12px 0px"}}}>
          <DialogTitle>Change Process Status</DialogTitle>
          <DialogContent>
          {selectedRow?.active === true ? "Are you sure you want to disable the ETL process?" : "Are you sure you want to Enable the ETL process?"}
          <SnackbarContent
            sx={{
              background: "#FDEDED",
              color: "#5F2120",
              marginTop: "16px",
              boxShadow: "none",
              border: "1px solid #D32F2F80",
              padding:"0 6px"
            }}
            message={
              <span style={{ display: "flex"}}>
                <ErrorOutlineIcon sx={{ marginRight: '8px', color: "#5F2120"}} />
              <span>
              <span style={{ fontWeight: "600"}}>
              {selectedRow?.active === true ? "Disabling the ETL process may result in outdated data in your reports and dashboards" : "Enabling the ETL process will start the flow of data updates."}
              </span>
              <br />
              <span>
              {selectedRow?.active === true ? "This action is irreversible until the process is manually re-enabled." : "This action is irreversible until the process is manually disabled."}
              </span>
            </span>
            </span>
            }
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
            <Button onClick={() => toggleETLProcess(selectedRow?.id)} variant="contained" color="secondary">{selectedRow?.active === true ? "Disabled" : "Enabled"} ETL Process</Button>
          </DialogActions>
        </Dialog>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filteredData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select,.MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-toolbar": {
              paddingLeft: "16px",
            },
          }}
        />
        <Pagination
          count={Math.ceil(filteredData.length / rowsPerPage)}
          onChange={(event, value) => handleChangePage(event, value - 1)}
          page={page+1}
          showFirstButton
          showLastButton
          className="pagination-button"
          color="secondary"
          size="small"
          sx={{
            ".css-wjh20t-MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            displayedRows: 'hidden-displayed-rows', // Apply the custom class
          }}
          sx={{
            ".MuiTablePagination-actions": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              marginRight: "15px",
            },
          }}
        />
      </Stack>
      <EditProcess editOpenModal={editOpenModal} editOnCloseModal={editHandleClose} selectedRow={selectedRow} setSelectedRow={setSelectedRow} oauthTokenHeader={oauthTokenHeader} setReload={setReload} />
    </TableContainer>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
