import { useEffect, useState } from 'react'
import LogResponse from '../../../../components/Admin/Processes/ETL-Process-Log/LogTable/LogResponse'
import { useLocation } from 'react-router-dom';
import store from '../../../../store';
import { setIsLoading } from '../../../../features/userSlice';
import { ETLLogsEndPointLogic } from '../../../../logic/Admin/ETLChains/ETLLogsEndPointLogic';
import { useHasETLLogsPermission } from '../../../../logic/Admin/Permissions/useHasETLLogsPermission';
import { ModulesPermissionNames } from '../../../../data/menu/ModulesPermissions';
import SimpleBackdrop from '../../../../components/BackDrop/BackDrop';

export default function ETLLogLogResponseScreen(userToken:any) {

  const location = useLocation();
  const { runId } = location.state || {}; // Retrieve passed state
  const [error,setError] = useState('') as any;
  const [loading,setLoading] = useState(false);
  const token = userToken?.userToken;
  const [singleLog,setSingleLog] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const hasEditPermissions = useHasETLLogsPermission([
    ModulesPermissionNames.POST_API_LOGS,
    ModulesPermissionNames.PUT_API_LOGS,
  ]);

  useEffect(()=>{
    const fetchSingleLogs = async () => {
      try {
        if(hasEditPermissions && runId){
          store.dispatch(setIsLoading(true))
          setLoading(true);
          await ETLLogsEndPointLogic.getLogsById(token,runId,setSingleLog);
        }
        else{
          setError('User don`t have permission')
        }
      } catch (error) {
        setError('Something went wrong while getting pages')
      } finally {
        store.dispatch(setIsLoading(false))
        setLoading(false);
        setRefresh(false);
      }
    }
    if(userToken && runId){
      fetchSingleLogs();
    }
  },[token,hasEditPermissions,userToken,runId,refresh]);

  return (
    <div>
      {loading ? <SimpleBackdrop/> : <LogResponse singleLog={singleLog} error={error} setRefresh={setRefresh} token={token}/>}
    </div>
  )
}
