import { Autocomplete, Button, Grid, Paper, SnackbarContent, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, CircularProgress } from "@mui/material"
import { Box,Stack } from "@mui/system"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useContext, useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import SharedStateContext from '../../../../SharedStateProvider';
import { DesignThemeEndPointLogic } from '../../../../../logic/AssetManagement/Design/Theme/DesignThemeEndPointLogic';
import { setIsLoading } from '../../../../../features/userSlice';
import SimpleBackdrop from '../../../../BackDrop/BackDrop';

const EditTheme = () => {
  const navigate = useNavigate();  // Initialize the useNavigate hook
  const [singlePageContent, setSinglePageContent] = useState([]) as any; // State for single page content
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [, setError] = useState("");
  const [, setDesignThemesData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const [themeName, setThemeName] = useState('');
  const [dictionary, setDictionary] = useState('');
  const [pageStructure, setPageStructure] = useState<string | null>(null);
  const [comparisonTable, setComparisonTable] = useState<string | null>(null); // State for page structure templates
  const [popupOffer, setPopupOffer] = useState<string | null>(null); // State for page structure templates
  const [brandinfo, setBrandInfo] = useState<string | null>(null); // State for page structure templates
  const [reviewScreenshots, setReviewScreenshots] = useState<string | null>(null); // State for page structure templates
  const [reviewBlock, setReviewBlock] = useState<string | null>(null); // State for page structure templates
  const [stickyCTA, setStickyCTA] = useState<string | null>(null); // State for page structure templates
  const [affiliateCoupon, setAffiliateCoupon] = useState<string | null>(null); // State for page structure templates
  const [brandAdvantages, setBrandAdvantages] = useState<string | null>(null); // State for page structure templates
  const [brandMetrics, setBrandMetrics] = useState<string | null>(null); // State for page structure templates
  const [textTiles, setTextTiles] = useState<string | null>(null); // State for page structure templates
  const [reviewBanner, setReviewBanner] = useState<string | null>(null); // State for page structure templates
  const [offerButtons, setOfferButtons] = useState<string | null>(null); // State for page structure templates
  const [meetTheTeam, setMeetTheTeam] = useState<string | null>(null); // State for page structure templates
  const [siteOwner, setSiteOwner] = useState<string | null>(null); // State for page structure templates
  const [paymentMethods, setPaymentMethods] = useState<string | null>(null); // State for page structure templates
  const [prosandCons, setProsandCons] = useState<string | null>(null); // State for page structure templates
  const [faq, setFaq] = useState<string | null>(null); // State for page structure templates
  const [carouselCards, setCarouselCards] = useState<string | null>(null); // State for page structure templates
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false); // State for error message visibility
  const location = useLocation(); // Initialize the useLocation hook
  const { designTemplatesData,designThemeData,selectedSharedUid, combinedOptions, oauthTokenHeader, selectedThemeShareuid } = location.state || {};
  const {handleCreateClose} = useContext(SharedStateContext);

  useEffect(() => {
    const fetchSingleTheme = async () => {
      setLoading(true);
      try {
        if (selectedThemeShareuid !== null && selectedThemeShareuid) {
          await DesignThemeEndPointLogic.getSingleDesignTheme(oauthTokenHeader, selectedThemeShareuid, setSinglePageContent, setError);
        } else  {
          throw new Error('Unauthorized access for theme');
        }
      } catch (error) {
        console.error(error, "error");
      } finally {
        setLoading(false);
      }
    };
    if (selectedThemeShareuid !== null && selectedThemeShareuid !== "") {
      fetchSingleTheme();
    }
  }, [oauthTokenHeader, selectedThemeShareuid]);

  const pageStructureTemplates = designTemplatesData
  .filter((template:any) => template.component === "Page Structure") // Filter for "Page Structure"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const comparisonTableTemplates = designTemplatesData
  .filter((template:any) => template.component === "Comparison Table") // Filter for "Comparison Table"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const popupOfferTemplates = designTemplatesData
  .filter((template:any) => template.component === "Popup Offer") // Filter for "Popup Offer"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  })); 
  
  const brandInfoTemplates = designTemplatesData
  .filter((template:any) => template.component === "Brand Info") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const reviewScreenshotsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Review Screenshots") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const reviewBlockTemplates = designTemplatesData
  .filter((template:any) => template.component === "Review Block") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const stickyCTATemplates = designTemplatesData
  .filter((template:any) => template.component === "Sticky CTA") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const affiliateCouponTemplates = designTemplatesData
  .filter((template:any) => template.component === "Affiliate Coupon") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const brandAdvantagesTemplates = designTemplatesData
  .filter((template:any) => template.component === "Brand Advantages") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const brandMetricsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Brand Metrics") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const textTilesTemplates = designTemplatesData
  .filter((template:any) => template.component === "Text Tiles") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const reviewBannerTemplates = designTemplatesData
  .filter((template:any) => template.component === "Review Banner") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const offerButtonsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Offer Buttons") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const meetTheTeamTemplates = designTemplatesData
  .filter((template:any) => template.component === "Meet The Team") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));
  
  const siteOwnerTemplates = designTemplatesData
  .filter((template:any) => template.component === "Site Owner") // Filter for "Animation"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const paymentMethodsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Payment Methods") // Filter for "Payment Methods"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const prosandConsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Brand Pros and Cons") // Filter for "Pros & Cons"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const faqTemplates = designTemplatesData
  .filter((template:any) => template.component === "FAQ") // Filter for "FAQ"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  const carouselCardsTemplates = designTemplatesData
  .filter((template:any) => template.component === "Carousel Cards") // Filter for "Cards"
  .map((template:any) => ({
    value: template.shared_uid, // Use shared_uid as the value
    label: template.name // Use name as the label
  }));

  useEffect(() => {
    if(singlePageContent) {
      setThemeName(singlePageContent?.name);
      let value = singlePageContent?.dictionary;
      value = value?.replace(/\\n/g, '')?.replace(/\\\\/g, '')?.replace(/\\"/g, '"'); // Remove "\n", "\\" and extra backslashes
      if (value?.startsWith('"') && value?.endsWith('"')) {  // Remove starting and ending quotes
          value = value?.slice(1, -1); // Remove the first and last character
      }
      setDictionary(value);
      const foundPageStructure = singlePageContent.templates?.find((template: any) => template.component === "Page Structure");
      setPageStructure(foundPageStructure?.shared_uid);
      const foundComparisonTable = singlePageContent.templates?.find((template: any) => template.component === "Comparison Table");
      setComparisonTable(foundComparisonTable?.shared_uid);
      const foundPopupOffer = singlePageContent.templates?.find((template: any) => template.component === "Popup Offer");
      setPopupOffer(foundPopupOffer?.shared_uid);
      const foundBrandInfo = singlePageContent.templates?.find((template: any) => template.component === "Brand Info");
      setBrandInfo(foundBrandInfo?.shared_uid);
      const foundReviewScreenshots = singlePageContent.templates?.find((template: any) => template.component === "Review Screenshots");
      setReviewScreenshots(foundReviewScreenshots?.shared_uid);
      const foundReviewBlock = singlePageContent.templates?.find((template: any) => template.component === "Review Block");
      setReviewBlock(foundReviewBlock?.shared_uid);
      const foundStickyCTA = singlePageContent.templates?.find((template: any) => template.component === "Sticky CTA");
      setStickyCTA(foundStickyCTA?.shared_uid);
      const foundAffiliateCoupon = singlePageContent.templates?.find((template: any) => template.component === "Affiliate Coupon");
      setAffiliateCoupon(foundAffiliateCoupon?.shared_uid);
      const foundBrandAdvantages = singlePageContent.templates?.find((template: any) => template.component === "Brand Advantages");
      setBrandAdvantages(foundBrandAdvantages?.shared_uid);
      const foundBrandMetrics = singlePageContent.templates?.find((template: any) => template.component === "Brand Metrics");
      setBrandMetrics(foundBrandMetrics?.shared_uid);
      const foundTextTiles = singlePageContent.templates?.find((template: any) => template.component === "Text Tiles");
      setTextTiles(foundTextTiles?.shared_uid);
      const foundReviewBanner = singlePageContent.templates?.find((template: any) => template.component === "Review Banner");
      setReviewBanner(foundReviewBanner?.shared_uid);
      const foundOfferButtons = singlePageContent.templates?.find((template: any) => template.component === "Offer Buttons");
      setOfferButtons(foundOfferButtons?.shared_uid);
      const foundMeetTheTeam = singlePageContent.templates?.find((template: any) => template.component === "Meet The Team");
      setMeetTheTeam(foundMeetTheTeam?.shared_uid);
      const foundSiteOwner = singlePageContent.templates?.find((template: any) => template.component === "Site Owner");
      setSiteOwner(foundSiteOwner?.shared_uid);
      const foundPaymentMethods = singlePageContent.templates?.find((template: any) => template.component === "Payment Methods");
      setPaymentMethods(foundPaymentMethods?.shared_uid);
      const foundProsandCons = singlePageContent.templates?.find((template: any) => template.component === "Brand Pros and Cons");
      setProsandCons(foundProsandCons?.shared_uid);
      const foundFaq = singlePageContent.templates?.find((template: any) => template.component === "FAQ");
      setFaq(foundFaq?.shared_uid);
      const foundCarouselCards = singlePageContent.templates?.find((template: any) => template.component === "Carousel Cards");
      setCarouselCards(foundCarouselCards?.shared_uid);
    }
  },[singlePageContent]);

  const updateTheme = async () => {
    const data = {
      name: themeName,
      dictionary: dictionary,
      templates: [
        pageStructure, comparisonTable, popupOffer, paymentMethods,
        prosandCons, faq, carouselCards,brandinfo, reviewScreenshots, reviewBlock, stickyCTA, affiliateCoupon, brandAdvantages, brandMetrics, textTiles, reviewBanner, offerButtons, meetTheTeam, siteOwner
      ].filter(item => item !== null && item !== undefined && item !== "" && !(Array.isArray(item) && item.length === 0)) // Filter out null, undefined, "", and []
    };
    try {
      if(oauthTokenHeader) {
        await DesignThemeEndPointLogic.editDesignTheme(oauthTokenHeader,data,selectedThemeShareuid);
      } else {
        throw new Error("User doesn't have permissions to edit design theme");
      }
      setDialogOpen(true);
      setIsLoading(true);
      await DesignThemeEndPointLogic.getDesignTheme(oauthTokenHeader, selectedSharedUid, setDesignThemesData, setError);
    } catch(error) {
      console.error(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const validate = async() => {
    // Check if all mandatory fields are filled
    if (!themeName || !pageStructure) {
      setSnackbarOpen(true);
      setSnackbarMessage('Please fill in all the mandatory fields');
      setIsValid(false);
      return false;
    }

    // Check if themeName is at least 8 characters long and contains only letters and numbers
    const themeNameRegex = /^[a-zA-Z0-9-_&*$#!:|,./\\ ]{8,}$/;
    if (!themeNameRegex.test(themeName)) {
      setSnackbarOpen(true);
      setSnackbarMessage('Template name must be at least 8 characters long and must contain only letters, numbers underscores and hyphens');
      setIsValid(false);
      return false;
    }


    const isThemeNameUnique = Array.isArray(designThemeData) && 
    designThemeData?.filter((item:any) => 
        item.name.toLowerCase() === themeName.toLowerCase() && 
        item?.name.toLowerCase() !== singlePageContent?.name?.toLowerCase()
    ).length === 0;
    if(!isThemeNameUnique) {
      setSnackbarOpen(true);
      setSnackbarMessage('Theme name must be unique');
      setIsValid(false);
      return false;
    }

    try {
      if (dictionary) {
        const cleanedDictionary = dictionary.replace(/\\n|\\+/g, ''); // Remove extra backslashes and newline characters
        const parsedAdditionalInfo = JSON.parse(cleanedDictionary);
        if (typeof parsedAdditionalInfo !== 'object') {
          throw new Error('Not an object');
        }
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMessage('Dictionary must be a valid JSON object');
      setShowErrorMessage(true);
      setIsValid(false);
      return false;
    }

    setIsValid(true); // Validation passed
    return true; // Validation passed
  }

  const handleUpdateTheme = async() => {
    const isFormValid = await validate();
    if (!isFormValid) return; // Exit if form is invalid
    try {
      setLoading(true);
      await updateTheme(); // Call the updateTheme function
      setIsLoading(true);
      setDialogOpen(true);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    handleCreateClose();
    navigate('/assets/design/theme');  // Redirect to the specified route
  };

  const handleClose = () => {
    setDialogOpen(false);
    handleCreateClose(); // Close the dialog
    setThemeName(''); // Reset the themeName
    setDictionary(''); // Reset the dictionary
    setPageStructure(null); // Reset the pageStructure
    setComparisonTable(null); // Reset the comparisonTable
    setPopupOffer(null); // Reset the popupOffer
    setPaymentMethods(null); // Reset the paymentMethods
    setProsandCons(null); // Reset the prosandCons
    setFaq(null); // Reset the faq
    setCarouselCards(null); // Reset the carouselCards
    setBrandInfo(null); // Reset the brandInfo
    setReviewScreenshots(null); // Reset the reviewScreenshots
    setReviewBlock(null); // Reset the reviewBlock
    setStickyCTA(null); // Reset the stickyCTA
    setAffiliateCoupon(null); // Reset the affiliateCoupon
    setBrandAdvantages(null); // Reset the brandAdvantages
    setBrandMetrics(null); // Reset the brandMetrics
    setTextTiles(null); // Reset the textTiles
    setReviewBanner(null); // Reset the reviewBanner
    setOfferButtons(null); // Reset the offerButtons
    setMeetTheTeam(null); // Reset the meetTheTeam
    setSiteOwner(null); // Reset the siteOwner
    navigate('/assets/design/theme');  // Redirect to the specified route
  };

  const handleDictionaryChange = (e:any) => {
    let value = e.target.value;

    // Remove starting and ending quotes if they exist
    if (value.startsWith('"') && value.endsWith('"')) {
        value = value.slice(1, -1); // Remove the first and last character
    }
    setDictionary(value);
};

  const renderSnackbar = () => {
  return (
    <Snackbar
      sx={{ zIndex: "9999999" }}
      open={snackbarOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarMessage.includes('Error')  || isValid ? "success" : "error"}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )};

  return (
    <>
    {loading ? (<SimpleBackdrop />
    ):(
      <>
        {renderSnackbar()}
      <Paper sx={{ padding: "8px 16px", height: "100%", display: "flex", flexDirection: "column" }}>
        <Typography fontSize={24}>Edit Theme</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Stack>
                <Typography marginTop={1}>General</Typography>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Theme Name" // Set your label here
                  placeholder="How you like the Theme to be called" // Set your placeholder here
                  multiline
                  required
                  color="secondary"
                  size="small"
                  maxRows={4}
                  value={themeName}
                  onChange={(e) => {setThemeName(e.target.value)}}
                  sx={{
                    width: "100%",
                    marginTop:"16px"
                  }}
                />
               <Typography fontSize={12} marginTop={0.5}>minimum of 8 characters, only letters and numbers. </Typography>
               <Autocomplete
                  sx={{ width: "100%", marginTop:"16px" }}
                  options={combinedOptions}
                  value={combinedOptions.find((option: any) => option.value === selectedSharedUid) || null}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Related website"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      color="secondary"
                      required
                    />
                )}
                size="small"
              />
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack flexDirection={"row"} gap={4}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography marginTop={1} marginBottom={2}>Code</Typography>
                    <TextField
                      id="outlined-textarea"
                      label="Dictionary"
                      value={dictionary}
                      onChange={handleDictionaryChange}
                      placeholder="{}"
                      multiline
                      rows={10}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: dictionary && showErrorMessage ? '#D32F2F' : 'default', // Set border color based on showErrorMessage
                          },
                          '&:hover fieldset': {
                            borderColor: dictionary && showErrorMessage ? '#D32F2F' : 'default', // Border color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: dictionary && showErrorMessage ? '#D32F2F' : 'default', // Border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack mt={2}>
                  <SnackbarContent
                    sx={{
                      background: "#FDEDED",
                      color: "#5F2120",
                      marginTop: "32px",
                      boxShadow: "none",
                      border: "1px solid #D32F2F80",
                      padding: "0 4px",
                    }}
                    message={
                      <Box display="flex">
                        <ErrorOutlineIcon sx={{ marginRight: "8px", color: "#5F2120" }} />
                        <Box>
                          <Typography variant="body1" fontWeight="500" sx={{ marginBottom: "4px" }}>
                          Update with Caution:
                          </Typography>
                          <Typography variant="body2">
                          Please proceed with extreme care when making updates, as these changes may adversely affect the overall design functionality. Ensure thorough testing and validation to avoid any unintended disruptions or errors in the end user experience.
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography>Assign Templates to Themes</Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box className="Page Structure">
              <Stack flexDirection={"row"} alignItems={"center"} mt={2}>
              <Checkbox disabled={Boolean(pageStructure)} checked={Boolean(pageStructure)} color="secondary" />
              <Autocomplete
                  sx={{ width: "100%"}}
                  options={pageStructureTemplates} // Set your options here
                  value={pageStructureTemplates?.find((option: any) => option?.value === pageStructure) || null} // Set the current value
                  onChange={(event, newValue) => {
                    setPageStructure(newValue ? newValue.value : null); // Set pageStructure or null if cleared
                  }}
                  getOptionLabel={(option: any) => option?.label || ''} // This specifies how to display the option label
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Page Structure"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                    required
                  />
                )}
                size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}>Mandatory Template</Typography>
              </Box>
              <Box className="Brand Info">
              <Stack flexDirection={"row"} alignItems={"center"} mt={2}>
              <Checkbox disabled={Boolean(brandinfo)} checked={Boolean(brandinfo)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={brandInfoTemplates}
                value={brandInfoTemplates?.find((option: any) => option?.value === brandinfo) || null}
                onChange={(event, newValue:any) => {
                  setBrandInfo(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Brand Info"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Review Screenshots">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(reviewScreenshots)} checked={Boolean(reviewScreenshots)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={reviewScreenshotsTemplates}
                value={reviewScreenshotsTemplates?.find((option: any) => option?.value === reviewScreenshots) || null}
                onChange={(event, newValue:any) => {
                  setReviewScreenshots(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Review Screenshots"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="FAQ">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(faq)} checked={Boolean(faq)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={faqTemplates}
                value={faqTemplates?.find((option: any) => option?.value === faq) || null}
                onChange={(event, newValue:any) => {
                  setFaq(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="FAQ"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Review Block">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(reviewBlock)} checked={Boolean(reviewBlock)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={reviewBlockTemplates}
                value={reviewBlockTemplates?.find((option: any) => option?.value === reviewBlock) || null}
                onChange={(event, newValue:any) => {
                  setReviewBlock(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Review Block"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Review Banner">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(reviewBanner)} checked={Boolean(reviewBanner)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={reviewBannerTemplates}
                value={reviewBannerTemplates?.find((option: any) => option?.value === reviewBanner) || null}
                onChange={(event, newValue:any) => {
                  setReviewBanner(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Review Banner"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Meet The Team">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(meetTheTeam)} checked={Boolean(meetTheTeam)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={meetTheTeamTemplates}
                value={meetTheTeamTemplates?.find((option: any) => option?.value === meetTheTeam) || null}
                onChange={(event, newValue:any) => {
                  setMeetTheTeam(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Meet The Team"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="Comparison Table">
              <Stack flexDirection={"row"} alignItems={"center"} mt={2}>
              <Checkbox disabled={Boolean(comparisonTable)} checked={Boolean(comparisonTable)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={comparisonTableTemplates}
                value={comparisonTableTemplates?.find((option: any) => option?.value === comparisonTable) || null}
                onChange={(event, newValue:any) => {
                  setComparisonTable(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Comparison Table"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Payment Methods">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(paymentMethods)} checked={Boolean(paymentMethods)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={paymentMethodsTemplates}
                value={paymentMethodsTemplates?.find((option: any) => option?.value === paymentMethods) || null}
                onChange={(event, newValue:any) => {
                  setPaymentMethods(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Payment Methods"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Sticky CTA">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(stickyCTA)} checked={Boolean(stickyCTA)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={stickyCTATemplates}
                value={stickyCTATemplates?.find((option: any) => option?.value === stickyCTA) || null}
                onChange={(event, newValue:any) => {
                  setStickyCTA(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Sticky CTA"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Affiliate Coupon">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(affiliateCoupon)} checked={Boolean(affiliateCoupon)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={affiliateCouponTemplates}
                value={affiliateCouponTemplates?.find((option: any) => option?.value === affiliateCoupon) || null}
                onChange={(event, newValue:any) => {
                  setAffiliateCoupon(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Affiliate Coupon"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Brand Advantages">
                <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
                  <Checkbox disabled={Boolean(brandAdvantages)} checked={Boolean(brandAdvantages)} color="secondary"/>
                  <Autocomplete
                    sx={{ width: "100%"}}
                    options={brandAdvantagesTemplates}
                    value={brandAdvantagesTemplates?.find((option: any) => option?.value === brandAdvantages) || null}
                    onChange={(event, newValue:any) => {
                      setBrandAdvantages(newValue ? newValue.value : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Please select a Template from the list."
                        label="Brand Advantages"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        color="secondary"
                      />
                  )}
                  size="small"
                  />
                </Stack>
                <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Offer Buttons">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(offerButtons)} checked={Boolean(offerButtons)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={offerButtonsTemplates}
                value={offerButtonsTemplates?.find((option: any) => option?.value === offerButtons) || null}
                onChange={(event, newValue:any) => {
                  setOfferButtons(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Offer Buttons"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              </Grid>
            <Grid item xs={4}>
              <Box className="Popup">
              <Stack flexDirection={"row"} alignItems={"center"} mt={2}>
              <Checkbox disabled={Boolean(popupOffer)} checked={Boolean(popupOffer)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={popupOfferTemplates}
                value={popupOfferTemplates?.find((option: any) => option?.value === popupOffer) || null}
                onChange={(event, newValue:any) => {
                  setPopupOffer(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Popup"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Pros & Cons">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(prosandCons)} checked={Boolean(prosandCons)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={prosandConsTemplates}
                value={prosandConsTemplates?.find((option: any) => option?.value === prosandCons) || null}
                onChange={(event, newValue:any) => {
                  setProsandCons(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Pros & Cons"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Brand Metrics">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(brandMetrics)} checked={Boolean(brandMetrics)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={brandMetricsTemplates}
                value={brandMetricsTemplates?.find((option: any) => option?.value === brandMetrics) || null}
                onChange={(event, newValue:any) => {
                  setBrandMetrics(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Brand Metrics"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Carousel Cards">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(carouselCards)} checked={Boolean(carouselCards)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={carouselCardsTemplates}
                value={carouselCardsTemplates?.find((option: any) => option?.value === carouselCards) || null}
                onChange={(event, newValue:any) => {
                  setCarouselCards(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Cards"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Text Tiles">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(textTiles)} checked={Boolean(textTiles)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={textTilesTemplates}
                value={textTilesTemplates?.find((option: any) => option?.value === textTiles) || null}
                onChange={(event, newValue:any) => {
                  setTextTiles(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Text Tiles"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
              <Box className="Site Owner">
              <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
              <Checkbox disabled={Boolean(siteOwner)} checked={Boolean(siteOwner)} color="secondary"/>
              <Autocomplete
                sx={{ width: "100%"}}
                options={siteOwnerTemplates}
                value={siteOwnerTemplates?.find((option: any) => option?.value === siteOwner) || null}
                onChange={(event, newValue:any) => {
                  setSiteOwner(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Please select a Template from the list."
                    label="Site Owner"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    color="secondary"
                  />
              )}
              size="small"
              />
              </Stack>
              <Typography fontSize={12} marginLeft={"42px"} mt={0.5}></Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Stack flexDirection={"row"} justifyContent={"end"} marginTop={4} gap={1}>
            <Button color="secondary" variant="outlined"><Stack flexDirection={"row"} gap={1} onClick={handleCancelClick}><ArrowBackIosNewIcon fontSize="small"/>Cancel</Stack></Button>
            <Button variant="contained" color="secondary" sx={{ textTransform: 'uppercase' }} onClick={handleUpdateTheme}><Stack flexDirection={"row"} gap={1}>{loading ? <CircularProgress size={24} color="inherit" /> : "Save Changes"}<CheckIcon fontSize="small"/></Stack></Button>
          </Stack>
        </Box>
      </Paper>
      {/* Dialog component */}
      <Dialog open={dialogOpen} onClose={handleClose} PaperProps={{ sx: { width: "900px", maxWidth: "900px" } }}>
        <Paper sx={{ padding: "16px 24px 20px 24px" }}>
          <DialogContent sx={{ padding: "0px 0px 16px 0px" }}>
            <DialogTitle>
              <Typography variant="h6">Changes Saved Successfully!</Typography>
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose} sx={{ textTransform: 'uppercase' }} variant="contained">Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
      </>
    )}
    </>
  )
}

export default EditTheme
