import { Draggable, Droppable } from "react-beautiful-dnd";

export default function DraggableElement(props: any) {
    return (
        <>
            <Droppable droppableId={`${props?.prefix}`}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {props?.values?.map((item: any, index: any) => (
                            item?.element !== 'Popup Offer' ?
                                <Draggable draggableId={item.id.toString()} index={index} key={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            data-is-dragging={snapshot.isDragging}
                                        >
                                            {item?.element}
                                        </div>
                                    )}
                                </Draggable> : ''
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable></>)
}