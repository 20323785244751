import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";

function SiteWideText() {
  const [, setGroupName] = useState("");
  // const [isDuplicateButtonDisabled, setIsDuplicateButtonDisabled] = useState(true);
  const [, setIsDuplicateButtonDisabled] = useState(true);

  // Handle input change and validate the minimum 8 character condition
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]+$/; // Allow only letters and numbers
    if (regex.test(value)) {
      setGroupName(value);
      setIsDuplicateButtonDisabled(value.length < 8); // Enable if 8+ characters
    }
  };

  return (
    <>
      <Typography>Site - wide text</Typography>
      <Box mt={1}>
        <TextField
          id="outlined-multiline-flexible"
          label="Website Name" // Set your label here
          placeholder="How you like the site to be called" // Set your placeholder here
          required
          fullWidth
          color="secondary"
          size="small"
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography
          sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
        >
          minimum of 8 characters, only letters and numbers.
        </Typography>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Advertiser Disclosure"
          placeholder="Placeholder"
          multiline
          rows={6}
          sx={{ marginTop: "16px" }}
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Footer Paragraph"
          placeholder="Placeholder"
          multiline
          rows={6}
          sx={{ marginTop: "16px" }}
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Copyright"
          placeholder="Placeholder"
          multiline
          rows={4}
          sx={{ marginTop: "16px" }}
          color="secondary"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography
          sx={{ marginTop: "4px", fontSize: "12px", color: "#00000099" }}
        >
          All fields are mandatory.
        </Typography>
        <Typography mt={2}>Secondary Headline</Typography>
        <Stack gap={2} mt={2}>
          <TextField
            size="small"
            fullWidth
            required
            id="outlined-required"
            label="Desktop"
            color="secondary"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            size="small"
            fullWidth
            required
            id="outlined-required"
            label="Tablet"
            color="secondary"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            size="small"
            fullWidth
            required
            id="outlined-required"
            label="Mobile"
            color="secondary"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
      </Box>
    </>
  );
}

export default SiteWideText;
