import { Grid, Paper, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
// import { paint } from "./ScoreDataset";

interface PerformanceDayBrandsGraphProps{
  exequteQueryContentQuery4:any;
  brandName:any;
}

const PerformanceDayBrandsGraph = ({exequteQueryContentQuery4,brandName}:PerformanceDayBrandsGraphProps) => {
  const rows = exequteQueryContentQuery4?.query_output || [];
  const filteredRows = brandName.length!==0
    ? rows.filter((row: any) => row.brand_name?.toLowerCase() === brandName.toLowerCase())
    : rows;
    const dateAggregates: { [key: string]: number } = {};
  
    filteredRows.forEach((row: any) => {
      const date = row.date_of_acquisition;
      const qftdValue = row.qftd_actual || 0;
      dateAggregates[date] = (dateAggregates[date] || 0) + qftdValue;
    });

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
  
    // Prepare the dataset for the bar chart
    const paint = Object.entries(dateAggregates)
      .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime()) // Sort by date
      .map(([date, totalQFTD]) => ({
        x: formatDate(date),
        y: totalQFTD,
      }));


     
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper sx={{ mt: 2, px: 2, py: 1, mb: 1 }}>
            <Typography sx={{ color: "#9C27B0", fontWeight: "500" }}>
              Daily QFTD Preformance
            </Typography>

            <BarChart
              height={300}
              dataset={paint}
              margin={{ left: 10, right: 25, top: 20, bottom: 30 }}
              grid={{ vertical: false, horizontal: true }}
              leftAxis={null}
              rightAxis={{}}
              slotProps={{ legend: { hidden: true } }}
              series={[
                {
                  dataKey: "y",
                  color: "#6200EE",
                  yAxisId: "leftAxisId",
                  stack: "total",
                },
              ]}
              xAxis={[{ dataKey: "x", scaleType: "band", disableLine: true }]}
              yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceDayBrandsGraph;
