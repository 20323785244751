import { trackPromise } from 'react-promise-tracker';
import { loginService } from '../../../services/login.service';
import Cookies from 'js-cookie';
import { setDisplayErrorSnackBar } from '../../../features/userSlice';
import { validEmail, validPassword } from './validation';
import store from '../../../store';
const isValidateFields = (email: string, password: string): any => {
    if (validEmail(email) !== '' && validPassword(password) !== '') {
        return false;
    }
    return true;
}
const handleTokenExpaired = (res: any, props: any) => {
    props.dispatch(setDisplayErrorSnackBar({ state: true, message: res?.text }));
    props.setUserSignInValueLocalStorage('false');
    localStorage.setItem('signin', 'false');
};
const isSuccessLogin = (body: any, props: any) => {
    /* props?.setIsLoginLoading(true) */
    try {
        trackPromise(loginService.signIn(body)
            .then((res) => {
                if (res?.data?.auth_token) {
                    Cookies.set('RT', res?.data?.auth_token); // rt - refresh token
                    window.location.href = '/';
                    
                }
            }).catch(error => {
                if (error?.status === 400) {
                    props.handleSnackbarOpen();
                    props.setSnackbarMessage("Invalid email or password. Please check your credentials and try again.");
                    handleTokenExpaired(error, props);
                } else {
                    props.handleSnackbarOpen();
                    props.setSnackbarMessage("Login failed. Please try again later.")
                    props.dispatch(setDisplayErrorSnackBar({ state: true, message: 'Login failed. Please try again later.' }));
                }
                props.dispatch(setDisplayErrorSnackBar({ state: true, message: 'An error occurred while logging in. Please try again later.' }));
            }));
    } catch (e) {
        // Catch any unexpected errors
        store.dispatch(setDisplayErrorSnackBar({ state: true, message: e }));
    }
    /* props?.setIsLoginLoading(false) */
};
// eslint-disable-next-line
export const loginHelpers = {
    isSuccessLogin,
    isValidateFields,
};