import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiAlert from "@mui/material/Alert";
import styles from "./LoginSection.module.css";
import { Link } from "react-router-dom";
const LoginSection = (props: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  /* const [isLoginDisabled, setIsLoginDisabled] = useState(false) */
  const [rememberMe, setRememberMe] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleLogin = useCallback(() => {
   
    if (!email || !password) {
      setOpenSnackbar(true);
      return;
    }

    // Proceed with login
    props?.loginNow(email, password);
   
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }, [email, password, rememberMe, props]);

 const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe');
    const savedUsername = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    if (rememberMeValue === 'true' && savedUsername && savedPassword) {
      setEmail(savedUsername)
      setPassword(savedPassword)
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    const keyDownHandler = (e:any) => {
      
      if(e.key === 'Enter'){
        if (rememberMe) {
          localStorage.setItem('rememberMe', 'true');
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        handleLogin()
      }
    }
    document.addEventListener('keydown', keyDownHandler);

    return() => {
      document.removeEventListener('keydown', keyDownHandler);
    }
  }, [email, password, rememberMe, handleLogin])
  

  return (
    <section className={styles.loginWindowContant}>
      <div className={styles.logoSection}>
        <img
          className={styles.optivalLogo1Icon}
          alt=""
          src="/optivallogo-1@2x.png"
        />
      </div>
      <div className={styles.titleSection}>
        <h6 className={styles.typography}>
          <div className={styles.h6}>welcome back</div>
        </h6>
        <h4 className={styles.typography1}>
          <div className={styles.h4}>Login to your account</div>
        </h4>
      </div>
      <form className={styles.actionsSection}>
        <div className={styles.textFielsSection}>
          <TextField
            className={styles.textfield}
            color="primary"
            variant="outlined"
            type="text"
            label="Email"
            placeholder="Enter Your Email"
            size="medium"
            margin="none"
            value={email}
            error={email ? props?.isValidEmail(email) : ''}
            /* helperText={'Required'} */
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            className={styles.textfield}
            value={password}
            color="primary"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            size="medium"
            margin="none"
            error={password ? props?.validPassword(password) : ''}
         /*    helperText={'Must be 8 or more characters (English only) and contain at least 1 number and 1 special character.'} */
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.additionalActions}>
          <FormControlLabel
            label="Remember Me"
            labelPlacement="end"
            control={
              <Checkbox color="secondary" checked={rememberMe} 
              onChange={(e) => setRememberMe(e.target.checked)}  size="medium" />
            }
          />
          <Link to="/reset-password" className={styles.typography2}>
            <div className={styles.body2}>Forgot Password?</div>
          </Link>
        </div>
        <Button
          className={styles.button}
          variant="contained"
          color="secondary"
          onClick={handleLogin}
          /* disabled={isLoginDisabled} */
        >
          LOGIN NOW
        </Button>
      </form>
      <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          Both email and password are required.
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default LoginSection;
