import { useEffect, useState } from "react";
import ProcessListCard from "../../../../components/Admin/Processes/ETL-Process-List/ProcessListFilters/ProcessListCard";
import ProcessListTable from "../../../../components/Admin/Processes/ETL-Process-List/ProcessListTable/ProcessListTable";
import { useHasETLProcessListPermission } from "../../../../logic/Admin/Permissions/useHasETLProcessListPermission";
import { setIsLoading } from "../../../../features/userSlice";
import store from "../../../../store";
import { ETLProcessListEndPointLogic } from "../../../../logic/Admin/ETL Process List/ETLProcessListEndPointLogic";
import { trackPromise } from "react-promise-tracker";
import SimpleBackdrop from "../../../../components/BackDrop/BackDrop";
import { ModulesPermissionNames } from "../../../../data/menu/ModulesPermissions";
 

export default function ETLProcessListScreen(userToken:any) {

  const hasGetPermissions = useHasETLProcessListPermission([
    ModulesPermissionNames.GET_API_PROCESSES,
    ModulesPermissionNames.GET_API_PROCESS_TOGGLE,
  ]);
  const hasEditPermissions = useHasETLProcessListPermission([
    ModulesPermissionNames.POST_API_PROCESS_TOGGLE,
    ModulesPermissionNames.PUT_API_PROCESS_TOGGLE,
    ModulesPermissionNames.POST_API_PROCESSES,
    ModulesPermissionNames.PUT_API_PROCESSES,
  ]);
  // const hasDeletePermission = useHasETLProcessListPermission([
  //   CapabilitiesUiName.DELETE_API_PROCESS_TOGGLE,
  //   CapabilitiesUiName.DELETE_API_PROCESS,
  // ])

  const oauthTokenHeader = userToken?.userToken;
  const [ETLProcessContent,setETLProcessContent] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [status, setStatus] = useState(null);
  const [reload,setReload] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [page, setPage] = useState(0);
  const [searchStatus,setSearchStatus] = useState("");
  // const [searchStatus,setSearchStatus] = useState("");

  const tableHeadings = {
    // "edit":"Edit",
    "id": "ID",
    "name": "Process Name",
    // "process_command": "Process Command",
    "last_run": "Last Run",
    "status": "Status",
    "run_process": "Run Process"
  }

  useEffect(() => {
    const fetchETLProcessList = async () => {
      store.dispatch(setIsLoading(true));
      setIsLoad(true);
      try {
        if (hasGetPermissions) {
          await ETLProcessListEndPointLogic.getETLProcessList(
            oauthTokenHeader,
            setETLProcessContent,
            trackPromise
          );
        } 
        else {
          throw new Error("User doesn't have permissions to fetch process list data");
        }
      } catch (error) {
        console.error("Error fetching advertising accounts:", error);
      } finally {
        store.dispatch(setIsLoading(false));
        setIsLoad(false);
        setReload(false);
      }
    };

    if (oauthTokenHeader) {
      fetchETLProcessList();
    }
  }, [oauthTokenHeader, hasGetPermissions , reload]);



  return (
    <>
      <ProcessListCard ETLProcessContent={ETLProcessContent} setSelectedProcess={setSelectedProcess} selectedProcess={selectedProcess} setStatus={setStatus} status={status} hasEditPermissions={hasEditPermissions} oauthTokenHeader={oauthTokenHeader} setReload={setReload} selectedRow={selectedRow} setSelectedRow={setSelectedRow} page={page} setPage={setPage}
      setSearchStatus={setSearchStatus} searchStatus={searchStatus}/>
      {isLoad? <SimpleBackdrop/> : <ProcessListTable tableHeadings={tableHeadings} ETLProcessContent={ETLProcessContent} selectedRow={selectedRow} setSelectedRow={setSelectedRow} selectedProcess={selectedProcess} status={status} oauthTokenHeader={oauthTokenHeader}setReload={setReload} setIsLoad={setIsLoad} page={page} setPage={setPage}/> }
    </>
  )
}
