import { Stack } from "@mui/system";
import { Autocomplete, Button, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import { useState } from "react";
import WebsiteActionFilter from "../WebsiteActionFilter/WebsiteActionFilter";
import CreateNewWebsiteModal from "../CreateActionsModal/CreateNewWebsiteModal";



function WebsiteActionFilterCard() {

  const websitelisting = [
    { label: "websiteexample1.com"},
    { label: "websiteexample2.com"},
    { label: "websiteexample3.com"},
    { label: "websiteexample4.com"},
    { label: "websiteexample5.com"},
  ];

  const [openNewWebsite, setopenNewWebsite] = useState(false);

  const handleOpenNewWebsite = () => {
    setopenNewWebsite(true);
  }

  const handleCloseNewWebsite = () => {
    setopenNewWebsite(false);
  }


  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"start"}
        gap={2}
      >
        <Autocomplete
          sx={{ width: "300px" }}
          options={websitelisting.map((websitename)=>websitename.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Page Group Name"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ marginLeft: "12px" }} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
            />
          )}
          size="small"
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <WebsiteActionFilter/>
          <Button
            variant="contained"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
            onClick={handleOpenNewWebsite}
          >
            Create new site <AddIcon />
          </Button>
        </Stack>
      </Stack>
      <CreateNewWebsiteModal open={openNewWebsite} handleClose={handleCloseNewWebsite}></CreateNewWebsiteModal>
    </>
  );
}

export default WebsiteActionFilterCard;
