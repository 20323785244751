import WebsiteActions from "../../../components/AssetManagement/Website/WebsiteActions/WebsiteActions"

function WebsiteActionsScreen() {
  return (
    <div>
        <WebsiteActions/>
    </div>
  )
}

export default WebsiteActionsScreen