import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { LineChart } from "@mui/x-charts";
import { ChartsReferenceLine } from "@mui/x-charts/ChartsReferenceLine";
import { paint } from "../ScoreDataset";
import { Box} from '@mui/system';


const modalStyle = {
  width: 600,
};

interface TrandDialogueProps {
    openModal: boolean;
    onCloseModal: () => void;
  }

const TrandDialogue: React.FC<TrandDialogueProps> =({ openModal, onCloseModal }) => {
   

  return (
    <>
    <Dialog
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={modalStyle} >
        <DialogTitle id="alert-dialog-title">
          {"Real Users’ Experience Trend"}
        </DialogTitle>
        <DialogContent>
        <LineChart
            dataset={paint}
            series={[
              {
                dataKey: "y",
                color: "#6200EE",
                showMark: false,
                curve: "linear",
              },
            ]}
            xAxis={[{ scaleType: "point", dataKey: "x", disableLine: true }]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
              },
            ]}
            height={250}
            margin={{ left: 30, right: 30, top: 25, bottom: 30 }}
            grid={{ vertical: false, horizontal: true }}
            leftAxis={null}
            rightAxis={{}}
            slotProps={{ legend: { hidden: true } }}
          >
            <ChartsReferenceLine
              y={4}
              lineStyle={{ stroke: "#EF5350", strokeDasharray: "3" }}
            />
            <ChartsReferenceLine
              y={2}
              lineStyle={{ stroke: "#4CAF50", strokeDasharray: "3" }}
            />
          </LineChart>
        </DialogContent>
        <DialogActions sx={{mx:'1rem',mb:'1rem'}}>
          <Button onClick={onCloseModal} size='small' variant='contained' color='secondary'>
            Close
          </Button>
        </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default TrandDialogue