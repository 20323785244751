import { Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";

function CongratulationMessage() {
  return (
    <div>
      <Paper sx={{ padding: "16px 24px 20px 24px" }}>
        <Stack>
          <img alt="Edit" src="/congratulations.jpg" />
          <Typography fontSize={34} align="center" sx={{ mt: 2 }}>
            Congratulations!<br></br> Your Website is almost Ready to Launch!
          </Typography>
          <Typography fontSize={20} align="left" fontWeight={500} mt={2}>
            You’ve successfully completed the setup process, and now it’s time
            for us to do our magic.
          </Typography>
          <Typography align="left" fontWeight={500} mt={2}>
            Your new website will soon be live. Here are a few things you could
            do when the site is created:
          </Typography>
          <Stack gap={1} mt={2}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <PreviewOutlinedIcon color="secondary" />
              <Typography>
                <Box component="span" color="secondary.main" fontWeight={500}>
                  View Your Site:
                </Box>{" "}
                Check out your new website and see your vision come to life.
              </Typography>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <AutoFixHighOutlinedIcon color="secondary" />
              <Typography>
                <Box component="span" color="secondary.main" fontWeight={500}>
                  Customize Further:
                </Box>{" "}
                Make additional tweaks and enhancements to perfect your site.
              </Typography>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <AddOutlinedIcon color="secondary" />
              <Typography>
                <Box component="span" color="secondary.main" fontWeight={500}>
                  Add Content:
                </Box>{" "}
                Start populating your site with comparison tables, widgets and
                more.
              </Typography>
            </Stack>
          </Stack>
          <Typography align="left" mt={2}>
            Our team will notify you via email when the site is ready, you can
            also see it on the website list. If you need any assistance, our
            support team is here to help.
          </Typography>
          <Typography align="left" mt={2} fontWeight={500}>
            We’re excited to see what you create and how your website will grow.
          </Typography>
        </Stack>
        <Stack alignItems={"center"} mt={2}>
          <Button color="secondary" variant="contained">
            Close
          </Button>
        </Stack>
      </Paper>
    </div>
  );
}

export default CongratulationMessage;
