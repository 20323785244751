import { endpointsService } from './../../../../services/endPoints.services';
import { setDisplayErrorSnackBar, setIsLoading } from '../../../../features/userSlice';
import store from '../../../../store';


async function getQuarterlySpeedGoals(oauthTokenHeader: string, website_shared_uid: string,setData:any,setError:any) {
  try {
    const response = await endpointsService.getQuarterlyGoals(oauthTokenHeader, website_shared_uid);
    if (!response.status || response.status !== 200) {
       /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Quarterly goals', type: 'error' }))); */
    } else {
      setData(response?.data);
    }
    } catch (e:any) {
    setError(e);
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
};

async function createQuarterlySpeedGoals(oauthTokenHeader:any, website_shared_uid:any, data:any) {
  try {
    const response = await endpointsService.createQuarterlyGoals(oauthTokenHeader, website_shared_uid, data);
    if (!response.status || response.status !== 201 || response.status === 400) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Creating Quarterly goals', type: 'error' }));
    }
    else {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Quarterly Goal Have Been Created Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function editQuarterlySpeedGoals(oauthTokenHeader:any, website_shared_uid:any, data:any) {
  try {
    const response = await endpointsService.editQuarterlyGoals(oauthTokenHeader, website_shared_uid, data);
    if (!response.status || response.status !== 200) {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Editing Quarterly goals', type: 'error' }));
    }
    else {
      store.dispatch(setDisplayErrorSnackBar({ message: 'Quarterly Goal Have Been Edited Successfully', type: 'success' }));
    }
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function getDevices(oauthTokenHeader: string, setDevices:any) {
  try {
    store.dispatch(setIsLoading(true));
    const response = await endpointsService.getDevices(oauthTokenHeader);
    if (!response || !response.status || response.status !== 200) {
      throw new Error('Failed to get page content');
    }
    setDevices(response?.data?.data); // Set the fetched data
  } catch(e:any) {
    store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function getPageViewInternalList(oauthTokenHeader: string, website_shared_uid: string,setPageInternalListData:any,setErrorAccToPageStatus:any,trackPromise:any) {
  try {
    const response = await endpointsService.getPageViewInternalList(oauthTokenHeader, website_shared_uid);
    if (!response.status || response.status !== 200) {
       /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Quarterly goals', type: 'error' }))); */
      //  setErrorAccToPageStatus(response)
    } else {
      store.dispatch(setIsLoading(true))
      setPageInternalListData(response?.data?.data);
    }
    } catch (e:any) {
      trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
  }
};

async function togglePageSpeed(oauthTokenHeader: string,data:any,website_shared_uid:any, psi_shared_uid:any, setTogglePageSpeedData:any) {
  try {
      const response = await endpointsService.putTogglePageSpeed(oauthTokenHeader,data,website_shared_uid, psi_shared_uid)
      if (!response.status || response.status !== 200){
         /*  setSnackbarOpen(false) */
      /* store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' })); */
      } else{
          store.dispatch(setIsLoading(true))
          setTogglePageSpeedData([response?.data?.data])
      }
  } catch (e:any) {
      store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
  }
}

async function postPageSpeedInternal(oauthTokenHeader: string, data:any, website_shared_uid:any, setPostedPageSpeedContent: any,setErrorAccToPageStatus:any, setIsLoading:any) {
  try {
      const response = await endpointsService.postPageSpeedInternal(oauthTokenHeader, data, website_shared_uid);
      if (!response.status || response.status !== 201) {
          setErrorAccToPageStatus([response])
          /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: 'Something went wrong while Getting Page Content', type: 'error' }))); */
      } else {
          /* store.dispatch(setIsLoading(true)) */
          setPostedPageSpeedContent([response?.data?.data]);
      }
  } catch (e:any) {
      if(e?.status !== undefined){
        setErrorAccToPageStatus([e])
         /*  trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }))); */
      }
      
      /* trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e || 'Unknown error occurred', type: 'error' }))); */
  }
}

export const QuarterlySpeedGoalsEndPointLogic = {
  getQuarterlySpeedGoals,
  createQuarterlySpeedGoals,
  editQuarterlySpeedGoals,
  getDevices,
  getPageViewInternalList,
  togglePageSpeed,
  postPageSpeedInternal
}
