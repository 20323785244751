import { endpointsService } from "../../../services/endPoints.services";
import store from "../../../store";
import { setDisplayErrorSnackBar, setIsLoading } from "../../../features/userSlice";

async function getManualExpenseContent(oauthTokenHeader: string, setIsLoading: any,setRows:any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getManualExpenseTableContent(oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        else {
            setRows(response?.data); // Set the fetched data
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function getCurrencies(oauthTokenHeader: string, setCurrencies:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getCurrencies(oauthTokenHeader);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get page content');
        }
        setCurrencies(response?.data?.data); // Set the fetched data
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}

async function createExpense(oauthTokenHeader: string, data:any, setExpense:any, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.createExpense(oauthTokenHeader,data);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to get page content');
        }
        setExpense(response?.data?.data); // Set the fetched data
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function editExpense(oauthTokenHeader: string, data:any, object_shared_uid:string, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.editExpense(oauthTokenHeader,data,object_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to edit expense');
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function postComment(oauthTokenHeader: string, data:any, object_shared_uid:string, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.postCommentME(oauthTokenHeader,data,object_shared_uid);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to post comment');
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function fileUploadME(oauthTokenHeader: string, data:any, object_shared_uid:string, trackPromise: any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.fileUploadME(oauthTokenHeader,data,object_shared_uid);
        if (!response || !response.status || response.status !== 201) {
            throw new Error('Failed to upload file');
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function getFiles(oauthTokenHeader: string, object_shared_uid:string, trackPromise: any,setUploadedFiles:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getFiles(oauthTokenHeader,object_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get files');
        }
        else {
            setUploadedFiles(response?.data?.data)
        }
    } catch (e:any) {
        // trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function getComments(oauthTokenHeader: string, object_shared_uid:string, trackPromise: any,setUploadedComments:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.getComments(oauthTokenHeader,object_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to get comments');
        }
        else {
            setUploadedComments(response?.data?.data)
        }
    } catch (e:any) {
        // trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function putComments(oauthTokenHeader: string, data:any, object_shared_uid:string, trackPromise: any,setSuccess:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.putComments(oauthTokenHeader,data,object_shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to update comments');
        }
        else {
            setSuccess(response?.reason);
        }
    } catch (e:any) {
        trackPromise(store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' })));
    }
}

async function deleteExpanse(oauthTokenHeader: string, shared_uid:string,setSuccess:any) {
    try {
        store.dispatch(setIsLoading(true));
        const response = await endpointsService.deleteExpense(oauthTokenHeader,shared_uid);
        if (!response || !response.status || response.status !== 200) {
            throw new Error('Failed to update comments');
        }
        else {
            setSuccess(response?.reason);
        }
    } catch (e:any) {
        store.dispatch(setDisplayErrorSnackBar({ message: e?.parsedText || 'Unknown error occurred', type: 'error' }));
    }
}



// eslint-disable-next-line
export const ManualExpenseEndPointLogic = {
    getManualExpenseContent,
    getCurrencies,
    createExpense,
    editExpense,
    postComment,
    fileUploadME,
    getFiles,
    getComments,
    putComments,
    deleteExpanse
};
